import React, { useEffect } from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import {
  sysActions,
  sysSelectors,
  useAction,
  useSelector,
} from "../../../state";
import { ButtonMenu, NotificationsIcon } from "../../../components";
import { FacilityAdminPages } from "../../../pages";
import { Navigation, useLocation } from "../../../lib";

const PendingFacilitiesMenu = () => {
  const location = useLocation();

  const allFacilities = useSelector(sysSelectors.allFacilities);
  const pendingFacilities = useSelector(sysSelectors.pendingFacilities);
  const pendingFacilitiesAlerted = useSelector(
    sysSelectors.pendingFacilitiesAlerted,
  );

  const setPendingFacilitiesAlerted = useAction(
    sysActions.setPendingFacilitiesAlerted,
  );
  useEffect(() => {
    if (
      pendingFacilities.length && // has pending facilities
      (!pendingFacilitiesAlerted || // and did not yet alert (via redirect to form), or
        (pendingFacilities.length === allFacilities.length && // all accessible facilities are pending and not on the profile page (once initially alerted, profile page can be accessed even if all user facilities are pending)
          !location.pathname.startsWith("/profile")))
    ) {
      // redirect to pending facility form
      if (!location.pathname.startsWith("/facilities")) {
        Navigation.go(FacilityAdminPages.pendingFacility, {
          params: { id: pendingFacilities[0].id },
        });
      }
      setPendingFacilitiesAlerted(true);
    }
  }, [
    allFacilities.length,
    location.pathname,
    pendingFacilities,
    pendingFacilities.length,
    pendingFacilitiesAlerted,
    setPendingFacilitiesAlerted,
  ]);

  return pendingFacilities.length ? (
    <>
      <ButtonMenu
        button={
          <Tooltip title="Pending facilities awaiting your attention">
            <IconButton
              aria-controls="facilities-menu"
              aria-haspopup="true"
              sx={{ ml: 1, color: "text.secondary" }}
            >
              <Badge color="error" badgeContent={pendingFacilities.length}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        }
        menuItems={pendingFacilities.map((facility) => ({
          label: `View ${facility.name} profile`,
          linkTo: Navigation.url(FacilityAdminPages.pendingFacility, {
            params: { id: facility.id },
          }),
        }))}
      />
    </>
  ) : null;
};

export default PendingFacilitiesMenu;
