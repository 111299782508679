import React, { useCallback, useState } from "react";
import { Button } from "@mui/material";
import { Navigation } from "../../../../lib";
import { AdminPages } from "../..";
import { adminActions, useAppDispatch } from "../../../../state";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../components";

export const DeactivateFacilityFilesSettingButton = React.memo(
  /**
   *
   */
  function DeactivateFacilityFilesSettingButton({
    groupId,
  }: {
    groupId: number;
  }) {
    const dispatch = useAppDispatch();

    const [showConfirmDeactivationDialog, setShowConfirmDeactivationDialog] =
      useState(false);

    const toggleEnableFacilityFiles = useCallback(async () => {
      const submitted = await dispatch(
        adminActions.submitGroupSettings(
          groupId,
          {
            facilityFilesEnabled: false,
          },
          "Facility files setting",
        ),
      );
      if (submitted) {
        Navigation.go(AdminPages.group, {
          params: { id: groupId },
          query: { tab: "settings" },
        });
      }
    }, [dispatch, groupId]);

    return (
      <>
        <Button
          color="error"
          onClick={() => setShowConfirmDeactivationDialog(true)}
          size="large"
          sx={{ ml: 1, whiteSpace: "nowrap" }}
          variant="contained"
        >
          Deactivate add on
        </Button>
        {showConfirmDeactivationDialog && (
          <ConfirmationDialog
            confirmColor="error"
            confirmText="Deactivate"
            handleClose={() => setShowConfirmDeactivationDialog(false)}
            handleConfirm={toggleEnableFacilityFiles}
            message="Facility users will lose access to all of their facility files immediately. This cannot be undone."
            open={true}
            title="Deactivate files add-on?"
            type={ConfirmationDialogTypes.Alert}
          />
        )}
      </>
    );
  },
);
