import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  adminActions,
  PolicyCategory,
  useAppDispatch,
} from "../../../../../state";
import {
  Form,
  replaceEmptyProps,
  replaceNullProps,
  useFormik,
} from "../../../../../lib";
import * as yup from "yup";
import { TextField } from "../../../../../components";

const validationSchema = yup.object({
  name: yup
    .string()
    .max(100, "Category name cannot exceed 100 characters")
    .required("Category name is required"),
});

interface CategoryDialogProps {
  category?: PolicyCategory;
  handleClose: () => void;
}

export const CategoryDialog = React.memo(
  /**
   *
   */
  function CategoryDialog({
    category: initialCategory,
    handleClose,
  }: CategoryDialogProps) {
    const dispatch = useAppDispatch();

    const form = useFormik({
      enableReinitialize: true,
      initialValues: (initialCategory
        ? replaceNullProps(initialCategory)
        : {
            name: "",
          }) as PolicyCategory,
      validationSchema,
      async onSubmit(values) {
        const savedCategory = await dispatch(
          adminActions.submitPolicyCategory(replaceEmptyProps(values)),
        );
        if (savedCategory) {
          handleClose();
        }
      },
    });

    return (
      <Dialog open={true} PaperProps={{ sx: { width: "600px" } }}>
        <Form form={form} promptCancelText="Back to Category settings">
          <DialogTitle variant="h6">
            {initialCategory?.id ? "Edit" : "Add"} category
          </DialogTitle>
          <DialogContent>
            <Grid container paddingTop="16px">
              <TextField name="name" label="Category name" />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={handleClose}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={form.isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
