import { useCallback } from "react";
import { authClient, uiActions, useDispatch } from "../../../state";

export function useSecureFileRead(name = "file") {
  const dispatch = useDispatch();

  const getSecureFileUrl = useCallback(
    async (fileUrl, extended = false) => {
      dispatch(uiActions.setLoading(true));

      // get signed url
      const { data, status } = await authClient.get(
        "/documents/signedUrl/read",
        { params: { blobUrl: fileUrl, extended } },
      );

      dispatch(uiActions.setLoading(false));

      if (status === 200) {
        const { signedUrl } = data;
        return signedUrl;
      }

      dispatch(uiActions.showError(`Failed to retrieve ${name}`));
    },
    [dispatch, name],
  );

  return getSecureFileUrl;
}
