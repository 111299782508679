import React, { useEffect, useState } from "react";
import { Typography, Box, IconButton, Dialog } from "@mui/material";
//Local
import {
  authSelectors,
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { useOutlet } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import { CompanyInfoDisplay } from "../../auth/CompanyInfoDisplay";
import {
  ArrowBackIcon,
  CloseIcon,
  ErrorIcon,
  HelpIcon,
  LogoutIcon,
} from "../../../components";
import { Navigation, useLocation } from "../../../lib";
import { AdminSharedPages } from "../../../pages";
import { SearchInput } from "../../../components";
import {
  HelpDialogContainerStyled,
  HotlineInfoContainerStyled,
  SearchContainerStyled,
  StagingBannerStyled,
  TitleChipStyled,
  ToolbarStyled,
  ToolbarTitleStyled,
  TopBarStyled,
} from "./AppBar.styles";
import { collapsedDrawerWidth, drawerWidth } from "./Drawer.styles";
import { PermissionClaims, PortalUserTypes } from "../../../lib/constants";
import { Link } from "react-router-dom";
import PendingFacilitiesMenu from "./PendingFacilitiesMenu";
import FacilityHeader, { facilityHeaderHeight } from "./FacilityHeader";
import { __PROD__ } from "../../../config";

function Search() {
  const location = useLocation();

  const submitSearch = (search) => {
    if (search) {
      const urlConfig = { query: { search } };
      Navigation.go(AdminSharedPages.search, urlConfig);
    }
  };

  return location.pathname !== AdminSharedPages.search.path ? (
    <SearchContainerStyled>
      <SearchInput
        adornmentPosition="start"
        fullWidth
        variant="standard"
        placeholder="Search CCG Portal..."
        name="search"
        onSearch={submitSearch}
        inputProps={{
          disableUnderline: true,
        }}
      />
    </SearchContainerStyled>
  ) : null;
}

function Help() {
  const dispatch = useDispatch();
  const helpDialogOpen = useSelector(uiSelectors.helpDialogOpen);

  const toggleHelpDialogOpen = () => {
    dispatch(uiActions.setHelpDialogOpen(!helpDialogOpen));
  };

  return (
    <>
      <IconButton
        onClick={toggleHelpDialogOpen}
        size="small"
        sx={{ ml: 1, color: "text.secondary" }}
      >
        <HelpIcon />
      </IconButton>
      <Dialog open={helpDialogOpen} onClose={toggleHelpDialogOpen}>
        <HelpDialogContainerStyled>
          <CompanyInfoDisplay
            title="Contact us"
            subtitle="Questions? Reach out to any member of our team."
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              color: "background.default",
            }}
            onClick={toggleHelpDialogOpen}
          >
            <CloseIcon />
          </IconButton>
        </HelpDialogContainerStyled>
      </Dialog>
    </>
  );
}

function HotlineInfo() {
  return (
    <HotlineInfoContainerStyled>
      <HelpIcon sx={{ color: "text.secondary" }} />
      <Typography variant="body2" color="text.secondary">
        Hotline (800) 909-4545
      </Typography>
    </HotlineInfoContainerStyled>
  );
}

export default function AppBar() {
  const userType = useSelector(authSelectors.userType);
  const userPermissionClaims = useSelector(authSelectors.permissionClaims);
  const isFacilityAdmin = userType === PortalUserTypes.FacilityAdmin;
  const isFacilityStaff = userType === PortalUserTypes.FacilityStaff;

  const menuOpen = useSelector(uiSelectors.menuOpen);
  const menuDisabled = useSelector(uiSelectors.menuDisabled);
  const hideMenu = useSelector(uiSelectors.hideMenu);
  const showFacilityHeader = useSelector(uiSelectors.showFacilityHeader);

  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const outlet = useOutlet();
  const pageProps = outlet?.props.children.props.children.props;
  const pageTitle = useSelector(uiSelectors.pageTitle);
  const pageTitleDisplay = pageTitle ? (
    <>
      {pageTitle.title}
      {pageTitle.status && (
        <TitleChipStyled
          backgroundColor={pageTitle.statusColor}
          label={pageTitle.status}
          size="small"
        />
      )}
    </>
  ) : (
    pageProps?.title
  );
  const goBack = pageProps?.goBack;
  const goBackDefaultPath = pageProps?.goBackDefaultPath;

  useEffect(() => {
    if (hideMenu) {
      setCurrentDrawerWidth(0);
    } else if (menuOpen) {
      setCurrentDrawerWidth(drawerWidth);
    } else {
      setCurrentDrawerWidth(collapsedDrawerWidth);
    }
  }, [hideMenu, menuOpen]);

  const [showStagingBanner, setShowStagingBanner] = useState(!__PROD__);

  return (
    <Box display="flex" justifyContent="center">
      {showStagingBanner && (
        <StagingBannerStyled>
          <Box>
            <ErrorIcon sx={{ mr: 1 }} />
            This staging site is for test purposes only. Do not use for live
            data entry.
          </Box>
          <IconButton size="small" onClick={() => setShowStagingBanner(false)}>
            <CloseIcon />
          </IconButton>
        </StagingBannerStyled>
      )}
      <TopBarStyled
        elevation={0}
        sx={{
          width: `calc(100% - ${currentDrawerWidth}px)`,
          ml: `${currentDrawerWidth}px`,
        }}
      >
        {showFacilityHeader && <FacilityHeader />}
        <ToolbarStyled
          sx={{
            marginTop: `${showFacilityHeader ? facilityHeaderHeight : "0"}px`,
          }}
        >
          <ToolbarTitleStyled variant="h6">
            {goBack && (
              <IconButton
                onClick={() => Navigation.goBack(goBackDefaultPath)}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {pageTitleDisplay}
          </ToolbarTitleStyled>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isFacilityStaff ? (
              <>
                <HotlineInfo />
                <IconButton component={Link} to="/auth/logout" sx={{ ml: 2 }}>
                  <LogoutIcon sx={{ color: "text.secondary" }} />
                </IconButton>
              </>
            ) : (
              <>
                {!menuDisabled && <Search />}
                {isFacilityAdmin && (
                  <>
                    <Help />
                    {userPermissionClaims.includes(
                      PermissionClaims.PendingFacilityEditClaim,
                    ) && <PendingFacilitiesMenu />}
                  </>
                )}
                <ProfileMenu />
              </>
            )}
          </Box>
        </ToolbarStyled>
      </TopBarStyled>
    </Box>
  );
}
