import { AppThunk } from "..";
import { pluralizeText } from "../../lib";
import {
  PolicyDocumentPublishStatuses,
  PolicyVersionPublishStatuses,
  PortalUserTypes,
} from "../../lib/constants";
import { ListTypes } from "../lists/state";
import {
  authClient,
  authSelectors,
  listsActions,
  sysActions,
  uiActions,
} from "../states";
import {
  Announcement,
  Audit,
  AuditExportRecord,
  AuditTemplate,
  AuditVersion,
  BasicAudit,
  BasicPolicyVersion,
  BasicUser,
  BulkPolicyUpdate,
  Facility,
  FacilityNote,
  Group,
  GroupFacilityRegionSearchResponse,
  GroupSettings,
  Policy,
  PolicyCategory,
  PolicyDocumentPublishLogRecord,
  PolicyVersion,
  PolicyVersionRecord,
  Position,
  Region,
  Resource,
  ResourceCategory,
  Role,
  Training,
  TrainingPacket,
} from "../types";
import { admin } from "./state";

const { actions } = admin;

export const adminActions = {
  ...actions,
  // user roles
  getRole(id: number): AppThunk<Promise<Role | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/roles/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve access level"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitRole(values: Role): AppThunk<Promise<Role | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/users/roles`, values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save access level",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Access level saved"));
        dispatch(listsActions.refreshList(ListTypes.userRoles));
        dispatch(sysActions.getAllRoles());
        return data;
      }
    };
  },
  deleteRole(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(`/users/roles/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete access level",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Access level deleted"));
        dispatch(listsActions.refreshList(ListTypes.userRoles));
        dispatch(sysActions.getAllRoles());
        return true;
      }
    };
  },
  // user positions
  getPosition(id: number): AppThunk<Promise<Position | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/positions/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve position"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitPosition(values: Position): AppThunk<Promise<Position | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/users/positions`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save position",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Position saved"));
        dispatch(listsActions.refreshList(ListTypes.userPositions));
        dispatch(sysActions.getAllPositions());
        return data;
      }
    };
  },
  deletePosition(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/users/positions/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete position",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Position deleted"));
        dispatch(listsActions.refreshList(ListTypes.userPositions));
        dispatch(sysActions.getAllPositions());
        return true;
      }
    };
  },
  // groups, facilities, regions
  searchGroupsAndFacilities(
    text: string,
    page: number,
    resultsPerPage: number,
  ): AppThunk<Promise<GroupFacilityRegionSearchResponse | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/search/groupsRegionsFacilities`,
        {
          params: { page, resultsPerPage, text },
        },
      );

      if (status !== 200) {
        dispatch(
          uiActions.showError(
            "Failed to retrieve groups and facilities search results",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  getGroup(id: number): AppThunk<Promise<Group | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/groups/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve group"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitGroup(
    values: Group,
    sendUserEmails?: boolean,
  ): AppThunk<Promise<Group | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/groups`, values, {
        params: { sendUserEmails },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save group",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Group saved"));
        dispatch(sysActions.getAllGroups());
        return data;
      }
    };
  },
  submitGroupStatus(
    id: number,
    groupStatus: string,
  ): AppThunk<Promise<Group | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/groups/${id}/status`,
        groupStatus,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save group status",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Group status saved"));
        dispatch(sysActions.getAllGroups());
        return data;
      }
    };
  },
  submitGroupSettings(
    id: number,
    settings: GroupSettings,
    settingName?: string,
  ): AppThunk<Promise<Group | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/groups/${id}/settings`,
        settings,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to save ${settingName || "group settings"}`,
          ),
        );
        return null;
      } else {
        await dispatch(sysActions.getAllGroups());
        dispatch(
          uiActions.showSuccess(`${settingName || "Group settings"} saved`),
        );
        return data;
      }
    };
  },
  getGroupUsersByClaim(
    groupId: number,
    claimValue: string,
    usersDescription: string,
  ): AppThunk<Promise<BasicUser[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/users/byClaim", {
        params: { groupId, claimValue },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            `Failed to retrieve group ${usersDescription} users`,
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  getRegion(id: number): AppThunk<Promise<Region | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/regions/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve region"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitRegion(
    values: Region,
    sendUserEmails?: boolean,
  ): AppThunk<Promise<Region | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/regions`, values, {
        params: { sendUserEmails },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save region",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Region saved"));
        dispatch(sysActions.getAllRegions());
        return data;
      }
    };
  },
  submitRegionStatus(
    id: number,
    regionStatus: string,
  ): AppThunk<Promise<Region | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/regions/${id}/status`,
        regionStatus,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save region status",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Region status saved"));
        dispatch(sysActions.getAllRegions());
        return data;
      }
    };
  },
  getFacility(
    id: number,
    withUsers?: boolean,
  ): AppThunk<Promise<Facility | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/facilities/${id}`, {
        params: { withUsers },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve facility"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitFacility(
    values: Facility,
    sendUserEmails?: boolean,
    submissionType = "",
  ): AppThunk<Promise<Facility | null>> {
    const submissionDescription = submissionType ? ` ${submissionType}` : "";
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/facilities`, values, {
        params: { sendUserEmails },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to save facility${submissionDescription}`,
          ),
        );
        return null;
      } else {
        const userType = authSelectors.userType(getState());
        if (userType === PortalUserTypes.CcgAdmin) {
          dispatch(
            uiActions.showSuccess(`Facility${submissionDescription} saved`),
          );
        }
        dispatch(sysActions.getAllFacilities());
        return data;
      }
    };
  },
  submitFacilityStatus(
    id: number,
    facilityStatus: string,
  ): AppThunk<Promise<Facility | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/facilities/${id}/status`,
        facilityStatus,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save facility status",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Facility status saved"));
        dispatch(sysActions.getAllFacilities());
        return data;
      }
    };
  },
  submitFacilityNote(
    facilityId: number,
    values: FacilityNote,
  ): AppThunk<Promise<FacilityNote | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/facilities/${facilityId}/notes`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save facility note",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Facility note saved"));
        dispatch(listsActions.refreshList(ListTypes.facilityNotes));
        return data;
      }
    };
  },
  deleteFacilityNote(
    facilityId: number,
    noteId: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/facilities/${facilityId}/notes/${noteId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete facility note",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Facility note deleted"));
        dispatch(listsActions.refreshList(ListTypes.facilityNotes));
        return true;
      }
    };
  },
  // policies
  getPolicy(id: number): AppThunk<Promise<Policy | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/policies/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitPolicy(values: Policy): AppThunk<Promise<Policy | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/policies`, values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to save policy${values.id ? " settings" : ""}`,
          ),
        );
        return null;
      } else {
        dispatch(
          uiActions.showSuccess(`Policy${values.id ? " settings" : ""} saved`),
        );
        dispatch(sysActions.getAllPolicies());
        return data;
      }
    };
  },
  deletePolicy(policyId: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(`/policies/${policyId}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete policy",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Policy deleted"));
        dispatch(sysActions.getAllPolicies());
        return true;
      }
    };
  },
  unpublishPolicy(policyId: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${policyId}/unpublish`,
        null,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to revert policy to draft",
          ),
        );
        return false;
      } else {
        return true;
      }
    };
  },
  bulkUpdatePolicy(
    id: number,
    values: BulkPolicyUpdate,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${id}/bulkUpdate`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 202) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to submit policy versions bulk update",
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            "Policy versions bulk update submitted" +
              (values.publish
                ? " - You will receive an email when publishing is complete."
                : ""),
          ),
        );
        dispatch(listsActions.refreshList(ListTypes.policyVersions));
        return true;
      }
    };
  },
  publishPoliciesForFacility(facilityId: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/byFacility/${facilityId}/publish`,
        undefined,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 202) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to submit facility policies for publishing",
          ),
        );
        return false;
      } else {
        return true;
      }
    };
  },
  getPolicyVersions(
    id: number,
    showLoading = true,
  ): AppThunk<Promise<BasicPolicyVersion[] | null>> {
    return async (dispatch) => {
      if (showLoading) dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/policies/${id}/versions/basic`,
      );
      if (showLoading) dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy versions"));
        return null;
      } else {
        return data;
      }
    };
  },
  getPolicyVersion(
    policyId: number,
    versionId: number,
  ): AppThunk<Promise<PolicyVersion | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/policies/${policyId}/versions/${versionId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy version"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitPolicyVersion(
    policyId: number,
    values: PolicyVersion,
    refreshList = true,
  ): AppThunk<Promise<PolicyVersion | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${policyId}/versions`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save policy version",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Policy version saved"));
        if (refreshList) {
          dispatch(listsActions.refreshList(ListTypes.policyVersions));
        }
        return data;
      }
    };
  },
  deletePolicyVersion(
    policyId: number,
    versionId: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/policies/${policyId}/versions/${versionId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete policy version",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Policy version deleted"));
        dispatch(listsActions.refreshList(ListTypes.policyVersions));
        return true;
      }
    };
  },
  publishPolicyVersion(
    policyId: number,
    versionId: number,
    values: Partial<{
      facilityIds: number[];
      stateIds: number[];
      publishChanges: boolean;
      notifyFacilities: boolean;
    }> = {},
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${policyId}/versions/${versionId}/publish`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 202) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to submit policy version for publishing",
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            "Policy version publishing -  You will receive an email when publishing is complete.",
          ),
        );

        // because the publish endpoint does not await execution of the actual publish, when refreshing the list immediately after submitting the publish, we set the publishStatus of the version to Publishing to ensure that it will reflect the recent action even if the api has not yet updated the db record
        const listResultsModifier = (listResults: PolicyVersionRecord[]) =>
          listResults.map((r) =>
            r.id === versionId &&
            r.publishStatus !== PolicyVersionPublishStatuses.Publishing
              ? {
                  ...r,
                  publishStatus: PolicyVersionPublishStatuses.Publishing,
                  publishStatusDisplay: PolicyVersionPublishStatuses.Publishing,
                }
              : r,
          );
        dispatch(
          listsActions.refreshList(
            ListTypes.policyVersions,
            listResultsModifier,
          ),
        );

        return true;
      }
    };
  },
  unpublishPolicyVersion(
    policyId: number,
    versionId: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${policyId}/versions/${versionId}/unpublish`,
        null,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to unpublish policy version",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Policy version unpublished"));
        dispatch(listsActions.refreshList(ListTypes.policyVersions));
        return true;
      }
    };
  },
  republishPolicyDocument(
    policyId: number,
    documentId: number,
    notifyFacility = false,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/${policyId}/policyDocs/${documentId}/republish`,
        null,
        { params: { notifyFacility } },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 202) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to submit policy document for publishing",
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            "Policy document publishing - You will receive an email when publishing is complete.",
          ),
        );

        // because the publish endpoint does not await execution of the actual publish, when refreshing the list immediately after submitting the publish, we set the publishStatus of the log to Publishing to ensure that it will reflect the recent action even if the api has not yet updated the db record
        const listResultsModifier = (
          listResults: PolicyDocumentPublishLogRecord[],
        ) =>
          listResults.map((r) =>
            r.id === documentId &&
            r.publishStatus !== PolicyDocumentPublishStatuses.Publishing
              ? {
                  ...r,
                  dateTime: new Date().toString(),
                  publishStatus: PolicyDocumentPublishStatuses.Publishing,
                  publishStatusDisplay:
                    PolicyDocumentPublishStatuses.Publishing,
                }
              : r,
          );
        dispatch(
          listsActions.refreshList(
            ListTypes.policyPublishLogs,
            listResultsModifier,
          ),
        );

        return true;
      }
    };
  },
  cancelLongPublishingPolicies(): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        "policies/cancelLongPublishing",
        null,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to cancel long-publishing policy documents",
          ),
        );
        return false;
      } else {
        if (data === 0) {
          dispatch(
            uiActions.showError("No long-publishing policy documents found"),
          );
        } else {
          dispatch(
            uiActions.showSuccess(
              `${data.toLocaleString()} ${pluralizeText(
                "publishing policy document",
                data,
              )} cancelled`,
            ),
          );
        }
        return true;
      }
    };
  },
  getPolicyVersionPreview(
    policyId: number,
    versionId: number,
  ): AppThunk<Promise<string | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/policies/${policyId}/versions/${versionId}/preview`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  submitPolicyCategory(
    values: PolicyCategory,
  ): AppThunk<Promise<PolicyCategory | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/policies/categories`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save category",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Category saved"));
        dispatch(sysActions.getPolicyCategories());
        return data;
      }
    };
  },
  deletePolicyCategory(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/policies/categories/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete category",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Category deleted"));
        dispatch(sysActions.getPolicyCategories());
        return true;
      }
    };
  },
  // resources
  getResource(id: number): AppThunk<Promise<Resource | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/resources/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve resource"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitResource(values: Resource): AppThunk<Promise<Resource | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/resources`, values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save resource",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Resource saved"));
        return data;
      }
    };
  },
  submitResourceCategory(
    values: ResourceCategory,
  ): AppThunk<Promise<ResourceCategory | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/resources/categories`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save category",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Category saved"));
        dispatch(sysActions.getResourceCategories());
        return data;
      }
    };
  },
  deleteResourceCategory(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/resources/categories/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete category",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Category deleted"));
        dispatch(sysActions.getResourceCategories());
        return true;
      }
    };
  },
  deleteRequiredDocumentSubmission(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/resources/requiredDocuments/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete submission",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Submission deleted"));
        dispatch(
          listsActions.refreshList(ListTypes.requiredDocumentSubmissions),
        );
        return true;
      }
    };
  },
  // trainings
  getTraining(id: number): AppThunk<Promise<Training | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/trainings/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve training"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitTraining(values: Training): AppThunk<Promise<Training | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/trainings`, values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save training",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Training saved"));
        return data;
      }
    };
  },
  getTrainingPacket(id: number): AppThunk<Promise<TrainingPacket | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/trainings/packets/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve training packet"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitTrainingPacket(
    values: TrainingPacket,
  ): AppThunk<Promise<TrainingPacket | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/trainings/packets`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save training packet",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Training packet saved"));
        return data;
      }
    };
  },
  // audits
  getAuditTemplate(id: number): AppThunk<Promise<AuditTemplate | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/audits/templates/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit template"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditTemplate(
    values: AuditTemplate,
    updateSentAudits = false,
  ): AppThunk<Promise<AuditTemplate | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/templates`,
        values,
        { params: { updateSentAudits } },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save audit template",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Audit template saved"));
        dispatch(sysActions.getAllAuditTemplates());
        return data;
      }
    };
  },
  getInProgressAuditsByTemplate(
    templateId: number,
  ): AppThunk<Promise<BasicAudit[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("audits/basic", {
        params: {
          auditTemplateId: templateId,
          inProgress: true,
        },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError("Failed to retrieve in progress audits list"),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  sendAudits(values: {
    facilityIDs: number[];
    auditTemplateIDs: number[];
    dueDate: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/audits/send`, values);
      dispatch(uiActions.setLoading(false));

      const auditsCount = values.auditTemplateIDs.length;
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to send ${pluralizeText("audit", auditsCount)}`,
          ),
        );
        return false;
      } else {
        return true;
      }
    };
  },
  submitAudit(
    values: Partial<Audit>,
    sendDueDateChangeEmail?: boolean,
  ): AppThunk<Promise<Audit | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("audits", values, {
        params: { sendDueDateChangeEmail },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save audit update",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Audit saved"));
        return data;
      }
    };
  },
  submitAuditGiftCardStatus(
    id: number,
    values: {
      status: string;
      trackingNumber?: string;
    },
    refreshInboxList?: boolean,
  ): AppThunk<Promise<Audit | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `audits/${id}/giftCardStatus`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to update audit gift card details",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Audit gift card details saved"));
        if (refreshInboxList) {
          dispatch(listsActions.refreshList(ListTypes.auditInbox));
        }
        return data;
      }
    };
  },
  resetAudit(
    id: number,
    values: {
      dueDate: string;
      notifyFacilities: boolean;
    },
  ): AppThunk<Promise<Audit | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `audits/${id}/reset`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to reset audit",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Audit reset"));
        return data;
      }
    };
  },
  sendAuditReminder(
    id: number,
    customMessage: string,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `audits/${id}/sendReminder`,
        {
          customMessage,
        },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to send audit reminder",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Audit reminder sent"));
        return true;
      }
    };
  },
  getAuditsExport(params: {
    groupIDs: number[];
    regionIDs: number[];
    facilityIDs: number[];
  }): AppThunk<Promise<AuditExportRecord[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("audits/export", params);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audits for export"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditVersionAnnotations(
    auditId: number,
    versionId: number,
    annotations: string,
  ): AppThunk<Promise<AuditVersion | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/${auditId}/versions/${versionId}/annotations`,
        annotations,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save audit",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditCommentsResolved(
    auditId: number,
    { commentThreadIds = [] as Number[], isResolved = false },
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/${auditId}/comments/resolve`,
        {
          commentThreadIds,
          isResolved,
        },
      );
      dispatch(uiActions.setLoading(false));

      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to resolve ${pluralizeText(
                "comment",
                commentThreadIds.length,
              )}`,
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            `${pluralizeText("Comment", commentThreadIds.length)} saved`,
          ),
        );
        return true;
      }
    };
  },
  // announcements
  getAnnouncement(id: number): AppThunk<Promise<Announcement | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/announcements/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve announcement"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitAnnouncement(
    values: Announcement,
  ): AppThunk<Promise<Announcement | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/announcements`, values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save announcement",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Announcement saved"));
        return data;
      }
    };
  },
};
