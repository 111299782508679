import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  adminActions,
  Position,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik } from "formik";
import { GridItemStyled, RootStyled } from "./PositionRoleForm.styles";
import { CloseIcon, SelectField, TextField } from "../../../../components";
import {
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useLocation,
  useRedirect,
} from "../../../../lib";
import * as yup from "yup";

const newPosition: Position = {
  name: "",
  defaultRoleID: null,
};

const validationSchema = yup.object({
  name: yup
    .string()
    .max(50, "Position name cannot exceed 50 characters")
    .required("Position name is required"),
  defaultRoleID: yup.number().nullable().required("Default role is required"),
});

export const PositionForm = React.memo(
  /**
   *
   */
  function PositionForm() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isNew = location.query.positionId === "new";
    const positionId = isNew ? "" : location.query.positionId;

    const [initialValues, setInitialValues] = useState<Position>(newPosition);

    const roleOptions = useSelector(sysSelectors.allRoles);

    const { redirectUrl, setRedirectUrl } = useRedirect();

    const getNavigationUrl = useCallback(
      (id?: number) => {
        return Navigation.url(location.pathname, {
          query: { ...location.query, positionId: id },
        });
      },
      [location.pathname, location.query],
    );

    useEffect(() => {
      (async () => {
        if (positionId) {
          // if roleId has been updated with a redirect url, reset the url
          if (redirectUrl) {
            setRedirectUrl("");
          }
          // otherwise fetch the resource
          else {
            const position = await dispatch(
              adminActions.getPosition(Number(positionId)),
            );
            if (position) {
              setInitialValues(replaceNullProps(position));
            }
          }
        } else {
          // if positionId has been cleared, reset form values
          setInitialValues(newPosition);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, positionId]);

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      async onSubmit(values) {
        const savedPosition = await dispatch(
          adminActions.submitPosition(replaceEmptyProps(values)),
        );
        if (savedPosition) {
          setInitialValues(replaceNullProps(savedPosition));

          // reset route upon new position creation
          if (isNew) {
            setRedirectUrl(getNavigationUrl(savedPosition.id));
          }
        }
      },
    });

    const onClose = useCallback(() => {
      Navigation.replace(getNavigationUrl());
    }, [getNavigationUrl]);

    return (
      <RootStyled>
        <Form
          form={form}
          noPrompt={!!redirectUrl}
          promptCancelText="Back to Position"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Configure position
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            <Tooltip
              title={
                initialValues.isPreset ? "Position name cannot be updated" : ""
              }
            >
              <GridItemStyled item sx={{ width: "100%", mb: 1 }}>
                <TextField
                  disabled={initialValues.isPreset}
                  name="name"
                  label="Position"
                />
              </GridItemStyled>
            </Tooltip>
            <GridItemStyled item sx={{ width: "100%" }}>
              <SelectField
                clearable={false}
                name="defaultRoleID"
                label="Default access level"
                options={roleOptions}
              />
            </GridItemStyled>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "end", mt: 4 }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </Form>
      </RootStyled>
    );
  },
);
