import React from "react";
import { styled } from "@mui/material/styles";
import { SwipeableDrawer, Box, IconButton } from "@mui/material";
import DrawerMenu from "./DrawerMenu";
import {
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { CloseIcon } from "../../../components";

const DrawerStyled = styled(SwipeableDrawer)({
  "& .MuiDrawer-paperAnchorLeft": {
    width: "90vw",
  },
});

export default function MobileDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <Box>
      <DrawerStyled
        anchor="left"
        open={menuOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <Box sx={{ p: 1, mb: 4 }}>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DrawerMenu />
      </DrawerStyled>
    </Box>
  );
}
