import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  BasicFacility,
  FacilityRecord,
  sysActions,
  useAppDispatch,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import {
  AssignmentLateIcon,
  ButtonMenu,
  FindInPageIcon,
  ListActionsContainerStyled,
  ListActionsMainStyled,
  ListActionsProps,
  MoreVertIcon,
  NewReleasesIcon,
  PaginationContainer,
  SearchInput,
} from "../../../../components";
import {
  FacilityCardStyled,
  FacilityStatTagStyled,
  ReportChangeLinkStyled,
} from "./FacilitiesList.styles";
import { GroupFacilityRegionLogosDisplay } from "../../../admin/components/groupsAndFacilities/general/GroupFacilityRegionLogosDisplay"; //TODO: move this
import { Navigation, pluralizeText, useLocation } from "../../../../lib";
import { FacilityAdminPages } from "../..";
import { AuditStatuses } from "../../../../lib/constants";

const filterQueryKey = {
  text: "search",
};

function FacilitiesListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { search = "" } = query;
  return (
    <ListActionsContainerStyled sx={{ borderBottom: "none" }}>
      <ListActionsMainStyled sx={{ paddingTop: "0px" }}>
        <Typography variant="h6">Your facilities</Typography>
        <SearchInput
          variant="outlined"
          placeholder="Search facilities"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
          sx={{ mr: 2 }}
        />
      </ListActionsMainStyled>
    </ListActionsContainerStyled>
  );
}

function FacilitiesListItem({ facility }: { facility: FacilityRecord }) {
  const {
    complianceOfficers,
    governingBody,
    groupID,
    groupLogoUrl,
    groupName,
    id,
    latestAuditStatus,
    logoUrl,
    name,
    newAnnouncementsCount,
    openAuditsCount,
    quickAccessResources,
    regionID,
    regionName,
    requiredDocumentsCount,
    stateID,
    stateName,
    status,
    type,
  } = facility;

  const dispatch = useAppDispatch();
  const location = useLocation();

  const navToFacilityDetail = useCallback(
    (url: string) => {
      const facilityFilter: BasicFacility = {
        id,
        groupID,
        groupName,
        name,
        regionID,
        stateID: stateID || undefined,
        stateName,
        status,
        type,
      };
      dispatch(sysActions.setFacilityFilter(facilityFilter));
      Navigation.go(url);
    },
    [
      dispatch,
      groupID,
      groupName,
      id,
      name,
      regionID,
      stateID,
      stateName,
      status,
      type,
    ],
  );

  const filterAnnouncementsList = useCallback(() => {
    Navigation.replace(location.pathname, {
      query: {
        ...location.query,
        facilities: [facility.id],
      },
    });
  }, [facility.id, location.pathname, location.query]);

  return (
    <FacilityCardStyled>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Box display="flex">
          <GroupFacilityRegionLogosDisplay
            groupLogoUrl={groupLogoUrl}
            logoUrl={logoUrl}
            name={name}
          />
          <Box ml={2}>
            <Typography variant="subtitle1">{name}</Typography>
            <Typography color="text.secondary" variant="caption">
              {groupName}
              {regionName && ` · ${regionName}`}
            </Typography>
          </Box>
        </Box>
        <ButtonMenu
          button={
            <Button
              color="inherit"
              size="large"
              sx={{ minWidth: "36px", padding: "8px 0" }}
            >
              <MoreVertIcon sx={{ height: "20px" }} />
            </Button>
          }
          menuItems={[
            {
              label: (
                <ReportChangeLinkStyled
                  href={`mailto:info@compliancecgllc.com?subject=${encodeURIComponent(
                    "Report CO or GB update to CCG",
                  )}`}
                  target="_blank"
                >
                  Report CO or GB change to CCG
                </ReportChangeLinkStyled>
              ),
            },
            ...quickAccessResources.map((r) => ({
              label: r.name,
              onClick: () =>
                navToFacilityDetail(
                  Navigation.url(FacilityAdminPages.resource, {
                    params: { id: r.id },
                  }),
                ),
            })),
          ]}
        />
      </Box>
      <Box>
        {complianceOfficers.length > 0 && (
          <Box display="flex" mb="8px">
            <Typography variant="subtitle2" mr="4px">
              CO:
            </Typography>
            <Typography variant="body2">
              {complianceOfficers.map((co) => co.name).join(", ")}
            </Typography>
          </Box>
        )}
        {governingBody.length > 0 && (
          <Box display="flex" mb="12px">
            <Typography variant="subtitle2" mr="4px">
              GB:
            </Typography>
            <Typography variant="body2">
              {governingBody.map((gb) => gb.name).join(", ")}
            </Typography>
          </Box>
        )}
        <Box display="flex">
          {openAuditsCount > 0 && (
            <FacilityStatTagStyled
              onClick={() =>
                navToFacilityDetail(FacilityAdminPages.audits.path)
              }
              type="audits"
            >
              <FindInPageIcon />
              <Typography variant="body2">
                {latestAuditStatus === AuditStatuses.Completed ||
                latestAuditStatus === AuditStatuses.CompletedCertificateSent
                  ? `${openAuditsCount.toLocaleString()} Past ${pluralizeText(
                      "Open Audit",
                      openAuditsCount,
                    )}`
                  : "Current Audit Outstanding"}
              </Typography>
            </FacilityStatTagStyled>
          )}
          {requiredDocumentsCount > 0 && (
            <FacilityStatTagStyled
              onClick={() =>
                navToFacilityDetail(FacilityAdminPages.requiredDocuments.path)
              }
              type="requiredDocs"
            >
              <AssignmentLateIcon />
              <Typography variant="body2">
                {requiredDocumentsCount.toLocaleString()}{" "}
                {pluralizeText("Required Document", requiredDocumentsCount)}
              </Typography>
            </FacilityStatTagStyled>
          )}
          {newAnnouncementsCount > 0 && (
            <FacilityStatTagStyled
              onClick={filterAnnouncementsList}
              type="announcements"
            >
              <NewReleasesIcon />
              <Typography variant="body2">
                {newAnnouncementsCount.toLocaleString()}{" "}
                {pluralizeText("New Announcement", newAnnouncementsCount)}
              </Typography>
            </FacilityStatTagStyled>
          )}
        </Box>
      </Box>
    </FacilityCardStyled>
  );
}

export const FacilitiesList = React.memo(
  /**
   *
   */
  function FacilitiesList() {
    return (
      <Box>
        <PaginationContainer
          actions={<FacilitiesListActions />}
          defaultOrderBy="name"
          defaultResultsPerPage={25}
          filterQueryKey={filterQueryKey}
          hidePaginationForMinRows={true}
          name="facilities"
          paginationQueryKeyPrefix="f" // differentiate pagination query params from announcements list
          renderResult={(row: FacilityRecord) => (
            <FacilitiesListItem key={row.id} facility={row} />
          )}
          type={ListTypes.facilities}
        />
      </Box>
    );
  },
);
