import React, { useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Typography } from "@mui/material";
import {
  BasicStatusChipStyled,
  CheckboxInput,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
} from "../../../../components";
import { Navigation, RouterLink } from "../../../../lib";
import { FilterListIcon, ListActionsProps } from "../../../../components";
import { AdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";
import { TrainingPacketRecord } from "../../../../state";

const filterQueryKey = {
  includeInactive: "showInactive",
  text: "search",
};

function TrainingPacketsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { search = "", showInactive = false } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!search);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Training packets</Typography>
        <Box>
          <CheckboxInput
            checked={!!showInactive}
            label="Show inactive"
            name="showInactive"
            onChange={onFilterChange}
          />
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            component={RouterLink}
            to={AdminPages.addTrainingPacket.path}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
          >
            Add packet
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search packets"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: ({ row }: { row: TrainingPacketRecord }) => (
      <BasicStatusChipStyled
        label={row.status}
        size="small"
        status={row.status}
      />
    ),
  },
];

export const TrainingPacketsList = React.memo(
  /**
   *
   */
  function TrainingPacketsList() {
    const onRowClick = useCallback(({ row }: { row: TrainingPacketRecord }) => {
      Navigation.go(AdminPages.editTrainingPacket, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<TrainingPacketsListActions />}
        columns={columns}
        filterQueryKey={filterQueryKey}
        name="training packets"
        onRowClick={onRowClick}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.trainingPackets}
      />
    );
  },
);
