import React, { useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import {
  CheckboxInput,
  CheckIcon,
  HealingIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  SelectInput,
  ToggleInput,
} from "../../../../components";
import { ListActionsProps } from "../../../../components";
import { ListTypes } from "../../../../state/lists/state";
import { AuditInboxRecord, sysSelectors, useSelector } from "../../../../state";
import {
  asArray,
  formatDate,
  Navigation,
  RouterLink,
  useLocation,
} from "../../../../lib";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import { AuditStatusTimeline } from "./details/AuditStatusTimeline";
import { AuditGiftCardActions } from "./details/AuditGiftCardActions";
import { AuditStatusChipStyled } from "./AuditsLists.styles";
import {
  AuditGiftCardStatuses,
  AuditStatuses,
} from "../../../../lib/constants";
import { AdminPages } from "../..";
import { isToday } from "date-fns";

const filterQueryKey = {
  auditStatus: "status",
  facilityIDs: "facilities",
  giftCardStatuses: "giftCardStatuses",
  includeInactive: "showInactive",
  text: "search",
};

function AuditsInboxListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const {
    facilities = [],
    giftCardStatuses = [],
    search = "",
    showInactive = false,
    status = "",
  } = query;

  const onStatusFilterChange = useCallback(
    (name: string, value: string) => {
      onFilterChange(name, value, { giftCardStatuses: [] }); // reset giftCardStatuses on status filter change
    },
    [onFilterChange],
  );

  const onGiftCardStatusesFilterChange = useCallback(
    (giftCardStatus: string, checked: boolean) => {
      const giftCardStatusesArray = asArray(giftCardStatuses);
      onFilterChange(
        "giftCardStatuses",
        checked
          ? [...giftCardStatusesArray, giftCardStatus]
          : giftCardStatusesArray.filter((s) => s !== giftCardStatus),
      );
    },
    [giftCardStatuses, onFilterChange],
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Box display="flex">
          <Typography variant="h5">Inbox</Typography>
          <ToggleInput
            name="status"
            onChange={onStatusFilterChange}
            options={[
              { id: AuditStatuses.AwaitingGrade, name: "Awaiting grade" },
              { id: AuditStatuses.ReadyForReview, name: "Ready for review" },
              { id: AuditStatuses.FollowUpNeeded, name: "Follow-up needed" },
              {
                id: AuditStatuses.CompletedCertificateSent,
                name: "Gift card list",
              },
            ]}
            value={status}
            sx={{ ml: 2 }}
          />
        </Box>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled>
        <SearchInput
          variant="outlined"
          placeholder="Search audits"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
          sx={{ mr: 2 }}
        />
        <SelectInput
          label="Facility"
          name="facilities"
          value={asArray(facilities)}
          onChange={onFilterChange}
          options={facilityOptions}
          groupOpts={true}
          multiple={true}
          sx={{ mr: 2 }}
        />
        <CheckboxInput
          checked={!!showInactive}
          label="Show inactive"
          name="showInactive"
          onChange={onFilterChange}
        />
        {status === AuditStatuses.CompletedCertificateSent && (
          <>
            <CheckboxInput
              checked={asArray(giftCardStatuses).includes(
                AuditGiftCardStatuses.Sent,
              )}
              label="Show gift card sent"
              name={AuditGiftCardStatuses.Sent}
              onChange={onGiftCardStatusesFilterChange}
              sx={{ ml: 1 }}
            />
            <CheckboxInput
              checked={asArray(giftCardStatuses).includes(
                AuditGiftCardStatuses.NotNeeded,
              )}
              label="Show gift card not needed"
              name={AuditGiftCardStatuses.NotNeeded}
              onChange={onGiftCardStatusesFilterChange}
              sx={{ ml: 1 }}
            />
          </>
        )}
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const AuditsInboxList = React.memo(
  /**
   *
   */
  function AuditsInboxList() {
    const {
      query: { status },
    } = useLocation();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Audit",
          flex: 2,
        },
        {
          field: "facilityName",
          headerName: "Facility",
          flex: 2,
          renderCell: ({ row }: { row: AuditInboxRecord }) => (
            <Box>
              <Link
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(AdminPages.facilityAudits, {
                  params: { id: row.facilityID },
                })}
              >
                {row.facilityName}
              </Link>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {row.groupName}
              </Typography>
            </Box>
          ),
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1.5,
          renderCell: ({ row }: { row: AuditInboxRecord }) => (
            <AuditStatusChipStyled
              label={row.statusDisplay}
              size="small"
              status={row.status}
            />
          ),
        },
        {
          field: "submittedOn",
          headerName: "Date submitted",
          flex: 1,
          valueFormatter: ({ value }) => {
            const date = new Date(value);
            return isToday(date)
              ? formatDate(value, "h:mm aa")
              : formatDate(value, "MMM d");
          },
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "submittedBy",
          headerName: "Submitted by",
          flex: 1,
        },
        {
          field: "hasCorrectiveActions",
          headerName: "CAP",
          flex: 0.75,
          renderCell: ({ row }: { row: AuditInboxRecord }) =>
            row.hasCorrectiveActions ? (
              <IconButton
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(AdminPages.auditCap, {
                  params: { id: row.id },
                })}
              >
                <HealingIcon color="primary" />
              </IconButton>
            ) : (
              ""
            ),
        },
        {
          field: "hasQuestions",
          headerName: "Questions",
          flex: 0.75,
          renderCell: ({ row }: { row: AuditInboxRecord }) =>
            row.hasQuestions ? (
              <Tooltip
                title={
                  row.hasUnresolvedQuestions ? "" : "All questions resolved"
                }
              >
                <CheckIcon
                  sx={{
                    color: row.hasUnresolvedQuestions
                      ? "text.default"
                      : "neutral.main",
                  }}
                />
              </Tooltip>
            ) : (
              ""
            ),
        },
        ...(!status || status === AuditStatuses.CompletedCertificateSent
          ? [
              {
                field: "giftCardStatus",
                headerName: "Gift card",
                flex: 0.75,
                renderCell: ({ row }: { row: AuditInboxRecord }) =>
                  row.status === AuditStatuses.CompletedCertificateSent ? (
                    <AuditGiftCardActions audit={row} inboxView={true} />
                  ) : (
                    ""
                  ),
              },
            ]
          : []),
        {
          field: "statusHistory",
          headerName: "Timeline",
          flex: 1,
          renderCell: ({ row }: { row: AuditInboxRecord }) => (
            <AuditStatusTimeline history={row.statusHistory} />
          ),
          sortable: false,
        },
      ],
      [status],
    );

    const onRowClick = useCallback(({ row }: { row: AuditInboxRecord }) => {
      Navigation.go(AdminPages.audit, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<AuditsInboxListActions />}
        columns={columns}
        defaultOrderBy="submittedOn"
        defaultOrderDirection="desc"
        filterQueryKey={filterQueryKey}
        name="audits"
        onRowClick={onRowClick}
        rowHeight={84}
        stickyHeader={true}
        type={ListTypes.auditInbox}
      />
    );
  },
);
