import React from "react";
import { GroupsList } from "./components";

export const GroupsPage = React.memo(
  /**
   *
   */
  function GroupsPage() {
    return <GroupsList />;
  },
);
