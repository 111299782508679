import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  DomainIcon,
  LocalHospitalIcon,
  PlaceIcon,
} from "../../../../../components";
import { sysSelectors, useSelector } from "../../../../../state";
import { Navigation } from "../../../../../lib";
import { AdminPages } from "../../..";
import {
  AvatarStyled,
  CardStyled,
  TextContainerStyled,
} from "./GroupsFacilitiesRegionsDashboard.styles";

export const GroupsFacilitiesRegionsDashboard = React.memo(
  /**
   *
   */
  function GroupsFacilitiesRegionsDashboard() {
    const groups = useSelector(sysSelectors.activeGroups);
    const regions = useSelector(sysSelectors.activeRegions);
    const facilities = useSelector(sysSelectors.activeFacilities);

    const dashboardItems = useMemo(
      () => [
        {
          title: "Groups",
          count: groups.length,
          color: "primary.light",
          icon: DomainIcon,
          url: AdminPages.groups.path,
        },
        {
          title: "Regions",
          count: regions.length,
          color: "warning.main",
          icon: PlaceIcon,
          url: AdminPages.regions.path,
        },
        {
          title: "Facilities",
          count: facilities.length,
          color: "error.light",
          icon: LocalHospitalIcon,
          url: AdminPages.facilities.path,
        },
      ],
      [facilities, groups, regions],
    );

    return (
      <Box display="flex">
        {dashboardItems.map(({ color, count, icon: Icon, title, url }, i) => (
          <CardStyled
            key={title}
            onClick={() => Navigation.go(url)}
            raised={true}
            sx={{ ml: i ? 4 : 0 }}
          >
            <AvatarStyled variant="rounded" sx={{ backgroundColor: color }}>
              <Icon />
            </AvatarStyled>
            <TextContainerStyled>
              <Typography variant="h6">
                {count ? count.toLocaleString() : ""}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {title}
              </Typography>
            </TextContainerStyled>
          </CardStyled>
        ))}
      </Box>
    );
  },
);
