import React from "react";
import { authSelectors, useSelector } from "../../../state";
import { ButtonMenu, PersonIcon } from "../../../components";
import { ProfileAvatarStyled } from "./AppBar.styles";

const ProfileMenu = () => {
  const userInfo = useSelector(authSelectors.userInfo);

  return (
    <ButtonMenu
      button={
        <ProfileAvatarStyled>
          {userInfo.firstName?.charAt(0) || <PersonIcon />}
        </ProfileAvatarStyled>
      }
      menuItems={[
        { label: "Settings", linkTo: "/profile" },
        { label: "Log out", linkTo: "/auth/logout" },
      ]}
    />
  );
};

export default ProfileMenu;
