import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BasicAuditTemplate,
  BasicFacility,
  BasicGroup,
  BasicPolicy,
  BasicPosition,
  BasicRegion,
  BasicRole,
  FacilityFileCategory,
  PolicyCategory,
  ResourceCategory,
} from "../types";

export interface SysState {
  hydrated: boolean;
  systemSettings: any;
  allRoles: BasicRole[];
  allPositions: BasicPosition[];
  allGroups: BasicGroup[];
  allRegions: BasicRegion[];
  allFacilities: BasicFacility[];
  allPolicies: BasicPolicy[];
  allAuditTemplates: BasicAuditTemplate[];
  policyCategories: PolicyCategory[];
  resourceCategories: ResourceCategory[];
  facilityFileCategories: FacilityFileCategory[];
  facilityFilter?: BasicFacility;
  groupFilter?: BasicGroup;
  pendingFacilitiesAlerted: boolean;
}

export const initialState: SysState = {
  hydrated: false,
  systemSettings: {},
  allRoles: [],
  allPositions: [],
  allGroups: [],
  allRegions: [],
  allFacilities: [],
  allPolicies: [],
  allAuditTemplates: [],
  policyCategories: [],
  resourceCategories: [],
  facilityFileCategories: [],
  facilityFilter: undefined,
  groupFilter: undefined,
  pendingFacilitiesAlerted: false,
};

export const sys = {
  ...createSlice({
    name: "sys",
    initialState,
    reducers: {
      resetSystemState() {
        return initialState;
      },
      setSystemHydrated(state) {
        state.hydrated = true;
      },
      setSystemSettings(state, { payload }: PayloadAction<any>) {
        state.systemSettings = payload || initialState.systemSettings;
      },
      setAllRoles(state, { payload }: PayloadAction<any>) {
        state.allRoles = payload || initialState.allRoles;
      },
      setAllPositions(state, { payload }: PayloadAction<any>) {
        state.allPositions = payload || initialState.allPositions;
      },
      setAllGroups(state, { payload }: PayloadAction<any>) {
        state.allGroups = payload || initialState.allGroups;
      },
      setAllFacilities(state, { payload }: PayloadAction<any>) {
        state.allFacilities = payload || initialState.allFacilities;
      },
      setAllRegions(state, { payload }: PayloadAction<any>) {
        state.allRegions = payload || initialState.allRegions;
      },
      setAllPolicies(state, { payload }: PayloadAction<any>) {
        state.allPolicies = payload || initialState.allPolicies;
      },
      setAllAuditTemplates(state, { payload }: PayloadAction<any>) {
        state.allAuditTemplates = payload || initialState.allAuditTemplates;
      },
      setPolicyCategories(state, { payload }: PayloadAction<any>) {
        state.policyCategories = payload || initialState.policyCategories;
      },
      setResourceCategories(state, { payload }: PayloadAction<any>) {
        state.resourceCategories = payload || initialState.resourceCategories;
      },
      setFacilityFileCategories(state, { payload }: PayloadAction<any>) {
        state.facilityFileCategories =
          payload || initialState.facilityFileCategories;
      },
      setFacilityFilter(
        state,
        { payload }: PayloadAction<BasicFacility | undefined>,
      ) {
        state.facilityFilter = payload || initialState.facilityFilter;
      },
      setGroupFilter(
        state,
        { payload }: PayloadAction<BasicGroup | undefined>,
      ) {
        state.groupFilter = payload || initialState.groupFilter;
      },
      setPendingFacilitiesAlerted(state, { payload }: PayloadAction<boolean>) {
        state.pendingFacilitiesAlerted = payload;
      },
    },
  }),
  persist: true,
};
