import React, { useEffect } from "react";
import { Link, Typography } from "@mui/material";
import { formatDate, Navigation, RouterLink } from "../../../../lib";
import { FacilityAdminPages } from "../../../facilityAdmin";
import { Audit, uiActions, useAppDispatch } from "../../../../state";
import { styled } from "@mui/material/styles";

const SubheaderStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "48px",
  marginBottom: "8px",
  "& a, & p": {
    fontSize: "12px",
  },
}));

interface AuditSubheaderProps {
  audit: Audit;
}

export const AuditSubheader = React.memo(
  /**
   *
   */
  function AuditSubheader({ audit }: AuditSubheaderProps) {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(uiActions.setPageHasSubtitle(true));
      return () => {
        dispatch(uiActions.setPageHasSubtitle(false));
      };
    }, [dispatch]);

    return (
      <SubheaderStyled>
        {audit.hasCorrectiveActionPlan && (
          <>
            <Link
              component={RouterLink}
              to={Navigation.url(FacilityAdminPages.auditCap, {
                params: { id: audit.id },
              })}
            >
              Related to Corrective Action Plan (CAP)
            </Link>
            <Typography color="text.secondary" margin="0 8px">
              ·
            </Typography>
          </>
        )}
        {audit.startedOn && (
          <Typography color="text.secondary">
            Started on {formatDate(audit.startedOn)}
          </Typography>
        )}
      </SubheaderStyled>
    );
  },
);
