import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Tooltip, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  AssignmentLateIcon,
  BuildIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  SelectInput,
} from "../../../../../components";
import { asArray, Navigation } from "../../../../../lib";
import { FilterListIcon, ListActionsProps } from "../../../../../components";
import {
  Facility,
  ResourceRecord,
  sysSelectors,
  useSelector,
} from "../../../../../state";
import { ListTypes } from "../../../../../state/lists/state";
import { ResourceTypes } from "../../../../../lib/constants";
import { AdminPages } from "../../..";
import { CopyLinkButton } from "../../../../../components/buttons/CopyLinkButton";
import { RequiredDocumentStatusText } from "../../../../adminShared/components";
import { FacilityAdminPages } from "../../../../facilityAdmin";

const filterQueryKey = {
  categoryIDs: "categories",
  type: "type",
  text: "search",
};

function FacilityResourcesListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { resourceTypes: resourceTypeOptions = [] } = useSelector(
    sysSelectors.systemSettings,
  );
  const resourceCategoryOptions = useSelector(sysSelectors.resourceCategories);

  const { categories = [], search = "", type = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(search || type || asArray(categories).length),
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Resources</Typography>
        <Button
          variant="text"
          color="primary"
          size="large"
          sx={{ ml: 1 }}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Filter <FilterListIcon sx={{ ml: 1 }} />
        </Button>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search resources"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Type"
            name="type"
            value={type}
            onChange={onFilterChange}
            options={resourceTypeOptions}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Category"
            name="categories"
            value={asArray(categories)}
            onChange={onFilterChange}
            options={resourceCategoryOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) => {
      const ResourceIcon =
        row.type === ResourceTypes.Tool
          ? BuildIcon
          : row.type === ResourceTypes.RequiredDocument
          ? AssignmentLateIcon
          : null;
      return (
        <Box display="flex" alignItems="center">
          {ResourceIcon && (
            <Tooltip title={row.typeDisplay}>
              <ResourceIcon sx={{ color: "text.secondary", mr: 1 }} />
            </Tooltip>
          )}
          {row.name}
        </Box>
      );
    },
  },
  {
    field: "categories",
    headerName: "Category",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <AbbreviatedListDisplay items={row.categories} />
    ),
    sortable: false,
  },
  {
    field: "requiredDocumentStatus",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <RequiredDocumentStatusText resource={row} />
    ),
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    width: 80,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <CopyLinkButton
        sx={{ color: "primary.main" }}
        tooltip="Copy resource link to share with system users. Note the link will not work for CCG admins."
        url={Navigation.fullUrl(FacilityAdminPages.resource, {
          params: { id: row.id },
        })}
      />
    ),
    sortable: false,
  },
];

interface FacilityResourcesListProps {
  facility: Facility;
}

export const FacilityResourcesList = React.memo(
  /**
   *
   */
  function FacilityResourcesList({ facility }: FacilityResourcesListProps) {
    const onRowClick = useCallback(({ row }: { row: ResourceRecord }) => {
      Navigation.go(AdminPages.editResource, { params: { id: row.id } });
    }, []);

    const listFilter = useMemo(
      () => ({ facilityIDs: [facility.id], requiredDocDetails: true }),
      [facility.id],
    );

    return (
      <List
        actions={<FacilityResourcesListActions />}
        columns={columns}
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        name="resources"
        onRowClick={onRowClick}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.resources}
      />
    );
  },
);
