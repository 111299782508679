import React, { useEffect, useState } from "react";
import { Navigation, useLocation } from "../../lib";
import { authActions, useAppDispatch } from "../../state";
import { AuthPages } from ".";
import { AuthCardStyled, TitleTextStyled } from "./Auth.styles";
import { ErrorTextStyled } from "../../components";

export const ConfirmEmailPage = React.memo(
  /**
   *
   */
  function ConfirmEmailPage() {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [error, setError] = useState(false);

    useEffect(() => {
      (async () => {
        const success = await dispatch(
          authActions.confirmEmail({
            token: location.query.token as string,
            email: location.query.email as string,
          }),
        );
        if (success) {
          const urlConfig = { query: { "email-conf": true } };
          Navigation.replace(AuthPages.login, urlConfig);
        } else {
          setError(true);
        }
      })();
    }, [dispatch, location.query.email, location.query.token]);

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">Confirm Email</TitleTextStyled>
        {error && (
          <ErrorTextStyled>
            Something went wrong and your email could not be confirmed. Check to
            ensure that the link you are using has not been tampered with and
            has not expired.
          </ErrorTextStyled>
        )}
      </AuthCardStyled>
    );
  },
);
