import React, { useCallback, useState } from "react";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import {
  PersonIcon,
  PhoneIcon,
  PlaceIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../../../../../components";
import {
  formatAddress,
  formatPhone,
  Navigation,
  RouterLink,
} from "../../../../../lib";
import {
  CardContainerStyled,
  CardStyled,
  SubtitleStyled,
} from "./GroupFacilityRegionSnapshot.styles";
import { EntityTypes, FacilityStatuses } from "../../../../../lib/constants";
import { AdminPages } from "../../..";
import { GroupFacilityRegion } from "../../../../../state";
import { GroupFacilityRegionStatusDropdown } from "./GroupFacilityRegionStatusDropdown";
import { GroupFacilityRegionLogosDisplay } from "./GroupFacilityRegionLogosDisplay";

interface GroupFacilityRegionSnapshotProps {
  details: GroupFacilityRegion;
  resetDetails: (details: GroupFacilityRegion) => void;
  type: string;
}

export const GroupFacilityRegionSnapshot = React.memo(
  /**
   *
   */
  function GroupFacilityRegionSnapshot({
    details,
    resetDetails,
    type,
  }: GroupFacilityRegionSnapshotProps) {
    const {
      address1,
      address2,
      city,
      groupID,
      groupName,
      groupLogoUrl,
      id,
      logoUrl,
      name,
      phone1,
      phone2,
      regionID,
      regionName,
      staffPassword,
      staffUserName,
      stateName,
      status,
      typeDisplay,
      zip,
    } = details;

    const onClickEdit = useCallback(() => {
      let url = "/";
      switch (type) {
        case EntityTypes.Facility:
          url = AdminPages.editFacility.path;
          break;
        case EntityTypes.Group:
          url = AdminPages.editGroup.path;
          break;
        case EntityTypes.Region:
          url = AdminPages.editRegion.path;
          break;
      }

      Navigation.go(url, { params: { id } });
    }, [id, type]);

    const [showPassword, setShowPassword] = useState(false);

    return (
      <CardContainerStyled>
        <CardStyled raised={true}>
          <Box display="flex">
            <GroupFacilityRegionLogosDisplay
              groupLogoUrl={groupLogoUrl}
              logoUrl={logoUrl}
              name={name}
            />
            <Box ml={2}>
              <Box mb={2}>
                <Box display="flex">
                  <Typography variant={groupName ? "subtitle1" : "h6"}>
                    {name}
                  </Typography>
                  <GroupFacilityRegionStatusDropdown
                    details={details}
                    resetDetails={resetDetails}
                    type={type}
                  />
                </Box>
                {groupID && (
                  <SubtitleStyled>
                    {typeDisplay && (
                      <>
                        <Typography variant="caption">{typeDisplay}</Typography>
                        {" · "}
                      </>
                    )}
                    <Link
                      component={RouterLink}
                      to={Navigation.url(AdminPages.group, {
                        params: { id: groupID },
                      })}
                    >
                      {groupName}
                    </Link>
                    {regionID && (
                      <>
                        {" · "}
                        <Link
                          variant="caption"
                          color="text.secondary"
                          component={RouterLink}
                          to={Navigation.url(AdminPages.region, {
                            params: { id: regionID },
                          })}
                        >
                          {regionName}
                        </Link>
                      </>
                    )}
                  </SubtitleStyled>
                )}
              </Box>
              <Box display="flex">
                <Box sx={{ minWidth: "280px" }}>
                  {(phone1 || phone2) && (
                    <Box display="flex" mb={1}>
                      <PhoneIcon sx={{ mr: 2 }} />
                      {phone1 && (
                        <Link href={`tel:${phone1}`}>
                          <Typography variant="body2">
                            {formatPhone(phone1)}
                          </Typography>
                        </Link>
                      )}
                      {phone1 && phone2 && (
                        <Typography variant="body2" mr={1} ml={1}>
                          •
                        </Typography>
                      )}
                      {phone2 && (
                        <Link href={`tel:${phone2}`}>
                          <Typography variant="body2">
                            {formatPhone(phone2)}
                          </Typography>
                        </Link>
                      )}
                    </Box>
                  )}
                  {(address1 || address2 || city || stateName || zip) && (
                    <Box display="flex">
                      <PlaceIcon />
                      <Typography
                        variant="body2"
                        ml={2}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {formatAddress(
                          {
                            address1,
                            address2,
                            city,
                            stateName,
                            zip,
                          },
                          "\n",
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {type === EntityTypes.Facility &&
                  (staffUserName || staffPassword) && (
                    <Box ml={8}>
                      <Typography variant="subtitle2" mb={1}>
                        Staff login
                      </Typography>
                      <Box display="flex" mb={1}>
                        <PersonIcon />
                        <Typography variant="body2" ml={2}>
                          {staffUserName}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          onClick={() =>
                            staffPassword && setShowPassword(!showPassword)
                          }
                          sx={{ padding: 0, color: "text.primary" }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                        {staffPassword && (
                          <Typography variant="body2" ml={2}>
                            {showPassword ? staffPassword : "••••••••"}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="outlined"
              size="large"
              onClick={onClickEdit}
              sx={{ ml: 1 }}
            >
              Edit
            </Button>
            {(status === FacilityStatuses.Pending ||
              status === FacilityStatuses.Review) && (
              <Button
                variant="outlined"
                size="large"
                onClick={onClickEdit}
                sx={{ ml: 1 }}
              >
                Onboard
              </Button>
            )}
          </Box>
        </CardStyled>
      </CardContainerStyled>
    );
  },
);
