import React, { Fragment, useMemo } from "react";
import { listsSelectors, uiSelectors, useSelector } from "../../state";
import { ListState } from "../../state/lists/state";
import {
  ListPaginationProps,
  pageSizeOptions,
  useListPagination,
} from "./PaginationHelpers";
import { ListActionsWrapper } from "./ListActions";
import { NoRowsOverlayStyled } from "./List.styles";
import {
  Box,
  SxProps,
  TablePagination,
  Theme,
  Typography,
} from "@mui/material";
import logo from "../../assets/img/logo_blue.svg";

interface GroupingOptions {
  getGroup: (result: any) => string;
  renderGroupHeader: (result: any, index: number) => string | JSX.Element;
}

interface PaginationContainerProps extends ListPaginationProps {
  hidePaginationForMinRows?: boolean;
  groupingOptions?: GroupingOptions;
  name?: string;
  noRowsDisplay?: string | JSX.Element;
  noRowsLogo?: string;
  renderResult: (result: any) => JSX.Element;
  sx?: SxProps<Theme>;
}

export const PaginationContainer = ({
  actions,
  dataModifier,
  dataUrl,
  defaultOrderBy,
  defaultOrderDirection, // note that order/orderBy cannot currently be updated from this component
  defaultResultsPerPage,
  filter,
  filterQueryKey,
  groupingOptions,
  hidePaginationForMinRows,
  name,
  noRowsDisplay,
  noRowsLogo,
  paginationQueryKeyPrefix,
  renderResult,
  sx,
  type,
  useQuery = true,
}: PaginationContainerProps) => {
  const { listParams, onPageChange, onPageSizeChange } = useListPagination({
    dataModifier,
    dataUrl,
    defaultOrderBy: defaultOrderBy,
    defaultOrderDirection,
    defaultResultsPerPage,
    filter,
    filterQueryKey,
    paginationQueryKeyPrefix,
    type,
    useQuery,
  });

  const loading = useSelector(uiSelectors.loading);

  const listState: ListState<any> = useSelector(listsSelectors[type]);
  const noResults = !listState.data.numberOfRows;

  const resultGroups = useMemo(() => {
    if (!groupingOptions) {
      return [""]; // return a single empty group to render all results if no grouping options are specified
    }
    return [
      ...new Set(
        listState.data.results.map((result) =>
          groupingOptions.getGroup(result),
        ),
      ),
    ];
  }, [groupingOptions, listState.data.results]);

  const hidePagination =
    noResults ||
    (hidePaginationForMinRows &&
      listState.data.numberOfRows <=
        (defaultResultsPerPage || pageSizeOptions[0]));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: noResults ? "100%" : "auto",
        ...sx,
      }}
    >
      {actions && (
        <ListActionsWrapper queryKeyPrefix={paginationQueryKeyPrefix}>
          {actions}
        </ListActionsWrapper>
      )}
      {!loading && noResults ? (
        <NoRowsOverlayStyled>
          <img alt="logo" src={noRowsLogo || logo} height="80px" />
          <Box sx={{ mt: 4 }}>
            {noRowsDisplay || (
              <Typography variant="subtitle2">
                No {name || "results"} found
              </Typography>
            )}
          </Box>
        </NoRowsOverlayStyled>
      ) : (
        <>
          {resultGroups.map((group, groupIndex) => {
            const results = listState.data.results.filter(
              (r) => !groupingOptions || groupingOptions.getGroup(r) == group,
            );
            return (
              <Fragment key={group}>
                {groupingOptions &&
                  groupingOptions.renderGroupHeader(results[0], groupIndex)}
                {results.map((r) => renderResult(r))}
              </Fragment>
            );
          })}
          {!hidePagination && (
            <TablePagination
              component={`div`}
              count={10}
              page={listParams.page - 1}
              onPageChange={(_, p) => onPageChange(p)}
              rowsPerPage={listParams.resultsPerPage}
              rowsPerPageOptions={pageSizeOptions}
              onRowsPerPageChange={(e) =>
                onPageSizeChange(Number(e.target.value))
              }
            />
          )}
        </>
      )}
    </Box>
  );
};
