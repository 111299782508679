import React, { useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Link, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  BasicStatusChipStyled,
  CheckboxInput,
  CheckIcon,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  LongTooltip,
  SearchInput,
  SelectInput,
} from "../../../../components";
import { asArray, Navigation, RouterLink } from "../../../../lib";
import { sysSelectors, TrainingRecord, useSelector } from "../../../../state";
import { AdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";

const filterQueryKey = {
  facilityIDs: "facilities",
  includeInactive: "showInactive",
  stateIDs: "states",
  text: "search",
  type: "type",
};

function TrainingsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { states: stateOptions = [], trainingTypes: trainingTypeOptions = [] } =
    useSelector(sysSelectors.systemSettings);
  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const {
    facilities = [],
    search = "",
    showInactive = false,
    states = [],
    type = "",
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(search || type || asArray(facilities).length || asArray(states).length),
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Trainings</Typography>
        <Box>
          <CheckboxInput
            checked={!!showInactive}
            label="Show inactive"
            name="showInactive"
            onChange={onFilterChange}
          />
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            component={RouterLink}
            to={AdminPages.addTraining.path}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
          >
            Add training
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search trainings"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Type"
            name="type"
            value={type}
            onChange={onFilterChange}
            options={trainingTypeOptions}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="State"
            name="states"
            value={asArray(states)}
            onChange={onFilterChange}
            options={stateOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility"
            name="facilities"
            value={asArray(facilities)}
            onChange={onFilterChange}
            options={facilityOptions}
            groupOpts={true}
            multiple={true}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "applicableFor",
    headerName: "Applicable for",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.facilities.length ? (
        <AbbreviatedListDisplay items={row.facilities} />
      ) : row.states ? (
        row.states.length > 50 ? (
          "All States"
        ) : (
          <AbbreviatedListDisplay items={row.states} />
        )
      ) : (
        ""
      ),
    sortable: false,
  },
  {
    field: "isRequiredOnHire",
    headerName: "Required on hire",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.isRequiredOnHire ? <CheckIcon /> : "",
  },
  {
    field: "isRequiredAnnually",
    headerName: "Required annually",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.isRequiredAnnually ? <CheckIcon /> : "",
  },
  {
    field: "policies",
    headerName: "Related Policy",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.policy ? (
        <LongTooltip title={row.policy.title}>
          <Link
            component={RouterLink}
            onClick={(e) => e.stopPropagation()}
            to={Navigation.url(AdminPages.editPolicy, {
              params: { id: row.policy.id },
            })}
          >
            {row.policy.title.replace(row.policy.name, "").trim()}
          </Link>
        </LongTooltip>
      ) : (
        ""
      ),
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: ({ row }: { row: TrainingRecord }) => (
      <BasicStatusChipStyled
        label={row.status}
        size="small"
        status={row.status}
      />
    ),
  },
];

export const TrainingsList = React.memo(
  /**
   *
   */
  function TrainingsList() {
    const onRowClick = useCallback(({ row }: { row: TrainingRecord }) => {
      Navigation.go(AdminPages.editTraining, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<TrainingsListActions />}
        columns={columns}
        filterQueryKey={filterQueryKey}
        name="trainings"
        onRowClick={onRowClick}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.trainings}
      />
    );
  },
);
