import React, { useMemo } from "react";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../../components";
import { BasicStatuses, EntityTypes } from "../../../../../lib/constants";

interface GroupFacilityRegionStatusConfirmationDialogProps {
  handleClose: () => void;
  handleConfirm: () => void;
  status: string;
  type: string;
}

export const GroupFacilityRegionStatusConfirmationDialog = React.memo(
  /**
   *
   */
  function GroupFacilityRegionStatusConfirmationDialog({
    handleClose,
    handleConfirm,
    status,
    type,
  }: GroupFacilityRegionStatusConfirmationDialogProps) {
    const deactivating = status === BasicStatuses.Inactive;

    const dialogDetails = useMemo(() => {
      const details = {
        confirmText: `${deactivating ? "Deactivate" : "Activate"} ${type}`,
        title: `${deactivating ? "Deactivate" : "Activate"} ${type}?`,
        show: true,
        message: "",
      };

      switch (type) {
        case EntityTypes.Group:
          details.message = deactivating
            ? `This will deactivate all regions and facilities in this group, and users that have access to only this group. When reactivating the group, the regions, facilities and users will need to be manually activated.`
            : `Please note, this will not activate the group's regions, facilities, and users. To activate them, please update the status of each one individually.`;
          break;
        case EntityTypes.Region:
          details.message = deactivating
            ? `This will deactivate all facilities in this region, and users that have access to only this region. When reactivating the region, the facilities and users will need to be manually activated.`
            : `Please note, this will not activate the region's facilities and users. To activate them, please update the status of each one individually.`;
          break;
        case EntityTypes.Facility:
          details.message = deactivating
            ? `This will deactivate users that have access to only this facility. When reactivating the facility, the users will need to be manually activated.`
            : `Please note, this will not activate the facility's users. To activate them, please update the status of each user individually.`;
          break;
      }

      return details;
    }, [deactivating, type]);

    return (
      <ConfirmationDialog
        confirmText={dialogDetails.confirmText}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        message={dialogDetails.message}
        open={true}
        title={dialogDetails.title}
        type={ConfirmationDialogTypes.Warning}
      />
    );
  },
);
