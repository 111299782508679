import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import { DialogActions } from "@mui/material";
import alert from "../../assets/img/alert.svg";
import emailSent from "../../assets/img/email_sent.svg";
import _continue from "../../assets/img/continue.svg";
import saved from "../../assets/img/saved.svg";
import thankYou from "../../assets/img/thank_you.svg";
import warning from "../../assets/img/warning.svg";

export interface ConfirmationDialogProps {
  cancelText?: string;
  cancelColor?: "primary" | "error";
  confirmColor?: "primary" | "error";
  confirmControl?: JSX.Element;
  confirmDisabled?: boolean;
  confirmText?: string;
  img?: string;
  loading?: boolean;
  message?: string | JSX.Element;
  messageContent?: JSX.Element;
  open: boolean;
  handleClose?: (e: any) => void;
  handleConfirm?: (e: any) => void;
  hideCancel?: boolean;
  reverseButtons?: boolean;
  title?: string | JSX.Element;
  type?: string;
  width?: string;
}

export const ConfirmationDialogTypes = {
  Alert: "Alert",
  Continue: "Continue",
  EmailSent: "EmailSent",
  Saved: "Saved",
  ThankYou: "ThankYou",
  Warning: "Warning",
};

export const ConfirmationDialog = React.memo(function ConfirmationDialog({
  cancelText,
  cancelColor,
  confirmColor,
  confirmControl,
  confirmDisabled,
  confirmText,
  img,
  loading,
  message,
  messageContent,
  open,
  handleClose,
  handleConfirm,
  hideCancel,
  reverseButtons,
  title,
  type,
  width = "400px",
}: ConfirmationDialogProps) {
  const imgSrc =
    img ||
    (type === ConfirmationDialogTypes.Alert
      ? alert
      : type === ConfirmationDialogTypes.Continue
      ? _continue
      : type === ConfirmationDialogTypes.EmailSent
      ? emailSent
      : type === ConfirmationDialogTypes.Saved
      ? saved
      : type === ConfirmationDialogTypes.ThankYou
      ? thankYou
      : type === ConfirmationDialogTypes.Warning
      ? warning
      : "");
  const centerImg =
    imgSrc === alert || imgSrc === _continue || imgSrc === thankYou;

  return (
    <Dialog open={open} PaperProps={{ sx: { textAlign: "center", width } }}>
      {imgSrc && (
        <Box
          sx={{
            textAlign: centerImg ? "center" : "left",
            mt: "32px",
          }}
        >
          <img
            src={imgSrc}
            alt="confirmation-img"
            style={{ maxHeight: "180px" }}
          />
        </Box>
      )}
      <DialogTitle variant="subtitle1">{title}</DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          sx={{ whiteSpace: "pre-wrap", lineHeight: 1.5, mb: 1 }}
        >
          {message}
        </Typography>
        {messageContent}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          ...(reverseButtons
            ? {
                flexDirection: "row-reverse",
                "& button:not(:first-of-type)": {
                  marginLeft: "0px",
                  marginRight: "8px",
                },
              }
            : {}),
        }}
      >
        {!hideCancel && (
          <Button
            disabled={loading}
            color={cancelColor}
            variant={cancelColor ? "contained" : "outlined"}
            onClick={handleClose}
            autoFocus
          >
            {cancelText || "Cancel"}
          </Button>
        )}
        {confirmControl || (
          <Button
            disabled={loading || confirmDisabled}
            color={confirmColor}
            variant="contained"
            onClick={handleConfirm}
          >
            {confirmText || "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
