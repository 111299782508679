import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { IconButton, Link, Typography } from "@mui/material";
import {
  LinkTextStyled,
  List,
  ListActionsContainerStyled,
  ListActionsMainStyled,
  SearchInput,
  VerifiedIcon,
} from "../../../../components";
import { ListActionsProps } from "../../../../components";
import { ListTypes } from "../../../../state/lists/state";
import { AuditRecord, sysSelectors, useSelector } from "../../../../state";
import {
  formatDate,
  Navigation,
  RouterLink,
  useLocation,
} from "../../../../lib";
import {
  AuditCapStatusCellStyled,
  AuditStatusChipStyled,
  ShowPastAuditsTextStyled,
} from "./AuditsList.styles";
import { StatusPage } from "../../../shared/components";
import { AuditCertificatePreviewDialog } from "../../../adminShared/components";
import { FacilityAdminPages } from "../..";
import facility from "../../../../assets/img/facility.svg";
import { AuditsPageTabs, AuditStatuses } from "../../../../lib/constants";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import { LatestAuditDisplay } from "./LatestAuditDisplay";

const filterQueryKey = {
  text: "search",
};

function AuditsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { search = "", showPast = false } = query;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">
          {query.tab === AuditsPageTabs.Completed
            ? "Completed audits"
            : "Audit Inbox"}
        </Typography>
        <SearchInput
          variant="outlined"
          placeholder="Search audits"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
        />
      </ListActionsMainStyled>
      {query.tab !== AuditsPageTabs.Completed && showPast && (
        <ShowPastAuditsTextStyled variant="caption">
          Viewing all incomplete audits.{" "}
          <LinkTextStyled
            onClick={() => onFilterChange("showPast", false)}
            variant="subtitle2"
          >
            Hide past audits
          </LinkTextStyled>
        </ShowPastAuditsTextStyled>
      )}
    </ListActionsContainerStyled>
  );
}

export const AuditsList = React.memo(
  /**
   *
   */
  function AuditsList() {
    const location = useLocation();
    const isCompletedAuditsList =
      location.query.tab === AuditsPageTabs.Completed;
    const showLatestAudit = !isCompletedAuditsList && !location.query.showPast;

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => ({
        [isCompletedAuditsList ? "completedOnly" : "excludeCompleted"]: true,
        ...(facilityFilter ? { facilityIDs: [facilityFilter.id] } : undefined),
      }),
      [facilityFilter, isCompletedAuditsList],
    );

    const onRowClick = useCallback(({ row }: { row: AuditRecord }) => {
      Navigation.go(
        FacilityAdminPages.audit,
        { params: { id: row.id } },
        { resumePrompt: true },
      );
    }, []);

    const [certificatePreviewUrl, setCertificatePreviewUrl] =
      useState<string>();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Audit",
          flex: 2,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <Typography variant="subtitle1">{row.name}</Typography>
          ),
        },
        {
          field: "sentOn",
          headerName: "Received",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        isCompletedAuditsList
          ? {
              field: "completedOn",
              headerName: "Completed",
              flex: 1,
              valueFormatter: ({ value }) => formatDate(value),
              sortingOrder: dateColumnSortingOrder,
            }
          : {
              field: "dueDate",
              headerName: "Due date",
              flex: 1,
              valueFormatter: ({ value }) => formatDate(value),
              sortingOrder: dateColumnSortingOrder,
            },
        {
          field: "submittedBy",
          headerName: "Submitted by",
          flex: 1,
        },
        isCompletedAuditsList
          ? {
              field: "hasCertificate",
              headerName: "Certificate",
              flex: 0.75,
              renderCell: ({ row }: { row: AuditRecord }) =>
                row.hasCertificate ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCertificatePreviewUrl(row.certificateUrl);
                    }}
                  >
                    <VerifiedIcon color="primary" />
                  </IconButton>
                ) : (
                  ""
                ),
            }
          : {
              field: "status",
              headerName: "Audit status",
              flex: 1,
              renderCell: ({ row }: { row: AuditRecord }) =>
                // exclude completed statuses from display to avoid flash of completed status chip displays when toggling between inbox and completed audits tabs
                row.status !== AuditStatuses.Completed &&
                row.status !== AuditStatuses.CompletedCertificateSent ? (
                  <AuditStatusChipStyled
                    label={row.statusDisplay}
                    size="small"
                    status={row.status}
                  />
                ) : (
                  ""
                ),
            },
        {
          field: "capStatus",
          headerName: "CAP status",
          flex: 1,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditCapStatusCellStyled status={row.capStatus}>
              <Link
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(FacilityAdminPages.auditCap, {
                  params: { id: row.id },
                })}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Typography variant="body2">{row.capStatusDisplay}</Typography>
              </Link>
            </AuditCapStatusCellStyled>
          ),
        },
      ],
      [isCompletedAuditsList],
    );

    return !facilityFilter?.id ? (
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="Select a facility from the blue bar at the top of the screen to view audits"
      />
    ) : showLatestAudit ? (
      <LatestAuditDisplay facilityId={facilityFilter.id} />
    ) : (
      <>
        <List
          actions={<AuditsListActions />}
          columns={columns}
          defaultOrderBy={isCompletedAuditsList ? "completedOn" : "status"}
          defaultOrderDirection={isCompletedAuditsList ? "desc" : "asc"}
          filter={listFilter}
          filterQueryKey={filterQueryKey}
          name="audits"
          onRowClick={onRowClick}
          rowHeight={84}
          stickyHeader={true}
          type={ListTypes.audits}
        />
        {certificatePreviewUrl && (
          <AuditCertificatePreviewDialog
            certificateUrl={certificatePreviewUrl}
            handleClose={() => setCertificatePreviewUrl(undefined)}
          />
        )}
      </>
    );
  },
);
