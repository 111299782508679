import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserStatuses } from "../../../../lib/constants";

interface UserStatusChipStyledProps extends ChipProps {
  status?: string;
}

export const UserStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<UserStatusChipStyledProps>(({ theme, status }) => ({
  color: theme.palette.background.default,
  backgroundColor:
    status === UserStatuses.Active
      ? theme.palette.primary.light
      : status === UserStatuses.Pending
      ? theme.palette.secondary.dark
      : theme.palette.neutral.main,
}));

export const ContactLink = styled(`a`)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "block",
  marginTop: "6px",
  textDecoration: "none",
  fontSize: "14px",
  lineHeight: "19px",
}));
