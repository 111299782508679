import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  adminActions,
  authSelectors,
  BasicRegion,
  GroupFacilityRegion,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  WizardContainerStyled,
  WizardHeaderStyled,
  WizardStepContainerStyled,
  WizardTitleStyled,
} from "./Wizard.styles";
import {
  FileUploadTypes,
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useFileUpload,
  useLocation,
  useRedirect,
} from "../../../../../lib";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  ArrowBackIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FormFooterContainerStyled,
  FormGridContainerStyled,
} from "../../../../../components";
import {
  initialValuesByType,
  validationSchemasByType,
  WizardModes,
} from "./GroupFacilityRegionWizardHelpers";
import { WizardStep } from "./WizardStep";
import { AdminPages } from "../../..";
import {
  BasicStatuses,
  EntityTypes,
  FacilityStatuses,
  PortalUserTypes,
} from "../../../../../lib/constants";
import { FacilityAdminSubmissionDialog } from "./FacilityAdminSubmissionDialog";
import { FacilitySubmissionDialog } from "./FacilitySubmissionDialog";
import { PublishFacilityPoliciesDialog } from "../details/PublishFacilityPoliciesDialog";
import { GroupFacilityRegionStatusConfirmationDialog } from "../general/GroupFacilityRegionStatusConfirmationDialog";
import { GroupRegionChangeConfirmationDialog } from "./GroupRegionChangeConfirmation";

interface GroupFacilityRegionWizardProps {
  type: string;
}

export const GroupFacilityRegionWizard = React.memo(
  /**
   *
   */
  function GroupFacilityRegionWizard({ type }: GroupFacilityRegionWizardProps) {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();

    const { redirect, setRedirect } = useRedirect();

    const userType = useSelector(authSelectors.userType);

    // field options
    const { states: stateOptions = [] } = useSelector(
      sysSelectors.systemSettings,
    );
    const positionOptions = useSelector(sysSelectors.allPositions);
    const roleOptions = useSelector(sysSelectors.allRoles);
    const groupOptions = useSelector(sysSelectors.allGroups);
    const allRegions = useSelector(sysSelectors.allRegions);
    const [regionOptions, setRegionOptions] = useState<BasicRegion[]>([]);

    // initial values
    const [initialValues, setInitialValues] = useState<GroupFacilityRegion>({
      ...initialValuesByType[type],
      // accomodate preset group/regionId from url
      ...(location.query.groupId || location.query.regionId
        ? {
            groupID: Number(location.query.groupId) || undefined,
            regionID: Number(location.query.regionId) || undefined,
          }
        : ""),
    });
    const [sendUserEmails, setSendUserEmails] = useState(false);

    // wizard mode & steps
    const [wizardMode, setWizardMode] = useState("");
    const [wizardSteps, setWizardSteps] = useState<number[]>([]);
    const [step, setStep] = useState(0);
    const isFinalStep = useMemo(
      () => step === wizardSteps[wizardSteps.length - 1],
      [wizardSteps, step],
    );

    // #region initial data retrieval and wizard configuration
    const retrieveAction = useMemo(() => {
      if (!params.id) {
        return;
      }

      switch (type) {
        case EntityTypes.Group:
          return () => adminActions.getGroup(Number(params.id));
        case EntityTypes.Region:
          return () => adminActions.getRegion(Number(params.id));
        case EntityTypes.Facility:
        default:
          return () => adminActions.getFacility(Number(params.id), true);
      }
    }, [params.id, type]);

    useEffect(() => {
      let mode = WizardModes.New;

      (async () => {
        if (retrieveAction) {
          // retrieve values
          const values: GroupFacilityRegion | null = await dispatch(
            retrieveAction(),
          );
          if (values) {
            setInitialValues(replaceNullProps(values));

            mode =
              userType === PortalUserTypes.FacilityAdmin
                ? WizardModes.FacilityAdmin
                : values.status === FacilityStatuses.Pending ||
                  values.status === FacilityStatuses.Review
                ? WizardModes.New
                : WizardModes.Edit;
          }
        }

        let steps: number[] = [];
        switch (mode) {
          case WizardModes.Edit:
            steps = [1];
            break;
          case WizardModes.FacilityAdmin:
          case WizardModes.New:
            steps = [1, 2];
            break;
        }

        setWizardMode(mode);
        setWizardSteps(steps);
        setStep(steps[0]);
      })();
    }, [dispatch, retrieveAction, userType]);
    // #endregion

    // #region submission properties
    const submitAction = useMemo(() => {
      switch (type) {
        case EntityTypes.Group:
          return adminActions.submitGroup;
        case EntityTypes.Region:
          return adminActions.submitRegion;
        case EntityTypes.Facility:
        default:
          return adminActions.submitFacility;
      }
    }, [type]);

    const [showStatusConfirmationDialog, setShowStatusConfirmationDialog] =
      useState(false);
    const [
      showChangeGroupRegionConfirmation,
      setShowChangeGroupRegionConfirmation,
    ] = useState(false);
    const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);

    const detailsPageUrl = useMemo(() => {
      switch (type) {
        case EntityTypes.Group:
          return AdminPages.group.path;
        case EntityTypes.Region:
          return AdminPages.region.path;
        case EntityTypes.Facility:
          return AdminPages.facility.path;
        default:
          return "";
      }
    }, [type]);

    const redirectToDetailsPage = useCallback(
      (id) => {
        if (!detailsPageUrl) return;

        const url = Navigation.url(detailsPageUrl, { params: { id } });
        if (params.id) {
          // if there is an id param, we assume navigation to this page was from the details page, in which case we go back instead of replacing navigation with the details url
          setRedirect(() => () => Navigation.goBack(url));
        } else {
          setRedirect(() => () => Navigation.replace(url));
        }
      },
      [detailsPageUrl, params.id, setRedirect],
    );
    // #endregion

    const uploadFile = useFileUpload("logo");

    const getValuesForSubmission = useCallback(
      async (values, setFieldValue) => {
        const valuesForSubmission = { ...values };

        // filter out empty user records
        valuesForSubmission.users = values.users
          ?.filter((user) => user.firstName && user.lastName && user.email)
          .map(
            ({ isLinkedToSystemUser: _, ...user }) => user, // discard isLinkedToSystemUser UI flag
          );

        // if a new file was selected for logoUrl, upload logo to get storage url
        if (values.logoUrl && typeof values.logoUrl === "object") {
          const logoFile = values.logoUrl;
          const logoFileType =
            type === EntityTypes.Group
              ? FileUploadTypes.GroupLogo
              : type === EntityTypes.Region
              ? FileUploadTypes.RegionLogo
              : FileUploadTypes.FacilityLogo;
          const logoUrl = await uploadFile(logoFile, logoFileType);

          if (!logoUrl) {
            return;
          }

          // update the logoUrl
          valuesForSubmission.logoUrl = logoUrl;
          // update the form field value as well, so that if save fails later on the url will be persisted with the form and won't require re-uploading
          setFieldValue("logoUrl", logoUrl);
        }

        // if ip address is added here, default the 'lock users' setting to true; if removed, reset it to false
        if (!!values.ipAddress !== !!initialValues.ipAddress) {
          valuesForSubmission.lockUsersToIP = !!values.ipAddress;
        }

        return valuesForSubmission;
      },
      [initialValues.ipAddress, type, uploadFile],
    );

    const onSubmit = useCallback(
      async (
        values: GroupFacilityRegion,
        { setFieldValue, setValues },
        {
          statusUpdateConfirmed = false,
          groupRegionChangeConfirmed = false,
        } = {},
      ) => {
        if (isFinalStep) {
          const activating =
            initialValues.status !== values.status &&
            initialValues.status === BasicStatuses.Inactive;
          const deactivating =
            initialValues.status !== values.status &&
            values.status === BasicStatuses.Inactive;

          // edit mode confirmations
          if (wizardMode === WizardModes.Edit) {
            if (statusUpdateConfirmed) {
              // note that the status update is the second confirmation, so if it's been confirmed we don't check group/region change
              setShowStatusConfirmationDialog(false);
            } else {
              // first check for group/region change
              if (groupRegionChangeConfirmed) {
                setShowChangeGroupRegionConfirmation(false);
              } else {
                // prompt for group/region change confirmation
                const groupRegionChange =
                  initialValues.groupID !== values.groupID ||
                  initialValues.regionID !== values.regionID;

                if (groupRegionChange) {
                  setShowChangeGroupRegionConfirmation(true);
                  return;
                }
              }

              // then check for activation/deactivation change
              if (activating || deactivating) {
                // prompt for activation/deactivation status change confirmation
                setShowStatusConfirmationDialog(true);
                return;
              }
            }
          }

          const valuesForSubmission = await getValuesForSubmission(
            values,
            setFieldValue,
          );
          if (!valuesForSubmission) {
            return;
          }

          const savedValues: GroupFacilityRegion | null = await dispatch(
            submitAction(
              replaceEmptyProps(valuesForSubmission),
              sendUserEmails,
            ),
          );
          if (savedValues) {
            setValues(replaceNullProps(savedValues));
            setInitialValues(replaceNullProps(savedValues));

            const publishFacilityPolicies = // show publish facility policies dialog when activating a facility
              type === EntityTypes.Facility && activating;
            if (wizardMode === WizardModes.Edit && !publishFacilityPolicies) {
              redirectToDetailsPage(savedValues.id);
            } else {
              setShowSubmissionDialog(true);
            }
          }
        } else {
          setStep((step) => step + 1);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      },
      [
        dispatch,
        getValuesForSubmission,
        initialValues.groupID,
        initialValues.regionID,
        initialValues.status,
        isFinalStep,
        redirectToDetailsPage,
        sendUserEmails,
        submitAction,
        type,
        wizardMode,
      ],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: validationSchemasByType[type][step],
      onSubmit,
    });

    const onSubmitPendingFacility = useCallback(() => {
      // set status to review and submit
      form.setFieldValue("status", FacilityStatuses.Review);
      form.submitForm();
    }, [form]);

    useEffect(() => {
      (() => {
        // filter region options to those associated with the selected group
        let filteredRegions: BasicRegion[] = [];
        if (allRegions && form.values.groupID) {
          filteredRegions = allRegions.filter(
            (r) => r.groupID === form.values.groupID,
          );
          setRegionOptions(filteredRegions);
        }
        // if a region has been selected that does not match the selected group, clear it out upon group selection
        if (
          form.values.groupID &&
          form.values.regionID &&
          !filteredRegions.find((r) => r.id === form.values.regionID)
        ) {
          form.setFieldValue("regionID", "");
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.groupID]);

    const onAddNext = useCallback(
      (addNext: boolean) => {
        if (addNext) {
          switch (type) {
            case EntityTypes.Group:
              return Navigation.replace(
                Navigation.url(AdminPages.addRegion.path, {
                  query: { groupId: form.values.id },
                }),
              );
            case EntityTypes.Region:
              return Navigation.replace(
                Navigation.url(AdminPages.addFacility.path, {
                  query: {
                    groupId: form.values.groupID,
                    regionId: form.values.id,
                  },
                }),
              );
          }
        } else {
          redirectToDetailsPage(form.values.id);
        }
      },
      [type, form.values.id, form.values.groupID, redirectToDetailsPage],
    );

    const onCancel = useCallback(() => {
      if (wizardMode === WizardModes.FacilityAdmin) {
        Navigation.replace("/");
      } else {
        Navigation.goBack(AdminPages.groupsAndFacilities.path);
      }
    }, [wizardMode]);

    const onGoBack = useCallback(() => {
      if (step > wizardSteps[0]) {
        setStep((step) => step - 1);
      } else {
        onCancel();
      }
    }, [onCancel, step, wizardSteps]);

    const wizardTitle = useMemo(() => {
      if (form.values.id || step > 1) {
        const groupId =
          step === 1 ? form.initialValues.groupID : form.values.groupID;
        const groupName =
          groupId && groupOptions.find((g) => g.id === groupId)?.name;

        const regionId =
          step === 1 ? form.initialValues.regionID : form.values.regionID;
        const regionName =
          regionId && regionOptions.find((r) => r.id === regionId)?.name;

        return (
          <>
            <Typography variant="h5" mb={0.5}>
              {form.values.name}
            </Typography>
            {groupName && (
              <Box display="flex">
                <Typography variant="subtitle2" mr={1}>
                  Part of:
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {groupName}
                </Typography>
              </Box>
            )}
            {regionName && (
              <Box display="flex">
                <Typography variant="subtitle2" mr={1}>
                  Region:
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {regionName}
                </Typography>
              </Box>
            )}
          </>
        );
      }
      return <Typography variant="h5">Add {type}</Typography>;
    }, [
      form.initialValues.groupID,
      form.initialValues.regionID,
      form.values.groupID,
      form.values.id,
      form.values.name,
      form.values.regionID,
      groupOptions,
      regionOptions,
      step,
      type,
    ]);

    return step ? (
      <WizardContainerStyled>
        {showSubmissionDialog ? (
          wizardMode === WizardModes.FacilityAdmin ? (
            <FacilityAdminSubmissionDialog
              facility={form.values}
              onClose={() => {
                setStep(wizardSteps[0]);
                setShowSubmissionDialog(false);
              }}
            />
          ) : type === EntityTypes.Facility ? (
            wizardMode === WizardModes.Edit ? (
              <PublishFacilityPoliciesDialog
                facility={form.values}
                handleConfirm={() => redirectToDetailsPage(form.values.id)}
              />
            ) : (
              <FacilitySubmissionDialog
                facility={form.values}
                handleConfirm={() => redirectToDetailsPage(form.values.id)}
              />
            )
          ) : (
            <ConfirmationDialog
              cancelText="No"
              confirmText="Yes"
              handleClose={() => onAddNext(false)}
              handleConfirm={() => onAddNext(true)}
              message={`Would you like to continue with adding a ${
                type === EntityTypes.Group ? "Region" : "Facility"
              } for this ${type}?`}
              open={true}
              title={`${form.values.name} has been saved`}
              type={ConfirmationDialogTypes.Saved}
            />
          )
        ) : (
          <Form
            form={form}
            noPrompt={!!redirect}
            promptCancelText={`Back to ${type}`}
          >
            <WizardHeaderStyled>
              {(wizardMode !== WizardModes.FacilityAdmin || step > 1) && (
                <IconButton onClick={onGoBack}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <WizardTitleStyled>{wizardTitle}</WizardTitleStyled>
            </WizardHeaderStyled>
            <WizardStepContainerStyled>
              <FormGridContainerStyled container>
                <WizardStep
                  form={form}
                  mode={wizardMode}
                  step={step}
                  type={type}
                  // options
                  groupOptions={groupOptions}
                  positionOptions={positionOptions}
                  regionOptions={regionOptions}
                  roleOptions={roleOptions}
                  stateOptions={stateOptions}
                  // helpers
                  setInitialValues={setInitialValues}
                  sendUserEmails={sendUserEmails}
                  toggleSendUserEmails={() =>
                    setSendUserEmails(!sendUserEmails)
                  }
                />
                <FormFooterContainerStyled>
                  <Button
                    variant="outlined"
                    size="large"
                    disabled={form.isSubmitting}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Box display="flex">
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={form.isSubmitting}
                    >
                      {isFinalStep
                        ? wizardMode === WizardModes.FacilityAdmin
                          ? "Save for later"
                          : "Save"
                        : "Continue"}
                    </Button>
                    {wizardMode === WizardModes.FacilityAdmin && isFinalStep && (
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={form.isSubmitting}
                        onClick={onSubmitPendingFacility}
                        sx={{ ml: 2 }}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </FormFooterContainerStyled>
              </FormGridContainerStyled>
            </WizardStepContainerStyled>
            {showStatusConfirmationDialog && (
              <GroupFacilityRegionStatusConfirmationDialog
                handleClose={() => setShowStatusConfirmationDialog(false)}
                handleConfirm={() =>
                  onSubmit(form.values, form, { statusUpdateConfirmed: true })
                }
                status={form.values.status as string}
                type={type}
              />
            )}
            {showChangeGroupRegionConfirmation && (
              <GroupRegionChangeConfirmationDialog
                handleClose={() => setShowChangeGroupRegionConfirmation(false)}
                handleConfirm={() =>
                  onSubmit(form.values, form, {
                    groupRegionChangeConfirmed: true,
                  })
                }
                groupChange={form.initialValues.groupID !== form.values.groupID}
                regionChange={
                  form.initialValues.regionID !== form.values.regionID
                }
                initialRegionID={form.initialValues.regionID}
              />
            )}
          </Form>
        )}
      </WizardContainerStyled>
    ) : null;
  },
);
