import React from "react";
import { CheckboxProps, Checkbox, FormControlLabel } from "@mui/material";

interface CheckboxInputProps extends Omit<CheckboxProps, "onChange"> {
  disabled?: boolean;
  checked: boolean;
  label: string | JSX.Element;
  name: string;
  onChange: (name: string, val: boolean) => void;
}

export const CheckboxInput = React.memo(
  /**
   *
   */
  function CheckboxInput({
    disabled,
    checked,
    label,
    name,
    onChange,
    sx,
    ...rest
  }: CheckboxInputProps) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={name}
            onChange={(e) => onChange(e.target.name, e.target.checked)}
            {...rest}
          />
        }
        disabled={disabled}
        label={label}
        sx={disabled ? { color: "neutral.light", ...sx } : sx}
      />
    );
  },
);
