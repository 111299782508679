import certificate from "../../../../../assets/img/audit_certificate.png";
import { formatDate, imgToDataURL } from "../../../../../lib";

export const generateCertificatePdf = async (auditName, facilityName) => {
  // dynamically importing for code splitting to reduce pdf-lib lib effect on bundle size
  const { PDFDocument, StandardFonts, rgb, PageSizes } = await import(
    "pdf-lib"
  );

  try {
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create();

    // Add a blank page to the document
    const page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]); // landscape page
    // Get the width and height of the page
    const { width, height } = page.getSize();

    // Embed the PNG image
    const imgUrl = await imgToDataURL(certificate);
    const pngImage = await pdfDoc.embedPng(imgUrl as string);

    // Draw the PNG image across the document page
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width,
      height,
    });

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const fontColor = rgb(0.11765, 0.37255, 0.66275);

    // Add audit name text
    const auditFontSize = 24;
    const auditTextWidth = helveticaFont.widthOfTextAtSize(
      auditName,
      auditFontSize,
    );
    page.drawText(auditName, {
      x: width / 2 - auditTextWidth / 2,
      y: 420,
      size: auditFontSize,
      font: helveticaFont,
      color: fontColor,
    });

    // Add facility name text
    const facilityFontSize =
      facilityName.length > 65 ? 18 : facilityName.length > 50 ? 24 : 32;
    const facilityTextWidth = helveticaFont.widthOfTextAtSize(
      facilityName,
      facilityFontSize,
    );
    page.drawText(facilityName, {
      x: width / 2 - facilityTextWidth / 2,
      y: 320,
      size: facilityFontSize,
      font: helveticaFont,
      color: fontColor,
    });

    // Add date text
    const dateFontSize = 16;
    const dateText = formatDate(new Date());
    const dateTextWidth = helveticaFont.widthOfTextAtSize(
      dateText,
      dateFontSize,
    );
    page.drawText(dateText, {
      x: width / 2 - dateTextWidth / 2,
      y: 100,
      size: dateFontSize,
      font: helveticaFont,
      color: fontColor,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    return new File([pdfBytes], "audit_certificate", {
      type: "application/pdf",
    });
  } catch (err) {
    console.error("Error generating audit certificate: ", err);
  }
};
