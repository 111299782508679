import React, { useEffect, useState } from "react";
import {
  authSelectors,
  facilityAdminActions,
  RequiredDocumentRecord,
  useAppDispatch,
} from "../../../../state";
import { RequiredDocumentSubmissionDialog } from "../../../adminShared/components";
import { Navigation } from "../../../../lib";
import { FacilityAdminPages } from "../..";
import { useSelector } from "react-redux";
import { PermissionClaims } from "../../../../lib/constants";

interface RequiredDocumentReviewProps {
  documentId: number;
  facilityId: number;
}

export const RequiredDocumentReview = React.memo(
  /**
   *
   */
  function RequiredDocumentReview({
    documentId,
    facilityId,
  }: RequiredDocumentReviewProps) {
    const dispatch = useAppDispatch();

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const allowReview = userPermissionClaims.includes(
      PermissionClaims.RequiredDocumentsReviewClaim,
    );

    const [requiredDocument, setRequiredDocument] =
      useState<RequiredDocumentRecord>();

    useEffect(() => {
      (async () => {
        // get a single required document record for direct document-review access
        const document = await dispatch(
          facilityAdminActions.getRequiredDocument(documentId, facilityId),
        );
        if (document) setRequiredDocument(document);
      })();
    }, [dispatch, documentId, facilityId]);

    return requiredDocument ? (
      <RequiredDocumentSubmissionDialog
        allowReview={allowReview}
        document={requiredDocument}
        handleClose={() => Navigation.go(FacilityAdminPages.requiredDocuments)}
      />
    ) : null;
  },
);
