import React from "react";
import { useParams } from "react-router-dom";
import { PageRootStyled } from "../../components";
import { sysSelectors, useSelector } from "../../state";
import { PoliciesMenu, PolicyPreview } from "../shared/components";

export const PolicyPage = React.memo(
  /**
   *
   */
  function PolicyPage() {
    const params = useParams();

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    // don't render policy page until facility filter has been applied
    return facilityFilter?.id ? (
      <PageRootStyled sx={{ flexDirection: "row" }}>
        <PoliciesMenu policyId={Number(params.id)} />
        <PolicyPreview
          facilityId={facilityFilter.id}
          facilityGroupId={facilityFilter.groupID}
          policyId={Number(params.id)}
        />
      </PageRootStyled>
    ) : null;
  },
);
