import React, { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Link, Typography } from "@mui/material";
import {
  List,
  ListActionsContainerStyled,
  ListActionsMainStyled,
} from "../../../../../components";
import {
  formatDate,
  Navigation,
  RouterLink,
  useLocation,
} from "../../../../../lib";
import { ListTypes } from "../../../../../state/lists/state";
import {
  Audit,
  AuditActivityRecord,
  listActionUrls,
} from "../../../../../state";
import { AuditPageTabs } from "../../../../../lib/constants";
import { AuditStatusTimeline } from "./AuditStatusTimeline";
import { AdminPages } from "../../..";
import { styled } from "@mui/material/styles";

const TimelineContainerStyled = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "24px",
  marginBottom: "16px",
}));

interface AuditActivityListActionsProps {
  audit: Audit;
}

function AuditActivityListActions({ audit }: AuditActivityListActionsProps) {
  const location = useLocation();
  return (
    <>
      <ListActionsContainerStyled>
        <ListActionsMainStyled>
          <Typography variant="h5">Activity</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() =>
                Navigation.replace(location.pathname, {
                  query: { tab: AuditPageTabs.Audit },
                })
              }
            >
              View audit
            </Button>
          </Box>
        </ListActionsMainStyled>
        <TimelineContainerStyled>
          <Typography variant="body2" mb={2}>
            {audit.statusHistory.at(-1)?.statusDisplay} -{" "}
            {formatDate(audit.statusHistory.at(-1)?.dateTime)}
          </Typography>
          <AuditStatusTimeline history={audit.statusHistory} large={true} />
        </TimelineContainerStyled>
      </ListActionsContainerStyled>
    </>
  );
}

export const AuditActivityList = React.memo(
  /**
   *
   */
  function AuditActivityList({ audit }: { audit: Audit }) {
    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          flex: 2,
          renderCell: ({ row }: { row: AuditActivityRecord }) => (
            <Typography variant="subtitle2">
              {row.name}
              {row.linkedAuditID ? (
                <>
                  {" "}
                  - View the{" "}
                  <Link
                    component={RouterLink}
                    onClick={(e) => e.stopPropagation()}
                    to={Navigation.url(AdminPages.audit, {
                      params: {
                        id:
                          row.linkedAuditID === audit.id
                            ? row.auditID
                            : row.linkedAuditID,
                      },
                    })}
                  >
                    {row.linkedAuditID === audit.id ? "original" : "new"} audit
                  </Link>
                </>
              ) : (
                ""
              )}
            </Typography>
          ),
          sortable: false,
        },
        {
          field: "dateTime",
          flex: 1,
          renderCell: ({ row }: { row: AuditActivityRecord }) => (
            <Typography variant="body2" width="100%" textAlign="right">
              {row.createdBy ? `By ${row.createdBy} on` : "On"}{" "}
              {formatDate(row.createdOn)}
            </Typography>
          ),
          sortable: false,
        },
      ],
      [audit.id],
    );

    return (
      <List
        actions={<AuditActivityListActions audit={audit} />}
        dataUrl={listActionUrls[ListTypes.auditActivity].replace(
          ":id",
          audit.id.toString(),
        )}
        columns={columns}
        hideHeader={true}
        key={audit.status} // set key to status to trigger reload when status is updated
        name="audit activity"
        rowHeight={84}
        type={ListTypes.auditActivity}
      />
    );
  },
);
