import React from "react";
import { FacilityAuditsList } from "./components";

export const FacilityAuditsPage = React.memo(
  /**
   *
   */
  function FacilityAuditsPage() {
    return <FacilityAuditsList />;
  },
);
