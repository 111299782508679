import React from "react";
import { TrainingForm } from "./components";

export const TrainingFormPage = React.memo(
  /**
   *
   */
  function TrainingFormPage() {
    return <TrainingForm />;
  },
);
