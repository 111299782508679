import React from "react";
import { AnnouncementForm } from "./components";

export const AnnouncementFormPage = React.memo(
  /**
   *
   */
  function AnnouncementFormPage() {
    return <AnnouncementForm />;
  },
);
