import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Tooltip,
  Typography,
} from "@mui/material";
import { ListTypes } from "../../../../state/lists/state";
import {
  AbbreviatedListDisplay,
  ButtonMenu,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  SelectInput,
  ToggleInput,
} from "../../../../components";
import {
  asArray,
  convertEntityFilter,
  formatDate,
  formatFullName,
  formatPhone,
  Navigation,
  pluralizeText,
  useLocation,
} from "../../../../lib";
import { FilterListIcon, ListActionsProps } from "../../../../components";
import {
  authSelectors,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  UserRecord,
  useSelector,
} from "../../../../state";
import { ContactLink, UserStatusChipStyled } from "./UsersList.styles";
import {
  FacilityUserLevels,
  PermissionClaims,
  PortalUserTypes,
  UserCategories,
  UserStatuses,
} from "../../../../lib/constants";
import { EntityFilter } from "../../../../lib/types";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import { AdminSharedPages } from "../..";
import { FacilityStaffLoginDetails } from "./FacilityStaffLoginDetails";

const filterQueryKey = {
  facilityIDs: "facilities",
  governingBodyOnly: "governingBodyOnly",
  groupIDs: "groups",
  includeInactive: "showInactive",
  positionIDs: "positions",
  statuses: "statuses",
  text: "search",
  userCategory: "userCategory",
};

const BulkActionTypes = {
  Deactivate: "Deactivate",
  SendSetPasswordEmail: "SendSetPasswordEmail",
};

interface UsersListActionsProps extends ListActionsProps, UsersListProps {
  hasEditUsersClaim: boolean;
  isFacilityAdmin: boolean;
  onClearSelectedUsers: () => void;
  selectedUsers: UserRecord[];
}

function UsersListActions({
  filter,
  hasEditUsersClaim,
  isFacilityAdmin,
  onClearSelectedUsers,
  onFilterChange = () => {},
  listType,
  query,
  selectedUsers,
}: UsersListActionsProps) {
  const dispatch = useAppDispatch();

  const {
    userCategories: userCategoryOptions = [],
    userStatuses: userStatusOptions = [],
  } = useSelector(sysSelectors.systemSettings);

  const activeGroups = useSelector(sysSelectors.activeGroups);
  const groupOptions = useMemo(
    () => (filter?.groupId || filter?.regionId ? [] : activeGroups),
    [activeGroups, filter?.groupId, filter?.regionId],
  );

  const activeFacilities = useSelector(sysSelectors.activeFacilities);
  const facilityOptions = useMemo(
    () =>
      filter?.groupId || filter?.regionId
        ? activeFacilities.filter(
            (f) =>
              (!filter?.groupId ||
                f.groupID?.toString() === filter.groupId.toString()) &&
              (!filter?.regionId ||
                f.regionID?.toString() === filter.regionId.toString()),
          )
        : activeFacilities,
    [activeFacilities, filter?.groupId, filter?.regionId],
  );
  const positionOptions = useSelector(sysSelectors.allPositions);

  const {
    facilities = [],
    governingBodyOnly = false,
    groups = [],
    positions = [],
    showInactive = false,
    search = "",
    statuses = [],
    userCategory = "",
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(true); // per client request we are defaulting the filters to open on this list

  const [bulkActionType, setBulkActionType] = useState<string>();

  const onSubmitBulkAction = useCallback(async () => {
    const userIds = selectedUsers.map((u) => u.id);

    let submitted = false;
    switch (bulkActionType) {
      case BulkActionTypes.Deactivate:
        submitted = await dispatch(sharedActions.deactivateUsers(userIds));
        break;
      case BulkActionTypes.SendSetPasswordEmail:
        submitted = await dispatch(
          sharedActions.sendUserResetPasswordEmails(userIds),
        );
        break;
    }

    if (submitted) {
      setBulkActionType(undefined);
      onClearSelectedUsers();
    }
  }, [bulkActionType, dispatch, onClearSelectedUsers, selectedUsers]);

  const onUserCategoryChange = useCallback(
    (name, value) => {
      onFilterChange(
        name,
        value,
        // if category is set to ccgAdmin, reset filters that are N/A for ccgAdmin category
        value === UserCategories.CcgAdmin
          ? {
              facilities: [],
              governingBodyOnly: undefined,
              groups: [],
              positions: [],
            }
          : {},
      );
    },
    [onFilterChange],
  );

  const onShowInactiveChange = useCallback(
    (name, checked) => {
      onFilterChange(
        name,
        checked,
        // if statuses filter includes Inactive, remove when showInactive is unchecked
        !checked && statuses.includes(UserStatuses.Inactive)
          ? { statuses: statuses.filter((s) => s !== UserStatuses.Inactive) }
          : {},
      );
    },
    [onFilterChange, statuses],
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled sx={isFacilityAdmin ? { pt: 0 } : undefined}>
        {isFacilityAdmin ? (
          <FacilityStaffLoginDetails />
        ) : (
          <Box display="flex">
            <Typography variant="h5">Users</Typography>
            {listType !== ListTypes.relatedUsers && (
              <ToggleInput
                name="userCategory"
                onChange={onUserCategoryChange}
                options={[
                  ...userCategoryOptions.map((t) => ({
                    ...t,
                    name: pluralizeText(t.name, 2),
                  })),
                  { id: "", name: "All Users" },
                ]}
                value={userCategory}
                sx={{ ml: 2 }}
              />
            )}
          </Box>
        )}
        <Box display="flex" alignItems="center">
          {!filter?.includeInactive && (
            <CheckboxInput
              checked={!!showInactive}
              label="Show inactive"
              name="showInactive"
              onChange={onShowInactiveChange}
            />
          )}
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          {hasEditUsersClaim && (
            <>
              {selectedUsers.length > 0 &&
                (isFacilityAdmin ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ ml: 1 }}
                    onClick={() =>
                      setBulkActionType(BulkActionTypes.Deactivate)
                    }
                  >
                    Deactivate
                  </Button>
                ) : (
                  <ButtonMenu
                    buttonSx={{ height: "36px" }}
                    buttonText="Bulk actions"
                    menuItems={[
                      {
                        label: "Deactivate",
                        onClick: () =>
                          setBulkActionType(BulkActionTypes.Deactivate),
                      },
                      {
                        label: "Send set password email",
                        onClick: () =>
                          setBulkActionType(
                            BulkActionTypes.SendSetPasswordEmail,
                          ),
                      },
                    ]}
                  />
                ))}
              {!!bulkActionType && (
                <ConfirmationDialog
                  confirmDisabled={
                    bulkActionType === BulkActionTypes.SendSetPasswordEmail &&
                    selectedUsers.length > 50
                  }
                  handleClose={() => setBulkActionType(undefined)}
                  handleConfirm={onSubmitBulkAction}
                  message={selectedUsers
                    .map((u) =>
                      formatFullName({
                        firstName: u.firstName,
                        lastName: u.lastName,
                      }),
                    )
                    .join("\n")}
                  open={true}
                  title={
                    <>
                      {bulkActionType === BulkActionTypes.Deactivate
                        ? "Are you sure you'd like to deactivate"
                        : "Send a set password email to"}{" "}
                      the following{" "}
                      {pluralizeText("user", selectedUsers.length)}?
                      {bulkActionType ===
                        BulkActionTypes.SendSetPasswordEmail &&
                      selectedUsers.length > 50 ? (
                        <Typography
                          variant="body2"
                          mt="8px"
                          fontWeight="500"
                          color="error"
                        >
                          Limit your selection to 50 users in order to proceed.
                        </Typography>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  type={ConfirmationDialogTypes.Warning}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ ml: 1 }}
                onClick={() =>
                  Navigation.go(
                    AdminSharedPages.addUser.path,
                    filter
                      ? {
                          query: {
                            groupId: filter.groupId,
                            facilityId: filter.facilityId,
                            regionId: filter.regionId,
                          },
                        }
                      : undefined,
                  )
                }
              >
                Add new user
              </Button>
            </>
          )}
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search users"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
          />
          {userCategory !== UserCategories.CcgAdmin &&
            !filter?.facilityId &&
            facilityOptions.length > 1 && (
              <>
                {groupOptions.length > 1 && (
                  <SelectInput
                    label="Group"
                    name="groups"
                    value={asArray(groups)}
                    onChange={onFilterChange}
                    options={groupOptions}
                    multiple={true}
                    sx={{ mr: 1 }}
                  />
                )}
                <SelectInput
                  label="Facility"
                  name="facilities"
                  value={asArray(facilities)}
                  onChange={onFilterChange}
                  options={facilityOptions}
                  groupOpts={groupOptions.length > 1}
                  multiple={true}
                  sx={{ mr: 1 }}
                />
              </>
            )}
          {userCategory !== UserCategories.CcgAdmin && (
            <SelectInput
              label="Position"
              name="positions"
              value={asArray(positions)}
              onChange={onFilterChange}
              options={positionOptions}
              multiple={true}
              sx={{ mr: 1 }}
            />
          )}
          <SelectInput
            label="Status"
            name="statuses"
            value={asArray(statuses)}
            onChange={onFilterChange}
            options={
              showInactive
                ? userStatusOptions
                : userStatusOptions.filter(
                    (s) => s.id !== UserStatuses.Inactive,
                  )
            }
            multiple={true}
            sx={{ mr: 2 }}
          />
          {userCategory !== UserCategories.CcgAdmin && (
            <CheckboxInput
              checked={!!governingBodyOnly}
              label="Governing body only"
              name="governingBodyOnly"
              onChange={onFilterChange}
            />
          )}
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

interface UsersListProps {
  filter?: EntityFilter;
  listType?: string;
}

export const UsersList = React.memo(
  /**
   *
   */
  function UsersList(props: UsersListProps) {
    const { filter, listType = ListTypes.users } = props;

    const userId = useSelector(authSelectors.userId);
    const userType = useSelector(authSelectors.userType);
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const hasEditUsersClaim = userPermissionClaims.includes(
      PermissionClaims.UsersEditClaim,
    );
    const facilityFilter = useSelector(sysSelectors.facilityFilter);
    const isFacilityAdmin = userType === PortalUserTypes.FacilityAdmin;

    const [selectedUsers, setSelectedUsers] = useState<UserRecord[]>([]);

    const entityFilter = useMemo(
      () =>
        isFacilityAdmin && facilityFilter
          ? { facilityId: facilityFilter.id }
          : filter,
      [facilityFilter, filter, isFacilityAdmin],
    );

    const listFilter = useMemo(
      () => (entityFilter ? convertEntityFilter(entityFilter) : undefined),
      [entityFilter],
    );

    const {
      query: { userCategory },
    } = useLocation();

    const columns: GridColDef[] = useMemo(() => {
      let columns = [
        {
          field: "name",
          headerName: "User",
          flex: 2,
          renderCell: ({ row }: { row: UserRecord }) => (
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: row.isGoverningBody ? "bold" : "normal",
                }}
              >
                {formatFullName({
                  firstName: row.firstName,
                  lastName: row.lastName,
                })}
              </Typography>
              <ContactLink
                href={`mailto:${row.email}`}
                onClick={(e) => e.stopPropagation()}
                target="_blank"
              >
                {row.email}
              </ContactLink>
              {isFacilityAdmin && (
                <ContactLink
                  href={`tel:${row.phone}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {formatPhone(row.phone)}
                </ContactLink>
              )}
            </Box>
          ),
        },
        {
          field: "createdOn",
          headerName: "Added",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "lastLogin",
          headerName: "Last Login",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "positions",
          headerName: "Position",
          flex: 2.25,
          renderCell: ({ row }: { row: UserRecord }) => (
            <>
              {row.isGoverningBody && (
                <Tooltip title="Governing body">
                  <Chip
                    color="warning"
                    label="GB"
                    size="small"
                    sx={{
                      color: "background.default",
                      mr: row.positions.length ? 1 : 0,
                      overflow: "visible !important",
                    }}
                  />
                </Tooltip>
              )}
              <AbbreviatedListDisplay items={row.positions} />
            </>
          ),
          sortable: false,
        },
        // exclude group/region/facility column when sublist facility filter is applied
        ...(!(filter && "facilityId" in filter)
          ? [
              {
                field: "groupRegionFacility",
                headerName: "Group/Region/Facility",
                flex: 2,
                renderCell: ({ row }: { row: UserRecord }) => {
                  switch (row.facilityUserLevel) {
                    case FacilityUserLevels.Group:
                      return <AbbreviatedListDisplay items={row.groups} />;
                    case FacilityUserLevels.Region:
                      return <AbbreviatedListDisplay items={row.regions} />;
                    case FacilityUserLevels.Facility:
                      return <AbbreviatedListDisplay items={row.facilities} />;
                  }
                  return "";
                },
                sortable: false,
              },
            ]
          : []),
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: UserRecord }) => (
            <UserStatusChipStyled
              label={row.status}
              size="small"
              status={row.status}
            />
          ),
        },
      ];

      // exclude group/region/facility and positions columns when CCG Admin user category filter is applied
      if (userCategory === UserCategories.CcgAdmin) {
        columns = columns.filter(
          (c) => c.field !== "groupRegionFacility" && c.field !== "positions",
        );
      }

      return columns;
    }, [filter, isFacilityAdmin, userCategory]);

    const onRowClick = useCallback(({ row }: { row: UserRecord }) => {
      Navigation.go(AdminSharedPages.editUser, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={
          <UsersListActions
            {...props}
            hasEditUsersClaim={hasEditUsersClaim}
            isFacilityAdmin={isFacilityAdmin}
            onClearSelectedUsers={() => setSelectedUsers([])}
            selectedUsers={selectedUsers}
            filter={entityFilter}
          />
        }
        checkboxSelection={hasEditUsersClaim}
        columns={columns}
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        isRowSelectable={
          ({ row }) =>
            row.id !== userId && // disable users from selecting their own user record for bulk actions
            row.status !== UserStatuses.Inactive // disable inactive user selection
        }
        onRowClick={hasEditUsersClaim ? onRowClick : undefined}
        name="users"
        onCheckboxSelection={(selection) => setSelectedUsers(selection)}
        rowHeight={84}
        selection={selectedUsers}
        stickyHeader={true}
        type={listType}
      />
    );
  },
);
