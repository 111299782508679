import React, { useEffect, useRef, useState } from "react";
import { Transition } from "history";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  ConfirmationDialogTypes,
} from "../../components";
import { useBlocker } from "./RouterHooks";

export interface RouterPromptProps
  extends Omit<
    ConfirmationDialogProps,
    "open" | "handleClose" | "handleConfirm" | "loading"
  > {
  when: boolean;
}

export function RouterPrompt(props: RouterPromptProps) {
  const { when, ...confirmationDialogProps } = props;

  const [confirmed, setConfirmed] = useState(false);
  const [promptTx, setPromptTx] = useState<Transition | null>(null);

  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  useBlocker((tx: Transition) => {
    setPromptTx(tx);
  }, when && !confirmed);

  useEffect(() => {
    if (confirmed && promptTx) {
      promptTx.retry();

      setTimeout(() => {
        // reset state if mounted after prompt confirmation retry
        if (isMountedRef.current) {
          setPromptTx(null);
          setConfirmed(false);
        }
      }, 10);
    }
  }, [confirmed, promptTx]);

  const handleCancel = () => setPromptTx(null);
  const handleConfirm = () => setConfirmed(true);

  return promptTx ? (
    <ConfirmationDialog
      {...confirmationDialogProps}
      cancelText={confirmationDialogProps.cancelText || "Go back"}
      confirmText={confirmationDialogProps.confirmText || "Leave"}
      handleClose={handleCancel}
      handleConfirm={handleConfirm}
      message={
        confirmationDialogProps.message || "Changes you made will not be saved."
      }
      open={true}
      title={confirmationDialogProps.title || "Are you sure you want to leave?"}
      type={confirmationDialogProps.type || ConfirmationDialogTypes.Warning}
    />
  ) : null;
}
