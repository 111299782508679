import React, { useCallback } from "react";
import { Navigation, useLocation } from "../../lib";
import { paginationQueryKey } from "./PaginationHelpers";

export interface ListActionsProps {
  onFilterChange?: (name: string, value: any, other?: object) => void;
  onFilterChangeEvt?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  query?: any;
}

// Wrapper element for usage with actions component provided to PaginatedList (when useQuery is true) - children will be injected with filter change handler params
export const ListActionsWrapper = ({ children, queryKeyPrefix = "" }) => {
  const { pathname, query } = useLocation();

  const onFilterChange = useCallback(
    (filterName, filterValue, otherFilters = {}) => {
      const urlConfig = {
        query: {
          ...query,
          [filterName]: filterValue || "",
          ...otherFilters,
          [queryKeyPrefix + paginationQueryKey.page]: 1,
        },
      };
      Navigation.replace(pathname, urlConfig);
    },
    [pathname, query, queryKeyPrefix],
  );

  const onFilterChangeEvt = useCallback(
    (e) =>
      onFilterChange(
        e.target.name,
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
      ),
    [onFilterChange],
  );

  return React.cloneElement(children, {
    onFilterChange,
    onFilterChangeEvt,
    query,
  });
};
