import React, { useEffect } from "react";
import { Navigation } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthPages } from ".";
import { BackdropLoader } from "../../components";

export const LogoutPage = React.memo(
  /**
   *
   */
  function LogoutPage() {
    const logout = useAction(authActions.logout);

    useEffect(() => {
      logout().then(() => {
        Navigation.reload(AuthPages.login);
      });
    }, [logout]);

    return <BackdropLoader loading={true} />;
  },
);
