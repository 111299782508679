import React from "react";
import { TrainingPacketForm } from "./components";

export const TrainingPacketFormPage = React.memo(
  /**
   *
   */
  function TrainingPacketFormPage() {
    return <TrainingPacketForm />;
  },
);
