import React from "react";
import { PoliciesList } from "./components";

export const PoliciesPage = React.memo(
  /**
   *
   */
  function PoliciesPage() {
    return <PoliciesList />;
  },
);
