import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const SearchInputContainerStyled = styled(Box)(() => ({
  maxWidth: "600px",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

export const SearchHeaderStyled = styled(Box)(() => ({
  maxWidth: "600px",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

export const SearchResultContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "34px",
  "& div:nth-of-type(1)": {
    display: "flex",
    alignItems: "center",
  },
  "& div:nth-of-type(2)": {
    marginLeft: "40px",
  },
}));

export const HighlightTextStyled = styled(Typography)(() => ({
  whiteSpace: "normal",
  "& tag": {
    fontWeight: "bold",
  },
}));

export const NoResultsSubTextStyled = styled(Typography)(() => ({
  display: "block",
  maxWidth: "400px",
  marginTop: "12px",
}));
