import React, { useCallback, useEffect, useRef } from "react";
import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import { LinkIcon } from "../icons";

interface CopyLinkButtonProps {
  sx?: SxProps<Theme>;
  tooltip?: string;
  url: string;
}

export const CopyLinkButton = React.memo(
  /**
   *
   */
  function CopyLinkButton({ sx, tooltip, url }: CopyLinkButtonProps) {
    const [showCopied, setShowCopied] = React.useState(false);

    const isMountedRef = useRef(true);
    useEffect(() => {
      return () => void (isMountedRef.current = false);
    }, []);

    const onCopyLink = useCallback(
      (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(url);
        setShowCopied(true);
        if (!tooltip) {
          setTimeout(() => {
            if (isMountedRef.current) {
              setShowCopied(false);
            }
          }, 2000);
        }
      },
      [tooltip, url],
    );

    return (
      <Tooltip
        color="success"
        onClose={() => setShowCopied(false)}
        title={showCopied ? "Link copied!" : tooltip || ""}
        {...(!tooltip
          ? {
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
              open: showCopied,
            }
          : {})}
      >
        <IconButton onClick={onCopyLink} sx={sx}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
    );
  },
);
