import React from "react";
import { EntityFilter } from "../../../../../lib/types";
import { ListTypes } from "../../../../../state/lists/state";
import { UsersList } from "../../../../adminShared/components";

interface UsersSublistProps {
  filter: EntityFilter;
}

export const UsersSublist = React.memo(
  /**
   *
   */
  function UsersSublist({ filter }: UsersSublistProps) {
    return <UsersList filter={filter} listType={ListTypes.relatedUsers} />;
  },
);
