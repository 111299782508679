import React, { useState } from "react";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { FacilityFileRecord } from "../../../../state";
import {
  CloseIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "../../../../components";
import { FacilityFilePreview } from "./FacilityFilePreview";

interface PrintFilesDialogProps {
  files: FacilityFileRecord[];
  handleClose: () => void;
}

export const PrintFilesDialog = React.memo(
  /**
   *
   */
  function PrintFilesDialog({ files, handleClose }: PrintFilesDialogProps) {
    const [printPreviewIndex, setPrintPreviewIndex] = useState(0);

    return (
      <Dialog open={true} maxWidth="md" fullWidth={true}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Box display="flex" justifyContent="flex-end" margin="16px 16px 8px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <FacilityFilePreview
            facilityFileId={files[printPreviewIndex].id}
            printOnly={true}
            printToolbarAddon={
              <Box display="flex" alignItems="center">
                <IconButton
                  disabled={printPreviewIndex === 0}
                  onClick={() => setPrintPreviewIndex(printPreviewIndex - 1)}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography sx={{ padding: "0px 16px" }}>
                  {printPreviewIndex + 1} of {files.length}
                </Typography>
                <IconButton
                  disabled={printPreviewIndex + 1 === files.length}
                  onClick={() => setPrintPreviewIndex(printPreviewIndex + 1)}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Box>
            }
          />
        </Box>
      </Dialog>
    );
  },
);
