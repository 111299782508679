import React, { useEffect } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { VisibilityIcon, VisibilityOffIcon } from "../icons";
import { TextField } from "./TextField";
import { FieldAttributes } from "formik";

interface PasswordFieldProps extends FieldAttributes<any> {
  showPassword?: boolean;
  showVisibilityIcon?: boolean;
}

export const PasswordField = React.memo(
  /**
   *
   */
  function PasswordField({
    showPassword: initialShowPassword = false,
    showVisibilityIcon = true,
    ...rest
  }: PasswordFieldProps) {
    const [showPassword, setShowPassword] = React.useState(initialShowPassword);
    useEffect(() => {
      if (!showVisibilityIcon) {
        setShowPassword(false);
      }
    }, [showVisibilityIcon]);
    return (
      <TextField
        {...rest}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: showVisibilityIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
