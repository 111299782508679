import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid-pro";
import {
  appBarHeight,
  facilityHeaderHeight,
} from "../../layouts/main/drawer/AppBar.styles";

// custom class names
// rows
export const clickableRowClassName = "clickable-row";
export const dividerRowClassName = "divider-row";
export const subdividerRowClassName = "subdivider-row";
// headers
export const textWrapHeaderClassName = "text-wrap-header";
// other
export const scrollArrowsClassName = "scroll-arrows";

interface ListContainerStyledProps {
  hideHeader?: boolean;
  hideSelectAll?: boolean;
  noResults?: boolean;
  stickyHeader?: boolean;
  withPageHeader?: boolean;
}

export const ListContainerStyled = styled(`div`, {
  shouldForwardProp: (prop) =>
    prop !== "hideHeader" &&
    prop !== "hideSelectAll" &&
    prop !== "noResults" &&
    prop !== "stickyHeader" &&
    prop !== "withPageHeader",
})<ListContainerStyledProps>(
  ({
    hideHeader,
    hideSelectAll,
    noResults,
    stickyHeader,
    withPageHeader,
    theme,
  }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: noResults ? "fit-content" : "auto",
    [`.${gridClasses.root}`]: {
      border: "none",
    },
    [`.${gridClasses.main}`]: {
      overflow: "unset",
    },
    [`.${gridClasses.columnSeparator}`]: {
      color: theme.palette.neutral.light,
      "&:hover": {
        color: theme.palette.neutral.dark,
      },
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
      color: theme.palette.text.secondary,
      fontSize: "14px",
      fontWeight: "bold",
    },
    [`.${gridClasses.cell}, .${gridClasses.cell} *`]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      "& .MuiFormControl-root *": {
        overflow: "initial",
        textOverflow: "initial",
      },
    },
    [`.${gridClasses.columnHeader}:not(.${gridClasses.columnHeaderCheckbox}), .${gridClasses.cell}:not(.${gridClasses.cellCheckbox})`]:
      {
        padding: "0 16px !important",
      },
    [`.${gridClasses.columnHeaders}, .${gridClasses.cell}`]: {
      borderBottomColor: `${theme.palette.neutral.light} !important`,
    },
    [`.${gridClasses.cell}:focus .${gridClasses.columnHeader}:focus, .${gridClasses.cell}:focus, .${gridClasses.columnHeader}:focus-within, .${gridClasses.cell}:focus-within`]:
      {
        outline: "none !important",
      },
    [`.${gridClasses.cellContent}, .${gridClasses["cell--withRenderer"]}`]: {
      cursor: "default",
    },
    // 'clickable-row' class styles
    [`.${gridClasses.row}.${clickableRowClassName}, .${gridClasses.row}.${clickableRowClassName} .${gridClasses.cellContent}, .${gridClasses.row}.${clickableRowClassName} .${gridClasses["cell--withRenderer"]}`]:
      {
        cursor: "pointer",
      },
    // 'divider-row' class styles
    [`.${gridClasses.row}.${dividerRowClassName} > .${gridClasses.cell}`]: {
      borderBottom: `6px solid ${theme.palette.text.secondary} !important`,
    },
    // 'subdivider-row' class styles
    [`.${gridClasses.row}.${subdividerRowClassName} > .${gridClasses.cell}`]: {
      borderBottom: `3px solid ${theme.palette.text.secondary} !important`,
    },
    // 'text-wrap-header' class styles
    [`.${gridClasses.columnHeader}.${textWrapHeaderClassName} .${gridClasses.columnHeaderTitle}`]:
      {
        whiteSpace: "pre-wrap",
        lineHeight: "normal",
      },
    // 'scroll-arrows' container class styles
    [`.${scrollArrowsClassName}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 3, // position above sticky header which has a z-index of 2
      marginLeft: "280px",
      "& button": {
        marginBottom: "-52px",
      },
      ...(stickyHeader
        ? {
            position: "sticky",
            top: appBarHeight + (withPageHeader ? facilityHeaderHeight : 0),
          }
        : {}),
    },
    // hidden header stylees
    ...(hideHeader
      ? {
          [`.${gridClasses.columnHeaders}`]: {
            borderBottom: "none",
          },
        }
      : {}),
    // sticky header styles
    ...(stickyHeader
      ? {
          [`.${gridClasses.columnHeaders}`]: {
            position: "sticky",
            width: "100%",
            top: appBarHeight + (withPageHeader ? facilityHeaderHeight : 0),
            backgroundColor: theme.palette.background.default,
            zIndex: 2, // z-index of 2 to ensure that sticky header appears above pinned columns
          },
        }
      : {}),
    // hidden select-all checkbox styles
    ...(hideSelectAll
      ? {
          [`.${gridClasses.columnHeaderCheckbox} .${gridClasses.columnHeaderTitleContainer}`]:
            {
              display: "none",
            },
        }
      : {}),
  }),
);

export const DynamicRowHeightListContainerStyled = styled(ListContainerStyled)(
  () => ({
    /* Allows the MUI grid container to expand to child height */
    [`.${gridClasses.virtualScrollerContent}`]: {
      height: "100% !important",
    },

    /* Allows the MUI grid rows to expand to child height */
    [`.${gridClasses.row}`]: {
      maxHeight: "none !important", //fit-content
    },

    /* Allows the MUI grid rows to expand to child height */
    [`.${gridClasses.cell}`]: {
      maxHeight: "none !important", //fit-content
      overflow: "auto",
      whiteSpace: "initial !important",
      lineHeight: "16px !important",
      display: "flex !important",
      alignItems: "center",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
    },
  }),
);

export const NoRowsOverlayStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
}));
