import React, { useCallback } from "react";
import { Link, Button } from "@mui/material";
import { Navigation, useLocation, yup, RouterLink } from "../../lib";
import { authActions, useAppDispatch } from "../../state";
import { AuthPages } from ".";
import { Formik, Form } from "formik";
import { TextField } from "../../components";
import {
  AuthCardStyled,
  BackToLoginBoxStyled,
  ButtonBoxStyled,
  SubtitleTextStyled,
  TitleTextStyled,
} from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const RequestSetPasswordPage = React.memo(
  /**
   *
   */
  function RequestSetPasswordPage() {
    const dispatch = useAppDispatch();
    const {
      query: { email, forgot },
    } = useLocation();

    const handleSubmit = useCallback(
      async (values) => {
        const success = await dispatch(authActions.requestPasswordLink(values));
        if (success) {
          Navigation.go(AuthPages.passwordRequestSent, {
            query: { email: values.email, forgot },
          });
        }
      },
      [dispatch, forgot],
    );

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">
          {forgot ? "Forgot" : "Set"} Password
        </TitleTextStyled>
        <SubtitleTextStyled variant="body2">
          Enter your email address and we will send you a link to{" "}
          {forgot ? "reset" : "set"} your password.
        </SubtitleTextStyled>
        <Formik
          initialValues={{
            email: email || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <TextField autoFocus autofill name="email" label="Email" />
              <ButtonBoxStyled>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {forgot ? "Reset" : "Set"} Password
                </Button>
              </ButtonBoxStyled>
              <BackToLoginBoxStyled>
                <Link component={RouterLink} to="/auth/login">
                  Back to sign in
                </Link>
              </BackToLoginBoxStyled>
            </Form>
          )}
        </Formik>
      </AuthCardStyled>
    );
  },
);
