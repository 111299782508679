import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  ParamsSerializerOptions,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { auth, AuthState } from "./state";
import { getQueryParam, Navigation } from "../../lib";
import { AppThunk } from "../types";
import { sysActions } from "../sys/actions";
import { uiActions } from "../ui/actions";
import { AuthPages } from "../../pages";
const { actions } = auth;

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.delete(url, config));
  },
  // download(url: string, config?: AxiosRequestConfig) {
  //   return Promise.reject("TODO: Implement apiDownload.");
  // },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.post<T>(url, data, config));
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.put<T>(url, data, config));
  },
};

async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    error = err;
    return err.response || {};
  });
  const res = await promise;
  if (error) {
    console.error({ error, res });
    if (res?.status === 401) {
      redirectToLogin();
    }
  }

  return res;
}

function clearStorePersist() {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
}

function redirectToLogin() {
  clearStorePersist();
  window.location.replace(
    "/auth/login?after=" +
      encodeURIComponent(window.location.pathname + window.location.search),
  );
}

function logout(dispatch) {
  clearStorePersist();
  dispatch(actions.setAuthState(undefined));
  dispatch(sysActions.resetSystemState());
}

const serializeParams: ParamsSerializerOptions = {
  serialize: (params: Record<string, any>) => qs.stringify(params),
};
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

interface AuthActionResponse {
  success?: boolean;
  message?: string;
}

// #endregion

const restrictedIpResponseMessage = "IP access restricted";
const restrictedIpDisplayMessage =
  "Contact your facility’s compliance officer for login assistance.";

function getResponseErrorMessage(e: AxiosError | unknown) {
  const msg = axios.isAxiosError(e) && e.response?.data?.message;
  if (msg === restrictedIpResponseMessage) {
    return restrictedIpDisplayMessage;
  }
  return msg;
}

function navToLoginVerification(mfaInfo) {
  const urlConfig = {
    query: {
      after: getQueryParam("after") || null,
      method: mfaInfo.contactMethodType,
      to: mfaInfo.maskedSentToValue,
      altMethod: mfaInfo.altContactMethodType,
    },
  };
  Navigation.replace(AuthPages.verifyLogin, urlConfig);
}

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthState): AppThunk {
    let authState: AuthState;
    if (authResponseData) {
      const { expiration, token } = authResponseData;
      const authExpired = expiration && new Date(expiration) < new Date();
      if (token && !authExpired) {
        authState = authResponseData;
      }
    }
    return async (dispatch, getState) => {
      if (authState) {
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  login(values: {
    username: string;
    password: string;
  }): AppThunk<Promise<AuthActionResponse>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      await logout(dispatch);
      try {
        const { data } = await axios.post(`/auth/login/basic`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        //handle redirect based on mfa requirement
        const { mfaInfo, requiresMfa } = data;
        if (requiresMfa) {
          navToLoginVerification(mfaInfo);
        }
        dispatch(uiActions.setLoading(false));
        dispatch(authActions.load(data));
        return { success: true };
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        return { success: false, message: getResponseErrorMessage(e) };
      }
    };
  },
  verifyLogin(values: {
    code: string;
    rememberMe: boolean;
  }): AppThunk<Promise<AuthActionResponse>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/auth/verifyMfa`, {
        params: values,
        withCredentials: true,
      });
      if (status !== 200) {
        dispatch(uiActions.setLoading(false));
        return { success: false, message: data.message };
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(authActions.load(data));
        return { success: true };
      }
    };
  },
  logout(): AppThunk {
    return async (dispatch) => {
      logout(dispatch);
    };
  },
  requestPasswordLink(values: { email: string }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      await logout(dispatch);
      try {
        await axios.post(`/account/forgotPw`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        dispatch(uiActions.setLoading(false));
        return true;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError(getResponseErrorMessage(e)));
        return false;
      }
    };
  },
  setPassword(values: {
    newPw: string;
    token: string;
    email: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        await axios.post(`/account/pwReset`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        await logout(dispatch);
        dispatch(uiActions.setLoading(false));
        return true;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError(getResponseErrorMessage(e)));
        return false;
      }
    };
  },
  sendVerificationCode(
    method: string,
    methodDescription: string,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/auth/sendMfa`, {
        params: { method },
      });
      if (status !== 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(
          uiActions.showError(
            data?.message || "Failed to send verification code",
          ),
        );
        return false;
      } else {
        //reset nav query based on mfa details
        const { mfaInfo } = data;
        navToLoginVerification(mfaInfo);
        dispatch(
          uiActions.showMessage(
            `Request sent. Check your ${methodDescription} for a login verification code.`,
          ),
        );
        dispatch(uiActions.setLoading(false));
        return true;
      }
    };
  },
  confirmEmail(values: {
    token: string;
    email: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        await logout(dispatch);
        await axios.post(`/account/confirmEmail`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        dispatch(uiActions.setLoading(false));
        return true;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError(getResponseErrorMessage(e)));
        return false;
      }
    };
  },
};
