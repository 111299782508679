import { styled } from "@mui/material/styles";

interface RootStyledProps {
  formOpen: boolean;
}
export const RootStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "formOpen",
})<RootStyledProps>(({ formOpen }) => ({
  display: "flex",
  height: "100%",
  overflow: formOpen ? "hidden" : "initial",
  "& > div:first-of-type": {
    width: "100%",
    overflowY: formOpen ? "auto" : "initial",
  },
}));
