import { styled } from "@mui/material/styles";
import { IconProps } from "@mui/material";
import { LocalHospitalIcon } from "../icons";
import { FacilityTypes } from "../../lib/constants";
import { fuchsia, lightBlue, purple, turquoise } from "../../themes";

interface FacilityTypeIconStyledProps extends IconProps {
  type: string;
}

export const FacilityTypeIconStyled = styled(LocalHospitalIcon, {
  shouldForwardProp: (prop) => prop !== "type",
})<FacilityTypeIconStyledProps>(({ theme, type }) => {
  switch (type) {
    case FacilityTypes.SNF:
      return { color: theme.palette.info.light };
    case FacilityTypes.ALF:
      return { color: theme.palette.warning.dark };
    case FacilityTypes.Hospital:
      return { color: theme.palette.secondary.dark };
    case FacilityTypes.FQHC:
      return { color: turquoise };
    case FacilityTypes.HomeHealthcare:
      return { color: fuchsia };
    case FacilityTypes.ABA:
      return { color: purple };
    case FacilityTypes.AssistedLiving:
      return { color: lightBlue };
    default:
      return { color: theme.palette.neutral.main };
  }
});
