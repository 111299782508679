import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BaseTextFieldProps, TextField } from "@mui/material";
import { formatDate } from "../../lib";

interface DateInputProps extends BaseTextFieldProps {
  dateOnly?: boolean;
  disableWeekends?: boolean;
  onChange: (val) => void;
  value: Date | string;
}

export const DateInput = React.memo(
  /**
   *
   */
  function DateInput({
    dateOnly,
    disableWeekends,
    onChange,
    value,
    ...textFieldProps
  }: DateInputProps) {
    return (
      <DatePicker
        ignoreInvalidInputs={true}
        onChange={(value) => onChange(dateOnly ? formatDate(value) : value)}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            onChange={(e) => {
              // clear value when field is manually cleared
              if (!e.target.value) {
                onChange("");
              }
            }}
          />
        )}
        shouldDisableDate={
          disableWeekends
            ? (date) => {
                const dayOfWeek = new Date(date).getDay();
                return dayOfWeek === 0 || dayOfWeek === 6;
              }
            : undefined
        }
      />
    );
  },
);
