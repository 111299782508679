import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  exportPdfAnnotations,
  PdfFormViewer,
  ViewerModes,
} from "../../../../components/pdf/PdfFormViewer";
import { Button, Typography } from "@mui/material";
import {
  formatDate,
  Navigation,
  useLocation,
  useSecureFileRead,
  useWebViewer,
} from "../../../../lib";
import {
  Audit,
  AuditVersion,
  authSelectors,
  NameValuePair,
  useSelector,
} from "../../../../state";
import {
  AuditCommentTypes,
  AuditStatuses,
  PortalUserTypes,
} from "../../../../lib/constants";
import { AuditCommentsPanel, CommentsPanelModes } from "./AuditCommentsPanel";
import {
  AuditBannerStyled,
  ViewerContainerStyled,
} from "./AuditFormViewer.styles";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  WarningIcon,
} from "../../../../components";
import { CorrectionAnnotationSubject } from "./AuditHelpers";

interface AuditFormViewerProps {
  audit: Audit;
  auditVersion?: AuditVersion;
  onSaveAudit: (
    annotations: string,
    fields: NameValuePair[],
    progressPageNumber: number,
    submit: boolean,
  ) => Promise<boolean>;
  onSaveAuditCommentAnnotations?: (annotations: string) => Promise<boolean>;
  setAudit: React.Dispatch<React.SetStateAction<Audit | undefined>>;
}

export const AuditFormViewer = React.memo(
  /**
   *
   */
  function AuditFormViewer({
    audit,
    auditVersion,
    onSaveAudit,
    onSaveAuditCommentAnnotations,
    setAudit,
  }: AuditFormViewerProps) {
    const userInfo = useSelector(authSelectors.userInfo);
    const userPositions = useSelector(authSelectors.facilityUserPositions);
    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const location = useLocation();
    const { edit, questionId } = location.query;

    const handleToggleEdit = useCallback(
      (editOn: boolean) => {
        Navigation.replace(location.pathname, {
          query: { ...location.query, edit: editOn || "" },
        });
      },
      [location.pathname, location.query],
    );

    const [webViewerUrl, setWebViewerUrl] = useState("");

    const getSecureFileUrl = useSecureFileRead("audit");

    useEffect(() => {
      (async () => {
        const fileUrl = await getSecureFileUrl(audit.url, true);
        return setWebViewerUrl(fileUrl);
      })();
    }, [audit.url, getSecureFileUrl]);

    const [resumeProgressPromptChecked, setResumeProgressPromptChecked] =
      useState(false);
    const [showResumeProgressPrompt, setShowResumeProgressPrompt] =
      useState(false);

    useEffect(() => {
      if (
        isCcgAdmin ||
        resumeProgressPromptChecked ||
        !location.state?.resumePrompt
      )
        return;

      const inProgressStatuses = [
        AuditStatuses.SentInProgress,
        AuditStatuses.ResentCorrectionsNeeded,
      ];
      if (
        inProgressStatuses.includes(audit.status) &&
        auditVersion?.progressPageNumber &&
        auditVersion?.progressPageNumber > 1
      ) {
        setShowResumeProgressPrompt(true);
      }
      setResumeProgressPromptChecked(true);

      // clear navigation state to prevent re-prompting when navigating back to this page
      Navigation.replaceState();
    }, [
      audit.status,
      auditVersion?.progressPageNumber,
      isCcgAdmin,
      location.state?.resumePrompt,
      resumeProgressPromptChecked,
    ]);

    const { viewerInstance } = useWebViewer();

    const handleSave = useCallback(
      async (submit: boolean, fields: NameValuePair[]) => {
        const {
          Core: { annotationManager, documentViewer },
        } = viewerInstance;

        const annotations = await exportPdfAnnotations(annotationManager);
        const currentPage = documentViewer.getCurrentPage();

        const saved = await onSaveAudit(
          annotations,
          fields,
          currentPage,
          submit,
        );

        // for ccg admin user, toggle to non edit mode after saving
        if (saved && isCcgAdmin) {
          handleToggleEdit(!edit);
        }
      },
      [edit, handleToggleEdit, isCcgAdmin, onSaveAudit, viewerInstance],
    );

    const handleSaveCommentAnnotations = useCallback(async () => {
      if (!onSaveAuditCommentAnnotations) return false;

      const annotations = await exportPdfAnnotations(
        viewerInstance.Core.annotationManager,
      );

      return await onSaveAuditCommentAnnotations(annotations);
    }, [onSaveAuditCommentAnnotations, viewerInstance?.Core.annotationManager]);

    const isLatestVersion =
      !auditVersion?.id || auditVersion.id === audit.versions[0]?.id;
    const viewerMode = useMemo(() => {
      if (!isLatestVersion) {
        return ViewerModes.ReadOnly;
      }

      switch (audit.status) {
        case AuditStatuses.Rescinded:
        case AuditStatuses.Completed:
        case AuditStatuses.CompletedCertificateSent:
          return ViewerModes.ReadOnly;
      }

      if (isCcgAdmin) {
        switch (audit.status) {
          case AuditStatuses.SentNotStarted:
            return ViewerModes.ReadOnly;
          default:
            return edit ? ViewerModes.Edit : ViewerModes.Comment;
        }
      } else {
        switch (audit.status) {
          case AuditStatuses.AwaitingGrade:
          case AuditStatuses.FollowUpNeeded:
          case AuditStatuses.ReadyForReview:
            return ViewerModes.ReadOnly;
          default:
            return ViewerModes.Edit;
        }
      }
    }, [audit.status, edit, isCcgAdmin, isLatestVersion]);

    const commentsPanelMode = useMemo(() => {
      switch (audit.status) {
        case AuditStatuses.SentNotStarted:
        case AuditStatuses.SentInProgress:
          if (!isCcgAdmin) {
            return CommentsPanelModes.HideCorrections;
          }
          break;
        case AuditStatuses.AwaitingGrade:
        case AuditStatuses.FollowUpNeeded:
        case AuditStatuses.ReadyForReview:
          if (!isCcgAdmin && audit.submittedVersionsCount <= 1) {
            return CommentsPanelModes.HideCorrections;
          }
          break;
        case AuditStatuses.Rescinded:
        case AuditStatuses.Completed:
        case AuditStatuses.CompletedCertificateSent:
          return CommentsPanelModes.ReadOnly;
      }

      return CommentsPanelModes.Default;
    }, [audit.status, audit.submittedVersionsCount, isCcgAdmin]);

    const failedToRetrieveAnnotations =
      auditVersion?.id && !auditVersion.annotations;
    const warningMessage = useMemo(() => {
      // if there are no annotations for a saved audit version, warn to avoid overriding saved data
      if (failedToRetrieveAnnotations) {
        return `Failed to retrieve audit responses.${
          viewerMode !== ViewerModes.ReadOnly
            ? ` Refresh the page before making any updates${
                isCcgAdmin ? ", comments, or corrections" : ""
              } to avoid overriding saved changes.`
            : ""
        }`;
      }

      if (isCcgAdmin) {
        switch (audit.status) {
          case AuditStatuses.SentNotStarted:
            return "This audit has not yet been started by the facility.";
          case AuditStatuses.SentInProgress:
          case AuditStatuses.ResentCorrectionsNeeded:
            return "This audit may be being worked on by the facility. Avoid updating content to prevent conflicting with facility changes.";
        }
      } else {
        switch (audit.status) {
          case AuditStatuses.AwaitingGrade:
          case AuditStatuses.FollowUpNeeded:
          case AuditStatuses.ReadyForReview:
            return "Audit is under review by CCG and is read-only.";
          case AuditStatuses.ResentCorrectionsNeeded:
            return `This audit is in correction mode.${
              audit.unresolvedCorrectionNumbers.length
                ? ` Please see comments on questions ${audit.unresolvedCorrectionNumbers.join(
                    ", ",
                  )}.`
                : ""
            }`;
        }
      }
    }, [
      audit.unresolvedCorrectionNumbers,
      audit.status,
      failedToRetrieveAnnotations,
      isCcgAdmin,
      viewerMode,
    ]);

    const userFullName = `${userInfo.firstName} ${userInfo.lastName}`;

    const fieldPrefills = useMemo(() => {
      if (isCcgAdmin || audit.status !== AuditStatuses.SentNotStarted) return; // prefill fields in unstarted audits for non ccg admins only
      return {
        Facility: audit.facilityName,
        FacilityPhone: audit.facilityPhone,
        State: audit.facilityState,
        Date: formatDate(new Date()),
        ConductedBy: userFullName,
        Position: userPositions?.join(", "),
      };
    }, [
      audit.facilityName,
      audit.facilityPhone,
      audit.facilityState,
      audit.status,
      isCcgAdmin,
      userFullName,
      userPositions,
    ]);

    if (audit.url && !webViewerUrl) return null;

    return (
      <>
        {warningMessage && (
          <AuditBannerStyled warning={true}>
            <WarningIcon />
            <Typography variant="body2" ml={2}>
              {warningMessage}
            </Typography>
          </AuditBannerStyled>
        )}
        {viewerInstance && showResumeProgressPrompt && auditVersion && (
          <ConfirmationDialog
            cancelText="Start at the beginning"
            confirmText={`Go to page ${auditVersion.progressPageNumber}`}
            handleClose={() => setShowResumeProgressPrompt(false)}
            handleConfirm={() => {
              viewerInstance.Core.documentViewer.setCurrentPage(
                auditVersion.progressPageNumber,
              );
              setShowResumeProgressPrompt(false);
            }}
            open={true}
            message={`Would you like to pick up where you left off \non page ${auditVersion.progressPageNumber}?`}
            title="Resume audit"
            type={ConfirmationDialogTypes.Continue}
          />
        )}
        <ViewerContainerStyled>
          <PdfFormViewer
            allowLayoutChange={isCcgAdmin}
            annotations={auditVersion?.annotations}
            autoFocus={true}
            customButtons={
              isCcgAdmin && viewerMode !== ViewerModes.ReadOnly ? (
                <Button
                  variant={edit ? "outlined" : "contained"}
                  size="large"
                  onClick={() => handleToggleEdit(!edit)}
                >
                  {edit ? "Exit" : "Edit"}
                </Button>
              ) : undefined
            }
            dataId={`${audit.id}`}
            // per client request, we've removed the functionality to allow switching between version views
            // to accommodate switching between versions (and reloading the document to refresh between versions), set the dataId to `${audit.id}-${auditVersion?.id}`
            // note: alternative to dataId method is to set a key which will re-instantiate the PdfFormViewer when changing between versions (not ideal to reload webviewer but can resort to this if accommodating version changes causes too much complexity)
            dataUrl={webViewerUrl}
            defaultSinglePageView={true}
            fieldPrefills={fieldPrefills}
            fieldValidation={
              !isCcgAdmin
                ? {
                    expectedValue: userFullName,
                    fieldName: "ConductedBy",
                    message: `The Conducted By response does not match your user name. It will be updated to '${userFullName}' before submission.`,
                  }
                : undefined
            }
            handleSave={handleSave}
            highlightFields={
              isCcgAdmin ? auditVersion?.updatedFields : undefined // highlight changed fields for ccg admin only
            }
            hideAnnotationsBySubject={CorrectionAnnotationSubject} // default correction annotations to hidden (shown via AuditCommentsPanel effects)
            name="audit"
            showProgressBar={true}
            showSave={true}
            showSubmit={!isCcgAdmin}
            submitConfirmationDescription={
              audit.status === AuditStatuses.ResentCorrectionsNeeded
                ? "made all corrections"
                : "answered all the questions"
            }
            submitDisabledDescription={
              audit.hasIncompleteCorrectiveActions
                ? "Complete the related Corrective Action Plan in order to submit this audit. If you feel that the CAP is not needed, please enter N/A and the reason why it is not needed."
                : ""
            }
            unsavedChangesPrompt={
              !isCcgAdmin &&
              audit.status === AuditStatuses.ResentCorrectionsNeeded
                ? {
                    cancelColor: "primary",
                    cancelText: "Return to audit",
                    confirmColor: "error",
                    confirmText: "Leave without saving",
                    message:
                      "Your progress will not be saved. Your audit was not submitted and will not be reviewed by CCG until you complete the required updates and resubmit the audit.",
                    reverseButtons: true,
                    title: "Leave without saving audit?",
                    type: ConfirmationDialogTypes.Alert,
                    width: "420px",
                  }
                : undefined
            }
            validateRequiredFields={true}
            viewerMode={viewerMode}
          />
          <AuditCommentsPanel
            audit={audit}
            auditVersion={auditVersion}
            initialType={
              questionId ? AuditCommentTypes.FacilityQuestion : undefined
            }
            isCcgAdmin={isCcgAdmin}
            handleSaveAnnotations={handleSaveCommentAnnotations}
            mode={commentsPanelMode}
            setAudit={setAudit}
          />
        </ViewerContainerStyled>
      </>
    );
  },
);
