import React, { useMemo } from "react";
import {
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  Link,
} from "@mui/material";
import {
  ErrorIcon,
  FileUploadField,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  HelpIcon,
  NumberField,
  PhoneField,
  SelectField,
  TextField,
  ToggleField,
} from "../../../../../components";
import { WizardStepProps } from "./WizardStep";
import { GroupRegionChangeConfirmationDetails } from "./GroupRegionChangeConfirmation";
import {
  BasicStatuses,
  BasicStatusOptions,
  EntityTypes,
  FacilityStatuses,
  FileTypes,
} from "../../../../../lib/constants";
import { WizardModes } from "./GroupFacilityRegionWizardHelpers";
import { sysSelectors, useSelector } from "../../../../../state";
import { styled } from "@mui/material/styles";
import { HelperTextStyled } from "./Wizard.styles";

const StatusTooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    sx={{ ml: 1, color: "text.secondary" }}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});

export const WizardStep1 = React.memo(
  /**
   *
   */
  function WizardStep1({
    form,
    groupOptions,
    mode,
    regionOptions,
    stateOptions,
    type,
  }: WizardStepProps) {
    const { facilityStatuses = [], facilityTypes = [] } = useSelector(
      sysSelectors.systemSettings,
    );

    const statusOptions = useMemo(() => {
      let statuses: any[] = [];

      if (type === EntityTypes.Facility) {
        // filter statuses for facility
        switch (form.initialValues.status) {
          case FacilityStatuses.Active:
          case FacilityStatuses.OnHold:
            statuses = facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Active ||
                id === FacilityStatuses.Inactive ||
                id === FacilityStatuses.OnHold,
            );
            break;
          case FacilityStatuses.Inactive:
          case FacilityStatuses.Onboarding:
            statuses = facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Active ||
                id === FacilityStatuses.Inactive ||
                id === FacilityStatuses.Onboarding ||
                id === FacilityStatuses.OnHold,
            );
            break;
          case FacilityStatuses.Pending:
          case FacilityStatuses.Review:
            statuses = facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Pending ||
                id === FacilityStatuses.Review ||
                id === FacilityStatuses.Onboarding,
            );
            break;
          default:
            statuses = facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Pending ||
                id === FacilityStatuses.Onboarding,
            );
            break;
        }
      } else {
        statuses = BasicStatusOptions;
      }

      // filter statuses if group/region parent is inactive
      let isGroupOrRegionInactive = false;
      if (form.values.groupID) {
        const group = groupOptions.find((g) => g.id === form.values.groupID);
        isGroupOrRegionInactive = group?.status === BasicStatuses.Inactive;

        if (!isGroupOrRegionInactive && form.values.regionID) {
          const region = regionOptions.find(
            (r) => r.id === form.values.regionID,
          );
          isGroupOrRegionInactive = region?.status === BasicStatuses.Inactive;
        }
      }

      if (isGroupOrRegionInactive) {
        const inactiveEnabledStatuses = [
          FacilityStatuses.Pending,
          FacilityStatuses.Review,
          FacilityStatuses.Inactive,
          BasicStatuses.Inactive,
        ];
        return statuses.map((s) => ({
          ...s,
          disabled: !inactiveEnabledStatuses.includes(s.id),
        }));
      }

      return statuses;
    }, [
      type,
      form.initialValues.status,
      form.values.groupID,
      form.values.regionID,
      facilityStatuses,
      groupOptions,
      regionOptions,
    ]);

    // Facility statuses details
    const showStatusDescriptionsTooltip =
      type === EntityTypes.Facility && mode === WizardModes.New;

    const showPendingStatusSuggestion =
      type === EntityTypes.Facility &&
      mode === WizardModes.New &&
      form.values.status !== FacilityStatuses.Pending &&
      !form.isValid &&
      form.submitCount > 0;

    // Facility type and state cannot be edited after onboarding
    const disableFacilityTypeField =
      type === EntityTypes.Facility &&
      mode === WizardModes.Edit &&
      !!form.initialValues.type;

    const disableStateField =
      type === EntityTypes.Facility &&
      mode === WizardModes.Edit &&
      !!form.initialValues.stateID;

    return (
      <>
        <FormGridItemFullWidthStyled>
          <Typography variant="h6">{type} Information</Typography>
        </FormGridItemFullWidthStyled>
        <FormGridItemStyled item>
          <TextField name="name" label={`${type} name`} />
        </FormGridItemStyled>
        {type === EntityTypes.Facility && mode !== WizardModes.FacilityAdmin && (
          <FormGridItemStyled item>
            <Tooltip
              title={
                disableFacilityTypeField
                  ? `Facility type cannot be changed for an ${
                      form.values.status === FacilityStatuses.Onboarding
                        ? "onboarding"
                        : "onboarded"
                    } facility`
                  : ""
              }
            >
              <Box>
                <SelectField
                  disabled={disableFacilityTypeField}
                  name="type"
                  label="Facility type"
                  options={facilityTypes}
                />
              </Box>
            </Tooltip>
          </FormGridItemStyled>
        )}
        {(type === EntityTypes.Facility ||
          (type === EntityTypes.Region && !form.values.id)) && ( // Region group cannot be edited
          <FormGridItemStyled item>
            <SelectField
              name="groupID"
              label="Group"
              options={groupOptions}
              helperText={
                form.values.id ? (
                  <Tooltip
                    title={
                      <GroupRegionChangeConfirmationDetails
                        groupChange={true}
                        tooltip={true}
                      />
                    }
                  >
                    <HelperTextStyled variant="caption">
                      <ErrorIcon /> Changing the group will affect access
                    </HelperTextStyled>
                  </Tooltip>
                ) : undefined
              }
            />
          </FormGridItemStyled>
        )}
        {type === EntityTypes.Facility && regionOptions.length > 0 && (
          <FormGridItemStyled item>
            <SelectField
              name="regionID"
              label="Region"
              options={regionOptions}
              disabled={!form.values.groupID}
              helperText={
                form.values.id ? (
                  <Tooltip
                    title={
                      <GroupRegionChangeConfirmationDetails
                        initialRegionID={form.initialValues.regionID}
                        regionChange={true}
                        tooltip={true}
                      />
                    }
                  >
                    <HelperTextStyled variant="caption">
                      <ErrorIcon /> Changing the region will affect access
                    </HelperTextStyled>
                  </Tooltip>
                ) : undefined
              }
            />
          </FormGridItemStyled>
        )}
        <FormGridItemStyled item>
          <TextField name="address1" label="Address line 1" />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <TextField name="address2" label="Address line 2" />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <TextField name="city" label="City" />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <Tooltip
            title={
              disableStateField
                ? mode === WizardModes.FacilityAdmin
                  ? "Contact CCG to update the facility state"
                  : `State cannot be changed for an ${
                      form.values.status === FacilityStatuses.Onboarding
                        ? "onboarding"
                        : "onboarded"
                    } facility`
                : ""
            }
          >
            <Box>
              <SelectField
                disabled={disableStateField}
                name="stateID"
                label="State"
                options={stateOptions}
              />
            </Box>
          </Tooltip>
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <NumberField
            allowLeadingZeros={true}
            isNumericString={true}
            name="zip"
            label="Zip code"
            thousandSeparator={false}
          />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <PhoneField name="phone1" label="Phone 1" />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <PhoneField name="phone2" label="Phone 2" />
        </FormGridItemStyled>
        {type === EntityTypes.Facility && (
          <FormGridItemStyled item>
            <TextField name="ipAddress" label="IP address" />
            <Typography fontSize="12px" color="primary" mt={1}>
              {mode !== WizardModes.FacilityAdmin
                ? "Facilities can find their IP address"
                : "IP address can be found"}{" "}
              at{" "}
              <Link
                href="https://www.whatismyip.com/"
                sx={{ fontSize: "12px" }}
                target="_blank"
              >
                whatismyip.com
              </Link>{" "}
              or by contacting tech support.
            </Typography>
          </FormGridItemStyled>
        )}
        {/* Note: we are hiding number of beds from the UI for now */}
        {/* {type === EntityTypes.Facility && (
          <FormGridItemStyled item>
            <NumberField name="numberOfBeds" label="Number of beds" />
          </FormGridItemStyled>
        )} */}
        <FormGridItemFullWidthStyled>
          <Typography variant="h6">{type} Logo</Typography>
        </FormGridItemFullWidthStyled>
        <FormGridItemFullWidthStyled>
          <FileUploadField
            fileType={FileTypes.Image}
            name="logoUrl"
            description="logo"
            thumbnailPreview={true}
          />
        </FormGridItemFullWidthStyled>
        {mode !== WizardModes.FacilityAdmin && (
          <>
            <FormGridItemFullWidthStyled>
              <Typography variant="h6" display="flex" alignItems="center">
                Status
                {showStatusDescriptionsTooltip && (
                  <StatusTooltipStyled
                    title={
                      <Box whiteSpace="pre-wrap">
                        <Box>
                          Profile pending:
                          <Box ml={2}>
                            • Allows you to continue without completing all
                            required fields.
                            <br />• Grants facility users access to the form to
                            allow them to submit their facility information.
                          </Box>
                        </Box>
                        {statusOptions.find(
                          (s) => s.id === FacilityStatuses.Review,
                        ) && (
                          <Box>
                            Profile review:
                            <Box ml={2}>
                              • Facility users will no longer have access to
                              this form to submit their facility information.
                            </Box>
                          </Box>
                        )}
                        <Box>
                          Onboarding
                          <Box ml={2}>
                            • Only allowed once all required information is
                            complete.
                          </Box>
                        </Box>
                      </Box>
                    }
                  >
                    <HelpIcon fontSize="small" />
                  </StatusTooltipStyled>
                )}
              </Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <Tooltip
                title={
                  statusOptions.some((o) => o.disabled)
                    ? `Statuses are disabled due to the ${type.toLowerCase()}'s inactive ${
                        type === EntityTypes.Facility
                          ? "group or region"
                          : "group"
                      }`
                    : ""
                }
              >
                <Box>
                  <ToggleField
                    name="status"
                    options={statusOptions}
                    size="large"
                  />
                </Box>
              </Tooltip>
            </FormGridItemFullWidthStyled>
            {showPendingStatusSuggestion && (
              <Typography variant="caption" mt="-24px" color="error.main">
                Please complete all required fields or set the status to Profile
                pending to continue
              </Typography>
            )}
          </>
        )}
      </>
    );
  },
);
