import React from "react";
import { Box } from "@mui/material";
import { ErrorTextStyled } from "../styled";
import { FieldArray, useField } from "formik";
import { FileUploadField, FileUploadFieldProps } from "./FileUploadField";

interface FileListUploadFieldProps extends FileUploadFieldProps {}

export const FileListUploadField = React.memo(
  /**
   *
   */
  function FileListUploadField({
    description,
    name,
    ...passProps
  }: FileListUploadFieldProps) {
    const [{ value }, meta] = useField(name);

    const error = meta.touched ? meta.error : "";

    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          return (
            <>
              {value.map((_, fileIndex) => {
                return (
                  <Box key={fileIndex} mb={2} width="100%">
                    <FileUploadField
                      description={description}
                      handleDelete={() => arrayHelpers.remove(fileIndex)}
                      name={`${name}[${fileIndex}]`}
                      {...passProps}
                    />
                  </Box>
                );
              })}
              <Box pt={1} pb={1} width="100%">
                <FileUploadField
                  description={`${value.length ? "additional " : ""}${
                    description || name
                  }`}
                  name={`${name}[${value.length}]`}
                  {...passProps}
                />
              </Box>
              {error && <ErrorTextStyled mt={1}>{error}</ErrorTextStyled>}
            </>
          );
        }}
      />
    );
  },
);
