import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  BasicResource,
  authSelectors,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import {
  FullWidthFormGridItemStyled,
  SelectInput,
} from "../../../../components";
import { exportRequiredDocumentsData } from "./RequiredDocumentsExportHelpers";
import {
  PortalUserTypes,
  RequiredDocumentFrequencies,
  ResourceTypes,
} from "../../../../lib/constants";
import { getCurrentQuarter, getCurrentYear } from "../../../../lib";

const currentQuarter = getCurrentQuarter();
const currentYear = getCurrentYear();

const quarterOptions = [1, 2, 3, 4].map((q) => ({ id: q, name: q.toString() }));
const yearOptions = Array.from(
  { length: 10 },
  (_, index) => currentYear - index,
).map((y) => ({ id: y, name: y.toString() }));

interface RequiredDocumentsExportDialogProps {
  handleClose: () => void;
}

export const RequiredDocumentsExportDialog = React.memo(
  /**
   *
   */
  function RequiredDocumentsExportDialog({
    handleClose,
  }: RequiredDocumentsExportDialogProps) {
    const dispatch = useAppDispatch();

    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const [filters, setFilters] = useState({
      quarter: currentQuarter,
      year: currentYear,
      resourceIDs: [] as number[],
      groupIDs: [] as number[],
      regionIDs: [] as number[],
      facilityIDs: [] as number[],
    });

    const [requiredDocumentResources, setRequiredDocumentResources] = useState<
      BasicResource[]
    >([]);

    useEffect(() => {
      (async () => {
        const resources = await dispatch(
          sharedActions.getBasicResources({
            type: ResourceTypes.RequiredDocument,
            requiredFrequency: RequiredDocumentFrequencies.Quarterly, // limit to quarterly required docs
          }),
        );
        if (resources) {
          setRequiredDocumentResources(resources);
        }
      })();
    }, [dispatch]);

    const groupOptions = useSelector(sysSelectors.allGroups);
    const regions = useSelector(sysSelectors.allRegions);
    const facilities = useSelector(
      isCcgAdmin ? sysSelectors.allFacilities : sysSelectors.activeFacilities,
    );

    const regionOptions = useMemo(
      () =>
        !filters.groupIDs.length
          ? regions
          : regions.filter((r) => filters.groupIDs.includes(r.groupID)),
      [filters.groupIDs, regions],
    );

    const facilityOptions = useMemo(
      () =>
        filters.regionIDs.length
          ? facilities.filter(
              (f) => f.regionID && filters.regionIDs.includes(f.regionID),
            )
          : filters.groupIDs.length
          ? facilities.filter((f) => filters.groupIDs.includes(f.groupID))
          : facilities,
      [facilities, filters.groupIDs, filters.regionIDs],
    );

    const onChangeFilters = useCallback((name, value) => {
      setFilters((filters) => ({ ...filters, [name]: value }));
    }, []);

    const onChangeGroups = useCallback(
      (_, value) => {
        const didRemoveGroups = value?.length < filters.groupIDs.length;

        const updatedFilters = { ...filters, groupIDs: value };

        if (didRemoveGroups) {
          //clear out selected regions and facilities that are no longer part of the group selection
          if (filters.regionIDs.length) {
            const availableRegionIds = regionOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            updatedFilters.regionIDs = updatedFilters.regionIDs.filter((i) =>
              availableRegionIds.includes(i),
            );
          }
          if (filters.facilityIDs.length) {
            const availableFacilityIds = facilityOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            updatedFilters.facilityIDs = updatedFilters.facilityIDs.filter(
              (i) => availableFacilityIds.includes(i),
            );
          }
        }

        setFilters(updatedFilters);
      },
      [facilityOptions, filters, regionOptions],
    );

    const onChangeRegions = useCallback(
      (_, value) => {
        const didRemoveRegions = value?.length < filters.regionIDs.length;

        const updatedFilters = { ...filters, regionIDs: value };

        if (didRemoveRegions) {
          //clear out selected facilities that are no longer part of the region selection
          if (filters.facilityIDs.length) {
            const availableFacilityIds = facilityOptions
              .filter((o) => value.includes(o.regionID))
              .map((o) => o.id);
            updatedFilters.facilityIDs = updatedFilters.facilityIDs.filter(
              (i) => availableFacilityIds.includes(i),
            );
          }
        }

        setFilters(updatedFilters);
      },
      [facilityOptions, filters],
    );

    const handleSubmit = useCallback(async () => {
      const data = await dispatch(
        sharedActions.getRequiredDocumentsExport(filters),
      );

      if (data) {
        await exportRequiredDocumentsData(
          requiredDocumentResources.filter((r) =>
            filters.resourceIDs.includes(r.id),
          ),
          data,
        );
        handleClose();
      }
    }, [dispatch, filters, handleClose, requiredDocumentResources]);

    return (
      <Dialog open={true}>
        <DialogTitle variant="h6">
          Configure report
          <Typography variant="body2" mt={2}>
            Configure which quarterly required documents you'd like to show on
            your report
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container paddingTop="16px">
            <FullWidthFormGridItemStyled item display="flex">
              <SelectInput
                clearable={false}
                name="quarter"
                label="Select quarter"
                options={quarterOptions}
                onChange={onChangeFilters}
                sx={{ marginRight: "16px" }}
                value={filters.quarter}
              />
              <SelectInput
                clearable={false}
                name="year"
                label="Select year"
                options={yearOptions}
                onChange={onChangeFilters}
                value={filters.year}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="resourceIDs"
                label="Select required documents"
                options={requiredDocumentResources.map((r) => ({
                  id: r.id,
                  name: r.name,
                }))}
                onChange={onChangeFilters}
                selectAll={true}
                value={filters.resourceIDs}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="groupIDs"
                label="Select groups"
                options={groupOptions}
                onChange={onChangeGroups}
                selectAll={true}
                value={filters.groupIDs}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="regionIDs"
                label="Select regions"
                options={regionOptions}
                onChange={onChangeRegions}
                selectAll={true}
                value={filters.regionIDs}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="facilityIDs"
                label="Select facilities"
                options={facilityOptions}
                onChange={onChangeFilters}
                selectAll={true}
                value={filters.facilityIDs}
              />
            </FullWidthFormGridItemStyled>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !filters.quarter ||
              !filters.year ||
              !filters.resourceIDs.length ||
              (!filters.groupIDs.length &&
                !filters.regionIDs.length &&
                !filters.facilityIDs.length)
            }
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
