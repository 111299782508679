import React from "react";
import { Box, BoxProps, ToggleButton, Typography } from "@mui/material";
import { Field, FieldAttributes, useField } from "formik";
import { ToggleButtonGroup } from "formik-mui";
import { Option } from "../../lib/types";
import { styled } from "@mui/material/styles";

interface ToggleFieldContainerStyledProps extends BoxProps {
  error?: string;
}

export const ToggleFieldContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})<ToggleFieldContainerStyledProps>(({ theme, error }) => ({
  "& button": {
    borderColor: error ? theme.palette.error.light : undefined,
  },
  "& p": {
    fontSize: 12,
    color: theme.palette.error.light,
  },
}));

interface ToggleFieldOption extends Option {
  disabled?: boolean;
}

interface ToggleFieldProps extends FieldAttributes<any> {
  buttonWidth?: string;
  options: ToggleFieldOption[];
}

export const ToggleField = React.memo(
  /**
   *
   */
  function ToggleField({
    buttonWidth,
    name,
    options,
    ...rest
  }: ToggleFieldProps) {
    const [, meta] = useField(name);

    const error = meta.touched ? meta.error : "";

    return (
      <ToggleFieldContainerStyled error={error}>
        <Field
          component={ToggleButtonGroup}
          exclusive
          name={name}
          type="checkbox"
          {...rest}
        >
          {options.map((o) => (
            <ToggleButton
              key={o.id}
              value={o.id}
              disabled={o.disabled}
              sx={{ width: buttonWidth }}
            >
              {o.name}
            </ToggleButton>
          ))}
        </Field>
        {error && <Typography>{error}</Typography>}
      </ToggleFieldContainerStyled>
    );
  },
);
