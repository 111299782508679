import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ListContainerStyled = styled(Box)(({ theme }) => ({
  width: "290px",
  minWidth: "290px",
  borderLeft: `1px solid ${theme.palette.divider}`,
  paddingLeft: "24px",
  marginLeft: "24px",
  "& > div": {
    height: "calc(100% - 48px)",
    overflowY: "auto",
  },
}));

export const SubmissionCardStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "10px",
  marginBottom: "12px",
}));

interface GoverningBodyConfirmationBannerStyledProps {
  confirmed: boolean;
  updated: boolean;
}

export const GoverningBodyConfirmationBannerStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "confirmed" && prop !== "updated",
})<GoverningBodyConfirmationBannerStyledProps>(({ confirmed, updated }) => ({
  fontSize: "14px",
  borderRadius: "3px",
  padding: "10px",
  width: "100%",
  backgroundColor: confirmed ? "#F0FDF6" : updated ? "#FFF8F1" : "#FDEEF0",
  marginTop: "8px",
}));
