import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RootStyled = styled(`div`)(({ theme }) => ({
  minWidth: "316px",
  width: "316px",
  borderLeft: "1px solid",
  borderLeftColor: theme.palette.divider,
  padding: "0 24px",
  marginLeft: "24px",
}));

export const SidebarTitleStyled = styled(Typography)(({ theme }) => ({
  padding: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const SidebarRowStyled = styled(`div`)(({ theme }) => ({
  padding: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
