import { PageDefinitions } from "../../lib/types";
import { ConfirmEmailPage } from "./ConfirmEmailPage";
import { LoginPage } from "./LoginPage";
import { LogoutPage } from "./LogoutPage";
import { PasswordRequestSentPage } from "./PasswordRequestSentPage";
import { RequestSetPasswordPage } from "./RequestSetPasswordPage";
import { SetPasswordPage } from "./SetPasswordPage";
import { VerifyLoginPage } from "./VerifyLoginPage";

export const AuthPages: PageDefinitions = {
  login: {
    anon: true,
    path: "/auth/login",
    title: "Login",
    view: LoginPage,
  },
  verifyLogin: {
    anon: true,
    path: "/auth/verify-login",
    title: "Verify Login",
    view: VerifyLoginPage,
  },
  logout: {
    anon: true,
    path: "/auth/logout",
    title: "Logout",
    view: LogoutPage,
  },
  requestSetPassword: {
    anon: true,
    path: "/auth/request-set-password",
    title: "Set Password",
    view: RequestSetPasswordPage,
  },
  passwordRequestSent: {
    anon: true,
    path: "/auth/password-request-sent",
    title: "Pasword Request Sent",
    view: PasswordRequestSentPage,
  },
  setPassword: {
    anon: true,
    path: "/auth/set-password",
    title: "Set Password",
    view: SetPasswordPage,
  },
  confirmEmail: {
    anon: true,
    path: "/auth/confirm-email",
    title: "Confirm Email",
    view: ConfirmEmailPage,
  },
};
