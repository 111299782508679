import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  adminActions,
  Policy,
  PolicyVersion,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import {
  Form,
  listFormat,
  pluralizeText,
  replaceEmptyProps,
  replaceNullProps,
  useFormik,
} from "../../../../../lib";
import * as yup from "yup";
import {
  AutocompleteField,
  ButtonMenu,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DialogCheckboxContainerStyled,
  FullWidthFormGridItemStyled,
  TextField,
  ToggleInput,
} from "../../../../../components";
import { validateYupSchema, yupToFormErrors } from "formik";
import { Option } from "../../../../../lib/types";
import {
  ApplicableForTypes,
  PolicyVersionPublishStatuses,
} from "../../../../../lib/constants";

const validationSchema = yup.object({
  name: yup
    .string()
    .max(250, "Name cannot exceed 250 characters")
    .when("id", {
      is: (id) => !!id,
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  facilityIDs: yup.array().when("$applicableFor", {
    is: (applicableFor) => applicableFor === ApplicableForTypes.Facility,
    then: (schema) => schema.min(1, "Facility is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  stateIDs: yup.array().when("$applicableFor", {
    is: (applicableFor) => applicableFor === ApplicableForTypes.State,
    then: (schema) => schema.min(1, "State is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  url: yup
    .string()
    .max(500, "URL cannot exceed 500 characters")
    .required("URL is required")
    .test(
      "google-doc-url",
      "Incorrect URL format",
      (value) => !value || value.toLowerCase().includes("docs.google.com"),
    ),
});

interface PolicyVersionDialogProps {
  handleClose: () => void;
  policy: Policy;
  policyVersionId?: number;
  publishStatus?: string;
  refreshPolicy: () => void;
}

const newPolicyVersionValues: PolicyVersion = {
  name: "",
  facilityIDs: [],
  stateIDs: [],
  url: "",
};

const initialConfirmChangesPrompt = {
  confirmText: "",
  message: "",
  title: "",
  show: false,
  showNotifyFacilitiesCheckbox: false,
  notifyFacilities: false,
};

const getOptionsDescription = (options: Option[], ids: number[]) =>
  listFormat(
    options.filter((o) => ids.includes(Number(o.id))).map((o) => o.name),
  );

export const PolicyVersionDialog = React.memo(
  /**
   *
   */
  function PolicyVersionDialog({
    handleClose,
    policy,
    policyVersionId,
    publishStatus,
    refreshPolicy,
  }: PolicyVersionDialogProps) {
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState<PolicyVersion>(
      newPolicyVersionValues,
    );

    const [applicableFor, setApplicableFor] = useState(
      ApplicableForTypes.State,
    );

    const [addAnother, setAddAnother] = useState(false);
    const [confirmChangesPrompt, setConfirmChangesPrompt] = useState(
      initialConfirmChangesPrompt,
    );

    const { states } = useSelector(sysSelectors.systemSettings);
    const facilities = useSelector(sysSelectors.allFacilities);
    const groups = useSelector(sysSelectors.allGroups);

    const stateOptions = useMemo(() => {
      // disable states that are included in other policy versions
      const notAllowedStateIds =
        policy.stateIDs.filter((s) => !initialValues.stateIDs.includes(s)) ||
        [];
      return states.map((s) => ({
        ...s,
        disabled: notAllowedStateIds.includes(s.id),
      }));
    }, [initialValues.stateIDs, policy.stateIDs, states]);

    const facilityOptions = useMemo(() => {
      // disable facilities that are included in other policy versions
      const notAllowedFacilityIds =
        policy.facilityIDs.filter(
          (f) => !initialValues.facilityIDs.includes(f),
        ) || [];
      return facilities
        .filter((f) => f.type === policy.facilityType) // exclude facilities that don't match the policy's facility type
        .map((f) => ({
          ...f,
          disabled: notAllowedFacilityIds.includes(f.id),
          name:
            facilities.filter((f2) => f2.name === f.name).length > 1 // if there is another facility with a matching name, append group name to the facility label
              ? `${f.name} (${groups.find((g) => g.id === f.groupID)?.name})`
              : f.name,
        }));
    }, [
      facilities,
      groups,
      initialValues.facilityIDs,
      policy.facilityIDs,
      policy.facilityType,
    ]);

    useEffect(() => {
      (async () => {
        if (policyVersionId) {
          const policyVersion = await dispatch(
            adminActions.getPolicyVersion(Number(policy.id), policyVersionId),
          );
          if (policyVersion) {
            setInitialValues(replaceNullProps(policyVersion));
            if (policyVersion.facilityIDs.length) {
              setApplicableFor(ApplicableForTypes.Facility);
            }
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyVersionId]);

    const getChangesForPrompt = useCallback(
      (values: PolicyVersion, setPrompt = true) => {
        const isPublished =
          publishStatus === PolicyVersionPublishStatuses.Published ||
          publishStatus === PolicyVersionPublishStatuses.UnpublishedChanges;

        const updatedUrl = values.url !== initialValues.url;
        const addedFacilityIDs = values.facilityIDs.filter(
          (f) => !initialValues.facilityIDs.includes(f),
        );
        const removedFacilityIDs = initialValues.facilityIDs.filter(
          (f) => !values.facilityIDs.includes(f),
        );
        const addedStateIDs = values.stateIDs.filter(
          (s) => !initialValues.stateIDs.includes(s),
        );
        const removedStateIDs = initialValues.stateIDs.filter(
          (s) => !values.stateIDs.includes(s),
        );

        const promptForChanges = isPublished
          ? updatedUrl ||
            addedFacilityIDs?.length ||
            removedFacilityIDs?.length ||
            addedStateIDs?.length ||
            removedStateIDs?.length
          : removedFacilityIDs?.length;

        if (!promptForChanges) {
          return;
        }

        const publish =
          isPublished &&
          !!(updatedUrl || addedFacilityIDs?.length || addedStateIDs?.length);

        if (setPrompt) {
          const prompt = {
            ...initialConfirmChangesPrompt,
            show: true,
            showNotifyFacilitiesCheckbox: publish,
            confirmText: publish ? "Update and publish" : "Update",
            title: publish
              ? "Publish version updates?"
              : "Update version settings?",
          };

          if (isPublished) {
            if (updatedUrl) {
              prompt.message =
                "Updating the document URL for a published policy version will publish the version for all applicable states and facilities.";
            } else {
              if (removedFacilityIDs?.length || removedStateIDs?.length) {
                if (removedFacilityIDs?.length) {
                  const removedFacilitiesDescription = getOptionsDescription(
                    facilityOptions,
                    removedFacilityIDs,
                  );
                  prompt.message += `This policy version will be removed for the ${removedFacilitiesDescription} ${pluralizeText(
                    "facility",
                    removedFacilityIDs.length,
                    "facilities",
                  )}.`;
                  // when removing facilities from a version for a policy that has state-specific versions:
                  if (policy.stateIDs.length) {
                    prompt.message += `\n\nIf there ${
                      removedFacilityIDs.length === 1
                        ? `is a state specific policy version applicable for ${removedFacilitiesDescription}, republish the version in order to generate the policy for the facility`
                        : "are state specific policy versions applicable for these facilities, republish the versions in order to generate the policy for the facilities"
                    }.`;
                  }
                }
                if (removedStateIDs?.length) {
                  prompt.message += `This policy version will be removed for all facilities in ${getOptionsDescription(
                    stateOptions,
                    removedStateIDs,
                  )}.`;
                }
              }

              if (addedFacilityIDs?.length || addedStateIDs?.length) {
                if (prompt.message.length) {
                  prompt.message += "\n\n───\n\n";
                }

                if (addedFacilityIDs?.length) {
                  prompt.message += `Adding ${
                    addedFacilityIDs.length === 1 ? "a facility" : "facilities"
                  } to this version will publish the version for the ${pluralizeText(
                    "facility",
                    addedFacilityIDs.length,
                    "facilities",
                  )}.`;
                }
                if (addedStateIDs?.length) {
                  prompt.message += `Adding ${
                    addedStateIDs.length === 1 ? "a state" : "states"
                  } to this version will publish the version for all facilities in the ${pluralizeText(
                    "state",
                    addedStateIDs.length,
                  )}.`;
                }
              }
            }
          } else {
            // if version is unpublished, check only for removed facilities to notify re publishing state versions for the facilities
            if (removedFacilityIDs?.length && policy.stateIDs.length) {
              const removedFacilitiesDescription = getOptionsDescription(
                facilityOptions,
                removedFacilityIDs,
              );
              prompt.message += `${removedFacilitiesDescription} will be removed from the policy version. \nIf there ${
                removedFacilityIDs.length === 1
                  ? `is a state specific policy version applicable for ${removedFacilitiesDescription}, republish the version in order to generate the policy for the facility`
                  : "are state specific policy versions applicable for these facilities, republish the versions in order to generate the policy for the facilities"
              }.`;
            }
          }

          setConfirmChangesPrompt(prompt);
        }

        return {
          addedFacilityIDs,
          addedStateIDs,
          removedFacilityIDs,
          removedStateIDs,
          updatedUrl,
          publish,
        };
      },
      [
        facilityOptions,
        initialValues.facilityIDs,
        initialValues.stateIDs,
        initialValues.url,
        policy.stateIDs.length,
        publishStatus,
        stateOptions,
      ],
    );

    const getValuesForSubmission = useCallback(
      (values) => {
        const valuesForSubmission = { ...values };

        // clear out facilities if applicable-for-type is state, clear states if type is facility (not clearing until submission to persist data when user toggles back and forth)
        if (
          applicableFor === ApplicableForTypes.State &&
          values.facilityIDs.length
        ) {
          valuesForSubmission.facilityIDs = [];
        } else if (
          applicableFor === ApplicableForTypes.Facility &&
          values.stateIDs.length
        ) {
          valuesForSubmission.stateIDs = [];
        }

        // default the version name for a new policy version
        if (!values.name) {
          let names: string[] = [];
          if (applicableFor === ApplicableForTypes.State) {
            names = stateOptions
              .filter((s) => values.stateIDs.includes(s.id))
              .map((s) => s.name);
          } else {
            names = facilityOptions
              .filter((f) => values.facilityIDs.includes(f.id))
              .map((f) => f.name);
          }
          let defaultVersionName = names.slice(0, 2).join(", ");
          if (names.length > 2) {
            defaultVersionName += ` +${names.length - 2}`;
          }
          valuesForSubmission.name = defaultVersionName;
        }

        return valuesForSubmission;
      },
      [facilityOptions, stateOptions, applicableFor],
    );

    const onSubmit = useCallback(
      async (
        values: PolicyVersion,
        { resetForm },
        changesConfirmed = false,
      ) => {
        const valuesForSubmission = getValuesForSubmission(values);

        // check for changes that require confirmation
        const changesForPrompt = getChangesForPrompt(
          valuesForSubmission,
          !changesConfirmed,
        );
        if (!changesConfirmed && changesForPrompt) {
          return;
        }

        const savedPolicyVersion = await dispatch(
          adminActions.submitPolicyVersion(
            Number(policy.id),
            replaceEmptyProps(valuesForSubmission),
            policy.hasVersions, // don't refresh list if there were previously no versions - list will load when policy is refreshed
          ),
        );

        if (savedPolicyVersion) {
          if (changesForPrompt?.publish) {
            const publishChangesForAll = changesForPrompt.updatedUrl; // publish changes for the entire policy version if url has been updated, otherwise specify added states/facilities for publish
            await dispatch(
              adminActions.publishPolicyVersion(
                Number(policy.id),
                Number(savedPolicyVersion.id),
                {
                  notifyFacilities: confirmChangesPrompt.notifyFacilities,
                  ...(publishChangesForAll
                    ? {
                        publishChanges: true,
                      }
                    : {
                        stateIds: changesForPrompt.addedStateIDs,
                        facilityIds: changesForPrompt.addedFacilityIDs,
                      }),
                },
              ),
            );
          }

          if (addAnother) {
            resetForm({ values: newPolicyVersionValues });
          } else {
            handleClose();
          }

          refreshPolicy();
        } else if (confirmChangesPrompt.show) {
          setConfirmChangesPrompt(initialConfirmChangesPrompt);
        }
      },
      [
        addAnother,
        confirmChangesPrompt.notifyFacilities,
        confirmChangesPrompt.show,
        dispatch,
        getChangesForPrompt,
        getValuesForSubmission,
        handleClose,
        policy.hasVersions,
        policy.id,
        refreshPolicy,
      ],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            applicableFor,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      onSubmit,
    });

    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

    const onCancel = useCallback(() => {
      if (form.dirty) {
        setShowCancelConfirmation(true);
      } else {
        handleClose();
      }
    }, [form.dirty, handleClose]);

    return showCancelConfirmation ? (
      <ConfirmationDialog
        cancelText="Back to settings"
        confirmText="Cancel changes"
        handleClose={() => setShowCancelConfirmation(false)}
        handleConfirm={handleClose}
        open={true}
        message={
          policyVersionId
            ? "Your unsaved version changes will be lost."
            : "Your policy version will not be saved."
        }
        title="Cancel version changes?"
        type={ConfirmationDialogTypes.Warning}
      />
    ) : confirmChangesPrompt.show ? (
      <ConfirmationDialog
        confirmText={confirmChangesPrompt.confirmText}
        handleClose={() => setConfirmChangesPrompt(initialConfirmChangesPrompt)}
        handleConfirm={() => onSubmit(form.values, form, true)}
        open={true}
        message={confirmChangesPrompt.message}
        messageContent={
          confirmChangesPrompt.showNotifyFacilitiesCheckbox ? (
            <DialogCheckboxContainerStyled>
              <CheckboxInput
                checked={confirmChangesPrompt.notifyFacilities}
                label="Notify facilities"
                name="notifyFacilities"
                onChange={(_, val) =>
                  setConfirmChangesPrompt((prompt) => ({
                    ...prompt,
                    notifyFacilities: val,
                  }))
                }
              />
            </DialogCheckboxContainerStyled>
          ) : undefined
        }
        title={confirmChangesPrompt.title}
        type={ConfirmationDialogTypes.Warning}
      />
    ) : (
      <Dialog open={true}>
        <Form form={form} promptCancelText="Back to Policy version">
          <DialogTitle variant="h6">
            {policyVersionId ? "Edit" : "Add"} policy version
          </DialogTitle>
          <DialogContent>
            <Grid container paddingTop="16px">
              {!!policyVersionId && (
                <FullWidthFormGridItemStyled item>
                  <TextField name="name" label="Name" />
                </FullWidthFormGridItemStyled>
              )}
              <FullWidthFormGridItemStyled item>
                <ToggleInput
                  name="type"
                  value={applicableFor}
                  onChange={(_, val) => setApplicableFor(val)}
                  options={[
                    { id: ApplicableForTypes.State, name: "State specific" },
                    {
                      id: ApplicableForTypes.Facility,
                      name: "Facility specific",
                    },
                  ]}
                />
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled item>
                {applicableFor === ApplicableForTypes.State ? (
                  <AutocompleteField
                    name="stateIDs"
                    label="Select states"
                    multiple={true}
                    options={stateOptions}
                  />
                ) : (
                  <AutocompleteField
                    name="facilityIDs"
                    label="Select facilities"
                    multiple={true}
                    options={facilityOptions}
                  />
                )}
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled item sx={{ paddingBottom: 0 }}>
                <TextField name="url" label="Google doc URL" />
              </FullWidthFormGridItemStyled>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            {policyVersionId ? (
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={form.isSubmitting}
                type="submit"
              >
                Save
              </Button>
            ) : (
              <ButtonMenu
                buttonText="Save"
                disabled={form.isSubmitting}
                menuItems={[
                  {
                    label: "Save and back to policy",
                    onClick: () => {
                      setAddAnother(false);
                      form.submitForm();
                    },
                  },
                  {
                    label: "Save and add another",
                    onClick: () => {
                      setAddAnother(true);
                      form.submitForm();
                    },
                  },
                ]}
              />
            )}
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
