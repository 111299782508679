import { Box, Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BasicStatuses, FacilityStatuses } from "../../../../../lib/constants";

export const HeaderContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
}));

interface StatusChipStyledProps extends ChipProps {
  status?: string;
}

export const StatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<StatusChipStyledProps>(({ theme, status }) => ({
  backgroundColor:
    status === BasicStatuses.Active
      ? theme.palette.primary.light
      : status === FacilityStatuses.Onboarding
      ? theme.palette.warning.main
      : status === FacilityStatuses.OnHold
      ? theme.palette.error.light
      : status === FacilityStatuses.Pending
      ? theme.palette.secondary.dark
      : status === FacilityStatuses.Review
      ? theme.palette.info.dark
      : theme.palette.neutral.main,
  color: theme.palette.background.default,
}));

export const FlexCellStyled = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
}));
