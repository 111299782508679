import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { RouterLink } from "../../../lib";
import { PageRootStyled } from "../../../components";

export const RootStyled = styled(PageRootStyled)(() => ({
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
}));

export const BoxStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.light}`,
  borderRadius: "3px",
  padding: "90px 220px",
  marginTop: "80px",
  textAlign: "center",
}));

interface StatusPageProps {
  img: string;
  imgHeight?: string;
  subtitle?: string | ReactElement;
  showHomepageLink?: boolean;
  tag?: JSX.Element;
  title: string;
}

export const StatusPage = React.memo(
  /**
   *
   */
  function StatusPage({
    img,
    imgHeight,
    subtitle,
    showHomepageLink,
    tag,
    title,
  }: StatusPageProps) {
    return (
      <RootStyled>
        <BoxStyled>
          <img alt={title} src={img} height={imgHeight || 206} />
          <Box mt={4} mb={2}>
            <Typography variant="subtitle1">{title}</Typography>
            {!!subtitle && (
              <Typography variant="body2" mt={1} maxWidth="600px">
                {subtitle}
              </Typography>
            )}
          </Box>
          {tag}
          {showHomepageLink && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/"
            >
              Go to homepage
            </Button>
          )}
        </BoxStyled>
      </RootStyled>
    );
  },
);
