import React, { useCallback, useEffect, useState } from "react";
import {
  BasicGroup,
  sysActions,
  sysSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { SelectInput } from "../../../components";
import { asArray, Navigation, useLocation } from "../../../lib";
import { paginationQueryKey } from "../../../components/lists/PaginationHelpers";

export default function GroupFilter() {
  const dispatch = useDispatch();
  const location = useLocation();

  const groupOptions = useSelector(sysSelectors.activeGroups);
  const groupFilter = useSelector(sysSelectors.groupFilter);
  const facilityFilter = useSelector(sysSelectors.facilityFilter);

  // manage selected group id locally to allow for comparison when the filter is updated externally (in order to apply necessary query updates)
  const [selectedGroupId, setSelectedGroupId] = useState(groupFilter?.id);

  const onSelectGroup = useCallback(
    async (group: BasicGroup | undefined, setFilter = true) => {
      setSelectedGroupId(group?.id);

      if (setFilter) {
        dispatch(sysActions.setGroupFilter(group));

        // clear mismatched facility filter upon group selection change
        if (facilityFilter?.groupID !== group?.id) {
          dispatch(sysActions.clearFacilityFilter());
        }
      }

      // if query param page or categories (for facility files) are set, reset when group is changed
      const resetPage = (location.query[paginationQueryKey.page] as number) > 1;
      const resetCategoriesFilter =
        location.query.categories &&
        asArray(location.query.categories.toString())?.length;
      if (resetPage || resetCategoriesFilter) {
        Navigation.replace(location.pathname, {
          query: {
            ...location.query,
            page: "",
            categories: "",
          },
        });
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [dispatch, facilityFilter?.groupID, location.pathname, location.query],
  );

  // auto-set the group filter for single-group users
  useEffect(() => {
    if (groupOptions.length === 1 && groupFilter?.id !== groupOptions[0].id) {
      onSelectGroup(groupOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupOptions.length]);

  // on mount and upon facility filter selection, set group filter to the facility selection's group if no matching group filter is set
  useEffect(() => {
    if (
      groupOptions.length > 1 &&
      facilityFilter?.groupID &&
      facilityFilter.groupID !== groupFilter?.id
    ) {
      const group = groupOptions.find((g) => g.id == facilityFilter.groupID);
      onSelectGroup(group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, facilityFilter?.groupID, groupOptions.length]);

  // update local selectedGroupId state and run reset query effects if group filter was set/cleared externally
  useEffect(() => {
    if (groupFilter?.id !== selectedGroupId) {
      onSelectGroup(groupFilter, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilter?.id]);

  useEffect(() => {
    // clear group filter when component unmounts (group should be set for only screens it is applicable for)
    return () => {
      dispatch(sysActions.clearGroupFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return groupOptions?.length > 1 ? (
    <SelectInput
      autocomplete={groupOptions.length > 10}
      clearable={false}
      fitToContentWidth={true}
      inputProps={{
        disableUnderline: true,
      }}
      name="groupId"
      onChange={(_, id) => onSelectGroup(groupOptions.find((g) => g.id === id))}
      options={[{ id: "all", name: "All groups" }, ...groupOptions]}
      sx={{ mr: 4 }}
      value={selectedGroupId || "all"}
      variant="standard"
    />
  ) : null;
}
