import React, { useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  BasicStatusChipStyled,
  CheckboxInput,
  FilterListIcon,
  HtmlSanitizedText,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  SelectInput,
} from "../../../../components";
import { asArray, formatDate, Navigation, RouterLink } from "../../../../lib";
import {
  AnnouncementRecord,
  sysSelectors,
  useSelector,
} from "../../../../state";
import { AdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";
import { dateColumnSortingOrder } from "../../../../components/lists/List";

const filterQueryKey = {
  facilityIDs: "facilities",
  facilityTypes: "facilityTypes",
  includeInactive: "showInactive",
  stateIDs: "states",
  text: "search",
};

function AnnouncementsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { facilityTypes: facilityTypeOptions = [], states: stateOptions = [] } =
    useSelector(sysSelectors.systemSettings);
  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const {
    facilities = [],
    facilityTypes = [],
    search = "",
    showInactive = false,
    states = [],
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(
      search ||
      asArray(facilities).length ||
      asArray(facilityTypes).length ||
      asArray(states).length
    ),
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h6">Announcements</Typography>
        <Box>
          <CheckboxInput
            checked={!!showInactive}
            label="Show inactive"
            name="showInactive"
            onChange={onFilterChange}
          />
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            component={RouterLink}
            to={AdminPages.addAnnouncement.path}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
          >
            Add announcement
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search announcements"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="State"
            name="states"
            value={asArray(states)}
            onChange={onFilterChange}
            options={stateOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility"
            name="facilities"
            value={asArray(facilities)}
            onChange={onFilterChange}
            options={facilityOptions}
            groupOpts={true}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility type"
            name="facilityTypes"
            value={asArray(facilityTypes)}
            onChange={onFilterChange}
            options={facilityTypeOptions}
            multiple={true}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const columns: GridColDef[] = [
  {
    field: "subject",
    headerName: "Subject",
    flex: 1,
    renderCell: ({ row }: { row: AnnouncementRecord }) => (
      <HtmlSanitizedText text={row.subject} />
    ),
  },
  {
    field: "body",
    headerName: "Body",
    flex: 1.5,
    renderCell: ({ row }: { row: AnnouncementRecord }) => (
      <HtmlSanitizedText text={row.body} />
    ),
  },
  {
    field: "postedOn",
    headerName: "Posted on",
    flex: 1,
    valueFormatter: ({ value }) => formatDate(value),
    sortingOrder: dateColumnSortingOrder,
  },
  {
    field: "facilityType",
    headerName: "Facility type",
    flex: 1,
    renderCell: ({ row }: { row: AnnouncementRecord }) =>
      row.facilityTypeDisplay,
  },
  {
    field: "applicableFor",
    headerName: "Applicable for",
    flex: 1,
    renderCell: ({ row }: { row: AnnouncementRecord }) =>
      row.facilities.length ? (
        <AbbreviatedListDisplay items={row.facilities} />
      ) : row.states ? (
        row.states.length > 50 ? (
          "All States"
        ) : (
          <AbbreviatedListDisplay items={row.states} />
        )
      ) : (
        ""
      ),
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: ({ row }: { row: AnnouncementRecord }) => (
      <BasicStatusChipStyled
        label={row.status}
        size="small"
        status={row.status}
      />
    ),
  },
];

export const AnnouncementsList = React.memo(
  /**
   *
   */
  function AnnouncementsList() {
    const onRowClick = useCallback(({ row }: { row: AnnouncementRecord }) => {
      Navigation.go(AdminPages.editAnnouncement, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<AnnouncementsListActions />}
        columns={columns}
        defaultOrderBy="postedOn"
        defaultOrderDirection="desc"
        filterQueryKey={filterQueryKey}
        name="announcements"
        onRowClick={onRowClick}
        rowHeight={84}
        stickyHeader={true}
        type={ListTypes.announcements}
      />
    );
  },
);
