import { FieldAttributes, useField } from "formik";
import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "./TextField";

export const PhoneField = React.memo(
  /**
   *
   */
  function PhoneField({ name, ...rest }: FieldAttributes<any>) {
    const [field, , helpers] = useField(name);
    const { onChange: _, ...fieldRest } = field; // discard onChange because NumberFormat change is handled properly via onValueChange
    return (
      <NumberFormat
        customInput={TextField}
        format="(###) ###-####"
        isNumericString
        mask="_"
        onValueChange={({ value }, { event }) => {
          // setValue only when onValueChange is triggered by an actual change event
          if (event?.type === "change") {
            helpers.setValue(value);
          }
        }}
        placeholder="(__) ___-____"
        type="tel"
        {...fieldRest}
        {...rest}
      />
    );
  },
);
