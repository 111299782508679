import React from "react";
import logo from "../../assets/img/logo.svg";
import {
  SidebarTitleStyled,
  SidebarSubtitleStyled,
  SidebarLinkStyled,
  // SidebarSocialsStyled,
} from "./AuthLayout.styles";
import { Box, Typography } from "@mui/material";
// import { FacebookIcon, LinkedInIcon, TwitterIcon } from "../../components";

interface CompanyInfoDisplayProps {
  title: string;
  subtitle: string;
}

export const CompanyInfoDisplay = React.memo(
  /**
   *
   */
  function CompanyInfoDisplay({ title, subtitle }: CompanyInfoDisplayProps) {
    return (
      <>
        <Box>
          <img alt="Compliance logo" src={logo} height={80} />
          <SidebarTitleStyled>
            <Typography variant="h5">{title}</Typography>
          </SidebarTitleStyled>
          <SidebarSubtitleStyled>
            <Typography variant="body1">{subtitle}</Typography>
          </SidebarSubtitleStyled>
          <SidebarLinkStyled href="tel:718-408-8989">
            <Typography variant="h6">(718) 408 8989</Typography>
          </SidebarLinkStyled>
          <SidebarLinkStyled
            href="mailto:info@compliancecgllc.com"
            target="_blank"
          >
            <Typography variant="h6">info@compliancecgllc.com</Typography>
          </SidebarLinkStyled>
          <SidebarLinkStyled
            href="https://www.compliancecg.com/"
            target="_blank"
          >
            <Typography variant="h6">compliancecg.com</Typography>
          </SidebarLinkStyled>
        </Box>
        {/* They don't currently have socials - removing for now */}
        {/* <SidebarSocialsStyled>
          <IconButton
            target="_blank"
            href="https://www.facebook.com/ComplianceCG/"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.facebook.com/ComplianceCG/"
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.facebook.com/ComplianceCG/"
          >
            <LinkedInIcon />
          </IconButton>
        </SidebarSocialsStyled> */}
      </>
    );
  },
);
