import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import {
  CheckIcon,
  FolderIcon,
  InfoIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  VisibilityOffIcon,
} from "../../../../components";
import { ListActionsProps } from "../../../../components";
import {
  authSelectors,
  PolicyRecord,
  sysSelectors,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import { asArray, Navigation, pluralizeText } from "../../../../lib";
import {
  FacilityUserLevels,
  PermissionClaims,
} from "../../../../lib/constants";
import { PrintPoliciesDialog } from "./PrintPoliciesDialog";
import { FacilityAdminPages } from "../../../facilityAdmin";

const filterQueryKey = {
  categoryIDs: "categories",
  text: "search",
};

interface PolicyListActionsProps extends ListActionsProps {
  allowBulkPrinting: boolean;
  onClickPrint: () => void;
  selectedPolicies: PolicyRecord[];
}

function PoliciesListActions({
  allowBulkPrinting,
  onFilterChange = () => {},
  onClickPrint,
  query,
  selectedPolicies,
}: PolicyListActionsProps) {
  const policyCategoryOptions = useSelector(sysSelectors.policyCategories);

  const { categories = [], search = "" } = query;

  const [showCategoriesTooltip, setShowCategoriesTooltip] = useState(false);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled sx={{ pt: 0 }}>
        <Box display="flex" alignItems="center" width="100%">
          <SearchInput
            variant="outlined"
            placeholder="Search by policy name or number"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
            onFocus={() => setShowCategoriesTooltip(true)}
            onBlur={() => setShowCategoriesTooltip(false)}
          />
          {!!asArray(categories).length && (
            <Tooltip
              title={`Search is limited to the selected ${pluralizeText(
                "category",
                asArray(categories).length,
                "categories",
              )}`}
              open={showCategoriesTooltip}
            >
              <InfoIcon
                sx={{ color: "text.secondary" }}
                onMouseEnter={() => setShowCategoriesTooltip(true)}
                onMouseLeave={() => setShowCategoriesTooltip(false)}
              />
            </Tooltip>
          )}
        </Box>
        {allowBulkPrinting && (
          <Tooltip
            title={!selectedPolicies.length ? "Select policies for print" : ""}
          >
            <Box>
              <Button
                disabled={!selectedPolicies.length}
                variant="contained"
                color="primary"
                size="large"
                sx={{ ml: 1 }}
                onClick={onClickPrint}
              >
                Print
              </Button>
            </Box>
          </Tooltip>
        )}
      </ListActionsMainStyled>
      <ListActionsExpandedStyled sx={{ flexFlow: "wrap" }}>
        {policyCategoryOptions.map((category) => {
          const selectedCategories = asArray(categories) as number[];
          const isSelected = selectedCategories.includes(category.id);
          return (
            <Chip
              color={isSelected ? "primary" : "default"}
              key={category.id}
              icon={
                isSelected ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <FolderIcon fontSize="small" />
                )
              }
              label={category.name}
              onClick={() =>
                onFilterChange(
                  "categories",
                  isSelected
                    ? selectedCategories.filter((c) => c !== category.id)
                    : [...selectedCategories, category.id],
                )
              }
              sx={{ mr: 2, mb: 1 }}
            />
          );
        })}
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const PoliciesList = React.memo(
  /**
   *
   */
  function PoliciesList() {
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const facilityUserLevel = useSelector(authSelectors.facilityUserLevel);
    const facilityFilter = useSelector(sysSelectors.facilityFilter);
    const activeGroups = useSelector(sysSelectors.activeGroups);

    const [selectedPolicies, setSelectedPolicies] = useState<PolicyRecord[]>(
      [],
    );
    const [showPrintDialog, setShowPrintDialog] = useState(false);

    const listFilter = useMemo(
      () => (facilityFilter ? { facilityIDs: [facilityFilter.id] } : undefined),
      [facilityFilter],
    );

    const allowBulkPrinting = useMemo(() => {
      // bulk printing is allowed for only group or region owners
      if (
        facilityUserLevel !== FacilityUserLevels.Group &&
        facilityUserLevel !== FacilityUserLevels.Region
      ) {
        return false;
      }

      const hasPrintingOverridePermission = userPermissionClaims.includes(
        PermissionClaims.PoliciesPrintingOverrideClaim,
      );

      // allow bulk printing if user has the printing override permission
      if (hasPrintingOverridePermission) return true;

      // if a facility filter is applied, don't allow bulk printing if printing is disabled for the group
      if (facilityFilter) {
        return !activeGroups.find((g) => g.id === facilityFilter.groupID)
          ?.policyPrintingDisabled;
      }

      // don't allow bulk printing if there are no active groups without printing disabled
      return activeGroups.some((g) => !g.policyPrintingDisabled);
    }, [activeGroups, facilityFilter, facilityUserLevel, userPermissionClaims]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "number",
          headerName: "Policy",
          flex: 1.5,
          renderCell: ({ row }: { row: PolicyRecord }) => (
            <Box>
              <Typography variant="body2">{row.title}</Typography>
            </Box>
          ),
        },
        {
          field: "categoryName",
          headerName: "Category",
          flex: 1,
        },
        ...(!facilityFilter?.id
          ? [
              {
                field: "actions",
                headerName: "",
                width: 80,
                renderCell: () => (
                  <Tooltip title="You are currently viewing policies across all facilities. Select a facility from the blue bar at the top of the screen to open this policy.">
                    <VisibilityOffIcon sx={{ color: "neutral.main" }} />
                  </Tooltip>
                ),
                sortable: false,
              },
            ]
          : []),
      ],
      [facilityFilter?.id],
    );

    const onRowClick = useCallback(({ row }: { row: PolicyRecord }) => {
      Navigation.go(FacilityAdminPages.policy, { params: { id: row.id } });
    }, []);

    return (
      <>
        <List
          actions={
            <PoliciesListActions
              allowBulkPrinting={allowBulkPrinting}
              onClickPrint={() => setShowPrintDialog(true)}
              selectedPolicies={selectedPolicies}
            />
          }
          checkboxSelection={allowBulkPrinting}
          columns={columns}
          filter={listFilter}
          filterQueryKey={filterQueryKey}
          name="policies"
          onRowClick={facilityFilter?.id ? onRowClick : undefined}
          onCheckboxSelection={(selection) => setSelectedPolicies(selection)}
          selection={selectedPolicies}
          stickyHeader={true}
          type={ListTypes.policies}
        />
        {showPrintDialog && (
          <PrintPoliciesDialog
            policies={selectedPolicies}
            handleClose={() => {
              setShowPrintDialog(false);
              setSelectedPolicies([]);
            }}
          />
        )}
      </>
    );
  },
);
