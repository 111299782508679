import React, { useCallback, useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import {
  FileUploadTypes,
  pluralizeText,
  useFileUpload,
} from "../../../../../lib";
import { AuditStatuses, FileTypes } from "../../../../../lib/constants";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FilePreview,
} from "../../../../../components";
import { Audit, uiActions, useAppDispatch } from "../../../../../state";
import { generateCertificatePdf } from "./AuditCertificateHelpers";

interface GenerateAuditCertificateButtonProps {
  audit: Audit;
  disabled?: boolean;
  onSubmit: (certificateUrl: string) => void;
}

export const GenerateAuditCertificateButton = React.memo(
  /**
   *
   */
  function GenerateAuditCertificateButton({
    audit,
    disabled,
    onSubmit,
  }: GenerateAuditCertificateButtonProps) {
    const dispatch = useAppDispatch();

    const [certificateData, setCertificateData] = useState<File>();
    const [warningDialog, setWarningDialog] = useState<{
      title: string | JSX.Element;
      message: string | JSX.Element;
    }>();

    const onGenerateCertificate = useCallback(async () => {
      if (!warningDialog) {
        let dialogTitle, dialogMessage;

        // confirm complete audit and generate certifcate for audit with an open CAP
        if (
          audit.status !== AuditStatuses.Completed &&
          audit.hasIncompleteCorrectiveActions
        ) {
          dialogTitle = "There is an open CAP linked to this audit.";
          dialogMessage =
            "Please confirm that you'd like to mark this audit as complete, and generate a certificate.";
        }
        // confirm generate certificate for audit with a CAP
        else if (audit.hasCorrectiveActionPlan) {
          dialogTitle = "There is a CAP linked to this audit.";
          dialogMessage =
            "Please confirm that you'd like to generate a certificate.";
        }

        // confirm complete audit with unresolved comments
        const unresolvedCorrectionCommentsCount =
          audit.unresolvedCommentThreadCounts.Correction;
        const unresolvedQuestionCommentsCount =
          audit.unresolvedCommentThreadCounts.FacilityQuestion;

        if (
          audit.status !== AuditStatuses.Completed &&
          (unresolvedCorrectionCommentsCount || unresolvedQuestionCommentsCount)
        ) {
          const unresolvedCommentsDescription = (
            <>
              This audit has{" "}
              {unresolvedCorrectionCommentsCount ? (
                <b>
                  {unresolvedCorrectionCommentsCount} unresolved{" "}
                  {pluralizeText("comment", unresolvedCorrectionCommentsCount)}
                </b>
              ) : (
                ""
              )}
              {unresolvedCorrectionCommentsCount &&
              unresolvedQuestionCommentsCount
                ? " and "
                : ""}
              {unresolvedQuestionCommentsCount ? (
                <b>
                  {unresolvedQuestionCommentsCount} unresolved{" "}
                  {pluralizeText("question", unresolvedQuestionCommentsCount)}
                </b>
              ) : (
                ""
              )}
            </>
          );

          if (dialogMessage) {
            dialogMessage = (
              <>
                {dialogMessage}
                <br />
                Note: {unresolvedCommentsDescription}.
              </>
            );
          } else {
            dialogTitle = unresolvedCommentsDescription;
            dialogMessage =
              "Please confirm that you'd like to mark this audit as complete and generate a certificate.";
          }
        }

        if (dialogTitle || dialogMessage) {
          setWarningDialog({
            title: dialogTitle,
            message: dialogMessage,
          });
          return;
        }
      } else {
        setWarningDialog(undefined);
      }

      dispatch(uiActions.setLoading(true));

      const data = await generateCertificatePdf(audit.name, audit.facilityName);
      if (data) {
        setCertificateData(data);
      } else {
        dispatch(uiActions.showError("Error generating audit certificate"));
      }

      dispatch(uiActions.setLoading(false));
    }, [
      warningDialog,
      dispatch,
      audit.name,
      audit.facilityName,
      audit.status,
      audit.hasIncompleteCorrectiveActions,
      audit.hasCorrectiveActionPlan,
      audit.unresolvedCommentThreadCounts.Correction,
      audit.unresolvedCommentThreadCounts.FacilityQuestion,
    ]);

    const uploadFile = useFileUpload();

    const onSaveCertificate = useCallback(async () => {
      const certificateUrl = await uploadFile(
        certificateData,
        FileUploadTypes.AuditCertificate,
        true,
      );
      if (certificateUrl) {
        onSubmit(certificateUrl);
      }
    }, [certificateData, onSubmit, uploadFile]);

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          size="large"
          sx={{ mr: 1 }}
          onClick={onGenerateCertificate}
        >
          Generate certificate
        </Button>
        {warningDialog && (
          <ConfirmationDialog
            cancelText="Back to audit"
            confirmText="Continue"
            handleConfirm={onGenerateCertificate}
            handleClose={() => setWarningDialog(undefined)}
            open={true}
            message={warningDialog.message}
            title={warningDialog.title}
            type={ConfirmationDialogTypes.Alert}
          />
        )}
        {certificateData && (
          <Dialog
            open={true}
            PaperProps={{ sx: { width: "80vw", maxWidth: "1200px" } }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="16px"
            >
              <Typography variant="h6">Save Audit Certificate</Typography>
              <Box display="flex">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  onClick={() => setCertificateData(undefined)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={onSaveCertificate}
                >
                  Save and send to facility
                </Button>
              </Box>
            </Box>
            <FilePreview
              fileName={`${audit.facilityName}_Audit_Certificate`}
              fileSource={certificateData}
              fileType={FileTypes.Pdf}
              pdfControls="toolbar=0&navpanes=0&zoom=90" // hides pdf controls in chrome
              style={{ minHeight: "80vh" }}
            />
          </Dialog>
        )}
      </>
    );
  },
);
