import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormFooterContainerStyled } from "../../../../components";

export const CapSubheaderStyled = styled(`div`)(() => ({
  marginLeft: "48px",
  marginBottom: "16px",
  display: "flex",
}));

export const CapEmptyContainerStyled = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

export const CorrectiveActionContainerStyled = styled(Box)(({ theme }) => ({
  maxWidth: "860px",
  border: `1px solid ${theme.palette.divider}`,
  padding: "24px",
  marginBottom: "46px",
  "& .MuiGrid-root": {
    maxWidth: "100%",
  },
}));

export const CorrectiveActionHeaderStyled = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: "16px",
  marginBottom: "16px",
}));

export const CorrectiveActionFooterStyled = styled(FormFooterContainerStyled)(
  () => ({
    justifyContent: "flex-start",
    borderTop: "none",
    padding: "8px 0",
  }),
);
