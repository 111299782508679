import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, IconButton, Typography } from "@mui/material";
import {
  CommentIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DeleteIcon,
  List,
  VisibilityIcon,
} from "../../../../../components";
import { formatDate, pluralizeText } from "../../../../../lib";
import { dateColumnSortingOrder } from "../../../../../components/lists/List";
import {
  adminActions,
  Facility,
  RequiredDocumentRecord,
  useAppDispatch,
} from "../../../../../state";
import { ListTypes } from "../../../../../state/lists/state";
import { RequiredDocumentSubmissionDialog } from "../../../../adminShared/components";
import { RequiredDocumentFrequencies } from "../../../../../lib/constants";

interface FacilityRequiredDocumentSubmissionsListProps {
  facility: Facility;
}

export const FacilityRequiredDocumentSubmissionsList = React.memo(
  /**
   *
   */
  function FacilityRequiredDocumentSubmissionsList({
    facility,
  }: FacilityRequiredDocumentSubmissionsListProps) {
    const dispatch = useAppDispatch();

    const [previewDoc, setPreviewDoc] = useState<RequiredDocumentRecord>();

    const [docToDelete, setDocToDelete] = useState<RequiredDocumentRecord>();

    const onDelete = useCallback(async () => {
      if (!docToDelete) {
        return;
      }
      const deleted = await dispatch(
        adminActions.deleteRequiredDocumentSubmission(docToDelete.id),
      );
      if (deleted) {
        setDocToDelete(undefined);
      }
    }, [dispatch, docToDelete]);

    const listFilter = useMemo(
      () => ({ facilityId: facility.id }),
      [facility.id],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "resourceName",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "createdOn",
          headerName: "Submitted",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "quarter",
          headerName: "Quarter/Year",
          flex: 1,
          renderCell: ({ row }: { row: RequiredDocumentRecord }) =>
            row.requiredFrequency === RequiredDocumentFrequencies.Annually
              ? row.year
              : row.requiredFrequency === RequiredDocumentFrequencies.Quarterly
              ? `Q${row.quarter} ${row.year}`
              : "",
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "review",
          headerName: "Review",
          flex: 1,
          renderCell: ({ row }: { row: RequiredDocumentRecord }) =>
            row.hasReviewWorkflow ? (
              <Box>
                <Typography
                  color={row.reviewedOn ? "success.dark" : "warning.dark"}
                  variant="body2"
                >
                  {row.reviewedOn
                    ? "Reviewed and confirmed by GB Designee"
                    : "Received - Pending GB Designee"}
                </Typography>
                {row.reviewedOn && (
                  <Typography
                    display="flex"
                    alignItems="center"
                    color="text.secondary"
                    mt="8px"
                    variant="body2"
                  >
                    <CommentIcon sx={{ mr: "4px" }} />
                    {row.commentsCount.toLocaleString()}{" "}
                    {pluralizeText("comment", row.commentsCount)}
                  </Typography>
                )}
              </Box>
            ) : (
              ""
            ),
          sortable: false,
        },
        {
          field: "actions",
          headerName: "",
          width: 120,
          renderCell: ({ row }: { row: RequiredDocumentRecord }) => (
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => setPreviewDoc(row)}
                sx={{ mr: 1, color: "text.secondary" }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setDocToDelete(row)}
                sx={{ color: "text.secondary" }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ),
          sortable: false,
        },
      ],
      [],
    );

    return (
      <>
        <List
          columns={columns}
          defaultOrderBy="quarter"
          defaultOrderDirection="desc"
          filter={listFilter}
          name="submissions"
          rowHeight={84}
          type={ListTypes.requiredDocumentSubmissions}
        />
        {docToDelete && (
          <ConfirmationDialog
            handleClose={() => setDocToDelete(undefined)}
            handleConfirm={onDelete}
            open={true}
            message="Are you sure you want to delete this submission?"
            title="Delete submission"
            type={ConfirmationDialogTypes.Alert}
          />
        )}
        {previewDoc && (
          <RequiredDocumentSubmissionDialog
            allowReview={true}
            document={previewDoc}
            handleClose={() => setPreviewDoc(undefined)}
            refreshSubmissions={true}
          />
        )}
      </>
    );
  },
);
