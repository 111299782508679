import React from "react";
import { useParams } from "react-router-dom";
import { FacilityFileForm } from "../adminShared/components";
import { GroupFacilityFilesAccessWrapper } from "./components";

export const FacilityFileFormPage = React.memo(
  /**
   *
   */
  function FacilityFileFormPage() {
    const params = useParams();

    return (
      <GroupFacilityFilesAccessWrapper>
        <FacilityFileForm groupId={Number(params.groupId)} />
      </GroupFacilityFilesAccessWrapper>
    );
  },
);
