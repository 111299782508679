import React, { useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import { ListTypes } from "../../../../../state/lists/state";
import {
  CheckboxInput,
  CheckIcon,
  List,
  ListActionsContainerStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
} from "../../../../../components";
import { formatAddress, Navigation, useLocation } from "../../../../../lib";
import {
  FlexCellStyled,
  HeaderContainerStyled,
  StatusChipStyled,
} from "./GroupsFacilitiesRegionsLists.styles";
import { GroupsFacilitiesRegionsDashboard } from "../general/GroupsFacilitiesRegionsDashboard";
import { AddGroupFacilityRegionMenu } from "../general/AddGroupFacilityRegionMenu";
import { AdminPages } from "../../..";
import { GroupRecord } from "../../../../../state";
import { textWrapHeaderClassName } from "../../../../../components/lists/List.styles";

const filterQueryKey = {
  includeInactive: "showInactive",
  text: "search",
};

function GroupsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { showInactive = false, search = "" } = query;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <SearchInput
          variant="outlined"
          placeholder="Search groups"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
        />
        <CheckboxInput
          checked={!!showInactive}
          label="Show inactive"
          name="showInactive"
          onChange={onFilterChange}
        />
      </ListActionsMainStyled>
    </ListActionsContainerStyled>
  );
}

export const GroupsList = React.memo(
  /**
   *
   */
  function GroupsList() {
    const {
      query: { showInactive },
    } = useLocation();

    const onRowClick = useCallback(({ row }: { row: GroupRecord }) => {
      Navigation.go(AdminPages.group, { params: { id: row.id } });
    }, []);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Group",
          flex: 2,
        },
        {
          field: "address",
          headerName: "Address",
          flex: 2,
          renderCell: ({ row }: { row: GroupRecord }) => (
            <Typography variant="body2" whiteSpace="pre">
              {formatAddress(row, "\n")}
            </Typography>
          ),
          sortable: false,
        },
        {
          field: "regionsCount",
          headerName: "Regions",
          flex: 1,
          renderCell: ({ row }: { row: GroupRecord }) => (
            <FlexCellStyled
              onClick={(e) => {
                e.stopPropagation();
                Navigation.go(AdminPages.group, {
                  params: { id: row.id },
                  query: { tab: "regions" },
                });
              }}
            >
              {showInactive && row.inactiveRegionsCount
                ? `${
                    row.activeRegionsCount
                      ? `${row.activeRegionsCount.toLocaleString()} + `
                      : ""
                  }${row.inactiveRegionsCount.toLocaleString()} Inactive`
                : row.activeRegionsCount.toLocaleString()}
            </FlexCellStyled>
          ),
        },
        {
          field: "facilitiesCount",
          headerName: "Facilities",
          flex: 1,
          renderCell: ({ row }: { row: GroupRecord }) => (
            <FlexCellStyled
              onClick={(e) => {
                e.stopPropagation();
                Navigation.go(AdminPages.group, {
                  params: { id: row.id },
                  query: { tab: "facilities" },
                });
              }}
            >
              {showInactive && row.inactiveFacilitiesCount
                ? `${
                    row.activeFacilitiesCount
                      ? `${row.activeFacilitiesCount.toLocaleString()} + `
                      : ""
                  }${row.inactiveFacilitiesCount.toLocaleString()} Inactive`
                : row.activeFacilitiesCount.toLocaleString()}
            </FlexCellStyled>
          ),
        },
        {
          field: "facilityFilesEnabled",
          headerName: "Facility Files Enabled",
          headerClassName: textWrapHeaderClassName,
          flex: 1,
          renderCell: ({ row }: { row: GroupRecord }) =>
            row.facilityFilesEnabled && <CheckIcon />,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: GroupRecord }) =>
            row.status && (
              <StatusChipStyled
                label={row.status}
                size="small"
                status={row.status}
              />
            ),
        },
      ],
      [showInactive],
    );

    return (
      <>
        <HeaderContainerStyled>
          <GroupsFacilitiesRegionsDashboard />
          <AddGroupFacilityRegionMenu />
        </HeaderContainerStyled>
        <List
          actions={<GroupsListActions />}
          columns={columns}
          filterQueryKey={filterQueryKey}
          name="groups"
          onRowClick={onRowClick}
          rowHeight={64}
          stickyHeader={true}
          type={ListTypes.groups}
        />
      </>
    );
  },
);
