import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { BasicRole, sysSelectors, useSelector } from "../../../../state";
import {
  GridItemStyled,
  GridSectionHeaderStyled,
  GridSectionStyled,
  RootStyled,
} from "./ConfigurePermissions.styles";
import {
  ArrowDropDownIcon,
  ArrowRightIcon,
  CheckboxField,
  CloseIcon,
} from "../../../../components";
import { FormikValues } from "formik";
import { ClaimCategories, PermissionClaims } from "../../../../lib/constants";

export interface ConfigurePermissionsProps {
  form: FormikValues;
  hasFacilityFilesEnabled: boolean;
  onClose: () => void;
  roleOptions: BasicRole[];
}

export const ConfigurePermissions = React.memo(
  /**
   *
   */
  function ConfigurePermissions({
    form,
    hasFacilityFilesEnabled,
    onClose,
    roleOptions,
  }: ConfigurePermissionsProps) {
    const { permissionClaims } = useSelector(sysSelectors.systemSettings);
    const categorizedClaims = useSelector(
      sysSelectors.categorizedPermissionClaims,
    );

    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

    const allowedClaimIds = useMemo(() => {
      // exclude claims included via user roles assignment
      const assignedRolesClaimIds: number[] = [];
      roleOptions.forEach((r) => {
        if (form.values.roleIDs?.includes(r.id)) {
          assignedRolesClaimIds.push(...r.claimIDs);
        }
      });

      return permissionClaims
        .map((c) => c.id)
        .filter((id) => !assignedRolesClaimIds.includes(id));
    }, [form.values.roleIDs, permissionClaims, roleOptions]);

    const toggleExpandedCategories = useCallback(
      (category = "") => {
        if (!category) {
          setExpandedCategories(
            expandedCategories.length === Object.keys(categorizedClaims).length
              ? []
              : Object.keys(categorizedClaims),
          );
        } else {
          setExpandedCategories(
            expandedCategories.includes(category)
              ? expandedCategories.filter((c) => c !== category)
              : [...expandedCategories, category],
          );
        }
      },
      [categorizedClaims, expandedCategories],
    );

    const toggleClaim = useCallback(
      (id) => {
        const claimIDs = form.values.claimIDs || [];
        form.setFieldValue(
          "claimIDs",
          claimIDs.includes(id)
            ? claimIDs.filter((c) => c !== id)
            : [...claimIDs, id],
        );
      },
      [form],
    );

    return (
      <RootStyled>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <GridSectionStyled>
          <GridSectionHeaderStyled>
            <Typography variant="subtitle1">Additional permissions</Typography>
            <Button variant="text" onClick={() => toggleExpandedCategories()}>
              {expandedCategories.length ===
              Object.keys(categorizedClaims).length
                ? "Collapse"
                : "Expand"}{" "}
              all
            </Button>
          </GridSectionHeaderStyled>
        </GridSectionStyled>
        {Object.keys(categorizedClaims).map((category) => {
          const isExpanded = expandedCategories.includes(category);
          const selectedClaimsCount = categorizedClaims[category].filter(
            (c) =>
              form.values.claimIDs?.includes(c.id) ||
              !allowedClaimIds.includes(c.id),
          ).length;
          return (
            <GridSectionStyled key={category}>
              <GridSectionHeaderStyled
                onClick={() => toggleExpandedCategories(category)}
              >
                <Typography
                  variant="subtitle2"
                  display="flex"
                  alignItems="center"
                >
                  {category}
                  {category === ClaimCategories.FacilityFiles &&
                    !hasFacilityFilesEnabled && (
                      <Tooltip title="This add-on has not been enabled for the user's facilities">
                        <Chip
                          label="Inactive"
                          size="small"
                          sx={{
                            backgroundColor: "neutral.extraLight",
                            fontWeight: "normal",
                            ml: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                </Typography>
                <Box>
                  <Typography variant="caption">
                    {!selectedClaimsCount
                      ? "None"
                      : selectedClaimsCount ===
                        categorizedClaims[category].length
                      ? "All"
                      : "Restricted"}
                  </Typography>
                  <IconButton sx={{ ml: 1 }}>
                    {isExpanded ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </Box>
              </GridSectionHeaderStyled>
              {isExpanded &&
                categorizedClaims[category].map((claim) => {
                  const isAllowed = allowedClaimIds.includes(claim.id);
                  let toolTipText = isAllowed
                    ? ""
                    : "This permission is included in the user's access levels";
                  if (
                    claim.claimValue ===
                    PermissionClaims.PoliciesPrintingOverrideClaim
                  )
                    toolTipText += `${
                      toolTipText.length ? "\n" : ""
                    }Assigning this permission will override group printing restrictions`;
                  return (
                    <GridItemStyled key={claim.id}>
                      <Tooltip title={toolTipText}>
                        <Box>
                          <CheckboxField
                            disabled={!isAllowed}
                            label={claim.displayName}
                            name="claimIDs"
                            checked={
                              !isAllowed ||
                              form.values.claimIDs?.includes(claim.id)
                            }
                            onChange={() => toggleClaim(claim.id)}
                          />
                        </Box>
                      </Tooltip>
                    </GridItemStyled>
                  );
                })}
            </GridSectionStyled>
          );
        })}
      </RootStyled>
    );
  },
);
