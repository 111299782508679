import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ErrorTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));

export const SuccessTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.dark,
}));

export const LinkTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    opacity: ".8",
  },
}));
