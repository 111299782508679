import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import { BasicAudit } from "../../../../state";
import { Navigation } from "../../../../lib";
import { DialogListItemStyled, OpenInNewIcon } from "../../../../components";
import { AdminPages } from "../..";

interface InProgressAuditsListDialogProps {
  audits: BasicAudit[];
  handleClose: () => void;
}

export const InProgressAuditsListDialog = React.memo(
  /**
   *
   */
  function InProgressAuditsListDialog({
    audits,
    handleClose,
  }: InProgressAuditsListDialogProps) {
    return (
      <Dialog open={true} PaperProps={{ sx: { width: "520px" } }}>
        <DialogTitle variant="h6">
          Update template for in progress audits?
          <Typography variant="body2" mt={2}>
            This audit is in progress for the following facilities. To update
            any of these audits to use the new template, click the link to open
            the audit in a new window and manually reset it. Note: Resetting the
            audit will delete all data that the facility has already filled out.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {audits.map((audit) => (
              <DialogListItemStyled key={audit.id}>
                <Typography>{audit.facilityName}</Typography>
                <IconButton
                  href={Navigation.fullUrl(AdminPages.audit, {
                    params: { id: audit.id },
                  })}
                  target="_blank"
                >
                  <OpenInNewIcon />
                </IconButton>
              </DialogListItemStyled>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose} size="large" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
