import { useMemo } from "react";
import { ViewerModes } from "../../../../components/pdf/PdfFormViewer";
import { useWebViewer } from "../../../../lib";
import { AuditCapStatuses, AuditStatuses } from "../../../../lib/constants";
import {
  Audit,
  AuditComment,
  AuditCommentRequest,
  AuditCommentThread,
  AuditVersion,
} from "../../../../state";
import { defaultTheme, fuchsia, turquoise } from "../../../../themes";

// #region comment helpers

export const CorrectionAnnotationSubject = "Correction-Comment";
export const CorrectiveActionTriggerText = "Corrective Action Plan (CAP)";

export function commentTextRequiresCorrectiveAction(
  commentText: string | undefined,
) {
  return commentText
    ?.toLowerCase()
    .includes(CorrectiveActionTriggerText.toLowerCase());
}

export function removedCommentCorrectiveActionText(
  commentRequest: AuditCommentRequest,
  initialCommentRequest: AuditCommentRequest,
) {
  return (
    initialCommentRequest.correctiveActionRequired &&
    !commentTextRequiresCorrectiveAction(commentRequest.text)
  );
}

export function createCommentRequest(
  comment: AuditComment,
  commentThread: AuditCommentThread,
) {
  const {
    type,
    questionNumber,
    relatedText,
    xfdfAnnotationIdentifier,
    isResolved,
  } = commentThread;

  // copy comment thread fields to the comment request to allow them to be updated for submission
  return {
    ...comment,
    type,
    questionNumber,
    relatedText,
    xfdfAnnotationIdentifier,
    isResolved,
  } as AuditCommentRequest;
}

// #endregion

// #region style helpers

const followUpNeededColor = turquoise;
const certificateSentColor = fuchsia;

export function getAuditStatusColor(
  status: string | undefined,
  statusDisplay: string | undefined,
  ccgAdminView: boolean,
) {
  if (ccgAdminView) {
    switch (status) {
      case AuditStatuses.SentNotStarted:
        return defaultTheme.palette.success.dark;
      case AuditStatuses.SentInProgress:
        return defaultTheme.palette.info.dark;
      case AuditStatuses.AwaitingGrade:
        return defaultTheme.palette.error.dark;
      case AuditStatuses.FollowUpNeeded:
        return statusDisplay?.toLowerCase().charAt(0) === "f"
          ? followUpNeededColor // display follow-up color only when follow-up needed label is shown
          : defaultTheme.palette.error.dark;
      case AuditStatuses.ReadyForReview:
        return defaultTheme.palette.secondary.main;
      case AuditStatuses.ResentCorrectionsNeeded:
        return defaultTheme.palette.warning.dark;
      case AuditStatuses.Completed:
        return defaultTheme.palette.primary.light;
      case AuditStatuses.CompletedCertificateSent:
        return certificateSentColor;
    }
    return defaultTheme.palette.neutral.main;
  } else {
    switch (status) {
      case AuditStatuses.SentNotStarted:
        return defaultTheme.palette.success.dark;
      case AuditStatuses.SentInProgress:
        return defaultTheme.palette.secondary.main;
      case AuditStatuses.AwaitingGrade:
      case AuditStatuses.FollowUpNeeded:
      case AuditStatuses.ReadyForReview:
        return defaultTheme.palette.warning.dark;
      case AuditStatuses.ResentCorrectionsNeeded:
        return defaultTheme.palette.error.dark;
      case AuditStatuses.Completed:
        return defaultTheme.palette.primary.light;
      case AuditStatuses.CompletedCertificateSent:
        return certificateSentColor;
    }
    return defaultTheme.palette.neutral.main;
  }
}

export function getAuditCapStatusColor(status) {
  switch (status) {
    case AuditCapStatuses.NotStarted:
      return defaultTheme.palette.error.dark;
    case AuditCapStatuses.InProgress:
      return defaultTheme.palette.primary.light;
    case AuditCapStatuses.Completed:
      return defaultTheme.palette.success.dark;
  }
  return defaultTheme.palette.neutral.main;
}

// #endregion

// #region hooks

interface useDisableAuditFormViewerActionProps {
  action: "completion" | "update-corrections";
  audit: Audit;
  auditVersion?: AuditVersion;
}
// form viewer disable action hook
export function useDisableAuditFormViewerAction({
  action,
  audit,
  auditVersion,
}: useDisableAuditFormViewerActionProps) {
  const { viewerInstance, viewerMode, viewerAnnotations } = useWebViewer();

  const disableAction = useMemo(() => {
    const actionDescription =
      action === "completion" ? "complete the audit" : "update comments";
    let disabled = false;
    let reason = "";

    if (
      !viewerInstance || // disable when viewer instance has not loaded
      !auditVersion?.id ||
      auditVersion.annotations !== viewerAnnotations // or viewer annotations have not loaded
    ) {
      disabled = true;
      reason = `Wait for audit to load to ${actionDescription}`;
    }
    // disable when viewing past audit version
    else if (auditVersion.id !== audit.versions[0]?.id) {
      disabled = true;
      reason = `${
        action === "update-corrections"
          ? "Audit comments cannot be managed from past versions. "
          : ""
      }View the latest audit version to ${actionDescription}`;
    }
    // disable completion action in edit mode
    else if (action === "completion" && viewerMode === ViewerModes.Edit) {
      disabled = true;
      reason = `Exit edit mode to ${actionDescription}`;
    }

    return { disabled, reason };
  }, [
    action,
    audit.versions,
    auditVersion?.annotations,
    auditVersion?.id,
    viewerAnnotations,
    viewerInstance,
    viewerMode,
  ]);

  return disableAction;
}
// #endregion
