import { createSlice } from "@reduxjs/toolkit";

export interface AdminState {}

const initialState: AdminState = {};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {},
  }),
  persist: false,
};
