import { styled } from "@mui/material/styles";

export const ViewerContainerStyled = styled(`div`)(() => ({
  display: "flex",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

export const DocumentBannerStyled = styled(`div`)(() => ({
  position: "absolute",
  left: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FEF7F0",
  borderRadius: "3px",
  padding: "12px 16px",
  width: "calc(100% - 120px)",
  "& button": {
    textTransform: "uppercase",
    textWrap: "nowrap",
  },
}));
