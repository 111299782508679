import React from "react";
import { FacilitiesList } from "./components";

export const FacilitiesPage = React.memo(
  /**
   *
   */
  function FacilitiesPage() {
    return <FacilitiesList />;
  },
);
