import React, { useCallback, useEffect, useState } from "react";
import { adminActions, Facility, useAppDispatch } from "../../../../../state";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../../components";
import { FacilityStatuses } from "../../../../../lib/constants";
import { Navigation } from "../../../../../lib";
import { AdminPages } from "../../..";

interface FacilitySubmissionDialogProps {
  facility: Facility;
  handleConfirm: () => void;
}

export const FacilitySubmissionDialog = React.memo(
  /**
   *
   */
  function FacilitySubmissionDialog({
    facility,
    handleConfirm,
  }: FacilitySubmissionDialogProps) {
    const dispatch = useAppDispatch();

    const [policiesError, setPoliciesError] = useState(false);
    const [showPoliciesErrorDialog, setShowPoliciesErrorDialog] =
      useState(false);
    const [policiesMessage, setPoliciesMessage] = useState("");

    const publishFacilityPolicies = useCallback(async () => {
      const published = await dispatch(
        adminActions.publishPoliciesForFacility(Number(facility.id)),
      );
      if (published) {
        setPoliciesMessage(
          "Policies will be published for this facility - You will receive an email when publishing is complete.",
        );
        setShowPoliciesErrorDialog(false);
      } else {
        setPoliciesMessage(
          "Policies were not published for this facility - publish applicable policies from the Policies page.",
        );
        setShowPoliciesErrorDialog(true);
      }
    }, [dispatch, facility.id]);

    const redirectToPoliciesPage = useCallback(() => {
      Navigation.replace(AdminPages.policies.path, {
        query: { facilities: [facility.id] },
      });
    }, [facility.id]);

    useEffect(() => {
      // generate facility policies upon facility onboarding
      if (facility.status === FacilityStatuses.Onboarding) {
        publishFacilityPolicies();
      }
    }, [facility.status, publishFacilityPolicies]);

    return showPoliciesErrorDialog ? (
      <ConfirmationDialog
        cancelText="Finish onboarding"
        confirmText="Generate policies"
        handleClose={() => {
          setShowPoliciesErrorDialog(false);
          setPoliciesError(true);
        }}
        handleConfirm={publishFacilityPolicies}
        message={
          <>
            Policies were not generated for {facility.name}. {"\n"}Please check
            your internet connection and try again. If the issue persists please
            finish onboarding the facility and then publish each applicable
            policy individually from the Policies page.
          </>
        }
        open={true}
        title="A publishing error occurred"
        type={ConfirmationDialogTypes.Alert}
        width="420px"
      />
    ) : (
      <ConfirmationDialog
        confirmText="Ok"
        hideCancel={true}
        handleConfirm={policiesError ? redirectToPoliciesPage : handleConfirm}
        message={
          <>
            {facility.name} was saved as{" "}
            {facility.status === FacilityStatuses.Pending
              ? "profile pending"
              : facility.status === FacilityStatuses.Review
              ? "under profile review"
              : "onboarding"}
            .
            {facility.status === FacilityStatuses.Pending
              ? " You will be notified when changes are made to the facility profile."
              : ""}
            {"\n"}
            You can manage this facility and add additional users from the
            Groups and Facilities section.
            {policiesMessage ? `\n\n${policiesMessage}` : ""}
          </>
        }
        open={true}
        title={`${facility.name} has been saved`}
        type={ConfirmationDialogTypes.Saved}
      />
    );
  },
);
