import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CommentThreadContainerStyled,
  CommentThreadTitleStyled,
  CommentsPanelContainerStyled,
  CommentsPanelHeaderStyled,
  CommentsTabsStyled,
  CommentsEmptyContainerStyled,
  CommentThreadFooterStyled,
  CommentsListStyled,
  CommentResolvedChipStyled,
  CommentSubtitleStyled,
  CommentTextStyled,
} from "./AuditCommentsPanel.styles";
import {
  CheckCircleIcon,
  DeleteIcon,
  EditIcon,
  FindInPageIcon,
  HealingIcon,
  SendIcon,
} from "../../../../components/icons";
import {
  adminActions,
  Audit,
  AuditComment,
  AuditCommentRequest,
  AuditCommentThread,
  AuditVersion,
  sharedActions,
  useAppDispatch,
} from "../../../../state";
import {
  formatDate,
  Navigation,
  useLocation,
  useWebViewer,
} from "../../../../lib";
import { AuditCommentTypes, UserCategories } from "../../../../lib/constants";
import { AuditCommentDialog } from "./AuditCommentDialog";
import { CheckboxInput, HtmlSanitizedText } from "../../../../components";
import { AuditDeleteCommentDialog } from "./AuditDeleteCommentDialog";
import {
  commentTextRequiresCorrectiveAction,
  CorrectionAnnotationSubject,
  CorrectiveActionTriggerText,
  createCommentRequest,
  removedCommentCorrectiveActionText,
  useDisableAuditFormViewerAction,
} from "./AuditHelpers";
import { FacilityAdminPages } from "../../../facilityAdmin";
import { isToday } from "date-fns";
import { nanoid } from "nanoid/non-secure";
import empty from "../../../../assets/img/empty.svg";
import comment_open from "../../../../assets/img/comment_open.png";
import comment_resolved from "../../../../assets/img/comment_resolved.png";

export const CommentsPanelModes = {
  Default: "Default",
  HideCorrections: "HideCorrections",
  ReadOnly: "ReadOnly",
};

const ActionTypes = {
  Add: "Add",
  Delete: "Delete",
  Edit: "Edit",
};

interface AuditCommentsPanelProps {
  audit: Audit;
  auditVersion?: AuditVersion;
  mode: string;
  handleSaveAnnotations: () => Promise<boolean>;
  initialType?: string;
  isCcgAdmin: boolean;
  setAudit: React.Dispatch<React.SetStateAction<Audit | undefined>>;
}

export const AuditCommentsPanel = React.memo(
  /**
   *
   */
  function AuditCommentsPanel({
    audit,
    auditVersion,
    handleSaveAnnotations,
    initialType,
    isCcgAdmin,
    mode,
    setAudit,
  }: AuditCommentsPanelProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { viewerInstance, viewerAnnotations } = useWebViewer();

    const {
      disabled: updateCorrectionsDisabled,
      reason: updateCorrectionsDisabledReason,
    } = useDisableAuditFormViewerAction({
      action: "update-corrections",
      audit,
      auditVersion,
    });

    const commentTypeOptions = useMemo(
      () => [
        ...(mode !== CommentsPanelModes.HideCorrections
          ? [{ id: AuditCommentTypes.Correction, name: "Comments" }]
          : []),
        { id: AuditCommentTypes.FacilityQuestion, name: "Questions" },
        ...(isCcgAdmin
          ? [{ id: AuditCommentTypes.Internal, name: "Internal" }]
          : []),
      ],
      [mode, isCcgAdmin],
    );

    const [type, setType] = useState(initialType || commentTypeOptions[0]?.id);

    const [showResolved, setShowResolved] = useState({
      [AuditCommentTypes.Correction]: !isCcgAdmin,
      [AuditCommentTypes.FacilityQuestion]: !isCcgAdmin,
      [AuditCommentTypes.Internal]: !isCcgAdmin,
    });

    const [commentThreads, setCommentThreads] = useState<AuditCommentThread[]>(
      [],
    );

    const [activeActionType, setActiveActionType] = useState<string>();
    const [activeCommentRequest, setActiveCommentRequest] =
      useState<AuditCommentRequest>();

    const activeCommentRequestThread = useMemo(
      () =>
        commentThreads.find(
          (c) => c.id === activeCommentRequest?.commentThreadID,
        ),
      [activeCommentRequest?.commentThreadID, commentThreads],
    );

    const onClearAction = useCallback(() => {
      setActiveCommentRequest(undefined);
      setActiveActionType(undefined);
    }, []);

    const refreshAuditCorrectionDetails = useCallback(async () => {
      const auditCorrectionDetails = await dispatch(
        sharedActions.getAuditCorrectionDetails(audit.id),
      );
      if (auditCorrectionDetails) {
        setAudit((audit) => ({
          ...(audit as Audit),
          ...auditCorrectionDetails,
        }));
      }
    }, [audit.id, dispatch, setAudit]);

    const retrieveComments = useCallback(
      async (refreshCorrectionDetails = false) => {
        if (refreshCorrectionDetails) {
          refreshAuditCorrectionDetails();
        }

        const commentThreads = await dispatch(
          sharedActions.getAuditComments(audit.id, {
            includeResolved: showResolved[type],
            type,
          }),
        );
        if (commentThreads) {
          setCommentThreads(commentThreads);
        }
      },
      [audit.id, dispatch, refreshAuditCorrectionDetails, showResolved, type],
    );

    useEffect(() => {
      retrieveComments();
    }, [retrieveComments]);

    const [shownAnnotationIdentifiers, setShownAnnotationIdentifiers] =
      useState(
        showResolved.Correction
          ? audit.correctionAnnotationIdentifiers
          : audit.unresolvedCorrectionAnnotationIdentifiers,
      );

    // set shownAnnotationIdentifiers based on audit correction annotation identifiers and showResolved setting
    useEffect(() => {
      const shownAnnotationIdentifiersUpdate = showResolved.Correction
        ? audit.correctionAnnotationIdentifiers
        : audit.unresolvedCorrectionAnnotationIdentifiers;
      // reset shownAnnotationIdentifiers only if current state does not deep-equal the update
      if (
        shownAnnotationIdentifiers.join(",") !==
        shownAnnotationIdentifiersUpdate.join(",")
      ) {
        setShownAnnotationIdentifiers(shownAnnotationIdentifiersUpdate);
      }
    }, [
      audit.correctionAnnotationIdentifiers,
      audit.unresolvedCorrectionAnnotationIdentifiers,
      showResolved.Correction,
      shownAnnotationIdentifiers,
    ]);

    // display annotation markup for comments that are visible in the comments panel (all are hidden by default)
    useEffect(() => {
      if (!viewerInstance || !viewerAnnotations) {
        return;
      }

      const { annotationManager } = viewerInstance.Core;
      const correctionAnnotations = annotationManager
        .getAnnotationsList()
        .filter((a) => a.Subject === CorrectionAnnotationSubject);
      correctionAnnotations.forEach((a) => {
        const hidden =
          mode === CommentsPanelModes.HideCorrections ||
          !shownAnnotationIdentifiers.includes(a.Id);
        if (hidden !== a.Hidden) {
          a.Hidden = hidden;
          annotationManager.redrawAnnotation(a);
        }
      });
    }, [mode, shownAnnotationIdentifiers, viewerAnnotations, viewerInstance]);

    useEffect(() => {
      if (!viewerInstance) {
        return;
      }

      const {
        Core: { Annotations, annotationManager, documentViewer },
        UI,
      } = viewerInstance;

      let clickCoordinates;
      const mouseRightDownListener = (e) =>
        // gets coordinates on right click for custom stamp annotation placement
        (clickCoordinates =
          documentViewer.getViewerCoordinatesFromMouseEvent(e));

      const annotationChangeListener = async (
        annotations,
        action,
        { imported },
      ) => {
        if (
          action === "modify" &&
          !imported && // limits to annotations updated from within the document (position adjustments)
          annotations[0]?.Subject === CorrectionAnnotationSubject
        ) {
          // save document annotations when a comment annotation has been moved
          await handleSaveAnnotations();
        }
      };

      const annotationSelectionListener = async (annotations, action) => {
        // scroll to comment thread when corresponding annotation is selected
        if (action === "selected" && annotations[0]?.Id) {
          const commentThreadElement = document.getElementById(
            annotations[0].Id,
          );
          if (commentThreadElement)
            commentThreadElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      if (isCcgAdmin) {
        // 'Add corrections' custom context menu popup on right-click for ccg admin
        documentViewer.addEventListener(
          "mouseRightDown",
          mouseRightDownListener,
        );

        UI.contextMenuPopup.update([
          {
            type: "actionButton",
            label: "Add comment",
            onClick: () => {
              const width = 94;
              const minX = 1;
              const maxX =
                documentViewer.getPageWidth(documentViewer.getCurrentPage()) -
                width;

              const stampAnnot = new Annotations.StampAnnotation({
                PageNumber: clickCoordinates.pageNumber,
                X:
                  clickCoordinates.x < minX
                    ? minX
                    : clickCoordinates.x > maxX
                    ? maxX
                    : clickCoordinates.x,
                Y: clickCoordinates.y,
                Width: width,
                Height: 26,
                Id: nanoid(33),
                Subject: CorrectionAnnotationSubject,
                NoDelete: true,
              });
              stampAnnot.setImageData(comment_open);

              annotationManager.addAnnotation(stampAnnot);
              annotationManager.redrawAnnotation(stampAnnot);

              setActiveActionType(ActionTypes.Add);
              setActiveCommentRequest({
                type: AuditCommentTypes.Correction,
                xfdfAnnotationIdentifier: stampAnnot.Id,
                relatedText: documentViewer.getSelectedText(),
              });
              documentViewer.clearSelection();
            },
          },
        ]);

        // add listener for annotation position changes
        annotationManager.addEventListener(
          "annotationChanged",
          annotationChangeListener,
        );
      }

      // add listener for annotation selection
      annotationManager.addEventListener(
        "annotationSelected",
        annotationSelectionListener,
      );

      return () => {
        // remove event listeners
        documentViewer.removeEventListener(
          "mouseRightDown",
          mouseRightDownListener,
        );
        annotationManager.removeEventListener(
          "annotationChanged",
          annotationChangeListener,
        );
        annotationManager.removeEventListener(
          "annotationSelected",
          annotationSelectionListener,
        );
      };
    }, [handleSaveAnnotations, isCcgAdmin, viewerInstance]);

    const handleFindCommentThread = useCallback(
      (commentThread: AuditCommentThread) => {
        if (viewerInstance && commentThread.xfdfAnnotationIdentifier) {
          const { annotationManager } = viewerInstance.Core;
          const commentAnnotation = annotationManager.getAnnotationById(
            commentThread.xfdfAnnotationIdentifier,
          );
          if (commentAnnotation) {
            annotationManager.jumpToAnnotation(commentAnnotation);

            annotationManager.deselectAllAnnotations();
            annotationManager.selectAnnotation(commentAnnotation);
          }
        }
      },
      [viewerInstance],
    );

    // scroll to query-specified focused comment
    useEffect(() => {
      if (
        !viewerInstance ||
        !viewerAnnotations ||
        !commentThreads.length ||
        !location.query.focusedCommentId
      ) {
        return;
      }

      const commentThread = commentThreads?.find((ct) =>
        ct.comments?.some((c) => c.id === location.query.focusedCommentId),
      );
      if (commentThread?.xfdfAnnotationIdentifier) {
        const commentThreadElement = document.getElementById(
          commentThread.xfdfAnnotationIdentifier,
        );
        if (commentThreadElement) {
          commentThreadElement.scrollIntoView({ behavior: "smooth" });
          handleFindCommentThread(commentThread);
        }
      }

      // clear focusedCommentId query param
      Navigation.replace(location.pathname, {
        params: location.params,
        query: { ...location.query, focusedCommentId: undefined },
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      commentThreads,
      handleFindCommentThread,
      location.query.focusedCommentId,
      viewerAnnotations,
      viewerInstance,
    ]);

    const handleCancelCommentRequest = useCallback(async () => {
      if (
        activeActionType === ActionTypes.Add &&
        activeCommentRequest?.xfdfAnnotationIdentifier &&
        viewerInstance
      ) {
        // remove the associated annotation if cancelling on a new correction comment
        const { annotationManager } = viewerInstance.Core;
        const commentAnnotation = annotationManager.getAnnotationById(
          activeCommentRequest.xfdfAnnotationIdentifier,
        );
        if (commentAnnotation) {
          commentAnnotation.NoDelete = false;
          annotationManager.deleteAnnotation(commentAnnotation);
        }
      }

      onClearAction();
    }, [
      activeActionType,
      activeCommentRequest?.xfdfAnnotationIdentifier,
      onClearAction,
      viewerInstance,
    ]);

    const handleSubmitCommentRequest = useCallback(
      async (commentRequest: AuditCommentRequest) => {
        const commentRequestForSubmission = { ...commentRequest };

        const commentRequestThread = commentThreads.find(
          (c) => c.id === commentRequest?.commentThreadID,
        );

        // set auditVersionId for a new comment
        if (!commentRequest.id && auditVersion?.id) {
          commentRequestForSubmission.auditVersionID = auditVersion.id;
        }

        if (commentRequest.type === AuditCommentTypes.Correction) {
          // set correctiveActionRequired flag if comment includes trigger text and there is no corrective action for this thread yet
          if (
            !commentRequestThread?.comments?.some(
              (c) => c.correctiveActionRequired,
            ) &&
            commentTextRequiresCorrectiveAction(
              commentRequestForSubmission.text,
            )
          ) {
            commentRequestForSubmission.correctiveActionRequired = true;
          }
          // remove correctiveActionRequired flag if cap trigger text was removed
          else if (
            activeCommentRequest &&
            removedCommentCorrectiveActionText(
              commentRequestForSubmission,
              activeCommentRequest,
            )
          ) {
            commentRequestForSubmission.correctiveActionRequired = false;
          }
        }

        const saved = await dispatch(
          sharedActions.submitAuditComment(
            audit.id,
            commentRequestForSubmission,
          ),
        );
        if (saved) {
          // save document annotations upon submission of a new comment that is linked to an annotatation identifier
          if (
            !commentRequest.commentThreadID &&
            commentRequest.xfdfAnnotationIdentifier
          ) {
            const annotationsSaved = await handleSaveAnnotations();
            if (!annotationsSaved) return annotationsSaved;
          }

          onClearAction();

          const refreshCorrectionDetails = // refresh correction details when:
            !commentRequest.commentThreadID || // a new comment thread is added
            !!commentRequest.isResolved !==
              !!commentRequestThread?.isResolved || // a comment thread is resolved/unresolved
            !!commentRequestForSubmission.correctiveActionRequired !==
              !!activeCommentRequest?.correctiveActionRequired; // a corrective action is added/removed

          retrieveComments(refreshCorrectionDetails);
        }

        return !!saved;
      },
      [
        activeCommentRequest,
        audit.id,
        auditVersion?.id,
        commentThreads,
        dispatch,
        handleSaveAnnotations,
        onClearAction,
        retrieveComments,
      ],
    );

    const handleDeleteComment = useCallback(async () => {
      if (!activeCommentRequest || !activeCommentRequestThread) return;

      const deleted = await dispatch(
        sharedActions.deleteAuditComment(
          audit.id,
          Number(activeCommentRequest.id),
        ),
      );
      if (deleted) {
        const deletingThread = // the entire thread is deleted when the comment being deleted is the first on the thread
          activeCommentRequestThread.comments?.findIndex(
            (c) => c.id === activeCommentRequest.id,
          ) === 0;
        const deleteAnnotation = // delete annotation when the thread is deleted
          deletingThread && !!activeCommentRequest.xfdfAnnotationIdentifier;

        // delete annotation and save when deleting a comment thread that is linked to an annotation identifier
        if (deleteAnnotation && viewerInstance) {
          const { annotationManager } = viewerInstance.Core;
          const commentAnnotation = annotationManager.getAnnotationById(
            activeCommentRequest.xfdfAnnotationIdentifier,
          );
          if (commentAnnotation) {
            commentAnnotation.NoDelete = false;
            annotationManager.deleteAnnotation(commentAnnotation);

            await handleSaveAnnotations();
          }
        }

        setActiveCommentRequest(undefined);
        setActiveActionType(undefined);

        const refreshCorrectionDetails = // refresh correction details when:
          deleteAnnotation || // an annotation is deleted
          activeCommentRequest.correctiveActionRequired || // a comment with a corrective action is deleted
          (deletingThread && !activeCommentRequestThread.isResolved); // an entire unresolved thread is deleted

        retrieveComments(refreshCorrectionDetails);
      }
    }, [
      activeCommentRequest,
      activeCommentRequestThread,
      audit.id,
      dispatch,
      handleSaveAnnotations,
      retrieveComments,
      viewerInstance,
    ]);

    const handleResolveCommentThreads = useCallback(
      async (commentThreads: AuditCommentThread[], resolve = true) => {
        const saved = await dispatch(
          adminActions.submitAuditCommentsResolved(audit.id, {
            commentThreadIds: commentThreads.map((c) => Number(c.id)),
            isResolved: resolve,
          }),
        );

        if (saved) {
          const updateAnnotations =
            viewerInstance &&
            commentThreads.some((c) => c.xfdfAnnotationIdentifier);

          if (updateAnnotations) {
            commentThreads
              .filter((c) => c.xfdfAnnotationIdentifier)
              .forEach((commentThread) => {
                // update annotation image and save when resolving a comment thread that is linked to an annotatation identifier
                const { annotationManager } = viewerInstance.Core;
                const commentAnnotation = annotationManager.getAnnotationById(
                  commentThread.xfdfAnnotationIdentifier,
                );

                if (commentAnnotation) {
                  commentAnnotation.setImageData(
                    resolve ? comment_resolved : comment_open,
                  );
                  annotationManager.redrawAnnotation(commentAnnotation);
                }
              });

            await handleSaveAnnotations();
          }

          retrieveComments(true);
        }
      },
      [
        audit.id,
        dispatch,
        handleSaveAnnotations,
        retrieveComments,
        viewerInstance,
      ],
    );

    const updateDisabled =
      updateCorrectionsDisabled && type === AuditCommentTypes.Correction;
    const updateDisabledDescription =
      updateDisabled && mode !== CommentsPanelModes.ReadOnly
        ? updateCorrectionsDisabledReason
        : "";
    const readOnly = mode === CommentsPanelModes.ReadOnly;

    const showAddNew =
      !readOnly &&
      (isCcgAdmin
        ? type === AuditCommentTypes.Internal
        : type === AuditCommentTypes.FacilityQuestion);

    return (
      <CommentsPanelContainerStyled tabCount={commentTypeOptions.length}>
        <CommentsPanelHeaderStyled>
          <Box height="32px">
            <Typography variant="subtitle1">Comments</Typography>
            {showAddNew && (
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: "primary.main" }}
                onClick={() => {
                  setActiveActionType(ActionTypes.Add);
                  setActiveCommentRequest({ type });
                }}
              >
                New
              </Button>
            )}
          </Box>
          <Box>
            <CheckboxInput
              checked={showResolved[type]}
              label="Show resolved"
              name="showResolved"
              onChange={(_, val) =>
                setShowResolved({ ...showResolved, [type]: val })
              }
            />
            {isCcgAdmin && audit.unresolvedCommentThreadCounts[type] > 0 && (
              <Tooltip title={updateDisabledDescription}>
                <Box>
                  <Button
                    color="primary"
                    disabled={readOnly || updateDisabled}
                    variant="text"
                    size="small"
                    onClick={() =>
                      handleResolveCommentThreads(
                        commentThreads.filter(
                          (c) =>
                            !c.isResolved &&
                            // exclude comments with incomplete corrective actions
                            !c.comments?.some(
                              (cm) =>
                                cm.correctiveActionRequired &&
                                !cm.correctiveActionCompleted,
                            ),
                        ),
                        true,
                      )
                    }
                  >
                    Resolve all
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        </CommentsPanelHeaderStyled>
        {commentTypeOptions.length > 1 && (
          <CommentsTabsStyled
            onChange={(_, val) => setType(val)}
            scrollButtons="auto"
            value={type}
            variant="scrollable"
          >
            {commentTypeOptions.map((t) => {
              const unresolvedCount = audit.unresolvedCommentThreadCounts[t.id];
              return (
                <Tab
                  key={t.id}
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">{t.name}</Typography>
                      {unresolvedCount > 0 && (
                        <Badge
                          color="error"
                          badgeContent={unresolvedCount}
                          sx={{ ml: 2 }}
                        />
                      )}
                    </Box>
                  }
                  value={t.id}
                />
              );
            })}
          </CommentsTabsStyled>
        )}
        {commentThreads.length ? (
          <CommentsListStyled>
            {commentThreads.map((commentThread, i) => (
              <CommentThreadDisplay
                auditId={audit.id}
                commentThread={commentThread}
                handleDeleteComment={(comment) => {
                  setActiveActionType(ActionTypes.Delete);
                  setActiveCommentRequest(
                    createCommentRequest(comment, commentThread),
                  );
                }}
                handleEditComment={(comment) => {
                  setActiveActionType(ActionTypes.Edit);
                  setActiveCommentRequest(
                    createCommentRequest(comment, commentThread),
                  );
                }}
                handleFindCommentThread={() =>
                  handleFindCommentThread(commentThread)
                }
                handleResolveCommentThread={(resolve) =>
                  handleResolveCommentThreads([commentThread], resolve)
                }
                handleSubmitComment={handleSubmitCommentRequest}
                isCcgAdmin={isCcgAdmin}
                key={i}
                readOnly={readOnly}
                updateDisabled={updateDisabled}
                updateDisabledDescription={updateDisabledDescription}
              />
            ))}
          </CommentsListStyled>
        ) : (
          <CommentsEmptyContainerStyled>
            <img alt="no-preview" src={empty} height={180} />
            <Typography variant="subtitle1" mt={2}>
              No{" "}
              {type === AuditCommentTypes.FacilityQuestion
                ? "questions"
                : "comments"}{" "}
              found
            </Typography>
            {mode !== CommentsPanelModes.ReadOnly && !isCcgAdmin && (
              <>
                <Typography variant="body2" mt={2} mb={2}>
                  Have a question?
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "primary.main" }}
                  onClick={() => {
                    setActiveActionType(ActionTypes.Add);
                    setActiveCommentRequest({
                      type: AuditCommentTypes.FacilityQuestion,
                    });

                    setType(AuditCommentTypes.FacilityQuestion);
                  }}
                >
                  Ask a question
                </Button>
              </>
            )}
          </CommentsEmptyContainerStyled>
        )}
        {activeCommentRequest &&
          (activeActionType === ActionTypes.Delete ? (
            <AuditDeleteCommentDialog
              comment={activeCommentRequest}
              commentThread={activeCommentRequestThread || {}}
              handleClose={() => {
                setActiveCommentRequest(undefined);
                setActiveActionType(undefined);
              }}
              handleConfirm={handleDeleteComment}
            />
          ) : (
            <AuditCommentDialog
              comment={activeCommentRequest}
              handleCancel={handleCancelCommentRequest}
              handleSubmit={handleSubmitCommentRequest}
            />
          ))}
      </CommentsPanelContainerStyled>
    );
  },
);

interface CommentThreadDisplayProps {
  auditId: number;
  commentThread: AuditCommentThread;
  handleDeleteComment: (comment: AuditComment) => void;
  handleEditComment: (comment: AuditComment) => void;
  handleFindCommentThread: () => void;
  handleResolveCommentThread: (resolved: boolean) => void;
  handleSubmitComment: (comment: AuditCommentRequest) => Promise<boolean>;
  isCcgAdmin: boolean;
  readOnly?: boolean;
  updateDisabled?: boolean;
  updateDisabledDescription?: string;
}

function CommentThreadDisplay({
  auditId,
  commentThread,
  handleDeleteComment,
  handleEditComment,
  handleFindCommentThread,
  handleResolveCommentThread,
  handleSubmitComment,
  isCcgAdmin,
  readOnly,
  updateDisabled,
  updateDisabledDescription,
}: CommentThreadDisplayProps) {
  const [replyText, setReplyText] = useState("");

  const onSubmitReply = useCallback(async () => {
    const replyCommentRequest = createCommentRequest(
      { text: replyText, commentThreadID: commentThread.id },
      commentThread,
    );

    const saved = await handleSubmitComment(replyCommentRequest);
    if (saved) {
      setReplyText("");
    }
  }, [commentThread, handleSubmitComment, replyText]);

  const { hasCorrectiveAction, hasIncompleteCorrectiveAction } = useMemo(() => {
    const hasCorrectiveAction = commentThread.comments?.some(
      (c) => c.correctiveActionRequired,
    );

    const hasIncompleteCorrectiveAction = commentThread.comments?.some(
      (c) => c.correctiveActionRequired && !c.correctiveActionCompleted,
    );

    return { hasCorrectiveAction, hasIncompleteCorrectiveAction };
  }, [commentThread.comments]);

  const hideReplyInput = readOnly || commentThread.isResolved;

  return (
    <CommentThreadContainerStyled id={commentThread.xfdfAnnotationIdentifier}>
      {commentThread.type === AuditCommentTypes.Correction && (
        <CommentThreadTitleStyled variant="subtitle2">
          {commentThread.isResolved && (
            <CheckCircleIcon
              fontSize="small"
              sx={{ color: "success.dark", mr: 1 }}
            />
          )}
          {commentThread.questionNumber}
          {commentThread.relatedText ? `: ${commentThread.relatedText}` : ""}
        </CommentThreadTitleStyled>
      )}
      {commentThread.comments?.map((comment) => (
        <CommentDisplay
          allowEditDelete={
            !readOnly &&
            !updateDisabled &&
            isCcgAdmin &&
            comment.commenterCategory === UserCategories.CcgAdmin
          }
          auditId={auditId}
          comment={comment}
          key={comment.id}
          onDelete={() => handleDeleteComment(comment)}
          onEdit={() => handleEditComment(comment)}
          type={commentThread.type}
        />
      ))}
      {!hideReplyInput && (
        <TextField
          fullWidth={true}
          inputProps={{ maxLength: 2000 }}
          multiline={true}
          onChange={(e) => setReplyText(e.target.value)}
          placeholder="Reply"
          value={replyText}
          size="small"
          InputProps={{
            endAdornment: replyText ? (
              <IconButton onClick={onSubmitReply} size="small">
                <SendIcon />
              </IconButton>
            ) : null,
          }}
          sx={{ mt: "8px" }}
        />
      )}
      <CommentThreadFooterStyled>
        <Box display="flex" alignItems="center">
          {hasCorrectiveAction && (
            <Tooltip title="CAP">
              <IconButton
                onClick={() => {
                  const capUrl = Navigation.fullUrl(
                    FacilityAdminPages.auditCap,
                    {
                      params: { id: auditId },
                      query: {
                        actionId: commentThread.comments?.find(
                          (c) => c.correctiveActionID,
                        )?.correctiveActionID,
                      },
                    },
                  );
                  window.open(capUrl, "_blank");
                }}
                size="small"
                sx={{ mr: "4px", color: "primary.light" }}
              >
                <HealingIcon />
              </IconButton>
            </Tooltip>
          )}
          {commentThread.xfdfAnnotationIdentifier && (
            <Tooltip title="Find my comment">
              <Button
                onClick={handleFindCommentThread}
                size="small"
                sx={{ color: "text.secondary" }}
                variant="text"
              >
                <FindInPageIcon sx={{ mr: "4px" }} />
                Find comment
              </Button>
            </Tooltip>
          )}
        </Box>
        {isCcgAdmin ? (
          <Tooltip
            title={
              updateDisabledDescription ||
              (hasIncompleteCorrectiveAction
                ? "Complete corrective action before resolving comment"
                : "")
            }
          >
            <Box>
              <CheckboxInput
                checked={!!commentThread.isResolved}
                disabled={
                  readOnly || updateDisabled || hasIncompleteCorrectiveAction
                }
                label={commentThread.isResolved ? "Resolved" : "Resolve"}
                name="isResolved"
                onChange={(_, resolved) => handleResolveCommentThread(resolved)}
              />
            </Box>
          </Tooltip>
        ) : commentThread.isResolved ? (
          <CommentResolvedChipStyled label="Resolved" />
        ) : (
          <div />
        )}
      </CommentThreadFooterStyled>
    </CommentThreadContainerStyled>
  );
}

interface CommentDisplayProps {
  allowEditDelete: boolean;
  auditId: number;
  comment: AuditComment;
  onDelete: () => void;
  onEdit: () => void;
  type?: string;
}

function CommentDisplay({
  allowEditDelete,
  auditId,
  comment,
  onDelete,
  onEdit,
  type,
}: CommentDisplayProps) {
  const capTextIndex =
    comment.correctiveActionRequired && comment?.text
      ? comment.text
          .toLowerCase()
          .indexOf(CorrectiveActionTriggerText.toLowerCase())
      : -1;
  return (
    <Box mb="16px">
      <CommentSubtitleStyled>
        <Typography variant="caption" color="text.secondary">
          {comment.commenterName} •{" "}
          {formatDate(
            comment.createdOn,
            isToday(new Date(comment.createdOn || "")) ? "h:mm aa" : "MMM d",
          )}
          {comment.auditVersionNumber
            ? ` • Version ${comment.auditVersionNumber}`
            : ""}
        </Typography>
        {allowEditDelete && (
          <Box display="flex">
            <IconButton size="small" sx={{ mr: 1 }} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
      </CommentSubtitleStyled>
      <HtmlSanitizedText
        color={type === AuditCommentTypes.Correction ? "error" : ""}
        styledTypography={CommentTextStyled}
        text={
          comment.text && capTextIndex >= 0
            ? `${comment.text.substring(0, capTextIndex)}<a
            target="_blank"
            href="${Navigation.url(FacilityAdminPages.auditCap, {
              params: { id: auditId },
              query: { actionId: comment.correctiveActionID },
            })}"
          >
            ${comment.text.substring(
              capTextIndex,
              capTextIndex + CorrectiveActionTriggerText.length,
            )}
          </a>${comment.text.substring(
            capTextIndex + CorrectiveActionTriggerText.length,
            comment.text.length,
          )}`
            : comment.text
        }
      />
    </Box>
  );
}
