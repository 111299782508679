import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { IconButton, Typography } from "@mui/material";
import {
  DownloadIcon,
  FilePreviewDialog,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
} from "../../../../components";
import { useFileDownload } from "../../../../lib";
import { ListActionsProps } from "../../../../components";
import { ListTypes } from "../../../../state/lists/state";
import { TrainingPacketRecord } from "../../../../state";

const filterQueryKey = {
  text: "search",
};

function TrainingPacketsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { search = "" } = query;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Packets</Typography>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled>
        <SearchInput
          variant="outlined"
          placeholder="Search packets"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
          sx={{ mr: 2 }}
        />
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const TrainingPacketsList = React.memo(
  /**
   *
   */
  function TrainingPacketsList() {
    const downloadFile = useFileDownload();

    const [previewPacket, setPreviewPacket] = useState<TrainingPacketRecord>();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          width: 80,
          renderCell: ({ row }: { row: TrainingPacketRecord }) => (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(row.url, row.name, true);
              }}
              sx={{ color: "text.secondary" }}
            >
              <DownloadIcon />
            </IconButton>
          ),
          sortable: false,
        },
      ],
      [downloadFile],
    );

    const onRowClick = useCallback(
      ({ row }: { row: TrainingPacketRecord }) => setPreviewPacket(row),
      [],
    );

    return (
      <>
        <List
          actions={<TrainingPacketsListActions />}
          columns={columns}
          filterQueryKey={filterQueryKey}
          name="training packets"
          onRowClick={onRowClick}
          rowHeight={84}
          rowNumbers={true}
          type={ListTypes.trainingPackets}
        />
        {previewPacket && (
          <FilePreviewDialog
            fileName={previewPacket.name}
            fileSource={previewPacket.url}
            handleClose={() => setPreviewPacket(undefined)}
            open={true}
            secure={true}
          />
        )}
      </>
    );
  },
);
