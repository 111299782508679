import React from "react";
import { AuditCap } from "../adminShared/components";

export const AuditCapPage = React.memo(
  /**
   *
   */
  function AuditCapPage() {
    return <AuditCap />;
  },
);
