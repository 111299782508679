import { facilityFilesPath } from "../../layouts/main/drawer/FacilityHeader";
import { PermissionClaims } from "../../lib/constants";
import { PageDefinitions } from "../../lib/types";
import { AuditCapPage } from "./AuditCapPage";
import { AuditPage } from "./AuditPage";
import { AuditsPage } from "./AuditsPage";
import { DashboardPage } from "./DashboardPage";
import { FacilityFileFormPage } from "./FacilityFileFormPage";
import { FacilityFilePage } from "./FacilityFilePage";
import { FacilityFilesPage } from "./FacilityFilesPage";
import { PendingFacilityPage } from "./PendingFacilityPage";
import { PoliciesPage } from "./PoliciesPage";
import { PolicyPage } from "./PolicyPage";
import { RequiredDocumentsPage } from "./RequiredDocumentsPage";
import { ResourcePage } from "./ResourcePage";
import { ResourcesPage } from "./ResourcesPage";
import { TrainingPage } from "./TrainingPage";
import { TrainingsPage } from "./TrainingsPage";
import { UsersPage } from "./UsersPage";

export const FacilityAdminPages: PageDefinitions = {
  // dashboard
  dashboard: {
    anon: false,
    isDefault: true,
    path: "/",
    title: "",
    view: DashboardPage,
  },
  // policies
  policies: {
    anon: false,
    authClaims: [PermissionClaims.PoliciesReadClaim],
    path: "/policies",
    title: "Policies and Procedures",
    view: PoliciesPage,
  },
  policy: {
    anon: false,
    authClaims: [PermissionClaims.PoliciesReadClaim],
    goBack: true,
    goBackDefaultPath: "/policies",
    path: "/policies/:id",
    title: "Policies",
    view: PolicyPage,
  },
  // resources
  requiredDocuments: {
    anon: false,
    authClaims: [PermissionClaims.ResourcesReadClaim],
    path: "/required-docs",
    title: "Required Documents",
    view: RequiredDocumentsPage,
  },
  resources: {
    anon: false,
    authClaims: [PermissionClaims.ResourcesReadClaim],
    path: "/resources",
    title: "Resources",
    view: ResourcesPage,
  },
  resource: {
    anon: false,
    authClaims: [PermissionClaims.ResourcesReadClaim],
    goBack: true,
    goBackDefaultPath: "/resources",
    path: "/resources/:id",
    title: "Resource",
    view: ResourcePage,
  },
  // trainings
  trainings: {
    anon: false,
    authClaims: [PermissionClaims.TrainingsReadClaim],
    path: "/trainings",
    title: "Trainings",
    view: TrainingsPage,
  },
  training: {
    anon: false,
    authClaims: [PermissionClaims.TrainingsReadClaim],
    goBack: true,
    goBackDefaultPath: "/trainings",
    path: "/trainings/:id",
    title: "Training",
    view: TrainingPage,
  },
  // audits
  audits: {
    anon: false,
    authClaims: [PermissionClaims.AuditsClaim],
    path: "/audits",
    title: "Audits",
    view: AuditsPage,
  },
  audit: {
    anon: false,
    authClaims: [PermissionClaims.AuditsClaim],
    goBack: true,
    goBackDefaultPath: "/audits",
    path: "/audits/:id",
    title: "Audit",
    view: AuditPage,
  },
  auditCap: {
    anon: false,
    authClaims: [PermissionClaims.AuditsClaim],
    goBack: true,
    goBackDefaultPath: "/audits",
    path: "/audits/:id/cap",
    title: "Corrective Action Plan",
    view: AuditCapPage,
  },
  // facility files
  facilityFiles: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    path: facilityFilesPath,
    title: "Facility Files",
    view: FacilityFilesPage,
  },
  facilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    goBack: true,
    goBackDefaultPath: "/files",
    path: `${facilityFilesPath}/:id`,
    title: "Facility Files",
    view: FacilityFilePage,
  },
  addFacilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesEditClaim],
    goBack: true,
    path: `${facilityFilesPath}/new`,
    goBackDefaultPath: "/files",
    title: "Add File",
    view: FacilityFileFormPage,
  },
  editFacilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesEditClaim],
    goBack: true,
    path: `${facilityFilesPath}/:id/edit`,
    goBackDefaultPath: "/files",
    title: "Edit File",
    view: FacilityFileFormPage,
  },
  // users
  users: {
    anon: false,
    authClaims: [PermissionClaims.UsersReadClaim],
    path: "/users",
    title: "Users",
    view: UsersPage,
  },
  // facility
  pendingFacility: {
    anon: false,
    authClaims: [PermissionClaims.PendingFacilityEditClaim],
    path: "/facilities/:id",
    title: "Facility Profile",
    view: PendingFacilityPage,
  },
};
