import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { LinkTextStyled } from "../../../../components";
import {
  LatestAuditDetails,
  facilityAdminActions,
  useAppDispatch,
} from "../../../../state";
import { AuditStatuses } from "../../../../lib/constants";
import { Navigation, useLocation } from "../../../../lib";
import { FacilityAdminPages } from "../..";
import { LatestAuditDisplayStyled } from "./AuditsList.styles";
import auditCompleted from "../../../../assets/img/audit_completed.svg";
import auditCorrections from "../../../../assets/img/audit_corrections.svg";
import auditReview from "../../../../assets/img/audit_review.svg";
import logo from "../../../../assets/img/logo_blue.svg";

interface LatestAuditDisplayProps {
  facilityId: number;
}

export const LatestAuditDisplay = React.memo(
  /**
   *
   */
  function LatestAuditDisplay({ facilityId }: LatestAuditDisplayProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [latestAudit, setLatestAudit] = useState<LatestAuditDetails | null>();

    const getLatestAudit = useCallback(async () => {
      const audit = await dispatch(
        facilityAdminActions.getLatestAudit(facilityId),
      );
      setLatestAudit(audit);
    }, [dispatch, facilityId]);

    useEffect(() => {
      getLatestAudit();
    }, [getLatestAudit]);

    const { title, message, imgSrc } = useMemo(() => {
      let title = "";
      let message: JSX.Element = <></>;
      let imgSrc = "";

      if (latestAudit === null) {
        imgSrc = logo;
        title = "No audits found";
      }

      if (latestAudit) {
        const getViewPastAuditsLink = (auditCompleted = false) => {
          const hasOtherOpenAudits =
            latestAudit.openAuditsCount > (auditCompleted ? 0 : 1); // if the current audit is closed, show 'view past' link if there is at least one open audit, otherwise show it only if there is more than one open audit
          return hasOtherOpenAudits ? (
            <Typography
              component="div"
              variant="body2"
              mt={auditCompleted ? "0px" : "12px"}
            >
              {auditCompleted ? "" : "Or "}
              <LinkTextStyled
                onClick={() =>
                  Navigation.replace(location.pathname, {
                    query: { ...location.query, showPast: true },
                  })
                }
                variant="subtitle2"
              >
                {auditCompleted ? "Click" : "click"} here
              </LinkTextStyled>{" "}
              to view past audits that are still open
            </Typography>
          ) : (
            <></>
          );
        };

        const viewAuditButton = (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() =>
              Navigation.go(FacilityAdminPages.audit, {
                params: { id: latestAudit.id },
              })
            }
          >
            View audit
          </Button>
        );

        switch (latestAudit.status) {
          case AuditStatuses.Completed:
          case AuditStatuses.CompletedCertificateSent:
            imgSrc = auditCompleted;
            title = "Great job on your last audit!";
            message = getViewPastAuditsLink(true);
            break;
          case AuditStatuses.ResentCorrectionsNeeded:
            title = "You have an open audit";
            message = (
              <>
                <Typography variant="body2" mb="16px">
                  Your audit is awaiting corrections
                </Typography>
                {viewAuditButton}
                {getViewPastAuditsLink()}
              </>
            );
            imgSrc = auditCorrections;
            break;
          case AuditStatuses.AwaitingGrade:
          case AuditStatuses.ReadyForReview:
          case AuditStatuses.FollowUpNeeded:
            title = "You have an open audit";
            message = (
              <>
                <Typography variant="body2" mb="16px">
                  Your audit is being reviewed by CCG
                </Typography>
                {viewAuditButton}
                {getViewPastAuditsLink()}
              </>
            );
            imgSrc = auditReview;
            break;
          case AuditStatuses.SentNotStarted:
          case AuditStatuses.SentInProgress:
            title = "You have an open audit";
            message = (
              <>
                <Typography variant="body2" mb="16px">
                  View the audit to{" "}
                  {latestAudit.status === AuditStatuses.SentNotStarted
                    ? "get started"
                    : "continue"}
                </Typography>
                {viewAuditButton}
                {getViewPastAuditsLink()}
              </>
            );
            imgSrc = auditReview;
            break;
        }
      }

      return { title, message, imgSrc };
    }, [latestAudit, location.pathname, location.query]);

    if (latestAudit === undefined) return null;

    return (
      <LatestAuditDisplayStyled noAudit={!latestAudit}>
        <img src={imgSrc} alt="audit" />
        <Typography variant={!latestAudit ? "subtitle2" : "subtitle1"} mb="8px">
          {title}
        </Typography>
        {message}
      </LatestAuditDisplayStyled>
    );
  },
);
