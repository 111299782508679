import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import {
  ButtonMenu,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DateInput,
  DialogCheckboxContainerStyled,
  MoreVertIcon,
  OpenInNewIcon,
  SelectInput,
} from "../../../../../components";
import {
  FileUploadTypes,
  formatDate,
  Navigation,
  pluralizeText,
  useFileUpload,
  useLocation,
  useSecureFileRead,
  useWebViewer,
} from "../../../../../lib";
import {
  adminActions,
  Audit,
  AuditVersion,
  uiActions,
  useAppDispatch,
} from "../../../../../state";
import { AuditStatuses } from "../../../../../lib/constants";
import { mergePdfAnnotations } from "../../../../../components/pdf/PdfFormViewer";
import {
  CorrectionAnnotationSubject,
  useDisableAuditFormViewerAction,
} from "../../../../adminShared/components/audits/AuditHelpers";
import { GenerateAuditCertificateButton } from "./GenerateAuditCertificateButton";
import { AuditGiftCardActions } from "./AuditGiftCardActions";
import { AuditCertificatePreviewDialog } from "../../../../adminShared/components";
import { AdminPages } from "../../..";
import transparent_placeholder from "../../../../../assets/img/transparent_placeholder.png";

const ActionTypes = {
  SubmitForReview: "SubmitForReview",
  FollowUpNeeded: "FollowUpNeeded",
  Reassign: "Reassign",
  MarkComplete: "MarkComplete",
  Rescind: "Rescind",
  Reset: "Reset",
  SendReminder: "SendReminder",
};

interface AuditActionsProps {
  audit: Audit;
  auditVersion?: AuditVersion;
}

export const AuditActions = React.memo(
  /**
   *
   */
  function AuditActions({ audit, auditVersion }: AuditActionsProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { viewerInstance } = useWebViewer();

    const {
      disabled: completeAuditDisabled,
      reason: completeAuditDisabledReason,
    } = useDisableAuditFormViewerAction({
      action: "completion",
      audit,
      auditVersion,
    });

    const [activeActionType, setActiveActionType] = useState("");
    const [auditResetDueDate, setAuditResetDueDate] = useState(audit.dueDate);
    const [auditResetNotify, setAuditResetNotify] = useState(false);
    const [auditReminderMessage, setAuditReminderMessage] = useState("");
    const [showCertificatePreview, setShowCertificatePreview] = useState(false);

    const getSecureFileUrl = useSecureFileRead("audit");
    const uploadFile = useFileUpload("completed audit");

    const uploadCompletedAudit = useCallback(async () => {
      if (completeAuditDisabled || !viewerInstance) {
        return;
      }

      dispatch(uiActions.setLoading(true));

      let uploadUrl;
      try {
        const auditUrl = await getSecureFileUrl(audit.url);

        // hide correction comment annotation markup before merging
        const commentAnnotations = viewerInstance.Core.annotationManager
          .getAnnotationsList()
          .filter((a) => a.Subject === CorrectionAnnotationSubject);

        if (commentAnnotations.length) {
          commentAnnotations.forEach((a) => {
            a.setImageData(transparent_placeholder); // note: not redrawing the annotations bec we are hiding for merge purposes only, UI should not be affected
          });
        }

        const mergedAudit = await mergePdfAnnotations(viewerInstance, auditUrl);

        uploadUrl = await uploadFile(
          mergedAudit,
          FileUploadTypes.CompletedAudits,
          true,
        );
      } catch (err) {
        console.error("Error merging audit file: ", err);
        dispatch(uiActions.showError("Failed to upload completed audit"));
      }

      dispatch(uiActions.setLoading(false));

      return uploadUrl;
    }, [
      audit.url,
      completeAuditDisabled,
      dispatch,
      getSecureFileUrl,
      uploadFile,
      viewerInstance,
    ]);

    const onUpdateAuditStatus = useCallback(
      async (status: string, certificateUrl?: string) => {
        const { id, dueDate, quarter, year } = audit;
        const updatedAudit = {
          id,
          dueDate,
          quarter,
          year,
          status,
          completedAuditUrl: undefined,
          certificateUrl,
        };

        // when audit is marked completed, upload merged audit file
        if (
          audit.status !== AuditStatuses.Completed &&
          (status === AuditStatuses.Completed ||
            status === AuditStatuses.CompletedCertificateSent)
        ) {
          const completedAuditUrl = await uploadCompletedAudit();
          if (!completedAuditUrl) {
            return;
          }
          updatedAudit.completedAuditUrl = completedAuditUrl;
        }

        const saved = await dispatch(adminActions.submitAudit(updatedAudit));
        if (saved) {
          // return to the prev route after saving
          Navigation.goBack(AdminPages.audits.path);
        }
      },
      [audit, dispatch, uploadCompletedAudit],
    );

    const onResetAudit = useCallback(async () => {
      const newAudit = await dispatch(
        adminActions.resetAudit(audit.id, {
          dueDate: auditResetDueDate,
          notifyFacilities: auditResetNotify,
        }),
      );
      if (newAudit) {
        Navigation.replace(
          Navigation.url(AdminPages.audit, {
            params: { id: newAudit.id },
          }),
        );
      }
    }, [audit.id, auditResetDueDate, auditResetNotify, dispatch]);

    const onSendAuditReminder = useCallback(async () => {
      const sent = await dispatch(
        adminActions.sendAuditReminder(audit.id, auditReminderMessage),
      );

      if (sent) {
        setAuditReminderMessage("");
      }
    }, [audit.id, auditReminderMessage, dispatch]);

    const onCloseAction = useCallback(() => {
      setActiveActionType("");
      setAuditResetDueDate(audit.dueDate);
      setAuditResetNotify(false);
      setAuditReminderMessage("");
    }, [audit.dueDate]);

    const onConfirmAction = useCallback(() => {
      switch (activeActionType) {
        case ActionTypes.SubmitForReview:
          onUpdateAuditStatus(AuditStatuses.ReadyForReview);
          break;
        case ActionTypes.FollowUpNeeded:
          onUpdateAuditStatus(AuditStatuses.FollowUpNeeded);
          break;
        case ActionTypes.Reassign:
          onUpdateAuditStatus(AuditStatuses.ResentCorrectionsNeeded);
          break;
        case ActionTypes.MarkComplete:
          onUpdateAuditStatus(AuditStatuses.Completed);
          break;
        case ActionTypes.Rescind:
          onUpdateAuditStatus(AuditStatuses.Rescinded);
          break;
        case ActionTypes.Reset:
          onResetAudit();
          break;
        case ActionTypes.SendReminder:
          onSendAuditReminder();
          break;
      }
      setActiveActionType("");
    }, [
      activeActionType,
      onResetAudit,
      onSendAuditReminder,
      onUpdateAuditStatus,
    ]);

    const actionConfirmationDetails = useMemo(() => {
      let confirmText = "",
        message = "" as string | JSX.Element,
        title = "",
        type = ConfirmationDialogTypes.Warning;

      switch (activeActionType) {
        case ActionTypes.SubmitForReview:
          confirmText = "Submit for review";
          title = "Submit for review?";
          message =
            "Please confirm that you'd like to submit this audit for review.";
          break;
        case ActionTypes.FollowUpNeeded:
          confirmText = "Follow-up needed";
          title = "Follow-up needed?";
          message =
            "Please confirm that you'd like to mark this audit as follow-up needed.";
          break;
        case ActionTypes.Reassign:
          confirmText = "Reassign";
          title = "Reassign audit?";
          message = "Please confirm that you'd like to reassign this audit.";
          break;
        case ActionTypes.MarkComplete: {
          confirmText = "Mark complete";
          if (audit.hasIncompleteCorrectiveActions) {
            title = "There is an open CAP linked to this audit.";
            message =
              "Please confirm that you'd like to mark this audit as complete.";
            type = ConfirmationDialogTypes.Alert;
          } else {
            title = "Mark audit complete?";
            message =
              "Please confirm that you'd like to mark this audit as complete.";
          }

          const unresolvedCorrectionCommentsCount =
            audit.unresolvedCommentThreadCounts.Correction;
          const unresolvedQuestionCommentsCount =
            audit.unresolvedCommentThreadCounts.FacilityQuestion;
          if (
            unresolvedCorrectionCommentsCount ||
            unresolvedQuestionCommentsCount
          ) {
            message = (
              <>
                {message} <br />
                Note: This audit has{" "}
                {unresolvedCorrectionCommentsCount ? (
                  <b>
                    {unresolvedCorrectionCommentsCount} unresolved{" "}
                    {pluralizeText(
                      "comment",
                      unresolvedCorrectionCommentsCount,
                    )}
                  </b>
                ) : (
                  ""
                )}
                {unresolvedCorrectionCommentsCount &&
                unresolvedQuestionCommentsCount
                  ? " and "
                  : ""}
                {unresolvedQuestionCommentsCount ? (
                  <b>
                    {unresolvedQuestionCommentsCount} unresolved{" "}
                    {pluralizeText("question", unresolvedQuestionCommentsCount)}
                  </b>
                ) : (
                  ""
                )}
                .
              </>
            );
          }
          break;
        }
        case ActionTypes.Rescind:
          confirmText = "Rescind";
          title = "Rescind audit?";
          message =
            "Please confirm that you'd like to rescind this audit. Note that the facility will not be notified that the audit has been rescinded and will no longer have access to view the audit.";
          break;
        case ActionTypes.Reset:
          confirmText = "Reset";
          title = "Reset audit?";
          message = `Please confirm that you'd like to reset this audit.  This will${
            audit.status !== AuditStatuses.Rescinded
              ? " rescind this audit and will"
              : ""
          } send a new audit to the facility. The facility will not be notified that the audit has been reset unless you choose to notify them by checking the checkbox below.`;
          break;
        case ActionTypes.SendReminder:
          confirmText = "Send";
          title = "Send audit reminder?";
          message =
            "If you'd like to add a custom message to the reminder email, please specify your message below.";
          break;
      }

      return {
        confirmText,
        message,
        title,
        type,
      };
    }, [
      activeActionType,
      audit.hasIncompleteCorrectiveActions,
      audit.status,
      audit.unresolvedCommentThreadCounts.Correction,
      audit.unresolvedCommentThreadCounts.FacilityQuestion,
    ]);

    const onSelectVersion = useCallback(
      (_, id) => {
        const versionUrl = Navigation.fullUrl(location.pathname, {
          params: location.params,
          query: { versionId: id },
        });
        window.open(versionUrl, "_blank");
      },
      [location.params, location.pathname],
    );

    return (
      <Box display="flex" alignItems="center" padding="8px 0">
        <SelectInput
          clearable={false}
          disabled={!audit.versions.length}
          name="versions"
          value={auditVersion?.id || ""}
          onChange={onSelectVersion}
          options={audit.versions.map((v) => ({
            id: v.id as number,
            name: `Version ${v.number} - ${formatDate(v.createdOn)}`,
            tag: (
              <Box display="flex" alignItems="center">
                <OpenInNewIcon sx={{ color: "neutral.main" }} />
              </Box>
            ),
          }))}
          label={!audit.versions.length ? "No versions saved" : ""}
          sx={{ width: "280px", mr: 2 }}
        />
        {(audit.status === AuditStatuses.AwaitingGrade ||
          audit.status === AuditStatuses.FollowUpNeeded) && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mr: 1 }}
            onClick={() => setActiveActionType(ActionTypes.SubmitForReview)}
          >
            Submit for review
          </Button>
        )}
        {audit.status === AuditStatuses.ReadyForReview && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mr: 1 }}
              onClick={() => setActiveActionType(ActionTypes.FollowUpNeeded)}
            >
              Follow-up needed
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mr: 1 }}
              onClick={() => setActiveActionType(ActionTypes.Reassign)}
            >
              Reassign
            </Button>
            <Tooltip title={completeAuditDisabledReason}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={completeAuditDisabled}
                  size="large"
                  sx={{ mr: 1 }}
                  onClick={() => setActiveActionType(ActionTypes.MarkComplete)}
                >
                  Mark complete
                </Button>
              </Box>
            </Tooltip>
          </>
        )}
        {(audit.status === AuditStatuses.ReadyForReview ||
          audit.status === AuditStatuses.Completed) && (
          <Tooltip
            title={
              audit.status !== AuditStatuses.Completed
                ? completeAuditDisabledReason
                : ""
            }
          >
            <Box>
              <GenerateAuditCertificateButton
                disabled={
                  audit.status !== AuditStatuses.Completed &&
                  completeAuditDisabled
                }
                audit={audit}
                onSubmit={(certificateUrl) =>
                  onUpdateAuditStatus(
                    AuditStatuses.CompletedCertificateSent,
                    certificateUrl,
                  )
                }
              />
            </Box>
          </Tooltip>
        )}
        {audit.certificateUrl && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mr: 1 }}
              onClick={() => setShowCertificatePreview(true)}
            >
              View certificate
            </Button>
            {showCertificatePreview && (
              <AuditCertificatePreviewDialog
                certificateUrl={audit.certificateUrl}
                handleClose={() => setShowCertificatePreview(false)}
              />
            )}
          </>
        )}
        {audit.status === AuditStatuses.Completed ||
        audit.status === AuditStatuses.CompletedCertificateSent ? (
          <AuditGiftCardActions audit={audit} />
        ) : (
          <ButtonMenu
            button={
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ minWidth: "36px", padding: "8px 0" }}
              >
                <MoreVertIcon sx={{ height: "20px" }} />
              </Button>
            }
            menuItems={[
              {
                label: "Reset",
                onClick: () => setActiveActionType(ActionTypes.Reset),
              },
              ...(audit.status !== AuditStatuses.Rescinded
                ? [
                    {
                      label: "Rescind",
                      onClick: () => setActiveActionType(ActionTypes.Rescind),
                    },
                  ]
                : []),
              ...(audit.status === AuditStatuses.SentNotStarted ||
              audit.status === AuditStatuses.SentInProgress ||
              audit.status === AuditStatuses.ResentCorrectionsNeeded
                ? [
                    {
                      label: "Send Reminder",
                      onClick: () =>
                        setActiveActionType(ActionTypes.SendReminder),
                    },
                  ]
                : []),
            ]}
          />
        )}
        {activeActionType && (
          <ConfirmationDialog
            cancelText="Back to audit"
            confirmText={actionConfirmationDetails.confirmText}
            handleConfirm={
              activeActionType === ActionTypes.Reset && !auditResetDueDate
                ? () => ""
                : onConfirmAction
            }
            handleClose={onCloseAction}
            open={true}
            message={actionConfirmationDetails.message}
            messageContent={
              activeActionType === ActionTypes.Reset ? (
                <Box mt="24px">
                  <DateInput
                    dateOnly={true}
                    disableWeekends={true}
                    label="Due date"
                    onChange={(val) => setAuditResetDueDate(val)}
                    value={auditResetDueDate}
                  />
                  <DialogCheckboxContainerStyled>
                    <CheckboxInput
                      checked={auditResetNotify}
                      label="Notify facility users that this audit was reset"
                      name="auditResetNotify"
                      onChange={(_, val) => setAuditResetNotify(val)}
                    />
                  </DialogCheckboxContainerStyled>
                </Box>
              ) : activeActionType === ActionTypes.SendReminder ? (
                <Box mt="24px">
                  <TextField
                    fullWidth
                    label="Email message"
                    multiline={true}
                    onChange={(e) => setAuditReminderMessage(e.target.value)}
                    rows={4}
                    value={auditReminderMessage}
                    InputProps={{
                      startAdornment: (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          mb={1}
                        >
                          Your audit was{" "}
                          {audit.sentOn
                            ? `assigned on ${formatDate(audit.sentOn)} `
                            : ""}
                          with a due date of {formatDate(audit.dueDate)}. As of
                          today, your {audit.name} has still not been submitted.
                          Please click on this link to complete your audit now.
                        </Typography>
                      ),
                      sx: {
                        display: "block",
                        fontSize: "14px",
                        textAlign: "left",
                      },
                    }}
                  />
                </Box>
              ) : undefined
            }
            title={actionConfirmationDetails.title}
            type={actionConfirmationDetails.type}
          />
        )}
      </Box>
    );
  },
);
