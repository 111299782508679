import React from "react";
import { TabbedView } from "../../components";
import { AuditsPageTabs } from "../../lib/constants";
import {
  AuditsDashboardList,
  AuditsInboxList,
  AuditTemplatesList,
} from "./components";

const tabs = [
  {
    id: AuditsPageTabs.Inbox,
    name: "Audit Inbox",
    component: AuditsInboxList,
  },
  {
    id: AuditsPageTabs.Manage,
    name: "Manage",
    component: AuditsDashboardList,
  },
  {
    id: AuditsPageTabs.Templates,
    name: "Templates",
    component: AuditTemplatesList,
  },
];

export const AuditsPage = React.memo(
  /**
   *
   */
  function AuditsPage() {
    return <TabbedView tabs={tabs} />;
  },
);
