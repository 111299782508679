import React from "react";
import { EntityTypes } from "../../lib/constants";
import { GroupFacilityRegionWizard } from "./components";

export const GroupFormPage = React.memo(
  /**
   *
   */
  function GroupFormPage() {
    return <GroupFacilityRegionWizard type={EntityTypes.Group} />;
  },
);
