import { Avatar, Box, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardStyled = styled(Card)(() => ({
  padding: "24px",
  height: "96px",
  width: "260px",
  display: "flex",
  cursor: "pointer",
}));

export const AvatarStyled = styled(Avatar)(() => ({
  marginRight: "24px",
  height: "48px",
  width: "48px",
}));

export const TextContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
