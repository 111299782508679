import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuditCapStatuses } from "../../../../lib/constants";

interface AuditCapStatusCellStyledProps {
  status?: string;
}

export const AuditCapStatusCellStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<AuditCapStatusCellStyledProps>(({ theme, status }) => ({
  height: "100%",
  width: "100%",
  "& a": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  "& p": {
    color:
      status === AuditCapStatuses.NotStarted
        ? theme.palette.error.dark
        : status === AuditCapStatuses.InProgress
        ? theme.palette.warning.dark
        : status === AuditCapStatuses.Completed
        ? theme.palette.success.dark
        : theme.palette.text.primary,
  },
}));

export const AuditDueDateCellStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    height: "18px",
    width: "18px",
    color: theme.palette.text.secondary,
  },
  "& div .MuiButtonBase-root": {
    height: "22px",
    width: "22px",
  },
  "& .MuiInputBase-root": {
    height: "48px",
    width: "150px",
  },
  "& .MuiInputAdornment-root": {
    overflow: "visible",
  },
}));
