import React, { useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "@mui/material";
import { facilityAdminActions, useAppDispatch } from "../../../../state";
import {
  FileUploadTypes,
  Form,
  useFileUpload,
  useFormik,
} from "../../../../lib";
import { FileUploadField } from "../../../../components";
import * as yup from "yup";

const validationSchema = yup.object({
  file: yup.mixed().nullable().required("File is required"),
});

interface TrainingAttendanceUploadDialogProps {
  facilityId: number;
  trainingId: number;
  handleClose: () => void;
}

export const TrainingAttendanceUploadDialog = React.memo(
  /**
   *
   */
  function TrainingAttendanceUploadDialog({
    facilityId,
    handleClose,
    trainingId,
  }: TrainingAttendanceUploadDialogProps) {
    const dispatch = useAppDispatch();
    const uploadFile = useFileUpload();

    const onSubmit = useCallback(
      async (values) => {
        const url = await uploadFile(
          values.file,
          FileUploadTypes.TrainingAttendance,
          true,
        );

        if (!url) {
          return;
        }

        const attendanceDetails = {
          facilityId,
          name: values.file.name,
          url,
        };
        const savedAttendance = await dispatch(
          facilityAdminActions.submitTrainingAttendance(
            trainingId,
            attendanceDetails,
          ),
        );
        if (savedAttendance) {
          handleClose();
        }
      },
      [dispatch, facilityId, handleClose, trainingId, uploadFile],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        file: null,
      },
      validationSchema,
      onSubmit,
    });

    return (
      <Dialog open={true} PaperProps={{ sx: { width: "600px" } }}>
        <DialogTitle variant="h6">Upload Attendee List</DialogTitle>
        <Form form={form} noPrompt={true}>
          <DialogContent>
            <FileUploadField name="file" noPreview={true} showFileName={true} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={handleClose}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={form.isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
