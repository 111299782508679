import { AppThunk } from "..";
import { authClient, uiActions } from "../states";
import { sys } from "./state";

const { actions } = sys;

export const sysActions = {
  ...actions,
  getSystemSettings(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/system`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve system settings"));
      } else {
        dispatch(actions.setSystemSettings(data));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  // roles, positions
  getAllPositions(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/users/positions/basic`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve positions"));
      } else {
        dispatch(actions.setAllPositions(data));
      }
    };
  },
  getAllRoles(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/users/roles/basic`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve access levels"));
      } else {
        dispatch(actions.setAllRoles(data));
      }
    };
  },
  // groups, regions, facilities
  getAllGroups(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/groups/basic`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve groups"));
      } else {
        dispatch(actions.setAllGroups(data));
      }
    };
  },
  getAllRegions(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/regions/basic`);

      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve regions"));
      } else {
        dispatch(actions.setAllRegions(data));
      }
    };
  },
  getAllFacilities(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/facilities/basic`);

      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve facilities"));
      } else {
        dispatch(actions.setAllFacilities(data));
      }
    };
  },
  // policies, resources, facility files, audits
  getAllPolicies(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/policies/basic`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policies"));
      } else {
        dispatch(actions.setAllPolicies(data));
      }
    };
  },
  getPolicyCategories(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/policies/categories`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy categories"));
      } else {
        dispatch(actions.setPolicyCategories(data));
      }
    };
  },
  getResourceCategories(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/resources/categories`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve resource categories"));
      } else {
        dispatch(actions.setResourceCategories(data));
      }
    };
  },
  getFacilityFileCategories(groupId: number): AppThunk {
    return async (dispatch, getState) => {
      const { data, status } = await authClient.get(
        `/facilityFiles/categories`,
        { params: { groupId } },
      );
      if (status !== 200) {
        dispatch(
          uiActions.showError("Failed to retrieve facility file categories"),
        );
      } else {
        // facility file categories are retrieved by individual group when there are no categories loaded for the group
        // combine existing categories with categories retrieved for the specified group
        const savedCategories = getState().sys.facilityFileCategories;
        const combinedCategories = [
          ...(savedCategories || []).filter((c) => c.groupID !== groupId),
          ...(data || []),
        ];
        dispatch(actions.setFacilityFileCategories(combinedCategories));
      }
    };
  },
  getAllAuditTemplates(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/audits/templates/basic`);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit templates"));
      } else {
        dispatch(actions.setAllAuditTemplates(data));
      }
    };
  },
  // portal filters
  clearFacilityFilter() {
    return actions.setFacilityFilter(undefined);
  },
  clearGroupFilter() {
    return actions.setGroupFilter(undefined);
  },
};
