import React from "react";
import { useParams } from "react-router-dom";
import { PoliciesMenu, PolicyPreview, StatusPage } from "../shared/components";
import { PolicySidebar } from "./components";
import { PageRootStyled } from "../../components";
import { useLocation, useSetFacilityFilter } from "../../lib";
import facility from "../../assets/img/facility.svg";

export const PolicyPage = React.memo(
  /**
   *
   */
  function PolicyPage() {
    const params = useParams();
    const location = useLocation();
    const queryFacilityId = location.query.facility;

    // if there is a facility id in the url query (from a shared policy link), apply it as the system facility filter so that the user can view the policy for the correct facility
    const facilityFilter = useSetFacilityFilter(queryFacilityId);

    return queryFacilityId ? null : !facilityFilter?.id ? ( // don't show anything when there is a query facility id that has not yet been applied; show status page when there is no system facility filter
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="Select a facility from the blue bar at the top of the screen to view this policy"
      />
    ) : (
      <PageRootStyled sx={{ flexDirection: "row" }}>
        <PoliciesMenu policyId={Number(params.id)} />
        <PolicyPreview
          facilityId={facilityFilter.id}
          facilityGroupId={facilityFilter.groupID}
          policyId={Number(params.id)}
        />
        <PolicySidebar
          facilityId={facilityFilter.id}
          policyId={Number(params.id)}
        />
      </PageRootStyled>
    );
  },
);
