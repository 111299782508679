import React from "react";
import { UsersList } from "../adminShared/components";

export const UsersPage = React.memo(
  /**
   *
   */
  function UsersPage() {
    return <UsersList />;
  },
);
