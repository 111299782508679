import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import dompurify from "dompurify";

interface HtmlSanitizedTextProps extends TypographyProps {
  sanitizationConfig?: any;
  styledTypography?: StyledComponent<TypographyProps>;
  text?: string;
}

export const HtmlSanitizedText = React.memo(
  /**
   *
   */
  function HtmlSanitizedText({
    sanitizationConfig = {
      ADD_ATTR: ["target"], // Default config: allow target="_blank" on links
    },
    styledTypography,
    text,
    ...passProps
  }: HtmlSanitizedTextProps) {
    const TypographyElement = styledTypography || Typography;
    return text ? (
      <TypographyElement
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(text, {
            IN_PLACE: true, // In place mode for faster sanitization
            ...sanitizationConfig,
          }),
        }}
        variant="body2"
        {...passProps}
      />
    ) : null;
  },
);
