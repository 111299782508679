import { styled } from "@mui/material/styles";

export const ErrorDisplayStyled = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));
