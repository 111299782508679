import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  adminActions,
  Role,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik } from "formik";
import {
  GridItemStyled,
  GridSectionHeaderStyled,
  GridSectionStyled,
  RootStyled,
} from "./PositionRoleForm.styles";
import {
  ArrowDropDownIcon,
  ArrowRightIcon,
  CheckboxField,
  CloseIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  TextField,
} from "../../../../components";
import {
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useLocation,
  useRedirect,
} from "../../../../lib";
import { ClaimCategories, PermissionClaims } from "../../../../lib/constants";
import * as yup from "yup";

const newRole: Role = {
  name: "",
  claimIDs: [],
};

const validationSchema = yup.object({
  name: yup
    .string()
    .max(250, "Access level name cannot exceed 250 characters")
    .required("Access level name is required"),
  claimIDs: yup.array(),
});

export const RoleForm = React.memo(
  /**
   *
   */
  function RoleForm() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isNew = location.query.roleId === "new";
    const roleId = isNew ? "" : location.query.roleId;

    const [initialValues, setInitialValues] = useState<Role>(newRole);

    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);

    const categorizedClaims = useSelector(
      sysSelectors.categorizedPermissionClaims,
    );
    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

    const { redirectUrl, setRedirectUrl } = useRedirect();

    const getNavigationUrl = useCallback(
      (id?: number) => {
        return Navigation.url(location.pathname, {
          query: { ...location.query, roleId: id },
        });
      },
      [location.pathname, location.query],
    );

    useEffect(() => {
      (async () => {
        if (roleId) {
          // if roleId has been updated with a redirect url, reset the url
          if (redirectUrl) {
            setRedirectUrl("");
          }
          // otherwise fetch the resource
          else {
            const role = await dispatch(adminActions.getRole(Number(roleId)));
            if (role) {
              setInitialValues(replaceNullProps(role));
            }
          }
        } else {
          // if roleId has been cleared, reset form values
          setInitialValues(newRole);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, roleId]);

    const onSubmit = useCallback(
      async (values, _, confirmed = false) => {
        if (!isNew && !confirmed) {
          setShowUpdateConfirmation(true);
          return;
        }

        const savedRole = await dispatch(
          adminActions.submitRole(replaceEmptyProps(values)),
        );
        if (savedRole) {
          setInitialValues(replaceNullProps(savedRole));

          // reset route upon new role creation
          if (isNew) {
            setRedirectUrl(getNavigationUrl(savedRole.id));
          }
        }
      },
      [dispatch, getNavigationUrl, isNew, setRedirectUrl],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    const onConfirm = useCallback(() => {
      setShowUpdateConfirmation(false);
      onSubmit(form.values, form, true);
    }, [form, onSubmit]);

    const onClose = useCallback(() => {
      Navigation.replace(getNavigationUrl());
    }, [getNavigationUrl]);

    const toggleExpandedCategories = useCallback(
      (category = "") => {
        if (!category) {
          setExpandedCategories(
            expandedCategories.length === Object.keys(categorizedClaims).length
              ? []
              : Object.keys(categorizedClaims),
          );
        } else {
          setExpandedCategories(
            expandedCategories.includes(category)
              ? expandedCategories.filter((c) => c !== category)
              : [...expandedCategories, category],
          );
        }
      },
      [categorizedClaims, expandedCategories],
    );

    const toggleClaim = useCallback(
      (id) => {
        const { claimIDs } = form.values;
        form.setFieldValue(
          "claimIDs",
          claimIDs.includes(id)
            ? claimIDs.filter((c) => c !== id)
            : [...claimIDs, id],
        );
      },
      [form],
    );

    return (
      <RootStyled>
        <Form
          form={form}
          noPrompt={!!redirectUrl}
          promptCancelText="Back to Access level"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Configure access level
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            <GridItemStyled item sx={{ width: "100%" }}>
              <TextField name="name" label="Access level" />
            </GridItemStyled>
          </Grid>
          <GridSectionStyled>
            <GridSectionHeaderStyled>
              <Typography variant="subtitle1">Permissions</Typography>
              <Button variant="text" onClick={() => toggleExpandedCategories()}>
                {expandedCategories.length ===
                Object.keys(categorizedClaims).length
                  ? "Collapse"
                  : "Expand"}{" "}
                all
              </Button>
            </GridSectionHeaderStyled>
          </GridSectionStyled>
          {Object.keys(categorizedClaims)
            .filter((category) => category !== ClaimCategories.Facility) // don't allow facility claims to be assigned to a role
            .map((category) => {
              const isExpanded = expandedCategories.includes(category);
              const selectedClaimsCount = categorizedClaims[category].filter(
                (c) => form.values.claimIDs.includes(c.id),
              ).length;
              return (
                <GridSectionStyled key={category}>
                  <GridSectionHeaderStyled
                    onClick={() => toggleExpandedCategories(category)}
                  >
                    <Typography variant="subtitle2">{category}</Typography>
                    <Box>
                      <Typography variant="caption">
                        {!selectedClaimsCount
                          ? "None"
                          : selectedClaimsCount ===
                            categorizedClaims[category].length
                          ? "All"
                          : "Restricted"}
                      </Typography>
                      <IconButton sx={{ ml: 1 }}>
                        {isExpanded ? (
                          <ArrowRightIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    </Box>
                  </GridSectionHeaderStyled>
                  {isExpanded &&
                    categorizedClaims[category].map((claim) => (
                      <GridItemStyled key={claim.id}>
                        <Tooltip
                          title={
                            claim.claimValue ===
                            PermissionClaims.PoliciesPrintingOverrideClaim
                              ? "Assigning this permission will override group printing restrictions"
                              : ""
                          }
                        >
                          <CheckboxField
                            label={claim.displayName}
                            name="claimIDs"
                            checked={form.values.claimIDs.includes(claim.id)}
                            onChange={() => toggleClaim(claim.id)}
                          />
                        </Tooltip>
                      </GridItemStyled>
                    ))}
                </GridSectionStyled>
              );
            })}
          <Grid
            container
            sx={{ display: "flex", justifyContent: "end", mt: 4 }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </Grid>
          {showUpdateConfirmation && (
            <ConfirmationDialog
              confirmText="Update access level"
              handleClose={() => setShowUpdateConfirmation(false)}
              handleConfirm={onConfirm}
              message="These changes will be applied to all users with this access level."
              open={true}
              title="Update access level?"
              type={ConfirmationDialogTypes.Alert}
            />
          )}
        </Form>
      </RootStyled>
    );
  },
);
