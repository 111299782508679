import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { useLocation } from "../../lib";
import { authActions, useAction } from "../../state";
import {
  AuthCardStyled,
  ButtonBoxStyled,
  SubtitleTextStyled,
  TitleTextStyled,
} from "./Auth.styles";

export const PasswordRequestSentPage = React.memo(
  /**
   *
   */
  function PasswordRequestSentPage() {
    const {
      query: { email, forgot },
    } = useLocation();

    const requestPasswordLink = useAction(authActions.requestPasswordLink);
    const resendEmail = useCallback(() => {
      requestPasswordLink({ email });
    }, [email, requestPasswordLink]);

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">Password Request Sent</TitleTextStyled>
        <SubtitleTextStyled variant="body2">
          We’ve sent you an email with a link and instructions to{" "}
          {forgot ? "reset" : "set"} your password.
          <br />
          <br />
          Didn’t receive the email? Check your junk mail or{" "}
          {email
            ? "have another one sent to you by clicking the button below"
            : "return to the previous page to send a new one"}
          .
        </SubtitleTextStyled>
        {!!email && (
          <ButtonBoxStyled>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={resendEmail}
            >
              Resend email
            </Button>
          </ButtonBoxStyled>
        )}
      </AuthCardStyled>
    );
  },
);
