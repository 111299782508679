import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface AnnouncementCardStyledProps {
  read: boolean;
}

export const AnnouncementCardStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "read",
})<AnnouncementCardStyledProps>(({ read }) => ({
  padding: "8px",
  marginBottom: "16px",
  borderRadius: "3px",
  ...(!read ? { backgroundColor: "#E7F2FB" } : {}),
}));

export const AnnouncementHeaderStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "4px",
  "& > span:first-of-type": {
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
  },
  "& .MuiChip-root": {
    marginLeft: "4px",
    height: "14px",
    fontSize: "8px",
    textTransform: "none",
  },
  "& button": { fontSize: "10px", padding: "2px 6px", textTransform: "none" },
}));

export const AnnouncementSubjectTextStyled = styled(Typography)(() => ({
  marginBottom: "6px",
  whiteSpace: "pre-wrap",
}));

export const AnnouncementBodyTextStyled = styled(Typography)(({ theme }) => ({
  marginBottom: "6px",
  whiteSpace: "pre-wrap",
  "& a": {
    color: theme.palette.primary.light,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      opacity: ".8",
    },
  },
}));
