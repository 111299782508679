import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DashboardContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  "& > div:first-of-type": {
    width: "100%",
    paddingRight: "24px",
    borderRight: `1px solid ${theme.palette.divider}`,
    marginBottom: "16px",
  },
  "& > div:last-of-type": {
    width: "452px",
    minWidth: "452px",
    padding: "0 16px",
    paddingBottom: "16px",
  },
}));
