import writeXlsxFile, { SheetData } from "write-excel-file";
import { formatDate } from "../../../../lib";
import { FacilityStatuses } from "../../../../lib/constants";
import { BasicResource, RequiredDocumentExportRecord } from "../../../../state";
import { ExportCell } from "../../../../lib/types";

// export cell styles
const borderedCellStyles = {
  borderColor: "#040404",
};

const titleCellStyles = {
  ...borderedCellStyles,
  fontWeight: "bold",
};

const leftHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  rightBorderColor: "#FFFFF",
  leftBorderStyle: "thick",
  bottomBorderStyle: "thick",
};

const centerHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  rightBorderColor: "#FFFFF",
  leftBorderColor: "#FFFFF",
  bottomBorderStyle: "thick",
};

const rightHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  leftBorderColor: "#FFFFF",
  rightBorderStyle: "thick",
  bottomBorderStyle: "thick",
};

const leftDocumentDataCellStyles = {
  ...borderedCellStyles,
  leftBorderStyle: "thick",
};

const centerDocumentDataCellStyles = {
  ...borderedCellStyles,
};

const rightDocumentDataCellStyles = {
  ...borderedCellStyles,
  rightBorderStyle: "thick",
};

const noDocumentCellStyles = {
  backgroundColor: "#A6A6A6",
};

const reviewedDocumentCellStyles = {
  backgroundColor: "#FFC0CB",
};

export async function exportRequiredDocumentsData(
  requiredDocumentResources: BasicResource[],
  records: RequiredDocumentExportRecord[],
) {
  const masterHeaderRow = [
    {
      value: "Facility Name",
      ...centerHeaderCellStyles,
    },
    {
      value: "Group Name",
      ...rightHeaderCellStyles,
    },
  ] as ExportCell[];

  const subHeaderRow = [{}, {}] as ExportCell[]; // default blank cell for facility and group name columns

  const dataRows = [] as ExportCell[];

  requiredDocumentResources.forEach(({ name }) => {
    masterHeaderRow.push(
      { ...leftHeaderCellStyles },
      { value: name, ...centerHeaderCellStyles },
      { ...rightHeaderCellStyles },
    );

    subHeaderRow.push(
      {
        value: "Quarter",
        ...titleCellStyles,
        ...leftDocumentDataCellStyles,
      },
      {
        value: "Date Returned",
        ...titleCellStyles,
        ...centerDocumentDataCellStyles,
      },
      {
        value: "GB Designee Confirmed",
        ...titleCellStyles,
        ...rightDocumentDataCellStyles,
      },
    );
  });

  records.forEach((record: RequiredDocumentExportRecord) => {
    const recordRow = [] as ExportCell[];
    recordRow.push(
      {
        value: `${record.facilityName}${
          record.facilityStatus == FacilityStatuses.Inactive
            ? " [Inactive]"
            : ""
        }`,
        ...titleCellStyles,
      },
      {
        value: record.groupName,
        ...titleCellStyles,
      },
    );

    requiredDocumentResources.forEach(
      ({ id: resourceId, hasReviewWorkflow }) => {
        const document = record.documents.find(
          (d) => d.resourceID === resourceId,
        );
        recordRow.push(
          {
            value: document ? `Q${document.quarter} ${document.year}` : "",
            ...leftDocumentDataCellStyles,
            ...(!document ? noDocumentCellStyles : ""),
          },
          {
            value: formatDate(document?.createdOn),
            ...centerDocumentDataCellStyles,
            ...(!document ? noDocumentCellStyles : ""),
          },
          {
            value: document
              ? hasReviewWorkflow
                ? document?.reviewedOn
                  ? "Confirmed"
                  : "Pending"
                : "N/A"
              : "",
            ...rightDocumentDataCellStyles,
            ...(!document
              ? noDocumentCellStyles
              : hasReviewWorkflow && document.reviewedOn
              ? reviewedDocumentCellStyles
              : ""),
          },
        );
      },
    );

    dataRows.push(recordRow);
  });

  const exportData = [masterHeaderRow, subHeaderRow, ...dataRows];

  // column styles: assign first two columns width of 50 chars, rest 20
  const columns = subHeaderRow.map((_, i) => ({ width: i > 1 ? 20 : 50 }));

  await writeXlsxFile(exportData as SheetData, {
    columns,
    fileName: `Required_Documents_Report_${formatDate(new Date())}.xlsx`,
  });
}
