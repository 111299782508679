import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  GiftCardIcon,
  TextButtonStyled,
} from "../../../../../components";
import { AuditGiftCardStatuses } from "../../../../../lib/constants";
import {
  adminActions,
  Audit,
  AuditInboxRecord,
  useAppDispatch,
} from "../../../../../state";
import { formatDate, Navigation } from "../../../../../lib";
import { AdminPages } from "../../..";
import { styled } from "@mui/material/styles";

export const ActionButtonStyled = styled(TextButtonStyled)(() => ({
  textTransform: "none",
  fontWeight: "bold",
}));

const DialogTypes = {
  Send: "Send",
  Unsend: "Unsend",
  EditTrackingNumber: "EditTrackingNumber",
};

interface AuditGiftCardActionsProps {
  audit: Audit | AuditInboxRecord;
  inboxView?: boolean;
}

export function AuditGiftCardActions({
  audit,
  inboxView,
}: AuditGiftCardActionsProps) {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { color, description } = useMemo(() => {
    let color = "warning.main";
    let description = "Pending gift card decision";

    switch (audit.giftCardStatus) {
      case AuditGiftCardStatuses.NotNeeded:
        description = "Gift card not needed";
        color = "neutral.dark";
        break;
      case AuditGiftCardStatuses.Needed:
        description = "Gift card needed";
        color = "error.dark";
        break;
      case AuditGiftCardStatuses.Sent:
        description = "Gift card sent";
        color = "success.dark";
        break;
    }

    return { color, description };
  }, [audit.giftCardStatus]);

  const [dialogType, setDialogType] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");

  const closeDialog = useCallback(() => {
    setDialogType("");
    setTrackingNumber("");
  }, []);

  const submitGiftCardStatus = useCallback(
    async (status: string) => {
      const updatedAudit = await dispatch(
        adminActions.submitAuditGiftCardStatus(
          audit.id,
          {
            status,
            trackingNumber:
              status === AuditGiftCardStatuses.Sent
                ? trackingNumber
                : undefined,
          },
          inboxView,
        ),
      );
      if (updatedAudit) {
        handleClosePopover();

        if (inboxView) {
          closeDialog();
        } else {
          // return to the prev route after saving
          Navigation.goBack(AdminPages.audits.path);
        }
      }
    },
    [
      audit.id,
      closeDialog,
      dispatch,
      handleClosePopover,
      inboxView,
      trackingNumber,
    ],
  );

  return (
    <>
      <Tooltip title={description}>
        <IconButton
          onClick={handleButtonClick}
          {...(!inboxView && {
            size: "small",
            sx: {
              borderRadius: "3px",
              border: "1px solid",
              borderColor: color,
            },
          })}
        >
          <GiftCardIcon sx={{ color }} />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box padding="16px">
          {audit.giftCardStatus === AuditGiftCardStatuses.NotNeeded ? (
            <>
              <Typography variant="body2" mb="8px">
                Gift card marked not needed
              </Typography>
              <ActionButtonStyled
                color="primary"
                onClick={() =>
                  submitGiftCardStatus(AuditGiftCardStatuses.Needed)
                }
                sx={{ mr: 2 }}
                variant="text"
              >
                Mark needed
              </ActionButtonStyled>
              <ActionButtonStyled
                color="primary"
                onClick={() => setDialogType(DialogTypes.Send)}
                variant="text"
              >
                Mark sent
              </ActionButtonStyled>
            </>
          ) : audit.giftCardStatus === AuditGiftCardStatuses.Needed ? (
            <>
              <Typography variant="body2" mb="8px">
                Awaiting gift card
              </Typography>
              <ActionButtonStyled
                color="primary"
                onClick={() => setDialogType(DialogTypes.Send)}
                sx={{ mr: 2 }}
                variant="text"
              >
                Mark sent
              </ActionButtonStyled>
              <ActionButtonStyled
                color="error"
                onClick={() =>
                  submitGiftCardStatus(AuditGiftCardStatuses.NotNeeded)
                }
                variant="text"
              >
                Mark not needed
              </ActionButtonStyled>
            </>
          ) : audit.giftCardStatus === AuditGiftCardStatuses.Sent ? (
            <>
              <Typography variant="body2" mb="8px">
                Sent on {formatDate(audit.giftCardSentOn)} by{" "}
                {audit.giftCardSentBy}
              </Typography>
              <Typography variant="body2" mb="8px">
                Tracking #: {audit.giftCardTrackingNumber}
              </Typography>
              <ActionButtonStyled
                color="error"
                onClick={() => setDialogType(DialogTypes.Unsend)}
                sx={{ mr: 2 }}
                variant="text"
              >
                Mark unsent
              </ActionButtonStyled>
              <ActionButtonStyled
                color="primary"
                onClick={() => {
                  setTrackingNumber(audit.giftCardTrackingNumber || "");
                  setDialogType(DialogTypes.EditTrackingNumber);
                }}
                variant="text"
              >
                Edit tracking number
              </ActionButtonStyled>
            </>
          ) : (
            <>
              <Typography variant="body2" mb="8px">
                Gift card needed?
              </Typography>
              <ActionButtonStyled
                color="primary"
                onClick={() =>
                  submitGiftCardStatus(AuditGiftCardStatuses.Needed)
                }
                sx={{ mr: 2 }}
                variant="text"
              >
                Confirm
              </ActionButtonStyled>
              <ActionButtonStyled
                color="error"
                onClick={() =>
                  submitGiftCardStatus(AuditGiftCardStatuses.NotNeeded)
                }
                variant="text"
              >
                Dismiss
              </ActionButtonStyled>
            </>
          )}
        </Box>
      </Popover>
      {dialogType == DialogTypes.Send ? (
        <ConfirmationDialog
          confirmDisabled={!trackingNumber}
          confirmText="Mark as sent"
          handleClose={closeDialog}
          handleConfirm={() => submitGiftCardStatus(AuditGiftCardStatuses.Sent)}
          open={true}
          message={`Please confirm that you'd like to mark this gift card as sent. Note: An email with the tracking number will be sent to ${audit.submittedBy} and all users with audit notification permissions in ${audit.facilityName}.`}
          messageContent={
            <TextField
              fullWidth
              label="Tracking number"
              onChange={(e) => setTrackingNumber(e.target.value)}
              value={trackingNumber}
              sx={{ mt: 2 }}
            />
          }
          title="Mark gift card sent?"
          type={ConfirmationDialogTypes.Warning}
        />
      ) : dialogType == DialogTypes.Unsend ? (
        <ConfirmationDialog
          confirmText="Mark unsent"
          handleClose={closeDialog}
          handleConfirm={() =>
            submitGiftCardStatus(AuditGiftCardStatuses.Needed)
          }
          open={true}
          message="This will permanently delete the tracking number."
          title="Mark gift card unsent?"
          type={ConfirmationDialogTypes.Alert}
        />
      ) : dialogType == DialogTypes.EditTrackingNumber ? (
        <ConfirmationDialog
          confirmDisabled={!trackingNumber}
          confirmText="Update"
          handleClose={closeDialog}
          handleConfirm={() => submitGiftCardStatus(audit.giftCardStatus)}
          open={true}
          message="Note: Users will not be notified regarding this change."
          messageContent={
            <TextField
              fullWidth
              label="Tracking number"
              onChange={(e) => setTrackingNumber(e.target.value)}
              value={trackingNumber}
              sx={{ mt: 2 }}
            />
          }
          title="Update tracking number"
          type={ConfirmationDialogTypes.Warning}
        />
      ) : (
        ""
      )}
    </>
  );
}
