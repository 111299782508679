import { Avatar, Box, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardContainerStyled = styled(Box)(() => ({
  height: "fit-content",
  marginBottom: "24px",
}));

export const CardStyled = styled(Card)(({ theme }) => ({
  padding: "24px",
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "900px",
  "& svg": {
    height: "16px",
  },
  "& a": {
    color: theme.palette.text.primary,
  },
}));

export const LogoStyled = styled(`img`)(({ theme }) => ({
  height: "34px",
  width: "34px",
  objectFit: "contain",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "50%",
  padding: "1px",
}));

export const SubLogoStyled = styled(`img`)(({ theme }) => ({
  height: "14px",
  width: "14px",
  objectFit: "contain",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "1px",
  backgroundColor: theme.palette.background.default,
}));

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "32px",
  width: "32px",
  backgroundColor: theme.palette.primary.light,
  fontSize: "16px",
}));

export const SubtitleStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  color: theme.palette.text.secondary,
  whiteSpace: "pre-wrap",
  "& a, span": {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
