import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuditActivityRecord,
  AuditDashboardRecord,
  AuditInboxRecord,
  AuditTemplateRecord,
  AuditRecord,
  FacilityActivityRecord,
  FacilityFileRecord,
  FacilityNoteRecord,
  FacilityRecord,
  GroupRecord,
  PolicyDocumentPublishLogRecord,
  PolicyRecord,
  PolicyVersionRecord,
  PositionRecord,
  RegionRecord,
  RequiredDocumentRecord,
  ResourceRecord,
  RoleRecord,
  SearchResultRecord,
  TrainingRecord,
  TrainingAttendanceRecord,
  TrainingPacketRecord,
  UserRecord,
} from "../types";

export interface ListParams {
  page?: number;
  resultsPerPage?: number;
  order?: string;
  orderBy?: string;
  selectedIds?: Array<number>;
  [x: string | number | symbol]: any; // include any unspecified filter properties
}

export interface ListData<T> {
  numberOfRows: number;
  results: Array<T>;
}

export interface ListState<T> {
  type: string;
  data: ListData<T>;
  params: ListParams;
  url: string;
}

function initialListState<T>(type: string) {
  const initialListState: ListState<T> = {
    type,
    data: { numberOfRows: 0, results: [] },
    params: {
      page: 0,
      resultsPerPage: undefined,
      order: undefined,
      orderBy: undefined,
      filter: {},
      selectedIds: [],
    },
    url: "",
  };
  return initialListState;
}

export const ListTypes = {
  // include all list types here
  announcements: "announcements",
  audits: "audits",
  auditActivity: "auditActivity",
  auditDashboard: "auditDashboard",
  auditInbox: "auditInbox",
  auditTemplates: "auditTemplates",
  facilities: "facilities",
  facilityActivity: "facilityActivity",
  facilityFiles: "facilityFiles",
  facilityNotes: "facilityNotes",
  groups: "groups",
  policies: "policies",
  policyPublishLogs: "policyPublishLogs",
  policyVersions: "policyVersions",
  regions: "regions",
  relatedFacilities: "relatedFacilities",
  relatedRegions: "relatedRegions",
  relatedUsers: "relatedUsers",
  requiredDocumentResources: "requiredDocumentResources",
  requiredDocumentSubmissions: "requiredDocumentSubmissions",
  resources: "resources",
  search: "search",
  toolResources: "toolResources",
  trainings: "trainings",
  trainingPackets: "trainingPackets",
  trainingAttendance: "trainingAttendance",
  users: "users",
  userPositions: "userPositions",
  userRoles: "userRoles",
};

export interface ListsState {
  // register all lists here
  announcements: ListState<AuditRecord>;
  audits: ListState<AuditRecord>;
  auditActivity: ListState<AuditActivityRecord>;
  auditDashboard: ListState<AuditDashboardRecord>;
  auditInbox: ListState<AuditInboxRecord>;
  auditTemplates: ListState<AuditTemplateRecord>;
  facilities: ListState<FacilityRecord>;
  facilityActivity: ListState<FacilityActivityRecord>;
  facilityFiles: ListState<FacilityFileRecord>;
  facilityNotes: ListState<FacilityNoteRecord>;
  groups: ListState<GroupRecord>;
  policies: ListState<PolicyRecord>;
  policyPublishLogs: ListState<PolicyDocumentPublishLogRecord>;
  policyVersions: ListState<PolicyVersionRecord>;
  regions: ListState<RegionRecord>;
  relatedFacilities: ListState<FacilityRecord>;
  relatedRegions: ListState<GroupRecord>;
  relatedUsers: ListState<UserRecord>;
  requiredDocumentResources: ListState<ResourceRecord>;
  requiredDocumentSubmissions: ListState<RequiredDocumentRecord>;
  resources: ListState<ResourceRecord>;
  search: ListState<SearchResultRecord>;
  toolResources: ListState<ResourceRecord>;
  trainings: ListState<TrainingRecord>;
  trainingPackets: ListState<TrainingPacketRecord>;
  trainingAttendance: ListState<TrainingAttendanceRecord>;
  users: ListState<UserRecord>;
  userPositions: ListState<PositionRecord>;
  userRoles: ListState<RoleRecord>;
}

export const initialState: ListsState = {
  // initialize all list types here
  announcements: initialListState(ListTypes.announcements),
  audits: initialListState(ListTypes.audits),
  auditActivity: initialListState(ListTypes.auditActivity),
  auditDashboard: initialListState(ListTypes.auditDashboard),
  auditInbox: initialListState(ListTypes.auditInbox),
  auditTemplates: initialListState(ListTypes.auditTemplates),
  users: initialListState(ListTypes.users),
  facilities: initialListState(ListTypes.facilities),
  facilityActivity: initialListState(ListTypes.facilityActivity),
  facilityFiles: initialListState(ListTypes.facilityFiles),
  facilityNotes: initialListState(ListTypes.facilityNotes),
  groups: initialListState(ListTypes.groups),
  policies: initialListState(ListTypes.policies),
  policyPublishLogs: initialListState(ListTypes.policyPublishLogs),
  policyVersions: initialListState(ListTypes.policyVersions),
  regions: initialListState(ListTypes.regions),
  relatedFacilities: initialListState(ListTypes.relatedFacilities),
  relatedRegions: initialListState(ListTypes.relatedRegions),
  relatedUsers: initialListState(ListTypes.relatedUsers),
  requiredDocumentResources: initialListState(
    ListTypes.requiredDocumentResources,
  ),
  requiredDocumentSubmissions: initialListState(
    ListTypes.requiredDocumentSubmissions,
  ),
  resources: initialListState(ListTypes.resources),
  search: initialListState(ListTypes.search),
  toolResources: initialListState(ListTypes.toolResources),
  trainings: initialListState(ListTypes.trainings),
  trainingPackets: initialListState(ListTypes.trainingPackets),
  trainingAttendance: initialListState(ListTypes.trainingAttendance),
  userPositions: initialListState(ListTypes.userPositions),
  userRoles: initialListState(ListTypes.userRoles),
};

export const lists = {
  ...createSlice({
    name: "lists",
    initialState,
    reducers: {
      populateList<T>(state, { payload }: PayloadAction<ListState<T>>) {
        state[payload.type] = payload;
      },
    },
  }),
  persist: false,
};
