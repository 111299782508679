import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextButtonStyled = styled(Button)(() => ({
  padding: 0,
  minWidth: "unset",
  "&:hover": {
    opacity: ".8",
    backgroundColor: "transparent",
  },
}));
