import React, { Fragment, useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Link, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  BasicStatusChipStyled,
  CheckboxInput,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  LongTooltip,
  SearchInput,
  SelectInput,
} from "../../../../components";
import { asArray, Navigation, RouterLink, useLocation } from "../../../../lib";
import { FilterListIcon, ListActionsProps } from "../../../../components";
import { ResourceRecord, sysSelectors, useSelector } from "../../../../state";
import { AdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";
import { ManageCategoriesDialog } from "./categories/ManageCategoriesDialog";
import { ResourcesPageTabs, ResourceTypes } from "../../../../lib/constants";
import { CopyLinkButton } from "../../../../components/buttons/CopyLinkButton";
import { RequiredDocumentsExportDialog } from "../../../adminShared/components";
import { FacilityAdminPages } from "../../../facilityAdmin";

const filterQueryKey = {
  categoryIDs: "categories",
  facilityIDs: "facilities",
  includeInactive: "showInactive",
  policyIDs: "policies",
  stateIDs: "states",
  text: "search",
};

interface ResourcesListActionsProps extends ListActionsProps {
  resourceType: string;
}

function ResourcesListActions({
  onFilterChange = () => {},
  query,
  resourceType,
}: ResourcesListActionsProps) {
  const { states: stateOptions = [] } = useSelector(
    sysSelectors.systemSettings,
  );
  const facilityOptions = useSelector(sysSelectors.activeFacilities);
  const resourceCategoryOptions = useSelector(sysSelectors.resourceCategories);
  const policyOptions = useSelector(sysSelectors.allPolicies);

  const {
    categories = [],
    facilities = [],
    policies = [],
    search = "",
    showInactive = false,
    states = [],
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(
      search ||
      asArray(categories).length ||
      asArray(policies).length ||
      asArray(facilities).length ||
      asArray(states).length
    ),
  );

  const [showManageCategoriesDialog, setShowManageCategoriesDialog] =
    useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">
          {resourceType === ResourceTypes.RequiredDocument
            ? "Required Documents"
            : "Tools"}
        </Typography>
        <Box>
          <CheckboxInput
            checked={!!showInactive}
            label="Show inactive"
            name="showInactive"
            onChange={onFilterChange}
          />
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setShowManageCategoriesDialog(true)}
          >
            Manage categories
          </Button>
          <Button
            component={RouterLink}
            to={Navigation.url(AdminPages.addResource.path, {
              query: { type: resourceType },
            })}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
          >
            Add{" "}
            {resourceType === ResourceTypes.RequiredDocument
              ? "required document"
              : "tool"}
          </Button>
          {resourceType === ResourceTypes.RequiredDocument && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setShowExportDialog(true)}
              >
                Export
              </Button>
              {showExportDialog && (
                <RequiredDocumentsExportDialog
                  handleClose={() => setShowExportDialog(false)}
                />
              )}
            </>
          )}
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder={`Search ${
              resourceType === ResourceTypes.RequiredDocument
                ? "documents"
                : "tools"
            }`}
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Category"
            name="categories"
            value={asArray(categories)}
            onChange={onFilterChange}
            options={resourceCategoryOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Related policy"
            name="policies"
            value={asArray(policies)}
            onChange={onFilterChange}
            options={policyOptions.map((o) => ({
              id: o.id,
              name: o.title,
            }))}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="State"
            name="states"
            value={asArray(states)}
            onChange={onFilterChange}
            options={stateOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility"
            name="facilities"
            value={asArray(facilities)}
            onChange={onFilterChange}
            options={facilityOptions}
            groupOpts={true}
            multiple={true}
          />
        </ListActionsExpandedStyled>
      </Collapse>
      {showManageCategoriesDialog && (
        <ManageCategoriesDialog
          handleClose={() => setShowManageCategoriesDialog(false)}
        />
      )}
    </ListActionsContainerStyled>
  );
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
  },
  {
    field: "categories",
    headerName: "Category",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <AbbreviatedListDisplay items={row.categories} />
    ),
    sortable: false,
  },
  {
    field: "policies",
    headerName: "Related Policy",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <LongTooltip title={row.policies.map((p) => p.title).join(", ")}>
        <Box>
          {row.policies.map((p, i) => (
            <Fragment key={p.id}>
              <Link
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(AdminPages.editPolicy, {
                  params: { id: p.id },
                })}
              >
                {p.title.replace(p.name, "").trim()}
              </Link>
              {i < row.policies.length - 1 && (
                <Typography variant="caption" mr={0.5}>
                  ,
                </Typography>
              )}
            </Fragment>
          ))}
        </Box>
      </LongTooltip>
    ),
    sortable: false,
  },
  {
    field: "applicableFor",
    headerName: "Applicable for",
    flex: 1,
    renderCell: ({ row }: { row: ResourceRecord }) =>
      row.facilities.length ? (
        <AbbreviatedListDisplay items={row.facilities} />
      ) : row.states ? (
        row.states.length > 50 ? (
          "All States"
        ) : (
          <AbbreviatedListDisplay items={row.states} />
        )
      ) : (
        ""
      ),
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <BasicStatusChipStyled
        label={row.status}
        size="small"
        status={row.status}
      />
    ),
  },
  {
    field: "actions",
    headerName: "",
    width: 80,
    renderCell: ({ row }: { row: ResourceRecord }) => (
      <CopyLinkButton
        sx={{ color: "primary.main" }}
        tooltip="Copy resource link to share with system users. Note the link will not work for CCG admins."
        url={Navigation.fullUrl(FacilityAdminPages.resource, {
          params: { id: row.id },
        })}
      />
    ),
    sortable: false,
  },
];

export const ResourcesList = React.memo(
  /**
   *
   */
  function ResourcesList() {
    const location = useLocation();
    const resourceType =
      location.query.tab === ResourcesPageTabs.RequiredDocuments
        ? ResourceTypes.RequiredDocument
        : ResourceTypes.Tool;

    const onRowClick = useCallback(({ row }: { row: ResourceRecord }) => {
      Navigation.go(AdminPages.editResource, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<ResourcesListActions resourceType={resourceType} />}
        columns={columns}
        filterQueryKey={filterQueryKey}
        name={
          resourceType === ResourceTypes.RequiredDocument
            ? "required documents"
            : "tools"
        }
        onRowClick={onRowClick}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={
          resourceType === ResourceTypes.RequiredDocument
            ? ListTypes.requiredDocumentResources
            : ListTypes.toolResources
        }
      />
    );
  },
);
