import React, { useMemo } from "react";
import { Option } from "../../../../../lib/types";
import {
  BasicGroup,
  BasicPosition,
  BasicRegion,
  BasicRole,
} from "../../../../../state";
import { WizardStep1 } from "./WizardStep1";
import { WizardStep2 } from "./WizardStep2";
import { FormikValues } from "formik";

export interface WizardStepProps {
  form: FormikValues;
  mode: string;
  step: number;
  type: string;
  //options:
  groupOptions: BasicGroup[];
  positionOptions: BasicPosition[];
  regionOptions: BasicRegion[];
  roleOptions: BasicRole[];
  stateOptions: Option[];
  // helpers
  setInitialValues: (vals: any) => void;
  sendUserEmails: boolean;
  toggleSendUserEmails: () => void;
}

export function WizardStep({ step, ...rest }: WizardStepProps) {
  const StepComponent: any = useMemo(() => {
    switch (step) {
      case 1:
        return WizardStep1;
      case 2:
        return WizardStep2;
    }
    return null;
  }, [step]);

  return StepComponent ? <StepComponent {...rest} /> : null;
}
