import React, { useEffect } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyled,
  LeftPanelStyled,
  RightPanelStyled,
} from "./AuthLayout.styles";
import { authSelectors, uiSelectors, useSelector } from "../../state";
import { getQueryParam, Navigation } from "../../lib";
import { CompanyInfoDisplay } from "./CompanyInfoDisplay";
import { BackdropLoader } from "../../components";

export const AuthLayout = React.memo(
  /**
   *
   */
  function AuthLayout() {
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const loading = useSelector(uiSelectors.loading);
    useEffect(() => {
      if (isLoggedIn === true) {
        Navigation.replace(getQueryParam("after") || "/");
      }
    }, [isLoggedIn]);
    return (
      <RootStyled>
        <LeftPanelStyled>
          <CompanyInfoDisplay
            title="Welcome to the Compliance Consulting Group Client Portal"
            subtitle="First time here? Reach out today to hear how CCG services can simplify healthcare compliance for you."
          />
        </LeftPanelStyled>
        <RightPanelStyled>
          <Outlet />
        </RightPanelStyled>
        <Notification />
        <BackdropLoader loading={loading} />
      </RootStyled>
    );
  },
);
