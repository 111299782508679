import React from "react";
import { ResourceForm } from "./components";

export const ResourceFormPage = React.memo(
  /**
   *
   */
  function ResourceFormPage() {
    return <ResourceForm />;
  },
);
