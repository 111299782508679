import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckboxInput,
  DeleteIcon,
  ErrorIcon,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  InfoIcon,
  LinkTextStyled,
  PhoneField,
  SelectField,
  TextField,
} from "../../../../../components";
import {
  authSelectors,
  BasicPosition,
  GroupFacilityRegion,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
  User,
} from "../../../../../state";
import { useFormikContext } from "formik";
import { handleChangeUserPositions } from "../../../../adminShared/components/users/UserFormHelpers";
import { formatFullName } from "../../../../../lib";
import {
  PermissionClaims,
  PortalUserTypes,
  UserStatuses,
} from "../../../../../lib/constants";
import debounce from "lodash.debounce";
import { defaultTheme } from "../../../../../themes";

interface UserFormProps {
  hideDelete?: boolean;
  onDelete: () => void;
  onFindUserMatch: (email: string) => User | undefined;
  onMatchFormUser?: (combinedUser: User, matchedUserIndex: number) => void;
  otherUserPositions?: BasicPosition[];
  positionOptions?: BasicPosition[];
  showUserPendingFacilityAccess: boolean;
  title?: string;
  user: User;
  userAccessor: string;
  wizardType: string;
}

const userMatchTypes = {
  Form: "Form",
  System: "System",
};

export const AbbreviatedUserForm = React.memo(
  /**
   *
   */
  function AbbreviatedUserForm({
    hideDelete,
    onDelete,
    onFindUserMatch,
    onMatchFormUser,
    otherUserPositions,
    positionOptions,
    showUserPendingFacilityAccess,
    title,
    user,
    userAccessor,
    wizardType,
  }: UserFormProps) {
    const dispatch = useAppDispatch();
    const form = useFormikContext();
    const authUserType = useSelector(authSelectors.userType);

    // link by email
    const [searchingEmails, setSearchingEmails] = useState(false);
    const [userMatch, setUserMatch] = useState<User>();
    const [userMatchType, setUserMatchType] = useState("");

    const disableLinkUserMatch =
      userMatchType === userMatchTypes.Form && !onMatchFormUser; // 'additional users' cannot be linked to other form users

    const onSearchEmails = useCallback(
      async (email, findFormUserMatch, hasPrevMatch) => {
        if (!email) {
          setUserMatch(undefined);
          setUserMatchType("");
          if (hasPrevMatch) {
            form.setFieldValue(`${userAccessor}.hasDuplicateEmail`, undefined);
          }
          return;
        }

        setSearchingEmails(true);

        let foundMatch: User | undefined;
        let foundMatchType = "";

        // first search form for user match
        const formUserMatch = findFormUserMatch(email);
        if (formUserMatch) {
          foundMatch = formUserMatch as User;
          foundMatchType = userMatchTypes.Form;
        }

        // if no match on form, search system emails
        if (!foundMatch) {
          const systemUserMatch = await dispatch(
            sharedActions.getUserByEmail({
              email,
              facilityUserLevel: wizardType,
            }),
          );
          if (systemUserMatch) {
            foundMatch = systemUserMatch;
            foundMatchType = userMatchTypes.System;
          }
        }

        setUserMatch(foundMatch);
        setUserMatchType(foundMatchType);

        if (foundMatch && !hasPrevMatch) {
          form.setFieldValue(`${userAccessor}.hasDuplicateEmail`, true);
        } else if (hasPrevMatch) {
          form.setFieldValue(`${userAccessor}.hasDuplicateEmail`, undefined);
        }

        setSearchingEmails(false);
      },
      [dispatch, form, userAccessor, wizardType],
    );

    const debounceSearchEmails = useRef(
      debounce((email, findFormUserMatch, hasPrevMatch) => {
        onSearchEmails(email, findFormUserMatch, hasPrevMatch);
      }, 1000),
    );

    const onChangeEmail = useCallback(
      (e) => {
        form.handleChange(e);
        debounceSearchEmails.current(
          e.target.value,
          onFindUserMatch,
          !!userMatch,
        );
      },
      [form, onFindUserMatch, userMatch],
    );

    const onLinkUserMatch = useCallback(async () => {
      if (!userMatch) {
        return;
      }

      const linkedUser = {
        ...userMatch,
        // assign current user's positions/roles that matched user is not already assigned
        positionIDs: [
          ...new Set([
            ...(userMatch.positionIDs as number[]),
            ...(user.positionIDs as number[]),
          ]),
        ],
        roleIDs: [
          ...new Set([
            ...(userMatch.roleIDs as number[]),
            ...(user.roleIDs as number[]),
          ]),
        ],
      };
      if (userMatchType === userMatchTypes.System) {
        linkedUser.isLinkedToSystemUser = true;
      }

      if (userMatchType === userMatchTypes.Form) {
        // call onMatchFormUser which will replace this user with the form user match and remove the matched user from the form
        const userMatchIndex = (
          form.values as GroupFacilityRegion
        ).users?.indexOf(userMatch);
        if (onMatchFormUser && (userMatchIndex as number) >= 0) {
          onMatchFormUser(linkedUser, userMatchIndex as number);
        }
      } else {
        // set this user to the linked system user match
        form.setFieldValue(userAccessor, linkedUser);
      }

      setUserMatch(undefined);
      setUserMatchType("");
      form.setFieldValue(`${userAccessor}.hasDuplicateEmail`, undefined);
    }, [
      userMatch,
      userMatchType,
      form,
      user.positionIDs,
      user.roleIDs,
      onMatchFormUser,
      userAccessor,
    ]);

    // delete button
    const { deleteDisabled, editDisabled } = useMemo(
      () => ({
        deleteDisabled: !!user.id && !user.isLinkedToSystemUser,
        editDisabled: !!user.id,
      }),
      [user.id, user.isLinkedToSystemUser],
    );

    const deleteButton = useMemo(
      () =>
        !hideDelete ? (
          <Tooltip
            title={
              deleteDisabled
                ? authUserType === PortalUserTypes.CcgAdmin
                  ? "User can be removed from the main user form"
                  : "Contact a CCG Admin regarding removing this user"
                : ""
            }
          >
            <Box>
              <IconButton disabled={deleteDisabled} onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          ""
        ),
      [authUserType, deleteDisabled, hideDelete, onDelete],
    );

    // pending facility access
    const { permissionClaims } = useSelector(sysSelectors.systemSettings);
    const pendingFacilityEditClaimId = useMemo(
      () =>
        !showUserPendingFacilityAccess
          ? undefined
          : permissionClaims.find(
              (c) => c.claimValue === PermissionClaims.PendingFacilityEditClaim,
            )?.id,
      [permissionClaims, showUserPendingFacilityAccess],
    );
    const hasPendingFacilityEditClaim = useMemo(
      () =>
        !showUserPendingFacilityAccess
          ? undefined
          : user.claimIDs?.includes(pendingFacilityEditClaimId),
      [
        pendingFacilityEditClaimId,
        showUserPendingFacilityAccess,
        user.claimIDs,
      ],
    );

    const onTogglePendingFacilityEditAccess = useCallback(() => {
      form.setFieldValue(
        `${userAccessor}.claimIDs`,
        hasPendingFacilityEditClaim
          ? user.claimIDs?.filter((c) => c !== pendingFacilityEditClaimId)
          : [...(user.claimIDs || []), pendingFacilityEditClaimId],
      );
    }, [
      form,
      hasPendingFacilityEditClaim,
      pendingFacilityEditClaimId,
      user.claimIDs,
      userAccessor,
    ]);

    // position change
    const onChangePositions = useCallback(
      (e) => {
        if (positionOptions)
          handleChangeUserPositions(e, form, positionOptions, userAccessor);
      },
      [form, positionOptions, userAccessor],
    );

    return (
      <>
        {title && (
          <FormGridItemFullWidthStyled
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minHeight="72px"
          >
            <Typography variant="subtitle1" display="flex" alignItems="center">
              {title}{" "}
              {otherUserPositions && otherUserPositions.length > 0 && (
                <Tooltip
                  title={`User is also the ${otherUserPositions
                    .map((p) => p.name)
                    .join(", ")}`}
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{ ml: 1, color: "text.secondary" }}
                  />
                </Tooltip>
              )}
            </Typography>
            {deleteButton}
          </FormGridItemFullWidthStyled>
        )}
        <FormGridItemStyled item>
          <TextField
            disabled={editDisabled}
            name={`${userAccessor}.email`}
            label="Email"
            onChange={onChangeEmail}
            InputProps={{
              endAdornment: searchingEmails ? (
                <CircularProgress size={18} />
              ) : userMatch ? (
                <ErrorIcon
                  sx={{
                    color: `${defaultTheme.palette.error.light} !important`,
                  }}
                />
              ) : null,
            }}
          />
          {userMatch && (
            <Typography variant="body2" color="error.light" mt={1}>
              {`User ${formatFullName({
                firstName: userMatch.firstName,
                lastName: userMatch.lastName,
              })}${
                userMatchType === userMatchTypes.Form ? " on this form" : ""
              } ${
                userMatchType === userMatchTypes.Form
                  ? "shares this email"
                  : " exists with this email"
              }.`}{" "}
              {disableLinkUserMatch ? (
                "Duplicate user emails cannot be saved."
              ) : (
                <LinkTextStyled
                  variant="caption"
                  onClick={onLinkUserMatch}
                  sx={{ color: "error.light", fontSize: "14px" }}
                >
                  {userMatchType === userMatchTypes.Form
                    ? "Link and copy user info"
                    : "Link user to this account"}
                </LinkTextStyled>
              )}
            </Typography>
          )}
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <PhoneField
            disabled={editDisabled}
            name={`${userAccessor}.phone`}
            label="Cell phone"
          />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <TextField
            disabled={editDisabled}
            name={`${userAccessor}.firstName`}
            label="First name"
          />
        </FormGridItemStyled>
        <FormGridItemStyled item>
          <TextField
            disabled={editDisabled}
            name={`${userAccessor}.lastName`}
            label="Last name"
          />
        </FormGridItemStyled>
        {positionOptions && (
          <FormGridItemStyled item display="flex" alignItems="center">
            <SelectField
              disabled={editDisabled}
              name={`${userAccessor}.positionIDs`}
              label="Position"
              multiple={true}
              onChange={onChangePositions}
              options={positionOptions}
            />
            {authUserType !== PortalUserTypes.CcgAdmin && (
              <Tooltip title="If the position you would like to add doesn't exist, please contact CCG">
                <InfoIcon
                  fontSize="small"
                  sx={{ ml: 1, color: "text.secondary" }}
                />
              </Tooltip>
            )}
          </FormGridItemStyled>
        )}
        <FormGridItemFullWidthStyled
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-16px",
            paddingBottom: "16px",
          }}
        >
          <Box>
            {showUserPendingFacilityAccess && (
              <CheckboxInput
                checked={!!hasPendingFacilityEditClaim}
                label="Grant access to complete facility profile"
                name="pendingFacilityAccess"
                onChange={onTogglePendingFacilityEditAccess}
              />
            )}
            {editDisabled && (
              <Typography variant="body2" color="text.secondary" mt={1}>
                {user.isLinkedToSystemUser
                  ? "Linked to existing user account. "
                  : ""}{" "}
                <b>
                  {user.status === UserStatuses.Inactive
                    ? "Note that this user is inactive. "
                    : ""}
                </b>
                {authUserType === PortalUserTypes.CcgAdmin
                  ? "Saved user information can be updated from the main user form."
                  : "Contact a CCG Admin regarding updating user information."}
              </Typography>
            )}
          </Box>
          {!title && deleteButton}
        </FormGridItemFullWidthStyled>
      </>
    );
  },
);
