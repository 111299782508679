import React from "react";
import { Button, Typography } from "@mui/material";
import { AdminPages } from "../../../..";
import { Navigation } from "../../../../../lib";
import { ListTypes } from "../../../../../state/lists/state";
import { RegionsList } from "../lists/RegionsList";
import { EntityFilter } from "../../../../../lib/types";

interface RegionsSublistProps {
  filter: EntityFilter;
}

export const RegionsSublist = React.memo(
  /**
   *
   */
  function RegionsSublist({ filter }: RegionsSublistProps) {
    return (
      <RegionsList
        filter={filter}
        listType={ListTypes.relatedRegions}
        noRowsDisplay={
          <>
            <Typography variant="subtitle2">
              There are no regions for this group
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                Navigation.go(AdminPages.addRegion.path, {
                  query: { groupId: filter.groupId },
                });
              }}
              sx={{ mt: 2, zIndex: 1 }}
            >
              Add Region
            </Button>
          </>
        }
      />
    );
  },
);
