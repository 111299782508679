import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import {
  adminActions,
  TrainingPacket,
  useAppDispatch,
} from "../../../../state";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  FileUploadField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  TextField,
  ToggleField,
} from "../../../../components";
import {
  FileUploadTypes,
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useFileUpload,
  useRedirect,
  yup,
} from "../../../../lib";
import { AdminPages } from "../..";
import {
  BasicStatusOptions,
  TrainingsPageTabs,
} from "../../../../lib/constants";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(250, "Name cannot exceed 250 characters"),
  status: yup.string().required("Status is required"),
  url: yup.string().required("File is required"),
});

export const TrainingPacketForm = React.memo(
  /**
   *
   */
  function TrainingPacketForm() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const { redirect, setRedirect } = useRedirect();

    const [initialValues, setInitialValues] = useState<TrainingPacket>({
      name: "",
      status: "",
      url: "",
    });

    useEffect(() => {
      (async () => {
        if (params.id) {
          const trainingPacket = await dispatch(
            adminActions.getTrainingPacket(Number(params.id)),
          );
          if (trainingPacket) {
            setInitialValues(replaceNullProps(trainingPacket));
          }
        }
      })();
    }, [dispatch, params.id]);

    const uploadFile = useFileUpload();

    const getValuesForSubmission = useCallback(
      async (values: TrainingPacket, setFieldValue) => {
        const valuesForSubmission = { ...values };

        // if a new file was selected, upload file to get storage url
        if (values.url && typeof values.url === "object") {
          const url = await uploadFile(
            values.url,
            FileUploadTypes.TrainingPacket,
            true,
          );

          if (!url) {
            return;
          }

          // update the url
          valuesForSubmission.url = url;
          // update the form field value as well, so that if save fails later on the url will be persisted with the form and won't require re-uploading
          setFieldValue("url", url);
        }

        return valuesForSubmission;
      },
      [uploadFile],
    );

    const onSubmit = useCallback(
      async (values: TrainingPacket, { setFieldValue }) => {
        const valuesForSubmission = await getValuesForSubmission(
          values,
          setFieldValue,
        );
        if (!valuesForSubmission) {
          return;
        }

        const savedTrainingPacket = await dispatch(
          adminActions.submitTrainingPacket(
            replaceEmptyProps(valuesForSubmission),
          ),
        );
        if (savedTrainingPacket) {
          setInitialValues(replaceNullProps(savedTrainingPacket));

          const trainingPacketsListUrl = Navigation.url(AdminPages.trainings, {
            query: {
              tab: TrainingsPageTabs.TrainingPackets,
            },
          });
          setRedirect(() => () => Navigation.goBack(trainingPacketsListUrl));
        }
      },
      [dispatch, getValuesForSubmission, setRedirect],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    const onCancel = useCallback(
      () => Navigation.goBack(AdminPages.trainings.path),
      [],
    );

    return (
      <Form
        form={form}
        noPrompt={!!redirect}
        promptCancelText="Back to Training packet"
      >
        <FormGridContainerStyled container>
          <FormGridItemFullWidthStyled item>
            <TextField name="name" label="Name" />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleField
              fullWidth={false}
              name="status"
              options={BasicStatusOptions}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <Typography variant="h6">File</Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <FileUploadField description="file" name="url" secure={true} />
          </FormGridItemFullWidthStyled>
          <FormFooterContainerStyled>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </FormFooterContainerStyled>
        </FormGridContainerStyled>
      </Form>
    );
  },
);
