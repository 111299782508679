import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { renderPageRoutes } from "./lib";
import { AuthLayout, MainLayout } from "./layouts";
import {
  AdminPages,
  AdminSharedPages,
  AuthPages,
  FacilityAdminPages,
  FacilityStaffPages,
  SharedPages,
} from "./pages";
import { HistoryRouter } from "./lib/routing/HistoryRouter";
import { authSelectors, useSelector } from "./state";
import { PortalUserTypes } from "./lib/constants";

const routesByUserType = {
  [PortalUserTypes.CcgAdmin]: { ...AdminPages, ...AdminSharedPages },
  [PortalUserTypes.FacilityAdmin]: {
    ...FacilityAdminPages,
    ...AdminSharedPages,
  },
  [PortalUserTypes.FacilityStaff]: FacilityStaffPages,
};

export const App = React.memo(
  /**
   *
   */
  function App() {
    const userType = useSelector(authSelectors.userType);
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);

    const defaultPath = useMemo(() => {
      const userTypeRoutes = (userType && routesByUserType[userType]) || {};
      const userRoutes = Object.keys(userTypeRoutes)
        .map((r) => userTypeRoutes[r])
        .filter(
          (r) =>
            !r.authClaims?.length ||
            r.authClaims.some((claim) => userPermissionClaims.includes(claim)),
        );
      const defaultPath = (userRoutes.find((r) => r.isDefault) || userRoutes[0])
        ?.path;
      return defaultPath || AuthPages.login.path;
    }, [userPermissionClaims, userType]);

    return (
      <HistoryRouter>
        <Routes>
          <Route path="/" element={<MainLayout defaultPath={defaultPath} />}>
            {userType && renderPageRoutes(routesByUserType[userType])}
            {renderPageRoutes(SharedPages)}
          </Route>
          <Route path="/auth" element={<AuthLayout />}>
            {renderPageRoutes(AuthPages)}
          </Route>
        </Routes>
      </HistoryRouter>
    );
  },
);
