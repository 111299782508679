import React from "react";
import { useSelector } from "react-redux";
import { sysSelectors } from "../../state";
import { FacilityFilesList } from "../shared/components";
import { FacilityFilesAccessWrapper } from "./components";

export const FacilityFilesPage = React.memo(
  /**
   *
   */
  function FacilityFilesPage() {
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    return (
      <FacilityFilesAccessWrapper>
        <FacilityFilesList groupId={Number(facilityFilter?.groupID)} />
      </FacilityFilesAccessWrapper>
    );
  },
);
