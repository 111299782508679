import React, { useCallback, useEffect, useState } from "react";
import { useFileDownload, useSecureFileRead } from "../../lib";
import { FileTypes } from "../../lib/constants";
import { StorageFile } from "../../state";
import { Box, IconButton, Typography } from "@mui/material";
import { DownloadIcon } from "../icons";

const getFileType = (fileSource: string | File | StorageFile) => {
  if (fileSource instanceof File) {
    return fileSource.type.includes("image")
      ? FileTypes.Image
      : fileSource.type.includes("video")
      ? FileTypes.Video
      : fileSource.type.includes("pdf")
      ? FileTypes.Pdf
      : FileTypes.Other;
  } else {
    const fileUrl =
      typeof fileSource === "string" ? fileSource : fileSource.url;
    return fileUrl.includes(".png") ||
      fileUrl.includes(".jpeg") ||
      fileUrl.includes(".jpg") ||
      fileUrl.includes(".gif") ||
      fileUrl.includes(".tif") ||
      fileUrl.includes(".tiff") ||
      fileUrl.includes(".bmp")
      ? FileTypes.Image
      : fileUrl.includes(".mp4")
      ? FileTypes.Video
      : fileUrl.includes(".pdf")
      ? FileTypes.Pdf
      : FileTypes.Other;
  }
};

export interface FilePreviewProps {
  fileName?: string;
  fileSource: string | File | StorageFile;
  fileType?: string;
  pdfControls?: string;
  secure?: boolean;
  style?: object;
}

export const FilePreview = React.memo(function FilePreview({
  fileName,
  fileSource,
  fileType,
  pdfControls,
  secure,
  style,
}: FilePreviewProps) {
  const type = fileType || getFileType(fileSource);

  const [src, setSrc] = useState(
    !secure && typeof fileSource === "string" ? fileSource : "",
  );
  const [revokeSrcOnUnload, setRevokeSrcOnUnload] = useState(false);

  const downloadFile = useFileDownload();

  const getSecureFileUrl = useSecureFileRead();
  const setSecureSrc = useCallback(async () => {
    setSrc(await getSecureFileUrl(fileSource, type === FileTypes.Video));
  }, [fileSource, getSecureFileUrl, type]);

  useEffect(() => {
    // create url from file src
    if (fileSource instanceof File) {
      setSrc(URL.createObjectURL(fileSource));
      setRevokeSrcOnUnload(true);
    }
    // get secure url src
    else if (secure) {
      setSecureSrc();
    }

    // Revoke the data uris to avoid memory leaks
    return () => {
      if (revokeSrcOnUnload) {
        URL.revokeObjectURL(src);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSource, secure]);

  const onFileLoad = useCallback(() => {
    if (revokeSrcOnUnload) {
      URL.revokeObjectURL(src);
    }
  }, [revokeSrcOnUnload, src]);

  if (!src) return null;

  switch (type) {
    case FileTypes.Image:
      return (
        <img
          alt="img preview"
          onLoad={onFileLoad}
          src={src}
          style={{
            objectFit: "contain",
            ...style,
          }}
        />
      );
    case FileTypes.Video:
      return (
        <video
          controls
          controlsList="nodownload" // eliminate download option
          onContextMenu={(e) => e.preventDefault()} // eliminate right click menu
          onLoad={onFileLoad}
          src={src}
          style={style}
          title="video preview"
        />
      );
    case FileTypes.Pdf:
      return (
        <iframe
          onLoad={onFileLoad}
          src={pdfControls ? `${src}#${pdfControls}` : src}
          title="file preview"
          style={style}
        />
      );
    case FileTypes.Other:
    default:
      return (
        <Box textAlign="center" sx={style}>
          <Typography mb={2}>
            Preview is not available for this file. Click below to download.
          </Typography>
          <IconButton
            color="primary"
            onClick={() => downloadFile(src, fileName || "preview-download")}
          >
            <DownloadIcon fontSize="large" />
          </IconButton>
        </Box>
      );
  }
});
