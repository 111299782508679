import axios from "axios";
import { useCallback } from "react";
import { uiActions, useDispatch } from "../../../state";
import { useSecureFileRead } from "./useSecureFileRead";

export function useFileDownload(name = "file") {
  const dispatch = useDispatch();

  const getSecureFileUrl = useSecureFileRead(name);

  const downloadFile = useCallback(
    async (fileUrl, fileName, secure = false) => {
      dispatch(uiActions.setLoading(true));

      let downloadUrl = fileUrl;
      if (secure) {
        downloadUrl = await getSecureFileUrl(fileUrl);
        if (!downloadUrl) {
          dispatch(uiActions.setLoading(false));
          dispatch(
            uiActions.showError(`Failed to retrieve ${name} for download`),
          );
          return;
        }
      }

      try {
        const blobRes = await axios.get(downloadUrl, { responseType: "blob" });

        const linkUrl = window.URL.createObjectURL(blobRes.data);

        const link = document.createElement("a");
        link.href = linkUrl;
        link.download = fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(linkUrl);
      } catch (err) {
        console.error("Error downloading file: ", err);
      }

      dispatch(uiActions.setLoading(false));
    },
    [dispatch, getSecureFileUrl, name],
  );

  return downloadFile;
}
