import * as yup from "yup";
import { phoneSchema } from "../../../../../lib";
import { EntityTypes, FacilityStatuses } from "../../../../../lib/constants";
import {
  BasicPosition,
  Facility,
  Group,
  GroupFacilityRegionBase,
  Region,
  User,
} from "../../../../../state";

export const WizardModes = {
  Edit: "Edit",
  FacilityAdmin: "FacilityAdmin", // facility admin accessing pending facility profile
  New: "New", // new entity, or facility in pending/review statuses
};

// #region Initial values

const baseInitialValues: GroupFacilityRegionBase = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  stateID: null,
  zip: "",
  phone1: "",
  phone2: "",
  logoUrl: "",
  status: "",
  users: [],
};

const groupInitialValues: Group = {
  ...baseInitialValues,
};

const regionInitialValues: Region = {
  ...baseInitialValues,
  groupID: null,
};

const facilityInitialValues: Facility = {
  ...baseInitialValues,
  groupID: null,
  regionID: null,
  type: "",
  numberOfBeds: null,
  ipAddress: "",
  lockUsersToIP: false,
  billingContactFirstName: "",
  billingContactLastName: "",
  billingContactEmail: "",
  billingContactPhone: "",
};

export const initialValuesByType = {
  [EntityTypes.Group]: groupInitialValues,
  [EntityTypes.Region]: regionInitialValues,
  [EntityTypes.Facility]: facilityInitialValues,
};

// #endregion

// #region Validation

// #region Step 1
const baseStep1Validation = {
  name: yup
    .string()
    .max(100, "Name cannot exceed 100 characters")
    .required("Name is required"),
  address1: yup
    .string()
    .nullable()
    .max(100, "Address line 1 cannot exceed 100 characters"),
  address2: yup
    .string()
    .nullable()
    .max(50, "Address line 2 cannot exceed 50 characters"),
  city: yup.string().nullable().max(50, "City cannot exceed 50 characters"),
  stateID: yup.number().nullable(),
  zip: yup.string().nullable().max(50, "Zip code cannot exceed 50 characters"),
  phone1: phoneSchema(),
  phone2: phoneSchema(),
  logoUrl: yup.string().nullable(),
  status: yup.string().nullable().required("Status is required"),
};

const groupStep1ValidationSchema = yup.object(baseStep1Validation);

const regionStep1ValidationSchema = yup.object({
  ...baseStep1Validation,
  groupID: yup.number().nullable().required("Group is required"),
});

const facilityStep1ValidationSchema = yup.object({
  ...baseStep1Validation,
  groupID: yup.number().nullable().required("Group is required"),
  regionID: yup.number().nullable(),
  type: yup.string().required("Facility type is required"),
  ipAddress: yup.string().when("status", {
    is: (status) =>
      status !== FacilityStatuses.Pending && status !== FacilityStatuses.Review,
    then: (schema) => schema.required("IP address is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lockUsersToIP: yup.boolean(),
  address1: yup
    .string()
    .max(100, "Address line 1 cannot exceed 100 characters")
    .when("status", {
      is: (status) =>
        status !== FacilityStatuses.Pending &&
        status !== FacilityStatuses.Review,
      then: (schema) => schema.required("Address line 1 is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  address2: yup
    .string()
    .nullable()
    .max(50, "Address line 2 cannot exceed 50 characters"),
  city: yup
    .string()
    .max(50, "City cannot exceed 50 characters")
    .when("status", {
      is: (status) =>
        status !== FacilityStatuses.Pending &&
        status !== FacilityStatuses.Review,
      then: (schema) => schema.required("City is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  stateID: yup
    .number()
    .nullable()
    .when("status", {
      is: (status) =>
        status !== FacilityStatuses.Pending &&
        status !== FacilityStatuses.Review,
      then: (schema) => schema.required("State is required"),
    }),
  zip: yup
    .string()
    .max(50, "Zip code cannot exceed 50 characters")
    .when("status", {
      is: (status) =>
        status !== FacilityStatuses.Pending &&
        status !== FacilityStatuses.Review,
      then: (schema) => schema.required("Zip is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  numberOfBeds: yup.number().nullable(),
});
// #endregion

// #region Step 2

// checks if any of the params have values for dependency validation
const dependencyInfoProvided = (...params) =>
  params.filter((p) => p).length > 0;

const firstNameValidationDeps = ["lastName", "email", "phone"];
const lastNameValidationDeps = ["firstName", "email", "phone"];
const emailValidationDeps = ["id", "firstName", "lastName", "phone"];
const positionIDsValidationDeps = ["firstName", "lastName", "email", "phone"];
const roleIDValidationDeps = ["firstName", "lastName", "email", "phone"];

const userValidationDeps: any = [
  firstNameValidationDeps,
  lastNameValidationDeps,
  emailValidationDeps,
  positionIDsValidationDeps,
  roleIDValidationDeps,
];

const userValidation = {
  firstName: yup
    .string()
    .max(50, "First name cannot exceed 50 characters")
    .when(firstNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("First name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  lastName: yup
    .string()
    .max(50, "Last name cannot exceed 50 characters")
    .when(lastNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Last name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  email: yup
    .string()
    .max(250, "Email cannot exceed 250 characters")
    .email("Enter a valid email")
    .when(emailValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  phone: phoneSchema(),
  positionIDs: yup.array().when(positionIDsValidationDeps, {
    is: dependencyInfoProvided,
    then: (schema) => schema.min(1, "Position is required"),
  }),
  roleIDs: yup.array(),
  hasDuplicateEmail: yup
    .boolean()
    .nullable()
    .notOneOf([true], "Enter a unique email"),
};

const billingContactFirstNameValidationDeps = [
  "billingContactLastName",
  "billingContactEmail",
  "billingContactPhone",
];
const billingContactLastNameValidationDeps = [
  "billingContactFirstName",
  "billingContactEmail",
  "billingContactPhone",
];
const billingContactEmailValidationDeps = [
  "billingContactFirstName",
  "billingContactLastName",
  "billingContactPhone",
];

const billingContactValidationDeps: any = [
  billingContactFirstNameValidationDeps,
  billingContactLastNameValidationDeps,
  billingContactEmailValidationDeps,
];

const billingContactValidation = {
  billingContactFirstName: yup
    .string()
    .max(50, "First name cannot exceed 50 characters")
    .when(billingContactFirstNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("First name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactLastName: yup
    .string()
    .max(50, "Last name cannot exceed 50 characters")
    .when(billingContactLastNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Last name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactEmail: yup
    .string()
    .max(250, "Email cannot exceed 250 characters")
    .email("Enter a valid email")
    .when(billingContactEmailValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactPhone: phoneSchema(),
};

const baseStep2Validation = {
  users: yup.array(yup.object().shape(userValidation, userValidationDeps)),
};

const groupStep2ValidationSchema = yup.object(baseStep2Validation);

const regionStep2ValidationSchema = yup.object(baseStep2Validation);

const facilityStep2ValidationSchema = yup.object().shape(
  {
    ...baseStep2Validation,
    ...billingContactValidation,
  },
  billingContactValidationDeps,
);
// #endregion

// #region Exports by type
const groupSchemas = {
  1: groupStep1ValidationSchema,
  2: groupStep2ValidationSchema,
};

const regionSchemas = {
  1: regionStep1ValidationSchema,
  2: regionStep2ValidationSchema,
};

const facilitySchemas = {
  1: facilityStep1ValidationSchema,
  2: facilityStep2ValidationSchema,
};

export const validationSchemasByType = {
  [EntityTypes.Group]: groupSchemas,
  [EntityTypes.Region]: regionSchemas,
  [EntityTypes.Facility]: facilitySchemas,
};
// #endregion

// #endregion

// #region Users

export const PresetUserPositionsByType = {
  [EntityTypes.Group]: [
    "Group Owner",
    "Group Operator",
    "Group VP of Operations",
  ],
  [EntityTypes.Region]: ["Regional Director of Operations"],
  [EntityTypes.Facility]: [
    "Facility Administrator",
    "Facility Compliance Officer",
    "Facility In-Service Coordinator",
    "Facility Director of HR",
  ],
};

export const getPositionByName = (name: string, positions: BasicPosition[]) =>
  positions.find((p) => p.name === name);

export const initialUserValues: User = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  positionIDs: [],
  roleIDs: [],
  claimIDs: [],
};

export const getInitialUsers = (
  type: string,
  positions: BasicPosition[],
  savedUsers: User[] = [],
) => {
  const users = [...savedUsers];

  // preset a user for each preset position:
  PresetUserPositionsByType[type].forEach((positionName) => {
    const position = getPositionByName(positionName, positions);
    if (
      position &&
      !savedUsers.find((u) => u.positionIDs?.includes(position.id))
    ) {
      users.push({
        ...initialUserValues,
        positionIDs: [position.id],
        roleIDs: [position.defaultRoleID],
      });
    }
  });

  return users;
};

// #endregion
