import React, { useCallback, useMemo, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { DeleteIcon, EditIcon, UploadIcon } from "../icons";
import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FilePreviewDialog,
} from "../dialogs";
import { FilePreview } from "../views";
import { ErrorTextStyled, LinkTextStyled } from "../styled";
import { useField } from "formik";
import { FileTypes } from "../../lib/constants";
import { StorageFile } from "../../state";
import { FadeOutText } from "../text";

interface UploadTextStyledProps extends BoxProps {
  error?: string;
}

const UploadTextStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "error",
})<UploadTextStyledProps>(({ error, theme }) => ({
  cursor: "pointer",
  color: error ? theme.palette.error.dark : theme.palette.primary.light,
  outline: "none",
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  "&:hover": {
    opacity: ".8",
  },
  "& svg": {
    marginRight: "8px",
  },
}));

const UploadedFileContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": { display: "flex" },
}));

const ThumbnailPreviewContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    height: "48px",
    width: "48px",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

export interface FileUploadFieldProps {
  accept?: Accept;
  clearable?: boolean;
  confirmDelete?: boolean;
  description?: string;
  disabled?: boolean;
  fileRejectionMessage?: string;
  fileType?: string;
  handleDelete?: () => void;
  maxSize?: number;
  name: string;
  noEdit?: boolean;
  noPreview?: boolean;
  previewFileTypes?: string[];
  secure?: boolean;
  showFileName?: boolean;
  thumbnailPreview?: boolean;
  uploadText?: string;
}

export const FileUploadField = React.memo(
  /**
   *
   */
  function FileUploadField({
    accept,
    clearable = true,
    confirmDelete,
    description,
    fileRejectionMessage,
    fileType,
    handleDelete,
    maxSize,
    name,
    noEdit,
    noPreview,
    previewFileTypes,
    secure,
    showFileName,
    thumbnailPreview,
    uploadText,
  }: FileUploadFieldProps) {
    const [{ value }, meta, helpers] = useField<string | File | StorageFile>(
      name,
    );

    const error = meta.touched ? meta.error : "";

    const [showPreview, setShowPreview] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showRejectedFileMessage, setShowRejectedFileMessage] =
      useState(false);

    const onDrop = useCallback(
      (acceptedFiles, rejectedFiles) => {
        helpers.setValue(acceptedFiles[0]);
        setShowRejectedFileMessage(!!rejectedFiles?.length);
      },
      [helpers],
    );

    const onToggleShowDeleteConfirmation = useCallback(
      () => setShowDeleteConfirmation(!showDeleteConfirmation),
      [showDeleteConfirmation],
    );

    const onDelete = useCallback(() => {
      if (handleDelete) {
        handleDelete();
      } else {
        helpers.setValue("");
      }
      if (showDeleteConfirmation) {
        onToggleShowDeleteConfirmation();
      }
    }, [
      handleDelete,
      helpers,
      onToggleShowDeleteConfirmation,
      showDeleteConfirmation,
    ]);

    const onToggleShowPreview = useCallback(
      () => setShowPreview(!showPreview),
      [showPreview],
    );

    const { getRootProps, getInputProps, open } = useDropzone({
      accept:
        accept ||
        (fileType === FileTypes.Image
          ? { "image/*": [] }
          : fileType === FileTypes.Pdf
          ? { "application/pdf": [] }
          : undefined),
      maxFiles: 1,
      maxSize,
      onDrop,
    });

    const displayName = useMemo(
      () => description || name || "file",
      [description, name],
    );

    return (
      <>
        {!value ? (
          <UploadTextStyled error={error} {...getRootProps()}>
            <input {...getInputProps()} />
            <UploadIcon /> {uploadText || "Upload"} {displayName}
          </UploadTextStyled>
        ) : (
          <UploadedFileContainerStyled>
            <Box mr={2}>
              {noPreview ||
              (previewFileTypes &&
                !previewFileTypes.includes((value as File).type)) ? (
                <Typography sx={{ color: "primary.light" }}>
                  {showFileName
                    ? (value as File | StorageFile)?.name
                    : `Uploaded ${displayName}`}
                </Typography>
              ) : thumbnailPreview ? (
                <>
                  <ThumbnailPreviewContainerStyled
                    onClick={onToggleShowPreview}
                  >
                    <FilePreview fileSource={value} fileType={fileType} />
                  </ThumbnailPreviewContainerStyled>
                  {!noEdit && (
                    <UploadTextStyled ml={2} error={error} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadIcon /> Replace {displayName}
                    </UploadTextStyled>
                  )}
                </>
              ) : (
                <LinkTextStyled
                  onClick={onToggleShowPreview}
                  sx={{ overflowWrap: "anywhere" }}
                >
                  {showFileName
                    ? (value as File | StorageFile)?.name
                    : `Preview uploaded ${displayName}`}
                </LinkTextStyled>
              )}
            </Box>
            <Box>
              {!thumbnailPreview && !noEdit && (
                <IconButton onClick={open}>
                  <EditIcon />
                </IconButton>
              )}
              {clearable && (
                <IconButton
                  onClick={
                    // confirm only if the file was saved to cloud storage
                    confirmDelete &&
                    (typeof value === "string" || (value as StorageFile)?.id)
                      ? onToggleShowDeleteConfirmation
                      : onDelete
                  }
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </UploadedFileContainerStyled>
        )}
        {showDeleteConfirmation && (
          <ConfirmationDialog
            handleClose={onToggleShowDeleteConfirmation}
            handleConfirm={onDelete}
            open={true}
            message={`Are you sure you want to delete this ${displayName}?`}
            title={`Delete ${displayName}?`}
            type={ConfirmationDialogTypes.Alert}
          />
        )}
        {showPreview && (
          <FilePreviewDialog
            fileSource={(value as StorageFile)?.url || value}
            fileType={fileType}
            handleClose={onToggleShowPreview}
            open={true}
            secure={secure}
          />
        )}
        {showRejectedFileMessage && (
          <FadeOutText
            onTimeout={() => setShowRejectedFileMessage(false)}
            textComponent={
              <ErrorTextStyled fontSize="12px" mt={1}>
                {fileRejectionMessage ||
                  (fileType && fileType !== FileTypes.Other
                    ? `File type not accepted. File must be of ${fileType?.toLowerCase()} type.`
                    : "Invalid file type")}
              </ErrorTextStyled>
            }
          />
        )}
        {error && (
          <ErrorTextStyled fontSize="12px" mt={1}>
            {error}
          </ErrorTextStyled>
        )}
      </>
    );
  },
);
