import React, { useCallback, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  CommentsDisabledIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FullWidthFormGridItemStyled,
  HealingIcon,
  TextField,
} from "../../../../components";
import { AuditCommentRequest } from "../../../../state";
import { Form, useFormik } from "../../../../lib";
import { AuditCommentTypes } from "../../../../lib/constants";
import {
  CorrectiveActionTriggerText,
  removedCommentCorrectiveActionText,
} from "./AuditHelpers";
import Draggable from "react-draggable";
import * as yup from "yup";

const validationSchema = yup.object({
  text: yup
    .string()
    .max(2000, "Text cannot exceed 2000 characters")
    .required("Enter comment text"),
  questionNumber: yup
    .string()
    .max(50, "Question number cannot exceed 50 characters")
    .when("type", {
      is: (type) => type === AuditCommentTypes.Correction,
      then: (schema) => schema.required("Question number is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  relatedText: yup
    .string()
    .max(500, "Related text cannot exceed 500 characters")
    .nullable(),
});

const DraggablePaperComponent = (props) => {
  return (
    <Draggable cancel=".no-drag">
      <Paper {...props} />
    </Draggable>
  );
};

interface AuditCommentDialogProps {
  comment: AuditCommentRequest;
  handleCancel: () => void;
  handleSubmit: (comment: AuditCommentRequest) => Promise<boolean>;
}

const commentFieldId = "comment-field";

export const AuditCommentDialog = React.memo(
  /**
   *
   */
  function AuditCommentDialog({
    comment,
    handleCancel,
    handleSubmit,
  }: AuditCommentDialogProps) {
    const [
      showRemoveCorrectiveActionConfirmation,
      setShowRemoveCorrectiveActionConfirmation,
    ] = useState(false);

    const onSubmit = useCallback(
      async (values, _, removeCorrectiveActionConfirmed = false) => {
        // if removed corrective action trigger text, prompt to confirm
        const removeCorrectiveAction = removedCommentCorrectiveActionText(
          values,
          comment,
        );

        if (removeCorrectiveActionConfirmed) {
          setShowRemoveCorrectiveActionConfirmation(false);
        } else if (removeCorrectiveAction) {
          setShowRemoveCorrectiveActionConfirmation(true);
          return;
        }

        await handleSubmit(values);
      },
      [comment, handleSubmit],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        questionNumber: "",
        relatedText: "",
        text: "",
        ...comment,
      } as AuditCommentRequest,
      validationSchema,
      onSubmit,
    });

    const onAddPresetCommentText = useCallback(
      (text: string) => {
        form.setFieldValue("text", form.values.text + text);
        document.getElementById(commentFieldId)?.focus();
      },
      [form],
    );

    const commentDescription =
      comment.type === AuditCommentTypes.FacilityQuestion
        ? "Question"
        : comment.type === AuditCommentTypes.Internal
        ? "Internal comment"
        : "Comment";

    return showRemoveCorrectiveActionConfirmation ? (
      <ConfirmationDialog
        handleClose={() => setShowRemoveCorrectiveActionConfirmation(false)}
        handleConfirm={() => onSubmit(form.values, form, true)}
        message="Editing this comment will delete the associated Corrective Action Plan (CAP) and any information that was already filled out. This cannot be undone."
        open={true}
        title="Remove Corrective Action Plan (CAP)?"
        type={ConfirmationDialogTypes.Alert}
      />
    ) : (
      <Dialog
        open={true}
        PaperComponent={DraggablePaperComponent}
        PaperProps={{ sx: { cursor: "grab", width: "600px" } }}
      >
        <Form form={form} promptCancelText={`Back to ${commentDescription}`}>
          <DialogTitle variant="h6">
            {comment.id ? "Edit" : "Submit"} {commentDescription.toLowerCase()}
          </DialogTitle>
          <DialogContent>
            <Grid container paddingTop="16px">
              {comment.type === AuditCommentTypes.Correction && (
                <>
                  <FullWidthFormGridItemStyled item>
                    <TextField
                      className="no-drag"
                      name="questionNumber"
                      label="Question number"
                    />
                  </FullWidthFormGridItemStyled>
                  <FullWidthFormGridItemStyled item>
                    <TextField
                      className="no-drag"
                      name="relatedText"
                      label="Related text"
                    />
                  </FullWidthFormGridItemStyled>
                </>
              )}
              <FullWidthFormGridItemStyled item position="relative">
                <TextField
                  className="no-drag"
                  name="text"
                  id={commentFieldId}
                  label={commentDescription}
                  multiline={true}
                  rows={4}
                />
                {comment.type === AuditCommentTypes.Correction && (
                  <Box sx={{ position: "absolute", right: 0, bottom: -8 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={form.isSubmitting}
                      onClick={() =>
                        onAddPresetCommentText(CorrectiveActionTriggerText)
                      }
                      sx={{ mr: 1 }}
                    >
                      <HealingIcon sx={{ mr: 1 }} /> Add CAP text
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={form.isSubmitting}
                      onClick={() =>
                        onAddPresetCommentText(
                          "<br /><b>Be sure to update the answer in the actual audit. This comment sidebar is specifically for follow-up questions that you may have for CCG.</b>",
                        )
                      }
                    >
                      <CommentsDisabledIcon sx={{ mr: 1 }} /> Add reply
                      disclaimer
                    </Button>
                  </Box>
                )}
              </FullWidthFormGridItemStyled>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={handleCancel}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={form.isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
