import React, { useCallback, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  authSelectors,
  facilityAdminActions,
  PolicyRecord,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import {
  CloseIcon,
  DeleteIcon,
  DialogListItemStyled,
  ErrorIcon,
  ErrorTextStyled,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  SelectInput,
} from "../../../../components";
import { PolicyPreview } from "../../../shared/components";
import { PermissionClaims } from "../../../../lib/constants";

interface PrintPoliciesDialogProps {
  handleClose: () => void;
  policies: PolicyRecord[];
}

interface PrintPreviewInfo {
  facilityID: number;
  policyID: number;
}

export const PrintPoliciesDialog = React.memo(
  /**
   *
   */
  function PrintPoliciesDialog({
    handleClose,
    policies,
  }: PrintPoliciesDialogProps) {
    const dispatch = useAppDispatch();

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const facilityFilter = useSelector(sysSelectors.facilityFilter);
    const activeGroups = useSelector(sysSelectors.activeGroups);
    const activeFacilities = useSelector(sysSelectors.activeFacilities);

    const [policiesForPrint, setPoliciesForPrint] = useState(policies);
    const [facilityIdsForPrint, setFacilityIds] = useState(
      facilityFilter?.id ? [facilityFilter.id] : [],
    );

    const [printValidationMessages, setPrintValidationMessages] = useState<
      string[]
    >([]);
    const [printPreviewInfos, setPrintPreviewInfos] = useState<
      PrintPreviewInfo[]
    >([]);
    const [printPreviewIndex, setPrintPreviewIndex] = useState(0);

    const facilityOptions = useMemo(() => {
      // include all facilities if user has the printing override permission
      const hasPrintingOverridePermission = userPermissionClaims.includes(
        PermissionClaims.PoliciesPrintingOverrideClaim,
      );
      if (hasPrintingOverridePermission) return activeFacilities;

      // otherwise include only facilities without group policy printing disabled
      return activeFacilities.filter(
        (f) =>
          !activeGroups.find((g) => g.id === f.groupID)?.policyPrintingDisabled,
      );
    }, [activeFacilities, activeGroups, userPermissionClaims]);

    const onClickPrint = useCallback(async () => {
      if (!facilityIdsForPrint.length) {
        return;
      }

      // check policies availability across all selected facilities
      const policyDocsResponse = await dispatch(
        facilityAdminActions.getPolicyDocsForPrint(
          policiesForPrint.map((p) => p.id),
          facilityIdsForPrint,
        ),
      );
      if (policyDocsResponse) {
        if (policyDocsResponse.documents.length) {
          setPrintPreviewInfos(policyDocsResponse.documents);
        }
        if (policyDocsResponse.validationMessages.length) {
          setPrintValidationMessages(policyDocsResponse.validationMessages);
        }
      }
    }, [dispatch, facilityIdsForPrint, policiesForPrint]);

    return (
      <Dialog
        open={true}
        maxWidth={!printPreviewInfos.length ? "sm" : "md"}
        fullWidth={true}
      >
        {!printPreviewInfos.length ? (
          <Box sx={{ minHeight: "200px" }}>
            <DialogTitle variant="h6">Print policies</DialogTitle>
            <DialogContent>
              <List>
                {policiesForPrint.map((policy) => (
                  <DialogListItemStyled key={policy.id}>
                    <Typography>{policy.title}</Typography>
                    {policiesForPrint.length > 1 && (
                      <IconButton
                        onClick={() =>
                          setPoliciesForPrint(
                            policiesForPrint.filter((p) => p.id !== policy.id),
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </DialogListItemStyled>
                ))}
              </List>
              <SelectInput
                groupOpts={activeGroups.length > 1}
                label="Select facilities"
                multiple={true}
                name="facilities"
                onChange={(_, vals) => setFacilityIds(vals)}
                options={facilityOptions}
                selectAll={true}
                sx={{ mt: 2 }}
                value={facilityIdsForPrint}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                size="large"
                onClick={handleClose}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              {!printPreviewInfos.length && (
                <Button
                  disabled={!facilityIdsForPrint.length}
                  variant="contained"
                  size="large"
                  onClick={onClickPrint}
                >
                  Print
                </Button>
              )}
            </DialogActions>
            {!!printValidationMessages.length && (
              <ErrorTextStyled
                variant="body2"
                sx={{
                  padding: "0 16px 16px",
                  textAlign: "right",
                  whiteSpace: "pre-wrap",
                }}
              >
                {printValidationMessages.join("\n")}
              </ErrorTextStyled>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "90vh",
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              margin="16px 16px 8px"
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <PolicyPreview
              policyId={printPreviewInfos[printPreviewIndex].policyID}
              facilityId={printPreviewInfos[printPreviewIndex].facilityID}
              printOnly={true}
              printToolbarAddon={
                <Box display="flex" alignItems="center">
                  <IconButton
                    disabled={printPreviewIndex === 0}
                    onClick={() => setPrintPreviewIndex(printPreviewIndex - 1)}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <Typography sx={{ padding: "0px 16px" }}>
                    {printPreviewIndex + 1} of {printPreviewInfos.length}
                  </Typography>
                  <IconButton
                    disabled={
                      printPreviewIndex + 1 === printPreviewInfos.length
                    }
                    onClick={() => setPrintPreviewIndex(printPreviewIndex + 1)}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                  {!!printValidationMessages.length && (
                    <Tooltip
                      title={
                        <Box whiteSpace="pre-wrap">
                          {printValidationMessages.join("\n")}
                        </Box>
                      }
                      sx={{ mr: 1 }}
                    >
                      <Badge
                        color="error"
                        badgeContent={printValidationMessages.length}
                      >
                        <ErrorIcon />
                      </Badge>
                    </Tooltip>
                  )}
                </Box>
              }
            />
          </Box>
        )}
      </Dialog>
    );
  },
);
