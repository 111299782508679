import React, { useMemo } from "react";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../components";
import { AuditCommentRequest, AuditCommentThread } from "../../../../state";

interface AuditDeleteCommentDialogProps {
  comment: AuditCommentRequest;
  commentThread: AuditCommentThread;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const AuditDeleteCommentDialog = React.memo(
  /**
   *
   */
  function AuditDeleteCommentDialog({
    comment,
    commentThread,
    handleClose,
    handleConfirm,
  }: AuditDeleteCommentDialogProps) {
    const confirmationMessage = useMemo(() => {
      let message = "";

      const commentIndex = commentThread.comments?.findIndex(
        (c) => c.id === comment.id,
      );
      const commentsForDeletion = commentThread.comments?.slice(
        commentIndex,
      ) || [comment];

      if (commentsForDeletion.length > 1) {
        message +=
          "Deleting this comment will delete all subsequent comments in the thread";
      }
      if (commentsForDeletion.some((c) => c.correctiveActionRequired)) {
        message += `${
          message ? ", and" : "Deleting this comment"
        } will delete the associated Corrective Action Plan (CAP) and any information that was already filled out. This cannot be undone`;
      }

      if (message) message += ".";

      return message || "This cannot be undone.";
    }, [comment, commentThread.comments]);

    return (
      <ConfirmationDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        message={confirmationMessage}
        open={true}
        title="Delete comment?"
        type={ConfirmationDialogTypes.Alert}
      />
    );
  },
);
