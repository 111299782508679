import { Chip, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CommentsPanelContainerStyledProps {
  tabCount: number;
}

export const CommentsPanelContainerStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "tabCount",
})<CommentsPanelContainerStyledProps>(({ tabCount, theme }) => {
  const width = tabCount > 2 ? "400px" : "370px";
  return {
    display: "flex",
    flexDirection: "column",
    minWidth: width,
    maxWidth: width,
    marginLeft: "16px",
    paddingLeft: "24px",
    borderLeft: `1px solid ${theme.palette.divider}`,
  };
});

export const CommentsPanelHeaderStyled = styled(`div`)(() => ({
  margin: "14px 0",
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& button": {
    height: "32px",
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
}));

export const CommentsTabsStyled = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const CommentsListStyled = styled(`div`)(() => ({
  height: "100%",
  overflowY: "auto",
  paddingBottom: "16px",
}));

export const CommentsEmptyContainerStyled = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

export const CommentThreadContainerStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "16px",
  marginTop: "16px",
  "& .MuiInputBase-root": {
    minHeight: "51px",
  },
}));

export const CommentThreadTitleStyled = styled(Typography)(() => ({
  display: "flex",
  marginBottom: "18px",
}));

export const CommentThreadFooterStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "16px",
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
}));

export const CommentSubtitleStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "28px",
  mb: "4px",
  "& svg": {
    height: "18px",
    width: "18px",
    color: theme.palette.neutral.main,
  },
}));

export const CommentTextStyled = styled(Typography)(({ theme }) => ({
  "& a": {
    color: theme.palette.primary.light,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      opacity: ".8",
    },
  },
}));

export const CommentResolvedChipStyled = styled(Chip)(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.success.dark,
  height: "24px",
  "& span": {
    padding: "0px 8px",
  },
}));
