import { WebViewerInstance } from "@pdftron/pdfjs-express";

import { REACT_APP_PDF_JS_API_CLIENT_KEY } from "../../config";

import ExpressUtils from "@pdftron/pdfjs-express-utils";
const expressUtils = new ExpressUtils({
  clientKey: REACT_APP_PDF_JS_API_CLIENT_KEY,
});

export async function exportPdfAnnotations(
  annotationManager,
  linksAndWidgets = false, // set to true when exporting annotations for pdf merge
) {
  return await annotationManager.exportAnnotations(
    linksAndWidgets
      ? undefined
      : {
          links: false,
          widgets: false,
        },
  );
}

export async function mergePdfAnnotations(
  viewerInstance: WebViewerInstance,
  fileUrl?: string,
) {
  const {
    Core: { annotationManager, documentViewer },
  } = viewerInstance;

  if (fileUrl) {
    expressUtils.setFile(fileUrl);
  } else {
    const fileData = await documentViewer.getDocument().getFileData({});
    const blob = new Blob([fileData], { type: "application/pdf" });
    expressUtils.setFile(blob);
  }

  const xfdf = await exportPdfAnnotations(annotationManager, true);
  expressUtils.setXFDF(xfdf);

  const response = await expressUtils.merge(); // merge XFDF

  const mergedFile = await response.getBlob();
  await response.deleteFile(); // remove file from the server

  return mergedFile;
}
