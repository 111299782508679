import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { AnnouncementsList, FacilitiesList } from "./components";
import { DashboardContainerStyled } from "./DashboardPage.styles";

export const DashboardPage = React.memo(
  /**
   *
   */
  function DashboardPage() {
    const { firstName } = useSelector(authSelectors.userInfo);

    return (
      <>
        <Typography variant="h4" mb="24px">
          Welcome, {firstName}
        </Typography>
        <DashboardContainerStyled>
          <FacilitiesList />
          <AnnouncementsList />
        </DashboardContainerStyled>
      </>
    );
  },
);
