import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TitleStyled = styled(Typography)(({ theme }) => ({
  marginBottom: "6px",
  "& .MuiChip-root": {
    color: theme.palette.background.default,
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: "500",
  },
}));

export const CommentsPanelStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "260px",
  minWidth: "260px",
  marginLeft: "24px",
  paddingTop: "8px",
  "& > div:first-of-type": {
    height: "calc(100% - 48px)",
  },
  "& > div:nth-of-type(2)": {
    height: "48px",
    textAlign: "right",
    "& button": {
      marginTop: "16px",
    },
  },
}));

export const CommentsScrollContainerStyled = styled(Box)(() => ({
  overflowY: "auto",
  maxHeight: "calc(100% - 84px)",
}));

export const CommentHeaderStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "6px",
  "& button": { textTransform: "none" },
}));
