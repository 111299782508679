import React from "react";
import { Badge } from "@mui/material";
import { DomainIcon } from "../../../../../components";
import { getInitials } from "../../../../../lib";
import {
  AvatarStyled,
  LogoStyled,
  SubLogoStyled,
} from "./GroupFacilityRegionSnapshot.styles";

interface GroupFacilityRegionLogosDisplayProps {
  groupLogoUrl?: string;
  logoUrl?: string;
  name?: string;
}

export const GroupFacilityRegionLogosDisplay = React.memo(
  /**
   *
   */
  function GroupFacilityRegionLogosDisplay({
    groupLogoUrl,
    logoUrl,
    name,
  }: GroupFacilityRegionLogosDisplayProps) {
    return (
      <Badge
        badgeContent={
          groupLogoUrl && <SubLogoStyled alt="group-logo" src={groupLogoUrl} />
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ height: "fit-content" }}
      >
        {logoUrl ? (
          <LogoStyled alt="logo" src={logoUrl} />
        ) : (
          <AvatarStyled>{getInitials(name) ?? <DomainIcon />}</AvatarStyled>
        )}
      </Badge>
    );
  },
);
