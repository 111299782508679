import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Navigation, usePageTitle, WebViewerContextProvider } from "../../lib";
import {
  Audit,
  AuditVersion,
  sharedActions,
  sysActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../state";
import { AuditView } from "./components";
import { ConfirmationDialog, ConfirmationDialogTypes } from "../../components";
import { AuditSubheader } from "../adminShared/components";
import { getAuditStatusColor } from "../adminShared/components/audits/AuditHelpers";
import { FacilityAdminPages } from ".";
import { AuditStatuses } from "../../lib/constants";

export const AuditPage = React.memo(
  /**
   *
   */
  function AuditPage() {
    const dispatch = useAppDispatch();

    const setPageTitle = usePageTitle();

    const params = useParams();

    const facilityFilter = useSelector(sysSelectors.facilityFilter);
    const facilityOptions = useSelector(sysSelectors.allFacilities);

    const [audit, setAudit] = useState<Audit>();
    const [auditVersion, setAuditVersion] = useState<AuditVersion>();
    const [auditLoaded, setAuditLoaded] = useState(false);

    const retrieveAudit = useCallback(async () => {
      if (params.id) {
        let _audit: Audit | null = null,
          _auditVersion: AuditVersion | null = null;

        _audit = await dispatch(sharedActions.getAudit(Number(params.id)));

        if (_audit) {
          // set page title
          setPageTitle({
            title: _audit.name,
            status: _audit.statusDisplay,
            statusColor: getAuditStatusColor(
              _audit.status,
              _audit.statusDisplay,
              false,
            ),
            facilityId: _audit.facilityID,
          });

          // retrieve latest audit version
          if (_audit.versions[0]) {
            _auditVersion = await dispatch(
              sharedActions.getAuditVersion(_audit.id, _audit.versions[0].id),
            );
          }

          // if the audit facilityId does not match the facility filter (or none is applied), apply the correct facility filter
          const facilityId = _audit.facilityID;
          if (facilityFilter?.id !== facilityId) {
            const facility = facilityOptions.find((f) => f.id === facilityId);
            if (facility) {
              dispatch(sysActions.setFacilityFilter(facility));
            }
          }

          // set all at the end to ensure that state does not change until all relevant info is available (important when continuing to next audit after submission)
          setAudit(_audit || undefined);
          setAuditVersion(_auditVersion || undefined);
          setAuditLoaded(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id, setPageTitle]);

    useEffect(() => {
      retrieveAudit();
    }, [retrieveAudit]);

    return audit && auditLoaded ? (
      audit.status === AuditStatuses.Rescinded ? (
        <ConfirmationDialog
          confirmText="Leave page"
          handleConfirm={() => Navigation.go(FacilityAdminPages.audits)}
          hideCancel={true}
          message="Your audit has been rescinded by CCG. No further action is needed. For any questions, contact CCG."
          open={true}
          title="Audit rescinded"
          type={ConfirmationDialogTypes.Saved}
        />
      ) : (
        <WebViewerContextProvider>
          <AuditSubheader audit={audit} />
          <AuditView
            audit={audit}
            auditVersion={auditVersion}
            refreshAudit={retrieveAudit}
            setAudit={setAudit}
          />
        </WebViewerContextProvider>
      )
    ) : null;
  },
);
