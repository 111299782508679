import React, { useCallback, useEffect, useState } from "react";
import { Button, Box, Typography, Chip } from "@mui/material";
import { sharedActions, useAppDispatch, UserProfile } from "../../state";
import { useFormik } from "formik";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  PasswordField,
  PhoneField,
  RadioGroupField,
  TextField,
} from "../../components";
import {
  Form,
  passwordSchema,
  phoneSchema,
  replaceEmptyProps,
  replaceNullProps,
} from "../../lib";
import { ContactMethodTypes } from "../../lib/constants";
import * as yup from "yup";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .max(50, "First name cannot exceed 50 characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .max(50, "Last name cannot exceed 50 characters")
    .required("Last name is required"),
  email: yup
    .string()
    .max(250, "Email cannot exceed 250 characters")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: phoneSchema(),
  currentPassword: yup.string().when("newPassword", {
    is: (newPassword) => !!newPassword,
    then: (schema) => schema.required("Current password is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  newPassword: passwordSchema(true, true),
  twoFactorAuthPreference: yup.string().nullable(),
});

export const ProfilePage = React.memo(
  /**
   *
   */
  function ProfilePage() {
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState<UserProfile>({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      currentPassword: "",
      newPassword: "",
      twoFactorAuthPreference: "",
    });
    const [resetPassword, setResetPassword] = useState(false);
    const [showUpdateEmailConfirmation, setShowUpdateEmailConfirmation] =
      useState(false);

    useEffect(() => {
      (async () => {
        const user = await dispatch(sharedActions.getProfile());
        if (user) {
          setInitialValues(replaceNullProps(user));
        }
      })();
    }, [dispatch]);

    const onSubmit = useCallback(
      async (
        values: UserProfile,
        { setValues },
        emailUpdateConfirmed = false,
      ) => {
        if (values.email !== initialValues.email && !emailUpdateConfirmed) {
          setShowUpdateEmailConfirmation(true);
          return;
        }

        const savedProfile = await dispatch(
          sharedActions.submitProfile(replaceEmptyProps(values)),
        );
        if (savedProfile) {
          setValues(replaceNullProps(savedProfile));
          setInitialValues(replaceNullProps(savedProfile));
          setResetPassword(false);
        }
      },
      [dispatch, initialValues.email],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    const onConfirmEmailUpdate = useCallback(() => {
      setShowUpdateEmailConfirmation(false);
      onSubmit(form.values, form, true);
    }, [form, onSubmit]);

    const onCancel = useCallback(() => {
      form.resetForm();
    }, [form]);

    return (
      <>
        <Form form={form} promptCancelText="Back to Profile">
          <FormGridContainerStyled container>
            <FormGridItemStyled item>
              <TextField name="firstName" label="First name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="lastName" label="Last name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="email" label="Email address" type="email" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <PhoneField name="phone" label="Cell phone" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              {resetPassword ? (
                <>
                  <PasswordField
                    name="currentPassword"
                    label="Current password"
                  />
                  <PasswordField
                    name="newPassword"
                    label="New password"
                    sx={{ mt: 2 }}
                  />
                </>
              ) : (
                <PasswordField // pw placeholder field
                  name="password"
                  label="Password"
                  disabled={true}
                  showVisibilityIcon={false}
                  value="••••••••"
                />
              )}
              <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    // prep password fields
                    form.setFieldValue(
                      "currentPassword",
                      resetPassword ? initialValues.currentPassword : "",
                    );
                    form.setFieldValue(
                      "newPassword",
                      resetPassword ? initialValues.newPassword : "",
                    );
                    setResetPassword(!resetPassword);
                  }}
                >
                  {resetPassword ? "Cancel " : ""}Reset Password
                </Button>
              </Box>
            </FormGridItemStyled>
            <FormGridItemFullWidthStyled item mb="8px">
              <Typography variant="subtitle1" mb="8px">
                Two factor authentication
              </Typography>
              <Typography variant="body2" mb="18px">
                Choose how you receive your verification code.
              </Typography>
              <RadioGroupField
                name="twoFactorAuthPreference"
                options={[
                  {
                    id: ContactMethodTypes.MobileNumber,
                    name: "SMS",
                    display: (
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" mr="16px">
                          SMS
                        </Typography>
                        <Chip
                          color="primary"
                          label="Best option"
                          size="small"
                          sx={{ backgroundColor: "primary.light" }}
                        />
                      </Box>
                    ),
                  },
                  {
                    id: ContactMethodTypes.Email,
                    name: "Email",
                    display: (
                      <>
                        <Typography variant="body2">Email</Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          position="absolute"
                          marginTop="8px"
                        >
                          Please note: There can be delays in emails which can
                          make your 2FA code expire or go to spam.
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
            </FormGridItemFullWidthStyled>
            <FormFooterContainerStyled>
              <Button
                variant="outlined"
                size="large"
                disabled={form.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </FormFooterContainerStyled>
          </FormGridContainerStyled>
        </Form>
        {showUpdateEmailConfirmation && (
          <ConfirmationDialog
            confirmText="Update email"
            handleClose={() => setShowUpdateEmailConfirmation(false)}
            handleConfirm={onConfirmEmailUpdate}
            message={`Updating your email will change the email used for system login.\n\nYou will be required to confirm your email address via a confirmation email which will be sent to the updated email address before logging in again.`}
            open={true}
            title="Update email?"
            type={ConfirmationDialogTypes.Warning}
          />
        )}
      </>
    );
  },
);
