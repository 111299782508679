import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Button, Collapse, Typography } from "@mui/material";
import {
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SelectInput,
} from "../../../../../components";
import {
  Facility,
  FacilityActivityRecord,
  listActionUrls,
  sysSelectors,
  useSelector,
} from "../../../../../state";
import { ListTypes } from "../../../../../state/lists/state";
import { formatDate } from "../../../../../lib";

const filterQueryKey = {
  type: "type",
};

function FacilityActivityListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { facilityActivityTypes: facilityActivityTypeOptions = [] } =
    useSelector(sysSelectors.systemSettings);

  const { type = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!type);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Activity</Typography>
        <Button
          variant="text"
          color="primary"
          size="large"
          sx={{ ml: 1 }}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Filter <FilterListIcon sx={{ ml: 1 }} />
        </Button>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SelectInput
            label="Activity type"
            name="type"
            value={type}
            onChange={onFilterChange}
            options={facilityActivityTypeOptions}
            sx={{ mr: 2 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

interface FacilityActivityListProps {
  facility: Facility;
}

const columns: GridColDef[] = [
  {
    field: "typeDisplay",
    flex: 2,
    sortable: false,
  },
  {
    field: "createdOn",
    flex: 1,
    renderCell: ({ row }: { row: FacilityActivityRecord }) => (
      <Typography variant="body2" width="100%" textAlign="right">
        {row.createdBy ? `By ${row.createdBy} on` : "On"}{" "}
        {formatDate(row.createdOn)} (Q{row.quarter} -{" "}
        {row.year.toString().slice(-2)})
      </Typography>
    ),
    sortable: false,
  },
];

export const FacilityActivityList = React.memo(
  /**
   *
   */
  function FacilityActivityList({ facility }: FacilityActivityListProps) {
    return (
      <List
        actions={<FacilityActivityListActions />}
        dataUrl={listActionUrls[ListTypes.facilityActivity].replace(
          ":id",
          facility.id?.toString() || "",
        )}
        columns={columns}
        defaultOrderBy="createdOn"
        defaultOrderDirection="desc"
        filterQueryKey={filterQueryKey}
        hideHeader={true}
        name="activity"
        rowHeight={84}
        type={ListTypes.facilityActivity}
      />
    );
  },
);
