import * as yup from "yup";
import {
  phoneSchema,
  replaceEmptyProps,
  replaceNullProps,
} from "../../../../lib";
import {
  FacilityUserLevels,
  PortalUserTypes,
  UserCategories,
  UserStatuses,
} from "../../../../lib/constants";
import { BasicPosition, BasicRole, User } from "../../../../state";

// #region Initial values

export const getInitialValues = ({
  allowedUserLevels,
  groupOptions,
  // pre-set facility/group/region
  facilityId,
  groupId,
  regionId,
}) => {
  // set default user level based on facility/region/group params
  let defaultUserLevel = facilityId
    ? FacilityUserLevels.Facility
    : regionId
    ? FacilityUserLevels.Region
    : groupId
    ? FacilityUserLevels.Group
    : "";

  if (!defaultUserLevel || !allowedUserLevels.includes(defaultUserLevel)) {
    defaultUserLevel =
      allowedUserLevels.length === 1 // default to the single allowed user level
        ? allowedUserLevels[0]
        : "";
  }

  const initialUser: User = {
    userLevel: defaultUserLevel,
    firstName: "",
    lastName: "",
    groupIDs:
      groupOptions.length === 1
        ? [groupOptions[0].id] // for users who have access to a single group, default group to the available group
        : groupId
        ? [groupId] // apply preset group id
        : [],
    regionIDs: regionId ? [regionId] : [], // apply preset region id
    facilityIDs: facilityId ? [facilityId] : [], // apply preset facility id
    // contact
    email: "",
    phone: "",
    // roles/positions
    positionIDs: [],
    roleIDs: [],
    claimIDs: [],
    // account
    status: UserStatuses.Pending,
    hasPassword: false,
  };
  return initialUser;
};

// #endregion

// #region User level

const CcgAdminUserLevel = "CCG";

export const userLevelDescriptions = {
  [CcgAdminUserLevel]:
    "This user will have full access to the CCG admin portal",
  [FacilityUserLevels.Group]:
    "This user will have access to all regions and facilities within the selected groups",
  [FacilityUserLevels.Region]:
    "This user will have access to all facilities within the selected regions, and to selected facilities outside of the selected regions",
  [FacilityUserLevels.Facility]:
    "This user will have access to only selected facilities",
};

export const getAllowedUserLevels = (
  authUserType?: string,
  authFacilityUserLevel?: string,
) => {
  switch (authUserType) {
    case PortalUserTypes.CcgAdmin: // ccg admin user: can create any level users
      return [
        FacilityUserLevels.Group,
        FacilityUserLevels.Region,
        FacilityUserLevels.Facility,
        CcgAdminUserLevel,
      ];
    case PortalUserTypes.FacilityAdmin:
    default:
      switch (authFacilityUserLevel) {
        case FacilityUserLevels.Group: // group level user: can create region/facility level users
          return [FacilityUserLevels.Region, FacilityUserLevels.Facility];
        default:
          // region/facility level user: can only create facility level users
          return [FacilityUserLevels.Facility];
      }
  }
};

export const getFormContext = (userLevel?: string) => {
  const context = {
    withGroups: false,
    withRegions: false,
    withFacilities: false,
    withRolesAndPermissions: false,
  };
  switch (userLevel) {
    case FacilityUserLevels.Group:
      context.withGroups = true;
      context.withRolesAndPermissions = true;
      break;
    case FacilityUserLevels.Region:
      context.withGroups = true;
      context.withRegions = true;
      context.withFacilities = true;
      context.withRolesAndPermissions = true;
      break;
    case FacilityUserLevels.Facility:
      context.withGroups = true;
      context.withFacilities = true;
      context.withRolesAndPermissions = true;
      break;
  }
  return context;
};

// #endregion

// #region Validation

const baseValidation = {
  firstName: yup
    .string()
    .max(50, "First name cannot exceed 50 characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .max(50, "Last name cannot exceed 50 characters")
    .required("Last name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .max(250, "Email cannot exceed 250 characters")
    .required("Email is required"),
  phone: phoneSchema(),
  status: yup.string().nullable().required("User Status is required"),
  userLevel: yup.string().required("User level is required"),
};

const facilityUsersValidation = {
  userLevel: yup.string().required("User level is required"),
  positionIDs: yup.array().min(1, "Position is required"),
  roleIDs: yup.array().min(1, "Access level is required"),
  claimIDs: yup.array().nullable(),
  groupIDs: yup.array().min(1, "Group is required"),
};

const groupLevelValidationSchema = yup.object({
  ...baseValidation,
  ...facilityUsersValidation,
});

const regionLevelValidationSchema = yup.object({
  ...baseValidation,
  ...facilityUsersValidation,
  regionIDs: yup.array().min(1, "Region is required"),
  facilityIDs: yup.array().nullable(),
});

const facilityLevelValidationSchema = yup.object({
  ...baseValidation,
  ...facilityUsersValidation,
  facilityIDs: yup.array().min(1, "Facility is required"),
});

export const getValidationSchema = (userLevel?: string) => {
  switch (userLevel) {
    case FacilityUserLevels.Group:
      return groupLevelValidationSchema;
    case FacilityUserLevels.Region:
      return regionLevelValidationSchema;
    case FacilityUserLevels.Facility:
      return facilityLevelValidationSchema;
    default:
      return yup.object(baseValidation);
  }
};
// #endregion

// #region Form helpers

export const getClaimsForSubmission = (
  values: any,
  roleOptions: BasicRole[],
) => {
  // filter claimIDs to exclude any claims that are already included based on the assigned roles
  const roleClaimIds: number[] = [];
  roleOptions.forEach((r) => {
    if (values.roleIDs.includes(r.id)) {
      roleClaimIds.push(...r.claimIDs);
    }
  });
  const overlappingClaimIds = values.claimIDs.filter((id) =>
    roleClaimIds.includes(id),
  );
  if (overlappingClaimIds.length) {
    return values.claimIDs.filter((id) => !overlappingClaimIds.includes(id));
  }
  return values.claimIDs;
};

export const formatUserForForm = (user: User) =>
  replaceNullProps({
    ...user,
    // map facilityUserLevel to userLevel - default to CcgAdminUserLevel if no facilityUserLevel specified
    userLevel: user.facilityUserLevel || CcgAdminUserLevel,
  });

export const formatUserForSubmission = (
  user: User,
  roleOptions: BasicRole[],
) => {
  // adjust claims
  if (user.claimIDs?.length) {
    user.claimIDs = getClaimsForSubmission(user, roleOptions);
  }

  // adjust user category according to user level
  user.category =
    user.userLevel === CcgAdminUserLevel
      ? UserCategories.CcgAdmin
      : UserCategories.FacilityUser;

  // map userLevel to facilityUserLevel
  user.facilityUserLevel =
    user.userLevel === CcgAdminUserLevel ? "" : user.userLevel;

  return replaceEmptyProps({
    ...user,
    // clear userLevel UI flag
    userLevel: undefined,
  });
};

export const handleChangeUserPositions = (
  e,
  form,
  positionOptions: BasicPosition[],
  userAccessor = "",
) => {
  const accessorPrefix = userAccessor ? `${userAccessor}.` : "";

  const prevValue = form.values.positionIDs || [];

  const { value } = e.target;
  form.setFieldValue(accessorPrefix + "positionIDs", value);

  let roleIDs = [...(form.values.roleIDs || [])];

  const removedPositionIDs = prevValue.filter((v) => !value.includes(v));
  if (removedPositionIDs?.length) {
    // remove default roles for removed positions - persist role IDs that are associated with persisted selection
    const defaultRoleIDsToPersist = positionOptions
      .filter((p) => value.includes(p.id))
      .map((p) => p.defaultRoleID);
    const roleIDsToRemove = positionOptions
      .filter(
        (p) =>
          removedPositionIDs.includes(p.id) &&
          !defaultRoleIDsToPersist.includes(p.defaultRoleID),
      )
      .map((p) => p.defaultRoleID);
    if (roleIDsToRemove) {
      roleIDs = roleIDs.filter((r) => !roleIDsToRemove.includes(r));
    }
  }

  const addedPositionIDs = value?.filter((v) => !prevValue.includes(v));
  if (addedPositionIDs?.length) {
    // add default roles for added positions
    const roleIDsToAdd = positionOptions
      .filter(
        (p) =>
          addedPositionIDs.includes(p.id) && !roleIDs.includes(p.defaultRoleID),
      )
      .map((p) => p.defaultRoleID);
    if (roleIDsToAdd) {
      roleIDs = [...roleIDs, ...roleIDsToAdd];
    }
  }

  form.setFieldValue(accessorPrefix + "roleIDs", roleIDs);
};

// #endregion
