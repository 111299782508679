import { createSlice } from "@reduxjs/toolkit";

export interface FacilityAdminState {}

const initialState: FacilityAdminState = {};

export const facilityAdmin = {
  ...createSlice({
    name: "facilityAdmin",
    initialState,
    reducers: {},
  }),
  persist: false,
};
