import { PermissionClaims } from "../../lib/constants";
import { PageDefinitions } from "../../lib/types";
import { AnnouncementFormPage } from "./AnnouncementFormPage";
import { AuditCapPage } from "./AuditCapPage";
import { AuditPage } from "./AuditPage";
import { AuditsPage } from "./AuditsPage";
import { AuditTemplateFormPage } from "./AuditTemplateFormPage";
import { ConfigurationPage } from "./ConfigurationPage";
import { FacilitiesPage } from "./FacilitiesPage";
import { FacilityAuditsPage } from "./FacilityAuditsPage";
import { FacilityFileFormPage } from "./FacilityFileFormPage";
import { FacilityFilePage } from "./FacilityFilePage";
import { FacilityFilesPage } from "./FacilityFilesPage";
import { FacilityFormPage } from "./FacilityFormPage";
import { FacilityPage } from "./FacilityPage";
import { GroupFormPage } from "./GroupFormPage";
import { GroupPage } from "./GroupPage";
import { GroupsAndFacilitiesPage } from "./GroupsAndFacilitiesPage";
import { GroupsPage } from "./GroupsPage";
import { PoliciesPage } from "./PoliciesPage";
import { PolicyPage } from "./PolicyPage";
import { RegionFormPage } from "./RegionFormPage";
import { RegionPage } from "./RegionPage";
import { RegionsPage } from "./RegionsPage";
import { ResourceFormPage } from "./ResourceFormPage";
import { ResourcesPage } from "./ResourcesPage";
import { TrainingFormPage } from "./TrainingFormPage";
import { TrainingPacketFormPage } from "./TrainingPacketFormPage";
import { TrainingsPage } from "./TrainingsPage";
import { UsersPage } from "./UsersPage";

export const AdminPages: PageDefinitions = {
  groupsAndFacilities: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    isDefault: true,
    path: "/groups-and-facilities",
    title: "Groups & Facilities",
    view: GroupsAndFacilitiesPage,
  },
  // groups
  groups: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/groups",
    title: "Groups",
    view: GroupsPage,
  },
  group: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/groups",
    path: "/groups/:id",
    title: "Group Details",
    view: GroupPage,
  },
  addGroup: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/groups/new",
    title: "Add Group",
    view: GroupFormPage,
  },
  editGroup: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/groups/:id/edit",
    title: "Edit Group",
    view: GroupFormPage,
  },
  // facilities
  facilities: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/facilities",
    title: "Facilities",
    view: FacilitiesPage,
  },
  facility: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/facilities",
    path: "/facilities/:id",
    title: "Facility Details",
    view: FacilityPage,
  },
  addFacility: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/facilities/new",
    title: "Add Facility",
    view: FacilityFormPage,
  },
  editFacility: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/facilities/:id/edit",
    title: "Edit Facility",
    view: FacilityFormPage,
  },
  // regions
  regions: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/regions",
    title: "Regions",
    view: RegionsPage,
  },
  region: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/regions",
    path: "/regions/:id",
    title: "Region Details",
    view: RegionPage,
  },
  addRegion: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/regions/new",
    title: "Add Region",
    view: RegionFormPage,
  },
  editRegion: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/regions/:id/edit",
    title: "Edit Region",
    view: RegionFormPage,
  },
  // policies
  policies: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/policies",
    title: "Policies",
    view: PoliciesPage,
  },
  addPolicy: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/policies/new",
    goBackDefaultPath: "/policies",
    title: "Add Policy",
    view: PolicyPage,
  },
  editPolicy: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/policies/:id/edit",
    goBackDefaultPath: "/policies",
    title: "Edit Policy",
    view: PolicyPage,
  },
  // resources
  resources: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/resources",
    title: "Resources",
    view: ResourcesPage,
  },
  addResource: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/resources/new",
    goBackDefaultPath: "/resources",
    title: "Add Resource",
    view: ResourceFormPage,
  },
  editResource: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/resources/:id/edit",
    goBackDefaultPath: "/resources",
    title: "Edit Resource",
    view: ResourceFormPage,
  },
  // trainings
  trainings: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/trainings",
    title: "Trainings",
    view: TrainingsPage,
  },
  addTraining: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/trainings/new",
    goBackDefaultPath: "/trainings",
    title: "Add Training",
    view: TrainingFormPage,
  },
  editTraining: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/trainings/:id/edit",
    goBackDefaultPath: "/trainings",
    title: "Edit Training",
    view: TrainingFormPage,
  },
  addTrainingPacket: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/trainings/packets/new",
    goBackDefaultPath: "/trainings",
    title: "Add Training Packet",
    view: TrainingPacketFormPage,
  },
  editTrainingPacket: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/trainings/packets/:id/edit",
    goBackDefaultPath: "/trainings",
    title: "Edit Training Packet",
    view: TrainingPacketFormPage,
  },
  // audits
  audits: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/audits",
    title: "Audits",
    view: AuditsPage,
  },
  addAuditTemplate: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/audits/templates/new",
    goBackDefaultPath: "/audits",
    title: "Add Audit Template",
    view: AuditTemplateFormPage,
  },
  editAuditTemplate: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/audits/templates/:id/edit",
    goBackDefaultPath: "/audits",
    title: "Edit Audit Template",
    view: AuditTemplateFormPage,
  },
  audit: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/audits",
    path: "/audits/:id",
    title: "Audit",
    view: AuditPage,
  },
  auditCap: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/audits",
    path: "/audits/:id/cap",
    title: "Corrective Action Plan",
    view: AuditCapPage,
  },
  facilityAudits: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    goBackDefaultPath: "/audits",
    path: "/audits/facility/:id",
    title: "Facility Audits",
    view: FacilityAuditsPage,
  },
  // facility files
  facilityFiles: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    goBack: true,
    goBackDefaultPath: "/groups",
    path: "/groups/:groupId/files",
    title: "Facility Files",
    view: FacilityFilesPage,
  },
  facilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    goBack: true,
    path: "/groups/:groupId/files/:id",
    title: "Facility Files",
    view: FacilityFilePage,
  },
  addFacilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesEditClaim],
    goBack: true,
    path: "/groups/:groupId/files/new",
    title: "Add File",
    view: FacilityFileFormPage,
  },
  editFacilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesEditClaim],
    goBack: true,
    path: "/groups/:groupId/files/:id/edit",
    title: "Edit File",
    view: FacilityFileFormPage,
  },
  // users
  users: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/users",
    title: "Users",
    view: UsersPage,
  },
  // configuration
  configuration: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    path: "/config",
    title: "Configuration",
    view: ConfigurationPage,
  },
  addAnnouncement: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/config/announcements/new",
    goBackDefaultPath: "/config",
    title: "Add Announcement",
    view: AnnouncementFormPage,
  },
  editAnnouncement: {
    anon: false,
    authClaims: [PermissionClaims.CcgAdminClaim],
    goBack: true,
    path: "/config/announcements/:id/edit",
    goBackDefaultPath: "/config",
    title: "Edit Announcement",
    view: AnnouncementFormPage,
  },
};
