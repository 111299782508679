import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { WebViewerInstance } from "@pdftron/pdfjs-express";

const WebViewerContext = React.createContext<{
  viewerInstance: WebViewerInstance | undefined;
  setViewerInstance: Dispatch<SetStateAction<WebViewerInstance | undefined>>;
  viewerMode: string | undefined;
  setViewerMode: Dispatch<SetStateAction<string | undefined>>;
  viewerAnnotations: string | undefined;
  setViewerAnnotations: Dispatch<SetStateAction<string | undefined>>;
}>({
  viewerInstance: undefined,
  setViewerInstance: () => "",
  viewerMode: undefined,
  setViewerMode: () => "",
  viewerAnnotations: undefined,
  setViewerAnnotations: () => "",
});

export function WebViewerContextProvider({ children }) {
  const [viewerInstance, setViewerInstance] = useState<WebViewerInstance>();
  const [viewerMode, setViewerMode] = useState<string>();
  const [viewerAnnotations, setViewerAnnotations] = useState<string>();

  const contextValue = useMemo(
    () => ({
      viewerInstance,
      setViewerInstance,
      viewerMode,
      setViewerMode,
      viewerAnnotations,
      setViewerAnnotations,
    }),
    [viewerAnnotations, viewerInstance, viewerMode],
  );

  return (
    <WebViewerContext.Provider value={contextValue}>
      {children}
    </WebViewerContext.Provider>
  );
}

// useWebViewer hook
export function useWebViewer() {
  const context = React.useContext(WebViewerContext);
  if (context === undefined) {
    throw new Error(
      "useWebViewer must be used within a WebViewerContextProvider",
    );
  }
  return context;
}
