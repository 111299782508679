import React, { useCallback, useEffect, useState } from "react";
import DrawerMenu from "./DrawerMenu";
import AppBar from "./AppBar";
import {
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import logo from "../../../assets/img/logo_blue.svg";
import expandedLogo from "../../../assets/img/logo_full.png";
import { DrawerHeaderStyled, DrawerStyled, drawerWidth } from "./Drawer.styles";
import { maxPageWidth } from "../MainLayout.styles";
import { Link } from "react-router-dom";

export default function NavigationDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const hideMenu = useSelector(uiSelectors.hideMenu);

  const [keepMenuOpen, setKeepMenuOpen] = useState(false);

  const handleDrawerClose = useCallback(() => {
    dispatch(uiActions.setMenuOpen(false));
  }, [dispatch]);

  const handleDrawerOpen = useCallback(() => {
    dispatch(uiActions.setMenuOpen(true));
  }, [dispatch]);

  useEffect(() => {
    // if window width with the drawer open exceeds the max page width, keep the drawer open
    if (window.innerWidth - drawerWidth > maxPageWidth) {
      handleDrawerOpen();
      setKeepMenuOpen(true);
    }
  }, [handleDrawerOpen]);

  return (
    <>
      <AppBar />
      {!hideMenu && (
        <DrawerStyled
          variant="permanent"
          open={menuOpen}
          onMouseOver={keepMenuOpen ? undefined : handleDrawerOpen}
          onMouseLeave={keepMenuOpen ? undefined : handleDrawerClose}
        >
          <DrawerHeaderStyled open={menuOpen}>
            <Link to="/">
              <img
                src={menuOpen ? expandedLogo : logo}
                alt="logo"
                height="40px"
              />
            </Link>
          </DrawerHeaderStyled>
          <DrawerMenu />
        </DrawerStyled>
      )}
    </>
  );
}
