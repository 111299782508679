import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  mergePdfAnnotations,
  PdfFormViewer,
  ViewerModes,
} from "../../../../components/pdf/PdfFormViewer";
import {
  AppThunk,
  authSelectors,
  FacilityResource,
  NameValuePair,
  RequiredDocument,
  uiActions,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { RequiredDocumentSubmissionsList } from "./RequiredDocumentSubmissionsList";
import { RequiredDocumentGoverningBodyReview } from "./RequiredDocumentGoverningBodyReview";
import {
  DocumentBannerStyled,
  ViewerContainerStyled,
} from "./RequiredDocumentFormViewer.styles";
import { Box, Button, Typography } from "@mui/material";
import {
  FileUploadTypes,
  getCurrentQuarter,
  getCurrentYear,
  useFileUpload,
  useWebViewer,
} from "../../../../lib";
import {
  PermissionClaims,
  RequiredDocumentFrequencies,
} from "../../../../lib/constants";
import { SelectInput } from "../../../../components";

const currentQuarter = getCurrentQuarter();
const currentYear = getCurrentYear();

interface RequiredDocumentFormViewerProps {
  errorDisplay: JSX.Element;
  facilityId: number;
  resource: FacilityResource;
  retrieveData: () => AppThunk<Promise<string | null>>;
  saveDocument: (doc: RequiredDocument) => Promise<void>;
}

export const RequiredDocumentFormViewer = React.memo(
  /**
   *
   */
  function RequiredDocumentFormViewer({
    errorDisplay,
    facilityId,
    resource,
    retrieveData,
    saveDocument,
  }: RequiredDocumentFormViewerProps) {
    const dispatch = useAppDispatch();

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const allowReview = userPermissionClaims.includes(
      PermissionClaims.RequiredDocumentsReviewClaim,
    );
    const allowSubmission = userPermissionClaims.includes(
      PermissionClaims.RequiredDocumentsClaim,
    );

    const [reportingTimeframe, setReportingTimeframe] = useState({
      quarter: currentQuarter,
      year: currentYear,
    });

    const timeframeOptions = useMemo(() => {
      // cannot change to a past timeframe if there is already a submission for the last frequency
      if (resource.hasRequiredDocumentForLastFrequency) return [];

      if (resource.requiredFrequency == RequiredDocumentFrequencies.Annually) {
        return [currentYear, currentYear - 1].map((year) => ({
          id: year.toString(),
          name: `${year}${year === currentYear ? " (Current)" : ""}`,
          quarter: currentQuarter, // annual docs quarter is defaulted to the current quarter
          year,
        }));
      } else if (
        resource.requiredFrequency == RequiredDocumentFrequencies.Quarterly
      ) {
        const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
        const lastQuarterYear =
          currentQuarter === 1 ? currentYear - 1 : currentYear;
        return [
          { quarter: currentQuarter, year: currentYear },
          {
            quarter: lastQuarter,
            year: lastQuarterYear,
          },
        ].map(({ quarter, year }) => ({
          id: `${quarter}-${year}`,
          name: `Quarter ${quarter} ${year}${
            quarter === currentQuarter ? " (Current)" : ""
          }`,
          quarter,
          year,
        }));
      }

      return [];
    }, [
      resource.hasRequiredDocumentForLastFrequency,
      resource.requiredFrequency,
    ]);

    const [governingBodyReviewed, setGoverningBodyReviewed] = useState(false);

    // reset governingBodyReviewed when facilityId changes
    useEffect(() => {
      setGoverningBodyReviewed(false);
    }, [facilityId]);

    const { viewerInstance } = useWebViewer();

    const uploadFile = useFileUpload("required document");

    const handleSave = useCallback(
      async (_, fields: NameValuePair[]) => {
        if (!viewerInstance) {
          return;
        }

        dispatch(uiActions.setLoading(true));

        try {
          const mergedAudit = await mergePdfAnnotations(viewerInstance);

          const uploadUrl = await uploadFile(
            mergedAudit,
            FileUploadTypes.RequiredDocuments,
            true,
          );

          const { quarter, year } = reportingTimeframe;

          await saveDocument({
            resourceID: resource.id,
            facilityID: facilityId,
            url: uploadUrl,
            fields,
            quarter,
            year,
          });
        } catch (err) {
          console.error("Error merging required document file: ", err);
          dispatch(uiActions.showError("Failed to upload document"));
        }

        dispatch(uiActions.setLoading(false));
      },
      [
        dispatch,
        facilityId,
        reportingTimeframe,
        resource.id,
        saveDocument,
        uploadFile,
        viewerInstance,
      ],
    );

    // prefill form based on latest document submission fields
    const [fieldPrefills, setFieldPrefills] = useState(
      resource.latestRequiredDocumentFields.reduce((obj, field) => {
        obj[field.name] = field.value;
        return obj;
      }, {}),
    );
    const hasFieldPrefills = Object.keys(fieldPrefills).length > 0;

    const checkGoverningBodyReview =
      allowSubmission && resource.hasReviewWorkflow && !governingBodyReviewed;

    return checkGoverningBodyReview ? ( // avoid loading the document until governingBodyReview was checked
      <RequiredDocumentGoverningBodyReview
        facilityId={facilityId}
        onReviewed={() => setGoverningBodyReviewed(true)}
        resourceName={resource.name}
      />
    ) : (
      <>
        <ViewerContainerStyled>
          <PdfFormViewer
            customButtons={
              allowSubmission && hasFieldPrefills ? (
                <DocumentBannerStyled>
                  <Typography variant="body2" mr={2}>
                    This form is pre-filled based on the latest submission.
                    Please review all answers and update them as needed.
                  </Typography>
                  <Button color="warning" onClick={() => setFieldPrefills([])}>
                    Clear all fields
                  </Button>
                </DocumentBannerStyled>
              ) : undefined
            }
            fieldPrefills={allowSubmission ? fieldPrefills : undefined}
            errorDisplay={errorDisplay}
            handleSave={handleSave}
            name="required document"
            key={hasFieldPrefills ? "prefilled" : "new"} // reload form viewer when prefills are reset
            retrieveData={retrieveData}
            showSave={false}
            showSubmit={true}
            submitConfirmationTag={
              timeframeOptions.length ? (
                <Box mt="24px" textAlign="left">
                  <Typography variant="subtitle2" maxWidth="340px" mb="16px">
                    Choose which quarter the document is reporting on
                  </Typography>
                  <SelectInput
                    clearable={false}
                    name="quarter"
                    onChange={(_, val) => {
                      const timeframeSelection = timeframeOptions.find(
                        (o) => o.id == val,
                      );
                      if (timeframeSelection)
                        setReportingTimeframe({
                          quarter: timeframeSelection.quarter,
                          year: timeframeSelection.year,
                        });
                    }}
                    options={timeframeOptions}
                    value={
                      timeframeOptions.find(
                        (o) =>
                          o.quarter === reportingTimeframe.quarter &&
                          o.year == reportingTimeframe.year,
                      )?.id || ""
                    }
                  />
                </Box>
              ) : undefined
            }
            validateRequiredFields={true}
            viewerMode={
              allowSubmission ? ViewerModes.Edit : ViewerModes.ReadOnly
            }
          />
          {(allowSubmission || allowReview) && (
            <RequiredDocumentSubmissionsList
              allowReview={allowReview}
              resource={resource}
            />
          )}
        </ViewerContainerStyled>
      </>
    );
  },
);
