import React from "react";
import { TabbedView } from "../../components";
import { AnnouncementsList } from "./components";

const tabs = [
  {
    id: "announcements",
    name: "Announcements",
    component: AnnouncementsList,
  },
];

export const ConfigurationPage = React.memo(
  /**
   *
   */
  function ConfigurationPage() {
    return <TabbedView tabs={tabs} />;
  },
);
