import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import {
  BasicFacility,
  sysActions,
  sysSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { ErrorIcon, SelectInput } from "../../../components";
import { asArray, Navigation, useLocation } from "../../../lib";
import { paginationQueryKey } from "../../../components/lists/PaginationHelpers";
import { FacilityStatuses } from "../../../lib/constants";

const incompleteProfileFacilityStatuses = [
  FacilityStatuses.Pending,
  FacilityStatuses.Review,
];

export default function FacilityFilter() {
  const dispatch = useDispatch();
  const location = useLocation();

  const facilityFilter = useSelector(sysSelectors.facilityFilter);
  const groupFilter = useSelector(sysSelectors.groupFilter);

  const hasMultipleGroups = useSelector(sysSelectors.activeGroups).length > 1;

  const activeFacilities = useSelector(sysSelectors.activeFacilities);
  const facilityOptions = useMemo(
    () =>
      groupFilter?.id
        ? activeFacilities.filter((f) => f.groupID == groupFilter.id)
        : activeFacilities,
    [activeFacilities, groupFilter?.id],
  );

  // manage selected facility id locally to allow for comparison when the filter is updated externally (in order to apply necessary query updates)
  const [selectedFacilityId, setSelectedFacilityId] = useState(
    facilityFilter?.id,
  );

  const onSelectFacility = useCallback(
    async (facility?: BasicFacility, setFilter = true) => {
      setSelectedFacilityId(facility?.id);

      if (setFilter) {
        dispatch(sysActions.setFacilityFilter(facility));
      }

      // reset specific query params when facility is changed: page (for lists), facilities (for users/policies lists), showPast (for audits list)
      const resetPage = (location.query[paginationQueryKey.page] as number) > 1;
      const resetFacilitiesFilter =
        location.query.facilities &&
        asArray(location.query.facilities?.toString())?.length;
      const resetShowPast = location.query.showPast;
      if (resetPage || resetFacilitiesFilter || resetShowPast) {
        Navigation.replace(location.pathname, {
          query: {
            ...location.query,
            page: "",
            facilities: "",
            showPast: "",
          },
        });
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [dispatch, location.pathname, location.query],
  );

  // update local selectedFacilityId state and run reset query effects if facility filter was set/cleared externally
  useEffect(() => {
    if (facilityFilter?.id !== selectedFacilityId) {
      onSelectFacility(facilityFilter, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityFilter?.id]);

  return facilityOptions?.length ? (
    <>
      {!facilityFilter || facilityOptions.length > 1 ? ( // !facilityFilter check to support EDGE case where a multi-group user has access to only one facility across multiple groups, and clears out their group selection via the GroupFilter (which will clear the auto-set facility selection)
        <SelectInput
          autocomplete={facilityOptions.length > 10}
          clearable={false}
          fitToContentWidth={true}
          groupOpts={hasMultipleGroups && !groupFilter?.id}
          inputProps={{
            disableUnderline: true,
          }}
          name="facilityId"
          onChange={(_, id) =>
            onSelectFacility(facilityOptions.find((f) => f.id === id))
          }
          options={[{ id: "all", name: "All facilities" }, ...facilityOptions]}
          value={selectedFacilityId || "all"}
          variant="standard"
        />
      ) : (
        <Typography>{facilityFilter?.name}</Typography>
      )}
      {facilityFilter?.status &&
        incompleteProfileFacilityStatuses.includes(facilityFilter.status) && (
          <Tooltip
            title={`Policies and Resources will not be accessible for this facility because it is ${
              facilityFilter.status === FacilityStatuses.Review
                ? "under profile review"
                : "profile pending"
            }.`}
          >
            <ErrorIcon fontSize="small" sx={{ color: "error.dark", ml: 1 }} />
          </Tooltip>
        )}
    </>
  ) : null;
}
