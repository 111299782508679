import React, { useMemo } from "react";
import {
  adminActions,
  authSelectors,
  facilityAdminActions,
  sysSelectors,
  useSelector,
} from "../../../../state";
import { Button, Typography } from "@mui/material";
import { Navigation, RouterLink } from "../../../../lib";
import { PdfViewer } from "../../../../components";
import { FacilityAdminPages } from "../../../facilityAdmin";
import { PermissionClaims, PortalUserTypes } from "../../../../lib/constants";
import { CopyLinkButton } from "../../../../components/buttons/CopyLinkButton";

export interface PolicyPreviewProps {
  facilityId: number | null; // null allowed when policyVersionId is set
  facilityGroupId?: number; // for determining allowPrint
  policyId: number;
  policyVersionId?: number; // for admin preview of version template
  printOnly?: boolean;
  printToolbarAddon?: JSX.Element;
}

export const PolicyPreview = React.memo(
  /**
   *
   */
  function PolicyPreview({
    facilityId,
    facilityGroupId,
    policyId,
    policyVersionId,
    printOnly,
    printToolbarAddon,
  }: PolicyPreviewProps) {
    const userType = useSelector(authSelectors.userType);
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);

    const activeGroups = useSelector(sysSelectors.activeGroups);
    const hasMultipleFacilities =
      useSelector(sysSelectors.activeFacilities).length > 1;

    const retrievePolicyData = useMemo(() => {
      if (policyVersionId) {
        return () =>
          adminActions.getPolicyVersionPreview(policyId, policyVersionId);
      } else {
        return () =>
          facilityAdminActions.getPolicyPreview(policyId, Number(facilityId));
      }
    }, [facilityId, policyId, policyVersionId]);

    const allowPrint = useMemo(() => {
      if (userType !== PortalUserTypes.FacilityAdmin) return false;

      const hasPrintingOverridePermission = userPermissionClaims.includes(
        PermissionClaims.PoliciesPrintingOverrideClaim,
      );

      return (
        hasPrintingOverridePermission ||
        !activeGroups.find((g) => g.id == facilityGroupId)
          ?.policyPrintingDisabled
      );
    }, [activeGroups, facilityGroupId, userPermissionClaims, userType]);

    return (
      <PdfViewer
        allowPrint={allowPrint}
        errorDisplay={
          <>
            <Typography variant="body2" mt={1} mb={4}>
              {policyVersionId
                ? "Check that the policy version URL is set to a valid template"
                : `This policy may not be available for your facility${
                    hasMultipleFacilities ? " selection" : ""
                  }`}
            </Typography>
            {!policyVersionId && !printOnly && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={RouterLink}
                to="/policies"
              >
                Go to policies dashboard
              </Button>
            )}
          </>
        }
        name="policy"
        printOnly={printOnly}
        toolbarAddon={
          printToolbarAddon ||
          (userType === PortalUserTypes.FacilityAdmin ? (
            <CopyLinkButton
              tooltip="Copy policy link to share with other users."
              url={Navigation.fullUrl(FacilityAdminPages.policy, {
                params: { id: policyId },
                query: { facility: facilityId },
              })}
            />
          ) : undefined)
        }
        retrieveData={retrievePolicyData}
      />
    );
  },
);
