import React, { useCallback, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  FacilityFileCategory,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DeleteIcon,
  DialogListItemStyled,
  EditIcon,
} from "../../../../../components";
import { CategoryDialog } from "./CategoryDialog";

const ActionTypes = {
  Add: "Add",
  Delete: "Delete",
  Edit: "Edit",
};

interface ManageCategoriesDialogProps {
  groupId: number;
  handleClose: () => void;
}

export const ManageCategoriesDialog = React.memo(
  /**
   *
   */
  function ManageCategoriesDialog({
    groupId,
    handleClose,
  }: ManageCategoriesDialogProps) {
    const dispatch = useAppDispatch();

    const [activeActionType, setActiveActionType] = useState<string>();
    const [activeCategory, setActiveCategory] =
      useState<FacilityFileCategory>();

    const onActivateAction = useCallback(
      (actionType: string, category?: FacilityFileCategory) => {
        setActiveActionType(actionType);
        setActiveCategory(category);
      },
      [],
    );

    const onClearAction = useCallback(() => {
      setActiveActionType(undefined);
      setActiveCategory(undefined);
    }, []);

    const onConfirmDeleteCategory = useCallback(async () => {
      const deleted = await dispatch(
        sharedActions.deleteFacilityFileCategory(
          Number(activeCategory?.id),
          groupId,
        ),
      );
      if (deleted) {
        onClearAction();
      }
    }, [activeCategory?.id, dispatch, groupId, onClearAction]);

    const facilityFileCategories = useSelector(
      sysSelectors.facilityFileCategories,
    );

    return activeActionType === ActionTypes.Add ||
      activeActionType === ActionTypes.Edit ? (
      <CategoryDialog
        category={activeCategory}
        groupId={groupId}
        handleClose={onClearAction}
      />
    ) : activeActionType === ActionTypes.Delete ? (
      <ConfirmationDialog
        confirmText="Delete category"
        handleClose={onClearAction}
        handleConfirm={onConfirmDeleteCategory}
        message="This cannot be undone."
        open={true}
        title="Delete category?"
        type={ConfirmationDialogTypes.Alert}
      />
    ) : (
      <Dialog open={true} PaperProps={{ sx: { width: "600px" } }}>
        <DialogTitle variant="h6">Manage categories</DialogTitle>
        <DialogContent>
          {!facilityFileCategories.length ? (
            <Typography
              color="text.secondary"
              sx={{ textAlign: "center", padding: "24px" }}
            >
              No categories found
            </Typography>
          ) : (
            <List>
              {facilityFileCategories
                .filter((c) => c.groupID === groupId)
                .map((category) => (
                  <DialogListItemStyled key={category.id}>
                    <Typography>{category.name}</Typography>
                    <Box display="flex">
                      <Tooltip
                        title={
                          category.hasFiles
                            ? "A category with linked files cannot be deleted"
                            : ""
                        }
                      >
                        <Box>
                          <IconButton
                            disabled={category.hasFiles}
                            onClick={() =>
                              onActivateAction(ActionTypes.Delete, category)
                            }
                            sx={{ color: "text.secondary" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      <IconButton
                        onClick={() =>
                          onActivateAction(ActionTypes.Edit, category)
                        }
                        sx={{ color: "text.secondary" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </DialogListItemStyled>
                ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => setActiveActionType(ActionTypes.Add)}
          >
            Add new
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
