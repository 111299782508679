import React from "react";
import { Box, Typography } from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../../components";

interface GroupRegionChangeConfirmationDetailsProps {
  groupChange?: boolean;
  regionChange?: boolean;
  initialRegionID?: number | null;
  tooltip?: boolean;
}

export function GroupRegionChangeConfirmationDetails({
  groupChange,
  regionChange,
  initialRegionID,
  tooltip,
}: GroupRegionChangeConfirmationDetailsProps) {
  return (
    <Box
      textAlign="left"
      {...(tooltip ? { sx: { "& p": { fontSize: "unset" } } } : "")}
    >
      {groupChange ? (
        <>
          <Typography variant="body2" mb="8px">
            • Group
            {initialRegionID ? " and Region " : " "}
            level users that currently have access to this facility will lose
            access, and Group level users in the new group will gain access to
            the facility.
          </Typography>
          <Typography variant="body2" mb="8px">
            • Facility level users
            {initialRegionID
              ? ", and Region level users with facility-specific access to this facility, "
              : " "}
            will maintain facility access. If these users have access to other
            facilities in this group, they will maintain access to facilities
            across both groups.
          </Typography>
          <Typography variant="body2" mb="8px">
            • All facility files assigned to this facility will no longer be
            available for the facility. Files that are assigned to only this
            facility will need to be reassigned or deactivated by a CCG Admin.
          </Typography>
        </>
      ) : regionChange ? (
        <Typography variant="body2">
          {initialRegionID
            ? "Region level users that currently have access to this facility will lose access, and "
            : " "}
          Region level users in the new region will gain access to the facility
        </Typography>
      ) : null}
    </Box>
  );
}

interface GroupRegionChangeConfirmationDialogProps
  extends GroupRegionChangeConfirmationDetailsProps {
  handleClose: () => void;
  handleConfirm: () => void;
}

export const GroupRegionChangeConfirmationDialog = React.memo(
  /**
   *
   */
  function GroupRegionChangeConfirmationDialog({
    handleClose,
    handleConfirm,
    groupChange,
    regionChange,
    initialRegionID,
  }: GroupRegionChangeConfirmationDialogProps) {
    return (
      <ConfirmationDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        open={true}
        messageContent={
          <GroupRegionChangeConfirmationDetails
            groupChange={groupChange}
            regionChange={regionChange}
            initialRegionID={initialRegionID}
          />
        }
        title={`Change ${
          groupChange ? "Group" : regionChange ? "Region" : ""
        }?`}
        type={ConfirmationDialogTypes.Warning}
      />
    );
  },
);
