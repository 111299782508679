import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import { Navigation, RouterLink, usePageTitle } from "../../../../lib";
import { sysSelectors } from "../../../../state";
import { StatusPage } from "../../../shared/components";
import { AdminPages } from "../..";
import facility from "../../../../assets/img/facility.svg";

export const GroupFacilityFilesAccessWrapper = React.memo(
  /**
   *
   */
  function GroupFacilityFilesAccessWrapper({
    children,
  }: {
    children: JSX.Element;
  }) {
    const params = useParams();

    const setPageTitle = usePageTitle();

    const groupOptions = useSelector(sysSelectors.allGroups);

    const groupSelection = useMemo(
      () => groupOptions.find((g) => g.id == Number(params.groupId)),
      [groupOptions, params.groupId],
    );

    useEffect(() => {
      if (groupSelection) {
        setPageTitle({
          title: `${groupSelection.name} files`,
        });
      }
    }, [groupSelection, setPageTitle]);

    return !groupSelection?.facilityFilesEnabled ? (
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="The Facility Files add-on is not enabled for this group"
        subtitle={
          <>
            Enable the setting{" "}
            <Link
              component={RouterLink}
              to={Navigation.url(AdminPages.group, {
                params: { id: params.groupId },
              })}
              sx={{ fontSize: "inherit" }}
            >
              here
            </Link>
          </>
        }
      />
    ) : (
      children
    );
  },
);
