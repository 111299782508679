import "./polyfill";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { authActions, persistor, store } from "./state";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { REACT_APP_MUI_X_LICENSE_KEY } from "./config";

// Example to use service worker / progressive web app (PWA):
// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import { defaultTheme } from "./themes";
// import "./assets/css/styles.css";

import { App } from "./App";
import { SystemData } from "./SystemData";
import { ErrorSentry } from "./components";

// license mui/x-data-grid-pro
if (REACT_APP_MUI_X_LICENSE_KEY)
  LicenseInfo.setLicenseKey(REACT_APP_MUI_X_LICENSE_KEY);

// NOTE: The `store` already comes preloaded slices that were persisted.

/**
 * Startup function.
 */
function main() {
  render();
  registerServiceWorker();
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
function registerServiceWorker() {
  // Example to implement register service worker:
  // serviceWorker.register(serviceWorkerConfig);
}

function render() {
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);
  root.render(
    <ErrorSentry>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReduxProvider store={store}>
            <PersistGate
              persistor={persistor}
              // configure auth after store is persisted
              onBeforeLift={() => store.dispatch(authActions.load({}))}
            >
              <CssBaseline />
              <SystemData />
              <App />
            </PersistGate>
          </ReduxProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ErrorSentry>,
  );
}

main();
