import React, { useEffect, useRef } from "react";

interface FadeOutTextProps {
  onTimeout: () => void;
  textComponent: JSX.Element;
  timeout?: number;
}

export const FadeOutText = React.memo(
  /**
   *
   */
  function FadeOutText({
    onTimeout,
    textComponent,
    timeout,
  }: FadeOutTextProps) {
    const isMountedRef = useRef(false);

    useEffect(() => {
      isMountedRef.current = true;

      setTimeout(() => {
        if (isMountedRef) {
          onTimeout();
        }
      }, timeout || 4000);

      return () => {
        isMountedRef.current = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return textComponent;
  },
);
