import React from "react";
import { Backdrop, CircularProgress, SxProps, Theme } from "@mui/material";

interface BackdropLoaderProps {
  loading: boolean;
  size?: number;
  sx?: SxProps<Theme>;
}

export const BackdropLoader = React.memo(
  /**
   *
   */
  function BackdropLoader({ loading, size = 128, sx }: BackdropLoaderProps) {
    return (
      <Backdrop open={loading} sx={sx} unmountOnExit>
        <CircularProgress size={size} />
      </Backdrop>
    );
  },
);
