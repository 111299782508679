import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BasicStatuses } from "../../lib/constants";

interface BasicStatusChipStyledProps extends ChipProps {
  status?: string;
}

export const BasicStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<BasicStatusChipStyledProps>(({ theme, status }) => ({
  color: theme.palette.background.default,
  backgroundColor:
    status === BasicStatuses.Active
      ? theme.palette.primary.light
      : theme.palette.neutral.main,
}));
