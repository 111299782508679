import React from "react";
import { AuditTemplateForm } from "./components";

export const AuditTemplateFormPage = React.memo(
  /**
   *
   */
  function AuditTemplateFormPage() {
    return <AuditTemplateForm />;
  },
);
