import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { REACT_APP_SITE_TITLE } from "../../config";
import {
  authSelectors,
  uiActions,
  uiSelectors,
  useAppDispatch,
  useSelector,
} from "../../state";
import { useLocation } from "react-router-dom";
import { Navigation } from ".";
import { PageDefinition, PageDefinitions } from "../types";

function Page(props: Omit<PageDefinition, "path">) {
  const { view: Component, title, anon, authClaims } = props;

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const userPermissionClaims = useSelector(authSelectors.permissionClaims);

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(uiActions.setPagePath(location.pathname));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (anon !== true) {
      if (!isLoggedIn) {
        Navigation.go(
          "/auth/login?after=" + location.pathname + location.search,
        );
      } else if (authClaims?.length) {
        const unauthorized = !authClaims.some((claim) =>
          userPermissionClaims.includes(claim),
        );
        if (unauthorized) {
          Navigation.replace("/unauthorized");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anon, authClaims, isLoggedIn, location.pathname, userPermissionClaims]);

  const uiPageTitle = useSelector(uiSelectors.pageTitle);

  const titleDisplay = uiPageTitle?.title || title;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {titleDisplay ? `${titleDisplay} | ` : ""}
          {REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <Component />
    </>
  );
}

export function renderPageRoutes(pages: PageDefinitions) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  return Object.keys(pages).map((pageName) => {
    const { path, ...rest } = pages[pageName];
    return (
      <Route {...rest} key={path} path={path} element={<Page {...rest} />} />
    );
  });
}
