import { styled } from "@mui/material/styles";
import { appBarHeight, facilityHeaderHeight } from "./drawer/AppBar.styles";

export const maxPageWidth = 2000;

export const MainContentStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface MainPageStyledProps {
  withHeader?: boolean;
  withSubtitle?: boolean;
}

export const MainPageStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "withHeader" && prop !== "withSubtitle",
})<MainPageStyledProps>(({ theme, withHeader, withSubtitle }) => {
  const topOffset = appBarHeight + (withHeader ? facilityHeaderHeight : 0);
  return {
    height: `calc(100vh - ${topOffset}px)`,
    marginTop: topOffset,
    width: "100%",
    maxWidth: maxPageWidth,
    display: "flex",
    flexDirection: "column",
    padding: `${withSubtitle ? 0 : 20}px 24px 0`,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 2px)",
      padding: "4px 4px 0 4px",
    },
  };
});
