import React from "react";
import { Field, FieldAttributes } from "formik";
import { TextField as FormikTextField } from "formik-mui";

export const TextField = React.memo(
  /**
   *
   */
  function TextField({
    autofill,
    fullWidth = true,
    type,
    ...rest
  }: FieldAttributes<any>) {
    return (
      <Field
        autoComplete={
          autofill
            ? "on"
            : type === "password"
            ? "new-password"
            : "one-time-code"
        }
        // autocomplete="off" option is not supported by browsers
        // Chrome workaround is the "one-time-code" option - see https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        // Firefox requires "new-password" to turn off autofill on password fields https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
        component={FormikTextField}
        fullWidth={fullWidth}
        size="small"
        type={type}
        {...rest}
      />
    );
  },
);
