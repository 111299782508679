import { Box, Tab, Tabs } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Navigation, useLocation } from "../../lib";

export interface TabProps {
  id: string;
  name: string | JSX.Element;
  component: React.NamedExoticComponent<any>;
}

export interface TabbedViewProps {
  disableTab?: (tabId: string) => boolean;
  hideTab?: (tabId: string) => boolean;
  setDefaultTab?: boolean;
  tabs: TabProps[];
  viewProps?: object;
}

export const TabbedView = React.memo(
  /**
   *
   */
  function TabbedView({
    disableTab,
    hideTab,
    setDefaultTab,
    tabs,
    viewProps = {},
  }: TabbedViewProps) {
    const location = useLocation();
    const {
      query: { tab: tabId },
    } = location;

    const activeTab = tabs.find((t) => t.id === tabId?.toString()) || tabs[0];
    const TabComponent = activeTab?.component;

    useEffect(() => {
      if (setDefaultTab && !tabId && tabs.length) {
        Navigation.replace(location.pathname, {
          query: { tab: tabs[0].id },
        });
      }
    }, [activeTab, location.pathname, setDefaultTab, tabId, tabs]);

    const handleTabChange = useCallback(
      (_, tab) => {
        Navigation.replace(location.pathname, { query: { tab } });
      },
      [location.pathname],
    );

    return (
      <>
        <Box borderBottom="1px solid" borderColor="divider">
          <Tabs
            value={activeTab?.id}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs
              .filter((t) => !hideTab || !hideTab(t.id))
              .map((t) => (
                <Tab
                  key={t.id}
                  label={t.name}
                  value={t.id}
                  disabled={disableTab ? disableTab(t.id) : false}
                />
              ))}
          </Tabs>
        </Box>
        {setDefaultTab && !tabId ? null : (
          <TabComponent key={tabId} {...viewProps} />
        )}
      </>
    );
  },
);
