import { styled } from "@mui/material/styles";

export const ViewerContainerStyled = styled(`div`)(() => ({
  display: "flex",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

interface AuditBannerStyledProps {
  warning?: boolean;
  withAction?: boolean;
}

export const AuditBannerStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "warning" && prop !== "withAction",
})<AuditBannerStyledProps>(({ theme, warning, withAction }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: withAction ? "space-between" : "normal",
  backgroundColor: warning ? "#FFEAE9" : "#EEF6FB",
  borderRadius: "3px",
  margin: "8px 0",
  padding: "12px",
  width: "100%",
  "& svg": {
    color: warning ? theme.palette.error.main : theme.palette.primary.light,
  },
}));
