import React, { useEffect } from "react";
import {
  authSelectors,
  sysActions,
  sysSelectors,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import MobileDrawer from "./drawer/MobileDrawer";
import NavigationDrawer from "./drawer/NavigationDrawer";
import Notification from "../../components/Notification";
import { BackdropLoader } from "../../components";
import { Outlet } from "react-router-dom";
import { MainContentStyled, MainPageStyled } from "./MainLayout.styles";
import { collapsedDrawerWidth, drawerWidth } from "./drawer/Drawer.styles";
import { Navigation, useLocation } from "../../lib";
import { PortalUserTypes } from "../../lib/constants";

interface MainLayoutProps {
  defaultPath?: string;
}

export const MainLayout = React.memo(
  /**
   *
   */
  function MainLayout({ defaultPath }: MainLayoutProps) {
    const dispatch = useDispatch();
    const location = useLocation();
    const loading = useSelector(uiSelectors.loading);
    const menuOpen = useSelector(uiSelectors.menuOpen);
    const hideMenu = useSelector(uiSelectors.hideMenu);
    const isMobile = false; // useMobile(); - we are not handling mobile responsive layout for now

    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const systemHydrated = useSelector(sysSelectors.systemHydrated);
    const noOutlet = isLoggedIn && !systemHydrated;

    const userType = useSelector(authSelectors.userType);

    const pageHasSubtitle = useSelector(uiSelectors.pageHasSubtitle);
    const facilityHeaderShown = useSelector(uiSelectors.showFacilityHeader);

    const facilityOptions = useSelector(sysSelectors.activeFacilities);
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    // redirect to default page if no route is specified
    useEffect(() => {
      if (location.pathname === "/" && location.pathname !== defaultPath) {
        Navigation.replace(defaultPath);
      }
    }, [defaultPath, location.pathname]);

    // auto-set the facility filter for single-facility facility users
    useEffect(() => {
      const isFacilityUser =
        userType === PortalUserTypes.FacilityAdmin ||
        userType === PortalUserTypes.FacilityStaff;
      if (
        isFacilityUser &&
        facilityOptions.length === 1 &&
        facilityFilter?.id !== facilityOptions[0].id
      ) {
        dispatch(sysActions.setFacilityFilter(facilityOptions[0]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityOptions.length]);

    const drawerOffset =
      isMobile || hideMenu
        ? 0
        : menuOpen
        ? `${drawerWidth}px`
        : `${collapsedDrawerWidth}px`;

    return (
      <>
        {isMobile ? <MobileDrawer /> : <NavigationDrawer />}
        <MainContentStyled sx={{ ml: drawerOffset }}>
          <MainPageStyled
            withHeader={facilityHeaderShown}
            withSubtitle={pageHasSubtitle}
          >
            {noOutlet ? null : <Outlet />}
          </MainPageStyled>
        </MainContentStyled>
        <Notification />
        <BackdropLoader
          loading={loading}
          sx={(theme) => ({
            marginLeft: drawerOffset, // prevent backdrop from covering drawer menu
            zIndex: theme.zIndex.tooltip + 1, // max zIndex
          })}
        />
      </>
    );
  },
);
