import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  facilityAdminActions,
  FacilityResource,
  RequiredDocument,
  sysSelectors,
  uiActions,
  useAppDispatch,
  useSelector,
} from "../../state";
import { Box, Link, Typography } from "@mui/material";
import { PdfViewer } from "../../components";
import { useParams } from "react-router-dom";
import {
  Navigation,
  RouterLink,
  useLocation,
  usePageTitle,
  useSetFacilityFilter,
  WebViewerContextProvider,
} from "../../lib";
import { ResourceTypes } from "../../lib/constants";
import { StatusPage } from "../shared/components";
import {
  RequiredDocumentFormViewer,
  RequiredDocumentReview,
} from "./components";
import { ResourceHeaderStyled } from "./ResourcePage.styles";
import { FacilityAdminPages } from ".";
import facility from "../../assets/img/facility.svg";

export const ResourcePage = React.memo(
  /**
   *
   */
  function ResourcePage() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();
    const queryFacilityId = location.query.facility;
    const queryReviewDocumentId = location.query.review;

    // if there is a facility id in the url query (from a shared resource link), apply it as the system facility filter so that the user can view the resource for the correct facility
    const facilityFilter = useSetFacilityFilter(queryFacilityId);

    const hasMultipleFacilities =
      useSelector(sysSelectors.activeFacilities).length > 1;

    const setPageTitle = usePageTitle();

    const [resourceLoaded, setResourceLoaded] = useState(false); // track if resource was loaded to avoid mounting the viewer until resource retrieval completed/failed
    const [resource, setResource] = useState<FacilityResource>();

    const fetchResource = useCallback(async () => {
      if (facilityFilter?.id) {
        const resource = await dispatch(
          facilityAdminActions.getResource(
            Number(params.id),
            facilityFilter.id,
          ),
        );
        if (resource) {
          setResource(resource);
          setPageTitle({
            title: resource.name,
          });
        } else {
          setResource(undefined);
        }

        setResourceLoaded(true);
      }
    }, [dispatch, facilityFilter?.id, params.id, setPageTitle]);

    useEffect(() => {
      (async () => {
        if (params.id) {
          fetchResource();
        }
      })();
    }, [fetchResource, params.id]);

    useEffect(() => {
      dispatch(uiActions.setPageHasSubtitle(true));
      return () => {
        dispatch(uiActions.setPageHasSubtitle(false));
      };
    }, [dispatch]);

    const retrieveResourceData = useMemo(
      () => () =>
        facilityAdminActions.getResourcePreview(
          Number(params.id),
          Number(facilityFilter?.id),
        ),
      [facilityFilter?.id, params.id],
    );

    const saveRequiredDocument = useCallback(
      async (requiredDocument: RequiredDocument) => {
        if (!resource) return;

        const saved = await dispatch(
          facilityAdminActions.submitRequiredDocument(requiredDocument),
        );

        if (saved) {
          Navigation.goBack(FacilityAdminPages.requiredDocuments.path);
        }
      },
      [dispatch, resource],
    );

    return queryFacilityId ? null : !facilityFilter?.id ? ( // don't show anything when there is a query facility id that has not yet been applied; show status page when there is no system facility filter
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="Select a facility from the blue bar at the top of the screen to view this resource"
      />
    ) : resourceLoaded ? (
      <>
        {resource && (
          <ResourceHeaderStyled>
            {resource.policies.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                mb={resource.description ? 1 : 0}
              >
                <Typography variant="caption" color="text.secondary" mr={1}>
                  Related Policy
                </Typography>
                {resource.policies.map((p, i) => (
                  <Fragment key={p.id}>
                    <Link
                      component={RouterLink}
                      to={Navigation.url(FacilityAdminPages.policy, {
                        params: { id: p.id },
                      })}
                      sx={{ fontSize: "12px" }}
                    >
                      {p.title.replace(p.name, "").trim()}
                    </Link>
                    {i < resource.policies.length - 1 && (
                      <Typography variant="caption" mr={0.5}>
                        ,
                      </Typography>
                    )}
                  </Fragment>
                ))}
              </Box>
            )}
            {resource.description && (
              <Typography variant="body2">{resource.description}</Typography>
            )}
          </ResourceHeaderStyled>
        )}
        {resource?.type === ResourceTypes.RequiredDocument ? (
          resource?.hasReviewWorkflow && queryReviewDocumentId ? (
            <RequiredDocumentReview
              documentId={Number(queryReviewDocumentId)}
              facilityId={facilityFilter.id}
            />
          ) : (
            <WebViewerContextProvider>
              <RequiredDocumentFormViewer
                errorDisplay={
                  <>
                    <Typography variant="body2" mt={1} mb={4}>
                      This resource may not be available for your facility
                      {hasMultipleFacilities ? " selection" : ""}
                    </Typography>
                  </>
                }
                facilityId={facilityFilter.id}
                resource={resource}
                retrieveData={retrieveResourceData}
                saveDocument={saveRequiredDocument}
              />
            </WebViewerContextProvider>
          )
        ) : (
          <PdfViewer
            allowDownload={true}
            allowPrint={true}
            downloadFileName={resource?.name}
            errorDisplay={
              <Typography variant="body2" mt={1} mb={4}>
                This resource may not be available for your facility
                {hasMultipleFacilities ? " selection" : ""}
              </Typography>
            }
            name="resource"
            retrieveData={retrieveResourceData}
          />
        )}
      </>
    ) : null;
  },
);
