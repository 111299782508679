import {
  AppBarProps,
  Avatar,
  Box,
  Chip,
  ChipProps,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { maxPageWidth } from "../MainLayout.styles";
import helpDialogBackground from "../../../assets/img/company_background.svg";
import { drawerWidth } from "./Drawer.styles";
export { facilityHeaderHeight } from "./FacilityHeader";

export const appBarHeight = 64;

interface TopBarStyledProps extends AppBarProps {
  open?: boolean;
}

export const TopBarStyled = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<TopBarStyledProps>(({ theme, open }) => ({
  maxWidth: maxPageWidth,
  position: "fixed",
  top: "0px",
  zIndex: theme.zIndex.appBar,
  borderRadius: "0px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const ToolbarStyled = styled(Toolbar)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: appBarHeight,
  padding: "20px 24px 0px",
}));

export const ToolbarTitleStyled = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginRight: "24px",
}));

export const SearchContainerStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.main}`,
  borderRadius: "3px",
  padding: "0px 8px",
  minWidth: "300px",
  "& .MuiInputBase-root": {
    height: "34px !important",
  },
  "& input": {
    fontSize: "16px",
    paddingBottom: "0",
  },
}));

export const HelpDialogContainerStyled = styled(`div`)(() => ({
  width: "442px",
  height: "552px",
  backgroundImage: `url(${helpDialogBackground})`,
  padding: 80,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  " > *": {
    maxWidth: "296px",
  },
}));

export const HotlineInfoContainerStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: "12px 24px",
  display: "flex",
  alignItems: "center",
  "& p": { whiteSpace: "nowrap", marginLeft: "8px" },
}));

interface TitleChipStyledProps extends ChipProps {
  backgroundColor?: string;
}

export const TitleChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<TitleChipStyledProps>(({ theme, backgroundColor }) => ({
  marginLeft: "16px",
  color: theme.palette.background.default,
  backgroundColor,
}));

export const ProfileAvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "32px",
  height: "32px",
  fontSize: "16px",
  backgroundColor: theme.palette.error.light,
  cursor: "pointer",
  marginLeft: "16px",
}));

export const StagingBannerStyled = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 2,
  position: "fixed",
  right: "6px",
  top: "6px",
  width: "600px",
  height: "40px",
  fontSize: "14px",
  fontWeight: "bold",
  padding: "6px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.neutral.extraLight,
  backgroundColor: theme.palette.error.dark,
  "& > div": {
    display: "flex",
    alignItems: "center",
  },
  "& svg": {
    color: theme.palette.neutral.extraLight,
    fontSize: "16px",
  },
}));
