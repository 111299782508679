import React, { useState } from "react";
import { phoneSchema } from "../../../../../lib";
import { Button, Typography, Link, Box } from "@mui/material";
import { adminActions, Facility, useAppDispatch } from "../../../../../state";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import {
  CheckboxField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  PasswordField,
  PhoneField,
  TextField,
} from "../../../../../components";
import {
  Form,
  passwordSchema,
  replaceEmptyProps,
  replaceNullProps,
} from "../../../../../lib";
import { FacilityStatuses } from "../../../../../lib/constants";
import * as yup from "yup";

const billingContactFirstNameValidationDeps = [
  "billingContactLastName",
  "billingContactEmail",
  "billingContactPhone",
];
const billingContactLastNameValidationDeps = [
  "billingContactFirstName",
  "billingContactEmail",
  "billingContactPhone",
];
const billingContactEmailValidationDeps = [
  "billingContactFirstName",
  "billingContactLastName",
  "billingContactPhone",
];

const billingContactValidationDeps: any = [
  billingContactFirstNameValidationDeps,
  billingContactLastNameValidationDeps,
  billingContactEmailValidationDeps,
];

// checks if any of the params have values for dependency validation
const dependencyInfoProvided = (...params) =>
  params.filter((p) => p).length > 0;

const billingContactValidation = {
  billingContactFirstName: yup
    .string()
    .max(50, "First name cannot exceed 50 characters")
    .when(billingContactFirstNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("First name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactLastName: yup
    .string()
    .max(50, "Last name cannot exceed 50 characters")
    .when(billingContactLastNameValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Last name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactEmail: yup
    .string()
    .max(250, "Email cannot exceed 250 characters")
    .email("Enter a valid email")
    .when(billingContactEmailValidationDeps, {
      is: dependencyInfoProvided,
      then: (schema) => schema.required("Email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  billingContactPhone: phoneSchema(),
};

const validationSchema = yup.object().shape(
  {
    ipAddress: yup.string().when("status", {
      is: (status) =>
        status !== FacilityStatuses.Pending &&
        status !== FacilityStatuses.Review,
      then: (schema) => schema.required("IP address is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    lockUsersToIP: yup.boolean(),
    staffUserName: yup
      .string()
      .nullable()
      .max(50, "Username cannot exceed 50 characters")
      .email("Enter a valid email")
      .when("$hasInitialStaffUserName", {
        is: true,
        then: (schema) => schema.required("Username is required"),
      }),
    staffPassword: passwordSchema(true, true).when("$hasInitialStaffPassword", {
      is: true,
      then: (schema) => schema.required("Password is required"),
    }),
    ...billingContactValidation,
  },
  billingContactValidationDeps,
);

interface FacilitySettingsProps {
  facility: Facility;
  resetFacility: (facility: Facility) => void;
}

export const FacilitySettings = React.memo(
  /**
   *
   */
  function FacilitySettings({
    facility: initialFacility,
    resetFacility,
  }: FacilitySettingsProps) {
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState<Facility>(
      replaceNullProps(initialFacility),
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            hasInitialStaffUserName: !!initialFacility.staffUserName,
            hasInitialStaffPassword: !!initialFacility.staffPassword,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      async onSubmit(values) {
        const savedFacility = await dispatch(
          adminActions.submitFacility(
            replaceEmptyProps(values),
            undefined,
            "settings",
          ),
        );
        if (savedFacility) {
          setInitialValues(replaceNullProps(savedFacility));
          resetFacility(savedFacility);
        }
      },
    });

    return (
      <Box sx={{ padding: "24px 0" }}>
        <Form form={form} promptCancelText="Back to Facility settings">
          <Typography variant="h5" sx={{ mb: 4 }}>
            Settings
          </Typography>
          <FormGridContainerStyled container>
            <FormGridItemFullWidthStyled>
              <Typography variant="subtitle1">General</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <TextField name="ipAddress" label="IP address" />
              <Typography fontSize="12px" color="primary" mt={1}>
                Facilities can find their IP address at{" "}
                <Link
                  href="https://www.whatismyip.com/"
                  sx={{ fontSize: "12px" }}
                  target="_blank"
                >
                  whatismyip.com
                </Link>{" "}
                or by contacting tech support.
              </Typography>{" "}
              <Box mt={2}>
                <CheckboxField
                  name="lockUsersToIP"
                  label="Lock staff login to IP address"
                />
              </Box>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled>
              <Typography variant="subtitle1"> Staff login</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemStyled item>
              <TextField name="staffUserName" label="Username" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <PasswordField name="staffPassword" label="Password" />
            </FormGridItemStyled>
            <FormGridItemFullWidthStyled>
              <Typography variant="subtitle1">Billing contact</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactFirstName" label="First name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactLastName" label="Last name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactEmail" label="Email" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <PhoneField name="billingContactPhone" label="Phone" />
            </FormGridItemStyled>
            {form.dirty && (
              <FormFooterContainerStyled>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={form.isSubmitting}
                  onClick={() => form.resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  Save
                </Button>
              </FormFooterContainerStyled>
            )}
          </FormGridContainerStyled>
        </Form>
      </Box>
    );
  },
);
