import { PermissionClaims } from "../../lib/constants";
import { PageDefinitions } from "../../lib/types";
import { ProfilePage } from "./ProfilePage";
import { SearchPage } from "./SearchPage";
import { UserFormPage } from "./UserFormPage";

export const AdminSharedPages: PageDefinitions = {
  profile: {
    anon: false,
    path: "/profile",
    title: "Account Settings",
    view: ProfilePage,
  },
  search: {
    anon: false,
    path: "/search",
    title: "Search",
    view: SearchPage,
  },
  // users
  addUser: {
    anon: false,
    authClaims: [PermissionClaims.UsersEditClaim],
    goBack: true,
    path: "/users/new",
    goBackDefaultPath: "/users",
    title: "Add User",
    view: UserFormPage,
  },
  editUser: {
    anon: false,
    authClaims: [PermissionClaims.UsersEditClaim],
    goBack: true,
    path: "/users/:id",
    goBackDefaultPath: "/users",
    title: "Edit User",
    view: UserFormPage,
  },
};
