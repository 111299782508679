import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import {
  CheckIcon,
  CloseIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DateInput,
  EditIcon,
  List,
  SelectInput,
} from "../../../../components";
import { ListTypes } from "../../../../state/lists/state";
import {
  adminActions,
  AuditRecord,
  listsActions,
  useAppDispatch,
} from "../../../../state";
import {
  formatDate,
  Navigation,
  RouterLink,
  usePageTitle,
} from "../../../../lib";
import {
  AuditCapStatusCellStyled,
  AuditDueDateCellStyled,
} from "./FacilityAuditsList.styles";
import { AuditStatusChipStyled } from "./AuditsLists.styles";
import { AuditStatusTimeline } from "./details/AuditStatusTimeline";
import { AuditStatuses } from "../../../../lib/constants";
import { AdminPages } from "../..";
import { useParams } from "react-router-dom";
import { dateColumnSortingOrder } from "../../../../components/lists/List";

const EditAuditFields = {
  DueDate: "DueDate",
  Quarter: "Quarter",
};

const getYearOptions = (targetYear) => {
  const endYear = new Date().getFullYear() + 1;
  const numberOfYears = endYear - targetYear + 5;

  const yearsRange = Array.from(
    { length: numberOfYears },
    (_, index) => endYear - index,
  );

  return yearsRange.map((y) => ({
    id: y,
    name: y.toString(),
  }));
};

export const FacilityAuditsList = React.memo(
  /**
   *
   */
  function FacilityAuditsList() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const setPageTitle = usePageTitle();

    useEffect(() => {
      setPageTitle({ title: "Facility Audits", facilityId: Number(params.id) });
    }, [params.id, setPageTitle]);

    const listFilter = useMemo(
      () => ({ facilityIDs: [Number(params.id)] }),
      [params.id],
    );

    const [editAuditRecord, setEditAuditRecord] = useState<AuditRecord>();
    const [editAuditField, setEditAuditField] = useState("");
    const [
      showSendDueDateChangeEmailDialog,
      setShowSendDueDateChangeEmailDialog,
    ] = useState(false);

    const handleClickEditAudit = useCallback((auditRecord, auditField) => {
      setEditAuditRecord(auditRecord);
      setEditAuditField(auditField);
    }, []);

    const handleCancelEditAudit = useCallback(() => {
      setEditAuditRecord(undefined);
      setEditAuditField("");
    }, []);

    const onEditAudit = useCallback(
      async (sendDueDateEmail?: boolean) => {
        if (!editAuditRecord) return;

        if (
          editAuditField === EditAuditFields.DueDate &&
          sendDueDateEmail === undefined
        ) {
          const emailAuditStatuses = [
            AuditStatuses.SentNotStarted,
            AuditStatuses.SentInProgress,
            AuditStatuses.AwaitingGrade,
            AuditStatuses.FollowUpNeeded,
            AuditStatuses.ReadyForReview,
            AuditStatuses.ResentCorrectionsNeeded,
          ];
          if (emailAuditStatuses.includes(editAuditRecord.status)) {
            setShowSendDueDateChangeEmailDialog(true);
            return;
          }
        } else {
          setShowSendDueDateChangeEmailDialog(false);
        }

        const { id, status, dueDate, quarter, year } = editAuditRecord;
        const updated = await dispatch(
          adminActions.submitAudit(
            { id, status, dueDate, quarter, year },
            sendDueDateEmail,
          ),
        );

        if (updated) {
          dispatch(listsActions.refreshList(ListTypes.audits));
          handleCancelEditAudit();
        }
      },
      [dispatch, editAuditField, editAuditRecord, handleCancelEditAudit],
    );

    const onRowClick = useCallback(
      ({ row }: { row: AuditRecord }) => {
        if (editAuditRecord) return;
        Navigation.go(AdminPages.audit, { params: { id: row.id } });
      },
      [editAuditRecord],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Audit",
          flex: 2,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <Typography variant="subtitle1">{row.name}</Typography>
          ),
        },
        {
          field: "sentOn",
          headerName: "Received",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "dueDate",
          headerName: "Due date",
          flex: editAuditField === EditAuditFields.DueDate ? 2 : 1.5,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditDueDateCellStyled>
              {editAuditField === EditAuditFields.DueDate &&
              editAuditRecord?.id === row.id ? (
                <>
                  <DateInput
                    dateOnly={true}
                    disableWeekends={true}
                    onChange={(dueDate) =>
                      setEditAuditRecord({ ...editAuditRecord, dueDate })
                    }
                    value={editAuditRecord.dueDate}
                    sx={{
                      "& .MuiInputBase-root": { width: "140px" },
                    }}
                  />
                  <Box display="flex" flexDirection="column" ml={1}>
                    <IconButton
                      disabled={
                        !editAuditRecord.dueDate ||
                        new Date(editAuditRecord.dueDate).getTime() ===
                          new Date(row.dueDate).getTime()
                      }
                      size="small"
                      onClick={() => onEditAudit()}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton size="small" onClick={handleCancelEditAudit}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="body2">
                    {formatDate(row.dueDate)}
                  </Typography>
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickEditAudit(row, EditAuditFields.DueDate);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </AuditDueDateCellStyled>
          ),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "quarter",
          headerName: "Quarter",
          flex: editAuditField === EditAuditFields.Quarter ? 2 : 1.2,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditDueDateCellStyled>
              {editAuditField === EditAuditFields.Quarter &&
              editAuditRecord?.id === row.id ? (
                <>
                  <SelectInput
                    clearable={false}
                    fullWidth={false}
                    name="quarter"
                    options={[
                      { id: 1, name: "Q1" },
                      { id: 2, name: "Q2" },
                      { id: 3, name: "Q3" },
                      { id: 4, name: "Q4" },
                    ]}
                    onChange={(_, quarter) =>
                      setEditAuditRecord({ ...editAuditRecord, quarter })
                    }
                    sx={{
                      mr: 1,
                      "& .MuiInputBase-root": { width: "70px" },
                    }}
                    value={editAuditRecord.quarter}
                  />
                  <SelectInput
                    clearable={false}
                    fullWidth={false}
                    name="year"
                    options={getYearOptions(row.year)}
                    onChange={(_, year) =>
                      setEditAuditRecord({ ...editAuditRecord, year })
                    }
                    sx={{ "& .MuiInputBase-root": { width: "90px" } }}
                    value={editAuditRecord.year}
                  />
                  <Box display="flex" flexDirection="column" ml={1} pr={1}>
                    <IconButton
                      disabled={
                        editAuditRecord.quarter === row.quarter &&
                        editAuditRecord.year === row.year
                      }
                      size="small"
                      onClick={() => onEditAudit()}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton size="small" onClick={handleCancelEditAudit}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="body2">
                    Q{row.quarter} {row.year}
                  </Typography>
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickEditAudit(row, EditAuditFields.Quarter);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </AuditDueDateCellStyled>
          ),
        },
        {
          field: "status",
          headerName: "Audit status",
          flex: 1.5,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditStatusChipStyled
              label={row.statusDisplay}
              size="small"
              status={row.status}
            />
          ),
        },
        {
          field: "hasQuestions",
          headerName: "Questions",
          flex: 1,
          renderCell: ({ row }: { row: AuditRecord }) =>
            row.hasQuestions ? (
              <Tooltip
                title={
                  row.hasUnresolvedQuestions ? "" : "All questions resolved"
                }
              >
                <CheckIcon
                  sx={{
                    color: row.hasUnresolvedQuestions
                      ? "text.default"
                      : "neutral.main",
                  }}
                />
              </Tooltip>
            ) : (
              ""
            ),
        },
        {
          field: "capStatus",
          headerName: "CAP status",
          flex: 1,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditCapStatusCellStyled status={row.capStatus}>
              <Link
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(AdminPages.auditCap, {
                  params: { id: row.id },
                })}
              >
                <Typography variant="body2">{row.capStatusDisplay}</Typography>
              </Link>
            </AuditCapStatusCellStyled>
          ),
        },
        {
          field: "statusHistory",
          headerName: "Timeline",
          flex: 1,
          renderCell: ({ row }: { row: AuditRecord }) => (
            <AuditStatusTimeline history={row.statusHistory} />
          ),
          sortable: false,
        },
      ],
      [
        editAuditField,
        editAuditRecord,
        handleCancelEditAudit,
        handleClickEditAudit,
        onEditAudit,
      ],
    );

    return (
      <>
        <Typography variant="h6" padding="24px 0">
          History
        </Typography>
        <List
          columns={columns}
          defaultOrderBy="status"
          filter={listFilter}
          name="audits"
          onRowClick={onRowClick}
          rowHeight={84}
          stickyHeader={true}
          type={ListTypes.audits}
        />
        {showSendDueDateChangeEmailDialog && (
          <ConfirmationDialog
            cancelText="Don't send"
            confirmText="Send"
            handleClose={() => onEditAudit(false)}
            handleConfirm={() => onEditAudit(true)}
            open={true}
            message="Would you like to send an email to notify facility users regarding this due date change?"
            title="Notify users"
            type={ConfirmationDialogTypes.Alert}
          />
        )}
      </>
    );
  },
);
