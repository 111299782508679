import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  loading: 0, // loading is a number rather than a boolean in order to allow multiple 'loadings' to be toggled simultaneously without one setting the single loading variable to false while the other is still loading
  notification: undefined, // {message: "hello", variant: "success"},
  menuOpen: false,
  helpDialogOpen: false,
  pagePath: "/",
  pageTitle: undefined,
  pageHasSubtitle: false,
  view: {},
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload ? state.loading + 1 : state.loading - 1;
      },
      hideNotification(state) {
        state.notification = undefined;
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 4000;
        }
        state.notification = payload;
      },
      setMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.menuOpen = payload;
      },
      setHelpDialogOpen(state, { payload }: PayloadAction<boolean>) {
        state.helpDialogOpen = payload;
      },
      setPagePath(state, { payload }: PayloadAction<string>) {
        state.pagePath = payload;
      },
      setPageTitle(state, { payload }: PayloadAction<UIPageTitle | undefined>) {
        state.pageTitle = payload;
      },
      setPageHasSubtitle(state, { payload }: PayloadAction<boolean>) {
        state.pageHasSubtitle = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

export interface UIPageTitle {
  title: string;
  status?: string;
  statusColor?: string;
  facilityId?: number;
}

export interface UIState {
  loading: number;
  notification?: UINotification;
  view: UIViewState;
  menuOpen: boolean;
  helpDialogOpen: boolean;
  pagePath: string;
  pageTitle?: UIPageTitle;
  pageHasSubtitle: boolean;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // viewName?: UIViewType;
}
