import React, { useMemo } from "react";
import { Box, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  authSelectors,
  BasicGroup,
  sysSelectors,
  uiSelectors,
  useSelector,
} from "../../../state";
import {
  AssignmentLateIcon,
  ChromeReaderModeIcon,
  DescriptionIcon,
  DomainIcon,
  FindInPageIcon,
  FolderIcon,
  HomeIcon,
  PeopleIcon,
  SchoolIcon,
  SettingsIcon,
} from "../../../components";
import { PortalUserTypes } from "../../../lib/constants";
import {
  AdminPages,
  FacilityAdminPages,
  FacilityStaffPages,
} from "../../../pages";
import {
  MenuContainerStyled,
  MenuItemStyled,
  MenuListStyled,
} from "./Drawer.styles";

interface MenuItemProps {
  label: string;
  Icon: any;
  to: string;
  subPaths?: string[];
  authClaims?: string[];
  groupsAccessCheck?: (groups: BasicGroup[]) => boolean;
}

const menuItems: Record<string, MenuItemProps[]> = {
  [PortalUserTypes.CcgAdmin]: [
    {
      label: "Groups and Facilities",
      Icon: DomainIcon,
      to: AdminPages.groupsAndFacilities.path,
      subPaths: [
        AdminPages.groups.path,
        AdminPages.facilities.path,
        AdminPages.regions.path,
      ],
    },
    {
      label: "Users",
      Icon: PeopleIcon,
      to: AdminPages.users.path,
    },
    {
      label: "Policies",
      Icon: ChromeReaderModeIcon,
      to: AdminPages.policies.path,
    },
    {
      label: "Resources",
      Icon: DescriptionIcon,
      to: AdminPages.resources.path,
    },
    {
      label: "Audits",
      Icon: FindInPageIcon,
      to: AdminPages.audits.path,
    },
    {
      label: "Trainings",
      Icon: SchoolIcon,
      to: AdminPages.trainings.path,
    },
    {
      label: "Configuration",
      Icon: SettingsIcon,
      to: AdminPages.configuration.path,
    },
  ],
  [PortalUserTypes.FacilityAdmin]: [
    {
      label: "Dashboard",
      Icon: HomeIcon,
      to: FacilityAdminPages.dashboard.path,
    },
    {
      label: "Users",
      Icon: PeopleIcon,
      to: FacilityAdminPages.users.path,
      authClaims: FacilityAdminPages.users.authClaims,
    },
    {
      label: "Policies",
      Icon: ChromeReaderModeIcon,
      to: FacilityAdminPages.policies.path,
      authClaims: FacilityAdminPages.policies.authClaims,
    },
    {
      label: "Required Documents",
      Icon: AssignmentLateIcon,
      to: FacilityAdminPages.requiredDocuments.path,
      authClaims: FacilityAdminPages.requiredDocuments.authClaims,
    },
    {
      label: "Resources",
      Icon: DescriptionIcon,
      to: FacilityAdminPages.resources.path,
      authClaims: FacilityAdminPages.resources.authClaims,
    },
    {
      label: "Audits",
      Icon: FindInPageIcon,
      to: FacilityAdminPages.audits.path,
      authClaims: FacilityAdminPages.audits.authClaims,
    },
    {
      label: "Trainings",
      Icon: SchoolIcon,
      to: FacilityAdminPages.trainings.path,
      authClaims: FacilityAdminPages.trainings.authClaims,
    },
    {
      label: "Facility Files",
      Icon: FolderIcon,
      to: FacilityAdminPages.facilityFiles.path,
      authClaims: FacilityAdminPages.facilityFiles.authClaims,
      groupsAccessCheck: (groups: BasicGroup[]) =>
        groups.some((g) => g.facilityFilesEnabled),
    },
  ],
  [PortalUserTypes.FacilityStaff]: [
    {
      label: "Policies",
      Icon: ChromeReaderModeIcon,
      to: FacilityStaffPages.policies.path,
      authClaims: FacilityStaffPages.policies.authClaims,
    },
    {
      label: "Facility Files",
      Icon: FolderIcon,
      to: FacilityStaffPages.facilityFiles.path,
      authClaims: FacilityStaffPages.facilityFiles.authClaims,
      groupsAccessCheck: (groups: BasicGroup[]) =>
        groups.some((g) => g.facilityFilesEnabled),
    },
  ],
};

const getMenuItems = (
  userType: string | undefined,
  userPermissionClaims: string[],
  activeGroups: BasicGroup[],
) => {
  if (!userType) {
    return [];
  }
  return (
    menuItems[userType]?.filter((item) => {
      const authClaimsValidated =
        !item.authClaims?.length ||
        item.authClaims.some((claim) => userPermissionClaims.includes(claim));
      const groupsAccessValidated =
        !item.groupsAccessCheck || item.groupsAccessCheck(activeGroups);
      return authClaimsValidated && groupsAccessValidated;
    }) || []
  );
};

export default function DrawerMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const menuDisabled = useSelector(uiSelectors.menuDisabled);
  const userType = useSelector(authSelectors.userType);
  const userPermissionClaims = useSelector(authSelectors.permissionClaims);

  const activeGroups = useSelector(sysSelectors.activeGroups);

  const userMenuItems = useMemo(
    () => getMenuItems(userType, userPermissionClaims, activeGroups),
    [activeGroups, userPermissionClaims, userType],
  );

  return userMenuItems.length ? (
    <MenuContainerStyled>
      <MenuListStyled>
        {userMenuItems.map(({ label, Icon, subPaths, to }) => (
          <Tooltip
            key={label}
            title={
              menuDisabled ? `Complete facility setup to access ${label}` : ""
            }
          >
            <Box>
              <MenuItemStyled
                disabled={menuDisabled}
                onClick={() => navigate(to)}
                selected={
                  to === "/"
                    ? location.pathname === to
                    : location.pathname.startsWith(to) ||
                      subPaths?.some((p) => location.pathname.startsWith(p))
                }
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                {menuOpen && <ListItemText primary={label} />}
              </MenuItemStyled>
            </Box>
          </Tooltip>
        ))}
      </MenuListStyled>
    </MenuContainerStyled>
  ) : null;
}
