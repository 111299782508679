import React from "react";
import { ResourcesList } from "./components";

export const ResourcesPage = React.memo(
  /**
   *
   */
  function ResourcesPage() {
    return <ResourcesList />;
  },
);
