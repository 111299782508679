import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getAuditStatusColor } from "../../../adminShared/components/audits/AuditHelpers";

interface AuditStatusChipStyledProps extends ChipProps {
  box?: boolean;
  label?: string;
  status?: string;
}

export const AuditStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status" && prop !== "box",
})<AuditStatusChipStyledProps>(({ theme, box, label, status }) => ({
  color: theme.palette.background.default,
  backgroundColor: getAuditStatusColor(status, label, true),
  ...(box
    ? {
        borderRadius: "3px",
        width: "100%",
      }
    : ""),
}));
