import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PolicyStatuses } from "../../../../lib/constants";

interface PolicyStatusChipStyledProps extends ChipProps {
  status?: string;
}

export const PolicyStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<PolicyStatusChipStyledProps>(({ theme, status }) => ({
  color: theme.palette.background.default,
  backgroundColor:
    status === PolicyStatuses.Active
      ? theme.palette.primary.light
      : theme.palette.neutral.main,
}));
