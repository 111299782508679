import { createSelector } from "@reduxjs/toolkit";
import { PermissionClaims, PortalUserTypes } from "../../lib/constants";
import { authSelectors, sysSelectors } from "../states";
import { AppState } from "../types";

export const uiSelectors = {
  loading: createSelector(
    (state: AppState) => state.ui.loading,
    (loading) => !!loading,
  ),

  notification: createSelector(
    (state: AppState) => state.ui.notification,
    (notification) => notification,
  ),

  menuOpen: createSelector(
    (state: AppState) => state.ui.menuOpen,
    (menuOpen) => menuOpen,
  ),

  hideMenu: createSelector(
    authSelectors.userType,
    sysSelectors.activeGroups,
    (userType, groups) =>
      !userType ||
      (userType === PortalUserTypes.FacilityStaff &&
        !groups.some((g) => g.facilityFilesEnabled)), // hide menu for facility staff users when facility files are not enabled (leaving a single menu option)
  ),

  menuDisabled: createSelector(
    [
      authSelectors.userType,
      authSelectors.permissionClaims,
      sysSelectors.allFacilities,
      sysSelectors.pendingFacilities,
    ],
    (userType, userPermissionClaims, allFacilities, pendingFacilities) => {
      if (userType === PortalUserTypes.FacilityAdmin) {
        return !!(
          userPermissionClaims.includes(
            PermissionClaims.PendingFacilityEditClaim,
          ) &&
          pendingFacilities.length &&
          allFacilities.length === pendingFacilities.length
        );
      }
      return false;
    },
  ),

  helpDialogOpen: createSelector(
    (state: AppState) => state.ui.helpDialogOpen,
    (helpDialogOpen) => helpDialogOpen,
  ),

  pageTitle: createSelector(
    (state: AppState) => state.ui.pageTitle,
    (pageTitle) => pageTitle,
  ),

  pageHasSubtitle: createSelector(
    (state: AppState) => state.ui.pageHasSubtitle,
    (pageHasSubtitle) => pageHasSubtitle,
  ),

  showFacilityHeader: createSelector(
    authSelectors.userType,
    (state: AppState) => state.ui.pageTitle,
    (state: AppState) => state.ui.pagePath,
    (userType, pageTitle, pagePath) =>
      pagePath !== "/" && // no facility header display on the default route
      (userType === PortalUserTypes.FacilityAdmin || !!pageTitle?.facilityId),
  ),

  // // Views
  // forView: createSelector(
  //   [(state: AppState) => state.ui.view, (_, name: keyof UIViewState) => name],
  //   (view, name) => view[name],
  // ),
};
