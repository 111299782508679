import React, { Fragment, useEffect, useMemo } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import {
  AddIcon,
  CheckboxInput,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  InfoIcon,
  PhoneField,
  TextField,
} from "../../../../../components";
import { WizardStepProps } from "./WizardStep";
import { FieldArray } from "formik";
import {
  initialUserValues,
  getInitialUsers,
  getPositionByName,
  PresetUserPositionsByType,
  WizardModes,
} from "./GroupFacilityRegionWizardHelpers";
import { AbbreviatedUserForm } from "./AbbreviatedUserForm";
import {
  BasicStatuses,
  EntityTypes,
  FacilityStatuses,
} from "../../../../../lib/constants";
import { BasicPosition, User } from "../../../../../state";

export const WizardStep2 = React.memo(
  /**
   *
   */
  function WizardStep2({
    form,
    mode,
    positionOptions,
    roleOptions,
    sendUserEmails,
    setInitialValues,
    toggleSendUserEmails,
    type,
  }: WizardStepProps) {
    // initialize users - set users for preset positions
    useEffect(() => {
      const initializedUsers = getInitialUsers(
        type,
        positionOptions,
        form.values.users,
      );
      form.setFieldValue("users", initializedUsers);

      //if form is not dirty, reset initial values after initializing users so there aren't unsaved changes
      if (!form.dirty) {
        setInitialValues((vals) => ({ ...vals, users: initializedUsers }));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleOptions]);

    const showUserPendingFacilityAccess =
      mode !== WizardModes.FacilityAdmin &&
      form.values.status === FacilityStatuses.Pending;

    const showSendUserEmailsCheckbox =
      mode !== WizardModes.FacilityAdmin &&
      (form.values.status === BasicStatuses.Active ||
        form.values.status === FacilityStatuses.Onboarding ||
        form.values.status === FacilityStatuses.Pending);

    const presetPositions = useMemo(
      () =>
        (PresetUserPositionsByType[type]
          .map((positionName) =>
            getPositionByName(positionName, positionOptions),
          )
          .filter((p) => p) as BasicPosition[]) || [],
      [positionOptions, type],
    );

    const additionalUsersPositionOptions = useMemo(
      () =>
        positionOptions.filter(
          (o) =>
            o.name.toLowerCase().startsWith(type.toLowerCase()) && // filter to include only positions that begin with the entity name
            !PresetUserPositionsByType[type].includes(o.name), // and exclude preset positions that are on the form
        ),
      [positionOptions, type],
    );

    return (
      <>
        <FormGridItemFullWidthStyled>
          <Typography variant="h6">Users</Typography>
          <Typography variant="body2" color="text.secondary" mt={1}>
            Enter an email and full name for each user in your{" "}
            {type.toLowerCase()}
          </Typography>
        </FormGridItemFullWidthStyled>
        <FieldArray
          name="users"
          render={(arrayHelpers) => {
            const users = form.values.users || [];
            return (
              <>
                {presetPositions.map((position) => {
                  const usersWithPosition = users.filter((u) =>
                    u.positionIDs?.includes(position.id),
                  );

                  const newUserWithPosition = {
                    ...initialUserValues,
                    positionIDs: [position.id],
                    roleIDs: [position.defaultRoleID],
                  };

                  return (
                    <Fragment key={position.id}>
                      {!usersWithPosition.length ? (
                        <FormGridItemFullWidthStyled minHeight="72px">
                          <Typography variant="subtitle1">
                            {position.name}
                          </Typography>
                        </FormGridItemFullWidthStyled>
                      ) : (
                        usersWithPosition.map((user: User) => {
                          const userIndex = users.indexOf(user);
                          const isOnlyUserWithPosition =
                            users.filter((u) =>
                              u.positionIDs?.includes(position.id),
                            ).length === 1;
                          const otherUserPresetPositions =
                            presetPositions.filter(
                              (p) =>
                                p.id !== position.id &&
                                user.positionIDs?.find((id) => id === p.id),
                            );
                          return (
                            <Fragment key={userIndex}>
                              <AbbreviatedUserForm
                                onDelete={() => {
                                  // if user is not linked to a system user, and is assigned to other preset positions, remove the user from this position
                                  if (
                                    !user.isLinkedToSystemUser &&
                                    otherUserPresetPositions.length
                                  ) {
                                    arrayHelpers.replace(userIndex, {
                                      ...user,
                                      positionIDs: user.positionIDs?.filter(
                                        (id) => id !== position.id,
                                      ),
                                    });
                                    // and if user was the only user with this position, add a blank user for the position
                                    if (isOnlyUserWithPosition) {
                                      arrayHelpers.push({
                                        ...newUserWithPosition,
                                      });
                                    }
                                  }
                                  // otherwise, if the user is the only with this position, reset the user
                                  else if (isOnlyUserWithPosition) {
                                    arrayHelpers.replace(userIndex, {
                                      ...newUserWithPosition,
                                    });
                                  }
                                  // otherwise, remove the user
                                  else {
                                    arrayHelpers.remove(userIndex);
                                  }
                                }}
                                onFindUserMatch={(email) =>
                                  users.find(
                                    (u, uIndex) =>
                                      uIndex !== userIndex && // exclude this user
                                      presetPositions.some(
                                        (p) => u.positionIDs?.includes(p.id), // search users with preset positions
                                      ) &&
                                      u.email?.toLowerCase() === // for a matching email
                                        email.toLowerCase(),
                                  )
                                }
                                onMatchFormUser={(
                                  combinedUser,
                                  matchedUserIndex,
                                ) => {
                                  // replace the current user index with the combined user and remove the matched user index
                                  // to ensure that the placement of the focused user within the form will not shift
                                  arrayHelpers.replace(userIndex, combinedUser);
                                  arrayHelpers.remove(matchedUserIndex);
                                }}
                                hideDelete={
                                  // hide delete icon if it's a blank user and the only with the preset position
                                  isOnlyUserWithPosition &&
                                  !user.id &&
                                  !user.email &&
                                  !user.phone &&
                                  !user.firstName &&
                                  !user.lastName
                                }
                                otherUserPositions={otherUserPresetPositions}
                                showUserPendingFacilityAccess={
                                  showUserPendingFacilityAccess
                                }
                                title={position.name}
                                user={user}
                                userAccessor={`users[${userIndex}]`}
                                wizardType={type}
                              />
                            </Fragment>
                          );
                        })
                      )}
                      <Button
                        variant="text"
                        onClick={() =>
                          arrayHelpers.push({ ...newUserWithPosition })
                        }
                        sx={{ mb: 2 }}
                      >
                        <AddIcon sx={{ mr: 1 }} /> Add {position.name}
                      </Button>
                    </Fragment>
                  );
                })}
              </>
            );
          }}
        />
        {type === EntityTypes.Facility && (
          <>
            <FormGridItemFullWidthStyled>
              <Typography
                variant="subtitle1"
                display="flex"
                alignItems="center"
              >
                Billing Contact
                <Tooltip title="Billing contact information will be saved on file for CCG. No user will be created for the billing contact.">
                  <InfoIcon
                    fontSize="small"
                    sx={{ ml: 1, color: "text.secondary" }}
                  />
                </Tooltip>
              </Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactEmail" label="Email" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <PhoneField name="billingContactPhone" label="Phone" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactFirstName" label="First name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField name="billingContactLastName" label="Last name" />
            </FormGridItemStyled>
          </>
        )}
        <FormGridItemFullWidthStyled minHeight="72px">
          <Typography variant="subtitle1">Additional users</Typography>
        </FormGridItemFullWidthStyled>
        {additionalUsersPositionOptions.length ? (
          <FieldArray
            name="users"
            render={(arrayHelpers) => {
              const users = form.values.users || [];
              return (
                <>
                  {users
                    .filter(
                      // filter to users without preset position
                      (user) =>
                        !user.positionIDs?.some((id) =>
                          presetPositions.find((p) => p.id === id),
                        ),
                    )
                    .map((user, i) => {
                      const userIndex = users.indexOf(user);
                      return (
                        <Fragment key={userIndex}>
                          {i > 0 && <FormGridItemFullWidthStyled />}
                          <AbbreviatedUserForm
                            onDelete={() => arrayHelpers.remove(userIndex)}
                            onFindUserMatch={(email) =>
                              users.find(
                                (u, uIndex) =>
                                  uIndex !== userIndex && // exclude this user
                                  u.email?.toLowerCase() === // search for a matching email
                                    email.toLowerCase(),
                              )
                            }
                            positionOptions={additionalUsersPositionOptions}
                            showUserPendingFacilityAccess={
                              showUserPendingFacilityAccess
                            }
                            user={user}
                            userAccessor={`users[${userIndex}]`}
                            wizardType={type}
                          />
                        </Fragment>
                      );
                    })}
                  <Button
                    variant="text"
                    onClick={() => arrayHelpers.push({ ...initialUserValues })}
                    sx={{ mb: 2 }}
                  >
                    <AddIcon sx={{ mr: 1 }} /> Add user
                  </Button>
                </>
              );
            }}
          />
        ) : (
          <FormGridItemFullWidthStyled>
            <Typography color="text.secondary">
              To add additional users,{" "}
              {mode === WizardModes.FacilityAdmin
                ? "please contact CCG"
                : `add ${type} user positions to the Positions list`}
            </Typography>
          </FormGridItemFullWidthStyled>
        )}
        {showSendUserEmailsCheckbox && (
          <FormGridItemFullWidthStyled mt={2}>
            <CheckboxInput
              checked={sendUserEmails}
              label={`Send welcome email to ${
                form.values.status === FacilityStatuses.Pending
                  ? "users with access to complete facility profile"
                  : "all users"
              }`}
              name="sendUserEmails"
              onChange={toggleSendUserEmails}
            />
          </FormGridItemFullWidthStyled>
        )}
      </>
    );
  },
);
