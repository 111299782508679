import React, { useCallback, useState } from "react";
import {
  Button,
  Typography,
  Box,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  adminActions,
  Policy,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import { useFormik } from "formik";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  LinkTextStyled,
  SelectField,
  TextButtonStyled,
  TextField,
} from "../../../../../components";
import {
  asString,
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useRedirect,
} from "../../../../../lib";
import { ManageCategoriesDialog } from "../categories/ManageCategoriesDialog";
import { AdminPages } from "../../..";
import { PolicyStatuses } from "../../../../../lib/constants";
import { PolicyTabProps } from "../../../PolicyPage";
import * as yup from "yup";

const validationSchema = yup.object({
  categoryID: yup.number().nullable().required("Category is required"),
  facilityType: yup.string().required("Facility type is required"),
  internalNote: yup
    .string()
    .nullable()
    .max(1000, "Internal note cannot exceed 1,000 characters"),
  number: yup
    .string()
    .required("Policy number is required")
    .max(50, "Policy number cannot exceed 50 characters"),
  name: yup
    .string()
    .required("Title is required")
    .max(250, "Title cannot exceed 250 characters"),
});

export const PolicySettings = React.memo(
  /**
   *
   */
  function PolicySettings({
    policy: initialPolicy,
    refreshPolicy,
    updatePolicy,
  }: PolicyTabProps) {
    const dispatch = useAppDispatch();

    const { redirectUrl, setRedirectUrl } = useRedirect();

    const [initialValues, setInitialValues] = useState<Policy>(
      replaceNullProps(initialPolicy),
    );

    const policyCategoryOptions = useSelector(sysSelectors.policyCategories);
    const { facilityTypes: facilityTypeOptions = [] } = useSelector(
      sysSelectors.systemSettings,
    );

    const [showManageCategoriesDialog, setShowManageCategoriesDialog] =
      useState(false);

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      async onSubmit(values) {
        const savedPolicy = await dispatch(
          adminActions.submitPolicy(replaceEmptyProps(values)),
        );
        if (savedPolicy) {
          setInitialValues(replaceNullProps(savedPolicy));
          if (initialPolicy.id) {
            updatePolicy(savedPolicy);
          } else {
            const savedPolicyId = asString(savedPolicy.id);
            setRedirectUrl(
              Navigation.url(AdminPages.editPolicy, {
                params: { id: savedPolicyId },
                query: { tab: "versions" },
              }),
            );
          }
        }
      },
    });

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
      useState(false);
    const [showRevertConfirmationDialog, setShowRevertConfirmationDialog] =
      useState(false);
    const [showRevertSuccessDialog, setShowRevertSuccessDialog] =
      useState(false);

    const onDelete = useCallback(async () => {
      const deleted = await dispatch(
        adminActions.deletePolicy(Number(initialPolicy.id)),
      );
      if (deleted) {
        Navigation.replace(AdminPages.policies.path);
      }
    }, [dispatch, initialPolicy.id]);

    const onRevertToDraft = useCallback(async () => {
      const reverted = await dispatch(
        adminActions.unpublishPolicy(Number(initialPolicy.id)),
      );
      if (reverted) {
        refreshPolicy();
        setShowDeleteConfirmationDialog(false);
        setShowRevertConfirmationDialog(false);
        setShowRevertSuccessDialog(true);
      }
    }, [dispatch, initialPolicy.id, refreshPolicy]);

    const onCancel = useCallback(() => {
      if (initialPolicy.id) {
        form.resetForm();
      } else {
        Navigation.goBack(AdminPages.policies.path);
      }
    }, [form, initialPolicy.id]);

    const disableDeleteRevert =
      form.dirty || initialPolicy.hasPublishingVersions;

    return (
      <Box sx={{ padding: "24px 0" }}>
        <Form
          form={form}
          noPrompt={!!redirectUrl}
          promptCancelText="Back to Policy"
        >
          <FormGridContainerStyled container>
            <FormGridItemFullWidthStyled>
              <Typography variant="h6">General settings</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <TextField name="name" label="Title" />
            </FormGridItemFullWidthStyled>
            <FormGridItemStyled>
              <TextField
                name="number"
                label="Policy number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">CCG</InputAdornment>
                  ),
                }}
              />
            </FormGridItemStyled>
            <FormGridItemStyled>
              <SelectField
                clearable={false}
                name="categoryID"
                label="Category"
                options={policyCategoryOptions}
                menuTag={
                  <LinkTextStyled
                    variant="subtitle2"
                    onClick={() => setShowManageCategoriesDialog(true)}
                    sx={{ textDecoration: "none" }}
                  >
                    Manage categories
                  </LinkTextStyled>
                }
              />
              {showManageCategoriesDialog && (
                <ManageCategoriesDialog
                  handleClose={() => setShowManageCategoriesDialog(false)}
                />
              )}
            </FormGridItemStyled>
            <FormGridItemStyled>
              <Tooltip
                title={
                  initialPolicy.hasVersions
                    ? "Facility type cannot be changed once policy versions have been added"
                    : ""
                }
              >
                <Box>
                  <SelectField
                    clearable={false}
                    disabled={initialPolicy.hasVersions}
                    name="facilityType"
                    label="Facility type"
                    options={facilityTypeOptions}
                  />
                </Box>
              </Tooltip>
            </FormGridItemStyled>
            <FormGridItemFullWidthStyled>
              <Typography variant="h6">Internal</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <TextField
                name="internalNote"
                label="Internal note"
                multiline={true}
                rows={4}
              />
            </FormGridItemFullWidthStyled>
            {!!initialPolicy.id && (
              <>
                {initialPolicy.status === PolicyStatuses.Active && (
                  <FormGridItemFullWidthStyled>
                    <Typography variant="subtitle1" mb="8px">
                      Revert to draft
                    </Typography>
                    <Typography variant="body2" mb="16px">
                      Reverts this policy to a draft. This will unpublish all
                      versions and hide the policy from all facilities.
                    </Typography>
                    <Tooltip
                      sx={{ width: "fit-content" }}
                      title={
                        disableDeleteRevert
                          ? form.dirty
                            ? "Save/discard unsaved changes before reverting this policy"
                            : "The policy cannot be reverted to draft while there are versions that are publishing"
                          : ""
                      }
                    >
                      <Box>
                        <TextButtonStyled
                          disabled={disableDeleteRevert}
                          onClick={() => setShowRevertConfirmationDialog(true)}
                          size="large"
                          variant="text"
                        >
                          Revert to draft
                        </TextButtonStyled>
                      </Box>
                    </Tooltip>
                    {showRevertConfirmationDialog && (
                      <ConfirmationDialog
                        confirmText="Revert to draft"
                        handleClose={() =>
                          setShowRevertConfirmationDialog(false)
                        }
                        handleConfirm={onRevertToDraft}
                        message="This will unpublish all versions and hide the policy from all facilities."
                        open={true}
                        title="Revert to draft?"
                        type={ConfirmationDialogTypes.Alert}
                      />
                    )}
                    {showRevertSuccessDialog && (
                      <ConfirmationDialog
                        confirmText="Ok"
                        handleConfirm={() => setShowRevertSuccessDialog(false)}
                        hideCancel={true}
                        message={`Policy ${initialPolicy.name} was reverted to draft and will no longer be available to facilities.`}
                        open={true}
                        title="Policy reverted"
                        type={ConfirmationDialogTypes.Alert}
                      />
                    )}
                  </FormGridItemFullWidthStyled>
                )}
                <FormGridItemFullWidthStyled>
                  <Typography variant="subtitle1" mb="8px">
                    Delete policy
                  </Typography>
                  <Typography variant="body2" mb="16px">
                    Deletes this policy and all its data. This action cannot be
                    undone.
                    {initialPolicy.status === PolicyStatuses.Active
                      ? " If you would like to just hide the policy, use the 'revert to draft' option above instead."
                      : ""}
                  </Typography>
                  <Tooltip
                    sx={{ width: "fit-content" }}
                    title={
                      disableDeleteRevert
                        ? form.dirty
                          ? "Discard unsaved changes before deleting this policy"
                          : "The policy cannot be deleted while there are versions that are publishing"
                        : ""
                    }
                  >
                    <Box>
                      <TextButtonStyled
                        color="error"
                        disabled={disableDeleteRevert}
                        onClick={() => setShowDeleteConfirmationDialog(true)}
                        size="large"
                        variant="text"
                      >
                        Delete Policy
                      </TextButtonStyled>
                    </Box>
                  </Tooltip>
                  {showDeleteConfirmationDialog && (
                    <ConfirmationDialog
                      confirmControl={
                        <>
                          {initialPolicy.status === PolicyStatuses.Active && (
                            <Button
                              variant="contained"
                              onClick={onRevertToDraft}
                            >
                              Revert to draft
                            </Button>
                          )}
                          <Button
                            color="error"
                            variant="contained"
                            onClick={onDelete}
                          >
                            Delete
                          </Button>
                        </>
                      }
                      handleClose={() => setShowDeleteConfirmationDialog(false)}
                      message={`This action cannot be undone. ${
                        initialPolicy.status === PolicyStatuses.Active
                          ? " If you may want to access the data, revert to draft instead."
                          : ""
                      }`}
                      open={true}
                      title="Delete policy and all its data?"
                      type={ConfirmationDialogTypes.Alert}
                    />
                  )}
                </FormGridItemFullWidthStyled>
              </>
            )}
            {form.dirty && (
              <FormFooterContainerStyled>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={form.isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={form.isSubmitting}
                  sx={{ ml: 2 }}
                >
                  {initialPolicy.id ? "Save" : "Save and add version"}
                </Button>
              </FormFooterContainerStyled>
            )}
          </FormGridContainerStyled>
        </Form>
      </Box>
    );
  },
);
