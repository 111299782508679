import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AppThunk,
  NameValuePair,
  uiSelectors,
  useAppDispatch,
  useSelector,
} from "../../state";
import {
  PdfActionsContainerStyled,
  PdfContainerStyled,
  ProgressHeaderStyled,
  LoadingContainerStyled,
  HeaderIconButtonStyled,
} from "./PdfFormViewer.styles";
import WebViewer, { WebViewerInstance } from "@pdftron/pdfjs-express";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { ConfirmationDialog, ConfirmationDialogTypes } from "../dialogs";
import {
  base64ToBlob,
  RouterPrompt,
  RouterPromptProps,
  useWebViewer,
} from "../../lib";
import { ErrorDisplayStyled, ErrorTextStyled } from "../styled";
import { ButtonMenu } from "../buttons";
import { MoreVertIcon } from "../icons";
import empty from "../../assets/img/empty.svg";

import { REACT_APP_PDF_JS_LICENSE_KEY } from "../../config";

export * from "./PdfFormViewerHelpers";

export const ViewerModes = {
  Edit: "Edit",
  Comment: "Comment",
  ReadOnly: "ReadOnly",
};

const defaultWidgetContainerStyles = {
  border: "none",
  backgroundColor: "transparent",
};

export interface PdfPreviewProps {
  allowLayoutChange?: boolean;
  annotations?: string;
  autoFocus?: boolean;
  customButtons?: JSX.Element;
  dataId?: string;
  dataUrl?: string;
  defaultSinglePageView?: boolean;
  errorDisplay?: JSX.Element;
  fieldPrefills?: Record<string, any>;
  fieldValidation?: {
    expectedValue: string;
    fieldName: string;
    message: string;
  };
  filename?: string;
  fitWidth?: boolean;
  handleSave?: (submit: boolean, fields: NameValuePair[]) => Promise<void>;
  highlightFields?: string[];
  hideAnnotationsBySubject?: string;
  name: string;
  retrieveData?: () => AppThunk<Promise<string | null>>;
  showProgressBar?: boolean;
  showSave?: boolean;
  showSubmit?: boolean;
  submitConfirmationDescription?: string;
  submitConfirmationTag?: JSX.Element;
  submitDisabledDescription?: string;
  unsavedChangesPrompt?: Omit<RouterPromptProps, "when">;
  validateRequiredFields?: boolean;
  viewerMode?: string;
  zoomLevel?: number;
}

export const PdfFormViewer = React.memo(
  /**
   *
   */
  function PdfFormViewer({
    allowLayoutChange,
    annotations,
    autoFocus,
    customButtons,
    dataId = "default",
    dataUrl,
    defaultSinglePageView,
    errorDisplay,
    fieldPrefills,
    fieldValidation,
    filename,
    fitWidth,
    handleSave,
    highlightFields,
    hideAnnotationsBySubject,
    name,
    retrieveData,
    showProgressBar,
    showSave,
    showSubmit,
    submitConfirmationDescription,
    submitConfirmationTag,
    submitDisabledDescription,
    unsavedChangesPrompt,
    validateRequiredFields,
    viewerMode,
    zoomLevel,
  }: PdfPreviewProps) {
    const dispatch = useAppDispatch();

    const [fileData, setFileData] = useState<string>();
    const [showError, setShowError] = useState(false);
    const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
    const [showSubmitValidationMessage, setShowSubmitValidationMessage] =
      useState(false);
    const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] =
      useState(false);

    const uiLoading = useSelector(uiSelectors.loading);

    // load file data if retrieveData function was provided (vs dataUrl)
    useEffect(() => {
      (async () => {
        if (retrieveData) {
          setShowError(false);

          const data = await dispatch(retrieveData());

          if (data) {
            setFileData(data);
          } else {
            setFileData(undefined);
            setShowError(true);
          }
        }
      })();
    }, [dispatch, retrieveData]);

    // viewer instance
    const viewerRef = useRef(null);
    const {
      viewerInstance,
      viewerAnnotations,
      setViewerInstance,
      setViewerMode,
      setViewerAnnotations,
    } = useWebViewer();

    // viewer state
    const [documentReadyId, setDocumentReadyId] = useState<string>(); // track which dataId document is ready for (allows switching between docs within a viewer instance)
    const [annotationsReadyId, setAnnotationsReadyId] = useState<string>(); // track which dataId annotations are ready for (allows switching between docs within a viewer instance)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // field changes tracking
    const [fieldValues, setFieldValues] = useState<{
      [fieldName: string]: {
        initialValue: string;
        value: string;
      };
    }>({});

    const hasUnsavedChanges = useMemo(
      () =>
        Object.keys(fieldValues).some(
          (f) => fieldValues[f].initialValue !== fieldValues[f].value,
        ),
      [fieldValues],
    );

    const [singlePageView, setSinglePageView] = useState(defaultSinglePageView);

    const updateLayoutMode = useCallback(
      (singlePageView = defaultSinglePageView) => {
        setSinglePageView(singlePageView);

        const { UI } = viewerInstance;

        // set page view
        if (singlePageView) {
          UI.setLayoutMode(UI.LayoutMode.Single);
          UI.setFitMode(UI.FitMode.FitPage);
        } else {
          UI.setLayoutMode(UI.LayoutMode.Continuous);

          if (fitWidth) {
            UI.setFitMode(UI.FitMode.FitWidth);
          } else {
            UI.setFitMode(UI.FitMode.Zoom);
            UI.setZoomLevel(zoomLevel || 1);
          }
        }

        // when setting single page layout mode, overflow is set to hidden on DocumentContainer to eliminate scroll bar that appears due to half-pixel shorter container height
        const docContainerElement =
          UI.iframeWindow.document.getElementsByClassName(
            "DocumentContainer",
          )[0];
        if (
          docContainerElement &&
          (docContainerElement.style.overflow === "hidden") !== singlePageView
        )
          docContainerElement.setAttribute(
            "style",
            singlePageView ? "overflow: hidden;" : "",
          );
      },
      [defaultSinglePageView, fitWidth, viewerInstance, zoomLevel],
    );

    // initialize web viewer instance
    useEffect(() => {
      if (!viewerRef.current) {
        return;
      }

      WebViewer(
        {
          licenseKey: REACT_APP_PDF_JS_LICENSE_KEY,
          path: `/webviewer/lib`,
          // disable elements
          disabledElements: [
            "header",
            "toolsHeader",
            "ribbons",
            "leftPanel",
            "searchPanel",
            "viewControlsOverlay",
            "pageNavOverlay",
            "textPopup",
            //  note: UI.annotationPopup.update([]) can be used instead of disabling the element (which may cause an error on annotation selection) - clears annotationPopup items
            // "annotationPopup",
            //  header buttons: (included in disabled header item)
            // "leftPanelButton",
            // "menuButton",
            // "panToolButton",
            // "searchButton",
            // "selectToolButton",
            // "toggleNotesButton",
            // "viewControlsButton",
            // "zoomOverlayButton",
          ],
          css: "/webviewer/custom-styles.css",
        },
        viewerRef.current,
      ).then((instance: WebViewerInstance) => {
        setViewerInstance(instance);

        const {
          Core: { Annotations, documentViewer },
          UI: { Feature },
          UI,
        } = instance;

        // disable features:
        UI.disableFeatures([
          Feature.ChangeView,
          Feature.Download,
          Feature.FilePicker,
          Feature.InlineComment,
          Feature.MathSymbols,
          Feature.Measurement,
          Feature.MultipleViewerMerging,
          Feature.NotesPanel,
          Feature.MultiTab,
          Feature.MultiViewerMode,
          Feature.Print,
          Feature.Ribbons,
          Feature.RightClickAnnotationPopup,
          Feature.Search,
          Feature.ThumbnailMerging,
          Feature.ThumbnailReordering,
          Feature.WatermarkPanel,
        ]);

        // replace annotationPopup items with an empty array - prevents popup rendering
        UI.annotationPopup.update([]);

        // style customization: eliminate the default red border and background that's added to fields (required fields, as well as dropdowns and radio buttons)
        Annotations.WidgetAnnotation.getContainerCustomStyles = () =>
          defaultWidgetContainerStyles;

        // pagination event listener
        documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
          setCurrentPage(pageNumber);
        });
      });

      return () => setViewerInstance(undefined);
    }, [setViewerInstance]);

    // load pdf document
    useEffect(() => {
      if (!viewerInstance || (!fileData && !dataUrl)) {
        return;
      }

      const {
        Core: { documentViewer },
        Events,
        UI,
      } = viewerInstance;

      // load document
      UI.loadDocument(dataUrl || base64ToBlob(fileData!), {
        filename: `${filename}.pdf` || "document.pdf",
      });

      // set required fields msg to false in case it was set from another view
      setShowRequiredFieldsMessage(false);

      const documentLoadedListener = async () => {
        setDocumentReadyId(dataId);

        // set layout mode
        updateLayoutMode();

        // auto-focus in the iframe window
        // (to allow keyboard arrow scrolling without manual user-click to focus - effective in singlePageView only)
        if (autoFocus) {
          UI.iframeWindow.focus();
        }

        // set pages state for progress bar
        setCurrentPage(documentViewer.getCurrentPage());
        setPageCount(documentViewer.getPageCount());

        documentViewer
          .getAnnotationsLoadedPromise()
          .then(() => setAnnotationsReadyId(dataId));

        // remove event listener imed after doc has been loaded
        documentViewer.removeEventListener(
          Events.DOCUMENT_LOADED,
          documentLoadedListener,
        );
      };

      documentViewer.addEventListener(
        Events.DOCUMENT_LOADED,
        documentLoadedListener,
      );

      return () => {
        setDocumentReadyId(undefined);
        setAnnotationsReadyId(undefined);
      };
    }, [
      autoFocus,
      dataId, // id dependency to reload and reset doc when switching between docs with the same dataUrl/fileData
      dataUrl,
      fileData,
      filename,
      updateLayoutMode,
      viewerInstance,
    ]);

    // load document annotations
    useEffect(() => {
      if (
        !viewerInstance ||
        documentReadyId !== dataId ||
        annotationsReadyId !== dataId
      )
        return;

      const {
        Core: { Annotations, annotationManager },
        UI,
      } = viewerInstance;

      // style highlightFields
      if (highlightFields?.length) {
        Annotations.WidgetAnnotation.getContainerCustomStyles = (widget) => {
          const highlight = highlightFields?.includes(widget.fieldName);
          return {
            ...defaultWidgetContainerStyles,
            border: highlight ? "2px solid #FF3E36" : "none",
          };
        };
      }

      (async () => {
        // check for updated annotations
        if (annotations !== viewerAnnotations) {
          // deselect any selected annotations before importing
          annotationManager.deselectAllAnnotations();

          if (annotations) {
            await annotationManager.importAnnotations(annotations);

            // default specified annotations to hidden
            if (hideAnnotationsBySubject) {
              annotationManager
                .getAnnotationsList()
                .filter((a) => a.Subject === hideAnnotationsBySubject)
                .forEach((a) => (a.Hidden = true));
            }
          }

          // set viewer annotations in context after doc annotations have been loaded
          setViewerAnnotations(annotations);
        }

        if (viewerMode === ViewerModes.Edit) {
          const fieldManager = annotationManager.getFieldManager();

          // set initial field values for unsaved changes comparison
          const initialFieldValues = {};

          fieldManager.getFields().forEach((field) => {
            initialFieldValues[field.name] = {
              initialValue: field.value,
              value: field.value,
            };

            // check for pre-fills
            if (fieldPrefills && fieldPrefills[field.name]) {
              const prefillValue = fieldPrefills[field.name];
              // pre-fill field
              field.setValue(prefillValue);
              // override field initialFieldValues
              initialFieldValues[field.name] = {
                initialValue: prefillValue,
                value: prefillValue,
              };
            }

            field.addEventListener("change", (f) => {
              setFieldValues((vals) => ({
                ...vals,
                [f.name]: { ...vals[f.name], value: f.value },
              }));
            });
          });

          setFieldValues(initialFieldValues);
        }

        // update viewer mode in context
        setViewerMode(viewerMode);

        if (viewerMode !== ViewerModes.Comment) {
          // disable context menu outside of comment mode
          viewerInstance.disableElements(["contextMenuPopup"]);

          // disable text selection outside of comment mode
          UI.disableFeatures([UI.Feature.TextSelection]);

          // set annotations to read-only outside of comment mode
          annotationManager.enableReadOnlyMode();
        } else {
          viewerInstance.enableElements(["contextMenuPopup"]);
          UI.enableFeatures([UI.Feature.TextSelection]);
          annotationManager.disableReadOnlyMode();
        }

        // set fields to read-only when not in edit mode
        annotationManager.getAnnotationsList().forEach((a) => {
          if (a.fieldFlags) {
            a.fieldFlags.set(
              Annotations.WidgetFlags.READ_ONLY,
              viewerMode !== ViewerModes.Edit,
            );
          }
        });
      })();

      return () => {
        setViewerAnnotations(undefined);
        setFieldValues({});
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      annotations,
      dataId,
      annotationsReadyId,
      documentReadyId,
      setViewerAnnotations,
      setViewerMode,
      viewerInstance,
      viewerMode,
    ]);

    const onSave = useCallback(
      async (submit = false, submitConfirmed = false) => {
        if (viewerInstance && handleSave) {
          setShowRequiredFieldsMessage(false);

          const { annotationManager } = viewerInstance.Core;

          if (submit) {
            if (validateRequiredFields) {
              const requiredFieldsFilled = annotationManager
                .getFieldManager()
                .areRequiredFieldsFilled();

              if (!requiredFieldsFilled) {
                setShowRequiredFieldsMessage(true);
                // scroll to first page when required fields are incomplete
                if (currentPage > 1) {
                  viewerInstance?.Core.documentViewer.setCurrentPage(1, true);
                }
                return;
              }
            }

            let fieldValidationError = false;
            if (fieldValidation) {
              const field = annotationManager
                .getFieldManager()
                .getField(fieldValidation.fieldName);
              fieldValidationError =
                !!field && field.value != fieldValidation.expectedValue;
            }

            if (
              !submitConfirmed ||
              fieldValidationError // re-prompt if fieldValidationError has persisted even after submit confirmation
            ) {
              setShowSubmitConfirmation(true);
              setShowSubmitValidationMessage(fieldValidationError);
              return;
            }
          }

          setFieldValues({}); // ensures that doesnt prompt for unsaved changes when exiting

          const fields = annotationManager
            .getFieldManager()
            .getFields()
            .map((field) => ({ name: field.name, value: field.value }));

          await handleSave(submit, fields);
        }
      },
      [
        currentPage,
        fieldValidation,
        handleSave,
        validateRequiredFields,
        viewerInstance,
      ],
    );

    const onConfirmSubmission = useCallback(() => {
      if (fieldValidation && showSubmitValidationMessage) {
        // update validated field value before submission
        const { annotationManager } = viewerInstance.Core;

        const field = annotationManager
          .getFieldManager()
          .getField(fieldValidation.fieldName);

        if (field) field.setValue(fieldValidation.expectedValue);

        setShowSubmitValidationMessage(false);
      }

      setShowSubmitConfirmation(false);
      onSave(true, true);
    }, [fieldValidation, onSave, showSubmitValidationMessage, viewerInstance]);

    return showError ? (
      <ErrorDisplayStyled>
        <img alt="no-preview" src={empty} height={180} />
        <Typography variant="subtitle1" mt={4}>
          Cannot display {name} preview
        </Typography>
        {errorDisplay}
      </ErrorDisplayStyled>
    ) : (
      <>
        <RouterPrompt when={hasUnsavedChanges} {...unsavedChangesPrompt} />
        <PdfContainerStyled>
          {documentReadyId ? (
            <PdfActionsContainerStyled>
              <Box width="100%" mr="16px">
                {singlePageView && showProgressBar && !!currentPage && (
                  <>
                    <ProgressHeaderStyled>
                      <Typography variant="caption">
                        Step {currentPage} of {pageCount}
                      </Typography>
                      <Box>
                        {currentPage > 1 && (
                          <Button
                            variant="text"
                            size="small"
                            onClick={() =>
                              viewerInstance?.Core.documentViewer.setCurrentPage(
                                currentPage - 1,
                                true,
                              )
                            }
                          >
                            Previous
                          </Button>
                        )}
                        {currentPage < pageCount && (
                          <Button
                            variant="text"
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={() =>
                              viewerInstance?.Core.documentViewer.setCurrentPage(
                                currentPage + 1,
                                true,
                              )
                            }
                          >
                            Next
                          </Button>
                        )}
                      </Box>
                    </ProgressHeaderStyled>
                    <LinearProgress
                      sx={{ height: "20px", width: "100%" }}
                      variant="determinate"
                      value={(100 * currentPage) / pageCount}
                    />
                  </>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                {customButtons}
                {viewerMode === ViewerModes.Edit && (
                  <>
                    {showSave && (
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ ml: 1 }}
                        onClick={() => onSave()}
                      >
                        Save
                      </Button>
                    )}
                    {showSubmit && (
                      <Tooltip title={submitDisabledDescription}>
                        <Box>
                          <Button
                            variant="contained"
                            size="large"
                            sx={{ ml: 2 }}
                            onClick={() => onSave(true)}
                            disabled={!!submitDisabledDescription}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                    {showRequiredFieldsMessage && (
                      <ErrorTextStyled
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          top: "52px",
                          right: 0,
                        }}
                      >
                        Please complete required fields
                      </ErrorTextStyled>
                    )}
                    {showSubmitConfirmation && (
                      <ConfirmationDialog
                        confirmText="Continue"
                        handleClose={() => {
                          setShowSubmitConfirmation(false);
                          setShowSubmitValidationMessage(false);
                        }}
                        handleConfirm={onConfirmSubmission}
                        open={true}
                        message={`Please assure that ${
                          currentPage !== pageCount
                            ? `you have reached the last page of the ${name} and`
                            : "you have"
                        } ${
                          submitConfirmationDescription ||
                          "answered all the questions"
                        } before submitting.`}
                        messageContent={
                          fieldValidation && showSubmitValidationMessage ? (
                            <ErrorTextStyled variant="caption">
                              <b>Note: </b>
                              {fieldValidation.message}
                            </ErrorTextStyled>
                          ) : (
                            submitConfirmationTag
                          )
                        }
                        title={`Submit ${name}`}
                        type={ConfirmationDialogTypes.Warning}
                      />
                    )}
                  </>
                )}
                {allowLayoutChange && (
                  <ButtonMenu
                    button={
                      <HeaderIconButtonStyled
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        <MoreVertIcon />
                      </HeaderIconButtonStyled>
                    }
                    menuItems={[
                      {
                        label: `Change to ${
                          singlePageView ? "smooth scrolling" : "single page"
                        } view`,
                        onClick: () => updateLayoutMode(!singlePageView),
                      },
                    ]}
                  />
                )}
              </Box>
            </PdfActionsContainerStyled>
          ) : (
            !uiLoading && (
              <LoadingContainerStyled>
                <Fade in={true} timeout={500}>
                  <CircularProgress size={100} />
                </Fade>
              </LoadingContainerStyled>
            )
          )}
          <div className="webviewer" ref={viewerRef} />
        </PdfContainerStyled>
      </>
    );
  },
);
