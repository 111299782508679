import React from "react";
import { useSelector } from "react-redux";
import { sysSelectors } from "../../state";
import { FacilityFilesAccessWrapper } from "./components";
import { FacilityFileForm } from "../adminShared/components";

export const FacilityFileFormPage = React.memo(
  /**
   *
   */
  function FacilityFileFormPage() {
    const groupFilter = useSelector(sysSelectors.groupFilter);

    return (
      <FacilityFilesAccessWrapper>
        <FacilityFileForm groupId={Number(groupFilter?.id)} />
      </FacilityFilesAccessWrapper>
    );
  },
);
