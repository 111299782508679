import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FacilityCardStyled = styled(`div`)(({ theme }) => ({
  padding: "24px",
  marginBottom: "24px",
  borderRadius: "3px",
  border: `1px solid ${theme.palette.divider}`,
  "&:hover a": {
    visibility: "unset !important",
  },
}));

interface FacilityStatTagStyledProps {
  type: "announcements" | "audits" | "requiredDocs";
}

export const FacilityStatTagStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "type",
})<FacilityStatTagStyledProps>(({ theme, type }) => {
  let color = "",
    backgroundColor = "";
  switch (type) {
    case "announcements":
      backgroundColor = "#F3ECFC";
      color = theme.palette.info.dark;
      break;
    case "audits":
      backgroundColor = "#FFF7F1";
      color = theme.palette.warning.dark;
      break;
    case "requiredDocs":
      backgroundColor = "#FDEEEF";
      color = theme.palette.error.dark;
      break;
  }

  return {
    color,
    backgroundColor,
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    padding: "2px 4px",
    marginRight: "12px",
    cursor: "pointer",
    "& svg": {
      height: "18px",
    },
    "&:hover": {
      opacity: ".8",
    },
  };
});

export const ReportChangeLinkStyled = styled(Link)(() => ({
  color: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
}));
