import React from "react";
import { EntityTypes } from "../../lib/constants";
import { GroupFacilityRegionWizard } from "../admin/components";

export const PendingFacilityPage = React.memo(
  /**
   *
   */
  function PendingFacilityPage() {
    return <GroupFacilityRegionWizard type={EntityTypes.Facility} />;
  },
);
