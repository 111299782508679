import React, { useCallback, useMemo, useState } from "react";
import {
  GridColDef,
  GRID_CHECKBOX_SELECTION_FIELD,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import {
  CheckboxInput,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  SelectInput,
  SuccessTextStyled,
} from "../../../../components";
import { ListActionsProps } from "../../../../components";
import { ListTypes } from "../../../../state/lists/state";
import {
  AuditDashboardRecord,
  BasicAuditTemplate,
  listsActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { SendAuditsDialog } from "./SendAuditsDialog";
import {
  asArray,
  formatDate,
  Navigation,
  RouterLink,
  useLocation,
} from "../../../../lib";
import { AuditStatusChipStyled } from "./AuditsLists.styles";
import { GroupFacilityRegionLogosDisplay } from "../groupsAndFacilities/general/GroupFacilityRegionLogosDisplay";
import { AuditsExportDialog } from "./details/AuditsExportDialog";
import { AdminPages } from "../..";
import { styled } from "@mui/material/styles";
import { AuditsPageTabs, FacilityStatuses } from "../../../../lib/constants";
import {
  dividerRowClassName,
  subdividerRowClassName,
} from "../../../../components/lists/List.styles";

const filterQueryKey = {
  groupIDs: "groups",
  includeInactive: "showInactive",
  includeOnboarding: "showOnboarding",
  includeOnHold: "showOnHold",
  regionIDs: "regions",
  templateIds: "ts", // shortened name to avoid exceeding max url query length
  text: "search",
};

const getDefaultTemplateColumns = (templates: BasicAuditTemplate[]) =>
  templates.filter((t) => !t.infrequent); // default to all standard templates

interface ManageAuditColumnsDialogProps {
  appliedTemplateColumns: BasicAuditTemplate[];
  handleClose: () => void;
  handleSave: (templateIds: number[]) => void;
}

const ManageAuditColumnsDialog = ({
  appliedTemplateColumns,
  handleClose,
  handleSave,
}: ManageAuditColumnsDialogProps) => {
  const auditTemplates = useSelector(sysSelectors.allAuditTemplates);

  const standardTemplates = useMemo(
    () => auditTemplates.filter((t) => !t.infrequent),
    [auditTemplates],
  );

  const infrequentTemplates = useMemo(
    () => auditTemplates.filter((t) => t.infrequent),
    [auditTemplates],
  );

  const [templateIds, setTemplateIds] = useState<number[]>(
    appliedTemplateColumns.map((t) => t.id),
  );

  const onSelectAll = useCallback(
    (checked: boolean, infrequent = false) => {
      const allTemplates = infrequent ? infrequentTemplates : standardTemplates;
      const allTemplateIds = allTemplates.map((t) => t.id);

      if (checked) {
        setTemplateIds([
          ...templateIds,
          ...allTemplateIds.filter((id) => !templateIds.includes(id)),
        ]);
      } else {
        setTemplateIds(
          templateIds.filter((id) => !allTemplateIds.includes(id)),
        );
      }
    },
    [infrequentTemplates, standardTemplates, templateIds],
  );

  const onSave = useCallback(() => {
    const defaultTemplates = getDefaultTemplateColumns(auditTemplates);
    const defaultSelected =
      templateIds.length === defaultTemplates.length &&
      defaultTemplates.map((t) => t.id).every((id) => templateIds.includes(id));

    handleSave(defaultSelected ? [] : templateIds); // if default is selected, set the selection to an empty array
    handleClose();
  }, [auditTemplates, handleClose, handleSave, templateIds]);

  return (
    <Dialog open={true} PaperProps={{ sx: { width: "600px" } }}>
      <DialogTitle variant="h6">Manage columns</DialogTitle>
      <DialogContent sx={{ "& div:not(:last-child)": { marginBottom: "8px" } }}>
        {auditTemplates.length ? (
          <>
            {standardTemplates.length > 0 && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography fontWeight="500">Standard Templates</Typography>
                  <CheckboxInput
                    checked={standardTemplates.every((t) =>
                      templateIds.includes(t.id),
                    )}
                    name="all"
                    onChange={(_, checked) => onSelectAll(checked)}
                    label="Select all"
                  />
                </Box>
                {standardTemplates.map((template) => (
                  <Box key={template.id}>
                    <CheckboxInput
                      checked={templateIds.includes(template.id)}
                      name={template.name}
                      onChange={(_, checked) =>
                        setTemplateIds((ids) =>
                          checked
                            ? [...ids, template.id]
                            : ids.filter((id) => id !== template.id),
                        )
                      }
                      label={template.name}
                    />
                  </Box>
                ))}
              </>
            )}
            {infrequentTemplates.length > 0 && (
              <>
                <Divider sx={{ margin: "16px 0" }} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography fontWeight="500">Infrequent Templates</Typography>
                  <CheckboxInput
                    checked={infrequentTemplates.every((t) =>
                      templateIds.includes(t.id),
                    )}
                    name="all"
                    onChange={(_, checked) => onSelectAll(checked, true)}
                    label="Select all"
                  />
                </Box>
                {infrequentTemplates.map((template) => (
                  <Box key={template.id}>
                    <CheckboxInput
                      checked={templateIds.includes(template.id)}
                      name={template.name}
                      onChange={(_, checked) =>
                        setTemplateIds((ids) =>
                          checked
                            ? [...ids, template.id]
                            : ids.filter((id) => id !== template.id),
                        )
                      }
                      label={template.name}
                    />
                  </Box>
                ))}
              </>
            )}
          </>
        ) : (
          <Typography>
            Configure audit templates{" "}
            <Link
              component={RouterLink}
              to={Navigation.url(AdminPages.audits, {
                query: { tab: AuditsPageTabs.Templates },
              })}
              sx={{ fontSize: "inherit" }}
            >
              here
            </Link>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          onClick={handleClose}
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button
          disabled={!templateIds.length}
          variant="contained"
          size="large"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface AuditsDashboardListActionsProps extends ListActionsProps {
  auditTemplateColumns: BasicAuditTemplate[];
  refreshList: () => void;
  selectedRecords: AuditDashboardRecord[];
}

function AuditsDashboardListActions({
  auditTemplateColumns,
  onFilterChange = () => {},
  query,
  refreshList,
  selectedRecords,
}: AuditsDashboardListActionsProps) {
  const {
    groups = [],
    regions = [],
    search = "",
    showInactive = false,
    showOnboarding = false,
    showOnHold = false,
  } = query;

  const groupOptions = useSelector(sysSelectors.allGroups);

  const allRegions = useSelector(sysSelectors.allRegions);
  const regionOptions = useMemo(() => {
    const groupsFilter = asArray(groups);
    return groupsFilter.length
      ? allRegions.filter((r) => groupsFilter.includes(r.groupID))
      : allRegions;
  }, [allRegions, groups]);

  const [filtersOpen, setFiltersOpen] = useState(
    !!(search || asArray(groups).length || asArray(regions).length),
  );

  const [showSendAuditsDialog, setShowSendAuditsDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showManageColumnsDialog, setShowManageColumnsDialog] = useState(false);

  const handleCloseSendAuditsDialog = useCallback(
    (refresh: boolean) => {
      setShowSendAuditsDialog(false);
      if (refresh) {
        refreshList();
      }
    },
    [refreshList],
  );

  const onGroupsFilterChange = useCallback(
    (name, value) => {
      // if there is an applied regions filter, filter the applied regions to only those included in the new group selection
      const regionsFilter = asArray(regions);
      if (regionsFilter.length) {
        const updatedRegions = regionsFilter.filter((regionId) =>
          value.includes(allRegions.find((r) => r.id === regionId)?.groupID),
        );
        onFilterChange(name, value, { regions: updatedRegions });
      } else {
        onFilterChange(name, value);
      }
    },
    [allRegions, onFilterChange, regions],
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Manage</Typography>
        <Box>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setShowManageColumnsDialog(true)}
            sx={{ ml: 1 }}
          >
            Show/hide audits
          </Button>
          {showManageColumnsDialog && (
            <ManageAuditColumnsDialog
              appliedTemplateColumns={auditTemplateColumns}
              handleClose={() => setShowManageColumnsDialog(false)}
              handleSave={(ids) =>
                onFilterChange(filterQueryKey.templateIds, ids)
              }
            />
          )}
          <Button
            disabled={!selectedRecords.length}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setShowSendAuditsDialog(true)}
          >
            Send audits
          </Button>
          {showSendAuditsDialog && (
            <SendAuditsDialog
              facilities={selectedRecords.map((r) => ({
                id: r.facilityID,
                name: r.facilityName,
              }))}
              handleClose={handleCloseSendAuditsDialog}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setShowExportDialog(true)}
          >
            Export
          </Button>
          {showExportDialog && (
            <AuditsExportDialog
              handleClose={() => setShowExportDialog(false)}
            />
          )}
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search facilities"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Group"
            name="groups"
            value={asArray(groups)}
            onChange={onGroupsFilterChange}
            options={groupOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            groupOpts={true}
            label="Region"
            name="regions"
            value={asArray(regions)}
            onChange={onFilterChange}
            options={regionOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!showOnboarding}
            label="Show onboarding"
            name="showOnboarding"
            onChange={onFilterChange}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!showOnHold}
            label="Show on hold"
            name="showOnHold"
            onChange={onFilterChange}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!showInactive}
            label="Show inactive"
            name="showInactive"
            onChange={onFilterChange}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    minWidth: 280,
    maxWidth: 380,
    border: `1px solid ${theme.palette.divider}`,
    padding: "16px",
  },
}));

function AuditCell({
  row,
  templateId,
}: {
  row: AuditDashboardRecord;
  templateId: number;
}) {
  const auditDetails = row.audits.find((a) => a.templateID === templateId);
  const AuditTooltip = auditDetails?.isArchived ? Tooltip : CustomTooltip;

  return auditDetails ? (
    <Box textAlign="center">
      <AuditTooltip
        title={
          auditDetails.isArchived ? (
            "Archived"
          ) : (
            <>
              <Box display="flex" alignItems="center" mb={1}>
                <GroupFacilityRegionLogosDisplay
                  groupLogoUrl={row.groupLogoUrl}
                  logoUrl={row.facilityLogoUrl}
                  name={row.facilityName}
                />
                <Box ml={2}>
                  <Typography variant="body2">{row.facilityName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {row.groupName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="subtitle1">{auditDetails.name}</Typography>
              {auditDetails.statusUpdatedOn && (
                <Typography variant="caption" color="text.secondary">
                  Updated on {formatDate(auditDetails.statusUpdatedOn)}
                </Typography>
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <AuditStatusChipStyled
                  label={auditDetails.statusDisplay}
                  size="small"
                  status={auditDetails.status}
                />
                <Button
                  variant="text"
                  size="small"
                  onClick={() =>
                    Navigation.go(AdminPages.audit.path, {
                      params: { id: auditDetails.id },
                    })
                  }
                >
                  View
                </Button>
              </Box>
            </>
          )
        }
      >
        <Box>
          <AuditStatusChipStyled
            box={true}
            label={auditDetails.statusDisplay}
            size="small"
            status={auditDetails.status}
            sx={
              // override status color for archived audits
              auditDetails.isArchived
                ? {
                    color: "text.primary",
                    backgroundColor: "neutral.light",
                  }
                : {}
            }
          />
        </Box>
      </AuditTooltip>
      <Typography variant="caption" color="text.secondary" mt={1}>
        on {formatDate(auditDetails.statusUpdatedOn)}
      </Typography>
    </Box>
  ) : null;
}

export const AuditsDashboardList = React.memo(
  /**
   *
   */
  function AuditsDashboardList() {
    const dispatch = useAppDispatch();
    const {
      query: { [filterQueryKey.templateIds]: queryTemplateIds },
    } = useLocation();

    const auditTemplates = useSelector(sysSelectors.allAuditTemplates);

    const [selectedRecords, setSelectedRecords] = useState<
      AuditDashboardRecord[]
    >([]);

    const auditTemplateColumns = useMemo(() => {
      const columnTemplateIds = asArray(queryTemplateIds as string | []);
      return columnTemplateIds.length
        ? auditTemplates.filter((t) => columnTemplateIds.includes(t.id)) // retrieve templates included in selection from the query
        : getDefaultTemplateColumns(auditTemplates); // use default template columns if none are selected
    }, [auditTemplates, queryTemplateIds]);

    const columns: GridColDef[] = useMemo(() => {
      return [
        {
          field: "facilityName",
          headerName: "Group/Facility",
          width: 280,
          renderCell: ({ row }: { row: AuditDashboardRecord }) => (
            <Box>
              <Link
                component={RouterLink}
                onClick={(e) => e.stopPropagation()}
                to={Navigation.url(AdminPages.facilityAudits, {
                  params: { id: row.facilityID },
                })}
                color="text.primary"
              >
                {row.facilityName}
              </Link>
              <Typography variant="body2" color="text.secondary" mt="4px">
                {row.groupName}
              </Typography>
              {row.regionName && (
                <Typography variant="caption" color="text.secondary" mt="4px">
                  {row.regionName}
                </Typography>
              )}
            </Box>
          ),
        },
        {
          field: "auditSentThisQuarter",
          headerName: "Current Quarter",
          width: 200,
          renderCell: ({ row }: { row: AuditDashboardRecord }) =>
            row.auditSentThisQuarter ? (
              <SuccessTextStyled variant="body1">Sent</SuccessTextStyled>
            ) : (
              ""
            ),
          sortable: false,
        },
        ...auditTemplateColumns.map((t) => ({
          field: t.name,
          headerName: t.name,
          width: 200,
          renderCell: ({ row }: { row: AuditDashboardRecord }) => (
            <AuditCell row={row} templateId={t.id} />
          ),
          sortable: false,
        })),
      ];
    }, [auditTemplateColumns]);

    const resultsFormatter = useCallback((results) => {
      return results.map((r: AuditDashboardRecord, i) => {
        const nextResult = results[i + 1];
        return {
          ...r,
          id: r.facilityID,
          isLastOfGroup: nextResult && r.groupID !== nextResult.groupID,
          isLastOfRegion:
            nextResult &&
            r.groupID === nextResult.groupID &&
            r.regionID !== nextResult.regionID,
        };
      });
    }, []);

    const refreshList = useCallback(() => {
      setSelectedRecords([]);
      dispatch(
        listsActions.refreshList(ListTypes.auditDashboard, resultsFormatter),
      );
    }, [dispatch, resultsFormatter]);

    return (
      <List
        actions={
          <AuditsDashboardListActions
            auditTemplateColumns={auditTemplateColumns}
            refreshList={refreshList}
            selectedRecords={selectedRecords}
          />
        }
        columns={columns}
        checkboxSelection={true}
        dataModifier={resultsFormatter}
        filterQueryKey={filterQueryKey}
        getRowClassName={({ row }: { row: AuditDashboardRecord }) =>
          row.isLastOfGroup
            ? dividerRowClassName
            : row.isLastOfRegion
            ? subdividerRowClassName
            : ""
        }
        horizontalScrollArrows={true}
        isRowSelectable={({ row }: { row: AuditDashboardRecord }) =>
          row.facilityStatus !== FacilityStatuses.Inactive
        }
        name="audits"
        onCheckboxSelection={(selection) => setSelectedRecords(selection)}
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_FIELD, "facilityName"],
        }}
        rowHeight={84}
        selection={selectedRecords}
        stickyHeader={true}
        type={ListTypes.auditDashboard}
      />
    );
  },
);
