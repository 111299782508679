import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  authSelectors,
  sysSelectors,
  uiSelectors,
  useSelector,
} from "../../../state";
import { styled } from "@mui/material/styles";
import FacilityFilter from "./FacilityFilter";
import GroupFilter from "./GroupFilter";
import { PortalUserTypes } from "../../../lib/constants";
import { useLocation } from "../../../lib";
import { maxPageWidth } from "../MainLayout.styles";

export const facilityFilesPath = "/files";
export const facilityHeaderHeight = 42;

export const FacilityHeaderContainerStyled = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  zIndex: theme.zIndex.appBar + 1,
  height: facilityHeaderHeight,
  width: "100%",
  maxWidth: maxPageWidth,
  padding: "8px 24px",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.dark,
  "& span, p, svg, input": {
    color: `${theme.palette.background.default} !important`,
    fontSize: "14px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  "& .MuiInput-root": {
    height: "fit-content",
  },
  "& .MuiSelect-select": {
    paddingBottom: "0",
  },
  "& .MuiAutocomplete-inputRoot": {
    paddingBottom: "0px !important",
    "&.MuiInputBase-adornedEnd": {
      paddingRight: "16px !important",
    },
  },
}));

export default function FacilityHeader() {
  const location = useLocation();

  const userType = useSelector(authSelectors.userType);
  const isFacilityAdmin = userType === PortalUserTypes.FacilityAdmin;

  const facilityOptions = useSelector(sysSelectors.allFacilities);
  const facilityFilter = useSelector(sysSelectors.facilityFilter);
  const uiPageTitle = useSelector(uiSelectors.pageTitle);

  const facility = useMemo(() => {
    if (facilityFilter) {
      return facilityFilter;
    }
    if (uiPageTitle?.facilityId) {
      return facilityOptions.find((f) => f.id === uiPageTitle.facilityId);
    }
  }, [facilityFilter, facilityOptions, uiPageTitle?.facilityId]);

  const showGroupFilter = useMemo(
    () => location.pathname.startsWith(facilityFilesPath), // show on facility admin facility files pages
    [location.pathname],
  );

  // display no facility header for non facility admin users without a facility selected via uiPageTitle.facilityId
  if (!isFacilityAdmin && !facility) return null;

  return (
    <FacilityHeaderContainerStyled>
      {uiPageTitle?.facilityId ? (
        <Typography>{facility?.name}</Typography>
      ) : (
        <>
          {showGroupFilter && <GroupFilter />}
          <FacilityFilter />
        </>
      )}
      {facility?.stateName && (
        <Box display="flex" ml={4}>
          <Typography sx={{ mr: 1, fontWeight: "initial !important" }}>
            State
          </Typography>
          <Typography>{facility.stateName}</Typography>
        </Box>
      )}
      {!showGroupFilter && facility?.groupName && (
        <Box display="flex" ml={4}>
          <Typography sx={{ mr: 1, fontWeight: "initial !important" }}>
            Part of
          </Typography>
          <Typography>{facility.groupName}</Typography>
        </Box>
      )}
    </FacilityHeaderContainerStyled>
  );
}
