import React, { useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import {
  CheckboxInput,
  CheckIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  LongTooltip,
  SearchInput,
  ToggleInput,
  VisibilityOffIcon,
} from "../../../../components";
import { Navigation, RouterLink } from "../../../../lib";
import { ListActionsProps } from "../../../../components";
import { sysSelectors, TrainingRecord, useSelector } from "../../../../state";
import { FacilityAdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";
import { TrainingTypes } from "../../../../lib/constants";

const filterQueryKey = {
  isRequiredOnHire: "onHire",
  isRequiredAnnually: "annual",
  text: "search",
  type: "type",
};

function TrainingsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { trainingTypes: trainingTypeOptions = [] } = useSelector(
    sysSelectors.systemSettings,
  );

  const { annual = false, onHire = false, search = "", type = "" } = query;

  const onChangeType = useCallback(
    (name, value) => {
      onFilterChange(
        name,
        value,
        // if type is changed from recommended, clear annual/onHire flag
        value !== TrainingTypes.Required && (annual || onHire)
          ? { annual: "", onHire: "" }
          : undefined,
      );
    },
    [annual, onFilterChange, onHire],
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Box display="flex">
          <Typography variant="h5">Trainings</Typography>
          <ToggleInput
            name="type"
            onChange={onChangeType}
            options={[...trainingTypeOptions, { id: "", name: "All" }]}
            value={type}
            sx={{ ml: 2 }}
          />
          {type === TrainingTypes.Required && (
            <>
              <CheckboxInput
                checked={!!annual}
                label="Required annually"
                name="annual"
                onChange={onFilterChange}
                sx={{ ml: 2 }}
              />
              <CheckboxInput
                checked={!!onHire}
                label="Required on hire"
                name="onHire"
                onChange={onFilterChange}
                sx={{ ml: 1 }}
              />
            </>
          )}
        </Box>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled>
        <SearchInput
          variant="outlined"
          placeholder="Search trainings"
          name="search"
          value={search}
          debounced={true}
          onSearch={(_search) => onFilterChange("search", _search)}
        />
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const TrainingsList = React.memo(
  /**
   *
   */
  function TrainingsList() {
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => (facilityFilter ? { facilityIDs: [facilityFilter.id] } : undefined),
      [facilityFilter],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Name",
          flex: 1.5,
        },
        {
          field: "type",
          headerName: "Type",
          flex: 1,
        },
        {
          field: "isRequiredOnHire",
          headerName: "Required on hire",
          flex: 1,
          renderCell: ({ row }: { row: TrainingRecord }) =>
            row.isRequiredOnHire ? <CheckIcon /> : "",
        },
        {
          field: "isRequiredAnnually",
          headerName: "Required annually",
          flex: 1,
          renderCell: ({ row }: { row: TrainingRecord }) =>
            row.isRequiredAnnually ? <CheckIcon /> : "",
        },
        {
          field: "policies",
          headerName: "Related Policy",
          flex: 1,
          renderCell: ({ row }: { row: TrainingRecord }) =>
            row.policy ? (
              <LongTooltip title={row.policy.title}>
                <Link
                  component={RouterLink}
                  onClick={(e) => e.stopPropagation()}
                  to={Navigation.url(FacilityAdminPages.policy, {
                    params: { id: row.policy.id },
                  })}
                >
                  {row.policy.title.replace(row.policy.name, "").trim()}
                </Link>
              </LongTooltip>
            ) : (
              ""
            ),
          sortable: false,
        },
        ...(!facilityFilter?.id
          ? [
              {
                field: "actions",
                headerName: "",
                width: 80,
                renderCell: () => (
                  <Tooltip title="You are currently viewing trainings across all facilities. Select a facility from the blue bar at the top of the screen to open this training.">
                    <VisibilityOffIcon sx={{ color: "neutral.main" }} />
                  </Tooltip>
                ),
                sortable: false,
              },
            ]
          : []),
      ],
      [facilityFilter?.id],
    );

    const onRowClick = useCallback(({ row }: { row: TrainingRecord }) => {
      Navigation.go(FacilityAdminPages.training, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<TrainingsListActions />}
        columns={columns}
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        name="trainings"
        onRowClick={facilityFilter?.id ? onRowClick : undefined}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.trainings}
      />
    );
  },
);
