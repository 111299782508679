import React from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { StatusPage } from "./components";
import unauthorized from "../../assets/img/unauthorized.svg";
import { styled } from "@mui/material/styles";

const LinkTextStyled = styled(`span`)(({ theme }) => ({
  color: theme.palette.primary.light,
  cursor: "pointer",
}));

export const UnauthorizedPage = React.memo(
  /**
   *
   */
  function UnauthorizedPage() {
    const dispatch = useDispatch();
    const showHelpDialog = () => {
      dispatch(uiActions.setHelpDialogOpen(true));
    };

    return (
      <StatusPage
        img={unauthorized}
        title="Access Denied"
        subtitle={
          <>
            You are not authorized to access this page. If you think this is a
            mistake,{" "}
            <LinkTextStyled onClick={showHelpDialog}>
              contact CCG
            </LinkTextStyled>
            .
          </>
        }
      />
    );
  },
);
