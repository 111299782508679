import React, { useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FacilityAdminPages } from "../..";
import { InfoIcon } from "../../../../components";
import { Navigation } from "../../../../lib";
import {
  Audit,
  AuditVersion,
  BasicAudit,
  facilityAdminActions,
  NameValuePair,
  sharedActions,
  useAppDispatch,
} from "../../../../state";
import {
  AuditCertificatePreviewDialog,
  AuditFormViewer,
  AuditBannerStyled,
} from "../../../adminShared/components";
import { AuditSavedDialog } from "./AuditSavedDialog";
import { StatusPage } from "../../../shared/components";
import { AuditStatuses } from "../../../../lib/constants";
import completed from "../../../../assets/img/audit_completed.svg";

interface AuditViewProps {
  audit: Audit;
  auditVersion?: AuditVersion;
  refreshAudit: () => Promise<void>;
  setAudit: React.Dispatch<React.SetStateAction<Audit | undefined>>;
}

export const AuditView = React.memo(
  /**
   *
   */
  function AuditView({
    audit,
    auditVersion,
    refreshAudit,
    setAudit,
  }: AuditViewProps) {
    const dispatch = useAppDispatch();

    const [showCertificatePreview, setShowCertificatePreview] = useState(false);
    const [showSavedDialog, setShowSavedDialog] = useState(false);
    const [showCompletedView, setShowCompletedView] = useState(false);
    const [nextAudit, setNextAudit] = useState<BasicAudit>();

    const onSaveAudit = useCallback(
      async (
        annotations: string,
        fields: NameValuePair[],
        progressPageNumber: number,
        submit: boolean,
      ) => {
        const versionForSubmission = {
          ...auditVersion,
          auditUrl: audit.url,
          annotations,
          fields,
          progressPageNumber,
        };

        // if version was previously submitted, clear the id to create a new version
        if (auditVersion?.submitted) {
          versionForSubmission.id = undefined;
        }

        // set version submitted flag
        versionForSubmission.submitted = submit;

        const saved = await dispatch(
          sharedActions.submitAuditVersion(audit.id, versionForSubmission),
        );

        if (saved) {
          await refreshAudit();

          if (submit) {
            const nextAudit = await dispatch(
              facilityAdminActions.getNextAudit(audit.id),
            );
            if (nextAudit) {
              setNextAudit(nextAudit);
            }
            setShowCompletedView(true);
          } else {
            setShowSavedDialog(true);
          }
        }

        return !!saved;
      },
      [audit.id, audit.url, auditVersion, dispatch, refreshAudit],
    );

    return showCompletedView ? (
      <StatusPage
        img={completed}
        tag={
          nextAudit ? (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                Navigation.replace(
                  FacilityAdminPages.audit,
                  {
                    params: { id: nextAudit.id },
                  },
                  { resumePrompt: true },
                );
                setShowCompletedView(false);
                setNextAudit(undefined);
              }}
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={() => Navigation.go(FacilityAdminPages.audits)}
            >
              Back to audits
            </Button>
          )
        }
        subtitle={
          nextAudit
            ? `Continue ${
                nextAudit.status === AuditStatuses.SentNotStarted
                  ? "to start"
                  : nextAudit.status === AuditStatuses.ResentCorrectionsNeeded
                  ? "to revise"
                  : "working on"
              } the next audit: ${nextAudit.name}`
            : ""
        }
        title={`${audit.name} completed`}
      />
    ) : (
      <>
        {audit.status === AuditStatuses.Completed ? (
          <AuditBannerStyled>
            <InfoIcon />
            <Typography variant="body2" ml={2} mr={1}>
              Your audit is complete!
            </Typography>
          </AuditBannerStyled>
        ) : audit.status === AuditStatuses.CompletedCertificateSent ? (
          <>
            <AuditBannerStyled withAction={true}>
              <Box display="flex" alignItems="center">
                <InfoIcon />
                <Typography variant="body2" ml={2} mr={1}>
                  You have been awarded a certificate!
                </Typography>
              </Box>
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowCertificatePreview(true)}
              >
                View
              </Button>
            </AuditBannerStyled>
            {showCertificatePreview && audit.certificateUrl && (
              <AuditCertificatePreviewDialog
                certificateUrl={audit.certificateUrl}
                handleClose={() => setShowCertificatePreview(false)}
              />
            )}
          </>
        ) : (
          <Box height="16px" /> // spacer
        )}
        <AuditFormViewer
          audit={audit}
          auditVersion={auditVersion}
          key={audit.id}
          onSaveAudit={onSaveAudit}
          setAudit={setAudit}
        />
        {showSavedDialog && (
          <AuditSavedDialog
            audit={audit}
            handleClose={() => setShowSavedDialog(false)}
          />
        )}
      </>
    );
  },
);
