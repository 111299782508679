import React from "react";
import { Facility, sysSelectors, useSelector } from "../../../../../state";
import { asString, Navigation, useLocation } from "../../../../../lib";
import { Link } from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../../components";
import { FacilityStatuses } from "../../../../../lib/constants";

interface FacilityAdminSubmissionDialogProps {
  facility: Facility;
  onClose: () => void;
}

export const FacilityAdminSubmissionDialog = React.memo(
  /**
   *
   */
  function FacilityAdminSubmissionDialog({
    facility,
    onClose,
  }: FacilityAdminSubmissionDialogProps) {
    const location = useLocation();

    const allFacilities = useSelector(sysSelectors.allFacilities);
    const pendingFacilities = useSelector(sysSelectors.pendingFacilities);

    const otherPendingFacilities = pendingFacilities.filter(
      (f) => f.id !== facility.id,
    );

    const noExitForm =
      allFacilities.length === pendingFacilities.length &&
      facility.status === FacilityStatuses.Pending;

    return (
      <ConfirmationDialog
        cancelText="Back"
        confirmText={
          otherPendingFacilities.length
            ? "Next pending facility profile"
            : noExitForm
            ? "Continue"
            : "Exit form"
        }
        handleClose={onClose}
        handleConfirm={() => {
          if (otherPendingFacilities.length) {
            // load form for next pending facility
            Navigation.reload(
              location.pathname.replace(
                asString(facility.id),
                otherPendingFacilities[0].id.toString(),
              ),
            );
          } else if (noExitForm) {
            onClose();
          } else {
            Navigation.go("/");
          }
        }}
        hideCancel={facility.status === FacilityStatuses.Review || noExitForm}
        message={
          facility.status === FacilityStatuses.Review ? (
            <span>
              CCG has been notified and will reach out to you regarding the next
              steps in the onboarding process. For any questions, you can
              contact CCG at{" "}
              {
                <Link href="mailto:info@compliancecgllc.com" target="_blank">
                  info@compliancecgllc.com
                </Link>
              }{" "}
              or {<Link href="tel:718-408-8989">(718) 408 8989</Link>}.
            </span>
          ) : (
            `Please complete and submit the profile form for ${facility.name} as soon as possible to move forward with the onboarding process.`
          )
        }
        open={true}
        title={
          facility.status === FacilityStatuses.Review
            ? "Thank you for your submission!"
            : "Your updates have been saved"
        }
        type={
          facility.status === FacilityStatuses.Review
            ? ConfirmationDialogTypes.ThankYou
            : ConfirmationDialogTypes.Saved
        }
      />
    );
  },
);
