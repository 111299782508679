import React from "react";
import { useParams } from "react-router-dom";
import { FacilityFilePreview } from "../shared/components";
import { GroupFacilityFilesAccessWrapper } from "./components";

export const FacilityFilePage = React.memo(
  /**
   *
   */
  function FacilityFilePage() {
    const params = useParams();

    return (
      <GroupFacilityFilesAccessWrapper>
        <FacilityFilePreview facilityFileId={Number(params.id)} />
      </GroupFacilityFilesAccessWrapper>
    );
  },
);
