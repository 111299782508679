import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ListTypes } from "../../../../state/lists/state";
import {
  DeleteIcon,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
} from "../../../../components";
import { Navigation, useHoveredRow, useLocation } from "../../../../lib";
import { PositionForm } from "./PositionForm";
import { RootStyled } from "./PositionsRolesList.styles";
import {
  adminActions,
  PositionRecord,
  useAppDispatch,
} from "../../../../state";

const filterQueryKey = {
  text: "search",
};

interface PositionsListActionsProps extends ListActionsProps {
  onAddPosition: () => void;
}

function PositionsListActions({
  onAddPosition,
  onFilterChange = () => {},
  query,
}: PositionsListActionsProps) {
  const { search = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!search);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Positions</Typography>
        <Box display="flex">
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddPosition}
            size="large"
            sx={{ ml: 1 }}
          >
            Add new position
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search positions"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

export const PositionsList = React.memo(
  /**
   *
   */
  function PositionsList() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const positionId = location.query.positionId;

    const onEditPosition = useCallback(
      (id: number | string) => {
        const urlConfig = { query: { ...location.query, positionId: id } };
        Navigation.replace(location.pathname, urlConfig);
      },
      [location.pathname, location.query],
    );

    const onDeletePosition = useCallback(
      async (id: number) => {
        const deleted = await dispatch(adminActions.deletePosition(id));
        if (deleted) {
          //if position is selected, clear it from query params
          if (positionId === id) {
            const urlConfig = {
              query: { ...location.query, positionId: undefined },
            };
            Navigation.replace(location.pathname, urlConfig);
          }
        }
      },
      [dispatch, location.pathname, location.query, positionId],
    );

    const { hoveredRowId, onRowHover, onRowLeave } = useHoveredRow();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Position",
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          width: 80,
          renderCell: ({ row }: { row: PositionRecord }) => {
            const { id: rowId, isPreset } = row;
            if (
              hoveredRowId !== rowId.toString() &&
              positionId?.toString() !== rowId.toString()
            ) {
              return null;
            }
            return (
              <Tooltip title={isPreset ? "This position is required" : ""}>
                <Box>
                  <IconButton
                    disabled={isPreset}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeletePosition(rowId);
                    }}
                    size="small"
                    sx={{ color: "text.secondary" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            );
          },
          sortable: false,
        },
      ],
      [hoveredRowId, onDeletePosition, positionId],
    );

    return (
      <RootStyled formOpen={!!positionId}>
        <List
          actions={
            <PositionsListActions onAddPosition={() => onEditPosition("new")} />
          }
          columns={columns}
          filterQueryKey={filterQueryKey}
          name="positions"
          onRowClick={({ row }: { row: PositionRecord }) =>
            onEditPosition(row.id)
          }
          onRowHover={onRowHover}
          onRowLeave={onRowLeave}
          stickyHeader={!positionId}
          type={ListTypes.userPositions}
        />
        {positionId && <PositionForm />}
      </RootStyled>
    );
  },
);
