import { useState } from "react";

export function useHoveredRow() {
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const onRowHover = (e) =>
    setHoveredRowId(e.currentTarget.getAttribute("data-id"));

  const onRowLeave = () => setHoveredRowId(null);

  return { hoveredRowId, onRowHover, onRowLeave };
}
