import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  BaseTextFieldProps,
  FilterOptionsState,
  TextField,
  Typography,
} from "@mui/material";
import { Option } from "../../lib/types";
import { ArrowDropDownIcon } from "../icons";

interface AutocompleteInputProps extends BaseTextFieldProps {
  clearable?: boolean;
  fitToContentWidth?: boolean;
  fullWidth?: boolean;
  groupOpts?: boolean;
  inputProps?: any;
  multiple?: boolean;
  name: string;
  onChange: (name: string, val: any, other?: object | undefined) => void;
  options: Option[];
  value: any;
}

const defaultInputWidth = 200;

export const AutocompleteInput = React.memo(
  /**
   *
   */
  function AutocompleteInput({
    clearable = true,
    fitToContentWidth,
    fullWidth,
    groupOpts,
    inputProps = {},
    multiple,
    name,
    onChange,
    options,
    sx,
    value,
    ...rest
  }: AutocompleteInputProps) {
    // the autocomplete input does not support a width setting to fit-content
    // when fitToContentWidth is true, we need to hide the autocomplete input when not focused, and render a placeholder label (so that the width is limited to the selected value text)
    // we maintain refs to the input and label, and when the label is clicked we set inputFocused to true to show (and set focus on) the autocomplete input, and set the inputWidth to the width of the static label
    const inputRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLSpanElement>(null);

    const [inputFocused, setInputFocused] = useState(false);
    const [inputWidth, setInputWidth] = useState<number>(defaultInputWidth);

    const handleChange = useCallback(
      (_, value) => {
        if (!clearable && !value) return;
        onChange(name, multiple ? value?.map((v) => v.id) : value?.id);
      },
      [clearable, multiple, name, onChange],
    );

    const toggleFocus = useCallback(() => {
      const focus = !inputFocused;
      setInputFocused(focus);

      if (focus) {
        // update input width to match the static label width
        if (!fullWidth) {
          const labelWidth = labelRef.current?.clientWidth || defaultInputWidth;
          setInputWidth(labelWidth);
        }

        // focus input
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
            // highlight input text after focus to allow for easy clearing
            setTimeout(() => inputRef.current?.select(), 0);
          }
        }, 0);
      }
    }, [fullWidth, inputFocused]);

    // when groupOpts is true, support searching by group name
    const filterGroupedOptions = useCallback(
      (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
        if (!inputValue) return options;

        const searchValue = inputValue.toLowerCase();

        return options.filter(
          (o) =>
            o.name.toLowerCase().includes(searchValue) ||
            o.groupName?.toLowerCase().includes(searchValue),
        );
      },
      [],
    );

    const sortedOptions = useMemo(() => {
      if (!groupOpts) return options;

      return options.sort(
        (o1, o2) => o1.groupName?.localeCompare(o2.groupName || "") ?? 0,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupOpts, options.length]);

    const selection = useMemo(
      () =>
        multiple
          ? sortedOptions.filter((o) => value.includes(o.id))
          : sortedOptions.find((o) => o.id === value),
      [multiple, sortedOptions, value],
    );

    const renderAutocomplete = !fitToContentWidth || inputFocused;

    return renderAutocomplete ? (
      <Autocomplete
        id={name}
        key={name}
        blurOnSelect
        componentsProps={{
          clearIndicator: {
            hidden: !clearable,
            sx: { display: !clearable ? "none" : "" },
          },
          popper: {
            placement: "bottom-start",
            style: { width: "fit-content", minWidth: inputWidth },
          },
        }}
        filterSelectedOptions
        filterOptions={groupOpts ? filterGroupedOptions : undefined}
        fullWidth={fullWidth}
        getOptionLabel={(option: Option) => option.name}
        getOptionDisabled={(option: Option) => !!option.isOptGroup}
        groupBy={groupOpts ? (o) => o.groupName || "" : undefined}
        multiple={multiple}
        onBlur={fitToContentWidth ? toggleFocus : undefined}
        onChange={handleChange}
        openOnFocus={true}
        options={sortedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            InputProps={{
              ...inputProps,
              ...params.InputProps,
            }}
            inputRef={inputRef}
          />
        )}
        sx={{
          ...sx,
          width: inputWidth,
        }}
        value={selection}
      />
    ) : (
      // placeholder label display for fitToContentWidth
      <Typography
        onClick={toggleFocus}
        ref={labelRef}
        sx={{
          ...sx,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {multiple
          ? (selection as Option[])?.map((v) => v.name).join(", ")
          : (selection as Option)?.name}
        <ArrowDropDownIcon sx={{ ml: "12px" }} />
      </Typography>
    );
  },
);
