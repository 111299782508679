import { createSelector } from "@reduxjs/toolkit";
import {
  FacilityUserTypes,
  PortalUserTypes,
  UserCategories,
} from "../../lib/constants";
import { AppState } from "../types";

export const authSelectors = {
  isLoggedIn: createSelector(
    (state: AppState) => state.auth,
    (auth) => !!auth.token && !auth.requiresMfa,
  ),
  permissionClaims: createSelector(
    (state: AppState) => state.auth.permissionClaims,
    (permissionClaims) => permissionClaims || [],
  ),
  userId: createSelector(
    (state: AppState) => state.auth.userID,
    (userId) => userId || 0,
  ),
  userName: createSelector(
    (state: AppState) => state.auth.userName,
    (userName) => userName || "",
  ),
  userInfo: createSelector(
    (state: AppState) => state.auth,
    (auth) => ({
      firstName: auth.firstName,
      lastName: auth.lastName,
      email: auth.userName,
    }),
  ),
  userType: createSelector(
    (state: AppState) => state.auth,
    (auth) => {
      switch (auth.userCategory) {
        case UserCategories.CcgAdmin:
          return PortalUserTypes.CcgAdmin;
        case UserCategories.FacilityUser:
          switch (auth.facilityUserType) {
            case FacilityUserTypes.Admin:
              return PortalUserTypes.FacilityAdmin;
            case FacilityUserTypes.Staff:
            default:
              return PortalUserTypes.FacilityStaff;
          }
      }
    },
  ),
  facilityUserLevel: createSelector(
    (state: AppState) => state.auth.facilityUserLevel,
    (facilityUserLevel) => facilityUserLevel,
  ),
  facilityUserPositions: createSelector(
    (state: AppState) => state.auth.facilityUserPositions,
    (facilityUserPositions) => facilityUserPositions,
  ),
};
