import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material";
import {
  AbbreviatedListDisplay,
  BasicStatusChipStyled,
  CheckboxInput,
  CheckIcon,
  EditIcon,
  FolderIcon,
  InfoIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  SearchInput,
  SelectInput,
} from "../../../../components";
import { ListActionsProps } from "../../../../components";
import {
  authSelectors,
  FacilityFileRecord,
  sysActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import {
  asArray,
  formatDate,
  Navigation,
  pluralizeText,
  RouterLink,
  useHoveredRow,
} from "../../../../lib";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import { PermissionClaims, PortalUserTypes } from "../../../../lib/constants";
import { PrintFilesDialog } from "./PrintFilesDialog";
import { ManageCategoriesDialog } from "../../../adminShared/components";
import { DeactivateFacilityFilesSettingButton } from "../../../admin/components";
import { FacilityAdminPages } from "../../../facilityAdmin";
import { AdminPages } from "../../../admin";

const filterQueryKey = {
  categoryIDs: "categories",
  facilityIDs: "facilities",
  includeInactive: "showInactive",
  text: "search",
};

interface FacilityFilesListActionsProps extends ListActionsProps {
  clearSelectedFiles: () => void;
  facilityFilterId?: number;
  groupId: number;
  hasEditFilesClaim: boolean;
  hasManageCategoriesClaim: boolean;
  isCcgAdmin: boolean;
  selectedFiles: FacilityFileRecord[];
}

function FacilityFilesListActions({
  clearSelectedFiles,
  facilityFilterId,
  groupId,
  hasEditFilesClaim,
  hasManageCategoriesClaim,
  isCcgAdmin,
  onFilterChange = () => {},
  query,
  selectedFiles,
}: FacilityFilesListActionsProps) {
  const dispatch = useAppDispatch();

  const {
    categories = [],
    facilities = [],
    search = "",
    showInactive = false,
  } = query;

  const activeFacilities = useSelector(sysSelectors.activeFacilities);
  const facilityOptions = useMemo(
    () => activeFacilities.filter((f) => f.groupID === groupId),
    [activeFacilities, groupId],
  );

  const facilityFileCategories = useSelector(
    sysSelectors.facilityFileCategories,
  );
  const facilityFileCategoryOptions = useMemo(
    () => facilityFileCategories.filter((c) => c.groupID === groupId),
    [facilityFileCategories, groupId],
  );

  const [showManageCategoriesDialog, setShowManageCategoriesDialog] =
    useState(false);
  const [showCategoriesTooltip, setShowCategoriesTooltip] = useState(false);

  const [showPrintDialog, setShowPrintDialog] = useState(false);

  // retrieve categories if there are no options available for the group
  useEffect(() => {
    if (!facilityFileCategoryOptions.length)
      dispatch(sysActions.getFacilityFileCategories(groupId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupId]);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled sx={{ pt: 0 }}>
        <Box display="flex" alignItems="center" width="100%">
          <SearchInput
            variant="outlined"
            placeholder="Search for files"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
            onFocus={() => setShowCategoriesTooltip(true)}
            onBlur={() => setShowCategoriesTooltip(false)}
          />
          {!!asArray(categories).length && (
            <Tooltip
              open={showCategoriesTooltip}
              title={`Search is limited to the selected ${pluralizeText(
                "category",
                asArray(categories).length,
                "categories",
              )}`}
            >
              <InfoIcon
                onMouseEnter={() => setShowCategoriesTooltip(true)}
                onMouseLeave={() => setShowCategoriesTooltip(false)}
                sx={{ color: "text.secondary" }}
              />
            </Tooltip>
          )}
          {!facilityFilterId && facilityOptions.length > 1 && (
            <SelectInput
              label="Facilities"
              multiple={true}
              name="facilities"
              value={asArray(facilities)}
              onChange={onFilterChange}
              options={facilityOptions}
              sx={{ ml: 2 }}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {hasEditFilesClaim && (
            <CheckboxInput
              checked={!!showInactive}
              label="Show inactive"
              name="showInactive"
              onChange={onFilterChange}
              sx={{ ml: 2, whiteSpace: "nowrap" }}
            />
          )}
          {hasManageCategoriesClaim && (
            <>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                sx={{ ml: 1, whiteSpace: "nowrap" }}
                onClick={() => setShowManageCategoriesDialog(true)}
              >
                Manage categories
              </Button>
              {showManageCategoriesDialog && (
                <ManageCategoriesDialog
                  groupId={groupId}
                  handleClose={() => setShowManageCategoriesDialog(false)}
                />
              )}
            </>
          )}
          <Tooltip
            title={!selectedFiles.length ? "Select files for print" : ""}
          >
            <Box>
              <Button
                disabled={!selectedFiles.length}
                variant="contained"
                color="primary"
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setShowPrintDialog(true)}
              >
                Print
              </Button>
            </Box>
          </Tooltip>
          {showPrintDialog && (
            <PrintFilesDialog
              files={selectedFiles}
              handleClose={() => {
                setShowPrintDialog(false);
                clearSelectedFiles();
              }}
            />
          )}
          {hasEditFilesClaim && (
            <Button
              component={RouterLink}
              to={
                isCcgAdmin
                  ? Navigation.url(AdminPages.addFacilityFile, {
                      params: { groupId },
                    })
                  : FacilityAdminPages.addFacilityFile.path
              }
              variant="contained"
              color="primary"
              size="large"
              sx={{ ml: 1, whiteSpace: "pre" }}
            >
              Add file
            </Button>
          )}
          {isCcgAdmin && (
            <DeactivateFacilityFilesSettingButton groupId={groupId} />
          )}
        </Box>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled sx={{ flexFlow: "wrap" }}>
        {facilityFileCategoryOptions.map((category) => {
          const selectedCategories = asArray(categories) as number[];
          const isSelected = selectedCategories.includes(category.id);
          return (
            <Chip
              color={isSelected ? "primary" : "default"}
              key={category.id}
              icon={
                isSelected ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <FolderIcon fontSize="small" />
                )
              }
              label={category.name}
              onClick={() =>
                onFilterChange(
                  "categories",
                  isSelected
                    ? selectedCategories.filter((c) => c !== category.id)
                    : [...selectedCategories, category.id],
                )
              }
              sx={{ mr: 2, mb: 1 }}
            />
          );
        })}
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

interface FacilityFilesListProps {
  groupId: number;
}

export const FacilityFilesList = React.memo(
  /**
   *
   */
  function FacilityFilesList({ groupId }: FacilityFilesListProps) {
    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const hasEditFilesClaim = userPermissionClaims.includes(
      PermissionClaims.FacilityFilesEditClaim,
    );
    const hasManageCategoriesClaim = userPermissionClaims.includes(
      PermissionClaims.FacilityFileCategoriesClaim,
    );

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const activeFacilities = useSelector(sysSelectors.activeFacilities);
    const hasMultipleFacilities = useMemo(
      () => activeFacilities.filter((f) => f.groupID === groupId).length > 1,
      [activeFacilities, groupId],
    );

    const [selectedFiles, setSelectedFiles] = useState<FacilityFileRecord[]>(
      [],
    );

    const listFilter = useMemo(
      () => ({
        groupId,
        ...(facilityFilter ? { facilityIDs: [facilityFilter.id] } : {}),
      }),
      [facilityFilter, groupId],
    );

    const { hoveredRowId, onRowHover, onRowLeave } = useHoveredRow();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "File",
          flex: 1.5,
        },
        {
          field: "categoryName",
          headerName: "Category",
          flex: 1,
        },
        ...(hasEditFilesClaim
          ? [
              {
                field: "lastUpdatedOn",
                headerName: "Last updated",
                flex: 1,
                valueFormatter: ({ value }) => formatDate(value),
                sortingOrder: dateColumnSortingOrder,
              },
              {
                field: "uploadedBy",
                headerName: "Uploaded by",
                flex: 1,
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: ({ row }: { row: FacilityFileRecord }) => (
                  <BasicStatusChipStyled
                    label={row.status}
                    size="small"
                    status={row.status}
                  />
                ),
              },
              ...(hasMultipleFacilities
                ? [
                    {
                      field: "facilities",
                      headerName: "Facilities",
                      flex: 1,
                      renderCell: ({ row }: { row: FacilityFileRecord }) => (
                        <AbbreviatedListDisplay items={row.facilities} />
                      ),
                      sortable: false,
                    },
                  ]
                : []),
              {
                field: "actions",
                headerName: "",
                width: 80,
                renderCell: ({ row }: { row: FacilityFileRecord }) => {
                  if (hoveredRowId !== row.id.toString()) {
                    return null;
                  }
                  return (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        const editFacilityFileUrl = isCcgAdmin
                          ? Navigation.url(AdminPages.editFacilityFile, {
                              params: { groupId, id: row.id },
                            })
                          : Navigation.url(
                              FacilityAdminPages.editFacilityFile,
                              { params: { id: row.id } },
                            );
                        Navigation.go(editFacilityFileUrl);
                      }}
                      size="small"
                      sx={{ color: "text.secondary" }}
                    >
                      <EditIcon />
                    </IconButton>
                  );
                },
                sortable: false,
              },
            ]
          : []),
      ],
      [
        groupId,
        hasEditFilesClaim,
        hasMultipleFacilities,
        hoveredRowId,
        isCcgAdmin,
      ],
    );

    const onRowClick = useCallback(
      ({ row }: { row: FacilityFileRecord }) => {
        const facilityFileUrl = isCcgAdmin
          ? Navigation.url(AdminPages.facilityFile, {
              params: { groupId, id: row.id },
            })
          : Navigation.url(FacilityAdminPages.facilityFile, {
              params: { id: row.id },
            });
        Navigation.go(facilityFileUrl);
      },
      [groupId, isCcgAdmin],
    );

    return (
      <>
        <List
          actions={
            <FacilityFilesListActions
              clearSelectedFiles={() => setSelectedFiles([])}
              facilityFilterId={facilityFilter?.id}
              groupId={groupId}
              hasEditFilesClaim={hasEditFilesClaim}
              hasManageCategoriesClaim={hasManageCategoriesClaim}
              selectedFiles={selectedFiles}
              isCcgAdmin={isCcgAdmin}
            />
          }
          checkboxSelection={true}
          columns={columns}
          filter={listFilter}
          filterQueryKey={filterQueryKey}
          name="files"
          onCheckboxSelection={(selection) => setSelectedFiles(selection)}
          onRowClick={onRowClick}
          onRowHover={onRowHover}
          onRowLeave={onRowLeave}
          selection={selectedFiles}
          stickyHeader={true}
          type={ListTypes.facilityFiles}
        />
      </>
    );
  },
);
