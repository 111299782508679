import React from "react";
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowBackIcon, CloseIcon } from "../icons";
import { FilePreview, FilePreviewProps } from "../views";
import { styled } from "@mui/material/styles";

interface PreviewDialogStyledProps extends DialogProps {
  fullScreen?: boolean;
}

export const PreviewDialogStyled = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "fullScreen",
})<PreviewDialogStyledProps>(({ fullScreen, theme }) => ({
  "& .MuiDialog-paper": fullScreen
    ? {
        maxHeight: "100%",
        maxWidth: "100%",
        height: "100%",
        width: "100%",
        margin: 0,
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.background.default,
        "& svg": {
          color: theme.palette.background.default,
        },
      }
    : {
        padding: "16px",
        minWidth: "800px",
      },
  "& iframe": {
    border: "none",
  },
}));

const fullHeightPreviewStyle = { minHeight: "80vh" };
const fullScreenPreviewStyle = { height: "calc(100% - 72px)" };

interface FilePreviewDialogProps extends FilePreviewProps {
  fullScreen?: boolean;
  fullScreenTitle?: string;
  handleClose: (e: any) => void;
  open: boolean;
}

export const FilePreviewDialog = React.memo(function FilePreviewDialog({
  fullScreen,
  fullScreenTitle,
  handleClose,
  open,
  ...previewProps
}: FilePreviewDialogProps) {
  return (
    <PreviewDialogStyled fullScreen={fullScreen} open={open}>
      {fullScreen ? (
        <Typography variant="h6" padding="16px">
          <IconButton
            onClick={handleClose}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <ArrowBackIcon />
          </IconButton>
          {fullScreenTitle}
        </Typography>
      ) : (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <FilePreview
        {...previewProps}
        style={fullScreen ? fullScreenPreviewStyle : fullHeightPreviewStyle}
      />
    </PreviewDialogStyled>
  );
});
