import React, { useCallback } from "react";
import { Autocomplete, Box } from "@mui/material";
import { TextField } from "./TextField";
import { FieldAttributes, useField } from "formik";
import { Option } from "../../lib/types";
import { styled } from "@mui/material/styles";

interface AutocompleteFieldOption extends Option {
  disabled?: boolean;
}

interface AutocompleteFieldProps extends FieldAttributes<any> {
  multiple?: boolean;
  options: AutocompleteFieldOption[];
}

const AutocompleteContainerStyled = styled(Box)(() => ({
  "& .MuiAutocomplete-endAdornment": {
    top: "14px",
  },
}));

export const AutocompleteField = React.memo(
  /**
   *
   */
  function AutocompleteField(props: AutocompleteFieldProps) {
    const { disabled, label, multiple, name, options } = props;
    const [field, , helpers] = useField(name);
    const { onChange: _, value, ...fieldRest } = field; // discard onChange because change is handled properly via handleChange

    const handleChange = useCallback(
      (_, val) => {
        if (multiple) {
          const all = val.find((v) => v.id === "all");
          helpers.setValue(
            all
              ? options.filter((o) => !o.disabled).map((o) => o.id)
              : val.map((v) => v.id),
          );
        } else {
          helpers.setValue(val?.id);
        }
      },
      [helpers, multiple, options],
    );

    const selection = multiple
      ? options.filter((o) => value.includes(o.id))
      : options.find((o) => o.id === value) || null;

    return (
      <AutocompleteContainerStyled>
        <Autocomplete
          id={name}
          key={name}
          disabled={disabled}
          disableCloseOnSelect
          filterSelectedOptions
          getOptionLabel={(option: AutocompleteFieldOption) => option.name}
          getOptionDisabled={(option: AutocompleteFieldOption) =>
            !!option.disabled
          }
          // note open issue re menu position adjusting from bottom to top based on available room
          // setting the height to 0 like below fixes the menu to the bottom position but causes it to extend past the bottom of the screen so options are cut off
          // // https://github.com/mui/material-ui/issues/21661
          // PopperComponent={({ style, ...props }) => (
          //   <Popper {...props} style={{ ...style, height: 0 }} />
          // )}
          multiple={multiple}
          onChange={handleChange}
          options={[
            ...(multiple &&
            (selection as [])?.length !==
              options.filter((o) => !o.disabled).length
              ? [{ id: "all", name: "Select all" }]
              : []),
            ...options,
          ]}
          renderInput={(params) => (
            <TextField
              label={label}
              onChange={undefined} // set onChange to undefined to override default change handler and allow autocomplete filtering rather than form input binding
              {...params}
              {...fieldRest}
            />
          )}
          value={selection}
        />
      </AutocompleteContainerStyled>
    );
  },
);
