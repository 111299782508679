import React, { Fragment, useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Chip, Link, Tooltip, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  AssignmentLateIcon,
  BuildIcon,
  CheckIcon,
  FolderIcon,
  InfoIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  VisibilityOffIcon,
} from "../../../../components";
import {
  asArray,
  Navigation,
  pluralizeText,
  RouterLink,
} from "../../../../lib";
import { ResourceRecord, sysSelectors, useSelector } from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import { RequiredDocumentStatusText } from "../../../adminShared/components/resources/RequiredDocumentStatusText";
import { ResourceTypes } from "../../../../lib/constants";
import { FacilityAdminPages } from "../..";

const filterQueryKey = {
  categoryIDs: "categories",
  text: "search",
};

function ResourcesListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { categories = [], search = "" } = query;

  const resourceCategoryOptions = useSelector(sysSelectors.resourceCategories);

  const [showCategoriesTooltip, setShowCategoriesTooltip] = useState(false);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled sx={{ pt: 0 }}>
        <Box display="flex" alignItems="center" width="100%">
          <SearchInput
            variant="outlined"
            placeholder="Search resources"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
            onFocus={() => setShowCategoriesTooltip(true)}
            onBlur={() => setShowCategoriesTooltip(false)}
          />
          {!!asArray(categories).length && (
            <Tooltip
              title={`Search is limited to the selected ${pluralizeText(
                "category",
                asArray(categories).length,
                "categories",
              )}`}
              open={showCategoriesTooltip}
            >
              <InfoIcon
                sx={{ color: "text.secondary" }}
                onMouseEnter={() => setShowCategoriesTooltip(true)}
                onMouseLeave={() => setShowCategoriesTooltip(false)}
              />
            </Tooltip>
          )}
        </Box>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled sx={{ flexFlow: "wrap" }}>
        {resourceCategoryOptions.map((category) => {
          const selectedCategories = asArray(categories) as number[];
          const isSelected = selectedCategories.includes(category.id);
          return (
            <Chip
              color={isSelected ? "primary" : "default"}
              key={category.id}
              icon={
                isSelected ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <FolderIcon fontSize="small" />
                )
              }
              label={category.name}
              onClick={() =>
                onFilterChange(
                  "categories",
                  isSelected
                    ? selectedCategories.filter((c) => c !== category.id)
                    : [...selectedCategories, category.id],
                )
              }
              sx={{ mr: 2, mb: 1 }}
            />
          );
        })}
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const ResourcesList = React.memo(
  /**
   *
   */
  function ResourcesList() {
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => ({
        requiredDocDetails: true,
        ...(facilityFilter ? { facilityIDs: [facilityFilter.id] } : {}),
      }),
      [facilityFilter],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Name",
          flex: 1.5,
          renderCell: ({ row }: { row: ResourceRecord }) => {
            const ResourceIcon =
              row.type === ResourceTypes.Tool
                ? BuildIcon
                : row.type === ResourceTypes.RequiredDocument
                ? AssignmentLateIcon
                : null;
            return (
              <Box display="flex" alignItems="center">
                {ResourceIcon && (
                  <Tooltip title={row.typeDisplay}>
                    <ResourceIcon sx={{ color: "text.secondary", mr: 1 }} />
                  </Tooltip>
                )}
                {row.name}
              </Box>
            );
          },
        },
        {
          field: "policies",
          headerName: "Related Policy",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <Box>
              {row.policies.map((p, i) => (
                <Fragment key={p.id}>
                  <Link
                    component={RouterLink}
                    onClick={(e) => e.stopPropagation()}
                    to={Navigation.url(FacilityAdminPages.policy, {
                      params: { id: p.id },
                    })}
                  >
                    {p.title.replace(p.name, "").trim()}
                  </Link>
                  {i < row.policies.length - 1 && (
                    <Typography variant="caption" mr={0.5}>
                      ,
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Box>
          ),
          sortable: false,
        },
        {
          field: "categories",
          headerName: "Category",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <AbbreviatedListDisplay items={row.categories} />
          ),
          sortable: false,
        },
        {
          field: "requiredDocumentStatus",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <RequiredDocumentStatusText resource={row} />
          ),
          sortable: false,
        },
        ...(!facilityFilter?.id
          ? [
              {
                field: "actions",
                headerName: "",
                width: 80,
                renderCell: () => (
                  <Tooltip title="You are currently viewing resources across all facilities. Select a facility from the blue bar at the top of the screen to open this resource.">
                    <VisibilityOffIcon sx={{ color: "neutral.main" }} />
                  </Tooltip>
                ),
                sortable: false,
              },
            ]
          : []),
      ],
      [facilityFilter?.id],
    );

    const onRowClick = useCallback(({ row }: { row: ResourceRecord }) => {
      Navigation.go(FacilityAdminPages.resource, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<ResourcesListActions />}
        columns={columns}
        filterQueryKey={filterQueryKey}
        filter={listFilter}
        name="resources"
        onRowClick={facilityFilter?.id ? onRowClick : undefined}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.resources}
      />
    );
  },
);
