import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

export const listsSelectors = {
  announcements: createSelector(
    (state: AppState) => state.lists.announcements,
    (announcements) => announcements,
  ),
  audits: createSelector(
    (state: AppState) => state.lists.audits,
    (audits) => audits,
  ),
  auditActivity: createSelector(
    (state: AppState) => state.lists.auditActivity,
    (auditActivity) => auditActivity,
  ),
  auditDashboard: createSelector(
    (state: AppState) => state.lists.auditDashboard,
    (auditDashboard) => auditDashboard,
  ),
  auditInbox: createSelector(
    (state: AppState) => state.lists.auditInbox,
    (auditInbox) => auditInbox,
  ),
  auditTemplates: createSelector(
    (state: AppState) => state.lists.auditTemplates,
    (auditTemplates) => auditTemplates,
  ),
  facilities: createSelector(
    (state: AppState) => state.lists.facilities,
    (facilities) => facilities,
  ),
  facilityActivity: createSelector(
    (state: AppState) => state.lists.facilityActivity,
    (facilityActivity) => facilityActivity,
  ),
  facilityFiles: createSelector(
    (state: AppState) => state.lists.facilityFiles,
    (facilityFiles) => facilityFiles,
  ),
  facilityNotes: createSelector(
    (state: AppState) => state.lists.facilityNotes,
    (facilityNotes) => facilityNotes,
  ),
  groups: createSelector(
    (state: AppState) => state.lists.groups,
    (groups) => groups,
  ),
  policies: createSelector(
    (state: AppState) => state.lists.policies,
    (policies) => policies,
  ),
  policyPublishLogs: createSelector(
    (state: AppState) => state.lists.policyPublishLogs,
    (policyPublishLogs) => policyPublishLogs,
  ),
  policyVersions: createSelector(
    (state: AppState) => state.lists.policyVersions,
    (policyVersions) => policyVersions,
  ),
  regions: createSelector(
    (state: AppState) => state.lists.regions,
    (regions) => regions,
  ),
  relatedFacilities: createSelector(
    (state: AppState) => state.lists.relatedFacilities,
    (relatedFacilities) => relatedFacilities,
  ),
  relatedRegions: createSelector(
    (state: AppState) => state.lists.relatedRegions,
    (relatedRegions) => relatedRegions,
  ),
  relatedUsers: createSelector(
    (state: AppState) => state.lists.relatedUsers,
    (relatedUsers) => relatedUsers,
  ),
  requiredDocumentResources: createSelector(
    (state: AppState) => state.lists.requiredDocumentResources,
    (requiredDocumentResources) => requiredDocumentResources,
  ),
  requiredDocumentSubmissions: createSelector(
    (state: AppState) => state.lists.requiredDocumentSubmissions,
    (requiredDocumentSubmissions) => requiredDocumentSubmissions,
  ),
  resources: createSelector(
    (state: AppState) => state.lists.resources,
    (resources) => resources,
  ),
  search: createSelector(
    (state: AppState) => state.lists.search,
    (search) => search,
  ),
  toolResources: createSelector(
    (state: AppState) => state.lists.toolResources,
    (toolResources) => toolResources,
  ),
  trainings: createSelector(
    (state: AppState) => state.lists.trainings,
    (trainings) => trainings,
  ),
  trainingPackets: createSelector(
    (state: AppState) => state.lists.trainingPackets,
    (trainingPackets) => trainingPackets,
  ),
  trainingAttendance: createSelector(
    (state: AppState) => state.lists.trainingAttendance,
    (trainingAttendance) => trainingAttendance,
  ),
  users: createSelector(
    (state: AppState) => state.lists.users,
    (users) => users,
  ),
  userPositions: createSelector(
    (state: AppState) => state.lists.userPositions,
    (userPositions) => userPositions,
  ),
  userRoles: createSelector(
    (state: AppState) => state.lists.userRoles,
    (userRoles) => userRoles,
  ),
};
