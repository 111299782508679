import { createSlice } from "@reduxjs/toolkit";

export interface SharedState {}

const initialState: SharedState = {};

export const shared = {
  ...createSlice({
    name: "shared",
    initialState,
    reducers: {},
  }),
  persist: false,
};
