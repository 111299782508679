import { Box, ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DialogCheckboxContainerStyled = styled(Box)(() => ({
  marginTop: "16px",
  "& span": {
    fontSize: "14px",
  },
}));

export const DialogListItemStyled = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
