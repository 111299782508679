import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Typography, Box, Tooltip } from "@mui/material";
import {
  authSelectors,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
  User,
} from "../../../../state";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { useParams } from "react-router-dom";
import {
  UserStatuses,
  PortalUserTypes,
  FacilityUserLevels,
} from "../../../../lib/constants";
import {
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  PhoneField,
  SelectField,
  TextField,
  ToggleField,
} from "../../../../components";
import { Form, Navigation, useLocation, useRedirect } from "../../../../lib";
import {
  getAllowedUserLevels,
  getInitialValues,
  getFormContext,
  handleChangeUserPositions,
  getValidationSchema,
  userLevelDescriptions,
  formatUserForForm,
  formatUserForSubmission,
} from "./UserFormHelpers";
import { ConfigurePermissions } from "./ConfigurePermissions";
import { AdminPages } from "../../../admin";
import { FacilityAdminPages } from "../../../facilityAdmin";

export const UserForm = React.memo(
  /**
   *
   */
  function UserForm() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();
    const { facilityId, groupId, regionId } = location.query;
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const authUserType = useSelector(authSelectors.userType);
    const authFacilityUserLevel = useSelector(authSelectors.facilityUserLevel);

    const { userStatuses: userStatusOptions = [] } = useSelector(
      sysSelectors.systemSettings,
    );
    const positionOptions = useSelector(sysSelectors.allPositions);
    const roleOptions = useSelector(sysSelectors.allRoles);

    const allowedUserLevels = useMemo(
      () => getAllowedUserLevels(authUserType, authFacilityUserLevel),
      [authUserType, authFacilityUserLevel],
    );

    const groupOptions = useSelector(sysSelectors.allGroups);
    const regionOptions = useSelector(sysSelectors.allRegions);
    const facilityOptions = useSelector(
      authUserType === PortalUserTypes.CcgAdmin
        ? sysSelectors.allFacilities
        : sysSelectors.activeFacilities,
    );

    const [initialValues, setInitialValues] = useState<User>(
      getInitialValues({
        allowedUserLevels,
        groupOptions,
        facilityId: facilityId || facilityFilter?.id,
        groupId: groupId || facilityFilter?.groupID,
        regionId,
      }),
    );
    const [emptyFormValues] = useState<User>(initialValues); // save original initial values in order to reset form when choose to add another after saving a new user (initial values will have been reset to the saved user values)
    const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);

    const [showConfigurePermissions, setShowConfigurePermissions] =
      useState(false);
    const [showUpdateEmailConfirmation, setShowUpdateEmailConfirmation] =
      useState(false);
    const [showUserCreatedDialog, setShowUserCreatedDialog] = useState(false);

    const [hideRegionsTooltip, setHideRegionsTooltip] = useState(false);

    const { redirect, setRedirect } = useRedirect();

    const usersListUrl = useMemo(
      () =>
        Navigation.url(
          authUserType === PortalUserTypes.CcgAdmin
            ? AdminPages.users
            : FacilityAdminPages.users,
        ),
      [authUserType],
    );

    const readOnly = useMemo(
      () =>
        !!(
          initialValues.id &&
          initialValues.facilityUserLevel &&
          !allowedUserLevels.includes(initialValues.facilityUserLevel)
        ),
      [allowedUserLevels, initialValues.facilityUserLevel, initialValues.id],
    );

    const readOnlyGroupRegionFacility = useMemo(
      () =>
        // disable editing user group/region/facility for facility level auth users
        !!initialValues.id &&
        authFacilityUserLevel === FacilityUserLevels.Facility,
      [authFacilityUserLevel, initialValues.id],
    );

    useEffect(() => {
      (async () => {
        if (params.id) {
          const user = await dispatch(sharedActions.getUser(Number(params.id)));
          if (user) {
            setInitialValues(formatUserForForm(user));
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id]);

    const onSubmit = useCallback(
      async (values, _, emailUpdateConfirmed = false) => {
        if (
          values.id &&
          values.email !== initialValues.email &&
          !emailUpdateConfirmed
        ) {
          setShowUpdateEmailConfirmation(true);
          return;
        }

        const savedUser = await dispatch(
          sharedActions.submitUser(
            formatUserForSubmission(values, roleOptions),
            sendWelcomeEmail,
          ),
        );
        if (savedUser) {
          setInitialValues(formatUserForForm(savedUser));

          if (params.id) {
            setRedirect(() => () => Navigation.goBack(usersListUrl));
          } else {
            // show dialog upon new user creation
            setShowUserCreatedDialog(true);
          }
        }
      },
      [
        dispatch,
        initialValues.email,
        params.id,
        roleOptions,
        sendWelcomeEmail,
        setRedirect,
        usersListUrl,
      ],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        const validationSchema = getValidationSchema(values.userLevel);
        try {
          validateYupSchema(values, validationSchema, true);
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      onSubmit,
    });

    const formContext = useMemo(
      () => getFormContext(form.values.userLevel),
      [form.values.userLevel],
    );

    const hasNewGroupAccess = useMemo(
      () =>
        form.values.groupIDs?.some((g) => !initialValues.groupIDs?.includes(g)),
      [form.values.groupIDs, initialValues.groupIDs],
    );
    const hasNewFacilityAccess = useMemo(
      () =>
        form.values.facilityIDs?.some(
          (r) => !initialValues.facilityIDs?.includes(r),
        ),
      [form.values.facilityIDs, initialValues.facilityIDs],
    );
    const hasNewRegionAccess = useMemo(
      () =>
        form.values.regionIDs?.some(
          (r) => !initialValues.regionIDs?.includes(r),
        ),
      [form.values.regionIDs, initialValues.regionIDs],
    );

    const sendEmailCheckboxDescription = useMemo(() => {
      if (form.values.status === UserStatuses.Inactive) {
        return;
      }

      if (!form.values.id) {
        return "Send welcome email";
      }

      if (hasNewGroupAccess || hasNewFacilityAccess || hasNewRegionAccess) {
        return `Send email to notify user of new ${[
          hasNewGroupAccess && "group",
          hasNewFacilityAccess && "facility",
          hasNewRegionAccess && "region",
        ]
          .filter((a) => a)
          .join(" and ")} access`;
      }
    }, [
      form.values.id,
      form.values.status,
      hasNewGroupAccess,
      hasNewFacilityAccess,
      hasNewRegionAccess,
    ]);

    const onConfirmEmailUpdate = useCallback(() => {
      setShowUpdateEmailConfirmation(false);
      onSubmit(form.values, form, true);
    }, [form, onSubmit]);

    const onChangeUserLevel = useCallback(
      (e) => {
        const { value } = e.target;
        form.setFieldValue("userLevel", value);

        // clear form values irrelevant for new user level
        const newFormContext = getFormContext(value);
        if (!newFormContext.withGroups && form.values.groupIDs?.length) {
          form.setFieldValue("groupIDs", []);
        }
        if (!newFormContext.withRegions && form.values.regionIDs?.length) {
          form.setFieldValue("regionIDs", []);
        }
        if (!newFormContext.withFacilities && form.values.facilityIDs?.length) {
          form.setFieldValue("facilityIDs", []);
        }
        // clear these values when fields visibility are toggled bec role is set by the API even when not shown (for CCG Admin)
        if (
          newFormContext.withRolesAndPermissions !==
          formContext.withRolesAndPermissions
        ) {
          if (form.values.positionIDs?.length)
            form.setFieldValue("positionIDs", []);
          if (form.values.roleIDs?.length) form.setFieldValue("roleIDs", []);
          if (form.values.claimIDs?.length) form.setFieldValue("claimIDs", []);
        }
      },
      [form, formContext],
    );

    const onChangeGroups = useCallback(
      (e) => {
        const { name, value } = e.target;

        const didRemoveGroups = value?.length < form.values[name]?.length;

        form.setFieldValue(name, value);

        if (didRemoveGroups) {
          //clear out selected regions and facilities that are no longer part of the group selection
          if (form.values.regionIDs?.length) {
            const availableRegionIds = regionOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            form.setFieldValue(
              "regionIDs",
              form.values.regionIDs.filter((i) =>
                availableRegionIds.includes(i),
              ),
            );
          }
          if (form.values.facilityIDs?.length) {
            const availableFacilityIds = facilityOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            form.setFieldValue(
              "facilityIDs",
              form.values.facilityIDs.filter((i) =>
                availableFacilityIds.includes(i),
              ),
            );
          }
        }
      },
      [facilityOptions, form, regionOptions],
    );

    const onChangeRegions = useCallback(
      (e) => {
        const { name, value } = e.target;
        form.setFieldValue(name, value);

        //filter out selected facilities that are included in the selected region when region is changed
        if (form.values.facilityIDs?.length) {
          const filteredFacilityIDs = form.values.facilityIDs.filter(
            (id) =>
              !facilityOptions.find(
                (o) => o.id === id && value.includes(o.regionID),
              ),
          );
          form.setFieldValue("facilityIDs", filteredFacilityIDs);
        }
      },
      [facilityOptions, form],
    );

    const onChangePositions = useCallback(
      (e) => {
        handleChangeUserPositions(e, form, positionOptions);
      },
      [form, positionOptions],
    );

    const onSendResetPasswordEmail = useCallback(() => {
      dispatch(
        sharedActions.sendUserResetPasswordEmails([Number(form.values.id)]),
      );
    }, [dispatch, form.values.id]);

    const onAddAnotherUser = useCallback(() => {
      form.resetForm({ values: emptyFormValues });
      setSendWelcomeEmail(true);
      setShowUserCreatedDialog(false);
    }, [form, emptyFormValues]);

    const onCancel = useCallback(
      () => Navigation.goBack(usersListUrl),
      [usersListUrl],
    );

    return (
      <Form form={form} noPrompt={!!redirect} promptCancelText="Back to User">
        <FormGridContainerStyled container>
          {!readOnly && allowedUserLevels.length > 1 && (
            <FormGridItemFullWidthStyled item>
              <SelectField
                name="userLevel"
                label="User level"
                onChange={onChangeUserLevel}
                options={allowedUserLevels.map((l) => ({
                  id: l,
                  name: l,
                  description: userLevelDescriptions[l],
                }))}
                size="large"
              />
            </FormGridItemFullWidthStyled>
          )}
          <FormGridItemStyled item>
            <TextField
              disabled={readOnly}
              name="firstName"
              label="First name"
            />
          </FormGridItemStyled>
          <FormGridItemStyled item>
            <TextField disabled={readOnly} name="lastName" label="Last name" />
          </FormGridItemStyled>
          {formContext.withGroups && (
            <FormGridItemStyled item>
              <SelectField
                disabled={
                  readOnly ||
                  readOnlyGroupRegionFacility ||
                  (!!initialValues.groupIDs?.length &&
                    groupOptions.length === 1) // disable when user has access to a single pre-set group
                }
                multiple={true}
                name="groupIDs"
                label="Groups"
                onChange={onChangeGroups}
                options={groupOptions}
              />
            </FormGridItemStyled>
          )}
          {formContext.withRegions && (
            <Tooltip
              title={
                !hideRegionsTooltip && form.values.regionIDs?.length
                  ? facilityOptions
                      .filter((f) =>
                        form.values.regionIDs?.includes(f.regionID as number),
                      )
                      .map((f) => f.name)
                      .join(", ")
                  : ""
              }
              placement="top"
            >
              <FormGridItemStyled item>
                <SelectField
                  disabled={
                    readOnly ||
                    readOnlyGroupRegionFacility ||
                    !form.values.groupIDs?.length
                  }
                  groupOpts={(form.values.groupIDs?.length || 0) > 1}
                  multiple={true}
                  name="regionIDs"
                  label="Regions"
                  onChange={onChangeRegions}
                  onClose={() => setHideRegionsTooltip(false)}
                  onOpen={() => setHideRegionsTooltip(true)}
                  options={regionOptions
                    .filter((o) => form.values.groupIDs?.includes(o.groupID))
                    .map((o) => ({
                      ...o,
                      checkboxTooltip:
                        facilityOptions
                          .filter((f) => f.regionID === o.id)
                          .map((f) => f.name)
                          .join(", ") || "No facilities",
                    }))}
                  tooltip={
                    form.values.regionIDs?.length
                      ? facilityOptions
                          .filter((f) =>
                            form.values.regionIDs?.includes(
                              f.regionID as number,
                            ),
                          )
                          .map((f) => f.name)
                          .join(", ")
                      : ""
                  }
                />
              </FormGridItemStyled>
            </Tooltip>
          )}
          {formContext.withFacilities && (
            <FormGridItemStyled item>
              <SelectField
                disabled={
                  readOnly ||
                  readOnlyGroupRegionFacility ||
                  !form.values.groupIDs?.length
                }
                groupOpts={(form.values.groupIDs?.length || 0) > 1}
                multiple={true}
                name="facilityIDs"
                label="Facilities"
                options={facilityOptions.filter(
                  (o) =>
                    form.values.groupIDs?.includes(o.groupID) &&
                    (!o.regionID ||
                      !form.values.regionIDs?.includes(o.regionID)),
                )}
              />
            </FormGridItemStyled>
          )}
          {formContext.withRolesAndPermissions && (
            <>
              <FormGridItemFullWidthStyled>
                <Typography variant="h6" mt={1} mb={1}>
                  Position and access
                </Typography>
              </FormGridItemFullWidthStyled>
              <FormGridItemStyled item>
                <SelectField
                  disabled={readOnly}
                  name="positionIDs"
                  label="Position"
                  multiple={true}
                  onChange={onChangePositions}
                  options={positionOptions}
                />
              </FormGridItemStyled>
              <FormGridItemStyled item>
                <SelectField
                  disabled={readOnly}
                  name="roleIDs"
                  label="Access level"
                  multiple={true}
                  options={roleOptions}
                />
                {authUserType === PortalUserTypes.CcgAdmin && (
                  <Button
                    variant="text"
                    size="medium"
                    onClick={() => setShowConfigurePermissions(true)}
                    sx={{ mt: 1 }}
                  >
                    Configure additional permissions
                  </Button>
                )}
              </FormGridItemStyled>
            </>
          )}
          <FormGridItemFullWidthStyled>
            <Typography variant="h6" mt={1} mb={1}>
              Contact Info
            </Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemStyled item>
            <TextField
              disabled={readOnly}
              name="email"
              label="Email address"
              type="email"
            />
          </FormGridItemStyled>
          <FormGridItemStyled item>
            <PhoneField disabled={readOnly} name="phone" label="Cell phone" />
          </FormGridItemStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleField
              disabled={readOnly}
              fullWidth={false}
              name="status"
              options={userStatusOptions.map((s) => ({
                ...s,
                disabled:
                  initialValues.status === s.id
                    ? false // selected value enabled
                    : initialValues.status === UserStatuses.Pending
                    ? s.id !== UserStatuses.Inactive // Pending -> only Inactive enabled
                    : initialValues.status === UserStatuses.Active
                    ? s.id !== UserStatuses.Inactive // Active -> only Inactive enabled
                    : initialValues.status === UserStatuses.Inactive
                    ? s.id === UserStatuses.Active && !initialValues.hasPassword // Inactive -> Pending enabled, Inactive -> Active enabled only if password has been set
                    : false,
              }))}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          {sendEmailCheckboxDescription && (
            <FormGridItemFullWidthStyled item>
              <CheckboxInput
                checked={sendWelcomeEmail}
                label={sendEmailCheckboxDescription}
                name="sendWelcomeEmail"
                onChange={() => setSendWelcomeEmail(!sendWelcomeEmail)}
              />
            </FormGridItemFullWidthStyled>
          )}
          <FormFooterContainerStyled>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Box display="flex">
              {!readOnly &&
                !!form.values.id &&
                form.initialValues.status !== UserStatuses.Inactive && (
                  <Button
                    color="primary"
                    variant="text"
                    size="large"
                    sx={{ mr: 2 }}
                    disabled={form.isSubmitting}
                    onClick={onSendResetPasswordEmail}
                  >
                    Send reset password email
                  </Button>
                )}
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </FormFooterContainerStyled>
        </FormGridContainerStyled>
        {showConfigurePermissions && (
          <ConfigurePermissions
            form={form}
            hasFacilityFilesEnabled={groupOptions.some(
              (g) =>
                form.values.groupIDs?.includes(g.id) && g.facilityFilesEnabled,
            )}
            onClose={() => setShowConfigurePermissions(false)}
            roleOptions={roleOptions}
          />
        )}
        {showUpdateEmailConfirmation && (
          <ConfirmationDialog
            confirmText="Update email"
            handleClose={() => setShowUpdateEmailConfirmation(false)}
            handleConfirm={onConfirmEmailUpdate}
            message={`Updating the user email will change the email used for system login.\n\nThe user will be required to confirm their email address via a confirmation email which will be sent to the updated email address before logging in again.`}
            open={true}
            title="Update email?"
            type={ConfirmationDialogTypes.Warning}
          />
        )}
        {showUserCreatedDialog && (
          <ConfirmationDialog
            cancelText="Back to users"
            confirmText="Add another"
            handleClose={onCancel}
            handleConfirm={onAddAnotherUser}
            message={
              sendWelcomeEmail && form.values.status !== UserStatuses.Inactive
                ? "An email was sent to the user notifying them to set their password."
                : ""
            }
            open={true}
            title="User created successfully"
            type={ConfirmationDialogTypes.Saved}
          />
        )}
      </Form>
    );
  },
);
