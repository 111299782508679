import React from "react";
import { ErrorBoundary, init } from "@sentry/react";

import {
  __DEV__,
  REACT_APP_ERRORS_DEV,
  REACT_APP_ERRORS_DSN,
  REACT_APP_VERSION,
  __PROD__,
} from "../../config";
import { Box, Typography } from "@mui/material";

const usingDevConfig = __DEV__ && !REACT_APP_ERRORS_DEV;

const devConfig = usingDevConfig
  ? {
      beforeSend() {
        // Do not send errors in development.
        return null;
      },
    }
  : undefined;

if (__DEV__ && !usingDevConfig) {
  console.warn("Reporting errors to https://sentry.io during development.");
}

/** See https://docs.sentry.io/learn/configuration */
init({
  /** Where to send errors. This is NOT a secret. */
  dsn: REACT_APP_ERRORS_DSN,
  environment: __PROD__ ? "production" : "staging",
  release: REACT_APP_VERSION,
  ...devConfig,
});

/**
 * Error boundary component that reports errors to https://sentry.io
 * NOTE: You don't _need_ to render this error boundary to report errors.
 * Error boundaries are primarily for catching React _rendering_ errors and
 * showing an error result UI.
 */

function ErrorFallback() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="1400"
    >
      <Typography variant="h6">An unexpected error has occurred</Typography>
    </Box>
  );
}

export function ErrorSentry(props) {
  //TODO: add tracing, use beforeSend to include error context such a user info
  return (
    <ErrorBoundary fallback={ErrorFallback} showDialog>
      {props.children}
    </ErrorBoundary>
  );
}
