import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useFileDownload } from "../../../../lib";
import { FacilityTraining, StorageFile } from "../../../../state";
import {
  DescriptionIcon,
  DownloadIcon,
  FilePreviewDialog,
  OndemandVideoIcon,
  SimpleList,
} from "../../../../components";
import { FileTypes } from "../../../../lib/constants";

const isVideo = (file: StorageFile) => file.name.endsWith(".mp4");

interface TrainingMaterialsListProps {
  training: FacilityTraining;
}

export const TrainingMaterialsList = React.memo(
  /**
   *
   */
  function TrainingMaterialsList({ training }: TrainingMaterialsListProps) {
    const downloadFile = useFileDownload();

    const [previewFile, setPreviewFile] = useState<StorageFile>();

    return (
      <>
        <SimpleList
          title="Training materials"
          name="training materials"
          columns={[
            {
              label: "Name",
              render: (row: StorageFile) => {
                const Icon = isVideo(row) ? OndemandVideoIcon : DescriptionIcon;
                return (
                  <Box display="flex" alignItems="center">
                    <Icon sx={{ color: "text.secondary", fontSize: "20px" }} />
                    <Typography variant="body2" ml={1}>
                      {row.name}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              label: "",
              render: (row: StorageFile) => {
                const video = isVideo(row);
                return (
                  <>
                    {!video && (
                      <IconButton
                        size="small"
                        onClick={() => downloadFile(row.url, row.name, true)}
                        sx={{ mr: 1, color: "text.secondary" }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    )}
                    <Button onClick={() => setPreviewFile(row)}>
                      {video ? "Play video" : "Preview"}
                    </Button>
                  </>
                );
              },
            },
          ]}
          rows={training.trainingMaterials}
        />
        {previewFile && (
          <FilePreviewDialog
            fileName={previewFile.name}
            fileSource={previewFile.url}
            fileType={isVideo(previewFile) ? FileTypes.Video : undefined}
            fullScreen={isVideo(previewFile)}
            fullScreenTitle={previewFile.name}
            handleClose={() => setPreviewFile(undefined)}
            open={true}
            secure={true}
          />
        )}
      </>
    );
  },
);
