import { Box, Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PolicyVersionPublishStatuses } from "../../../../../lib/constants";

interface PolicyVerionStatusChipStyledProps extends ChipProps {
  status?: string;
}

export const PolicyVersionStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<PolicyVerionStatusChipStyledProps>(({ theme, status }) => ({
  color:
    status === PolicyVersionPublishStatuses.Unpublished
      ? theme.palette.text.primary
      : theme.palette.background.default,
  backgroundColor:
    status === PolicyVersionPublishStatuses.Published
      ? theme.palette.primary.light
      : status === PolicyVersionPublishStatuses.Publishing
      ? theme.palette.secondary.main
      : status === PolicyVersionPublishStatuses.UnpublishedChanges
      ? theme.palette.warning.main
      : status === PolicyVersionPublishStatuses.Unpublished
      ? theme.palette.neutral.extraLight
      : theme.palette.neutral.main,
}));

export const NoPolicyVersionsOverlayStyled = styled(Box)(() => ({
  margin: "64px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
