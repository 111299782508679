import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { TableHeadStyled, TableRowStyled } from "./SimpleList.styles";
import { NoRowsOverlayStyled } from "./List.styles";
import logo from "../../assets/img/logo_blue.svg";

export interface SimpleListProps {
  columns: { label: string; render: (row) => JSX.Element | string }[];
  name?: string;
  rows: { id: any }[];
  title?: string;
}

export const SimpleList = React.memo(
  /**
   *
   */
  function SimpleList({ columns, name, rows, title }: SimpleListProps) {
    return (
      <>
        {title && (
          <Typography variant="h6" padding="24px 0">
            {title}
          </Typography>
        )}
        <Table>
          <TableHeadStyled>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  align={!column.label ? "right" : undefined}
                  key={column.label}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {!rows?.length ? (
              <TableRow>
                <TableCell sx={{ width: "100%" }}>
                  <NoRowsOverlayStyled>
                    <img alt="logo" src={logo} height="80px" />
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="subtitle2">
                        No {name || "results"} found
                      </Typography>
                    </Box>
                  </NoRowsOverlayStyled>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRowStyled key={row.id}>
                  {columns.map((column) => (
                    <TableCell
                      align={!column.label ? "right" : undefined}
                      key={`${column.label}-${row.id}`}
                    >
                      {column.render(row)}
                    </TableCell>
                  ))}
                </TableRowStyled>
              ))
            )}
          </TableBody>
        </Table>
      </>
    );
  },
);
