import { useEffect } from "react";
import {
  sysActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../state";
import { Navigation, useLocation } from "../routing";

export function useSetFacilityFilter(facilityId, query = "facility") {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const facilityFilter = useSelector(sysSelectors.facilityFilter);

  // sets the provided facilityId as the system facility filter, then clears the facility from the route query
  useEffect(() => {
    (async () => {
      if (facilityId) {
        if (facilityFilter?.id !== facilityId) {
          // update system facility filter
          const facility = facilityOptions.find((f) => f.id === facilityId);
          if (facility) {
            dispatch(sysActions.setFacilityFilter(facility));
          }
        }
        // clear facility id from query
        if (query) {
          Navigation.replace(location.pathname, {
            query: { ...location.query, [query]: undefined },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, facilityId]);

  return facilityFilter;
}
