import { useEffect, useState } from "react";
import { Navigation } from "../routing";

export function useRedirect() {
  // redirect url
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    // navigate to redirect url when specified
    if (redirectUrl) {
      Navigation.replace(redirectUrl);
    }
  }, [redirectUrl]);

  // custom redirect function
  const [redirect, setRedirect] = useState<Function>();

  useEffect(() => {
    // call custom redirect function when specified
    if (redirect) {
      redirect();
    }
  }, [redirect]);

  return { redirect, setRedirect, redirectUrl, setRedirectUrl };
}
