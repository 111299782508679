import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Button, Collapse, Typography } from "@mui/material";
import {
  CheckIcon,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  SelectInput,
} from "../../../../../components";
import { Navigation } from "../../../../../lib";
import {
  Facility,
  sysSelectors,
  TrainingRecord,
  useSelector,
} from "../../../../../state";
import { AdminPages } from "../../..";
import { ListTypes } from "../../../../../state/lists/state";

const filterQueryKey = {
  text: "search",
  type: "type",
};

function FacilityTrainingsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const { trainingTypes: trainingTypeOptions = [] } = useSelector(
    sysSelectors.systemSettings,
  );

  const { search = "", type = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!(search || type));

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Trainings</Typography>
        <Button
          variant="text"
          color="primary"
          size="large"
          sx={{ ml: 1 }}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Filter <FilterListIcon sx={{ ml: 1 }} />
        </Button>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search trainings"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Type"
            name="type"
            value={type}
            onChange={onFilterChange}
            options={trainingTypeOptions}
            sx={{ mr: 2 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}
interface FacilityTrainingsListProps {
  facility: Facility;
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "isRequiredOnHire",
    headerName: "Required on hire",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.isRequiredOnHire ? <CheckIcon /> : "",
  },
  {
    field: "isRequiredAnnually",
    headerName: "Required annually",
    flex: 1,
    renderCell: ({ row }: { row: TrainingRecord }) =>
      row.isRequiredAnnually ? <CheckIcon /> : "",
  },
];

export const FacilityTrainingsList = React.memo(
  /**
   *
   */
  function FacilityTrainingsList({ facility }: FacilityTrainingsListProps) {
    const onRowClick = useCallback(({ row }: { row: TrainingRecord }) => {
      Navigation.go(AdminPages.editTraining, { params: { id: row.id } });
    }, []);

    const listFilter = useMemo(
      () => ({ facilityIDs: [facility.id] }),
      [facility.id],
    );

    return (
      <List
        actions={<FacilityTrainingsListActions />}
        columns={columns}
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        name="trainings"
        onRowClick={onRowClick}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.trainings}
      />
    );
  },
);
