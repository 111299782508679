import { admin, AdminState } from "./admin/state";
import { auth, AuthState } from "./auth/state";
import { facilityAdmin, FacilityAdminState } from "./facilityAdmin/state";
import { facilityStaff, FacilityStaffState } from "./facilityStaff/state";
import { lists, ListsState } from "./lists/state";
import { shared, SharedState } from "./shared/state";
import { sys, SysState } from "./sys/state";
import { ui, UIState } from "./ui/state";

import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types

export * from "./admin/actions";
export * from "./admin/selectors";

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./facilityAdmin/actions";
export * from "./facilityAdmin/selectors";

export * from "./facilityStaff/actions";
export * from "./facilityStaff/selectors";

export * from "./lists/actions";
export * from "./lists/selectors";

export * from "./shared/actions";
export * from "./shared/selectors";

export * from "./sys/actions";
export * from "./sys/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export interface AppState {
  admin: AdminState;
  auth: AuthState;
  facilityAdmin: FacilityAdminState;
  facilityStaff: FacilityStaffState;
  lists: ListsState;
  shared: SharedState;
  sys: SysState;
  ui: UIState;
}

const states: AppStateInfo[] = [
  // Export States
  admin,
  auth,
  facilityAdmin,
  facilityStaff,
  lists,
  shared,
  sys,
  ui,
];
export default states;
