import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PolicyDocumentPublishStatuses } from "../../../../../lib/constants";

interface PolicyPublishLogStatusStyledProps extends TypographyProps {
  status?: string;
}

export const PolicyPublishLogStatusStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "status",
})<PolicyPublishLogStatusStyledProps>(({ theme, status }) => ({
  color:
    status === PolicyDocumentPublishStatuses.Published
      ? theme.palette.success.dark
      : status === PolicyDocumentPublishStatuses.Publishing
      ? theme.palette.secondary.main
      : status === PolicyDocumentPublishStatuses.PublishFailed
      ? theme.palette.error.dark
      : theme.palette.text.primary,
}));
