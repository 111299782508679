import { Box, Chip, ChipProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuditCapStatuses } from "../../../../lib/constants";
import { getAuditStatusColor } from "../../../adminShared/components/audits/AuditHelpers";

interface AuditStatusChipStyledProps extends ChipProps {
  label?: string;
  status?: string;
}

export const AuditStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<AuditStatusChipStyledProps>(({ theme, label, status }) => ({
  color: theme.palette.background.default,
  backgroundColor: getAuditStatusColor(status, label, false),
}));

interface AuditCapStatusCellStyledProps {
  status?: string;
}

export const AuditCapStatusCellStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<AuditCapStatusCellStyledProps>(({ theme, status }) => ({
  height: "100%",
  width: "100%",
  "& a": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  "& p": {
    color:
      status === AuditCapStatuses.NotStarted
        ? theme.palette.error.dark
        : status === AuditCapStatuses.InProgress
        ? theme.palette.warning.dark
        : status === AuditCapStatuses.Completed
        ? theme.palette.success.dark
        : theme.palette.text.primary,
  },
}));

export const ShowPastAuditsTextStyled = styled(Typography)(() => ({
  marginBottom: 16,
  marginTop: -16,
  display: "flex",
  alignItems: "flex-end",
  "& h6": {
    marginLeft: "4px",
    textDecoration: "none",
  },
}));

interface LatestAuditDisplayStyledProps {
  noAudit: boolean;
}

export const LatestAuditDisplayStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "noAudit",
})<LatestAuditDisplayStyledProps>(({ noAudit }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  "& img": {
    height: noAudit ? "80px" : "144px",
    marginBottom: noAudit ? "32px" : "24px",
  },
  "& h6": {
    display: "inline",
    textDecoration: "none",
  },
}));
