import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormGridContainerStyled = styled(Grid)(() => ({
  maxWidth: "680px",
  "& .MuiFormControlLabel-labelPlacementEnd": {
    marginRight: "0px",
  },
}));

export const FormGridItemStyled = styled(Grid)(() => ({
  width: "50%",
  minWidth: "300px",
  padding: "0 24px 32px 0",
}));

export const FormGridItemFullWidthStyled = styled(FormGridItemStyled)(() => ({
  width: "100%",
}));

export const FullWidthFormGridItemStyled = styled(FormGridItemFullWidthStyled)(
  () => ({
    paddingRight: 0,
  }),
);

export const FormFooterContainerStyled = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: "32px 0",
  width: "100%",
}));
