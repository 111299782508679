import React, { useCallback } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@mui/material";
import { Option } from "../../lib/types";

interface ToggleInputOption extends Option {
  display?: JSX.Element | string;
}

interface ToggleInputProps extends Omit<ToggleButtonGroupProps, "onChange"> {
  buttonWidth?: string;
  name: string;
  onChange: (name: string, val: any) => void;
  options: ToggleInputOption[];
  value: any;
}

export const ToggleInput = React.memo(
  /**
   *
   */
  function ToggleInput({
    buttonWidth,
    name,
    onChange,
    options,
    ...rest
  }: ToggleInputProps) {
    const handleChange = useCallback(
      (_, newValue: string | null) => {
        onChange(name, newValue);
      },
      [name, onChange],
    );

    return (
      <ToggleButtonGroup exclusive onChange={handleChange} {...rest}>
        {options.map((o) => (
          <ToggleButton key={o.id} value={o.id} sx={{ width: buttonWidth }}>
            {o.display || o.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  },
);
