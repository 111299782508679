import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ResourceHeaderStyled = styled(Box)(({ theme }) => ({
  paddingLeft: "48px",
  paddingBottom: "16px",
  marginBottom: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& > p": {
    maxWidth: "840px",
  },
}));
