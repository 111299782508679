import React, { useMemo } from "react";
import { sysSelectors, useSelector } from "../../../../state";
import { StatusPage } from "../../../shared/components";
import unauthorized from "../../../../assets/img/unauthorized.svg";

export const FacilityFilesAccessWrapper = React.memo(
  /**
   *
   */
  function FacilityFilesAccessWrapper({ children }: { children: JSX.Element }) {
    const groupOptions = useSelector(sysSelectors.activeGroups);
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const groupSelection = useMemo(
      () => groupOptions.find((g) => g.id == facilityFilter?.groupID),
      [facilityFilter?.groupID, groupOptions],
    );

    // don't render page content until facility filter has been applied
    return !facilityFilter?.groupID ? null : !groupSelection?.facilityFilesEnabled ? (
      <StatusPage
        img={unauthorized}
        imgHeight="80px"
        title={`${facilityFilter.name} does not have access to facility files`}
      />
    ) : (
      children
    );
  },
);
