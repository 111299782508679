import React, { useCallback, useMemo } from "react";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../icons";
import { TextField } from "./TextField";
import { FieldAttributes, useField } from "formik";
import { Option } from "../../lib/types";

interface SelectFieldOption extends Option {
  description?: string;
  disabled?: boolean;
  checkboxTooltip?: string;
}

interface SelectFieldProps extends FieldAttributes<any> {
  clearable?: boolean;
  groupOpts?: boolean;
  menuTag?: JSX.Element;
  multiple?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  options: SelectFieldOption[];
  selectAll?: boolean;
}

export const SelectField = React.memo(
  /**
   *
   */
  function SelectField({
    clearable = true,
    groupOpts,
    disabled,
    menuTag,
    multiple,
    name,
    onChange,
    onClose,
    onOpen,
    options,
    selectAll,
    ...rest
  }: SelectFieldProps) {
    const [field, , helpers] = useField(name);
    const { onChange: _, value, ...fieldRest } = field; // discard onChange because change is handled properly via handleChange

    const handleChange = useCallback(
      (e) => {
        if (selectAll && e.target.value.indexOf("all") >= 0) {
          const val =
            field.value?.length === options?.length
              ? []
              : options?.filter((o) => !o.disabled).map((o) => o.id);
          helpers.setValue(val);
          if (onChange) {
            onChange({
              target: {
                name,
                value: val,
              },
            });
          }
        } else {
          helpers.setValue(e.target.value);
          if (onChange) onChange(e);
        }
      },
      [field.value?.length, helpers, name, onChange, options, selectAll],
    );

    const handleClear = useCallback(() => {
      handleChange({
        target: {
          name,
          value: multiple ? [] : null,
        },
      });
    }, [handleChange, multiple, name]);

    const groupedOptions = useMemo(() => {
      if (!groupOpts) return;

      const groupedOptions: SelectFieldOption[] = [];

      options
        .sort((o1, o2) => o1.groupName?.localeCompare(o2.groupName || "") ?? 0)
        .forEach((option) => {
          const optionGroupName = option.groupName || "";
          // add optGroup item if there is none matching the option's group name
          if (
            !groupedOptions.find(
              (o) => o.isOptGroup && o.name === optionGroupName,
            )
          ) {
            groupedOptions.push({
              id: optionGroupName,
              name: optionGroupName,
              isOptGroup: true,
            });
          }
          groupedOptions.push(option);
        });

      return groupedOptions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupOpts, options.length]);

    return (
      <TextField
        select
        id={name}
        onChange={handleChange}
        disabled={disabled}
        SelectProps={{
          endAdornment:
            clearable && !disabled ? (
              <CloseIcon
                onClick={handleClear}
                sx={{
                  cursor: "pointer",
                  fontSize: 14,
                  mr: 2,
                  opacity: field.value?.toString().length ? 1 : 0, // hide via opacity if no value, hidden this way to prevent menu from jumping when icon is shown/hidden
                }}
              />
            ) : null,
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          },
          multiple,
          renderValue: multiple
            ? (selected: any) =>
                selected
                  .map((v) => options?.find((o) => o.id === v)?.name || "Other")
                  .join(", ")
            : undefined,
          onOpen,
          onClose,
        }}
        value={value || ""}
        {...fieldRest}
        {...rest}
      >
        {selectAll && (
          <MenuItem value="all">
            {multiple && (
              <Checkbox
                checked={
                  field.value?.length ===
                  options?.filter((o) => !o.disabled).length
                }
              />
            )}
            <ListItemText primary="Select all" />
          </MenuItem>
        )}
        {(groupedOptions || options)?.map((o) =>
          o.isOptGroup ? (
            <MenuItem
              key={o.id}
              value={o.id}
              disabled={true}
              sx={{
                fontSize: "12px",
                color: "text.secondary",
                opacity: "1 !important",
              }}
            >
              {o.name}
            </MenuItem>
          ) : (
            <MenuItem
              key={o.id}
              value={o.id}
              disabled={o.disabled}
              sx={o.description ? { display: "block" } : undefined}
            >
              {multiple && (
                <Tooltip title={o.checkboxTooltip || ""} placement="right">
                  <Checkbox checked={field.value?.indexOf(o.id) >= 0} />
                </Tooltip>
              )}
              <ListItemText primary={o.name} />
              {o.description && (
                <Typography variant="caption" whiteSpace="pre-wrap">
                  {o.description}
                </Typography>
              )}
            </MenuItem>
          ),
        )}
        {menuTag && <Box padding="8px 16px">{menuTag}</Box>}
      </TextField>
    );
  },
);
