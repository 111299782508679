import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  formatDate,
  Navigation,
  RouterLink,
  RouterPrompt,
  useLocation,
  usePageTitle,
} from "../../../../lib";
import {
  AuditCorrectiveActionPlan,
  authSelectors,
  sharedActions,
  sysActions,
  sysSelectors,
  uiActions,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { AuditStatuses, PortalUserTypes } from "../../../../lib/constants";
import { AuditCorrectiveActionForm } from "./AuditCorrectiveActionForm";
import { getAuditCapStatusColor } from "./AuditHelpers";
import { CapEmptyContainerStyled, CapSubheaderStyled } from "./AuditCap.styles";
import { Link, Typography } from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../components";
import { FacilityAdminPages } from "../../../facilityAdmin";
import empty from "../../../../assets/img/empty.svg";

export const AuditCap = React.memo(
  /**
   *
   */
  function AuditCap() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();

    const setPageTitle = usePageTitle();

    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const facilityFilter = useSelector(sysSelectors.facilityFilter);
    const facilityOptions = useSelector(sysSelectors.allFacilities);

    const [showSubmitAuditDialog, setShowSubmitAuditDialog] = useState(false);

    const [cap, setCap] = useState<AuditCorrectiveActionPlan>();

    const retrieveCorrectiveActionPlan = useCallback(
      async (scrollToAction = false) => {
        if (params.id) {
          const cap = await dispatch(
            sharedActions.getAuditCorrectiveActionPlan(Number(params.id)),
          );
          if (cap) {
            setCap(cap);

            setPageTitle({
              title: "Corrective Action Plan",
              status: cap.statusDisplay,
              statusColor: getAuditCapStatusColor(cap.status),
              facilityId: cap.facilityID,
            });

            if (!isCcgAdmin) {
              // if the audit facilityId does not match the facility filter (or none is applied), apply the correct facility filter
              const facilityId = cap.facilityID;
              if (facilityFilter?.id !== facilityId) {
                const facility = facilityOptions.find(
                  (f) => f.id === facilityId,
                );
                if (facility) {
                  dispatch(sysActions.setFacilityFilter(facility));
                }
              }
            }

            // scroll to specified corrective action
            const actionId = location.query.actionId;
            if (scrollToAction && actionId) {
              setTimeout(() => {
                const actionElement = window.document.getElementById(
                  actionId!.toString(),
                );
                const elementTop = actionElement?.getBoundingClientRect().top;
                // scroll only if the element is within the bottom half of the page
                if (elementTop && elementTop > window.innerHeight / 2) {
                  window.scrollTo({
                    top: elementTop,
                    behavior: "smooth",
                  });
                }
                // note: leaving this out for now to avoid interfering with the back button behavior when this page is opened in a new tab
                // // clear actionId query param
                // Navigation.replace(location.pathname, {
                //   query: { ...location.query, actionId: undefined },
                // });
              }, 500);
            }
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dispatch, params.id, setPageTitle],
    );

    useEffect(() => {
      retrieveCorrectiveActionPlan(true);
    }, [retrieveCorrectiveActionPlan]);

    useEffect(() => {
      dispatch(uiActions.setPageHasSubtitle(true));
      return () => {
        dispatch(uiActions.setPageHasSubtitle(false));
      };
    }, [dispatch]);

    const [unsavedChanges, setUnsavedChanges] = useState(0);

    const onChangeUnsavedChanges = useCallback((hasUnsavedChanges) => {
      setUnsavedChanges((ct) => (hasUnsavedChanges ? ct + 1 : ct - 1));
    }, []);

    const onCorrectiveActionSubmission = useCallback(
      (correctiveActionId) => {
        if (isCcgAdmin) return;

        // if this is the final submission (all other corrective actions were submitted), prompt the user to return to the audit
        const isFinalSubmission = cap?.correctiveActions
          .filter((a) => a.id !== correctiveActionId)
          .every((a) => a.submittedOn);
        if (isFinalSubmission) {
          setShowSubmitAuditDialog(true);
        }
      },
      [cap?.correctiveActions, isCcgAdmin],
    );

    return cap ? (
      <>
        <RouterPrompt when={unsavedChanges > 0} cancelText="Back to CAP" />
        <CapSubheaderStyled>
          <Typography variant="body2" color="text.secondary" mr="4px">
            Related to
          </Typography>
          <Link
            component={RouterLink}
            to={Navigation.url(FacilityAdminPages.audit, {
              params: { id: cap.auditID },
            })}
          >
            {cap.auditName}
          </Link>
          {cap.createdOn && (
            <>
              <Typography variant="body2" color="text.secondary" margin="0 8px">
                ·
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Created on {formatDate(cap.createdOn)}
              </Typography>
            </>
          )}
        </CapSubheaderStyled>
        {!cap.correctiveActions.length ? (
          <CapEmptyContainerStyled>
            <img alt="no-preview" src={empty} height={180} />
            <Typography variant="subtitle1" mt={2}>
              No Corrective Actions found
            </Typography>
          </CapEmptyContainerStyled>
        ) : (
          cap.correctiveActions.map((correctiveAction) => (
            <AuditCorrectiveActionForm
              auditId={cap.auditID}
              correctiveAction={correctiveAction}
              key={correctiveAction.id}
              onChangeUnsavedChanges={onChangeUnsavedChanges}
              onSubmission={() =>
                onCorrectiveActionSubmission(correctiveAction.id)
              }
              readOnly={
                cap.auditStatus === AuditStatuses.Rescinded ||
                cap.auditStatus === AuditStatuses.Completed ||
                cap.auditStatus === AuditStatuses.CompletedCertificateSent
              }
              refreshCorrectiveActions={retrieveCorrectiveActionPlan}
            />
          ))
        )}
        {showSubmitAuditDialog && (
          <ConfirmationDialog
            confirmText="Return to audit"
            handleConfirm={() => {
              Navigation.go(
                Navigation.url(FacilityAdminPages.audit, {
                  params: { id: cap.auditID },
                }),
              );
            }}
            hideCancel={true}
            open={true}
            message="Your Corrective Action Plan (CAP) has been saved, but will not be reviewed by CCG until you resubmit the audit. Be sure to review and address all comments before resubmitting the audit."
            title="Corrective Action Plan (CAP) Saved"
            type={ConfirmationDialogTypes.Saved}
          />
        )}
      </>
    ) : null;
  },
);
