import React, { useCallback, useEffect, useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import {
  adminActions,
  AuditTemplate,
  BasicAudit,
  useAppDispatch,
} from "../../../../state";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  CheckboxField,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DialogCheckboxContainerStyled,
  ErrorTextStyled,
  FileUploadField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  InfoIcon,
  TextField,
  ToggleField,
} from "../../../../components";
import {
  FileUploadTypes,
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useFileUpload,
  useRedirect,
  yup,
} from "../../../../lib";
import {
  AuditsPageTabs,
  BasicStatuses,
  BasicStatusOptions,
  FileTypes,
} from "../../../../lib/constants";
import { AdminPages } from "../..";
import { InProgressAuditsListDialog } from "./InProgressAuditsListDialog";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(250, "Name cannot exceed 250 characters"),
  status: yup.string().required("Status is required"),
  url: yup.string().required("File is required"),
  infrequent: yup.boolean(),
});

export const AuditTemplateForm = React.memo(
  /**
   *
   */
  function AuditTemplateForm() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const { redirect, setRedirect } = useRedirect();

    const [initialValues, setInitialValues] = useState<AuditTemplate>({
      name: "",
      status: "",
      url: "",
      infrequent: false,
    });

    const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
      useState(false);
    const [showFileChangeConfirmation, setShowFileChangeConfirmation] =
      useState(false);

    const [updateSentAudits, setUpdateSentAudits] = useState(false);
    const [inProgressAudits, setInProgressAudits] = useState<BasicAudit[]>([]);

    useEffect(() => {
      (async () => {
        if (params.id) {
          const auditTemplate = await dispatch(
            adminActions.getAuditTemplate(Number(params.id)),
          );
          if (auditTemplate) {
            setInitialValues(replaceNullProps(auditTemplate));
          }
        }
      })();
    }, [dispatch, params.id]);

    const uploadFile = useFileUpload();

    const redirectToTemplatesList = useCallback(() => {
      const auditTemplatesListUrl = Navigation.url(AdminPages.audits, {
        query: {
          tab: AuditsPageTabs.Templates,
        },
      });
      setRedirect(() => () => Navigation.goBack(auditTemplatesListUrl));
    }, [setRedirect]);

    const getValuesForSubmission = useCallback(
      async (values: AuditTemplate, setFieldValue) => {
        const valuesForSubmission = { ...values };

        // if a new file was selected, upload file to get storage url
        if (values.url && typeof values.url === "object") {
          const url = await uploadFile(
            values.url,
            FileUploadTypes.AuditTemplate,
            true,
          );

          if (!url) {
            return;
          }

          // update the url
          valuesForSubmission.url = url;
          // update the form field value as well, so that if save fails later on the url will be persisted with the form and won't require re-uploading
          setFieldValue("url", url);
        }

        return valuesForSubmission;
      },
      [uploadFile],
    );

    const onSubmit = useCallback(
      async (
        values: AuditTemplate,
        { setFieldValue },
        changesConfirmed = false,
      ) => {
        if (changesConfirmed) {
          setShowDeactivateConfirmation(false);
          setShowFileChangeConfirmation(false);
        } else {
          if (
            initialValues.status === BasicStatuses.Active &&
            values.status === BasicStatuses.Inactive
          ) {
            setShowDeactivateConfirmation(true);
            return;
          }
          if (
            initialValues.id &&
            values.url &&
            typeof values.url === "object"
          ) {
            setShowFileChangeConfirmation(true);
            return;
          }
        }

        const valuesForSubmission = await getValuesForSubmission(
          values,
          setFieldValue,
        );
        if (!valuesForSubmission) {
          return;
        }

        const savedAuditTemplate = await dispatch(
          adminActions.submitAuditTemplate(
            replaceEmptyProps(valuesForSubmission),
            updateSentAudits,
          ),
        );
        if (savedAuditTemplate) {
          setInitialValues(replaceNullProps(savedAuditTemplate));

          if (updateSentAudits) {
            // reset updateSentAudits flag
            setUpdateSentAudits(false);

            // retrieve in progress audits
            const inProgressAudits = await dispatch(
              adminActions.getInProgressAuditsByTemplate(
                Number(savedAuditTemplate.id),
              ),
            );
            if (inProgressAudits?.length) {
              setInProgressAudits(inProgressAudits);
              return;
            }
          }

          redirectToTemplatesList();
        }
      },
      [
        dispatch,
        getValuesForSubmission,
        initialValues.id,
        initialValues.status,
        redirectToTemplatesList,
        updateSentAudits,
      ],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    const onCancel = useCallback(
      () => Navigation.goBack(AdminPages.audits.path),
      [],
    );

    return (
      <>
        <Form
          form={form}
          noPrompt={!!redirect}
          promptCancelText="Back to Audit template"
        >
          <FormGridContainerStyled container>
            <FormGridItemFullWidthStyled item>
              <TextField name="name" label="Name" />
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <ToggleField
                fullWidth={false}
                name="status"
                options={BasicStatusOptions}
                size="large"
              />
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled>
              <Typography variant="h6">File</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled>
              <FileUploadField
                fileType={FileTypes.Pdf}
                description="file"
                name="url"
                secure={true}
              />
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled
              item
              display="flex"
              alignItems="center"
            >
              <CheckboxField name="infrequent" label="Infrequent" />
              <Tooltip title="Audit templates that are marked as 'infrequent' will be hidden from the Manage Audits dashboard by default.">
                <InfoIcon
                  fontSize="small"
                  sx={{ ml: 1, color: "text.secondary" }}
                />
              </Tooltip>
            </FormGridItemFullWidthStyled>
            <FormFooterContainerStyled>
              <Button
                variant="outlined"
                size="large"
                disabled={form.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </FormFooterContainerStyled>
          </FormGridContainerStyled>
        </Form>
        {showDeactivateConfirmation && (
          <ConfirmationDialog
            confirmText="Deactivate"
            handleClose={() => setShowDeactivateConfirmation(false)}
            handleConfirm={() => onSubmit(form.values, form, true)}
            message="You will no longer be able to send this audit to facilities. Facilities who already received the audit will not be impacted."
            open={true}
            title="Deactivate audit?"
            type={ConfirmationDialogTypes.Warning}
          />
        )}
        {showFileChangeConfirmation && (
          <ConfirmationDialog
            confirmText="Update"
            handleClose={() => {
              setShowFileChangeConfirmation(false);
              setUpdateSentAudits(false);
            }}
            handleConfirm={() => onSubmit(form.values, form, true)}
            message="This template will be used for all future audits."
            messageContent={
              <DialogCheckboxContainerStyled>
                <CheckboxInput
                  checked={updateSentAudits}
                  label="Update the template for all 'sent' audits"
                  name="updateSentAudits"
                  onChange={(_, val) => setUpdateSentAudits(val)}
                />
                <ErrorTextStyled fontSize="12px">
                  Note: The facility will not be notified of the change.
                  <br />
                  This action cannot be undone.
                </ErrorTextStyled>
              </DialogCheckboxContainerStyled>
            }
            open={true}
            title="Update audit?"
            type={ConfirmationDialogTypes.Warning}
          />
        )}
        {inProgressAudits.length > 0 && (
          <InProgressAuditsListDialog
            audits={inProgressAudits}
            handleClose={redirectToTemplatesList}
          />
        )}
      </>
    );
  },
);
