import React from "react";
import { StatusPage } from "./components";
import notFound from "../../assets/img/not_found.svg";

export const NotFoundPage = React.memo(
  /**
   *
   */
  function NotFoundPage() {
    return (
      <StatusPage
        img={notFound}
        title="404 - Page not found"
        showHomepageLink={true}
      />
    );
  },
);
