import React, { useCallback, useMemo } from "react";
import { sharedActions, useAppDispatch } from "../../../../state";
import { DownloadIcon, PdfViewer } from "../../../../components";
import { IconButton } from "@mui/material";
import { downloadFromBase64 } from "../../../../lib";

interface FacilityFilePreviewProps {
  facilityFileId: number;
  printOnly?: boolean;
  printToolbarAddon?: JSX.Element;
}

export const FacilityFilePreview = React.memo(
  /**
   *
   */
  function FacilityFilePreview({
    facilityFileId,
    printOnly,
    printToolbarAddon,
  }: FacilityFilePreviewProps) {
    const dispatch = useAppDispatch();

    const retrieveFileData = useMemo(
      () => () => sharedActions.getFacilityFilePreview(facilityFileId),
      [facilityFileId],
    );

    const downloadFile = useCallback(async () => {
      const downloadResponse = await dispatch(
        sharedActions.getFacilityFileDownload(facilityFileId),
      );
      if (downloadResponse) {
        const fileName = downloadResponse.fileName;
        const fileType = `application/${
          fileName.endsWith(".doc") || fileName.endsWith(".docx")
            ? "msword"
            : "pdf" // default to pdf
        }`;

        downloadFromBase64(fileName, fileType, downloadResponse.fileData);
      }
    }, [dispatch, facilityFileId]);

    return (
      <PdfViewer
        allowDownload={!printOnly}
        allowPrint={true}
        downloadComponent={
          !printOnly ? (
            <IconButton onClick={downloadFile}>
              <DownloadIcon />
            </IconButton>
          ) : undefined
        }
        name="facility file"
        printOnly={printOnly}
        toolbarAddon={printToolbarAddon}
        retrieveData={retrieveFileData}
      />
    );
  },
);
