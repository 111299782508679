import React from "react";
import { Link } from "@mui/material";
import { FacilityAdminPages } from "../..";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../components";
import { Navigation, RouterLink, useWebViewer } from "../../../../lib";
import { Audit } from "../../../../state";
import { AuditStatuses } from "../../../../lib/constants";

interface AuditSavedDialogProps {
  audit: Audit;
  handleClose: () => void;
}

export const AuditSavedDialog = React.memo(
  /**
   *
   */
  function AuditSavedDialog({ audit, handleClose }: AuditSavedDialogProps) {
    const { viewerInstance } = useWebViewer();

    if (audit.status !== AuditStatuses.ResentCorrectionsNeeded) {
      return (
        <ConfirmationDialog
          cancelText="Back to audit"
          confirmText="Leave page"
          handleClose={handleClose}
          handleConfirm={() => Navigation.replace(FacilityAdminPages.audits)}
          message="Your progress has been saved. You can continue the audit when you return."
          open={true}
          title="Audit saved"
          type={ConfirmationDialogTypes.Saved}
        />
      );
    }

    if (audit.hasIncompleteCorrectiveActions) {
      return (
        <ConfirmationDialog
          cancelText="Close"
          confirmText="Complete CAP"
          handleClose={() => Navigation.replace(FacilityAdminPages.audits)}
          handleConfirm={() =>
            Navigation.go(FacilityAdminPages.auditCap, {
              params: { id: audit.id },
            })
          }
          message={
            <>
              Your audit has been saved, but will not be reviewed by CCG until
              you complete{" "}
              <Link
                component={RouterLink}
                to={Navigation.url(FacilityAdminPages.auditCap, {
                  params: { id: audit.id },
                })}
              >
                the Corrective Action Plan (CAP)
              </Link>{" "}
              and submit the audit. If you feel that the CAP is not needed,
              please enter N/A and the reason why it is not needed.
            </>
          }
          open={true}
          title="Audit saved"
          type={ConfirmationDialogTypes.Alert}
        />
      );
    }

    const { annotationManager } = viewerInstance.Core;
    const requiredFieldsFilled = annotationManager
      .getFieldManager()
      .areRequiredFieldsFilled();

    return (
      <ConfirmationDialog
        cancelText="Close"
        confirmText="Return to audit"
        handleClose={() => Navigation.replace(FacilityAdminPages.audits)}
        handleConfirm={handleClose}
        message={`Your audit has been saved, but will not be reviewed by CCG until you ${
          requiredFieldsFilled
            ? "submit it"
            : "completed all required fields and submit the audit"
        }.`}
        open={true}
        title="Audit saved"
        type={
          requiredFieldsFilled
            ? ConfirmationDialogTypes.Warning
            : ConfirmationDialogTypes.Alert
        }
      />
    );
  },
);
