import React, { useCallback, useState } from "react";
import { Button, Link } from "@mui/material";
import { Navigation, RouterLink, useLocation, yup } from "../../lib";
import { authActions, useAppDispatch } from "../../state";
import { PasswordField, TextField } from "../../components";
import { Formik, Form } from "formik";
import {
  AuthCardStyled,
  ButtonBoxStyled,
  ForgotPassBoxStyled,
  SubtitleTextStyled,
  TitleTextStyled,
} from "./Auth.styles";
import { AuthPages } from ".";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const dispatch = useAppDispatch();
    const {
      query: { "pw-set": pwSetSuccess, "email-conf": emailConfirmedSuccess },
    } = useLocation();
    const [error, setError] = useState("");

    const handleSubmit = useCallback(
      async (values) => {
        const { success, message } = await dispatch(
          authActions.login({
            username: values.email,
            password: values.password,
          }),
        );
        if (!success) {
          setError(
            message || "Invalid username or password. Please try again.",
          );
        }
      },
      [dispatch],
    );

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">Sign in</TitleTextStyled>
        {error ? (
          <SubtitleTextStyled variant="body2" sx={{ color: "error.dark" }}>
            {error}
          </SubtitleTextStyled>
        ) : pwSetSuccess ? (
          <SubtitleTextStyled variant="body2" sx={{ color: "success.dark" }}>
            Password has been set successfully! Sign in and you're good to go.
          </SubtitleTextStyled>
        ) : emailConfirmedSuccess ? (
          <SubtitleTextStyled variant="body2" sx={{ color: "success.dark" }}>
            Email has been successfully confirmed! Sign in and you're good to
            go.
          </SubtitleTextStyled>
        ) : (
          <SubtitleTextStyled variant="body2">
            Welcome! Sign in to continue.
          </SubtitleTextStyled>
        )}
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <TextField autoFocus autofill name="email" label="Email" />
              <PasswordField autofill name="password" label="Password" />
              <ForgotPassBoxStyled>
                <Link
                  component={RouterLink}
                  to={Navigation.url(AuthPages.requestSetPassword, {
                    query: { email: values.email },
                  })}
                >
                  Forgot Password?
                </Link>
              </ForgotPassBoxStyled>
              <ButtonBoxStyled>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Sign In
                </Button>
              </ButtonBoxStyled>
            </Form>
          )}
        </Formik>
      </AuthCardStyled>
    );
  },
);
