import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import { ListTypes } from "../../../../state/lists/state";
import {
  DeleteIcon,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
} from "../../../../components";
import { Navigation, useHoveredRow, useLocation } from "../../../../lib";
import { RoleForm } from "./RoleForm";
import { RootStyled } from "./PositionsRolesList.styles";
import { adminActions, RoleRecord, useAppDispatch } from "../../../../state";

const filterQueryKey = {
  text: "search",
};

interface RolesListActionsProps extends ListActionsProps {
  onAddRole: () => void;
}

function RolesListActions({
  onAddRole,
  onFilterChange = () => {},
  query,
}: RolesListActionsProps) {
  const { search = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!search);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Access levels</Typography>
        <Box display="flex">
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddRole}
            size="large"
            sx={{ ml: 1 }}
          >
            Add new access level
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search access levels"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 1 }}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

export const RolesList = React.memo(
  /**
   *
   */
  function RolesList() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const roleId = location.query.roleId;

    const onEditRole = useCallback(
      (id: number | string) => {
        const urlConfig = { query: { ...location.query, roleId: id } };
        Navigation.replace(location.pathname, urlConfig);
      },
      [location.pathname, location.query],
    );

    const onDeleteRole = useCallback(
      async (id: number) => {
        const deleted = await dispatch(adminActions.deleteRole(id));
        if (deleted) {
          //if role is selected, clear it from query params
          if (roleId === id) {
            const urlConfig = {
              query: { ...location.query, roleId: undefined },
            };
            Navigation.replace(location.pathname, urlConfig);
          }
        }
      },
      [dispatch, location.pathname, location.query, roleId],
    );

    const { hoveredRowId, onRowHover, onRowLeave } = useHoveredRow();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Access level",
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          width: 80,
          renderCell: ({ row }: { row: RoleRecord }) => {
            if (
              hoveredRowId !== row.id.toString() &&
              roleId?.toString() !== row.id.toString()
            ) {
              return null;
            }
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteRole(row.id);
                }}
                size="small"
                sx={{ color: "text.secondary" }}
              >
                <DeleteIcon />
              </IconButton>
            );
          },
          sortable: false,
        },
      ],
      [hoveredRowId, onDeleteRole, roleId],
    );

    return (
      <RootStyled formOpen={!!roleId}>
        <List
          actions={<RolesListActions onAddRole={() => onEditRole("new")} />}
          columns={columns}
          filterQueryKey={filterQueryKey}
          name="access levels"
          onRowClick={({ row }: { row: RoleRecord }) => onEditRole(row.id)}
          onRowHover={onRowHover}
          onRowLeave={onRowLeave}
          stickyHeader={!roleId}
          type={ListTypes.userRoles}
        />
        {roleId && <RoleForm />}
      </RootStyled>
    );
  },
);
