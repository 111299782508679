import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import background from "../../assets/img/auth_background.svg";
import { maxPageWidth } from "../main/MainLayout.styles";

export const RootStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanelStyled = styled(`div`)(({ theme }) => ({
  padding: 16,
  background: `url(${background}), linear-gradient(90deg, ${theme.palette.primary.dark} 80%, ${theme.palette.background.default} 20%)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 100%",
  backgroundPosition: "right",
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
    width: "50%",
    padding: 80,
    paddingLeft: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "end",
    " > div": {
      width: "100%",
      maxWidth: maxPageWidth / 2 - 160,
      " > *": {
        maxWidth: 296,
      },
    },
  },
}));

export const RightPanelStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  marginBottom: 100,
  [theme.breakpoints.up("xs")]: {
    height: "100%",
    width: "50%",
  },
}));

export const SidebarTitleStyled = styled(`div`)(({ theme }) => ({
  color: theme.palette.background.default,
  marginTop: 48,
  marginBottom: 24,
}));

export const SidebarSubtitleStyled = styled(`div`)(({ theme }) => ({
  color: theme.palette.background.default,
  marginBottom: 40,
}));

export const SidebarLinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.background.default,
  display: "block",
  marginBottom: 16,
}));

export const SidebarSocialsStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  " & svg": {
    color: theme.palette.background.default,
  },
}));
