import React, { useCallback, useEffect, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ListTypes } from "../../state/lists/state";
import {
  AccountCircleIcon,
  AssignmentLateIcon,
  BuildIcon,
  ChromeReaderModeIcon,
  FindInPageIcon,
  HtmlSanitizedText,
  LinkTextStyled,
  List,
  ListActionsProps,
  SchoolIcon,
  SearchInput,
} from "../../components";
import { Navigation, listFormat, pluralizeText, useLocation } from "../../lib";
import {
  authSelectors,
  listsSelectors,
  SearchResultRecord,
  sysActions,
  sysSelectors,
  useAction,
  useSelector,
} from "../../state";
import {
  HighlightTextStyled,
  NoResultsSubTextStyled,
  SearchHeaderStyled,
  SearchInputContainerStyled,
  SearchResultContainerStyled,
} from "./SearchPage.styles";
import {
  PermissionClaims,
  PortalUserTypes,
  SearchResultTypes,
  TrainingsPageTabs,
} from "../../lib/constants";
import { AdminPages } from "../admin";
import { FacilityAdminPages } from "../facilityAdmin";
import { AdminSharedPages } from ".";

const filterQueryKey = {
  text: "search",
  type: "type",
};

const defaultRowHeight = 84;

interface SearchType {
  type: string;
  name: string;
  icon: any;
  rowHeight: number;
  getUrl: (record: SearchResultRecord, isCcgAdmin: boolean) => boolean;
  getUniqueId: (record: SearchResultRecord) => number | string;
  authClaim: string;
}

const searchTypes: SearchType[] = [
  {
    type: SearchResultTypes.Policy,
    name: "Policies",
    icon: ChromeReaderModeIcon,
    rowHeight: 140,
    getUrl: ({ id, facilityID }: SearchResultRecord, isCcgAdmin = false) =>
      isCcgAdmin
        ? Navigation.url(AdminPages.editPolicy, {
            params: { id },
            query: facilityID
              ? { tab: "versions", facilities: facilityID }
              : undefined,
          })
        : Navigation.url(FacilityAdminPages.policy, {
            params: { id },
            query: { facility: facilityID },
          }),
    getUniqueId: ({ id, facilityID }: SearchResultRecord) =>
      `${id}-${facilityID}`,
    authClaim: PermissionClaims.PoliciesReadClaim,
  },
  {
    type: SearchResultTypes.Tool,
    name: "Tools",
    icon: BuildIcon,
    rowHeight: 140,
    getUrl: ({ id }: SearchResultRecord, isCcgAdmin = false) =>
      isCcgAdmin
        ? Navigation.url(AdminPages.editResource, {
            params: { id },
          })
        : Navigation.url(FacilityAdminPages.resource, { params: { id } }),
    getUniqueId: ({ id }: SearchResultRecord) => id,
    authClaim: PermissionClaims.ResourcesReadClaim,
  },
  {
    type: SearchResultTypes.RequiredDocument,
    name: "Required Documents",
    icon: AssignmentLateIcon,
    rowHeight: 140,
    getUrl: ({ id }: SearchResultRecord, isCcgAdmin = false) =>
      isCcgAdmin
        ? Navigation.url(AdminPages.editResource, {
            params: { id },
          })
        : Navigation.url(FacilityAdminPages.resource, { params: { id } }),
    getUniqueId: ({ id }: SearchResultRecord) => id,
    authClaim: PermissionClaims.ResourcesReadClaim,
  },
  {
    type: SearchResultTypes.User,
    name: "Users",
    icon: AccountCircleIcon,
    rowHeight: defaultRowHeight,
    getUrl: ({ id }: SearchResultRecord) =>
      Navigation.url(AdminSharedPages.editUser, { params: { id } }),
    getUniqueId: ({ id }: SearchResultRecord) => id,
    authClaim: PermissionClaims.UsersReadClaim,
  },
  {
    type: SearchResultTypes.Training,
    name: "Trainings",
    icon: SchoolIcon,
    rowHeight: defaultRowHeight,
    getUrl: ({ id }: SearchResultRecord, isCcgAdmin = false) =>
      isCcgAdmin
        ? Navigation.url(AdminPages.editTraining, {
            params: { id },
          })
        : Navigation.url(FacilityAdminPages.training, { params: { id } }),
    getUniqueId: ({ id }: SearchResultRecord) => id,
    authClaim: PermissionClaims.TrainingsReadClaim,
  },
  {
    type: SearchResultTypes.TrainingPacket,
    name: "Training Packets",
    icon: SchoolIcon,
    rowHeight: defaultRowHeight,
    getUrl: ({ id, titleText }: SearchResultRecord, isCcgAdmin = false) =>
      isCcgAdmin
        ? Navigation.url(AdminPages.editTrainingPacket, {
            params: { id },
          })
        : Navigation.url(FacilityAdminPages.trainings, {
            query: {
              tab: TrainingsPageTabs.TrainingPackets,
              search: titleText,
            },
          }),
    getUniqueId: ({ id }: SearchResultRecord) => id,
    authClaim: PermissionClaims.TrainingsReadClaim,
  },
];

const searchTypesObj = {
  [SearchResultTypes.Policy]: searchTypes.find(
    (t) => t.type === SearchResultTypes.Policy,
  ),
  [SearchResultTypes.Tool]: searchTypes.find(
    (t) => t.type === SearchResultTypes.Tool,
  ),
  [SearchResultTypes.RequiredDocument]: searchTypes.find(
    (t) => t.type === SearchResultTypes.RequiredDocument,
  ),
  [SearchResultTypes.User]: searchTypes.find(
    (t) => t.type === SearchResultTypes.User,
  ),
  [SearchResultTypes.Training]: searchTypes.find(
    (t) => t.type === SearchResultTypes.Training,
  ),
  [SearchResultTypes.TrainingPacket]: searchTypes.find(
    (t) => t.type === SearchResultTypes.TrainingPacket,
  ),
};

interface SearchActionsProps extends ListActionsProps {
  accessibleSearchTypes: SearchType[];
}

function SearchActions({
  accessibleSearchTypes,
  onFilterChange = () => {},
  query,
}: SearchActionsProps) {
  const {
    data: { numberOfRows },
  } = useSelector(listsSelectors.search);

  const hasMultipleFacilities =
    useSelector(sysSelectors.activeFacilities).length > 1;

  const facilityFilter = useSelector(sysSelectors.facilityFilter);
  const clearFacilityFilter = useAction(sysActions.clearFacilityFilter);

  const { search = "", type = "" } = query;

  return (
    <>
      <SearchInputContainerStyled>
        <SearchInput
          variant="outlined"
          placeholder="Search CCG Portal..."
          name="search"
          value={search}
          debounced={true}
          debounceWait={750}
          onSearch={(_search) => {
            if (_search) {
              onFilterChange("search", _search);
            }
          }}
          size="small"
        />
      </SearchInputContainerStyled>
      <Box borderBottom="1px solid" borderColor="divider">
        <Tabs value={type} onChange={(_, val) => onFilterChange("type", val)}>
          {accessibleSearchTypes.map((t) => (
            <Tab key={t.type} label={t.name} value={t.type} />
          ))}
        </Tabs>
      </Box>
      <SearchHeaderStyled>
        <Typography variant="caption" color="text.secondary">
          {numberOfRows} {pluralizeText("result", numberOfRows)} found{" "}
          {hasMultipleFacilities && !!facilityFilter && (
            <>
              for {facilityFilter.name}
              <LinkTextStyled
                variant="caption"
                onClick={clearFacilityFilter}
                ml={1}
              >
                View results for all facilities
              </LinkTextStyled>
            </>
          )}
        </Typography>
      </SearchHeaderStyled>
    </>
  );
}

function renderSearchResult({ row }: { row: SearchResultRecord }) {
  const { facilityName, highlightText, subtitleText, titleText, type } = row;
  const ResultIcon = searchTypesObj[type]?.icon || FindInPageIcon;
  return (
    <SearchResultContainerStyled>
      <Box>
        <ResultIcon sx={{ color: "neutral.main", mr: 2 }} />
        <Typography variant="subtitle2">
          {titleText}
          {facilityName ? ` - ${facilityName}` : ""}
        </Typography>
      </Box>
      <Box>
        {subtitleText && (
          <Typography variant="caption" color="text.secondary" mt={1}>
            {subtitleText}
          </Typography>
        )}
        {highlightText && (
          <HtmlSanitizedText
            mt={1}
            sanitizationConfig={{
              ALLOWED_TAGS: ["tag"], // Only allow specified tags
            }}
            styledTypography={HighlightTextStyled}
            text={highlightText}
          />
        )}
      </Box>
    </SearchResultContainerStyled>
  );
}

export const SearchPage = React.memo(
  /**
   *
   */
  function SearchPage() {
    const location = useLocation();
    const searchType = location.query.type;

    const userType = useSelector(authSelectors.userType);
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const accessibleSearchTypes = useMemo(
      () =>
        searchTypes.filter((t) => userPermissionClaims.includes(t.authClaim)),
      [userPermissionClaims],
    );

    // default the results tab
    useEffect(() => {
      if (!searchType && accessibleSearchTypes.length) {
        Navigation.replace(location.pathname, {
          query: { ...location.query, type: accessibleSearchTypes[0].type },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "result",
          flex: 1,
          renderCell: renderSearchResult,
        },
      ],
      [],
    );

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => (facilityFilter ? { facilityIDs: [facilityFilter.id] } : undefined),
      [facilityFilter],
    );

    const onRowClick = useCallback(
      ({ row }: { row: SearchResultRecord }) => {
        const url = searchTypesObj[row.type]!.getUrl(row, isCcgAdmin);
        Navigation.go(url);
      },
      [isCcgAdmin],
    );

    const listName =
      (searchType && searchTypesObj[searchType.toString()]?.name) ||
      "search results";

    return searchType ? (
      <List
        actions={
          <SearchActions accessibleSearchTypes={accessibleSearchTypes} />
        }
        columns={columns}
        getRowHeight={({ model }) =>
          isCcgAdmin ? defaultRowHeight : searchTypesObj[model.type]?.rowHeight
        }
        getRowId={(model) =>
          searchTypesObj[model.type]?.getUniqueId(model) || model.id
        }
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        hideHeader={true}
        name={listName}
        noRowsDisplay={
          <>
            <Typography variant="subtitle2">No {listName} found</Typography>
            {accessibleSearchTypes.length > 1 && (
              <NoResultsSubTextStyled variant="caption">
                View more search results in the{" "}
                {listFormat(
                  accessibleSearchTypes
                    .filter((s) => s.type !== searchType)
                    .map((s) => s.name),
                )}{" "}
                tabs.
              </NoResultsSubTextStyled>
            )}
          </>
        }
        onRowClick={onRowClick}
        type={ListTypes.search}
      />
    ) : null;
  },
);
