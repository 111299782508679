import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import {
  adminActions,
  sysSelectors,
  Training,
  uiActions,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { useParams } from "react-router-dom";
import {
  AutocompleteField,
  CheckboxField,
  FileListUploadField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  TextField,
  ToggleField,
  ToggleInput,
} from "../../../../components";
import {
  FileUploadTypes,
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useFileUpload,
  useRedirect,
  yup,
} from "../../../../lib";
import { AdminPages } from "../..";
import {
  ApplicableForTypes,
  BasicStatusOptions,
  TrainingTypes,
} from "../../../../lib/constants";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(250, "Name cannot exceed 250 characters"),
  description: yup
    .string()
    .nullable()
    .max(1000, "Description cannot exceed 1,000 characters"),
  type: yup.string().required("Type is required"),
  isRequiredOnHire: yup
    .boolean()
    .nullable()
    .test(
      "requiredTimeframe",
      "Select a required timeframe",
      (value, context) =>
        !(
          !value &&
          !context.parent.isRequiredAnnually &&
          context.parent.type === TrainingTypes.Required
        ),
    ),
  isRequiredAnnually: yup
    .boolean()
    .nullable()
    .test(
      "requiredTimeframe",
      "Select a required timeframe",
      (value, context) =>
        !(
          !value &&
          !context.parent.isRequiredOnHire &&
          context.parent.type === TrainingTypes.Required
        ),
    ),
  status: yup.string().required("Status is required"),
  facilityIDs: yup.array().when("$applicableForType", {
    is: (applicableForType) =>
      applicableForType === ApplicableForTypes.Facility,
    then: (schema) => schema.min(1, "Facility is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  stateIDs: yup.array().when("$applicableForType", {
    is: (applicableForType) => applicableForType === ApplicableForTypes.State,
    then: (schema) => schema.min(1, "State is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  policyID: yup.number().nullable(),
  trainingMaterials: yup.array(),
});

export const TrainingForm = React.memo(
  /**
   *
   */
  function TrainingForm() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const { redirect, setRedirect } = useRedirect();

    const {
      trainingTypes: trainingTypeOptions = [],
      states: stateOptions = [],
    } = useSelector(sysSelectors.systemSettings);
    const facilityOptions = useSelector(sysSelectors.allFacilities);
    const policyOptions = useSelector(sysSelectors.allPolicies);

    const [initialValues, setInitialValues] = useState<Training>({
      name: "",
      description: "",
      type: "",
      isRequiredOnHire: false,
      isRequiredAnnually: false,
      status: "",
      policyID: null,
      facilityIDs: [],
      stateIDs: [],
      trainingMaterials: [],
    });

    const [applicableForType, setApplicableForType] = useState(
      ApplicableForTypes.State,
    );

    useEffect(() => {
      (async () => {
        if (params.id) {
          const training = await dispatch(
            adminActions.getTraining(Number(params.id)),
          );
          if (training) {
            setInitialValues(replaceNullProps(training));
            if (training.facilityIDs.length) {
              setApplicableForType(ApplicableForTypes.Facility);
            }
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id]);

    const uploadFile = useFileUpload();

    const getValuesForSubmission = useCallback(
      async (values: Training, setFieldValue) => {
        const valuesForSubmission = { ...values };

        // clear out facilities if applicable-for-type is state, clear states if type is facility (not clearing until submission to persist data when user toggles back and forth)
        if (
          applicableForType === ApplicableForTypes.State &&
          values.facilityIDs.length
        ) {
          valuesForSubmission.facilityIDs = [];
        } else if (
          applicableForType === ApplicableForTypes.Facility &&
          values.stateIDs.length
        ) {
          valuesForSubmission.stateIDs = [];
        }

        if (valuesForSubmission.trainingMaterials.some((f) => !f.id)) {
          dispatch(uiActions.setLoading(true));

          const fileUploadActions = [] as Promise<boolean>[];
          const uploadAction = async (file, fileListName, fileIndex) => {
            const url = await uploadFile(
              file,
              FileUploadTypes.TrainingMaterial,
              true,
            );

            if (!url) {
              return false;
            }

            const uploadedFile = { name: file.name, url };
            valuesForSubmission[fileListName][fileIndex] = uploadedFile;
            // update the form field value as well, so that if save fails later on the url will be persisted with the form and won't require re-uploading
            setFieldValue(`${fileListName}[${fileIndex}]`, uploadedFile);

            return true;
          };

          valuesForSubmission.trainingMaterials.forEach((file, index) => {
            if (!file.id)
              fileUploadActions.push(
                uploadAction(file, "trainingMaterials", index),
              );
          });

          const filesUploaded = await Promise.all(fileUploadActions);
          if (filesUploaded.some((uploaded) => !uploaded)) {
            return;
          }

          dispatch(uiActions.setLoading(false));
        }

        return valuesForSubmission;
      },
      [applicableForType, dispatch, uploadFile],
    );

    const onSubmit = useCallback(
      async (values: Training, { setFieldValue }) => {
        const valuesForSubmission = await getValuesForSubmission(
          values,
          setFieldValue,
        );
        if (!valuesForSubmission) {
          return;
        }

        const savedTraining = await dispatch(
          adminActions.submitTraining(replaceEmptyProps(valuesForSubmission)),
        );
        if (savedTraining) {
          setInitialValues(replaceNullProps(savedTraining));

          setRedirect(() => () => Navigation.goBack(AdminPages.trainings.path));
        }
      },
      [dispatch, getValuesForSubmission, setRedirect],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            applicableForType,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      onSubmit,
    });

    const onCancel = useCallback(
      () => Navigation.goBack(AdminPages.trainings.path),
      [],
    );

    const onChangeType = useCallback(
      (e) => {
        const { value } = e.target;
        form.setFieldValue("type", value);

        // clear required fields if type is changed from required
        if (value !== TrainingTypes.Required) {
          if (form.values.isRequiredOnHire) {
            form.setFieldValue("isRequiredOnHire", false);
          }
          if (form.values.isRequiredAnnually) {
            form.setFieldValue("isRequiredAnnually", false);
          }
        }
      },
      [form],
    );

    return (
      <Form
        form={form}
        noPrompt={!!redirect}
        promptCancelText="Back to Training"
      >
        <FormGridContainerStyled container>
          <FormGridItemFullWidthStyled item>
            <TextField name="name" label="Name" />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <TextField
              name="description"
              label="Description"
              multiline={true}
              rows={4}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleField
              buttonWidth="140px"
              fullWidth={false}
              name="type"
              onChange={onChangeType}
              options={trainingTypeOptions}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          {form.values.type === TrainingTypes.Required && (
            <>
              <FormGridItemFullWidthStyled item>
                <CheckboxField
                  label="Required on hire"
                  name="isRequiredOnHire"
                />
                <CheckboxField
                  label="Required annually"
                  name="isRequiredAnnually"
                  sx={{ ml: 2 }}
                />
              </FormGridItemFullWidthStyled>
            </>
          )}
          <FormGridItemFullWidthStyled item>
            <ToggleField
              buttonWidth="140px"
              fullWidth={false}
              name="status"
              options={BasicStatusOptions}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <Typography variant="h6" mt={1} mb={1}>
              Training Materials
            </Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <FileListUploadField
              description="training material"
              name="trainingMaterials"
              noEdit={true}
              secure={true}
              showFileName={true}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <Typography variant="h6" mt={1} mb={1}>
              Applicable for
            </Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleInput
              name="applicableForType"
              value={applicableForType}
              onChange={(_, val) => setApplicableForType(val)}
              options={[
                { id: ApplicableForTypes.State, name: "State specific" },
                { id: ApplicableForTypes.Facility, name: "Facility specific" },
              ]}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            {applicableForType === ApplicableForTypes.State ? (
              <AutocompleteField
                name="stateIDs"
                label="Select states"
                multiple={true}
                options={stateOptions}
              />
            ) : (
              <AutocompleteField
                name="facilityIDs"
                label="Select facilities"
                multiple={true}
                options={facilityOptions}
              />
            )}
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <AutocompleteField
              name="policyID"
              label="Related policy"
              options={policyOptions.map((o) => ({
                id: o.id,
                name: o.title,
              }))}
            />
          </FormGridItemFullWidthStyled>
          <FormFooterContainerStyled>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </FormFooterContainerStyled>
        </FormGridContainerStyled>
      </Form>
    );
  },
);
