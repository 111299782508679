import React, { useCallback, useEffect, useState } from "react";
import {
  authSelectors,
  facilityAdminActions,
  FacilityTraining,
  sysSelectors,
  uiActions,
  useAppDispatch,
  useSelector,
} from "../../state";
import { Box, Link, Typography } from "@mui/material";
import { ErrorDisplayStyled, TabbedView } from "../../components";
import { useParams } from "react-router-dom";
import { StatusPage } from "../shared/components";
import { Navigation, RouterLink, usePageTitle } from "../../lib";
import { TrainingHeaderStyled } from "./TrainingPage.styles";
import { TrainingAttendanceList, TrainingMaterialsList } from "./components";
import { PermissionClaims } from "../../lib/constants";
import { FacilityAdminPages } from ".";
import facility from "../../assets/img/facility.svg";
import empty from "../../assets/img/empty.svg";

const tabs = [
  {
    id: "materials",
    name: "Training Materials",
    component: TrainingMaterialsList,
  },
  {
    id: "attendees",
    name: "Attendees",
    component: TrainingAttendanceList,
  },
];

export const TrainingPage = React.memo(
  /**
   *
   */
  function TrainingPage() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const hasMultipleFacilities =
      useSelector(sysSelectors.activeFacilities).length > 1;

    const setPageTitle = usePageTitle();

    const [training, setTraining] = useState<FacilityTraining>();
    const [showError, setShowError] = useState(false);

    const fetchTraining = useCallback(async () => {
      if (facilityFilter?.id) {
        const training = await dispatch(
          facilityAdminActions.getTraining(
            Number(params.id),
            facilityFilter.id,
          ),
        );

        setShowError(!training);

        if (training) {
          setTraining(training);
          setPageTitle({
            title: training.name,
          });
        } else {
          setTraining(undefined);
        }
      }
    }, [dispatch, facilityFilter?.id, params.id, setPageTitle]);

    useEffect(() => {
      (async () => {
        if (params.id) {
          fetchTraining();
        }
      })();
    }, [fetchTraining, params.id]);

    useEffect(() => {
      dispatch(uiActions.setPageHasSubtitle(true));
      return () => {
        dispatch(uiActions.setPageHasSubtitle(false));
      };
    }, [dispatch]);

    return !facilityFilter?.id ? ( // show status page when there is no system facility filter
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="Select a facility from the blue bar at the top of the screen to view this training"
      />
    ) : showError ? (
      <ErrorDisplayStyled>
        <img alt="no-preview" src={empty} height={180} />
        <Typography variant="subtitle1" mt={4}>
          Cannot access training
        </Typography>
        <Typography variant="body2" mt={1} mb={4}>
          This training may not be available for your facility
          {hasMultipleFacilities ? " selection" : ""}
        </Typography>
      </ErrorDisplayStyled>
    ) : training ? (
      <>
        <TrainingHeaderStyled>
          {training.policy && (
            <Box
              display="flex"
              alignItems="center"
              mb={training.description ? 1 : 0}
            >
              <Typography variant="caption" color="text.secondary" mr={1}>
                Related Policy
              </Typography>
              <Link
                component={RouterLink}
                to={Navigation.url(FacilityAdminPages.policy, {
                  params: { id: training.policy.id },
                })}
                sx={{ fontSize: "12px" }}
              >
                {training.policy.title.replace(training.policy.name, "").trim()}
              </Link>
            </Box>
          )}
          {training.description && (
            <Typography variant="body2">{training.description}</Typography>
          )}
        </TrainingHeaderStyled>
        {userPermissionClaims.includes(
          PermissionClaims.TrainingsAttendanceClaim,
        ) ? (
          <TabbedView tabs={tabs} viewProps={{ training }} />
        ) : (
          <TrainingMaterialsList training={training} />
        )}
      </>
    ) : null;
  },
);
