import React from "react";
import { Tooltip } from "@mui/material";
import { getAuditStatusColor } from "../../../../adminShared/components/audits/AuditHelpers";
import { AuditStatusHistory } from "../../../../../state";
import { formatDate } from "../../../../../lib";
import { styled } from "@mui/material/styles";

const AuditTimelineContainerStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "100%",
}));

const AuditTimelineLineStyled = styled(`div`)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.neutral.light}`,
  position: "absolute",
  width: "100%",
}));

interface AuditTimelineMarkerStyledProps {
  large?: boolean;
  status?: string;
}

const AuditTimelineMarkerStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "large" && prop !== "status",
})<AuditTimelineMarkerStyledProps>(({ large, status }) => ({
  height: large ? "24px" : "8px",
  width: large ? "24px" : "8px",
  borderRadius: "30px",
  zIndex: 1,
  backgroundColor: getAuditStatusColor(status, "", true),
}));

interface AuditStatusTimelineProps {
  history: AuditStatusHistory[];
  large?: boolean;
}

export function AuditStatusTimeline({
  history,
  large,
}: AuditStatusTimelineProps) {
  return (
    <AuditTimelineContainerStyled>
      <AuditTimelineLineStyled />
      {history.map((h, i) => (
        <Tooltip
          key={i}
          title={`${h.statusDisplay} - ${formatDate(h.dateTime)}`}
        >
          <AuditTimelineMarkerStyled large={large} status={h.status} />
        </Tooltip>
      ))}
    </AuditTimelineContainerStyled>
  );
}
