import React, { useCallback, useMemo } from "react";
import {
  BaseTextFieldProps,
  Checkbox,
  CircularProgress,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../icons";
import { Option } from "../../lib/types";
import { AutocompleteInput } from "./AutocompleteInput";

interface SelectInputOption extends Option {
  tag?: JSX.Element;
}

interface SelectInputProps extends BaseTextFieldProps {
  autocomplete?: boolean;
  clearable?: boolean;
  fitToContentWidth?: boolean;
  fullWidth?: boolean;
  groupOpts?: boolean;
  inputProps?: any;
  loading?: boolean;
  multiple?: boolean;
  name: string;
  onChange: (name: string, val: any, other?: object | undefined) => void;
  options: SelectInputOption[];
  selectAll?: boolean;
  value: any;
}

export const SelectInput = React.memo(
  /**
   *
   */
  function SelectInput({
    autocomplete,
    clearable = true,
    fitToContentWidth,
    fullWidth = !autocomplete,
    groupOpts,
    inputProps = {},
    loading,
    multiple,
    name,
    onChange,
    options,
    selectAll,
    sx,
    value,
    ...rest
  }: SelectInputProps) {
    const handleSelectChange = useCallback(
      (e) => {
        if (selectAll && e.target.value.indexOf("all") >= 0) {
          onChange(
            name,
            value?.length === options.length ? [] : options.map((o) => o.id),
          );
        } else {
          onChange(name, e.target.value);
        }
      },
      [name, onChange, options, selectAll, value?.length],
    );

    const groupedOptions = useMemo(() => {
      if (autocomplete || !groupOpts) return;

      const groupedOptions: SelectInputOption[] = [];

      options
        .sort((o1, o2) => o1.groupName?.localeCompare(o2.groupName || "") ?? 0)
        .forEach((option) => {
          const optionGroupName = option.groupName || "";
          // add optGroup item if there is none matching the option's group name
          if (
            !groupedOptions.find(
              (o) => o.isOptGroup && o.name === optionGroupName,
            )
          ) {
            groupedOptions.push({
              id: optionGroupName,
              name: optionGroupName,
              isOptGroup: true,
            });
          }
          groupedOptions.push(option);
        });

      return groupedOptions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupOpts, options.length]);

    return autocomplete ? (
      <AutocompleteInput
        clearable={clearable}
        fitToContentWidth={fitToContentWidth}
        fullWidth={fullWidth}
        groupOpts={groupOpts}
        inputProps={inputProps}
        multiple={multiple}
        name={name}
        onChange={onChange}
        options={options}
        sx={sx}
        value={value}
        {...rest}
      />
    ) : (
      <TextField
        select
        fullWidth={fullWidth}
        onChange={handleSelectChange}
        value={value}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size={14} sx={{ mr: 2 }} />
          ) : clearable ? (
            <CloseIcon
              onClick={() => onChange(name, multiple ? [] : "")}
              sx={{
                cursor: "pointer",
                fontSize: 14,
                mr: 2,
                opacity: value?.toString().length ? 1 : 0, // hide via opacity if no value, hidden this way to prevent menu from jumping when icon is shown/hidden
              }}
            />
          ) : null,
          ...inputProps,
        }}
        SelectProps={{
          multiple,
          renderValue: multiple
            ? (selected: any) =>
                selected
                  .map((v) => options.find((o) => o.id === v)?.name)
                  .join(", ")
            : undefined,
        }}
        size="small"
        sx={{ ...sx, ...(fitToContentWidth ? { width: "fit-content" } : {}) }}
        {...rest}
      >
        {selectAll && (
          <MenuItem value="all">
            {multiple && (
              <Checkbox checked={!!value && value.length === options.length} />
            )}
            <ListItemText primary="Select all" />
          </MenuItem>
        )}
        {(groupedOptions || options).map((o) =>
          o.isOptGroup ? (
            <MenuItem
              key={o.id}
              value={o.id}
              disabled={true}
              sx={{
                fontSize: "12px",
                color: "text.secondary",
                opacity: "1 !important",
              }}
            >
              {o.name}
            </MenuItem>
          ) : (
            <MenuItem key={o.id} value={o.id}>
              {multiple && <Checkbox checked={value?.indexOf(o.id) >= 0} />}
              <ListItemText primary={o.name} />
              {o.tag && value !== o.id && (
                <ListItemSecondaryAction>{o.tag}</ListItemSecondaryAction>
              )}
            </MenuItem>
          ),
        )}
      </TextField>
    );
  },
);
