import { Autocomplete, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageRootStyled } from "../../components";

export const RootStyled = styled(PageRootStyled)(() => ({
  alignItems: "center",
}));

export const AddMenuContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginBottom: "48px",
}));

export const AutocompleteSearchStyled = styled(Autocomplete)(() => ({
  width: "600px",
  margin: "48px 0",
}));

export const MaxSearchResultsNoteStyled = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  color: theme.palette.text.secondary,
  margin: "8px 24px",
  whiteSpace: "pre-wrap",
}));
