import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  AddMenuContainerStyled,
  AutocompleteSearchStyled,
  MaxSearchResultsNoteStyled,
  RootStyled,
} from "./GroupsAndFacilitiesPage.styles";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { SearchIcon } from "../../components";
import debounce from "lodash.debounce";
import {
  adminActions,
  GroupFacilityRegionSearchResponse,
  useAppDispatch,
} from "../../state";
import { getDuplicateValues, Navigation } from "../../lib";
import { AdminPages } from ".";
import {
  AddGroupFacilityRegionMenu,
  GroupsFacilitiesRegionsDashboard,
} from "./components";
import { EntityTypes } from "../../lib/constants";

const maxResults = 100; // limit results to 100
const maxResultsNoteName = "max_results_note";
const maxResultsNoteOption = {
  // option properties
  value: 0,
  label:
    "Can't find what you're looking for? \nSearch results are limited to 100 matches. Try refining your search to get more results.",
  group: "",
  // result properties
  id: 0,
  name: maxResultsNoteName,
  isActive: true,
  type: "",
};

const initialSearchResponse = {
  results: [],
  groupsCount: 0,
  regionsCount: 0,
  facilitiesCount: 0,
};

function GroupsFacilitiesRegionsSearch() {
  const dispatch = useAppDispatch();

  const [searchResponse, setSearchResponse] =
    useState<GroupFacilityRegionSearchResponse>(initialSearchResponse);
  const [searching, setSearching] = useState(false);

  const onSearch = useCallback(
    async (value) => {
      // reset search response when search is cleared
      if (!value) {
        setSearchResponse(initialSearchResponse);
        return;
      }

      setSearching(true);
      const response = await dispatch(
        adminActions.searchGroupsAndFacilities(value, 1, maxResults),
      ); // we are avoiding pagination by always requesting page 1 with a max result set of 100
      if (response) {
        setSearchResponse(response);
      }
      setSearching(false);
    },
    [dispatch],
  );

  const debounceSearch = useRef(
    debounce((value) => {
      onSearch(value);
    }, 500),
  );

  const handleChange = useCallback(
    (e) => debounceSearch.current(e.target.value),
    [],
  );

  const searchResultCategories = useMemo(
    () => ({
      [EntityTypes.Group]: {
        title: "Groups",
        url: AdminPages.group.path,
        count: searchResponse.groupsCount,
      },
      [EntityTypes.Region]: {
        title: "Regions",
        url: AdminPages.region.path,
        count: searchResponse.regionsCount,
      },
      [EntityTypes.Facility]: {
        title: "Facilities",
        url: AdminPages.facility.path,
        count: searchResponse.facilitiesCount,
      },
    }),
    [
      searchResponse.facilitiesCount,
      searchResponse.groupsCount,
      searchResponse.regionsCount,
    ],
  );

  const formattedSearchResults = useMemo(() => {
    const duplicateResultNames = getDuplicateValues(
      searchResponse.results.map((r) => r.name),
    );

    const results =
      searchResponse.results.map((r, i) => ({
        ...r,
        // set option props
        value: i,
        label: `${
          // append group name (or id for group results) to name property if result name is a duplicate
          duplicateResultNames.includes(r.name)
            ? `${r.name} (${r.type === EntityTypes.Group ? r.id : r.groupName})`
            : r.name
        }${r.isActive ? "" : " [Inactive]"}`,
        // set value for results grouping
        group: `${searchResultCategories[r.type].title} (${
          searchResultCategories[r.type].count
        })`,
      })) || [];

    // if not all results are shown, add a mock option with max results disclaimer
    const countExceedsMaxResults =
      results.length &&
      searchResponse.groupsCount +
        searchResponse.regionsCount +
        searchResponse.facilitiesCount >
        maxResults;
    if (countExceedsMaxResults) {
      results.push(maxResultsNoteOption);
    }
    return results;
  }, [
    searchResponse.facilitiesCount,
    searchResponse.groupsCount,
    searchResponse.regionsCount,
    searchResponse.results,
    searchResultCategories,
  ]);

  const onSelectResult = useCallback(
    (_, selection, reason) => {
      const { type, id } = selection || {};
      if (type && reason === "selectOption") {
        Navigation.go(searchResultCategories[type].url, { params: { id } });
      }
    },
    [searchResultCategories],
  );

  return (
    <AutocompleteSearchStyled
      disableCloseOnSelect={true} // to prevent the menu from closing when user hits enter
      filterOptions={(x) => x} // override default filtering because we are filtering on the server side
      freeSolo
      options={formattedSearchResults}
      groupBy={(option: any) => option.group}
      onChange={onSelectResult}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleChange}
          placeholder="Search for Groups and Facilities"
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
            endAdornment: searching ? <CircularProgress size={16} /> : null,
          }}
        />
      )}
      renderOption={(props, option: any) =>
        option.name === maxResultsNoteName ? (
          <MaxSearchResultsNoteStyled key={option.name}>
            {option.label}
          </MaxSearchResultsNoteStyled>
        ) : (
          <li {...props}>{option.label}</li>
        )
      }
    />
  );
}

export const GroupsAndFacilitiesPage = React.memo(
  /**
   *
   */
  function GroupsAndFacilitiesPage() {
    return (
      <RootStyled>
        <AddMenuContainerStyled>
          <AddGroupFacilityRegionMenu />
        </AddMenuContainerStyled>
        <Typography variant="h4">Groups & Facilities</Typography>
        <GroupsFacilitiesRegionsSearch />
        <GroupsFacilitiesRegionsDashboard />
      </RootStyled>
    );
  },
);
