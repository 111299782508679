import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  authSelectors,
  facilityAdminActions,
  IdNamePair,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import {
  SidebarRowStyled,
  SidebarTitleStyled,
  RootStyled,
} from "./PolicySidebar.styles";
import { OpenInNewIcon } from "../../../../components";
import { Link } from "react-router-dom";
import { Navigation, pluralizeText } from "../../../../lib";
import { FacilityAdminPages } from "../..";
import { PermissionClaims } from "../../../../lib/constants";

export interface PolicySidebarProps {
  facilityId: number;
  policyId: number;
}

export const PolicySidebar = React.memo(
  /**
   *
   */
  function PolicySidebar({ facilityId, policyId }: PolicySidebarProps) {
    const dispatch = useAppDispatch();

    const userPermissionClaims = useSelector(authSelectors.permissionClaims);
    const hasResourcesClaim = userPermissionClaims.includes(
      PermissionClaims.ResourcesReadClaim,
    );
    const hasTrainingsClaim = userPermissionClaims.includes(
      PermissionClaims.TrainingsReadClaim,
    );

    const [resources, setResources] = useState<IdNamePair[]>([]);
    const [trainings, setTrainings] = useState<IdNamePair[]>([]);

    useEffect(() => {
      (async () => {
        // retrieve resources
        if (hasResourcesClaim) {
          const resources = await dispatch(
            facilityAdminActions.getPolicyResources(policyId, facilityId),
          );
          setResources(resources || []);
        }
      })();
    }, [dispatch, facilityId, hasResourcesClaim, policyId]);

    useEffect(() => {
      (async () => {
        // retrieve trainings
        if (hasTrainingsClaim) {
          const trainings = await dispatch(
            facilityAdminActions.getPolicyTrainings(policyId, facilityId),
          );
          setTrainings(trainings || []);
        }
      })();
    }, [dispatch, facilityId, hasTrainingsClaim, policyId]);

    return resources.length || trainings.length ? (
      <RootStyled>
        {resources.length > 0 && (
          <Box mb={2}>
            <SidebarTitleStyled variant="subtitle1">
              Related resources
            </SidebarTitleStyled>
            {resources.map((resource) => (
              <SidebarRowStyled key={resource.id}>
                <Typography variant="body2">{resource.name}</Typography>
                <Link
                  to={Navigation.url(FacilityAdminPages.resource, {
                    params: { id: resource.id },
                  })}
                  target="_blank"
                >
                  <IconButton sx={{ color: "text.secondary", ml: 1 }}>
                    <OpenInNewIcon sx={{ height: "20px" }} />
                  </IconButton>
                </Link>
              </SidebarRowStyled>
            ))}
          </Box>
        )}
        {trainings.length > 0 && (
          <Box>
            <SidebarTitleStyled variant="subtitle1">
              {pluralizeText("Training", trainings.length)}
            </SidebarTitleStyled>
            {trainings.map((training) => (
              <SidebarRowStyled key={training.id}>
                <Typography variant="body2">{training.name}</Typography>
                <Link
                  to={Navigation.url(FacilityAdminPages.training, {
                    params: { id: training.id },
                  })}
                  target="_blank"
                >
                  <IconButton sx={{ color: "text.secondary", ml: 1 }}>
                    <OpenInNewIcon sx={{ height: "20px" }} />
                  </IconButton>
                </Link>
              </SidebarRowStyled>
            ))}
          </Box>
        )}
      </RootStyled>
    ) : null;
  },
);
