import { PageDefinitions } from "../../lib/types";
import { NotFoundPage } from "./NotFoundPage";
import { UnauthorizedPage } from "./UnauthorizedPage";

export const SharedPages: PageDefinitions = {
  unauthorized: {
    anon: false,
    path: "/unauthorized",
    title: "Unauthorized",
    view: UnauthorizedPage,
  },
  notFound: {
    anon: false,
    path: "*",
    title: "Not Found",
    view: NotFoundPage,
  },
};
