import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  CheckboxInput,
  CheckCircleOutlineIcon,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DialogCheckboxContainerStyled,
  ErrorOutlineIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  OpenInNewIcon,
  RestartAltIcon,
  SelectInput,
  VisibilityIcon,
} from "../../../../../components";
import { asArray, formatDateTime } from "../../../../../lib";
import { PolicyDocumentPublishStatuses } from "../../../../../lib/constants";
import { dateColumnSortingOrder } from "../../../../../components/lists/List";
import { ListTypes } from "../../../../../state/lists/state";
import {
  adminActions,
  BasicPolicyVersion,
  listActionUrls,
  PolicyDocumentPublishLogRecord,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import { PolicyPreviewDialog } from "./PolicyPreviewDialog";
import { PolicyTabProps } from "../../../PolicyPage";
import { PolicyPublishLogStatusStyled } from "./PolicyPublishLogs.styles";
import { differenceInHours } from "date-fns";

const filterQueryKey = {
  facilityIDs: "facilities",
  policyVersionIDs: "versions",
  publishStatuses: "publishStatuses",
};

const ActionTypes = {
  Preview: "Preview",
  Retry: "Retry",
};

interface PolicyPublishLogsListActionsProps extends ListActionsProps {
  policyId: number;
}

function PolicyPublishLogsListActions({
  onFilterChange = () => {},
  policyId,
  query,
}: PolicyPublishLogsListActionsProps) {
  const dispatch = useAppDispatch();

  const { policyDocumentPublishStatuses = [] } = useSelector(
    sysSelectors.systemSettings,
  );

  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const [policyVersionOptions, setPolicyVersionOptions] = useState<
    BasicPolicyVersion[]
  >([]);

  useEffect(() => {
    (async () => {
      const versions = await dispatch(
        adminActions.getPolicyVersions(policyId, false),
      );
      if (versions) {
        setPolicyVersionOptions(versions);
      }
    })();
  }, [dispatch, policyId]);

  const { facilities = [], publishStatuses = [], versions = [] } = query;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Publish log</Typography>
      </ListActionsMainStyled>
      <ListActionsExpandedStyled>
        <SelectInput
          label="Facility"
          name="facilities"
          value={asArray(facilities)}
          onChange={onFilterChange}
          options={facilityOptions}
          multiple={true}
          sx={{ mr: 2 }}
        />
        <SelectInput
          label="Version"
          name="versions"
          value={asArray(versions)}
          onChange={onFilterChange}
          options={policyVersionOptions}
          multiple={true}
          sx={{ mr: 2 }}
        />
        <SelectInput
          clearable={true}
          label="Publish status"
          name="publishStatuses"
          value={asArray(publishStatuses)}
          onChange={onFilterChange}
          options={policyDocumentPublishStatuses}
          multiple={true}
        />
      </ListActionsExpandedStyled>
    </ListActionsContainerStyled>
  );
}

export const PolicyPublishLogs = React.memo(
  /**
   *
   */
  function PolicyPublishLogs({ policy, refreshPolicy }: PolicyTabProps) {
    const dispatch = useAppDispatch();

    const [activeActionType, setActiveActionType] = useState<string>();
    const [activePublishLog, setActivePublishLog] =
      useState<PolicyDocumentPublishLogRecord>();

    const [notifyFacility, setNotifyFacility] = useState(false);

    const onActivateAction = useCallback(
      (actionType: string, publishLog?: PolicyDocumentPublishLogRecord) => {
        setActiveActionType(actionType);
        setActivePublishLog(publishLog);
      },
      [],
    );

    const onClearAction = useCallback(() => {
      setActiveActionType(undefined);
      setActivePublishLog(undefined);
      setNotifyFacility(false);
    }, []);

    const onRetry = useCallback(async () => {
      if (!activePublishLog) {
        return;
      }

      const published = await dispatch(
        adminActions.republishPolicyDocument(
          activePublishLog.policyID,
          activePublishLog.id,
          notifyFacility,
        ),
      );
      if (published) {
        refreshPolicy();
      }
      onClearAction();
    }, [
      activePublishLog,
      dispatch,
      notifyFacility,
      onClearAction,
      refreshPolicy,
    ]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "policyVersionName",
          headerName: "Version name",
          flex: 2,
          renderCell: ({ row }: { row: PolicyDocumentPublishLogRecord }) => (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{row.policyVersionName}</Typography>
              {row.policyVersionUrl && (
                <IconButton
                  sx={{ color: "text.secondary", ml: 1 }}
                  href={row.policyVersionUrl}
                  target="_blank"
                >
                  <OpenInNewIcon sx={{ height: "20px" }} />
                </IconButton>
              )}
            </Box>
          ),
        },
        {
          field: "facilityName",
          headerName: "Facility",
          flex: 2,
        },
        {
          field: "dateTime",
          headerName: "Date",
          flex: 2,
          valueFormatter: ({ value }) => formatDateTime(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "publishStatus",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: PolicyDocumentPublishLogRecord }) => (
            <PolicyPublishLogStatusStyled
              variant="body2"
              display="flex"
              alignItems="center"
              status={row.publishStatus}
            >
              {row.publishStatus === PolicyDocumentPublishStatuses.Published ? (
                <>
                  <CheckCircleOutlineIcon sx={{ mr: 1 }} /> Published
                </>
              ) : row.publishStatus ===
                PolicyDocumentPublishStatuses.PublishFailed ? (
                <>
                  <ErrorOutlineIcon sx={{ mr: 1 }} /> Failed
                </>
              ) : row.publishStatus ===
                PolicyDocumentPublishStatuses.Publishing ? (
                <>
                  <RestartAltIcon sx={{ mr: 1 }} /> Publishing
                </>
              ) : (
                ""
              )}
            </PolicyPublishLogStatusStyled>
          ),
        },
        {
          field: "retry-action",
          headerName: "",
          width: 100,
          renderCell: ({ row }: { row: PolicyDocumentPublishLogRecord }) => {
            // allow retry if publish failed or if is in publishing status for more than 5 minutes
            const allowRetry =
              row.publishStatus ===
                PolicyDocumentPublishStatuses.PublishFailed ||
              (row.publishStatus === PolicyDocumentPublishStatuses.Publishing &&
                differenceInHours(new Date(), new Date(row.dateTime)) > 6);
            return allowRetry ? (
              <Button
                variant="text"
                color="primary"
                size="large"
                sx={{ mr: 2 }}
                onClick={() => onActivateAction(ActionTypes.Retry, row)}
              >
                Retry
              </Button>
            ) : (
              ""
            );
          },
          sortable: false,
        },
        {
          field: "preview-action",
          headerName: "",
          width: 72,
          renderCell: ({ row }: { row: PolicyDocumentPublishLogRecord }) => (
            <Tooltip
              title={
                row.publishStatus !== PolicyDocumentPublishStatuses.Published
                  ? "View last published document"
                  : ""
              }
            >
              <IconButton
                onClick={() => onActivateAction(ActionTypes.Preview, row)}
              >
                <VisibilityIcon sx={{ color: "text.secondary" }} />
              </IconButton>
            </Tooltip>
          ),
          sortable: false,
        },
      ],
      [onActivateAction],
    );

    return policy.id ? (
      <>
        <List
          actions={<PolicyPublishLogsListActions policyId={policy.id} />}
          dataUrl={listActionUrls[ListTypes.policyPublishLogs].replace(
            ":id",
            policy.id.toString(),
          )}
          columns={columns}
          defaultOrderBy="dateTime"
          defaultOrderDirection="desc"
          filterQueryKey={filterQueryKey}
          name="logs"
          hidePaginationForMinRows={true}
          stickyHeader={true}
          type={ListTypes.policyPublishLogs}
        />
        {activePublishLog &&
          (activeActionType === ActionTypes.Retry ? (
            <ConfirmationDialog
              confirmText="Retry"
              handleClose={onClearAction}
              handleConfirm={onRetry}
              open={true}
              message={`This will publish the policy for ${activePublishLog.facilityName}.`}
              messageContent={
                <DialogCheckboxContainerStyled>
                  <CheckboxInput
                    checked={notifyFacility}
                    label="Notify facility"
                    name="noitfyFacility"
                    onChange={(_, val) => setNotifyFacility(val)}
                  />
                </DialogCheckboxContainerStyled>
              }
              title="Retry publish?"
              type={ConfirmationDialogTypes.Warning}
            />
          ) : (
            activeActionType === ActionTypes.Preview && (
              <PolicyPreviewDialog
                handleClose={onClearAction}
                policyId={activePublishLog.policyID}
                facilityId={activePublishLog.facilityID}
              />
            )
          ))}
      </>
    ) : null;
  },
);
