import React, { useCallback, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  adminActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import {
  FullWidthFormGridItemStyled,
  SelectInput,
} from "../../../../../components";
import { FacilityStatuses } from "../../../../../lib/constants";
import { exportAuditsData } from "./AuditsExportHelpers";

interface AuditsExportDialogProps {
  handleClose: () => void;
}

export const AuditsExportDialog = React.memo(
  /**
   *
   */
  function AuditsExportDialog({ handleClose }: AuditsExportDialogProps) {
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState({
      groupIDs: [] as number[],
      regionIDs: [] as number[],
      facilityIDs: [] as number[],
    });

    const auditTemplates = useSelector(sysSelectors.allAuditTemplates);

    const groupOptions = useSelector(sysSelectors.allGroups);
    const regions = useSelector(sysSelectors.allRegions);
    const facilities = useSelector(sysSelectors.allFacilities);

    const regionOptions = useMemo(
      () =>
        !filters.groupIDs.length
          ? regions
          : regions.filter((r) => filters.groupIDs.includes(r.groupID)),
      [filters.groupIDs, regions],
    );

    const facilityOptions = useMemo(() => {
      // pending and review facilities are excluded from the audits export
      const relevantFacilities = facilities.filter(
        (f) =>
          f.status !== FacilityStatuses.Pending &&
          f.status !== FacilityStatuses.Review,
      );
      return filters.regionIDs.length
        ? relevantFacilities.filter(
            (f) => f.regionID && filters.regionIDs.includes(f.regionID),
          )
        : filters.groupIDs.length
        ? relevantFacilities.filter((f) => filters.groupIDs.includes(f.groupID))
        : relevantFacilities;
    }, [facilities, filters.groupIDs, filters.regionIDs]);

    const onChangeGroups = useCallback(
      (_, value) => {
        const didRemoveGroups = value?.length < filters.groupIDs.length;

        const updatedFilters = { ...filters, groupIDs: value };

        if (didRemoveGroups) {
          //clear out selected regions and facilities that are no longer part of the group selection
          if (filters.regionIDs.length) {
            const availableRegionIds = regionOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            updatedFilters.regionIDs = updatedFilters.regionIDs.filter((i) =>
              availableRegionIds.includes(i),
            );
          }
          if (filters.facilityIDs.length) {
            const availableFacilityIds = facilityOptions
              .filter((o) => value.includes(o.groupID))
              .map((o) => o.id);
            updatedFilters.facilityIDs = updatedFilters.facilityIDs.filter(
              (i) => availableFacilityIds.includes(i),
            );
          }
        }

        setFilters(updatedFilters);
      },
      [facilityOptions, filters, regionOptions],
    );

    const onChangeRegions = useCallback(
      (_, value) => {
        const didRemoveRegions = value?.length < filters.regionIDs.length;

        const updatedFilters = { ...filters, regionIDs: value };

        if (didRemoveRegions) {
          //clear out selected facilities that are no longer part of the region selection
          if (filters.facilityIDs.length) {
            const availableFacilityIds = facilityOptions
              .filter((o) => value.includes(o.regionID))
              .map((o) => o.id);
            updatedFilters.facilityIDs = updatedFilters.facilityIDs.filter(
              (i) => availableFacilityIds.includes(i),
            );
          }
        }

        setFilters(updatedFilters);
      },
      [facilityOptions, filters],
    );

    const onChangeFacilities = useCallback((_, value) => {
      setFilters((filters) => ({ ...filters, facilityIDs: value }));
    }, []);

    const handleSubmit = useCallback(async () => {
      const data = await dispatch(adminActions.getAuditsExport(filters));

      if (data) {
        await exportAuditsData(auditTemplates, data);
        handleClose();
      }
    }, [auditTemplates, dispatch, filters, handleClose]);

    return (
      <Dialog open={true}>
        <DialogTitle variant="h6">
          Configure report
          <Typography variant="body2" mt={2}>
            Configure which audits you'd like to show on your report
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container paddingTop="16px">
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="groupIDs"
                label="Select groups"
                options={groupOptions}
                onChange={onChangeGroups}
                selectAll={true}
                value={filters.groupIDs}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="regionIDs"
                label="Select regions"
                options={regionOptions}
                onChange={onChangeRegions}
                selectAll={true}
                value={filters.regionIDs}
              />
            </FullWidthFormGridItemStyled>
            <FullWidthFormGridItemStyled item>
              <SelectInput
                multiple={true}
                name="facilityIDs"
                label="Select facilities"
                options={facilityOptions}
                onChange={onChangeFacilities}
                selectAll={true}
                value={filters.facilityIDs}
              />
            </FullWidthFormGridItemStyled>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !filters.groupIDs.length &&
              !filters.regionIDs.length &&
              !filters.facilityIDs.length
            }
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
