import { AppThunk } from "..";
import { splitCamelCase } from "../../lib";
import { authClient, uiActions } from "../states";
import { initialState, ListParams, lists, ListTypes } from "./state";

const { actions } = lists;

export const listActionUrls = {
  [ListTypes.announcements]: "/announcements",
  [ListTypes.audits]: "/audits",
  [ListTypes.auditActivity]: "/audits/:id/activity",
  [ListTypes.auditDashboard]: "/audits/dashboardView",
  [ListTypes.auditInbox]: "/audits/inboxView",
  [ListTypes.auditTemplates]: "/audits/templates",
  [ListTypes.facilities]: "/facilities",
  [ListTypes.facilityActivity]: "/facilities/:id/activity",
  [ListTypes.facilityFiles]: "/facilityFiles",
  [ListTypes.facilityNotes]: "facilities/:id/notes",
  [ListTypes.groups]: "/groups",
  [ListTypes.policies]: "/policies",
  [ListTypes.policyVersions]: "/policies/:id/versions",
  [ListTypes.policyPublishLogs]: "/policies/:id/policyDocs/publishLogs",
  [ListTypes.regions]: "/regions",
  [ListTypes.relatedFacilities]: "/facilities",
  [ListTypes.relatedRegions]: "/regions",
  [ListTypes.relatedUsers]: "/users",
  [ListTypes.requiredDocumentResources]: "/resources?type=RequiredDocument",
  [ListTypes.requiredDocumentSubmissions]: "/resources/requiredDocuments",
  [ListTypes.resources]: "/resources",
  [ListTypes.search]: "/search",
  [ListTypes.toolResources]: "/resources?type=Tool",
  [ListTypes.trainings]: "/trainings",
  [ListTypes.trainingPackets]: "/trainings/packets",
  [ListTypes.trainingAttendance]: "/trainings/:id/attendance",
  [ListTypes.users]: "/users",
  [ListTypes.userPositions]: "/users/positions",
  [ListTypes.userRoles]: "/users/roles",
};

export const listsActions = {
  ...actions,
  getList(
    type: string,
    params: ListParams,
    url: string = "",
    resultsModifier?: (results: any[]) => any[],
  ): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      url = url || listActionUrls[type];
      const { data, status } = await authClient.get(url, {
        params,
      });

      if (resultsModifier && data?.results) {
        data.results = resultsModifier(data.results);
      }

      if (status !== 200) {
        dispatch(
          uiActions.showError(`Failed to retrieve ${splitCamelCase(type)}`),
        );
      } else {
        const listUpdate = {
          type,
          data,
          params,
          url,
        };
        dispatch(actions.populateList(listUpdate));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  refreshList(
    type: string,
    resultsModifier?: (results: any[]) => any[],
  ): AppThunk {
    return async (dispatch, getState) => {
      //retrieve previous request params
      const { params, url } = getState().lists[type];

      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(url, {
        params,
      });

      if (resultsModifier && data?.results) {
        data.results = resultsModifier(data.results);
      }

      if (status !== 200) {
        dispatch(
          uiActions.showError(`Failed to retrieve ${splitCamelCase(type)}`),
        );
      } else {
        const listUpdate = {
          type,
          data,
          params,
          url,
        };
        dispatch(actions.populateList(listUpdate));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  clearList(type: string): AppThunk {
    return async (dispatch) => {
      const listUpdate = initialState[type];
      dispatch(actions.populateList(listUpdate));
    };
  },
};
