import React from "react";
import { PositionsList, RolesList } from "./components";
import { TabbedView } from "../../components";
import { UsersList } from "../adminShared/components";

const tabs = [
  { id: "users", name: "Users", component: UsersList },
  { id: "access-levels", name: "Access levels", component: RolesList },
  { id: "positions", name: "Positions", component: PositionsList },
];

export const UsersPage = React.memo(
  /**
   *
   */
  function UsersPage() {
    return <TabbedView tabs={tabs} />;
  },
);
