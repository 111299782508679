import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  PersonIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../../../../components";
import {
  facilityAdminActions,
  FacilityStaffLogin,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { styled } from "@mui/material/styles";

export const BoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "2px 16px",
  "& svg": {
    color: theme.palette.text.primary,
    height: "20px",
  },
}));

export const FacilityStaffLoginDetails = React.memo(
  /**
   *
   */
  function FacilityStaffLoginDetails() {
    const dispatch = useAppDispatch();
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const [showPassword, setShowPassword] = useState(false);

    const [loginDetails, setLoginDetails] = useState<FacilityStaffLogin>();

    useEffect(() => {
      (async () => {
        if (facilityFilter?.id) {
          const staffLogin = await dispatch(
            facilityAdminActions.getFacilityStaffLogin(facilityFilter.id),
          );
          if (staffLogin) {
            setLoginDetails(staffLogin);
          }
        } else if (loginDetails) {
          setLoginDetails(undefined);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityFilter?.id]);

    return loginDetails?.staffUserName || loginDetails?.staffPassword ? (
      <BoxStyled>
        <Typography variant="subtitle2" mr={2}>
          Staff login
        </Typography>
        <Box display="flex" alignItems="center" mr={2}>
          <PersonIcon />
          <Typography variant="body2" ml={2}>
            {loginDetails.staffUserName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mr={2}>
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          <Typography variant="body2" ml={2}>
            {showPassword ? loginDetails.staffPassword : "••••••••"}
          </Typography>
        </Box>
      </BoxStyled>
    ) : (
      <Box />
    );
  },
);
