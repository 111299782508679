import React, { useCallback } from "react";
import { Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { ArrowDropDownIcon, ArrowDropUpIcon } from "../icons";
import { Link } from "react-router-dom";

interface ButtonMenuItem {
  disabled?: boolean;
  label: string | JSX.Element;
  linkTo?: string;
  onClick?: () => void;
}

interface ButtonMenuProps {
  button?: JSX.Element;
  buttonSize?: "small" | "medium" | "large";
  buttonSx?: SxProps<Theme>;
  buttonText?: string;
  buttonVariant?: "text" | "contained" | "outlined";
  disabled?: boolean;
  menuItems: ButtonMenuItem[];
}

export const ButtonMenu = React.memo(
  /**
   *
   */
  function ButtonMenu({
    button,
    disabled = false,
    buttonSize: buttonSize = "large",
    buttonSx: buttonSx,
    buttonText,
    buttonVariant,
    menuItems,
  }: ButtonMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleMenuItemClick = useCallback(
      (item: ButtonMenuItem) => {
        if (item.onClick) {
          item.onClick();
        }
        handleClose();
      },
      [handleClose],
    );

    const MenuIcon = menuOpen ? ArrowDropUpIcon : ArrowDropDownIcon;

    return (
      <>
        <div
          aria-controls="button-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {button || (
            <Button
              color="primary"
              disabled={disabled}
              size={buttonSize}
              sx={buttonSx}
              variant={buttonVariant || "contained"}
            >
              {buttonText} <MenuIcon sx={{ ml: 1 }} />
            </Button>
          )}
        </div>
        <Menu
          id="button-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              minWidth: anchorEl && anchorEl.offsetWidth,
              width: "fit-content",
            },
          }}
        >
          {menuItems.map((item, index) =>
            item.linkTo ? (
              <MenuItem
                disabled={item.disabled}
                component={Link}
                key={index}
                onClick={() => handleMenuItemClick(item)}
                to={item.linkTo}
              >
                {item.label}
              </MenuItem>
            ) : (
              <MenuItem
                disabled={item.disabled}
                key={index}
                onClick={() => handleMenuItemClick(item)}
              >
                {item.label}
              </MenuItem>
            ),
          )}
        </Menu>
      </>
    );
  },
);
