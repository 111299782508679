import { Button, Typography } from "@mui/material";
import React from "react";
import { AdminPages } from "../../..";
import { Navigation } from "../../../../../lib";
import { EntityFilter } from "../../../../../lib/types";
import { ListTypes } from "../../../../../state/lists/state";
import { FacilitiesList } from "../lists/FacilitiesList";

interface FacilitiesSublistProps {
  filter: EntityFilter;
}

export const FacilitiesSublist = React.memo(
  /**
   *
   */
  function FacilitiesSublist({ filter }: FacilitiesSublistProps) {
    return (
      <FacilitiesList
        filter={filter}
        listType={ListTypes.relatedFacilities}
        noRowsDisplay={
          <>
            <Typography variant="subtitle2">
              There are no facilities for this{" "}
              {filter.regionId ? "region" : "group"}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                Navigation.go(AdminPages.addFacility.path, {
                  query: {
                    groupId: filter.groupId,
                    regionId: filter.regionId,
                  },
                });
              }}
              sx={{ mt: 2, zIndex: 1 }}
            >
              Add Facility
            </Button>
          </>
        }
      />
    );
  },
);
