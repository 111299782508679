import React from "react";
import { PageRootStyled } from "../../components";
import { UserForm } from "./components";
import { styled } from "@mui/material/styles";

export const RootStyled = styled(PageRootStyled)(() => ({
  "& form": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const UserFormPage = React.memo(
  /**
   *
   */
  function UserFormPage() {
    return (
      <RootStyled>
        <UserForm />
      </RootStyled>
    );
  },
);
