import React from "react";
import { RequiredDocumentsList } from "./components";

export const RequiredDocumentsPage = React.memo(
  /**
   *
   */
  function RequiredDocumentsPage() {
    return <RequiredDocumentsList />;
  },
);
