import React from "react";
import { EntityTypes } from "../../lib/constants";
import { GroupFacilityRegionWizard } from "./components";

export const FacilityFormPage = React.memo(
  /**
   *
   */
  function FacilityFormPage() {
    return <GroupFacilityRegionWizard type={EntityTypes.Facility} />;
  },
);
