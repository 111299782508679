import React, { useCallback, useMemo } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { ButtonMenu } from "../../../../../components";
import { Navigation } from "../../../../../lib";
import { AdminPages } from "../../..";
import { EntityFilter } from "../../../../../lib/types";
import { EntityTypes } from "../../../../../lib/constants";

interface AddGroupFacilityRegionMenuProps {
  filter?: EntityFilter;
  sx?: SxProps<Theme>;
}

export const AddGroupFacilityRegionMenu = React.memo(
  /**
   *
   */
  function AddGroupFacilityRegionMenu({
    filter,
    sx,
  }: AddGroupFacilityRegionMenuProps) {
    const addOptions = useMemo(
      () => [
        {
          type: EntityTypes.Group,
          url: AdminPages.addGroup.path,
        },
        {
          type: EntityTypes.Region,
          url: AdminPages.addRegion.path,
        },
        {
          type: EntityTypes.Facility,
          url: AdminPages.addFacility.path,
        },
      ],
      [],
    );

    const onClick = useCallback(
      ({ type, url }) => {
        const query: any = {};

        //when adding a sub-entity from a filtered list, apply the current entity filter
        if (filter) {
          switch (type) {
            case EntityTypes.Facility:
              query.groupId = filter.groupId;
              query.regionId = filter.regionId;
              break;
            case EntityTypes.Region:
              query.groupId = filter.groupId;
              break;
          }
        }

        Navigation.go(url, { query });
      },
      [filter],
    );

    return (
      <Box sx={sx}>
        <ButtonMenu
          buttonText="Add"
          menuItems={addOptions.map((menuItem) => ({
            label: menuItem.type,
            onClick: () => onClick(menuItem),
          }))}
        />
      </Box>
    );
  },
);
