import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowIdGetter,
  GridRowParams,
  GridRowSelectionModel,
  GridSortDirection,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { listsSelectors, uiSelectors, useSelector } from "../../state";
import { ListState } from "../../state/lists/state";
import { ListActionsWrapper } from "./ListActions";
import {
  ListPaginationProps,
  pageSizeOptions,
  useListPagination,
} from "./PaginationHelpers";
import {
  clickableRowClassName,
  DynamicRowHeightListContainerStyled,
  ListContainerStyled,
  NoRowsOverlayStyled,
  scrollArrowsClassName,
} from "./List.styles";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "../icons";
import logo from "../../assets/img/logo_blue.svg";

interface ListProps extends ListPaginationProps {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  dynamicRowHeight?: boolean;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  getRowId?: GridRowIdGetter<any>;
  hideHeader?: boolean;
  hidePaginationForMinRows?: boolean;
  hideRowsPerPage?: boolean;
  hideSelectAll?: boolean;
  horizontalScrollArrows?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  name?: string;
  noRowsDisplay?: string | JSX.Element;
  onCheckboxSelection?: (rows: any[]) => void;
  onRowClick?: (row: any) => void;
  onRowHover?: (e: any) => void;
  onRowLeave?: (e: any) => void;
  pinnedColumns?: GridPinnedColumns;
  rowHeight?: number;
  rowNumbers?: boolean;
  selection?: any[];
  stickyHeader?: boolean;
}

// sortingOrder options for dates with 'desc' first to default field sort to desc
export const dateColumnSortingOrder = [
  "desc",
  "asc",
  null,
  undefined,
] as GridSortDirection[];

export const List = ({
  actions,
  checkboxSelection,
  columns,
  dataModifier,
  dataUrl,
  defaultOrderBy,
  defaultOrderDirection,
  defaultResultsPerPage,
  dynamicRowHeight,
  filter,
  filterQueryKey,
  getRowClassName,
  getRowHeight,
  getRowId,
  hideHeader,
  hidePaginationForMinRows,
  hideRowsPerPage,
  hideSelectAll,
  horizontalScrollArrows,
  isRowSelectable,
  name,
  noRowsDisplay,
  onCheckboxSelection,
  onRowClick,
  onRowHover,
  onRowLeave,
  pinnedColumns,
  paginationQueryKeyPrefix,
  rowHeight,
  rowNumbers,
  selection,
  stickyHeader,
  type,
  useQuery = true,
}: ListProps) => {
  const { listParams, onPageChange, onPageSizeChange, onSortChange } =
    useListPagination({
      dataModifier,
      dataUrl,
      defaultOrderBy:
        // if no defaultOrderBy is provided, default to the first sortable column
        defaultOrderBy || columns.filter((c) => c.sortable !== false)[0]?.field,
      defaultOrderDirection,
      defaultResultsPerPage,
      filter,
      filterQueryKey,
      paginationQueryKeyPrefix,
      type,
      useQuery,
    });

  const loading = useSelector(uiSelectors.loading);

  const listState: ListState<any> = useSelector(listsSelectors[type]);

  const listResults = useMemo(
    () =>
      rowNumbers
        ? listState.data.results.map((r, i) => ({ ...r, rowNumber: i + 1 }))
        : listState.data.results,
    [listState.data.results, rowNumbers],
  );
  const noResults = !(listState.data.numberOfRows > 0);

  const listColumns = useMemo(
    () =>
      rowNumbers
        ? [
            {
              field: "rowNumber",
              headerName: "",
              width: 40,
              sortable: false,
            },
            ...columns,
          ]
        : columns,
    [columns, rowNumbers],
  );

  // #region horizontal scroll arrows
  const apiRef = useGridApiRef();

  const [scrollArrows, setScrollArrows] = useState({
    left: false,
    right: true,
  });

  useEffect(() => {
    if (horizontalScrollArrows && apiRef.current) {
      const handleScrollPositionChange = ({ left }, e) => {
        if (e?.target) {
          const scrollTarget = e.target as HTMLElement;

          setScrollArrows({
            left: left > 0,
            right: left < scrollTarget.scrollWidth - scrollTarget.offsetWidth,
          });
        }
      };

      return apiRef.current.subscribeEvent(
        "scrollPositionChange",
        handleScrollPositionChange,
      );
    }
  }, [apiRef, horizontalScrollArrows]);

  const updateHorizontalScroll = useCallback(
    (scrollRight = true) => {
      const scrollPosition = apiRef.current.getScrollPosition();
      scrollPosition.left = scrollRight
        ? scrollPosition.left + 200
        : scrollPosition.left - 200;

      apiRef.current.scroll(scrollPosition);
    },
    [apiRef],
  );
  // #endregion

  const onPaginationModelChange = useCallback(
    (paginationModelUpdate: GridPaginationModel) => {
      if (paginationModelUpdate.page !== Number(listParams.page) - 1) {
        // pagination model page is 0-based
        onPageChange(paginationModelUpdate.page);
      }
      if (paginationModelUpdate.pageSize !== listParams.resultsPerPage) {
        onPageSizeChange(paginationModelUpdate.pageSize);
      }
    },
    [
      onPageChange,
      onPageSizeChange,
      listParams.page,
      listParams.resultsPerPage,
    ],
  );

  const onSortModelChange = useCallback(
    (sortModelUpdate: GridSortModel) => {
      onSortChange(sortModelUpdate[0]?.sort, sortModelUpdate[0]?.field);
    },
    [onSortChange],
  );

  const onSelectionModelChange = useCallback(
    (selectionModelUpdate: GridRowSelectionModel) => {
      if (onCheckboxSelection) {
        const preservedSelection = (selection || []).filter((s) =>
          selectionModelUpdate.includes(s.id),
        );
        const newSelection = listState.data.results.filter(
          (r) =>
            !preservedSelection.some((s) => s.id === r.id) &&
            selectionModelUpdate.includes(r.id),
        );
        onCheckboxSelection([...preservedSelection, ...newSelection]);
      }
    },
    [listState.data.results, onCheckboxSelection, selection],
  );

  const paginationModel: GridPaginationModel = useMemo(
    () => ({
      page: Number(listParams.page) - 1, // pagination model page is 0-based
      pageSize: Number(listParams.resultsPerPage),
    }),
    [listParams.page, listParams.resultsPerPage],
  );

  const sortModel: GridSortModel | undefined = useMemo(
    () =>
      listParams.orderBy
        ? [
            {
              field: listParams.orderBy.toString(),
              sort: (listParams.order === "desc"
                ? "desc"
                : "asc") as GridSortDirection,
            },
          ]
        : undefined,
    [listParams.order, listParams.orderBy],
  );

  const facilityHeaderShown = useSelector(uiSelectors.showFacilityHeader);

  const StyledContainer = dynamicRowHeight
    ? DynamicRowHeightListContainerStyled
    : ListContainerStyled;

  return (
    <StyledContainer
      hideHeader={hideHeader}
      hideSelectAll={hideSelectAll}
      noResults={noResults}
      stickyHeader={stickyHeader}
      withPageHeader={facilityHeaderShown}
    >
      {actions && (
        <ListActionsWrapper queryKeyPrefix={paginationQueryKeyPrefix}>
          {actions}
        </ListActionsWrapper>
      )}
      {horizontalScrollArrows && (
        <Box className={scrollArrowsClassName}>
          <IconButton
            disabled={!scrollArrows.left}
            onClick={() => updateHorizontalScroll(false)}
          >
            {scrollArrows.left && <ArrowLeftIcon />}
          </IconButton>
          <IconButton
            disabled={!scrollArrows.right}
            onClick={() => updateHorizontalScroll()}
          >
            {scrollArrows.right && <ArrowRightIcon />}
          </IconButton>
        </Box>
      )}

      <DataGridPro
        apiRef={apiRef}
        autoHeight={!dynamicRowHeight && !noResults}
        checkboxSelection={checkboxSelection}
        columns={listColumns}
        columnHeaderHeight={hideHeader ? 0 : 56} //default header height
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableRowSelectionOnClick={true}
        getRowClassName={getRowClassName}
        getRowHeight={getRowHeight}
        getRowId={getRowId}
        hideFooter={
          noResults ||
          (hidePaginationForMinRows &&
            listState.data.numberOfRows <=
              (defaultResultsPerPage || pageSizeOptions[0]))
        }
        isRowSelectable={isRowSelectable}
        keepNonExistentRowsSelected={true}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={onRowClick}
        onRowSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={hideRowsPerPage ? [] : pageSizeOptions}
        pinnedColumns={pinnedColumns}
        rowCount={listState.data.numberOfRows}
        rows={listResults}
        rowHeight={rowHeight || 52} // default row height
        rowSelectionModel={selection?.map((s) => s.id)}
        slots={{
          noRowsOverlay: () =>
            loading ? null : (
              <NoRowsOverlayStyled>
                <img alt="logo" src={logo} height="80px" />
                <Box sx={{ mt: 4 }}>
                  {noRowsDisplay || (
                    <Typography variant="subtitle2">
                      No {name || "results"} found
                    </Typography>
                  )}
                </Box>
              </NoRowsOverlayStyled>
            ),
        }}
        slotProps={{
          row: {
            onMouseEnter: onRowHover,
            onMouseLeave: onRowLeave,
            className: onRowClick ? clickableRowClassName : "",
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
      />
    </StyledContainer>
  );
};
