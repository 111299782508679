import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ErrorIcon, TabbedView } from "../../components";
import { usePageTitle } from "../../lib";
import { PolicyStatuses } from "../../lib/constants";
import { adminActions, Policy, useAppDispatch } from "../../state";
import {
  PolicyPublishLogs,
  PolicySettings,
  PolicyVersions,
} from "./components";
import { defaultTheme } from "../../themes";

const newPolicy = {
  name: "",
  number: "",
  categoryID: null,
  internalNote: "",
  facilityType: "",
  facilityIDs: [],
  stateIDs: [],
  status: PolicyStatuses.Draft,
};

export interface PolicyTabProps {
  policy: Policy;
  refreshPolicy: () => void;
  updatePolicy: (policy: Policy) => void;
}

export const PolicyPage = React.memo(
  /**
   *
   */
  function PolicyPage() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const setPageTitle = usePageTitle();

    const [policy, setPolicy] = useState<Policy>();

    const onSetPolicy = useCallback(
      (policy: Policy) => {
        setPolicy(policy);
        setPageTitle({
          title: `Edit ${policy.title}`,
          status: policy.status,
          statusColor:
            policy.status === PolicyStatuses.Active
              ? defaultTheme.palette.primary.light
              : defaultTheme.palette.neutral.main,
        });
      },
      [setPageTitle],
    );

    const fetchPolicy = useCallback(async () => {
      const policy = await dispatch(adminActions.getPolicy(Number(params.id)));
      if (policy) {
        onSetPolicy(policy);
      }
    }, [dispatch, onSetPolicy, params.id]);

    useEffect(() => {
      (async () => {
        if (params.id) {
          fetchPolicy();
        } else {
          setPolicy(newPolicy);
        }
      })();
    }, [fetchPolicy, onSetPolicy, params.id]);

    const tabs = useMemo(
      () => [
        { id: "general", name: "General", component: PolicySettings },
        { id: "versions", name: "Versions", component: PolicyVersions },
        {
          id: "logs",
          name: policy?.hasPublishFailedDocs ? (
            <Box display="flex" alignItems="center">
              Publish log <ErrorIcon sx={{ ml: 1, color: "error.dark" }} />
            </Box>
          ) : (
            "Publish log"
          ),
          component: PolicyPublishLogs,
        },
      ],
      [policy?.hasPublishFailedDocs],
    );

    return policy ? (
      <TabbedView
        disableTab={(tabId) => !params.id && tabId !== tabs[0].id}
        tabs={tabs}
        viewProps={
          {
            policy,
            refreshPolicy: fetchPolicy,
            updatePolicy: onSetPolicy,
          } as PolicyTabProps
        }
      />
    ) : null;
  },
);
