import React from "react";
import { Field, FieldAttributes, useField } from "formik";
import { RadioGroup } from "formik-mui";
import {
  Box,
  BoxProps,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Option } from "../../lib/types";

interface RadioGroupFieldContainerStyledProps extends BoxProps {
  error?: string;
}

export const RadioGroupFieldContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})<RadioGroupFieldContainerStyledProps>(({ theme, error }) =>
  error
    ? {
        "& p, label": {
          color: theme.palette.error.main,
        },
      }
    : {},
);

interface RadioGroupFieldOption extends Option {
  display?: JSX.Element | string;
}

interface RadioGroupFieldProps extends FieldAttributes<any> {
  options: RadioGroupFieldOption[];
}

export const RadioGroupField = React.memo(
  /**
   *
   */
  function RadioGroupField({
    disabled,
    label,
    name,
    options,
    ...rest
  }: RadioGroupFieldProps) {
    const [, meta] = useField(name);

    const error = meta.touched ? meta.error : "";

    return (
      <RadioGroupFieldContainerStyled error={error}>
        <Field component={RadioGroup} label={label} name={name} {...rest}>
          {options.map((option) => (
            <FormControlLabel
              control={<Radio />}
              disabled={disabled}
              key={option.id}
              label={option.display || option.name}
              value={option.id}
            />
          ))}
        </Field>
        {error && <Typography fontSize="12px">{error}</Typography>}
      </RadioGroupFieldContainerStyled>
    );
  },
);
