import { AppThunk, authActions } from "..";
import { pluralizeText } from "../../lib";
import { ListTypes } from "../lists/state";
import { authClient, listsActions, sysActions, uiActions } from "../states";
import {
  Audit,
  AuditComment,
  AuditCommentThread,
  AuditCorrectionDetails,
  AuditCorrectiveAction,
  AuditCorrectiveActionPlan,
  AuditVersion,
  BasicResource,
  FacilityFile,
  FacilityFileCategory,
  FacilityFileResponse,
  RequiredDocument,
  RequiredDocumentComment,
  RequiredDocumentExportRecord,
  User,
  UserProfile,
} from "../types";
import { shared } from "./state";
import { toFormData } from "axios";

const { actions } = shared;

export const sharedActions = {
  ...actions,
  // profile
  getProfile(): AppThunk<Promise<UserProfile | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/profile`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve profile"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitProfile(values: UserProfile): AppThunk<Promise<UserProfile | null>> {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("users/profile", values);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Profile update failed",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Profile updated"));
        // update auth state with updated profile properties
        dispatch(
          authActions.setAuthState({
            ...getState().auth,
            firstName: data.firstName,
            lastName: data.lastName,
            userName: data.email,
          }),
        );
        return data;
      }
    };
  },
  // users
  getUser(id: number): AppThunk<Promise<User | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve user"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitUser(
    values: User,
    sendWelcomeEmail?: boolean,
  ): AppThunk<Promise<User | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/users`, values, {
        params: { sendWelcomeEmail: sendWelcomeEmail ? undefined : false }, // default is true, specify param only sendWelcomeEmail is false
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save user",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("User saved"));
        return data;
      }
    };
  },
  deactivateUsers(ids: number[]): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/users/deactivate`, ids);
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to deactivate users",
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            `${pluralizeText("User", ids.length)} deactivated`,
          ),
        );
        dispatch(listsActions.refreshList(ListTypes.users));
        return true;
      }
    };
  },
  sendUserResetPasswordEmails(
    ids: number[],
    emailDescription = "Reset password",
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/users/sendResetPwEmail`,
        ids,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              `Failed to send ${emailDescription.toLowerCase()} ${pluralizeText(
                "email",
                ids.length,
              )}`,
          ),
        );
        return false;
      } else {
        dispatch(
          uiActions.showSuccess(
            `${emailDescription} ${pluralizeText("email", ids.length)} sent`,
          ),
        );
        dispatch(listsActions.refreshList(ListTypes.users));
        return true;
      }
    };
  },
  getUserByEmail(params: {
    email: string;
    facilityUserLevel: string;
  }): AppThunk<Promise<User | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/users/byEmail`, {
        params,
      });
      if (status !== 200 && status !== 204) {
        dispatch(uiActions.showError("Failed to retrieve user"));
        return null;
      } else {
        return data;
      }
    };
  },
  // audits
  getAudit(id: number): AppThunk<Promise<Audit | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/audits/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit"));
        return null;
      } else {
        return data;
      }
    };
  },
  getAuditCorrectionDetails(
    auditId: number,
  ): AppThunk<Promise<AuditCorrectionDetails | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/audits/${auditId}/correctionDetails`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit details"));
        return null;
      } else {
        return data;
      }
    };
  },
  getAuditVersion(
    id: number,
    versionId: number,
  ): AppThunk<Promise<AuditVersion | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/audits/${id}/versions/${versionId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit version"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditVersion(
    auditId: number,
    values: AuditVersion,
  ): AppThunk<Promise<AuditVersion | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/${auditId}/versions`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save audit",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Audit saved"));
        return data;
      }
    };
  },
  getAuditComments(
    auditId: number,
    params: { includeResolved: boolean; type: string },
  ): AppThunk<Promise<AuditCommentThread[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/audits/${auditId}/comments`,
        { params },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve audit comments"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditComment(
    auditId: number,
    values: AuditComment,
  ): AppThunk<Promise<AuditComment | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/${auditId}/comments`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save comment",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Comment saved"));
        return data;
      }
    };
  },
  deleteAuditComment(auditId: number, id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/audits/${auditId}/comments/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete comment",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Comment deleted"));
        return true;
      }
    };
  },
  getAuditCorrectiveActionPlan(
    id: number,
  ): AppThunk<Promise<AuditCorrectiveActionPlan | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/audits/${id}/correctiveActionPlan`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            "Failed to retrieve audit corrective action plan",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  submitAuditCorrectiveAction(
    auditId: number,
    values: AuditCorrectiveAction,
  ): AppThunk<Promise<AuditCorrectiveAction[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/audits/${auditId}/correctiveActions`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save corrective action",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Corrective action saved"));
        return data;
      }
    };
  },
  // facility files
  getFacilityFile(id: number): AppThunk<Promise<FacilityFile | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/facilityFiles/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve facility file"));
        return null;
      } else {
        return data;
      }
    };
  },
  submitFacilityFile(
    values: FacilityFile,
  ): AppThunk<Promise<FacilityFile | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        "facilityFiles",
        toFormData(values),
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save facility file",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Facility file saved"));
        return data;
      }
    };
  },
  getFacilityFilePreview(id: number): AppThunk<Promise<string | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/facilityFiles/${id}/view?exportPdf=true`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data?.fileData;
      }
    };
  },
  getFacilityFileDownload(
    id: number,
  ): AppThunk<Promise<FacilityFileResponse | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/facilityFiles/${id}/view`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  deleteFacilityFile(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(`/facilityFiles/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete file",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("File deleted"));
        return true;
      }
    };
  },
  getFacilityFileCategories(
    groupId: number,
  ): AppThunk<Promise<FacilityFileCategory[] | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/facilityFiles/categories?groupId=${groupId}`,
      );
      if (status !== 200) {
        dispatch(
          uiActions.showError("Failed to retrieve facility file categories"),
        );
      } else {
        return data;
      }
    };
  },
  submitFacilityFileCategory(
    values: FacilityFileCategory,
  ): AppThunk<Promise<FacilityFileCategory | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/facilityFiles/categories`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save category",
          ),
        );
        return null;
      } else {
        dispatch(uiActions.showSuccess("Category saved"));
        dispatch(sysActions.getFacilityFileCategories(values.groupID));
        return data;
      }
    };
  },
  deleteFacilityFileCategory(
    id: number,
    groupId: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/facilityFiles/categories/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete category",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Category deleted"));
        dispatch(sysActions.getFacilityFileCategories(groupId));
        return true;
      }
    };
  },
  // resources
  getBasicResources(
    params: Partial<{
      name: string;
      type: string;
      requiredFrequency: string;
      includeFacilitiesAndStates: boolean;
    }> = {},
  ): AppThunk<Promise<BasicResource[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/resources/basic`, {
        params,
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200 && status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              (params.name
                ? "Failed to validate resource name"
                : "Failed to retrieve resources"),
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  // required documents
  getRequiredDocumentComments(
    documentId: number,
  ): AppThunk<Promise<RequiredDocumentComment[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/resources/requiredDocuments/${documentId}/comments`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve document comments"));
      } else {
        return data;
      }
    };
  },
  submitRequiredDocumentReview(
    documentId: number,
    commentText?: string,
  ): AppThunk<Promise<RequiredDocument | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/resources/requiredDocuments/${documentId}/review`,
        { commentText },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to submit document review",
          ),
        );
      } else {
        return data;
      }
    };
  },
  submitRequiredDocumentComment(
    documentId: number,
    comment?: RequiredDocumentComment,
  ): AppThunk<Promise<RequiredDocumentComment | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/resources/requiredDocuments/${documentId}/comments`,
        comment,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to submit comment",
          ),
        );
      } else {
        return data;
      }
    };
  },
  deleteRequiredDocumentComment(
    documentId: number,
    commentId: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/resources/requiredDocuments/${documentId}/comments/${commentId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete comment",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Comment deleted"));
        return true;
      }
    };
  },
  getRequiredDocumentsExport(params: {
    quarter: number;
    year: number;
    resourceIDs: number[];
    groupIDs: number[];
    regionIDs: number[];
    facilityIDs: number[];
  }): AppThunk<Promise<RequiredDocumentExportRecord[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        "resources/requiredDocuments/export",
        params,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            "Failed to retrieve required documents for export",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
};
