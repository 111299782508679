import React, { useCallback } from "react";
import { Button, Typography } from "@mui/material";
import {
  Navigation,
  passwordSchema,
  pwLengthValidator,
  pwUpperCharValidator,
  pwLowerCharValidator,
  pwDigitCharValidator,
  pwSpecialCharValidator,
  useLocation,
  yup,
} from "../../lib";
import { authActions, useAppDispatch } from "../../state";
import { PasswordField } from "../../components";
import { defaultTheme } from "../../themes";
import { AuthPages } from ".";
import { Form, Formik } from "formik";
import {
  AuthCardStyled,
  ButtonBoxStyled,
  PasswordCritieriaBoxStyled,
  SubtitleTextContainerStyled,
  TitleTextStyled,
} from "./Auth.styles";

const validationSchema = yup.object({
  password1: passwordSchema(),
  password2: passwordSchema(false).test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password1 === value;
    },
  ),
});

const getPasswordValidationColor = (
  validator: Function,
  { errors, touched, values },
) => {
  const style = {};

  if (!touched.password1) {
    return "";
  }

  if (values.password1 && validator(values.password1)) {
    return defaultTheme.palette.success.dark;
  } else if (errors.password1) {
    return defaultTheme.palette.error.dark;
  }

  return style;
};

export const SetPasswordPage = React.memo(
  /**
   *
   */
  function SetPasswordPage() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const is60DayReset = location.query["60dayReset"];

    const handleSubmit = useCallback(
      async (values) => {
        const success = await dispatch(
          authActions.setPassword({
            newPw: values.password1,
            token: location.query.token as string,
            email: location.query.email as string,
          }),
        );
        if (success) {
          const urlConfig = { query: { "pw-set": true } };
          Navigation.replace(AuthPages.login, urlConfig);
        }
      },
      [dispatch, location.query.email, location.query.token],
    );

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">
          Create{is60DayReset ? " new" : ""} password
        </TitleTextStyled>
        <Formik
          initialValues={{
            password1: "",
            password2: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, ...formProps }) => (
            <>
              <SubtitleTextContainerStyled>
                <Typography variant="body2">
                  {is60DayReset
                    ? "Choose a password. Password must be reset every 60 days."
                    : "It's a good idea to use a strong password that you're not using elsewhere."}
                </Typography>
                <PasswordCritieriaBoxStyled>
                  <Typography variant="body2">
                    Password must contain:
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getPasswordValidationColor(
                      pwLengthValidator,
                      formProps,
                    )}
                  >
                    &#8226; At least 8 characters
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getPasswordValidationColor(
                      pwLowerCharValidator,
                      formProps,
                    )}
                  >
                    &#8226; A lowercase character
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getPasswordValidationColor(
                      pwUpperCharValidator,
                      formProps,
                    )}
                  >
                    &#8226; An uppercase character
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getPasswordValidationColor(
                      pwDigitCharValidator,
                      formProps,
                    )}
                  >
                    &#8226; A number between 0-9
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getPasswordValidationColor(
                      pwSpecialCharValidator,
                      formProps,
                    )}
                  >
                    &#8226; A special character
                  </Typography>
                </PasswordCritieriaBoxStyled>
              </SubtitleTextContainerStyled>
              <Form>
                <PasswordField label="Password" name="password1" />
                <PasswordField label="Re-enter password" name="password2" />
                <ButtonBoxStyled>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save password
                  </Button>
                </ButtonBoxStyled>
              </Form>
            </>
          )}
        </Formik>
      </AuthCardStyled>
    );
  },
);
