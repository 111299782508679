import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabbedView } from "../../components";
import { BasicStatuses, EntityTypes } from "../../lib/constants";
import { adminActions, Region, useAppDispatch } from "../../state";
import {
  FacilitiesSublist,
  GroupFacilityRegionSnapshot,
  UsersSublist,
} from "./components";

const tabs = [
  { id: "facilities", name: "Facilities", component: FacilitiesSublist },
  { id: "users", name: "Users", component: UsersSublist },
];

export const RegionPage = React.memo(
  /**
   *
   */
  function RegionPage() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [region, setRegion] = useState<Region>();

    useEffect(() => {
      (async () => {
        if (params.id) {
          const region = await dispatch(
            adminActions.getRegion(Number(params.id)),
          );
          if (region) {
            setRegion(region);
          }
        }
      })();
    }, [dispatch, params.id]);

    const componentProps = useMemo(
      () => ({
        filter: {
          regionId: params.id,
          groupId: region?.groupID,
          includeInactive: region?.status === BasicStatuses.Inactive,
        },
      }),
      [params.id, region?.groupID, region?.status],
    );

    return region ? (
      <>
        <GroupFacilityRegionSnapshot
          details={region}
          resetDetails={setRegion}
          type={EntityTypes.Region}
        />
        <TabbedView tabs={tabs} viewProps={componentProps} />
      </>
    ) : null;
  },
);
