import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  token?: string;
  expiration?: string;
  permissionClaims?: Array<string>;
  requiresMfa?: boolean;
  userID?: number;
  userName?: string;
  userCategory?: string;
  facilityUserType?: string;
  facilityUserLevel?: string;
  facilityUserPositions?: string[];
  firstName?: string;
  lastName?: string;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
    },
  }),
  persist: true,
};
