import { createSlice } from "@reduxjs/toolkit";

export interface FacilityStaffState {}

const initialState: FacilityStaffState = {};

export const facilityStaff = {
  ...createSlice({
    name: "facilityStaff",
    initialState,
    reducers: {},
  }),
  persist: false,
};
