import { useCallback } from "react";
import { authClient, uiActions, useDispatch } from "../../../state";
import { nanoid } from "nanoid/non-secure";
import axios from "axios";

export const FileUploadTypes = {
  GroupLogo: "GroupLogo",
  RegionLogo: "RegionLogo",
  FacilityLogo: "RegionLogo",
  TrainingMaterial: "TrainingMaterial",
  TrainingAttendance: "TrainingAttendance",
  TrainingPacket: "TrainingPacket",
  AuditTemplate: "AuditTemplate",
  AuditCertificate: "AuditCertificate",
  CompletedAudits: "CompletedAudits",
  RequiredDocuments: "RequiredDocuments",
};

const FileUploadPaths = {
  [FileUploadTypes.GroupLogo]: "group_logos",
  [FileUploadTypes.RegionLogo]: "region_logos",
  [FileUploadTypes.FacilityLogo]: "facility_logos",
  [FileUploadTypes.TrainingMaterial]: "training_materials",
  [FileUploadTypes.TrainingAttendance]: "training_attendance",
  [FileUploadTypes.TrainingPacket]: "training_packets",
  [FileUploadTypes.AuditTemplate]: "audit_templates",
  [FileUploadTypes.AuditCertificate]: "audit_certificates",
  [FileUploadTypes.CompletedAudits]: "completed_audits",
  [FileUploadTypes.RequiredDocuments]: "required_documents",
};

function createUniqueFileName(file) {
  const uuid = nanoid(22);
  const parts = file.name?.split(".") || [];
  let extension = (parts.length > 1 ? parts.pop() : "").trim();
  if (!extension) {
    extension = getFileExtension(file.type);
  }
  return `${uuid}.${extension}`;
}

function getFileExtension(mimeType) {
  switch (mimeType) {
    case "image/bmp":
      return "bmp";
    case "image/gif":
      return "gif";
    case "image/jpeg":
      return "jpeg";
    case "image/jpg":
      return "jpg";
    case "image/png":
      return "png";
    case "image/svg+xml":
      return "svg";
    case "image/tiff":
      return "tiff";
    case "image/x-icon":
      return "ico";
    case "video/mp4":
      return "mp4";
    case "application/pdf":
      return "pdf";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx";
    case "text/csv":
      return "csv";
    case "application/msword":
      return "doc";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    case "application/vnd.adobe.xfdf":
      return "xfdf";
    default:
      return "file";
  }
}

function trimTrailingSlash(path) {
  if (path) {
    const { length } = path;
    if (path.charAt(length - 1) === "/") {
      return path.substr(0, length - 1);
    }
  }
  return path;
}

export function useFileUpload(name = "file") {
  const dispatch = useDispatch();

  const uploadFile = useCallback(
    async (file, fileType, secure = false, destUrl = "") => {
      const filePath = FileUploadPaths[fileType];
      if (!filePath) {
        return;
      }

      dispatch(uiActions.setLoading(true));

      // get signed url
      const { data, status } = await authClient.get(
        `/documents/signedUrl/readWrite?isSecure=${secure}`,
      );

      if (status === 200) {
        const { blobContainerUrl, sas } = data;

        const fileUrl =
          destUrl ||
          `${trimTrailingSlash(
            blobContainerUrl,
          )}/${filePath}/${createUniqueFileName(file)}`;
        const uploadUrl = `${fileUrl}${sas}`;

        // upload file to storage container
        try {
          await axios.put(uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
              "x-ms-blob-type": "BlockBlob",
            },
          });
          dispatch(uiActions.setLoading(false));
          return fileUrl;
        } catch (err) {
          console.error("Error uploading file: ", err);
        }
      }

      dispatch(uiActions.setLoading(false));
      dispatch(uiActions.showError(`Failed to upload ${name}`));
    },
    [dispatch, name],
  );

  return uploadFile;
}
