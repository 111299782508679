import { FieldAttributes, useField } from "formik";
import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "./TextField";

interface NumberFieldProps extends FieldAttributes<any> {
  decimals?: number;
  isNumericString?: boolean;
}

export const NumberField = React.memo(
  /**
   *
   */
  function NumberField({
    decimals = 0,
    isNumericString,
    name,
    ...rest
  }: NumberFieldProps) {
    const [field, , helpers] = useField(name);
    const { onChange: _, ...fieldRest } = field; // discard onChange because NumberFormat change is handled properly via onValueChange
    return (
      <NumberFormat
        customInput={TextField}
        decimalScale={decimals}
        fixedDecimalScale={!!decimals}
        inputMode="numeric"
        isNumericString={isNumericString}
        onValueChange={({ floatValue, value }, { event }) => {
          // setValue only when onValueChange is triggered by an actual change event
          if (event?.type === "change") {
            helpers.setValue((isNumericString ? value : floatValue) || "");
          }
        }}
        thousandSeparator
        {...fieldRest}
        {...rest}
      />
    );
  },
);

/**
 * Note: resolution added to package.json for this issue (after mui package upgrades)
 * https://github.com/s-yadav/react-number-format/issues/630
 * https://stackoverflow.com/questions/71791347/npm-package-cannot-be-used-as-a-jsx-component-type-errors
 */
