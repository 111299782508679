import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "../../../../components";
import {
  BasicPolicy,
  facilityAdminActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import {
  PoliciesMenuContainerStyled,
  PoliciesMenuListStyled,
  PoliciesMenuTitleStyled,
} from "./PoliciesMenu.styles";
import { Navigation } from "../../../../lib";
import { FacilityAdminPages } from "../../../facilityAdmin";

interface PoliciesMenuProps {
  policyId: number;
}

export const PoliciesMenu = React.memo(
  /**
   *
   */
  function PoliciesMenu({ policyId }: PoliciesMenuProps) {
    const dispatch = useAppDispatch();

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const [menuOpen, setMenuOpen] = useState(false);
    const [policies, setPolicies] = useState<BasicPolicy[]>([]);

    useEffect(() => {
      (async () => {
        if (!facilityFilter?.id) {
          return;
        }

        const policies = await dispatch(
          facilityAdminActions.getBasicPolicies({
            facilityId: facilityFilter.id,
            policyId,
          }),
        );
        setPolicies(policies || []);
      })();
    }, [dispatch, facilityFilter?.id, policyId]);

    return policies.length > 1 ? (
      <PoliciesMenuContainerStyled open={menuOpen}>
        <Button onMouseEnter={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </Button>
        <Box
          onMouseLeave={() => setMenuOpen(false)}
          onMouseOver={() => setMenuOpen(true)}
        >
          {menuOpen && (
            <>
              <PoliciesMenuTitleStyled variant="body2">
                {policies[0].categoryName}
              </PoliciesMenuTitleStyled>
              <PoliciesMenuListStyled>
                {policies.map((policy) => {
                  const selected = policy.id === policyId;
                  return (
                    <ListItemButton
                      key={policy.id}
                      onClick={() =>
                        Navigation.replace(FacilityAdminPages.policy, {
                          params: { id: policy.id },
                        })
                      }
                      selected={selected}
                    >
                      <ListItemText>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          mb={1}
                        >
                          {policy.title.replace(policy.name, "")}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={selected ? "primary.main" : ""}
                        >
                          {policy.name}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </PoliciesMenuListStyled>
            </>
          )}
        </Box>
      </PoliciesMenuContainerStyled>
    ) : null;
  },
);
