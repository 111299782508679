import { yup } from "./forms";

// #region password validation and schema
export const pwLengthValidator = (pw: string | null | undefined) =>
  pw && pw.length >= 8;
export const pwUpperCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[A-Z]/g);
export const pwLowerCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[a-z]/g);
export const pwDigitCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[0-9]/g);
export const pwSpecialCharValidator = (pw: string | null | undefined) =>
  pw?.match(/[^a-zA-Z0-9\s]/g);

export const passwordSchema = (fullValidation = true, nullable = false) =>
  (nullable
    ? yup.string().nullable()
    : yup.string().required("Password is required")
  )
    .min(8, "Password should be of minimum 8 characters length")
    .test(
      "pw-upper-char",
      "Password should contain at least one uppercase character",
      (value) => !value || !fullValidation || !!pwUpperCharValidator(value),
    )
    .test(
      "pw-lower-char",
      "Password should contain at least one lowercase character",
      (value) => !value || !fullValidation || !!pwLowerCharValidator(value),
    )
    .test(
      "pw-digit-char",
      "Password should contain at least one digit",
      (value) => !value || !fullValidation || !!pwDigitCharValidator(value),
    )
    .test(
      "pw-digit-char",
      "Password should contain at least one special character",
      (value) => !value || !fullValidation || !!pwSpecialCharValidator(value),
    );

export const phoneSchema = (nullable = true) =>
  (nullable
    ? yup.string().nullable()
    : yup.string().required("Phone is required")
  ).length(10, "Enter a valid phone");
