import React, { useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Tooltip, Typography } from "@mui/material";
import {
  ButtonMenu,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  ErrorOutlineIcon,
  FacilityTypeIconStyled,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  LongTooltip,
  MoreVertIcon,
  SearchInput,
  SelectInput,
} from "../../../../components";
import {
  asArray,
  formatDate,
  Navigation,
  pluralizeText,
  RouterLink,
} from "../../../../lib";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import { FilterListIcon, ListActionsProps } from "../../../../components";
import {
  adminActions,
  PolicyRecord,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { PolicyStatusChipStyled } from "./PoliciesList.styles";
import { AdminPages } from "../..";
import { ListTypes } from "../../../../state/lists/state";
import { ManageCategoriesDialog } from "./categories/ManageCategoriesDialog";

const filterQueryKey = {
  categoryIDs: "categories",
  facilityIDs: "facilities",
  facilityTypes: "facilityTypes",
  publishError: "publishError",
  stateIDs: "states",
  statuses: "statuses",
  text: "search",
};

interface PoliciesListActionsProps extends ListActionsProps {}

function PoliciesListActions({
  onFilterChange = () => {},
  query,
}: PoliciesListActionsProps) {
  const dispatch = useAppDispatch();

  const {
    facilityTypes: facilityTypeOptions = [],
    policyStatuses: policyStatusOptions = [],
    states: stateOptions = [],
  } = useSelector(sysSelectors.systemSettings);
  const facilityOptions = useSelector(sysSelectors.activeFacilities);
  const policyCategoryOptions = useSelector(sysSelectors.policyCategories);

  const {
    categories = [],
    facilities = [],
    facilityTypes = [],
    publishError = false,
    search = "",
    states = [],
    statuses = [],
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(
      search ||
      publishError ||
      asArray(categories).length ||
      asArray(facilities).length ||
      asArray(facilityTypes).length ||
      asArray(states).length ||
      asArray(statuses).length
    ),
  );

  const [showManageCategoriesDialog, setShowManageCategoriesDialog] =
    useState(false);

  const [
    showCancelLongPublishingConfirmationDialog,
    setShowCancelLongPublishingConfirmationDialog,
  ] = useState(false);

  const cancelLongPublishingPolicies = useCallback(async () => {
    const cancelled = await dispatch(
      adminActions.cancelLongPublishingPolicies(),
    );
    if (cancelled) {
      setShowCancelLongPublishingConfirmationDialog(false);
    }
  }, [dispatch]);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled sx={{ pt: 0, justifyContent: "flex-end" }}>
        <Box display="flex">
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setShowManageCategoriesDialog(true)}
          >
            Manage categories
          </Button>
          <Button
            component={RouterLink}
            to={AdminPages.addPolicy.path}
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
          >
            Add new policy
          </Button>
          <ButtonMenu
            button={
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ minWidth: "36px", padding: "10px 0", ml: 1 }}
              >
                <MoreVertIcon sx={{ height: "20px" }} />
              </Button>
            }
            menuItems={[
              {
                label: "Cancel long-publishing policies",
                onClick: () =>
                  setShowCancelLongPublishingConfirmationDialog(true),
              },
            ]}
          />
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search policies"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Category"
            name="categories"
            value={asArray(categories)}
            onChange={onFilterChange}
            options={policyCategoryOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Status"
            name="statuses"
            value={asArray(statuses)}
            onChange={onFilterChange}
            options={policyStatusOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="State"
            name="states"
            value={asArray(states)}
            onChange={onFilterChange}
            options={stateOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility"
            name="facilities"
            value={asArray(facilities)}
            onChange={onFilterChange}
            options={facilityOptions}
            groupOpts={true}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility type"
            name="facilityTypes"
            value={asArray(facilityTypes)}
            onChange={onFilterChange}
            options={facilityTypeOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!publishError}
            label="Publish errors"
            name="publishError"
            onChange={onFilterChange}
          />
        </ListActionsExpandedStyled>
      </Collapse>
      {showManageCategoriesDialog && (
        <ManageCategoriesDialog
          handleClose={() => setShowManageCategoriesDialog(false)}
        />
      )}
      {showCancelLongPublishingConfirmationDialog && (
        <ConfirmationDialog
          cancelText="Continue publishing"
          confirmText="Cancel publishing"
          handleClose={() =>
            setShowCancelLongPublishingConfirmationDialog(false)
          }
          handleConfirm={cancelLongPublishingPolicies}
          message={`The publishing for all policy documents that have stalled publishing for more than 1 hour will be cancelled, and the status will be updated to 'Publish Failed'.\nNote: You'll now be able to bulk republish these documents from each policy version.`}
          open={true}
          title="Cancel publishing policies"
          type={ConfirmationDialogTypes.Alert}
          width="440px"
        />
      )}
    </ListActionsContainerStyled>
  );
}

const PolicyTooltips = ({ policy }: { policy: PolicyRecord }) => {
  const {
    publishFailedDocsCount,
    publishNeededDocsCount,
    publishingVersionsCount,
    unpublishedChangesVersionsCount,
  } = policy;

  const toolTips = [] as { title: string; color: string }[];

  if (publishingVersionsCount) {
    toolTips.push({
      title: "Versions publishing",
      color: "secondary.light",
    });
  }
  if (unpublishedChangesVersionsCount) {
    toolTips.push({
      title: "Unpublished changes",
      color: "warning.light",
    });
  }
  if (publishFailedDocsCount) {
    toolTips.push({
      title: `A publishing error occurred. This policy was not updated for ${publishFailedDocsCount.toLocaleString()} ${pluralizeText(
        "facility",
        publishFailedDocsCount,
        "facilities",
      )}.`,
      color: "error.main",
    });
  }
  if (publishNeededDocsCount) {
    toolTips.push({
      title: `This policy was not published for ${publishNeededDocsCount.toLocaleString()} applicable ${pluralizeText(
        "facility",
        publishNeededDocsCount,
        "facilities",
      )}`,
      color: "error.main",
    });
  }

  return (
    <>
      {toolTips.map((toolTip, index) => (
        <Tooltip
          key={index}
          title={toolTip.title}
          sx={{ ml: 1, color: toolTip.color }}
        >
          <ErrorOutlineIcon fontSize="small" />
        </Tooltip>
      ))}
    </>
  );
};

const columns: GridColDef[] = [
  {
    field: "number",
    headerName: "Policy",
    flex: 2,
    renderCell: ({ row }: { row: PolicyRecord }) => (
      <Box display="flex">
        <Tooltip title={row.facilityTypeDisplay}>
          <FacilityTypeIconStyled type={row.facilityType} />
        </Tooltip>
        <Box ml="10px">
          <Typography variant="body2">{row.title}</Typography>
          <Typography variant="body2" color="text.secondary" mt={1}>
            {row.categoryName}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "lastPublishedOn",
    headerName: "Last published",
    flex: 1,
    renderCell: ({ row }: { row: PolicyRecord }) => (
      <Box display="flex" alignItems="center">
        <Typography variant="body2">
          {formatDate(row.lastPublishedOn)}
        </Typography>
        <PolicyTooltips policy={row} />
      </Box>
    ),
    sortingOrder: dateColumnSortingOrder,
  },
  {
    field: "versionsCount",
    headerName: "Versions",
    flex: 1,
    renderCell: ({ row }: { row: PolicyRecord }) =>
      row.versionsCount ? (
        <Box>
          <Typography variant="body2">
            {row.versionsCount.toLocaleString()}{" "}
            {pluralizeText("version", row.versionsCount)}
          </Typography>
          {!!row.versionsByState.length && (
            <LongTooltip
              title={row.versionsByState.map((v) => v.name).join("\n")}
            >
              <Typography variant="body2" color="text.secondary" mt={0.8}>
                {row.versionsByState.length.toLocaleString()} state specific
              </Typography>
            </LongTooltip>
          )}
          {!!row.versionsByFacility.length && (
            <LongTooltip
              title={row.versionsByFacility.map((v) => v.name).join("\n")}
            >
              <Typography variant="body2" color="text.secondary" mt={0.8}>
                {row.versionsByFacility.length.toLocaleString()} facility
                specific
              </Typography>
            </LongTooltip>
          )}
        </Box>
      ) : (
        "No versions"
      ),
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: ({ row }: { row: PolicyRecord }) => (
      <PolicyStatusChipStyled
        label={row.status}
        size="small"
        status={row.status}
      />
    ),
  },
];

export const PoliciesList = React.memo(
  /**
   *
   */
  function PoliciesList() {
    const onRowClick = useCallback(({ row }: { row: PolicyRecord }) => {
      Navigation.go(AdminPages.editPolicy, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<PoliciesListActions />}
        columns={columns}
        filterQueryKey={filterQueryKey}
        name="policies"
        onRowClick={onRowClick}
        rowHeight={84}
        stickyHeader={true}
        type={ListTypes.policies}
      />
    );
  },
);
