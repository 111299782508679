import React from "react";
import { Field, FieldAttributes, useField } from "formik";
import { CheckboxWithLabel } from "formik-mui";

export const CheckboxField = React.memo(
  /**
   *
   */
  function CheckboxField({ label, name, sx, ...rest }: FieldAttributes<any>) {
    const [, meta] = useField(name);
    const color = meta.touched && meta.error ? "error.main" : "";
    return (
      <Field
        component={CheckboxWithLabel}
        Label={{ label, sx: { color } }}
        name={name}
        type="checkbox"
        sx={{ color, ...sx }}
        {...rest}
      />
    );
  },
);
