import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { ResourceRecord } from "../../../../state";
import { RequiredDocumentStatuses } from "../../../../lib/constants";
import {
  CheckCircleIcon,
  CommentIcon,
  DateRangeIcon,
  ErrorIcon,
  PendingIcon,
} from "../../../../components";
import { formatDate, pluralizeText } from "../../../../lib";
import { styled } from "@mui/material/styles";

interface StatusTextStyledProps {
  status?: string;
}

const StatusTextStyled = styled(`div`, {
  shouldForwardProp: (prop) => prop !== "status",
})<StatusTextStyledProps>(({ theme, status }) => {
  const color =
    status === RequiredDocumentStatuses.Completed ||
    status === RequiredDocumentStatuses.Reviewed
      ? theme.palette.success.dark
      : status === RequiredDocumentStatuses.PendingReview
      ? theme.palette.warning.dark
      : status === RequiredDocumentStatuses.Needed
      ? theme.palette.error.dark
      : theme.palette.text.primary;
  return {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: color,
    whiteSpace: "pre-wrap",
    "& svg": {
      marginRight: "4px",
      color: color,
    },
    "& svg:nth-of-type(2)": {
      color: theme.palette.text.secondary,
      marginLeft: "6px",
    },
    "& p": {
      fontSize: "14px",
      color: theme.palette.text.primary,
      marginLeft: "6px",
    },
  };
});

interface RequiredDocumentStatusTextProps {
  resource: ResourceRecord;
}

export function RequiredDocumentStatusText({
  resource,
}: RequiredDocumentStatusTextProps) {
  const reviewCommentIcon = !!resource.requiredDocumentCommentsCount && (
    <Tooltip
      title={pluralizeText("comment", resource.requiredDocumentCommentsCount)}
    >
      <CommentIcon />
    </Tooltip>
  );

  return (
    <StatusTextStyled status={resource.requiredDocumentStatus}>
      {resource.requiredDocumentStatus ===
      RequiredDocumentStatuses.Completed ? (
        <>
          <CheckCircleIcon /> Completed
        </>
      ) : resource.requiredDocumentStatus ===
        RequiredDocumentStatuses.Reviewed ? (
        <>
          <CheckCircleIcon />
          Reviewed and confirmed by GB Designee
          {reviewCommentIcon}
        </>
      ) : resource.requiredDocumentStatus ===
        RequiredDocumentStatuses.PendingReview ? (
        <>
          <PendingIcon />
          Received - Pending GB Designee
          {reviewCommentIcon}
        </>
      ) : resource.requiredDocumentStatus ===
        RequiredDocumentStatuses.Needed ? (
        <>
          <ErrorIcon />
          Update Needed{" "}
          <Typography>
            Due {formatDate(resource.requiredDocumentDueDate)}
          </Typography>
        </>
      ) : resource.requiredDocumentDueDate ? (
        <>
          <DateRangeIcon />
          Due {formatDate(resource.requiredDocumentDueDate)}
        </>
      ) : (
        ""
      )}
    </StatusTextStyled>
  );
}
