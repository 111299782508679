import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Tooltip, TooltipProps } from "@mui/material";

interface LongTooltipProps extends TooltipProps {
  maxLength?: number;
  maxLines?: number;
  title: string;
}

export const LongTooltip = React.memo(
  /**
   *
   */
  function LongTooltip({
    maxLength = 800,
    maxLines = 20,
    title,
    ...tooltipProps
  }: LongTooltipProps) {
    const tooltipRef = useRef(null);
    const [placement, setPlacement] = useState<"bottom" | "top">();

    useEffect(() => {
      if (tooltipRef.current) {
        const tooltipElement = tooltipRef.current as HTMLElement;
        const boundingRect = tooltipElement.getBoundingClientRect();

        const tooltipPlacement =
          boundingRect.bottom < window.innerHeight / 2 ? "bottom" : "top";
        setPlacement(tooltipPlacement);
      }
    }, [tooltipRef]);

    const titleDisplay = useMemo(() => {
      let truncatedTitle = "";
      if (title.length > maxLength) {
        truncatedTitle = title.substring(0, 800);
      }
      if (title.includes("\n")) {
        const titleLines = title.split("\n");
        if (titleLines.length > maxLines) {
          truncatedTitle = titleLines.slice(0, maxLines).join("\n");
        }
      }
      return truncatedTitle ? `${truncatedTitle}...` : title;
    }, [maxLength, maxLines, title]);

    return (
      <Tooltip
        ref={tooltipRef}
        placement={placement}
        title={<Box whiteSpace="pre-wrap">{titleDisplay}</Box>}
        {...tooltipProps}
      />
    );
  },
);
