import { FacilityStatuses } from "../../../../../lib/constants";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StatusDropdownContainerStyledProps extends BoxProps {
  status?: string;
}

export const StatusDropdownContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<StatusDropdownContainerStyledProps>(({ theme, status }) => ({
  "& .MuiInput-root": {
    borderRadius: "30px",
    backgroundColor:
      status === FacilityStatuses.Active
        ? theme.palette.primary.light
        : status === FacilityStatuses.Onboarding
        ? theme.palette.warning.main
        : status === FacilityStatuses.OnHold
        ? theme.palette.error.light
        : status === FacilityStatuses.Pending
        ? theme.palette.secondary.dark
        : status === FacilityStatuses.Review
        ? theme.palette.info.dark
        : theme.palette.neutral.main,
    color: theme.palette.background.default,
    height: "24px",
    width: "fit-content",
    marginLeft: "16px",
    paddingLeft: "8px",
    paddingTop: "2px",
  },
  "& span": {
    fontSize: "14px",
  },
  "& svg": {
    color: `${theme.palette.background.default} !important`,
    marginTop: "3px",
  },
}));
