import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TrainingHeaderStyled = styled(Box)(() => ({
  marginLeft: "48px",
  marginBottom: "16px",
  "& > p": {
    maxWidth: "840px",
  },
}));
