import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const WizardContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const WizardHeaderStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: " center",
  position: "relative",
  marginBottom: "48px",
  "& button": {
    position: "absolute",
    left: 0,
  },
  "& svg": {
    color: theme.palette.text.primary,
  },
}));

export const WizardTitleStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "600px",
  textAlign: "center",
}));

export const WizardStepContainerStyled = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "3px",
  padding: "40px 24px 0 24px",
  marginBottom: "16px",
}));

export const HelperTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  display: "flex",
  alignItems: "center",
  "& svg": {
    fontSize: "12px",
    marginRight: "4px",
  },
}));
