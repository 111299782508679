import React, { useCallback, useEffect, useState } from "react";
import { adminActions, Facility, useAppDispatch } from "../../../../../state";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
} from "../../../../../components";
import { FacilityStatuses } from "../../../../../lib/constants";
import { Navigation } from "../../../../../lib";
import { AdminPages } from "../../..";

interface PublishFacilityPoliciesDialogProps {
  facility: Facility;
  handleConfirm: () => void;
}

export const PublishFacilityPoliciesDialog = React.memo(
  /**
   *
   */
  function PublishFacilityPoliciesDialog({
    facility,
    handleConfirm,
  }: PublishFacilityPoliciesDialogProps) {
    const dispatch = useAppDispatch();

    const [showPoliciesErrorDialog, setShowPoliciesErrorDialog] =
      useState(false);
    const [policiesMessage, setPoliciesMessage] = useState("");

    const publishFacilityPolicies = useCallback(async () => {
      const published = await dispatch(
        adminActions.publishPoliciesForFacility(Number(facility.id)),
      );
      if (published) {
        setPoliciesMessage(
          "Policies will be published for this facility - You will receive an email when publishing is complete.",
        );
        setShowPoliciesErrorDialog(false);
      } else {
        setShowPoliciesErrorDialog(true);
      }
    }, [dispatch, facility.id]);

    const redirectToPoliciesPage = useCallback(() => {
      Navigation.go(AdminPages.policies.path, {
        query: { facilities: [facility.id] },
      });
    }, [facility.id]);

    useEffect(() => {
      publishFacilityPolicies();
    }, [publishFacilityPolicies]);

    return showPoliciesErrorDialog ? (
      <ConfirmationDialog
        cancelText="Go to policies"
        confirmText="Try again"
        handleClose={redirectToPoliciesPage}
        handleConfirm={publishFacilityPolicies}
        message={
          <>
            Policies were not generated for {facility.name}. {"\n"}Please check
            your internet connection and try again. If the issue persists please
            publish each applicable policy individually from the Policies page.
          </>
        }
        open={true}
        title="A publishing error occurred"
        type={ConfirmationDialogTypes.Alert}
        width="420px"
      />
    ) : (
      <ConfirmationDialog
        confirmText="Ok"
        hideCancel={true}
        handleConfirm={handleConfirm}
        message={
          <>
            {facility.name} was saved as{" "}
            {facility.status === FacilityStatuses.Pending
              ? "profile pending"
              : facility.status === FacilityStatuses.Review
              ? "under profile review"
              : facility.status === FacilityStatuses.OnHold
              ? "on hold"
              : facility.status?.toLowerCase()}
            .{"\n\n"}
            {policiesMessage}
          </>
        }
        open={true}
        title={`${facility.name} activated`}
        type={ConfirmationDialogTypes.Saved}
      />
    );
  },
);
