import React, { useEffect } from "react";
import { PermissionClaims } from "./lib/constants";
import {
  authSelectors,
  sysActions,
  sysSelectors,
  useAction,
  useSelector,
} from "./state";

export const SystemData = React.memo(
  /**
   *
   */
  function SystemData() {
    const setSystemHydrated = useAction(sysActions.setSystemHydrated);
    const systemHydrated = useSelector(sysSelectors.systemHydrated);

    const getSystemSettings = useAction(sysActions.getSystemSettings);
    const hasSystemSettings = useSelector(sysSelectors.hasSystemSettings);

    const getAllRoles = useAction(sysActions.getAllRoles);
    const hasSystemRoles = useSelector(sysSelectors.hasSystemRoles);

    const getAllPositions = useAction(sysActions.getAllPositions);
    const hasSystemPositions = useSelector(sysSelectors.hasSystemPositions);

    const getAllGroups = useAction(sysActions.getAllGroups);
    const getAllFacilities = useAction(sysActions.getAllFacilities);
    const getAllRegions = useAction(sysActions.getAllRegions);
    const getAllPolicies = useAction(sysActions.getAllPolicies);
    const getAllAuditTemplates = useAction(sysActions.getAllAuditTemplates);
    const getPolicyCategories = useAction(sysActions.getPolicyCategories);
    const getResourceCategories = useAction(sysActions.getResourceCategories);

    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const userPermissionClaims = useSelector(authSelectors.permissionClaims);

    useEffect(() => {
      (async () => {
        if (isLoggedIn) {
          // get settings, roles, and positions only if not previously retrieved and persisted
          let systemSettingsPrm,
            rolesPrm,
            positionsPrm,
            policiesPrm,
            policyCategoriesPrm,
            resourceCategoriesPrm,
            auditTemplatesPrm;
          if (!hasSystemSettings) systemSettingsPrm = getSystemSettings();

          if (userPermissionClaims.includes(PermissionClaims.UsersReadClaim)) {
            if (!hasSystemRoles) rolesPrm = getAllRoles();
            if (!hasSystemPositions) positionsPrm = getAllPositions();
          }

          // refresh groups, facilities, regions, categories, and audit templates on each reload
          const groupsPrm = getAllGroups();
          const facilitiesPrm = getAllFacilities();
          const regionsPrm = getAllRegions();
          if (
            userPermissionClaims.includes(PermissionClaims.PoliciesReadClaim)
          ) {
            policyCategoriesPrm = getPolicyCategories();
          }
          if (
            userPermissionClaims.includes(PermissionClaims.ResourcesReadClaim)
          ) {
            resourceCategoriesPrm = getResourceCategories();
          }
          if (userPermissionClaims.includes(PermissionClaims.CcgAdminClaim)) {
            // all policies for CCG Admin resource pages related policy selection
            policiesPrm = getAllPolicies();
            // all audit templates for CCG Admin audits dashboard page
            auditTemplatesPrm = getAllAuditTemplates();
          }

          // set hydrated flag after data retrieval if system was not previously hydrated and persisted
          if (!systemHydrated) {
            await Promise.all([
              systemSettingsPrm,
              rolesPrm,
              positionsPrm,
              groupsPrm,
              facilitiesPrm,
              regionsPrm,
              policiesPrm,
              policyCategoriesPrm,
              resourceCategoriesPrm,
              auditTemplatesPrm,
            ]);
            setSystemHydrated(true);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      getAllAuditTemplates,
      getAllFacilities,
      getAllGroups,
      getAllPolicies,
      getAllPositions,
      getAllRegions,
      getAllRoles,
      getPolicyCategories,
      getResourceCategories,
      getSystemSettings,
      isLoggedIn,
    ]);

    return null;
  },
);
