import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  adminActions,
  BasicUser,
  Group,
  useAppDispatch,
} from "../../../../../state";
import { useFormik } from "formik";
import {
  CheckboxField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
} from "../../../../../components";
import { Form, Navigation } from "../../../../../lib";
import { PermissionClaims, UserStatuses } from "../../../../../lib/constants";
import { AdminPages } from "../../..";
import { FacilityFilesLinkStyled } from "./GroupSettings.styles";

interface GroupSettingsProps {
  group: Group;
  policyPrintingOverrideUsers?: BasicUser[];
  resetGroup: (group: Group) => void;
  setPolicyPrintingOverrideUsers: React.Dispatch<
    React.SetStateAction<BasicUser[] | undefined>
  >;
}

const getInitialValues = (group: Group) => ({
  facilityFilesEnabled: group.settings?.facilityFilesEnabled,
  policyPrintingDisabled: group.settings?.policyPrintingDisabled,
});

export const GroupSettings = React.memo(
  /**
   *
   */
  function GroupSettings({
    group,
    policyPrintingOverrideUsers,
    resetGroup,
    setPolicyPrintingOverrideUsers,
  }: GroupSettingsProps) {
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState(getInitialValues(group));

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      async onSubmit(values) {
        const savedGroup = await dispatch(
          adminActions.submitGroupSettings(Number(group.id), values),
        );
        if (savedGroup) {
          setInitialValues(getInitialValues(savedGroup));
          resetGroup(savedGroup);
        }
      },
    });

    useEffect(() => {
      (async () => {
        // retrieve policyPrintingOverrideUsers if policyPrinting is disabled and the list was not yet retrieved
        if (
          form.values.policyPrintingDisabled &&
          !policyPrintingOverrideUsers
        ) {
          const policyPrintingOverrideUsers = await dispatch(
            adminActions.getGroupUsersByClaim(
              Number(group.id),
              PermissionClaims.PoliciesPrintingOverrideClaim,
              "policy printing override",
            ),
          );
          setPolicyPrintingOverrideUsers(policyPrintingOverrideUsers || []);
        }
      })();
    }, [
      dispatch,
      form.values.policyPrintingDisabled,
      group.id,
      policyPrintingOverrideUsers,
      setPolicyPrintingOverrideUsers,
    ]);

    return (
      <Box sx={{ padding: "24px 0" }}>
        <Form form={form} promptCancelText="Back to Facility settings">
          <Typography variant="h5" sx={{ mb: 4 }}>
            Settings
          </Typography>
          <FormGridContainerStyled container>
            <FormGridItemFullWidthStyled>
              <Typography variant="subtitle1">Facility Files</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <CheckboxField
                name="facilityFilesEnabled"
                label="Enable facility files for this group"
              />
              {initialValues.facilityFilesEnabled && (
                <FacilityFilesLinkStyled
                  to={Navigation.url(AdminPages.facilityFiles, {
                    params: { groupId: group.id },
                  })}
                >
                  Manage files
                </FacilityFilesLinkStyled>
              )}
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled>
              <Typography variant="subtitle1">Policies</Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <CheckboxField
                name="policyPrintingDisabled"
                label="Disable printing for this group"
              />
              {form.values.policyPrintingDisabled && (
                <Box mt={3}>
                  <Typography variant="subtitle2" mb={1}>
                    Overrides
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={2}>
                    Policy printing override is enabled for the following users.
                    You can manage these permissions within a user's profile.
                  </Typography>
                  {policyPrintingOverrideUsers && (
                    <Typography variant="body2">
                      {policyPrintingOverrideUsers.length
                        ? policyPrintingOverrideUsers.map(
                            (user) =>
                              `${user.name}${
                                user.status !== UserStatuses.Active
                                  ? ` (${user.status})`
                                  : ""
                              }`,
                          )
                        : "No users"}
                    </Typography>
                  )}
                </Box>
              )}
            </FormGridItemFullWidthStyled>
            {form.dirty && (
              <FormFooterContainerStyled>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={form.isSubmitting}
                  onClick={() => form.resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  Save
                </Button>
              </FormFooterContainerStyled>
            )}
          </FormGridContainerStyled>
        </Form>
      </Box>
    );
  },
);
