import React, { useCallback, useMemo, useState } from "react";
import {
  BasicStatuses,
  BasicStatusOptions,
  EntityTypes,
  FacilityStatuses,
} from "../../../../../lib/constants";
import {
  adminActions,
  GroupFacilityRegion,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import { Tooltip } from "@mui/material";
import { SelectInput } from "../../../../../components";
import { PublishFacilityPoliciesDialog } from "../details/PublishFacilityPoliciesDialog";
import { StatusChipStyled } from "../lists/GroupsFacilitiesRegionsLists.styles";
import { StatusDropdownContainerStyled } from "./GroupFacilityRegionStatusDropdown.styles";
import { GroupFacilityRegionStatusConfirmationDialog } from "./GroupFacilityRegionStatusConfirmationDialog";

interface GroupFacilityRegionStatusDropdownProps {
  details: GroupFacilityRegion;
  resetDetails: (entity: GroupFacilityRegion) => void;
  type: string;
}
export const GroupFacilityRegionStatusDropdown = React.memo(
  /**
   *
   */
  function GroupFacilityRegionStatusDropdown({
    details,
    resetDetails,
    type,
  }: GroupFacilityRegionStatusDropdownProps) {
    const dispatch = useAppDispatch();

    const { facilityStatuses = [] } = useSelector(sysSelectors.systemSettings);

    const statusOptions = useMemo(() => {
      // filter statuses for facility
      if (type === EntityTypes.Facility) {
        switch (details.status) {
          case FacilityStatuses.Active:
          case FacilityStatuses.OnHold:
            return facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Active ||
                id === FacilityStatuses.Inactive ||
                id === FacilityStatuses.OnHold,
            );
          case FacilityStatuses.Inactive:
          case FacilityStatuses.Onboarding:
            return facilityStatuses.filter(
              ({ id }) =>
                id === FacilityStatuses.Active ||
                id === FacilityStatuses.Inactive ||
                id === FacilityStatuses.Onboarding ||
                id === FacilityStatuses.OnHold,
            );
          default:
            return [];
        }
      }

      return BasicStatusOptions;
    }, [details, facilityStatuses, type]);

    const [statusUpdateForConfirmation, setStatusUpdateForConfirmation] =
      useState("");

    const [showFacilityPoliciesDialog, setShowFacilityPoliciesDialog] =
      useState(false);

    const submitStatusAction = useMemo(() => {
      switch (type) {
        case EntityTypes.Group:
          return adminActions.submitGroupStatus;
        case EntityTypes.Region:
          return adminActions.submitRegionStatus;
        case EntityTypes.Facility:
        default:
          return adminActions.submitFacilityStatus;
      }
    }, [type]);

    const updateStatus = useCallback(
      async (statusUpdate) => {
        if (statusUpdateForConfirmation) {
          setStatusUpdateForConfirmation("");
        }

        const updatedDetails: GroupFacilityRegion | null = await dispatch(
          submitStatusAction(Number(details.id), statusUpdate),
        );

        if (updatedDetails) {
          const publishFacilityPolicies =
            type === EntityTypes.Facility &&
            details.status === FacilityStatuses.Inactive;

          resetDetails(updatedDetails);

          if (publishFacilityPolicies) {
            setShowFacilityPoliciesDialog(true);
          }
        }
      },
      [
        details.id,
        details.status,
        dispatch,
        resetDetails,
        statusUpdateForConfirmation,
        submitStatusAction,
        type,
      ],
    );

    const handleChange = useCallback(
      async (_, statusUpdate) => {
        if (!statusUpdate || details.status === statusUpdate) {
          return;
        }

        const activating = details.status === BasicStatuses.Inactive;
        const deactivating = statusUpdate === BasicStatuses.Inactive;
        // when activating/deactivating, set status for confirmation prompt
        if (activating || deactivating) {
          setStatusUpdateForConfirmation(statusUpdate);
        } else {
          updateStatus(statusUpdate);
        }
      },
      [details.status, updateStatus],
    );

    const readOnly = useMemo(() => {
      switch (type) {
        case EntityTypes.Facility:
          return (
            details.status === FacilityStatuses.Pending ||
            details.status === FacilityStatuses.Review ||
            details.groupStatus === BasicStatuses.Inactive ||
            details.regionStatus === BasicStatuses.Inactive
          );
        case EntityTypes.Region:
          return (
            details.status === BasicStatuses.Inactive &&
            details.groupStatus === BasicStatuses.Inactive
          );
        default:
          return false;
      }
    }, [details, type]);

    return readOnly ? (
      <Tooltip
        title={
          details.groupStatus === BasicStatuses.Inactive
            ? `This ${type.toLowerCase()} cannot be activated before the ${
                details.groupName
              } group is activated.`
            : details.regionStatus === BasicStatuses.Inactive
            ? `This ${type.toLowerCase()} cannot be activated before the ${
                details.regionName
              } region is activated.`
            : ""
        }
      >
        <StatusChipStyled
          label={details.statusDisplay || details.status}
          size="small"
          status={details.status}
          sx={{ ml: 2 }}
        />
      </Tooltip>
    ) : (
      <>
        <StatusDropdownContainerStyled status={details.status}>
          <SelectInput
            clearable={false}
            name="status"
            inputProps={{ disableUnderline: true }}
            onChange={handleChange}
            options={statusOptions}
            value={details.status}
            variant="standard"
          />
        </StatusDropdownContainerStyled>
        {statusUpdateForConfirmation && (
          <GroupFacilityRegionStatusConfirmationDialog
            handleClose={() => setStatusUpdateForConfirmation("")}
            handleConfirm={() => updateStatus(statusUpdateForConfirmation)}
            status={statusUpdateForConfirmation}
            type={type}
          />
        )}
        {showFacilityPoliciesDialog && (
          <PublishFacilityPoliciesDialog
            facility={details}
            handleConfirm={() => setShowFacilityPoliciesDialog(false)}
          />
        )}
      </>
    );
  },
);
