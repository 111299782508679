import writeXlsxFile, { SheetData } from "write-excel-file";
import { formatDate } from "../../../../../lib";
import { AuditStatuses, FacilityStatuses } from "../../../../../lib/constants";
import { AuditExportRecord, BasicAuditTemplate } from "../../../../../state";
import { ExportCell } from "../../../../../lib/types";

// export cell styles
const borderedCellStyles = {
  borderColor: "#040404",
};

const titleCellStyles = {
  ...borderedCellStyles,
  fontWeight: "bold",
};

const leftHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  rightBorderColor: "#FFFFF",
  leftBorderStyle: "thick",
  bottomBorderStyle: "thick",
};

const centerHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  rightBorderColor: "#FFFFF",
  leftBorderColor: "#FFFFF",
  bottomBorderStyle: "thick",
};

const rightHeaderCellStyles = {
  ...borderedCellStyles,
  ...titleCellStyles,
  leftBorderColor: "#FFFFF",
  rightBorderStyle: "thick",
  bottomBorderStyle: "thick",
};

const leftAuditDataCellStyles = {
  ...borderedCellStyles,
  leftBorderStyle: "thick",
};

const centerAuditDataCellStyles = {
  ...borderedCellStyles,
};

const rightAuditDataCellStyles = {
  ...borderedCellStyles,
  rightBorderStyle: "thick",
};

const noAuditCellStyles = {
  backgroundColor: "#A6A6A6",
};

const archivedAuditCellStyles = {
  backgroundColor: "#DDE3EB",
};

const notReturnedAuditCellStyles = {
  backgroundColor: "#FFFF00",
};

const completedAuditCellStyles = {
  backgroundColor: "#FFC0CB",
};

export async function exportAuditsData(
  auditTemplates: BasicAuditTemplate[],
  records: AuditExportRecord[],
) {
  const masterHeaderRow = [
    {
      value: "Facility Name",
      ...centerHeaderCellStyles,
    },
    {
      value: "Group Name",
      ...rightHeaderCellStyles,
    },
  ] as ExportCell[];

  const subHeaderRow = [{}, {}] as ExportCell[]; // default blank cell for facility and group name columns

  const dataRows = [] as ExportCell[];

  auditTemplates.forEach(({ name }) => {
    masterHeaderRow.push(
      { ...leftHeaderCellStyles },
      { value: name, ...centerHeaderCellStyles },
      { ...rightHeaderCellStyles },
    );

    subHeaderRow.push(
      {
        value: "Date Assigned",
        ...titleCellStyles,
        ...leftAuditDataCellStyles,
      },
      {
        value: "Date Returned",
        ...titleCellStyles,
        ...centerAuditDataCellStyles,
      },
      {
        value: "Completed/Certificate",
        ...titleCellStyles,
        ...rightAuditDataCellStyles,
      },
    );
  });

  records.forEach((record: AuditExportRecord) => {
    const recordRow = [] as ExportCell[];
    recordRow.push(
      {
        value: `${record.facilityName}${
          record.facilityStatus == FacilityStatuses.Inactive
            ? " [Inactive]"
            : ""
        }`,
        ...titleCellStyles,
      },
      {
        value: record.groupName,
        ...titleCellStyles,
      },
    );

    auditTemplates.forEach(({ id: auditTemplateId }) => {
      const audit = record.audits.find((a) => a.templateID === auditTemplateId);
      recordRow.push(
        {
          value: formatDate(audit?.sentOn),
          ...leftAuditDataCellStyles,
          ...(!audit
            ? noAuditCellStyles
            : audit.isArchived
            ? archivedAuditCellStyles
            : ""),
        },
        {
          value: formatDate(audit?.submittedOn),
          ...centerAuditDataCellStyles,
          ...(!audit
            ? noAuditCellStyles
            : audit.isArchived
            ? archivedAuditCellStyles
            : !audit.submittedOn
            ? notReturnedAuditCellStyles
            : ""),
        },
        {
          value:
            audit?.status === AuditStatuses.Completed
              ? "Completed"
              : audit?.status === AuditStatuses.CompletedCertificateSent
              ? "Certificate"
              : "",
          ...rightAuditDataCellStyles,
          ...(!audit
            ? noAuditCellStyles
            : audit.isArchived
            ? archivedAuditCellStyles
            : audit?.status === AuditStatuses.Completed ||
              audit?.status === AuditStatuses.CompletedCertificateSent
            ? completedAuditCellStyles
            : ""),
        },
      );
    });

    dataRows.push(recordRow);
  });

  const exportData = [masterHeaderRow, subHeaderRow, ...dataRows];

  // column styles: assign first two columns width of 50 chars, rest 20
  const columns = subHeaderRow.map((_, i) => ({ width: i > 1 ? 20 : 50 }));

  await writeXlsxFile(exportData as SheetData, {
    columns,
    fileName: `Audits_Report_${formatDate(new Date())}.xlsx`,
  });
}
