import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DeleteIcon,
  List,
  ListActionsContainerStyled,
  ListActionsMainStyled,
  TextButtonStyled,
  TextField,
} from "../../../../../components";
import {
  Facility,
  FacilityNote,
  FacilityNoteRecord,
  adminActions,
  listActionUrls,
  useAppDispatch,
} from "../../../../../state";
import { ListTypes } from "../../../../../state/lists/state";
import { Form, formatDate, useFormik } from "../../../../../lib";
import * as yup from "yup";

const validationSchema = yup.object({
  text: yup.string().required("Enter note text"),
});

interface FacilityNoteDialogProps {
  facilityId: number;
  handleClose: () => void;
}

export const FacilityNoteDialog = React.memo(
  /**
   *
   */
  function FacilityNoteDialog({
    facilityId,
    handleClose,
  }: FacilityNoteDialogProps) {
    const dispatch = useAppDispatch();

    const onSubmit = useCallback(
      async (values: FacilityNote) => {
        const submitted = await dispatch(
          adminActions.submitFacilityNote(facilityId, values),
        );
        if (submitted) {
          handleClose();
        }
      },
      [dispatch, facilityId, handleClose],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        text: "",
      } as FacilityNote,
      validationSchema,
      onSubmit,
    });

    return (
      <Dialog open={true} PaperProps={{ sx: { width: "600px" } }}>
        <Form form={form} promptCancelText="Back to note">
          <DialogTitle variant="h6">Add Note</DialogTitle>
          <DialogContent sx={{ paddingTop: "8px !important" }}>
            <TextField name="text" label="Note" multiline={true} rows={4} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={handleClose}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={form.isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);

function FacilityNotesListActions({ facilityId }: { facilityId: number }) {
  const [showNewNoteDialog, setShowNewNoteDialog] = useState(false);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Notes</Typography>
        <Button
          size="large"
          sx={{ ml: 1 }}
          variant="contained"
          onClick={() => setShowNewNoteDialog(true)}
        >
          Add note
        </Button>
        {showNewNoteDialog && (
          <FacilityNoteDialog
            facilityId={facilityId}
            handleClose={() => setShowNewNoteDialog(false)}
          />
        )}
      </ListActionsMainStyled>
    </ListActionsContainerStyled>
  );
}

interface FacilityNotesListProps {
  facility: Facility;
}

export const FacilityNotesList = React.memo(
  /**
   *
   */
  function FacilityNotesList({ facility }: FacilityNotesListProps) {
    const dispatch = useAppDispatch();

    const [noteToDelete, setNoteToDelete] = useState<FacilityNoteRecord>();
    const [showMoreNoteIds, setShowMoreNoteIds] = useState<number[]>([]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "text",
          flex: 2,
          renderCell: ({ row }: { row: FacilityNoteRecord }) => {
            const showMore = showMoreNoteIds.includes(row.id);
            return (
              <Typography variant="body2" whiteSpace="pre-wrap" paddingY="16px">
                {!showMore && row.text.length > 200
                  ? `${row.text.substring(0, 200)}...`
                  : row.text}
                {row.text.length > 200 && (
                  <>
                    {"   "}
                    <TextButtonStyled
                      onClick={() =>
                        setShowMoreNoteIds(
                          showMore
                            ? showMoreNoteIds.filter((id) => id !== row.id)
                            : [...showMoreNoteIds, row.id],
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      {showMore ? "Show less" : "Show more"}
                    </TextButtonStyled>
                  </>
                )}
              </Typography>
            );
          },
          sortable: false,
        },
        {
          field: "createdOn",
          flex: 1,
          renderCell: ({ row }: { row: FacilityNoteRecord }) => (
            <Box width="100%" height="100%" textAlign="right" paddingY="16px">
              <Typography color="text.secondary" variant="caption">
                {row.createdBy ? `${row.createdBy}  •  ` : ""}
                {formatDate(row.createdOn)}
              </Typography>
            </Box>
          ),
          sortable: false,
        },
        {
          field: "actions",
          width: 80,
          renderCell: ({ row }: { row: FacilityNoteRecord }) => (
            <Box width="100%" height="100%" paddingY="8px">
              <IconButton
                size="small"
                onClick={() => setNoteToDelete(row)}
                sx={{ color: "text.secondary" }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ),
          sortable: false,
        },
      ],
      [showMoreNoteIds],
    );

    const onDelete = useCallback(async () => {
      if (!noteToDelete) {
        return;
      }
      const deleted = await dispatch(
        adminActions.deleteFacilityNote(Number(facility.id), noteToDelete.id),
      );
      if (deleted) {
        setNoteToDelete(undefined);
      }
    }, [dispatch, facility.id, noteToDelete]);

    return (
      <>
        <List
          actions={
            <FacilityNotesListActions facilityId={Number(facility.id)} />
          }
          dataUrl={listActionUrls[ListTypes.facilityNotes].replace(
            ":id",
            facility.id?.toString() || "",
          )}
          dynamicRowHeight={true}
          columns={columns}
          defaultOrderBy="createdOn"
          defaultOrderDirection="desc"
          hideHeader={true}
          name="notes"
          type={ListTypes.facilityNotes}
        />
        {noteToDelete && (
          <ConfirmationDialog
            handleClose={() => setNoteToDelete(undefined)}
            handleConfirm={onDelete}
            open={true}
            message="Are you sure you want to delete this note?"
            title="Delete note?"
            type={ConfirmationDialogTypes.Alert}
          />
        )}
      </>
    );
  },
);
