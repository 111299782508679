import React, { useMemo } from "react";
import { sysSelectors, useSelector } from "../../../../state";
import { StatusPage } from "../../../shared/components";
import facility from "../../../../assets/img/facility.svg";
import unauthorized from "../../../../assets/img/unauthorized.svg";

export const FacilityFilesAccessWrapper = React.memo(
  /**
   *
   */
  function FacilityFilesAccessWrapper({ children }: { children: JSX.Element }) {
    const groupOptions = useSelector(sysSelectors.activeGroups);
    const groupFilter = useSelector(sysSelectors.groupFilter);

    const groupSelection = useMemo(
      () => groupOptions.find((g) => g.id == groupFilter?.id),
      [groupFilter?.id, groupOptions],
    );

    return !groupFilter?.id ? ( // show status page when there is no system group filter
      <StatusPage
        img={facility}
        imgHeight="80px"
        title="Select a group from the blue bar at the top of the screen to view facility files"
      />
    ) : !groupSelection?.facilityFilesEnabled ? (
      <StatusPage
        img={unauthorized}
        imgHeight="80px"
        title={`${groupFilter.name} does not have access to facility files`}
      />
    ) : (
      children
    );
  },
);
