import React, { Fragment, useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  List,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  VisibilityOffIcon,
} from "../../../../components";
import { Navigation, RouterLink } from "../../../../lib";
import {
  ResourceRecord,
  authSelectors,
  sysSelectors,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import { RequiredDocumentStatusText } from "../../../adminShared/components/resources/RequiredDocumentStatusText";
import { RequiredDocumentsExportDialog } from "../../../adminShared/components";
import { PermissionClaims } from "../../../../lib/constants";
import { FacilityAdminPages } from "../..";

const filterQueryKey = {
  text: "search",
};

function RequiredDocumentsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const userPermissionClaims = useSelector(authSelectors.permissionClaims);
  const allowExport = userPermissionClaims.includes(
    PermissionClaims.RequiredDocumentsClaim,
  );

  const { search = "" } = query;

  const [showExportDialog, setShowExportDialog] = useState(false);

  return (
    <ListActionsMainStyled sx={{ pt: 0 }}>
      <SearchInput
        variant="outlined"
        placeholder="Search required documents"
        name="search"
        value={search}
        debounced={true}
        onSearch={(_search) => onFilterChange("search", _search)}
      />
      {allowExport && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => setShowExportDialog(true)}
          >
            Export
          </Button>
          {showExportDialog && (
            <RequiredDocumentsExportDialog
              handleClose={() => setShowExportDialog(false)}
            />
          )}
        </>
      )}
    </ListActionsMainStyled>
  );
}

export const RequiredDocumentsList = React.memo(
  /**
   *
   */
  function RequiredDocumentsList() {
    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => ({
        requiredDocDetails: true,
        ...(facilityFilter ? { facilityIDs: [facilityFilter.id] } : {}),
      }),
      [facilityFilter],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Required Document",
          flex: 1.5,
        },
        {
          field: "policies",
          headerName: "Related Policy",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <Box>
              {row.policies.map((p, i) => (
                <Fragment key={p.id}>
                  <Link
                    component={RouterLink}
                    onClick={(e) => e.stopPropagation()}
                    to={Navigation.url(FacilityAdminPages.policy, {
                      params: { id: p.id },
                    })}
                  >
                    {p.title.replace(p.name, "").trim()}
                  </Link>
                  {i < row.policies.length - 1 && (
                    <Typography variant="caption" mr={0.5}>
                      ,
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Box>
          ),
          sortable: false,
        },
        {
          field: "categories",
          headerName: "Category",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <AbbreviatedListDisplay items={row.categories} />
          ),
          sortable: false,
        },
        {
          field: "requiredDocumentStatus",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: ResourceRecord }) => (
            <RequiredDocumentStatusText resource={row} />
          ),
          sortable: false,
        },
        ...(!facilityFilter?.id
          ? [
              {
                field: "actions",
                headerName: "",
                width: 80,
                renderCell: () => (
                  <Tooltip title="You are currently viewing required documents across all facilities. Select a facility from the blue bar at the top of the screen to open this document.">
                    <VisibilityOffIcon sx={{ color: "neutral.main" }} />
                  </Tooltip>
                ),
                sortable: false,
              },
            ]
          : []),
      ],
      [facilityFilter?.id],
    );

    const onRowClick = useCallback(({ row }: { row: ResourceRecord }) => {
      Navigation.go(FacilityAdminPages.resource, { params: { id: row.id } });
    }, []);

    return (
      <List
        actions={<RequiredDocumentsListActions />}
        columns={columns}
        filter={listFilter}
        filterQueryKey={filterQueryKey}
        name="required documents"
        onRowClick={facilityFilter?.id ? onRowClick : undefined}
        rowHeight={84}
        rowNumbers={true}
        stickyHeader={true}
        type={ListTypes.requiredDocumentResources}
      />
    );
  },
);
