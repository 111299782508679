import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import {
  adminActions,
  Resource,
  sharedActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { useParams } from "react-router-dom";
import {
  AutocompleteField,
  CheckboxField,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  ErrorIcon,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  LinkTextStyled,
  SelectField,
  TextField,
  ToggleField,
  ToggleInput,
} from "../../../../components";
import {
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useLocation,
  usePageTitle,
  useRedirect,
  yup,
} from "../../../../lib";
import { AdminPages } from "../..";
import {
  ApplicableForTypes,
  BasicStatuses,
  BasicStatusOptions,
  ResourcesPageTabs,
  ResourceTypes,
} from "../../../../lib/constants";
import { ManageCategoriesDialog } from "./categories/ManageCategoriesDialog";
import { styled, useTheme } from "@mui/material/styles";

const urlValidationMessage = "Incorrect URL format";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(250, "Name cannot exceed 250 characters"),
  description: yup
    .string()
    .nullable()
    .max(1000, "Description cannot exceed 1,000 characters"),
  type: yup.string().required("Type is required"),
  status: yup.string().required("Status is required"),
  url: yup
    .string()
    .max(500, "URL cannot exceed 500 characters")
    .required("URL is required")
    .test(
      "google-drive-file-url",
      urlValidationMessage,
      (value) =>
        !value || value.toLowerCase().includes("drive.google.com/file"),
    ),
  requiredFrequency: yup.string().nullable(),
  hasReviewWorkflow: yup.boolean().nullable(),
  hasQuickAccess: yup.boolean().nullable(),
  categoryIDs: yup.array().when("type", {
    is: (type) => type === ResourceTypes.Tool,
    then: (schema) => schema.min(1, "Category is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  facilityIDs: yup.array().when(["status", "$applicableFor"], {
    is: (status, applicableFor) =>
      status !== BasicStatuses.Inactive &&
      applicableFor === ApplicableForTypes.Facility,
    then: (schema) => schema.min(1, "Facility is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  stateIDs: yup.array().when(["status", "$applicableFor"], {
    is: (status, applicableFor) =>
      status !== BasicStatuses.Inactive &&
      applicableFor === ApplicableForTypes.State,
    then: (schema) => schema.min(1, "State is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  policyIDs: yup.array(),
});

const UrlInstructionsTooltip = styled(
  ({ className, ...props }: Omit<TooltipProps, "title">) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      title={
        <Box whiteSpace="pre-wrap">
          Where to find the correct URL format:
          <br />• Open Google Drive and find the file in the Google Drive
          folder. Right-click on the file and choose 'Get link' or 'Share' from
          the menu.
          <br />• When the sharing modal opens, use the 'Copy Link' button to
          copy the file URL to your clipboard.
          <br />• Do not copy the URL from the browser bar when previewing the
          PDF in the drive.
          <br />• The correct URL will have the word 'file' in it (sample:
          https://drive.google.com/file/d/17oTGxYF4RRVdTUqK8S4nmMRDntJOQx-X/view?usp=sharing)
          <br />• The wrong URL will have the word 'folders' in it (sample:
          https://drive.google.com/drive/u/0/folders/1Yp_w2Pjtk59Lk_6MCrvjO38W8Pm6k62z).
          <br />• Note: Resource files must be saved in PDF format. Google Docs
          are not supported.
        </Box>
      }
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
}));

export const ResourceForm = React.memo(
  /**
   *
   */
  function ResourceForm() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();
    const theme = useTheme();

    const setPageTitle = usePageTitle();

    const { redirect, setRedirect } = useRedirect();

    const {
      requiredFrequencies: requiredFrequencyOptions = [],
      states: stateOptions = [],
    } = useSelector(sysSelectors.systemSettings);
    const resourceCategoryOptions = useSelector(
      sysSelectors.resourceCategories,
    );
    const facilityOptions = useSelector(sysSelectors.allFacilities);
    const policyOptions = useSelector(sysSelectors.allPolicies);

    const [initialValues, setInitialValues] = useState<Resource>({
      name: "",
      description: "",
      type: location.query.type?.toString() || ResourceTypes.Tool, //default to Tool
      status: "",
      url: "",
      requiredFrequency: "",
      hasReviewWorkflow: false,
      hasQuickAccess: false,
      categoryIDs: [],
      policyIDs: [],
      facilityIDs: [],
      stateIDs: [],
    });

    const [applicableFor, setApplicableFor] = useState(
      ApplicableForTypes.State,
    );

    const [resourceMatchConfirmation, setResourceMatchConfirmation] =
      useState<{ allowSubmit: boolean; message: string }>();

    useEffect(() => {
      (async () => {
        if (params.id) {
          const resource = await dispatch(
            adminActions.getResource(Number(params.id)),
          );
          if (resource) {
            setInitialValues(replaceNullProps(resource));
            const { facilityIDs, typeDisplay } = resource;
            setPageTitle({ title: `Edit ${typeDisplay}` });
            if (facilityIDs.length) {
              setApplicableFor(ApplicableForTypes.Facility);
            }
          }
        } else {
          const typeDisplay =
            location.query.type === ResourceTypes.Tool
              ? "Tool"
              : location.query.type === ResourceTypes.RequiredDocument
              ? "Required document"
              : "Resource";
          setPageTitle({ title: `Add ${typeDisplay}` });
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id]);

    const getValuesForSubmission = useCallback(
      (values: Resource) => {
        const valuesForSubmission = { ...values };

        // clear out facilities if applicable-for-type is state, clear states if type is facility (not clearing until submission to persist data when user toggles back and forth)
        if (
          applicableFor === ApplicableForTypes.State &&
          values.facilityIDs.length
        ) {
          valuesForSubmission.facilityIDs = [];
        } else if (
          applicableFor === ApplicableForTypes.Facility &&
          values.stateIDs.length
        ) {
          valuesForSubmission.stateIDs = [];
        }

        return valuesForSubmission;
      },
      [applicableFor],
    );

    // returns boolean to indicate if confirmation is required
    const checkResourceMatchConfirmation = useCallback(
      async (values: Resource) => {
        // check for resource name match if relevant fields were changed
        const checkForMatch =
          values.name != initialValues.name || // changed name
          // or added state
          values.stateIDs.some((s) => !initialValues.stateIDs.includes(s)) ||
          // or added facility
          values.facilityIDs.some(
            (f) => !initialValues.facilityIDs.includes(f),
          );

        if (!checkForMatch) return false;

        let resourceMatches = await dispatch(
          sharedActions.getBasicResources({
            name: values.name as string,
            includeFacilitiesAndStates: true,
          }),
        );

        if (!resourceMatches) {
          return true; // return true to exit submission if there's an error retrieving matches
        }

        resourceMatches = resourceMatches.filter((r) => r.id !== values.id); // exclude the current resource from the match list

        if (!resourceMatches.length) {
          return false;
        }

        // check for matching resource with overlapping states config
        if (
          applicableFor === ApplicableForTypes.State &&
          resourceMatches
            .flatMap((r) => r.stateIDs)
            .some((s) => values.stateIDs.includes(s))
        ) {
          setResourceMatchConfirmation({
            allowSubmit: false,
            message: `There is a state-specific resource with this resource name. Mutiple state-specific resources with overlapping states cannot be created with the same name.`,
          });
          return true;
        }

        // check for matching resource with overlapping facilities config
        if (
          applicableFor === ApplicableForTypes.Facility &&
          resourceMatches
            .flatMap((r) => r.facilityIDs)
            .some((f) => values.facilityIDs.includes(f))
        ) {
          setResourceMatchConfirmation({
            allowSubmit: false,
            message: `There is a facility-specific resource with this resource name.  Mutiple facility-specific resources with overlapping facilities cannot be created with the same name.`,
          });
          return true;
        }

        // check for matching resource with opposite state/facility config type
        if (
          resourceMatches.some((r) =>
            applicableFor === ApplicableForTypes.State
              ? r.facilityIDs.length
              : r.stateIDs.length,
          )
        ) {
          setResourceMatchConfirmation({
            allowSubmit: true,
            message: `Note that there is ${
              resourceMatches.length > 1 ? "at least one" : "a"
            } ${
              applicableFor === ApplicableForTypes.State
                ? "facility-specific"
                : "state-specific"
            } resource with the same name as this resource. The facility-specific resource will always override the state-specific resource for resources that share the same name.`,
          });
          return true;
        }

        return false;
      },
      [
        applicableFor,
        dispatch,
        initialValues.facilityIDs,
        initialValues.name,
        initialValues.stateIDs,
      ],
    );

    const onSubmit = useCallback(
      async (values: Resource) => {
        const valuesForSubmission = getValuesForSubmission(values);

        // clear match confirmation state upon submission confirmation
        if (resourceMatchConfirmation) {
          setResourceMatchConfirmation(undefined);
        } else {
          const confirmationRequired = await checkResourceMatchConfirmation(
            valuesForSubmission,
          );
          if (confirmationRequired) return;
        }

        const savedResource = await dispatch(
          adminActions.submitResource(replaceEmptyProps(valuesForSubmission)),
        );
        if (savedResource) {
          setInitialValues(replaceNullProps(savedResource));

          const resourcesListUrl = Navigation.url(AdminPages.resources, {
            query: {
              tab:
                values.type === ResourceTypes.RequiredDocument
                  ? ResourcesPageTabs.RequiredDocuments
                  : ResourcesPageTabs.Tools,
            },
          });
          setRedirect(() => () => Navigation.goBack(resourcesListUrl));
        }
      },
      [
        checkResourceMatchConfirmation,
        dispatch,
        getValuesForSubmission,
        resourceMatchConfirmation,
        setRedirect,
      ],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            applicableFor: applicableFor,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      onSubmit,
    });

    const onCancel = useCallback(
      () => Navigation.goBack(AdminPages.resources.path),
      [],
    );

    const [showManageCategoriesDialog, setShowManageCategoriesDialog] =
      useState(false);

    return (
      <>
        <Form
          form={form}
          noPrompt={!!redirect}
          promptCancelText="Back to Resource"
        >
          <FormGridContainerStyled container>
            <FormGridItemStyled item>
              <TextField name="name" label="Name" />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <TextField
                name="url"
                label="Google drive URL"
                helperText="Please note: The Google Drive file must be a pdf"
                InputProps={{
                  endAdornment:
                    form.touched.url &&
                    form.errors.url === urlValidationMessage ? (
                      <UrlInstructionsTooltip>
                        <ErrorIcon
                          sx={{
                            color: `${theme.palette.error.dark} !important`,
                          }}
                        />
                      </UrlInstructionsTooltip>
                    ) : undefined,
                }}
              />
            </FormGridItemStyled>
            <FormGridItemStyled item>
              <SelectField
                name="categoryIDs"
                label="Category"
                menuTag={
                  <LinkTextStyled
                    variant="subtitle2"
                    onClick={() => setShowManageCategoriesDialog(true)}
                    sx={{ textDecoration: "none" }}
                  >
                    Manage categories
                  </LinkTextStyled>
                }
                multiple={true}
                options={resourceCategoryOptions}
              />
              {showManageCategoriesDialog && (
                <ManageCategoriesDialog
                  handleClose={() => setShowManageCategoriesDialog(false)}
                />
              )}
            </FormGridItemStyled>
            {form.values.type === ResourceTypes.RequiredDocument ? (
              <FormGridItemStyled item>
                <CheckboxField
                  name="hasReviewWorkflow"
                  label="Add CO Quarterly Report workflow"
                />
              </FormGridItemStyled>
            ) : (
              <FormGridItemStyled item>
                <CheckboxField
                  name="hasQuickAccess"
                  label="Accessible from user landing screen"
                />
              </FormGridItemStyled>
            )}
            <FormGridItemFullWidthStyled item>
              <TextField
                name="description"
                label="Description"
                multiline={true}
                rows={4}
              />
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <ToggleField
                fullWidth={false}
                name="status"
                options={BasicStatusOptions}
                size="large"
              />
            </FormGridItemFullWidthStyled>
            {form.values.type === ResourceTypes.RequiredDocument && (
              <>
                <FormGridItemFullWidthStyled>
                  <Typography variant="h6" mt={1} mb={1}>
                    Required timeframe
                  </Typography>
                </FormGridItemFullWidthStyled>
                <FormGridItemFullWidthStyled item>
                  <ToggleField
                    fullWidth={false}
                    name="requiredFrequency"
                    options={[
                      { id: "", name: "None" },
                      ...requiredFrequencyOptions,
                    ]}
                    size="large"
                  />
                </FormGridItemFullWidthStyled>
              </>
            )}
            <FormGridItemFullWidthStyled>
              <Typography variant="h6" mt={1} mb={1}>
                Applicable for
              </Typography>
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <ToggleInput
                name="applicableFor"
                value={applicableFor}
                onChange={(_, val) => setApplicableFor(val)}
                options={[
                  { id: ApplicableForTypes.State, name: "State specific" },
                  {
                    id: ApplicableForTypes.Facility,
                    name: "Facility specific",
                  },
                ]}
              />
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              {applicableFor === ApplicableForTypes.State ? (
                <AutocompleteField
                  name="stateIDs"
                  label="Select states"
                  multiple={true}
                  options={stateOptions}
                />
              ) : (
                <AutocompleteField
                  name="facilityIDs"
                  label="Select facilities"
                  multiple={true}
                  options={facilityOptions}
                />
              )}
            </FormGridItemFullWidthStyled>
            <FormGridItemFullWidthStyled item>
              <AutocompleteField
                name="policyIDs"
                label="Related policy"
                multiple={true}
                options={policyOptions.map((o) => ({
                  id: o.id,
                  name: o.title,
                }))}
              />
            </FormGridItemFullWidthStyled>
            <FormFooterContainerStyled>
              <Button
                variant="outlined"
                size="large"
                disabled={form.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </FormFooterContainerStyled>
          </FormGridContainerStyled>
        </Form>
        {!!resourceMatchConfirmation && (
          <ConfirmationDialog
            hideCancel={!resourceMatchConfirmation.allowSubmit}
            confirmText={
              resourceMatchConfirmation.allowSubmit ? "Continue" : "Edit name"
            }
            handleClose={() => setResourceMatchConfirmation(undefined)}
            handleConfirm={() =>
              resourceMatchConfirmation.allowSubmit
                ? onSubmit(form.values)
                : setResourceMatchConfirmation(undefined)
            }
            message={resourceMatchConfirmation?.message}
            open={true}
            title="Resource name match"
            type={ConfirmationDialogTypes.Warning}
          />
        )}
      </>
    );
  },
);
