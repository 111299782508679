import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
  adminActions,
  Announcement,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { useParams } from "react-router-dom";
import {
  AutocompleteField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  SelectField,
  TextField,
  ToggleField,
  ToggleInput,
} from "../../../../components";
import {
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useRedirect,
  yup,
} from "../../../../lib";
import { AdminPages } from "../..";
import {
  ApplicableForTypes,
  BasicStatusOptions,
} from "../../../../lib/constants";

const validationSchema = yup.object({
  subject: yup
    .string()
    .max(500, "Subject cannot exceed 1000 characters")
    .when("body", {
      is: (body) => !body,
      then: (schema) => schema.required("Subject or body is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  body: yup.string().nullable(),
  status: yup.string().required("Status is required"),
  facilityType: yup.string().required("Facility type is required"),
  facilityIDs: yup.array().when("$applicableForType", {
    is: (applicableForType) =>
      applicableForType === ApplicableForTypes.Facility,
    then: (schema) => schema.min(1, "Facility is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  stateIDs: yup.array().when("$applicableForType", {
    is: (applicableForType) => applicableForType === ApplicableForTypes.State,
    then: (schema) => schema.min(1, "State is required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const AnnouncementForm = React.memo(
  /**
   *
   */
  function AnnouncementForm() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const { redirect, setRedirect } = useRedirect();

    const {
      facilityTypes: facilityTypeOptions = [],
      states: stateOptions = [],
    } = useSelector(sysSelectors.systemSettings);
    const facilityOptions = useSelector(sysSelectors.allFacilities);

    const [initialValues, setInitialValues] = useState<Announcement>({
      subject: "",
      body: "",
      status: "",
      facilityType: "",
      facilityIDs: [],
      stateIDs: [],
    });

    const [applicableForType, setApplicableForType] = useState(
      ApplicableForTypes.State,
    );

    useEffect(() => {
      (async () => {
        if (params.id) {
          const announcement = await dispatch(
            adminActions.getAnnouncement(Number(params.id)),
          );
          if (announcement) {
            setInitialValues(replaceNullProps(announcement));
            if (announcement.facilityIDs.length) {
              setApplicableForType(ApplicableForTypes.Facility);
            }
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id]);

    const getValuesForSubmission = useCallback(
      async (values: Announcement) => {
        const valuesForSubmission = { ...values };

        // clear out facilities if applicable-for-type is state, clear states if type is facility (not clearing until submission to persist data when user toggles back and forth)
        if (
          applicableForType === ApplicableForTypes.State &&
          values.facilityIDs.length
        ) {
          valuesForSubmission.facilityIDs = [];
        } else if (
          applicableForType === ApplicableForTypes.Facility &&
          values.stateIDs.length
        ) {
          valuesForSubmission.stateIDs = [];
        }

        return valuesForSubmission;
      },
      [applicableForType],
    );

    const onSubmit = useCallback(
      async (values: Announcement) => {
        const valuesForSubmission = await getValuesForSubmission(values);
        if (!valuesForSubmission) {
          return;
        }

        const savedAnnouncement = await dispatch(
          adminActions.submitAnnouncement(
            replaceEmptyProps(valuesForSubmission),
          ),
        );
        if (savedAnnouncement) {
          setInitialValues(replaceNullProps(savedAnnouncement));

          setRedirect(
            () => () => Navigation.goBack(AdminPages.configuration.path),
          );
        }
      },
      [dispatch, getValuesForSubmission, setRedirect],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validate: (values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            applicableForType,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      },
      onSubmit,
    });

    const onCancel = useCallback(
      () => Navigation.goBack(AdminPages.configuration.path),
      [],
    );

    const onChangeFacilityType = useCallback(
      (e) => {
        const value = e.target.value;
        form.setFieldValue("facilityType", value);

        // clear facility selection when facility type is changed
        form.setFieldValue("facilityIDs", []);
      },
      [form],
    );

    return (
      <Form
        form={form}
        noPrompt={!!redirect}
        promptCancelText="Back to Announcement"
      >
        <FormGridContainerStyled container>
          <FormGridItemFullWidthStyled item>
            <TextField name="subject" label="Subject" />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <TextField name="body" label="Body" multiline={true} rows={4} />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleField
              buttonWidth="140px"
              fullWidth={false}
              name="status"
              options={BasicStatusOptions}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <Typography variant="h6" mt={1} mb={1}>
              Applicable for
            </Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <SelectField
              clearable={false}
              name="facilityType"
              label="Facility type"
              onChange={onChangeFacilityType}
              options={facilityTypeOptions}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleInput
              name="applicableForType"
              value={applicableForType}
              onChange={(_, val) => setApplicableForType(val)}
              options={[
                { id: ApplicableForTypes.State, name: "State specific" },
                { id: ApplicableForTypes.Facility, name: "Facility specific" },
              ]}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            {applicableForType === ApplicableForTypes.State ? (
              <AutocompleteField
                name="stateIDs"
                label="Select states"
                multiple={true}
                options={stateOptions}
              />
            ) : (
              <Tooltip
                title={
                  !form.values.facilityType
                    ? "Select a facility type before selecting applicable facilities"
                    : ""
                }
              >
                <Box>
                  <AutocompleteField
                    disabled={!form.values.facilityType}
                    name="facilityIDs"
                    label="Select facilities"
                    multiple={true}
                    options={facilityOptions.filter(
                      (f) => f.type == form.values.facilityType,
                    )}
                  />
                </Box>
              </Tooltip>
            )}
          </FormGridItemFullWidthStyled>
          <FormFooterContainerStyled>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </FormFooterContainerStyled>
        </FormGridContainerStyled>
      </Form>
    );
  },
);
