import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PdfContainerStyled = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  "& .webviewer": {
    height: "100%",
    width: "100%",
  },
}));

export const LoadingContainerStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "absolute",
}));

export const PdfActionsContainerStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  padding: "8px 0px",
}));

export const ProgressHeaderStyled = styled(`div`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "4px",
  "& > div": {
    display: "flex",
    alignItems: "center",
  },
}));

export const HeaderIconButtonStyled = styled(Button)(() => ({
  minWidth: 0,
  padding: "6px 8px",
  marginLeft: "8px",
}));
