import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabbedView } from "../../components";
import { BasicStatuses, EntityTypes } from "../../lib/constants";
import { adminActions, BasicUser, Group, useAppDispatch } from "../../state";
import {
  FacilitiesSublist,
  GroupFacilityRegionSnapshot,
  GroupSettings,
  RegionsSublist,
  UsersSublist,
} from "./components";

const tabs = [
  { id: "regions", name: "Regions", component: RegionsSublist },
  { id: "facilities", name: "Facilities", component: FacilitiesSublist },
  { id: "users", name: "Users", component: UsersSublist },
  { id: "settings", name: "Settings", component: GroupSettings },
];

export const GroupPage = React.memo(
  /**
   *
   */
  function GroupPage() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [group, setGroup] = useState<Group>();
    const [policyPrintingOverrideUsers, setPolicyPrintingOverrideUsers] =
      useState<BasicUser[]>();

    useEffect(() => {
      (async () => {
        if (params.id) {
          const group = await dispatch(
            adminActions.getGroup(Number(params.id)),
          );
          if (group) {
            setGroup(group);
          }
        }
      })();
    }, [dispatch, params.id]);

    const componentProps = useMemo(
      () => ({
        filter: {
          groupId: params.id,
          includeInactive: group?.status === BasicStatuses.Inactive,
        },
        policyPrintingOverrideUsers,
        group,
        resetGroup: setGroup,
        setPolicyPrintingOverrideUsers,
      }),
      [group, params.id, policyPrintingOverrideUsers],
    );

    return group ? (
      <>
        <GroupFacilityRegionSnapshot
          details={group}
          resetDetails={setGroup}
          type={EntityTypes.Group}
        />
        <TabbedView tabs={tabs} viewProps={componentProps} />
      </>
    ) : null;
  },
);
