import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CommentIcon, PaginationContainer } from "../../../../components";
import { formatDate, pluralizeText } from "../../../../lib";
import {
  FacilityResource,
  listActionUrls,
  listsActions,
  RequiredDocumentRecord,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import {
  GoverningBodyConfirmationBannerStyled,
  ListContainerStyled,
  SubmissionCardStyled,
} from "./RequiredDocumentSubmissionsLists.styles";
import { RequiredDocumentSubmissionDialog } from "../../../adminShared/components/resources/RequiredDocumentSubmissionDialog";
import { RequiredDocumentFrequencies } from "../../../../lib/constants";

interface RequiredDocumentSubmissionListItemProps {
  allowReview: boolean;
  document: RequiredDocumentRecord;
  onClickView: () => void;
}

function RequiredDocumentSubmissionListItem({
  allowReview,
  document,
  onClickView,
}: RequiredDocumentSubmissionListItemProps) {
  return (
    <SubmissionCardStyled key={document.id}>
      <Typography mb="8px" variant="subtitle2">
        Submitted on {formatDate(document.createdOn)}
      </Typography>
      {document.hasReviewWorkflow && (
        <Typography
          color={document.reviewedOn ? "success.dark" : "warning.dark"}
          mb="16px"
          variant="body2"
        >
          {document.reviewedOn
            ? "Reviewed and confirmed by GB Designee"
            : "Received - Pending GB Designee"}
        </Typography>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {document.hasReviewWorkflow && (
          <Typography
            display="flex"
            alignItems="center"
            color="text.secondary"
            variant="body2"
          >
            <CommentIcon sx={{ mr: "4px" }} />
            {document.commentsCount.toLocaleString()}{" "}
            {pluralizeText("comment", document.commentsCount)}
          </Typography>
        )}
        <Button
          color="primary"
          onClick={onClickView}
          size="small"
          sx={{ minWidth: 0 }}
          variant="text"
        >
          {allowReview && document.hasReviewWorkflow && !document.reviewedOn
            ? "Review"
            : "View"}
        </Button>
      </Box>
    </SubmissionCardStyled>
  );
}

interface RequiredDocumentSubmissionsListProps {
  allowReview: boolean;
  resource: FacilityResource;
}

export const RequiredDocumentSubmissionsList = React.memo(
  /**
   *
   */
  function RequiredDocumentSubmissionsList({
    allowReview,
    resource,
  }: RequiredDocumentSubmissionsListProps) {
    const dispatch = useAppDispatch();

    const facilityFilter = useSelector(sysSelectors.facilityFilter);

    const listFilter = useMemo(
      () => ({
        resourceID: resource.id,
        ...(facilityFilter ? { facilityId: facilityFilter.id } : {}),
      }),
      [facilityFilter, resource.id],
    );

    const [previewDoc, setPreviewDoc] = useState<RequiredDocumentRecord>();

    // clear list on unmount, because submissions are resource-specific and should not persist across resource views
    useEffect(() => {
      return () => {
        dispatch(listsActions.clearList(ListTypes.requiredDocumentSubmissions));
      };
    }, [dispatch]);

    return (
      <ListContainerStyled>
        <Typography variant="subtitle1" mb="24px">
          Document Submissions
        </Typography>
        <PaginationContainer
          dataUrl={listActionUrls[
            ListTypes.requiredDocumentSubmissions
          ].replace(":id", resource.id.toString())}
          defaultOrderBy="quarter"
          defaultOrderDirection="desc"
          filter={listFilter}
          groupingOptions={
            resource.requiredFrequency
              ? {
                  getGroup: (row: RequiredDocumentRecord) =>
                    resource.requiredFrequency ===
                    RequiredDocumentFrequencies.Annually
                      ? `${row.year}`
                      : `${row.quarter} ${row.year}`,
                  renderGroupHeader: (
                    row: RequiredDocumentRecord,
                    groupIndex,
                  ) => (
                    <Box mb="16px">
                      <Typography
                        variant="subtitle2"
                        marginTop={groupIndex ? "20px" : "0px"}
                      >
                        {resource.requiredFrequency ===
                        RequiredDocumentFrequencies.Annually
                          ? `Year ${row.year}`
                          : `Quarter ${row.quarter} ${row.year}`}
                      </Typography>
                      {resource.hasReviewWorkflow && (
                        <GoverningBodyConfirmationBannerStyled
                          confirmed={row.governingBodyConfirmed}
                          updated={row.governingBodyUpdated}
                        >
                          {row.governingBodyConfirmed
                            ? `GB confirmed by ${row.governingBodyReviewedBy}`
                            : row.governingBodyUpdated
                            ? `GB reviewed and updated by ${row.governingBodyReviewedBy}`
                            : "GB not confirmed"}
                        </GoverningBodyConfirmationBannerStyled>
                      )}
                    </Box>
                  ),
                }
              : undefined
          }
          hidePaginationForMinRows={true}
          name="submissions"
          renderResult={(row: RequiredDocumentRecord) => (
            <RequiredDocumentSubmissionListItem
              allowReview={allowReview}
              key={row.id}
              document={row}
              onClickView={() => setPreviewDoc(row)}
            />
          )}
          sx={{ paddingRight: "16px" }}
          type={ListTypes.requiredDocumentSubmissions}
        />
        {previewDoc && (
          <RequiredDocumentSubmissionDialog
            allowReview={allowReview}
            document={previewDoc}
            handleClose={() => setPreviewDoc(undefined)}
            refreshSubmissions={true}
          />
        )}
      </ListContainerStyled>
    );
  },
);
