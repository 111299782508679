import { Box, BoxProps, Drawer, List, ListItemButton } from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";

export const drawerWidth = 248;
export const collapsedDrawerWidth = 80;

// Drawer styled components
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: collapsedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

export const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open
    ? {
        "& .MuiDrawer-paper": openedMixin(theme),
      }
    : {
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
}));

interface DrawerHeaderStyledProps extends BoxProps {
  open: boolean;
}

export const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerHeaderStyledProps>(({ open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "space-between" : "center",
  margin: "20px 16px 16px",
}));

// Drawer Menu styled components
export const MenuContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
}));

export const MenuListStyled = styled(List)(() => ({
  width: "100%",
  padding: "0 12px",
}));

export const MenuItemStyled = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "3px",
  padding: "8px 0",
  marginBottom: "8px",
  justifyContent: "center",
  height: "46px",
  "& svg": {
    color: theme.palette.text.primary,
  },
  "&.Mui-selected svg": {
    color: theme.palette.primary.main,
  },
}));
