import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AuthCardStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: 280,
  position: "relative",
  "& form": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& .MuiFormControl-root": {
      paddingBottom: "16px",
    },
    "& .MuiFormControlLabel-label": {
      whiteSpace: "nowrap",
    },
  },
}));

export const TitleTextStyled = styled(Typography)(() => ({
  marginBottom: 12,
}));

export const SubtitleTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 24,
}));

export const SubtitleTextContainerStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 24,
}));

export const ButtonBoxStyled = styled(Box)(() => ({
  marginTop: 24,
  "& button": {
    width: "100%",
  },
}));

export const ForgotPassBoxStyled = styled(Box)(() => ({
  marginTop: 16,
  textAlign: "right",
}));

export const BackToLoginBoxStyled = styled(Box)(() => ({
  marginTop: 16,
  textAlign: "center",
}));

export const PasswordCritieriaBoxStyled = styled(Box)(() => ({
  marginTop: 8,
  "& p": {
    fontSize: 14,
    marginTop: 4,
  },
}));
