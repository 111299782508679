import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import {
  AuditCorrectiveAction,
  sharedActions,
  useAppDispatch,
} from "../../../../state";
import { useFormik } from "formik";
import {
  DateField,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  HtmlSanitizedText,
  TextField,
} from "../../../../components";
import {
  Form,
  formatDate,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  RouterLink,
} from "../../../../lib";
import * as yup from "yup";
import {
  CorrectiveActionContainerStyled,
  CorrectiveActionFooterStyled,
  CorrectiveActionHeaderStyled,
} from "./AuditCap.styles";
import { FacilityAdminPages } from "../../../facilityAdmin";

const validationSchema = yup.object({
  issueDescription: yup
    .string()
    .max(2000, "Issue description cannot exceed 2000 characters")
    .required("Issue description is required"),
  implementationDescription: yup
    .string()
    .max(2000, "Implementation description cannot exceed 2000 characters")
    .required("Implementation description is required"),
  dateOfImplementation: yup
    .date()
    .required("Date of implementation is required"),
  responsiblePartyName: yup
    .string()
    .max(50, "Name of responsible party cannot exceed 50 characters")
    .required("Name of responsible party is required"),
  responsiblePartyPosition: yup
    .string()
    .max(50, "Position of responsible party cannot exceed 50 characters")
    .required("Position of responsible party is required"),
});

interface AuditCorrectiveActionFormProps {
  auditId: number;
  correctiveAction: AuditCorrectiveAction;
  onChangeUnsavedChanges: (changes: boolean) => void;
  onSubmission: () => void;
  readOnly?: boolean;
  refreshCorrectiveActions: () => void;
}

export const AuditCorrectiveActionForm = React.memo(
  /**
   *
   */
  function AuditCorrectiveActionForm({
    auditId,
    correctiveAction,
    onChangeUnsavedChanges,
    onSubmission,
    readOnly,
    refreshCorrectiveActions,
  }: AuditCorrectiveActionFormProps) {
    const dispatch = useAppDispatch();

    const [readMode, setReadMode] = useState(
      readOnly || !!correctiveAction.submittedOn,
    );

    const [initialValues, setInitialValues] = useState<AuditCorrectiveAction>(
      replaceNullProps(correctiveAction),
    );

    const onSubmit = useCallback(
      async (values: AuditCorrectiveAction) => {
        const savedCorrectiveAction = await dispatch(
          sharedActions.submitAuditCorrectiveAction(
            auditId,
            replaceEmptyProps(values),
          ),
        );
        if (savedCorrectiveAction) {
          refreshCorrectiveActions();
          onSubmission();

          setInitialValues(replaceNullProps(savedCorrectiveAction));
          setReadMode(true);
        }
      },
      [auditId, dispatch, onSubmission, refreshCorrectiveActions],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    const onCancel = useCallback(() => {
      form.resetForm();
      if (correctiveAction.submittedOn) {
        setReadMode(true);
      }
    }, [correctiveAction.submittedOn, form]);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    useEffect(() => {
      if (form.dirty !== hasUnsavedChanges) {
        setHasUnsavedChanges(form.dirty);
        onChangeUnsavedChanges(form.dirty);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.dirty]);

    return (
      <CorrectiveActionContainerStyled
        key={correctiveAction.id}
        id={correctiveAction.id?.toString()}
      >
        <CorrectiveActionHeaderStyled>
          <Typography variant="h6">
            Corrective Action: {correctiveAction.commentQuestionNumber}
          </Typography>
          {correctiveAction.auditCommentID && (
            <Box mt={1}>
              <Typography
                component="span"
                variant="body2"
                color="text.seconary"
                mr="4px"
              >
                Related to
              </Typography>
              <Link
                component={RouterLink}
                to={Navigation.url(FacilityAdminPages.audit, {
                  params: { id: auditId },
                  query: { focusedCommentId: correctiveAction.auditCommentID },
                })}
                mr="4px"
              >
                {correctiveAction.commentQuestionNumber}:
              </Link>
              <Typography component="span" variant="subtitle2">
                {correctiveAction.commentRelatedText}
              </Typography>
            </Box>
          )}
          <HtmlSanitizedText
            fontStyle="italic"
            mt={1}
            text={correctiveAction.commentText}
          />
        </CorrectiveActionHeaderStyled>
        {readMode ? (
          <>
            <Typography variant="h6" mb={1}>
              Issue summary
            </Typography>
            <Typography variant="body2">
              {correctiveAction.issueDescription}
            </Typography>
            <Typography variant="h6" mt={2} mb={1}>
              Corrective action
            </Typography>
            <Typography variant="caption" color="text.secondary">
              To be implemented by {correctiveAction.responsiblePartyName} on{" "}
              {formatDate(correctiveAction.dateOfImplementation)} • Submitted by{" "}
              {correctiveAction.submittedByName} on{" "}
              {formatDate(correctiveAction.submittedOn)}
            </Typography>
            <Typography variant="body2" mt={1}>
              {correctiveAction.implementationDescription}
            </Typography>
            {!readOnly && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: "24px" }}
                onClick={() => setReadMode(false)}
              >
                Update
              </Button>
            )}
          </>
        ) : (
          <Form form={form} noPrompt={true}>
            <FormGridContainerStyled container>
              <FormGridItemFullWidthStyled item>
                <TextField
                  label="Please describe the issue"
                  name="issueDescription"
                  multiline={true}
                  rows={4}
                />
              </FormGridItemFullWidthStyled>
              <FormGridItemFullWidthStyled item>
                <TextField
                  label="What corrective action will be taken?"
                  name="implementationDescription"
                  multiline={true}
                  rows={4}
                />
              </FormGridItemFullWidthStyled>
              <FormGridItemStyled item>
                <TextField
                  label="Name of responsible party"
                  name="responsiblePartyName"
                />
              </FormGridItemStyled>
              <FormGridItemStyled item>
                <TextField
                  label="Position of responsible party"
                  name="responsiblePartyPosition"
                />
              </FormGridItemStyled>
              <FormGridItemStyled item>
                <DateField
                  dateOnly={true}
                  name="dateOfImplementation"
                  label="Date of implementation"
                />
              </FormGridItemStyled>
              <CorrectiveActionFooterStyled>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  {correctiveAction.submittedOn ? "Save" : "Complete"}
                </Button>
                {(form.dirty || correctiveAction.submittedOn) && (
                  <Button
                    variant="outlined"
                    size="large"
                    disabled={form.isSubmitting}
                    onClick={onCancel}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                )}
              </CorrectiveActionFooterStyled>
            </FormGridContainerStyled>
          </Form>
        )}
      </CorrectiveActionContainerStyled>
    );
  },
);
