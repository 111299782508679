import React from "react";
import { TextField } from "./TextField";
import { FieldAttributes, useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDate } from "../../lib";

interface DateFieldProps extends FieldAttributes<any> {
  dateOnly?: boolean;
  disableWeekends?: boolean;
}

export const DateField = React.memo(
  /**
   *
   */
  function DateField({
    dateOnly,
    disableWeekends,
    label,
    name,
    ...rest
  }: DateFieldProps) {
    const [field, meta, helpers] = useField(name);
    const { onChange: _, ...fieldRest } = field; // discard onChange because DatePicker change is handled properly via onChange

    const error = meta.touched ? meta.error : "";

    return (
      <DatePicker
        ignoreInvalidInputs={true}
        onChange={(value) =>
          // returning date-only values in mm/dd/yyyy format to eliminate timezone specification
          helpers.setValue(dateOnly ? formatDate(value) : value)
        }
        value={field.value}
        renderInput={(params) => (
          <TextField
            {...fieldRest}
            {...rest}
            {...params}
            error={!!error}
            inputProps={{
              ...params.inputProps,
              placeholder: label,
            }}
            onChange={(e) => {
              // setValue when field is manually cleared
              if (!e.target.value) {
                helpers.setValue("");
              }
            }}
          />
        )}
        shouldDisableDate={
          disableWeekends
            ? (date) => {
                const dayOfWeek = new Date(date).getDay();
                return dayOfWeek === 0 || dayOfWeek === 6;
              }
            : undefined
        }
      />
    );
  },
);
