import React, { useCallback } from "react";
import {
  adminActions,
  Audit,
  AuditVersion,
  NameValuePair,
  sharedActions,
  useAppDispatch,
} from "../../../../../state";
import { AuditFormViewer } from "../../../../adminShared/components";

interface AuditViewProps {
  audit: Audit;
  auditVersion?: AuditVersion;
  refreshAudit: () => Promise<void>;
  setAudit: React.Dispatch<React.SetStateAction<Audit | undefined>>;
}

export const AuditView = React.memo(
  /**
   *
   */
  function AuditView({
    audit,
    auditVersion,
    refreshAudit,
    setAudit,
  }: AuditViewProps) {
    const dispatch = useAppDispatch();

    const onSaveAudit = useCallback(
      async (annotations: string, fields: NameValuePair[]) => {
        const versionForSubmission = {
          ...auditVersion,
          auditUrl: audit.url,
          annotations,
          fields,
        };

        const saved = await dispatch(
          sharedActions.submitAuditVersion(audit.id, versionForSubmission),
        );

        if (saved) {
          await refreshAudit();
        }

        return !!saved;
      },
      [audit.id, audit.url, auditVersion, dispatch, refreshAudit],
    );

    const onSaveAuditCommentAnnotations = useCallback(
      async (annotations: string) => {
        if (!auditVersion?.id) return false; // comments cannot be added before an audit version has been saved

        const saved = await dispatch(
          adminActions.submitAuditVersionAnnotations(
            audit.id,
            auditVersion.id,
            annotations,
          ),
        );

        if (saved) {
          await refreshAudit();
        }

        return !!saved;
      },
      [audit.id, auditVersion?.id, dispatch, refreshAudit],
    );

    return (
      <AuditFormViewer
        audit={audit}
        auditVersion={auditVersion}
        key={audit.id}
        onSaveAudit={onSaveAudit}
        onSaveAuditCommentAnnotations={onSaveAuditCommentAnnotations}
        setAudit={setAudit}
      />
    );
  },
);
