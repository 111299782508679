import React from "react";
import { TabbedView } from "../../components";
import { ResourcesPageTabs } from "../../lib/constants";
import { ResourcesList } from "./components";

const tabs = [
  { id: ResourcesPageTabs.Tools, name: "Tools", component: ResourcesList },
  {
    id: ResourcesPageTabs.RequiredDocuments,
    name: "Required Documents",
    component: ResourcesList,
  },
];

export const ResourcesPage = React.memo(
  /**
   *
   */
  function ResourcesPage() {
    return <TabbedView tabs={tabs} />;
  },
);
