import React from "react";
import { Typography } from "@mui/material";
import { LongTooltip } from "../tooltips";

export interface AbbreviatedListDisplayProps {
  items: { name: string }[];
}

export const AbbreviatedListDisplay = React.memo(
  /**
   *
   */
  function AbbreviatedListDisplay({ items }: AbbreviatedListDisplayProps) {
    const itemNames = items.map((i) => i.name).sort();
    return (
      <>
        <Typography variant="body2">{itemNames[0]}</Typography>
        {items.length > 1 && (
          <LongTooltip title={itemNames.slice(1, items.length).join(", ")}>
            <Typography
              variant="caption"
              ml={1}
              color="text.secondary"
              overflow="initial !important"
            >
              + {items.length - 1}
            </Typography>
          </LongTooltip>
        )}
      </>
    );
  },
);
