import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabbedView } from "../../components";
import { useLocation, usePageTitle, WebViewerContextProvider } from "../../lib";
import { AuditPageTabs } from "../../lib/constants";
import {
  Audit,
  AuditVersion,
  sharedActions,
  useAppDispatch,
} from "../../state";
import { AuditActions, AuditActivityList, AuditView } from "./components";
import { AuditSubheader } from "../adminShared/components";
import { getAuditStatusColor } from "../adminShared/components/audits/AuditHelpers";

const tabs = [
  {
    id: AuditPageTabs.Audit,
    name: "Audit",
    component: AuditView,
  },
  {
    id: AuditPageTabs.Activity,
    name: "Activity",
    component: AuditActivityList,
  },
];

export const AuditPage = React.memo(
  /**
   *
   */
  function AuditPage() {
    const dispatch = useAppDispatch();

    const setPageTitle = usePageTitle();

    const location = useLocation();
    const params = useParams();

    const [audit, setAudit] = useState<Audit>();
    const [auditVersion, setAuditVersion] = useState<AuditVersion>();
    const [auditLoaded, setAuditLoaded] = useState(false);

    const retrieveAudit = useCallback(async () => {
      if (params.id) {
        let _audit: Audit | null = null,
          _auditVersion: AuditVersion | null = null;

        _audit = await dispatch(sharedActions.getAudit(Number(params.id)));

        if (_audit) {
          // set page title
          setPageTitle({
            title: _audit.name,
            status: _audit.statusDisplay,
            statusColor: getAuditStatusColor(
              _audit.status,
              _audit.statusDisplay,
              true,
            ),
            facilityId: _audit.facilityID,
          });

          // retrieve audit version
          if (_audit.versions.length) {
            // get version specified in query, fall back on first version
            const auditVersionId =
              (location.query.versionId &&
                _audit.versions.find((v) => v.id === location.query.versionId)
                  ?.id) ||
              _audit.versions[0].id;

            _auditVersion = await dispatch(
              sharedActions.getAuditVersion(_audit.id, auditVersionId),
            );
          }

          // set all at the end to ensure that state does not change until all relevant info is available
          setAudit(_audit || undefined);
          setAuditVersion(_auditVersion || undefined);
          setAuditLoaded(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id, setPageTitle]);

    useEffect(() => {
      retrieveAudit();
    }, [retrieveAudit]);

    const componentProps = useMemo(
      () => ({ audit, auditVersion, refreshAudit: retrieveAudit, setAudit }),
      [audit, auditVersion, retrieveAudit],
    );

    // Per client request we are only allowing audit versions to be opened in new tabs, switching between versions within the same screen is not an option for now
    // const onSelectVersion = useCallback(
    //   (id) => {
    //     Navigation.replace(location.pathname, { query: { versionId: id } });
    //     setAuditVersion(audit?.versions.find((v) => v.id === id));
    //   },
    //   [audit?.versions, location.pathname],
    // );

    return audit && auditLoaded ? (
      <WebViewerContextProvider>
        <AuditSubheader audit={audit} />
        <AuditActions
          audit={audit}
          auditVersion={auditVersion}
          key={audit.id}
          // onSelectVersion={onSelectVersion}
        />
        <TabbedView tabs={tabs} viewProps={componentProps} />
      </WebViewerContextProvider>
    ) : null;
  },
);
