import { createSelector } from "@reduxjs/toolkit";
import { getDuplicateValues } from "../../lib";
import {
  BasicStatuses,
  CategorizedClaims,
  FacilityStatuses,
  UserCategories,
} from "../../lib/constants";
import { AppState } from "../types";

const formatOptionNameByStatus = (name: string, status: string) =>
  status === BasicStatuses.Inactive ? `${name} [Inactive]` : name;

const _allGroups = createSelector(
  (state: AppState) => state.sys.allGroups,
  (allGroups) => {
    if (!allGroups) return [];

    const duplicateNames = getDuplicateValues(allGroups.map((g) => g.name));
    return allGroups.map((g) => ({
      ...g,
      name: `${formatOptionNameByStatus(g.name, g.status)}${
        duplicateNames.includes(g.name) ? ` (${g.id})` : "" // append group id if there is a group with a matching name
      }`,
    }));
  },
);

const _allRegions = createSelector(
  (state: AppState) => state.sys.allRegions,
  (allRegions) => {
    if (!allRegions) return [];

    const duplicateNames = getDuplicateValues(allRegions.map((r) => r.name));
    return allRegions.map((r) => ({
      ...r,
      name: `${formatOptionNameByStatus(r.name, r.status)}${
        duplicateNames.includes(r.name) ? ` (${r.groupName})` : "" // append group name if there is a region with a matching name
      }`,
    }));
  },
);

const _allFacilities = createSelector(
  (state: AppState) => state.sys.allFacilities,
  (allFacilities) => {
    if (!allFacilities) return [];

    const duplicateNames = getDuplicateValues(allFacilities.map((f) => f.name));
    return allFacilities.map((f) => ({
      ...f,
      name: `${formatOptionNameByStatus(f.name, f.status)}${
        duplicateNames.includes(f.name) ? ` (${f.groupName})` : "" // append group name if there is a facility with a matching name
      }`,
    }));
  },
);

export const sysSelectors = {
  systemHydrated: createSelector(
    (state: AppState) => state.sys.hydrated,
    (hydrated) => hydrated,
  ),
  systemSettings: createSelector(
    (state: AppState) => state.sys.systemSettings,
    (systemSettings) => systemSettings,
  ),
  hasSystemSettings: createSelector(
    (state: AppState) => state.sys.systemSettings,
    (systemSettings) => !!Object.keys(systemSettings).length,
  ),
  allRoles: createSelector(
    (state: AppState) => state.sys.allRoles,
    (allRoles) => allRoles,
  ),
  hasSystemRoles: createSelector(
    (state: AppState) => state.sys.allRoles,
    (allRoles) => !!allRoles.length,
  ),
  allPositions: createSelector(
    (state: AppState) => state.sys.allPositions,
    (allPositions) => allPositions,
  ),
  hasSystemPositions: createSelector(
    (state: AppState) => state.sys.allPositions,
    (allPositions) => !!allPositions.length,
  ),
  categorizedPermissionClaims: createSelector(
    (state: AppState) => state.sys.systemSettings.permissionClaims,
    (permissionClaims) => {
      const categorizedPermissionClaims = {};

      if (!permissionClaims) {
        return categorizedPermissionClaims;
      }

      Object.keys(CategorizedClaims).forEach((category) => {
        categorizedPermissionClaims[category] = CategorizedClaims[category]
          .map(({ claimValue, displayName }) => {
            // get system claim values for each claim
            const permissionClaim = permissionClaims.find(
              (c) => c.claimValue === claimValue,
            );
            return { ...permissionClaim, displayName };
          })
          .filter((c) => c.id);
      });

      return categorizedPermissionClaims;
    },
  ),
  // policies, resource categories, and audit templates for system dropdowns
  allPolicies: createSelector(
    (state: AppState) => state.sys.allPolicies,
    (allPolicies) => allPolicies,
  ),
  allAuditTemplates: createSelector(
    (state: AppState) => state.sys.allAuditTemplates,
    (allAuditTemplates) => allAuditTemplates,
  ),
  policyCategories: createSelector(
    (state: AppState) => state.sys.policyCategories,
    (policyCategories) => policyCategories,
  ),
  resourceCategories: createSelector(
    (state: AppState) => state.sys.resourceCategories,
    (resourceCategories) => resourceCategories,
  ),
  facilityFileCategories: createSelector(
    (state: AppState) => state.sys.facilityFileCategories,
    (facilityFileCategories) => facilityFileCategories,
  ),
  // groups, facilities, regions for system dropdowns
  allGroups: _allGroups,
  activeGroups: createSelector(_allGroups, (allGroups) =>
    allGroups.filter((g) => g.status !== BasicStatuses.Inactive),
  ),
  allRegions: _allRegions,
  activeRegions: createSelector(_allRegions, (allRegions) =>
    allRegions.filter((r) => r.status !== BasicStatuses.Inactive),
  ),
  allFacilities: _allFacilities,
  activeFacilities: createSelector(
    [_allFacilities, (state: AppState) => state.auth.userCategory],
    (allFacilities, userCategory) => {
      const inactiveFacilityStatuses =
        userCategory === UserCategories.FacilityUser
          ? [
              FacilityStatuses.Pending,
              // for facility users also exclude facilities in pending status (which are included in allFacilities for users with pending facility access)
              // inactive and review will be auto-filtered by the api, but adding for an extra layer of security
              FacilityStatuses.Inactive,
              FacilityStatuses.Review,
            ]
          : [FacilityStatuses.Inactive];
      return allFacilities.filter(
        (f) => !inactiveFacilityStatuses.includes(f.status),
      );
    },
  ),
  pendingFacilities: createSelector(_allFacilities, (allFacilities) =>
    allFacilities.filter((f) => f.status === FacilityStatuses.Pending),
  ),
  // selected facility for facility users with access to multiple facilities who can filter their system to a specific facility
  facilityFilter: createSelector(
    (state: AppState) => state.sys.facilityFilter,
    (facilityFilter) => facilityFilter,
  ),
  // selected group for facility users with access to multiple groups who can filter their system to a specific group (currently available for facility files module only)
  groupFilter: createSelector(
    (state: AppState) => state.sys.groupFilter,
    (groupFilter) => groupFilter,
  ),
  // tracks whether user was alerted regarding pending facilities awaiting their attention (via redirect to facility profile page)
  pendingFacilitiesAlerted: createSelector(
    (state: AppState) => state.sys.pendingFacilitiesAlerted,
    (pendingFacilitiesAlerted) => pendingFacilitiesAlerted,
  ),
};
