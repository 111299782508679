import { Box, BoxProps, List, Typography } from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";

//Drawer styled components
const openedMixin = (theme: Theme): CSSObject => ({
  width: "316px",
  minWidth: "316px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: "56px",
  minWidth: "56px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

interface PoliciesMenuStyledProps extends BoxProps {
  open: boolean;
}

export const PoliciesMenuContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<PoliciesMenuStyledProps>(({ theme, open }) => ({
  height: "100%",
  position: "relative",
  marginRight: "16px",
  overflow: "hidden",
  ...(open
    ? {
        ...openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
      }),
  "& > button": {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "50%",
    height: "32px",
    width: "32px",
    minWidth: "32px",
    position: "absolute",
    right: 0,
    top: "8px",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  "& > div": {
    display: "flex",
    flexDirection: "column",
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%",
    width: open ? "300px" : "40px",
    maxWidth: open ? "300px" : "40px",
  },
}));

export const PoliciesMenuTitleStyled = styled(Typography)(() => ({
  padding: "16px 0 8px",
  fontWeight: "500",
}));

export const PoliciesMenuListStyled = styled(List)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  padding: "0px 16px",
  height: "100%",
  overflowY: "auto",
}));
