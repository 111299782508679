import React from "react";
import { RegionsList } from "./components";

export const RegionsPage = React.memo(
  /**
   *
   */
  function RegionsPage() {
    return <RegionsList />;
  },
);
