import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  adminActions,
  IdNamePair,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { Form, listFormat, pluralizeText, useFormik } from "../../../../lib";
import * as yup from "yup";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DateField,
  DeleteIcon,
  DialogListItemStyled,
  FullWidthFormGridItemStyled,
  SelectField,
} from "../../../../components";

const validationSchema = yup.object({
  facilityIDs: yup.array().min(1, "Facilities are required"),
  auditTemplateIDs: yup.array().min(1, "Audits are required"),
  dueDate: yup.date().required("Due date is required"),
});

interface SendAuditsDialogProps {
  facilities: IdNamePair[];
  handleClose: (clearSelection: boolean) => void;
}

export const SendAuditsDialog = React.memo(
  /**
   *
   */
  function SendAuditsDialog({
    facilities,
    handleClose,
  }: SendAuditsDialogProps) {
    const dispatch = useAppDispatch();

    const [initialValues] = useState({
      facilityIDs: facilities.map((f) => f.id),
      auditTemplateIDs: [] as number[],
      dueDate: "",
    });

    const auditTemplates = useSelector(sysSelectors.allAuditTemplates);

    const [sendSuccessMessage, setSendSuccessMessage] = useState("");

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      async onSubmit(values) {
        const sent = await dispatch(adminActions.sendAudits(values));
        if (sent) {
          const auditNames = listFormat(
            auditTemplates
              .filter((t) => values.auditTemplateIDs.includes(t.id))
              .map((t) => t.name),
          );
          const facilityNames = listFormat(
            facilities
              .filter((f) => values.facilityIDs.includes(f.id))
              .map((f) => f.name),
          );
          setSendSuccessMessage(
            `${auditNames} ${
              values.auditTemplateIDs.length > 1 ? "were" : "was"
            } sent to ${facilityNames}.`,
          );
        }
      },
    });

    return sendSuccessMessage ? (
      <ConfirmationDialog
        confirmText="Back to audits"
        handleConfirm={() => handleClose(true)}
        hideCancel={true}
        message={sendSuccessMessage}
        open={true}
        title={`${pluralizeText(
          "Audit",
          form.values.auditTemplateIDs.length,
        )} sent`}
        type={ConfirmationDialogTypes.EmailSent}
      />
    ) : (
      <Dialog open={true} PaperProps={{ sx: { width: "520px" } }}>
        <Form form={form} promptCancelText="Back to Send audits">
          <DialogTitle variant="h6">
            Send audits
            <Typography variant="body2" mt={2}>
              Send audits to the following facilities
            </Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {facilities
                .filter(({ id }) => form.values.facilityIDs.includes(id))
                .map((facility) => (
                  <DialogListItemStyled key={facility.id}>
                    <Typography>{facility.name}</Typography>
                    <IconButton
                      onClick={() =>
                        form.setFieldValue(
                          "facilityIDs",
                          form.values.facilityIDs.filter(
                            (id) => id !== facility.id,
                          ),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </DialogListItemStyled>
                ))}
            </List>
            <Grid container mt={2}>
              <FullWidthFormGridItemStyled item>
                <SelectField
                  multiple={true}
                  name="auditTemplateIDs"
                  label="Select audits"
                  options={auditTemplates}
                />
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled item>
                <DateField
                  dateOnly={true}
                  disableWeekends={true}
                  name="dueDate"
                  label="Due date"
                />
              </FullWidthFormGridItemStyled>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={() => handleClose(false)}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={form.isSubmitting}
              type="submit"
            >
              Send
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
