import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "./types";

const useAppDispatch: () => AppThunkDispatch = useDispatch;

/** Creates a callback function to dispatch the given action. */
function useAction(action: any) {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => dispatch(action(...args)),
    [dispatch, action],
  );
}

export { useAction, useAppDispatch };
