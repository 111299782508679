import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Tooltip, Typography } from "@mui/material";
import { ListTypes } from "../../../../../state/lists/state";
import {
  CheckboxInput,
  FacilityTypeIconStyled,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  SelectInput,
} from "../../../../../components";
import {
  asArray,
  convertEntityFilter,
  formatAddress,
  Navigation,
} from "../../../../../lib";
import { FilterListIcon } from "../../../../../components";
import {
  HeaderContainerStyled,
  StatusChipStyled,
} from "./GroupsFacilitiesRegionsLists.styles";
import { GroupsFacilitiesRegionsDashboard } from "../general/GroupsFacilitiesRegionsDashboard";
import { AddGroupFacilityRegionMenu } from "../general/AddGroupFacilityRegionMenu";
import { AdminPages } from "../../..";
import { EntityFilter } from "../../../../../lib/types";
import {
  FacilityRecord,
  sysSelectors,
  useSelector,
} from "../../../../../state";
import { FacilityStatuses } from "../../../../../lib/constants";

const filterQueryKey = {
  groupIDs: "groups",
  regionIDs: "regions",
  includeInactive: "showInactive",
  statuses: "statuses",
  types: "types",
  text: "search",
};

interface FacilitiesListActionsProps extends ListActionsProps {
  filter?: EntityFilter;
  listType: string;
}

function FacilitiesListActions({
  filter,
  onFilterChange = () => {},
  listType,
  query,
}: FacilitiesListActionsProps) {
  const {
    facilityStatuses: facilityStatusOptions = [],
    facilityTypes: facilityTypeOptions = [],
  } = useSelector(sysSelectors.systemSettings);
  const groupOptions = useSelector(sysSelectors.activeGroups);
  const regionOptions = useSelector(sysSelectors.activeRegions);

  const {
    groups = [],
    regions = [],
    showInactive = false,
    statuses = [],
    search = "",
    types = [],
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(
      asArray(groups).length ||
      asArray(regions).length ||
      asArray(statuses).length ||
      asArray(types).length
    ),
  );

  const isSublist = listType === ListTypes.relatedFacilities;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        {isSublist ? (
          <Typography variant="h5">Facilities</Typography>
        ) : (
          <SearchInput
            variant="outlined"
            placeholder="Search facilities"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
          />
        )}
        <Box display="flex" alignItems="center">
          {!filter?.includeInactive && (
            <CheckboxInput
              checked={!!showInactive}
              label="Show inactive"
              name="showInactive"
              onChange={onFilterChange}
            />
          )}
          {!isSublist && (
            <Button
              variant="text"
              color="primary"
              size="large"
              sx={{ ml: 1 }}
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              Filter <FilterListIcon sx={{ ml: 1 }} />
            </Button>
          )}
          {listType === ListTypes.relatedFacilities && (
            <AddGroupFacilityRegionMenu filter={filter} sx={{ ml: 1 }} />
          )}
        </Box>
      </ListActionsMainStyled>
      {!isSublist && (
        <Collapse in={filtersOpen}>
          <ListActionsExpandedStyled>
            <SelectInput
              label="Group"
              name="groups"
              value={asArray(groups)}
              onChange={onFilterChange}
              options={groupOptions}
              multiple={true}
              sx={{ mr: 2 }}
            />
            <SelectInput
              label="Region"
              name="regions"
              value={asArray(regions)}
              onChange={onFilterChange}
              options={regionOptions}
              groupOpts={true}
              multiple={true}
              sx={{ mr: 2 }}
            />
            <SelectInput
              label="Status"
              name="statuses"
              value={asArray(statuses)}
              onChange={onFilterChange}
              options={
                showInactive
                  ? facilityStatusOptions
                  : facilityStatusOptions.filter(
                      (s) => s.id !== FacilityStatuses.Inactive,
                    )
              }
              multiple={true}
              sx={{ mr: 2 }}
            />
            <SelectInput
              label="Type"
              name="types"
              value={asArray(types)}
              onChange={onFilterChange}
              options={facilityTypeOptions}
              multiple={true}
            />
          </ListActionsExpandedStyled>
        </Collapse>
      )}
    </ListActionsContainerStyled>
  );
}

const facilitiesListColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Facility",
    flex: 2,
    renderCell: ({ row }: { row: FacilityRecord }) => (
      <Box display="flex">
        <Tooltip title={row.typeDisplay}>
          <FacilityTypeIconStyled type={row.type} />
        </Tooltip>
        <Typography ml="10px" variant="body2">
          {row.name}
        </Typography>
      </Box>
    ),
  },
  {
    field: "regionName",
    headerName: "Region",
    flex: 2,
  },
  {
    field: "groupName",
    headerName: "Group",
    flex: 2,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
    renderCell: ({ row }: { row: FacilityRecord }) => (
      <Typography variant="body2" whiteSpace="pre">
        {formatAddress(row, "\n")}
      </Typography>
    ),
    sortable: false,
  },
  // Note: we are hiding number of beds from the UI for now
  // {
  //   field: "numberOfBeds",
  //   headerName: "Beds",
  //   flex: 1,
  //   valueFormatter: ({ value }) => value?.toLocaleString(),
  // },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }: { row: FacilityRecord }) =>
      row.status && (
        <StatusChipStyled
          label={row.statusDisplay}
          size="small"
          status={row.status}
        />
      ),
  },
];

interface FacilitiesListProps {
  filter?: EntityFilter;
  listType?: string;
  noRowsDisplay?: string | JSX.Element;
}

export const FacilitiesList = React.memo(
  /**
   *
   */
  function FacilitiesList({
    filter,
    listType = ListTypes.facilities,
    noRowsDisplay,
  }: FacilitiesListProps) {
    const listFilter = useMemo(
      () => (filter ? convertEntityFilter(filter) : undefined),
      [filter],
    );

    const columns = useMemo(() => {
      switch (listType) {
        case ListTypes.relatedFacilities:
          return facilitiesListColumns.filter((c) => c.field !== "groupName");
        case ListTypes.facilities:
        default:
          return facilitiesListColumns;
      }
    }, [listType]);

    const onRowClick = useCallback(({ row }: { row: FacilityRecord }) => {
      Navigation.go(AdminPages.facility, { params: { id: row.id } });
    }, []);

    return (
      <>
        {listType !== ListTypes.relatedFacilities && (
          <HeaderContainerStyled>
            <GroupsFacilitiesRegionsDashboard />
            <AddGroupFacilityRegionMenu />
          </HeaderContainerStyled>
        )}
        <List
          actions={
            <FacilitiesListActions filter={filter} listType={listType} />
          }
          columns={columns}
          filter={listFilter}
          filterQueryKey={filterQueryKey}
          name="facilities"
          noRowsDisplay={noRowsDisplay}
          onRowClick={onRowClick}
          rowHeight={64}
          stickyHeader={true}
          type={listType}
        />
      </>
    );
  },
);
