import React from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import {
  PolicyPreview,
  PolicyPreviewProps,
} from "../../../../shared/components";
import { CloseIcon } from "../../../../../components";

interface PolicyPreviewDialogProps extends PolicyPreviewProps {
  handleClose: () => void;
}

export const PolicyPreviewDialog = React.memo(
  /**
   *
   */
  function PolicyPreviewDialog({
    handleClose,
    ...previewProps
  }: PolicyPreviewDialogProps) {
    return (
      <Dialog
        open={true}
        PaperProps={{
          sx: { width: "80vw", maxWidth: "960px", height: "90vh" },
        }}
      >
        <Box display="flex" justifyContent="flex-end" margin="16px 16px 8px">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ padding: 0, maxHeight: "100%" }}>
          <PolicyPreview {...previewProps} />
        </DialogContent>
      </Dialog>
    );
  },
);
