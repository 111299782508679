import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  adminActions,
  BasicPolicyVersion,
  useAppDispatch,
} from "../../../../../state";
import { Form, useFormik } from "../../../../../lib";
import * as yup from "yup";
import {
  ButtonMenu,
  CheckboxField,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DialogCheckboxContainerStyled,
  FullWidthFormGridItemStyled,
  TextField,
} from "../../../../../components";

const validationSchema = yup.object({
  policyVersionIDs: yup.array().min(1, "Versions are required"),
  findText: yup.string().required("Find text is required"),
  replaceText: yup.string(),
  isCaseSensitive: yup.boolean(),
  confirmed: yup.boolean().oneOf([true], "Please confirm this action"),
});

interface BulkUpdatePolicyDialogProps {
  policyId: number;
  handleClose: () => void;
}

export const BulkUpdatePolicyDialog = React.memo(
  /**
   *
   */
  function BulkUpdatePolicyDialog({
    handleClose,
    policyId,
  }: BulkUpdatePolicyDialogProps) {
    const dispatch = useAppDispatch();

    const confirmCheckboxRef = useRef(null);

    const [initialValues, setInitialValues] = useState({
      policyVersionIDs: [] as number[],
      findText: "",
      replaceText: "",
      isCaseSensitive: true,
      confirmed: false,
    });
    const [policyVersions, setPolicyVersions] = useState<BasicPolicyVersion[]>(
      [],
    );

    const [publish, setPublish] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [notifyFacilities, setNotifyFacilities] = useState(false);

    useEffect(() => {
      (async () => {
        const versions = await dispatch(
          adminActions.getPolicyVersions(policyId),
        );
        if (versions) {
          setPolicyVersions(versions);
          // check off all versions by default
          setInitialValues((vals) => ({
            ...vals,
            policyVersionIDs: versions
              .filter((v) => !v.isPublishing)
              .map((v) => v.id),
          }));
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyId]);

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      async onSubmit(values) {
        if (publish && !showPublishDialog) {
          setShowPublishDialog(true);
          return;
        } else if (showPublishDialog) {
          setShowPublishDialog(false);
        }

        const bulkUpdated = await dispatch(
          adminActions.bulkUpdatePolicy(policyId, {
            ...values,
            publish,
            notifyFacilities,
          }),
        );
        if (bulkUpdated) {
          handleClose();
        }
      },
    });

    const onClickSubmit = useCallback(
      (withPublish) => {
        // if not confirmed, scroll confirmation checkbox into view
        if (!form.values.confirmed && confirmCheckboxRef.current) {
          (confirmCheckboxRef.current as HTMLElement).scrollIntoView({
            behavior: "smooth",
          });
        }

        setPublish(withPublish);
        form.submitForm();
      },
      [form],
    );

    const onToggleVersionId = useCallback(
      (id, checked) => {
        form.setFieldValue(
          "policyVersionIDs",
          checked
            ? [...form.values.policyVersionIDs, id]
            : form.values.policyVersionIDs.filter((i) => i !== id),
        );
      },
      [form],
    );

    return showPublishDialog ? (
      <ConfirmationDialog
        confirmText="Update and publish"
        handleClose={() => {
          setShowPublishDialog(false);
          setNotifyFacilities(false);
        }}
        handleConfirm={form.submitForm}
        open={true}
        message="This will publish the policy for facilities that are part of the selected versions."
        messageContent={
          <DialogCheckboxContainerStyled>
            <CheckboxInput
              checked={notifyFacilities}
              label="Notify facilities"
              name="notifyFacilities"
              onChange={(_, val) => setNotifyFacilities(val)}
            />
          </DialogCheckboxContainerStyled>
        }
        title="Publish policy updates?"
        type={ConfirmationDialogTypes.Warning}
      />
    ) : (
      <Dialog open={true}>
        <Form form={form} promptCancelText="Back to Bulk update">
          <DialogTitle variant="h6">Bulk update policy versions</DialogTitle>
          <DialogContent>
            <Grid container>
              <FullWidthFormGridItemStyled item>
                <Typography variant="subtitle2" mb={2}>
                  Select versions to update
                  {form.submitCount > 0 && form.errors.policyVersionIDs && (
                    <Typography variant="caption" color="error.main" ml={1}>
                      {form.errors.policyVersionIDs}
                    </Typography>
                  )}
                </Typography>
                {policyVersions.map((version) => {
                  return (
                    <Box key={version.id}>
                      <CheckboxInput
                        checked={form.values.policyVersionIDs.includes(
                          version.id as number,
                        )}
                        disabled={version.isPublishing}
                        label={
                          <Typography
                            variant="body2"
                            color={!version.isPublished ? "neutral.main" : ""}
                          >
                            {version.name}
                            {version.isPublishing
                              ? " - Publishing"
                              : !version.isPublished
                              ? " - Unpublished"
                              : ""}
                          </Typography>
                        }
                        name="policyVersionIDs"
                        onChange={(_, checked) =>
                          onToggleVersionId(version.id, checked)
                        }
                      />
                    </Box>
                  );
                })}
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={2}
              >
                <Typography variant="subtitle2">Find and replace</Typography>
                <CheckboxField name="isCaseSensitive" label="Case sensitive" />
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled item>
                <TextField name="findText" label="Find" />
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled item>
                <TextField name="replaceText" label="Replace with" />
              </FullWidthFormGridItemStyled>
              <FullWidthFormGridItemStyled
                item
                ref={confirmCheckboxRef}
                sx={{ paddingBottom: 0 }}
              >
                <CheckboxField
                  name="confirmed"
                  label="I understand that this action cannot be undone."
                />
              </FullWidthFormGridItemStyled>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={handleClose}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <ButtonMenu
              buttonText="Replace all"
              disabled={form.isSubmitting}
              menuItems={[
                {
                  label: (
                    <Box sx={{ width: "240px", whiteSpace: "pre-wrap" }}>
                      <Typography fontSize="14px">
                        Replace only in Google doc
                      </Typography>
                      <Typography fontSize="12px" color="text.secondary">
                        Changes will be saved to the Google doc but will not be
                        published.
                      </Typography>
                    </Box>
                  ),
                  onClick: () => onClickSubmit(false),
                },
                {
                  label: (
                    <Box sx={{ width: "240px", whiteSpace: "pre-wrap" }}>
                      <Typography fontSize="14px">
                        Replace and publish
                      </Typography>
                      <Typography fontSize="12px" color="text.secondary">
                        Changes will be saved to the Google doc and published
                        for all versions. Note: Unpublished versions will be
                        published.
                      </Typography>
                    </Box>
                  ),
                  onClick: () => onClickSubmit(true),
                },
              ]}
            />
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
