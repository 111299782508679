import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import {
  AbbreviatedListDisplay,
  ButtonMenu,
  CheckboxInput,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DialogCheckboxContainerStyled,
  ErrorOutlineIcon,
  ErrorTextStyled,
  FilterListIcon,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  LongTooltip,
  MoreVertIcon,
  OpenInNewIcon,
  SearchInput,
  SelectInput,
} from "../../../../../components";
import {
  asArray,
  formatDate,
  listFormat,
  Navigation,
  useLocation,
} from "../../../../../lib";
import { dateColumnSortingOrder } from "../../../../../components/lists/List";
import { ListTypes } from "../../../../../state/lists/state";
import {
  NoPolicyVersionsOverlayStyled,
  PolicyVersionStatusChipStyled,
} from "./PolicyVersions.styles";
import {
  adminActions,
  listActionUrls,
  PolicyVersionRecord,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../../state";
import { PolicyVersionDialog } from "./PolicyVersionDialog";
import { PolicyVersionPublishStatuses } from "../../../../../lib/constants";
import { BulkUpdatePolicyDialog } from "./BulkUpdatePolicyDialog";
import { PolicyPreviewDialog } from "./PolicyPreviewDialog";
import { PolicyTabProps } from "../../../PolicyPage";
import empty from "../../../../../assets/img/empty.svg";

const filterQueryKey = {
  facilityIDs: "facilities",
  publishError: "publishError",
  publishStatuses: "publishStatuses",
  stateIDs: "states",
  text: "search",
};

const ActionTypes = {
  Add: "Add",
  Delete: "Delete",
  Edit: "Edit",
  Preview: "Preview",
  Publish: "Publish",
  PublishChanges: "PublishChanges",
  PublishFailedDocs: "PublishFailedDocs",
  PublishUnpublishedDocs: "PublishUnpublishedDocs",
  Unpublish: "Unpublish",
  BulkUpdatePolicy: "BulkUpdatePolicy",
};

interface PolicyVersionsListActionsProps extends ListActionsProps {
  onActivateAction: (actionType: string, version?: PolicyVersionRecord) => void;
}

function PolicyVersionsListActions({
  onActivateAction,
  onFilterChange = () => {},
  query,
}: PolicyVersionsListActionsProps) {
  const {
    policyVersionPublishStatuses: policyVersionPublishStatusOptions = [],
    states: stateOptions,
  } = useSelector(sysSelectors.systemSettings);
  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const {
    facilities = [],
    publishError = false,
    publishStatuses = [],
    states = [],
    search = "",
  } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!(
      search ||
      publishError ||
      asArray(facilities).length ||
      asArray(publishStatuses).length ||
      asArray(states).length
    ),
  );

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h5">Versions</Typography>
        <Box>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter <FilterListIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => onActivateAction(ActionTypes.BulkUpdatePolicy)}
          >
            Bulk update
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ ml: 1 }}
            onClick={() => onActivateAction(ActionTypes.Add)}
          >
            Add version
          </Button>
        </Box>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SearchInput
            variant="outlined"
            placeholder="Search versions"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="State"
            name="states"
            value={asArray(states)}
            onChange={onFilterChange}
            options={stateOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Facility"
            name="facilities"
            value={asArray(facilities)}
            onChange={onFilterChange}
            options={facilityOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <SelectInput
            label="Publish status"
            name="publishStatuses"
            value={asArray(publishStatuses)}
            onChange={onFilterChange}
            options={policyVersionPublishStatusOptions}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!publishError}
            label="Publish errors"
            name="publishError"
            onChange={onFilterChange}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

const PolicyVersionTooltips = ({
  policyVersion,
}: {
  policyVersion: PolicyVersionRecord;
}) => {
  const {
    publishFailedDocsCount,
    publishFailedFacilities,
    publishNeededDocsCount,
    publishNeededFacilities,
    publishStatus,
  } = policyVersion;

  if (publishStatus === PolicyVersionPublishStatuses.Publishing) return null;

  const toolTips = [] as { title: string; color: string }[];

  if (publishFailedDocsCount) {
    toolTips.push({
      title: `A publishing error occurred. This policy was not updated for ${listFormat(
        publishFailedFacilities.map((f) => f.name),
      )}.`,
      color: "error.main",
    });
  }
  if (publishNeededDocsCount) {
    toolTips.push({
      title: `This policy was not published for ${listFormat(
        publishNeededFacilities.map((f) => f.name),
      )}`,
      color: "error.main",
    });
  }

  return (
    <>
      {toolTips.map((toolTip, index) => (
        <LongTooltip
          key={index}
          title={toolTip.title}
          sx={{ ml: 1, color: toolTip.color }}
        >
          <ErrorOutlineIcon fontSize="small" />
        </LongTooltip>
      ))}
    </>
  );
};

export const PolicyVersions = React.memo(
  /**
   *
   */
  function PolicyVersions({ policy, refreshPolicy }: PolicyTabProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [activeActionType, setActiveActionType] = useState<string>();
    const [activePolicyVersion, setActivePolicyVersion] =
      useState<PolicyVersionRecord>();
    const [notifyFacilities, setNotifyFacilities] = useState(false);

    const onActivateAction = useCallback(
      (actionType: string, version?: PolicyVersionRecord) => {
        setActiveActionType(actionType);
        setActivePolicyVersion(version);
      },
      [],
    );

    const onClearAction = useCallback(() => {
      setActiveActionType(undefined);
      setActivePolicyVersion(undefined);
      setNotifyFacilities(false);
    }, []);

    const onConfirmAction = useCallback(async () => {
      if (!activePolicyVersion) return;

      let actionSucceeded = false;
      switch (activeActionType) {
        case ActionTypes.Delete:
          actionSucceeded = await dispatch(
            adminActions.deletePolicyVersion(
              Number(policy.id),
              activePolicyVersion.id,
            ),
          );
          break;
        case ActionTypes.Unpublish:
          actionSucceeded = await dispatch(
            adminActions.unpublishPolicyVersion(
              Number(policy.id),
              activePolicyVersion.id,
            ),
          );
          break;
        case ActionTypes.Publish:
        case ActionTypes.PublishChanges:
        case ActionTypes.PublishFailedDocs:
        case ActionTypes.PublishUnpublishedDocs:
          actionSucceeded = await dispatch(
            adminActions.publishPolicyVersion(
              Number(policy.id),
              activePolicyVersion.id,
              {
                publishChanges: activeActionType === ActionTypes.PublishChanges,
                facilityIds:
                  activeActionType === ActionTypes.PublishFailedDocs
                    ? activePolicyVersion.publishFailedFacilities.map(
                        (f) => f.id,
                      )
                    : activeActionType === ActionTypes.PublishUnpublishedDocs
                    ? activePolicyVersion.publishNeededFacilities.map(
                        (f) => f.id,
                      )
                    : undefined,
                notifyFacilities,
              },
            ),
          );
          break;
      }
      if (actionSucceeded) {
        onClearAction();
        // refresh policy to ensure that policy details (status, available states/facilities, etc) are up to date
        refreshPolicy();
      }
    }, [
      activeActionType,
      activePolicyVersion,
      dispatch,
      notifyFacilities,
      onClearAction,
      policy.id,
      refreshPolicy,
    ]);

    const actionConfirmationDetails = useMemo(() => {
      const details = {
        confirmText: "",
        messageContent: undefined as undefined | JSX.Element,
        message: "" as string | JSX.Element,
        title: "",
        type: "",
      };

      if (!activeActionType) return details;

      switch (activeActionType) {
        case ActionTypes.Delete:
          details.title = "Delete policy version?";
          details.message = "This cannot be undone.";
          // when deleting a facility-specific policy version, for a policy that has state-specific versions:
          if (
            activePolicyVersion?.facilities.length &&
            policy.stateIDs.length
          ) {
            details.message += `\n\nIf there ${
              activePolicyVersion.facilities.length === 1
                ? `is a state specific policy version applicable for ${activePolicyVersion.facilities[0].name}, republish the version in order to generate the policy for the facility`
                : "are state specific policy versions applicable for this version's facilities, republish the versions in order to generate the policy for the facilities"
            }.`;
          }
          details.confirmText = "Delete version";
          details.type = ConfirmationDialogTypes.Alert;
          break;
        case ActionTypes.Unpublish:
          details.title = "Unpublish policy version?";
          details.message =
            "Confirm that you'd like to unpublish this policy version.";
          if (activePolicyVersion?.facilities.length) {
            details.message += `\n\nNote that by unpublishing this policy version, it will no longer be available for applicable facilities, even if there are published state specific versions for the facilities' states. Remove the facilities from this version or delete the version to give the facilities access to this policy.`;
          }
          details.confirmText = "Unpublish version";
          details.type = ConfirmationDialogTypes.Alert;
          break;
        case ActionTypes.Publish:
        case ActionTypes.PublishChanges:
        case ActionTypes.PublishFailedDocs:
        case ActionTypes.PublishUnpublishedDocs: {
          const publishChanges =
            activeActionType === ActionTypes.PublishChanges;
          const facilitiesForPublish =
            activeActionType === ActionTypes.PublishFailedDocs
              ? activePolicyVersion?.publishFailedFacilities
              : activeActionType === ActionTypes.PublishUnpublishedDocs
              ? activePolicyVersion?.publishNeededFacilities
              : [];
          details.title = `Publish ${publishChanges ? "changes" : "version"}?`;
          details.message = `This will ${
            publishChanges
              ? "update"
              : activeActionType === ActionTypes.PublishFailedDocs
              ? "publish"
              : "activate"
          } the policy for ${
            facilitiesForPublish?.length
              ? listFormat(facilitiesForPublish.map((f) => f.name))
              : "all applicable facilities"
          }.`;
          details.messageContent = (
            <DialogCheckboxContainerStyled>
              <CheckboxInput
                checked={notifyFacilities}
                label="Notify facilities"
                name="notifyFacilities"
                onChange={(_, val) => setNotifyFacilities(val)}
              />
            </DialogCheckboxContainerStyled>
          );
          details.confirmText = `Publish${publishChanges ? " changes" : ""}`;
          details.type = ConfirmationDialogTypes.Warning;
          break;
        }
      }

      return details;
    }, [
      activeActionType,
      activePolicyVersion?.facilities,
      activePolicyVersion?.publishFailedFacilities,
      activePolicyVersion?.publishNeededFacilities,
      notifyFacilities,
      policy.stateIDs.length,
    ]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Version",
          flex: 2,
          renderCell: ({ row }: { row: PolicyVersionRecord }) => (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{row.name}</Typography>
              {row.url && (
                <IconButton
                  sx={{ color: "text.secondary", ml: 1 }}
                  href={row.url}
                  target="_blank"
                >
                  <OpenInNewIcon sx={{ height: "20px" }} />
                </IconButton>
              )}
            </Box>
          ),
        },
        {
          field: "applicable",
          headerName: "Applicable for",
          flex: 2,
          renderCell: ({ row }: { row: PolicyVersionRecord }) =>
            row.facilities.length ? (
              <AbbreviatedListDisplay items={row.facilities} />
            ) : row.states ? (
              row.states.length > 50 ? (
                "All States"
              ) : (
                <AbbreviatedListDisplay items={row.states} />
              )
            ) : (
              ""
            ),
          sortable: false,
        },
        {
          field: "lastPublishedOn",
          headerName: "Last published",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "lastUpdatedOn",
          headerName: "Last edited",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "lastUpdatedBy",
          headerName: "Last editor",
          flex: 1,
        },
        {
          field: "publishStatus",
          headerName: "Publish status",
          flex: 1.5,
          renderCell: ({ row }: { row: PolicyVersionRecord }) => (
            <>
              <PolicyVersionStatusChipStyled
                label={row.publishStatusDisplay}
                size="small"
                status={row.publishStatus}
              />
              <PolicyVersionTooltips policyVersion={row} />
            </>
          ),
        },
        {
          field: "publish-actions",
          headerName: "",
          width: 200,
          renderCell: ({ row }: { row: PolicyVersionRecord }) => (
            <Box>
              {row.publishStatus ===
              PolicyVersionPublishStatuses.Unpublished ? (
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={() => onActivateAction(ActionTypes.Publish, row)}
                >
                  Publish
                </Button>
              ) : row.publishStatus !==
                  PolicyVersionPublishStatuses.Publishing &&
                (row.publishFailedDocsCount || row.publishNeededDocsCount) ? (
                <ButtonMenu
                  buttonText="Publish"
                  buttonVariant="text"
                  menuItems={[
                    row.publishStatus ===
                    PolicyVersionPublishStatuses.UnpublishedChanges
                      ? {
                          label: "Publish changes",
                          onClick: () =>
                            onActivateAction(ActionTypes.PublishChanges, row),
                        }
                      : { label: "" },
                    row.publishFailedDocsCount
                      ? {
                          label: "Publish failed docs",
                          onClick: () =>
                            onActivateAction(
                              ActionTypes.PublishFailedDocs,
                              row,
                            ),
                        }
                      : { label: "" },
                    row.publishNeededDocsCount
                      ? {
                          label: "Publish unpublished docs",
                          onClick: () =>
                            onActivateAction(
                              ActionTypes.PublishUnpublishedDocs,
                              row,
                            ),
                        }
                      : { label: "" },
                  ].filter((i) => i.label)}
                />
              ) : row.publishStatus ===
                PolicyVersionPublishStatuses.UnpublishedChanges ? (
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={() =>
                    onActivateAction(ActionTypes.PublishChanges, row)
                  }
                >
                  Publish changes
                </Button>
              ) : (
                ""
              )}
            </Box>
          ),
          sortable: false,
        },
        {
          field: "menu-actions",
          headerName: "",
          width: 72,
          renderCell: ({ row }: { row: PolicyVersionRecord }) =>
            row.publishStatus !== PolicyVersionPublishStatuses.Publishing ? (
              <ButtonMenu
                button={
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                }
                menuItems={[
                  ...(row.publishFailedDocsCount
                    ? [
                        {
                          label: "View publish logs",
                          onClick: () => {
                            Navigation.go(location.pathname, {
                              query: { tab: "logs", versions: [row.id] },
                            });
                          },
                        },
                      ]
                    : []),
                  ...(row.publishStatus !==
                  PolicyVersionPublishStatuses.Unpublished
                    ? [
                        {
                          label: "Unpublish version",
                          onClick: () =>
                            onActivateAction(ActionTypes.Unpublish, row),
                        },
                      ]
                    : []),
                  {
                    label: "Preview template",
                    onClick: () => onActivateAction(ActionTypes.Preview, row),
                  },
                  {
                    label: "Edit version settings",
                    onClick: () => onActivateAction(ActionTypes.Edit, row),
                  },
                  {
                    label: <ErrorTextStyled>Delete version</ErrorTextStyled>,
                    onClick: () => onActivateAction(ActionTypes.Delete, row),
                  },
                ]}
              />
            ) : (
              ""
            ),
          sortable: false,
        },
      ],
      [location.pathname, onActivateAction],
    );

    return policy.id ? (
      <>
        {!policy.hasVersions ? (
          <NoPolicyVersionsOverlayStyled>
            <img alt="no-results" src={empty} height="240px" />
            <Typography variant="subtitle1" mt={4} mb={4}>
              It looks like you haven't added any versions yet
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ zIndex: 1 }}
              onClick={() => setActiveActionType(ActionTypes.Add)}
            >
              Add version
            </Button>
          </NoPolicyVersionsOverlayStyled>
        ) : (
          <List
            actions={
              <PolicyVersionsListActions onActivateAction={onActivateAction} />
            }
            dataUrl={listActionUrls[ListTypes.policyVersions].replace(
              ":id",
              policy.id.toString(),
            )}
            columns={columns}
            defaultOrderBy="publishStatus"
            filterQueryKey={filterQueryKey}
            name="versions"
            hidePaginationForMinRows={true}
            stickyHeader={true}
            type={ListTypes.policyVersions}
          />
        )}
        {activeActionType === ActionTypes.BulkUpdatePolicy ? (
          <BulkUpdatePolicyDialog
            handleClose={onClearAction}
            policyId={policy.id}
          />
        ) : activeActionType === ActionTypes.Add ||
          activeActionType === ActionTypes.Edit ? (
          <PolicyVersionDialog
            handleClose={onClearAction}
            policy={policy}
            policyVersionId={activePolicyVersion?.id}
            publishStatus={activePolicyVersion?.publishStatus}
            refreshPolicy={refreshPolicy}
          />
        ) : activeActionType === ActionTypes.Publish ||
          activeActionType === ActionTypes.PublishChanges ||
          activeActionType === ActionTypes.PublishFailedDocs ||
          activeActionType === ActionTypes.PublishUnpublishedDocs ||
          activeActionType === ActionTypes.Delete ||
          activeActionType === ActionTypes.Unpublish ? (
          <ConfirmationDialog
            confirmText={actionConfirmationDetails.confirmText}
            messageContent={actionConfirmationDetails.messageContent}
            handleClose={onClearAction}
            handleConfirm={onConfirmAction}
            message={actionConfirmationDetails.message}
            open={true}
            title={actionConfirmationDetails.title}
            type={actionConfirmationDetails.type}
          />
        ) : activeActionType === ActionTypes.Preview && activePolicyVersion ? (
          <PolicyPreviewDialog
            handleClose={onClearAction}
            policyId={policy.id}
            policyVersionId={activePolicyVersion.id}
            facilityId={null}
          />
        ) : (
          ""
        )}
      </>
    ) : null;
  },
);
