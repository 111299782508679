import { AppThunk } from "..";
import { ListTypes } from "../lists/state";
import { authClient, listsActions, uiActions } from "../states";
import { facilityAdmin } from "./state";
import {
  BasicAudit,
  BasicPolicy,
  FacilityResource,
  FacilityStaffLogin,
  FacilityTraining,
  IdNamePair,
  LatestAuditDetails,
  PolicyDocsResponse,
  RequiredDocument,
  RequiredDocumentRecord,
  TrainingAttendance,
} from "../types";

const { actions } = facilityAdmin;

export const facilityAdminActions = {
  ...actions,
  // users
  getFacilityStaffLogin(
    facilityId: number,
  ): AppThunk<Promise<FacilityStaffLogin | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/facilities/${facilityId}/staffLogin`,
      );
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            "Failed to retrieve facility staff login credentials",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  // policies
  getBasicPolicies(
    {
      facilityId,
      policyId,
    }: {
      facilityId: number;
      policyId: number;
    },
    showLoading = false, //default to false because basic policies are retrieved for a menu which would not require a loading indicator
  ): AppThunk<Promise<BasicPolicy[] | null>> {
    return async (dispatch) => {
      if (showLoading) dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/policies/basic/", {
        params: { facilityId, categoryByPolicyId: policyId },
      });
      if (showLoading) dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policies"));
        return null;
      } else {
        return data;
      }
    };
  },
  getPolicyPreview(
    policyId: number,
    facilityId: number,
  ): AppThunk<Promise<string | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/policies/${policyId}/policyDocs/view?facilityId=${facilityId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  getPolicyDocsForPrint(
    policyIds: number[],
    facilityIds: number[],
  ): AppThunk<Promise<PolicyDocsResponse | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/policies/policyDocs", {
        params: { policyIds, facilityIds },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to retrieve policies for print",
          ),
        );
        return null;
      } else {
        return data;
      }
    };
  },
  getPolicyResources(
    policyId: number,
    facilityId: number,
  ): AppThunk<Promise<IdNamePair[] | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `policies/${policyId}/resources`,
        {
          params: { facilityId },
        },
      );
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy resources"));
        return null;
      } else {
        return data;
      }
    };
  },
  getPolicyTrainings(
    policyId: number,
    facilityId: number,
  ): AppThunk<Promise<IdNamePair[] | null>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `policies/${policyId}/trainings`,
        {
          params: { facilityId },
        },
      );
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to retrieve policy trainings"));
        return null;
      } else {
        return data;
      }
    };
  },
  // resources
  getResource(
    resourceId: number,
    facilityId: number,
  ): AppThunk<Promise<FacilityResource | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/resources/${resourceId}/byFacility/${facilityId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  getResourcePreview(
    resourceId: number,
    facilityId: number,
  ): AppThunk<Promise<string | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/resources/${resourceId}/view?facilityId=${facilityId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  getRequiredDocument(
    documentId: number,
    facilityId: number,
  ): AppThunk<Promise<RequiredDocumentRecord | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/resources/requiredDocuments`,
        // retrieve a single record from the paginated required documents endpoint
        {
          params: {
            requiredDocumentId: documentId,
            facilityId,
            page: 1,
            resultsPerPage: 1,
          },
        },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data.results[0] || null;
      }
    };
  },
  submitRequiredDocument(
    values: RequiredDocument,
  ): AppThunk<Promise<RequiredDocument | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/resources/requiredDocuments`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save document",
          ),
        );
        return null;
      } else {
        dispatch(
          listsActions.refreshList(ListTypes.requiredDocumentSubmissions),
        );
        return data;
      }
    };
  },
  getGoverningBody(facilityId: number): AppThunk<Promise<IdNamePair[] | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/facilities/${facilityId}/governingBody`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  getGoverningBodyReviewed(
    facilityId: number,
  ): AppThunk<Promise<boolean | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/facilities/${facilityId}/governingBodyReviewed`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  submitGoverningBodyReview(
    facilityId: number,
    values: {
      confirmed: boolean;
      requiredUpdates: string;
    },
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/facilities/${facilityId}/governingBodyReview`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") ||
              "Failed to submit governing body review",
          ),
        );
        return false;
      } else {
        return true;
      }
    };
  },
  // trainings
  getTraining(
    trainingId: number,
    facilityId: number,
  ): AppThunk<Promise<FacilityTraining | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/trainings/${trainingId}/byFacility/${facilityId}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  submitTrainingAttendance(
    trainingId: number,
    values: TrainingAttendance,
  ): AppThunk<Promise<TrainingAttendance | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/trainings/${trainingId}/attendance`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to save training attendees",
          ),
        );
        return null;
      } else {
        dispatch(listsActions.refreshList(ListTypes.trainingAttendance));
        return data;
      }
    };
  },
  deleteTrainingAttendance(
    trainingId: number,
    id: number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete(
        `/trainings/${trainingId}/attendance/${id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to delete training attendees",
          ),
        );
        return false;
      } else {
        dispatch(uiActions.showSuccess("Training attendees deleted"));
        dispatch(listsActions.refreshList(ListTypes.trainingAttendance));
        return true;
      }
    };
  },
  // audits
  getLatestAudit(
    facilityId: number,
  ): AppThunk<Promise<LatestAuditDetails | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("audits/latest", {
        params: { facilityId },
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  getNextAudit(id: number): AppThunk<Promise<BasicAudit | null>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/audits/${id}/next`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        return null;
      } else {
        return data;
      }
    };
  },
  // announcements
  markAnnouncementRead(id: number): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/announcements/${id}/read`,
        null,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(
          uiActions.showError(
            data?.messages?.join("\n") || "Failed to mark announcement read",
          ),
        );
        return false;
      } else {
        dispatch(listsActions.refreshList(ListTypes.announcements));
        return true;
      }
    };
  },
};
