import { Option } from "./types";

export const EntityTypes = {
  Group: "Group",
  Facility: "Facility",
  Region: "Region",
};

export const FileTypes = {
  Image: "Image",
  Video: "Video",
  Pdf: "Pdf",
  Other: "Other",
};

export const ApplicableForTypes = {
  State: "State",
  Facility: "Facility",
};

export const ContactMethodTypes = {
  Email: "Email",
  MobileNumber: "MobileNumber",
};

// #region Statuses

export const BasicStatuses = {
  Active: "Active",
  Inactive: "Inactive",
};

export const BasicStatusOptions: Option[] = Object.keys(BasicStatuses).map(
  (s) => ({
    id: s,
    name: s,
  }),
);

export const FacilityStatuses = {
  ...BasicStatuses,
  Onboarding: "Onboarding",
  OnHold: "OnHold",
  Pending: "Pending",
  Review: "Review",
};

export const PolicyStatuses = {
  Draft: "Draft",
  Active: "Active",
};

export const PolicyVersionPublishStatuses = {
  Unpublished: "Unpublished",
  Publishing: "Publishing",
  Published: "Published",
  UnpublishedChanges: "UnpublishedChanges",
};

export const PolicyDocumentPublishStatuses = {
  Publishing: "Publishing",
  Published: "Published",
  PublishFailed: "PublishFailed",
};

export const UserStatuses = {
  ...BasicStatuses,
  Pending: "Pending",
};

// #endregion

// #region Users

export const UserCategories = {
  CcgAdmin: "CcgAdmin",
  FacilityUser: "FacilityUser",
};

export const FacilityUserTypes = {
  Admin: "Admin",
  Staff: "Staff",
};

export const FacilityUserLevels = {
  Group: "Group",
  Region: "Region",
  Facility: "Facility",
};

export const PortalUserTypes = {
  CcgAdmin: "CcgAdmin",
  FacilityAdmin: "FacilityAdmin",
  FacilityStaff: "FacilityStaff",
};

export const PermissionClaims = {
  CcgAdminClaim: "CcgAdminClaim",
  GoverningBodyClaim: "GoverningBodyClaim",
  FacilityStaffClaim: "FacilityStaffClaim",
  PoliciesReadClaim: "PoliciesReadClaim",
  PoliciesPrintingOverrideClaim: "PoliciesPrintingOverrideClaim",
  PoliciesNotificationsClaim: "PoliciesNotificationsClaim",
  ResourcesReadClaim: "ResourcesReadClaim",
  RequiredDocumentsClaim: "RequiredDocumentsClaim",
  RequiredDocumentsReviewClaim: "RequiredDocumentsReviewClaim",
  RequiredDocumentsNotificationsClaim: "RequiredDocumentsNotificationsClaim",
  TrainingsReadClaim: "TrainingsReadClaim",
  TrainingsAttendanceClaim: "TrainingsAttendanceClaim",
  AuditsClaim: "AuditsClaim",
  AuditsNotificationsClaim: "AuditsNotificationsClaim",
  FacilityFilesReadClaim: "FacilityFilesReadClaim",
  FacilityFilesEditClaim: "FacilityFilesEditClaim",
  FacilityFileCategoriesClaim: "FacilityFileCategoriesClaim",
  UsersReadClaim: "UsersReadClaim",
  UsersEditClaim: "UsersEditClaim",
  PendingFacilityEditClaim: "PendingFacilityEditClaim",
};

export const ClaimCategories = {
  Policies: "Policies",
  Resources: "Resources",
  Trainings: "Trainings",
  Audits: "Audits",
  FacilityFiles: "Facility Files",
  Users: "Users",
  Facility: "Facility",
  Notifications: "Notifications",
};

export const CategorizedClaims = {
  [ClaimCategories.Users]: [
    { displayName: "View", claimValue: PermissionClaims.UsersReadClaim },
    { displayName: "Edit", claimValue: PermissionClaims.UsersEditClaim },
  ],
  [ClaimCategories.Policies]: [
    { displayName: "View", claimValue: PermissionClaims.PoliciesReadClaim },
    {
      displayName: "Print Override",
      claimValue: PermissionClaims.PoliciesPrintingOverrideClaim,
    },
  ],
  [ClaimCategories.Resources]: [
    { displayName: "View", claimValue: PermissionClaims.ResourcesReadClaim },
    {
      displayName: "Submit Required Documents",
      claimValue: PermissionClaims.RequiredDocumentsClaim,
    },
    {
      displayName: "Review Required Documents",
      claimValue: PermissionClaims.RequiredDocumentsReviewClaim,
    },
  ],
  [ClaimCategories.Trainings]: [
    { displayName: "View", claimValue: PermissionClaims.TrainingsReadClaim },
    {
      displayName: "Manage Attendees",
      claimValue: PermissionClaims.TrainingsAttendanceClaim,
    },
  ],
  [ClaimCategories.Audits]: [
    {
      displayName: "View/Edit",
      claimValue: PermissionClaims.AuditsClaim,
    },
  ],
  [ClaimCategories.Facility]: [
    {
      displayName: "Edit Pending",
      claimValue: PermissionClaims.PendingFacilityEditClaim,
    },
  ],
  [ClaimCategories.FacilityFiles]: [
    {
      displayName: "View",
      claimValue: PermissionClaims.FacilityFilesReadClaim,
    },
    {
      displayName: "Edit",
      claimValue: PermissionClaims.FacilityFilesEditClaim,
    },
    {
      displayName: "Manage Categories",
      claimValue: PermissionClaims.FacilityFileCategoriesClaim,
    },
  ],
  [ClaimCategories.Notifications]: [
    {
      displayName: "Policies",
      claimValue: PermissionClaims.PoliciesNotificationsClaim,
    },
    {
      displayName: "Required Documents",
      claimValue: PermissionClaims.RequiredDocumentsNotificationsClaim,
    },
    {
      displayName: "Audits",
      claimValue: PermissionClaims.AuditsNotificationsClaim,
    },
  ],
};

// #endregion

// #region Facilities

export const FacilityTypes = {
  SNF: "SNF",
  ALF: "ALF",
  Hospital: "Hospital",
  FQHC: "FQHC",
  HomeHealthcare: "HomeHealthcare",
  ABA: "ABA",
  AssistedLiving: "AssistedLiving",
};

// #endregion

// #region Resources

export const ResourcesPageTabs = {
  Tools: "tools",
  RequiredDocuments: "required-docs",
};

export const ResourceTypes = {
  Tool: "Tool",
  RequiredDocument: "RequiredDocument",
};

export const RequiredDocumentFrequencies = {
  Annually: "Annually",
  Quarterly: "Quarterly",
};

export const RequiredDocumentStatuses = {
  Needed: "Needed",
  Completed: "Completed",
  PendingReview: "PendingReview",
  Reviewed: "Reviewed",
};

// #endregion

// #region Trainings

export const TrainingsPageTabs = {
  Trainings: "trainings",
  TrainingPackets: "packets",
};

export const TrainingTypes = {
  Required: "Required",
  Recommended: "Recommended",
};

// #endregion

// #region Audits

export const AuditsPageTabs = {
  Inbox: "inbox",
  Completed: "completed",
  Manage: "manage",
  Templates: "templates",
};

export const AuditPageTabs = {
  Audit: "audit",
  Activity: "activity",
};

export const AuditStatuses = {
  SentNotStarted: "SentNotStarted",
  SentInProgress: "SentInProgress",
  AwaitingGrade: "AwaitingGrade",
  FollowUpNeeded: "FollowUpNeeded",
  ReadyForReview: "ReadyForReview",
  ResentCorrectionsNeeded: "ResentCorrectionsNeeded",
  Rescinded: "Rescinded",
  Completed: "Completed",
  CompletedCertificateSent: "CompletedCertificateSent",
};

export const AuditCapStatuses = {
  NotStarted: "NotStarted",
  InProgress: "InProgress",
  Completed: "Completed",
};

export const AuditGiftCardStatuses = {
  NotNeeded: "NotNeeded",
  Needed: "Needed",
  Sent: "Sent",
};

export const AuditCommentTypes = {
  Correction: "Correction",
  FacilityQuestion: "FacilityQuestion",
  Internal: "Internal",
};

// #endregion

// #region Search

export const SearchResultTypes = {
  User: "User",
  Policy: "Policy",
  RequiredDocument: "RequiredDocument",
  Tool: "Tool",
  Training: "Training",
  TrainingPacket: "TrainingPacket",
};

// #endregion

// #region Settings

export const GroupSettingTypes = {
  FacilityFilesEnabled: "FacilityFilesEnabled",
  PolicyPrintingDisabled: "PolicyPrintingDisabled",
};

// #endregion
