import React, { Suspense } from "react";
import { AppThunk } from "../../state";

export interface PdfViewerProps {
  allowDownload?: boolean;
  allowPrint?: boolean;
  dataUrl?: string;
  defaultScale?: number;
  downloadComponent?: JSX.Element;
  downloadFileName?: string;
  errorDisplay?: JSX.Element;
  name: string;
  printButton?: JSX.Element;
  printOnly?: boolean;
  retrieveData?: () => AppThunk<Promise<string | null>>;
  toolbarAddon?: JSX.Element;
}

// dynamically importing PdfViewer for code splitting to reduce @react-pdf-viewer lib effect on bundle size
const PdfViewerImport = React.lazy(() => import("./PdfViewer"));

export function PdfViewer(props: PdfViewerProps) {
  return (
    <Suspense>
      <PdfViewerImport {...props} />
    </Suspense>
  );
}
