import { useCallback, useEffect } from "react";
import { uiActions, useDispatch } from "../../state";
import { UIPageTitle } from "../../state/ui/state";

export function usePageTitle() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(uiActions.setPageTitle(undefined));
    };
  }, [dispatch]);

  return useCallback(
    (pageTitle: UIPageTitle | undefined) => {
      dispatch(uiActions.setPageTitle(pageTitle));
    },
    [dispatch],
  );
}
