import React, { useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, yup } from "../../lib";
import { authActions, useAppDispatch } from "../../state";
import { TextButtonStyled, CheckboxField, TextField } from "../../components";
import { Form, Formik } from "formik";
import {
  AuthCardStyled,
  ButtonBoxStyled,
  SubtitleTextStyled,
  TitleTextStyled,
} from "./Auth.styles";
import { ContactMethodTypes } from "../../lib/constants";

const validationSchema = yup.object({
  code: yup.string().required("Verification code is required"),
});

const getContactMethodDescription = (contactMethod) =>
  contactMethod == ContactMethodTypes.Email
    ? "email"
    : contactMethod == ContactMethodTypes.MobileNumber
    ? "text"
    : "";

export const VerifyLoginPage = React.memo(
  /**
   *
   */
  function VerifyLoginPage() {
    const dispatch = useAppDispatch();
    const {
      query: { altMethod, method, to },
    } = useLocation();

    const [error, setError] = useState("");

    const handleSubmit = useCallback(
      async (values) => {
        const { success, message } = await dispatch(
          authActions.verifyLogin(values),
        );
        if (!success) {
          setError(message || "Invalid verification code. Please try again.");
        }
      },
      [dispatch],
    );

    const sendVerificationCode = useCallback(
      async (sendMethod) =>
        dispatch(
          authActions.sendVerificationCode(
            String(sendMethod),
            getContactMethodDescription(sendMethod),
          ),
        ),
      [dispatch],
    );

    return (
      <AuthCardStyled>
        <TitleTextStyled variant="h5">Verify your account</TitleTextStyled>
        {error ? (
          <SubtitleTextStyled variant="body2" sx={{ color: "error.dark" }}>
            {error}
          </SubtitleTextStyled>
        ) : (
          <SubtitleTextStyled variant="body2">
            Enter the authentication code sent to {to || "your email or phone"}{" "}
            to verify your account.
          </SubtitleTextStyled>
        )}

        <Formik
          initialValues={{
            code: "",
            rememberMe: true,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <TextField autoFocus name="code" label="Enter 6-digit code" />
              <CheckboxField
                label="Remember this device for 90 days"
                name="rememberMe"
              />
              <ButtonBoxStyled>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Verify
                </Button>
              </ButtonBoxStyled>
            </Form>
          )}
        </Formik>

        <Typography variant="body2" color="text.secondary" mt="24px" mb="8px">
          Please allow a few minutes for your verification code to be sent.
          <br />
        </Typography>
        <Typography variant="body2" color="text.secondary" mb="12px">
          Didn't receive it?
          {method === ContactMethodTypes.Email &&
            !altMethod &&
            " Click below to resend or contact CCG to add a mobile number for SMS verification."}
        </Typography>
        <Box display="flex">
          <TextButtonStyled
            variant="text"
            onClick={() => sendVerificationCode(method)}
          >
            Resend {getContactMethodDescription(method)}
          </TextButtonStyled>
          {altMethod && (
            <TextButtonStyled
              variant="text"
              onClick={() => sendVerificationCode(altMethod)}
              sx={{ ml: 2 }}
            >
              Send {getContactMethodDescription(altMethod)}
            </TextButtonStyled>
          )}
        </Box>
        {altMethod && (
          <Typography variant="body2" color="text.secondary" mt="18px">
            Note: You can set your default verification method on your profile.
          </Typography>
        )}
      </AuthCardStyled>
    );
  },
);
