import React, { useCallback, useRef, useState } from "react";
import { BaseTextFieldProps, TextField } from "@mui/material";
import { CloseIcon, SearchIcon } from "../icons";
import debounce from "lodash.debounce";

interface SearchInputProps extends BaseTextFieldProps {
  adornmentPosition?: string;
  clearable?: boolean;
  debounced?: boolean;
  debounceWait?: number;
  fullWidth?: boolean;
  inputProps?: any;
  onSearch: (val: any) => void;
}

export const SearchInput = React.memo(
  /**
   *
   */
  function SearchInput({
    adornmentPosition = "end",
    clearable = true,
    debounced,
    debounceWait,
    fullWidth = true,
    inputProps = {},
    onSearch,
    value = "",
    ...rest
  }: SearchInputProps) {
    const [searchValue, setSearchValue] = useState(value);

    const debounceSearch = useRef(
      debounce((searchFunc, value) => {
        searchFunc(value);
      }, debounceWait || 500),
    );

    const handleChange = useCallback(
      (e) => {
        setSearchValue(e.target.value);
        if (debounced) {
          debounceSearch.current(onSearch, e.target.value);
        }
      },
      [debounced, onSearch],
    );

    const handleClear = useCallback(() => {
      setSearchValue("");
      onSearch("");
    }, [onSearch]);

    return (
      <TextField
        fullWidth={fullWidth}
        InputProps={{
          onKeyDown: !debounced
            ? (e) => {
                if (e.key === "Enter") onSearch(searchValue);
              }
            : undefined,
          startAdornment:
            adornmentPosition === "start" ? (
              <SearchIcon sx={{ mr: 1 }} />
            ) : null,
          endAdornment: (
            <>
              {clearable && !!searchValue ? (
                <CloseIcon
                  onClick={handleClear}
                  sx={{
                    cursor: "pointer",
                    fontSize: 14,
                  }}
                />
              ) : null}
              {adornmentPosition === "end" ? (
                <SearchIcon sx={{ ml: 1 }} />
              ) : null}
            </>
          ),
          ...inputProps,
        }}
        onChange={handleChange}
        size="small"
        value={searchValue}
        {...rest}
      />
    );
  },
);
