import { TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "& td": {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  "& th": {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.neutral.dark,
    fontWeight: "bold",
  },
}));
