import React, { useCallback, useEffect, useState } from "react";
import {
  IdNamePair,
  facilityAdminActions,
  useAppDispatch,
} from "../../../../state";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { RadioGroupField, TextField } from "../../../../components";
import { Form, useFormik, yup } from "../../../../lib";

const validationSchema = yup.object({
  confirmed: yup.string().required("Confirmation is required"),
  requiredUpdates: yup.string().when("confirmed", {
    is: (confirmed) => confirmed === "false",
    then: (schema) => schema.required("Details of the changes are required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

interface RequiredDocumentGoverningBodyReviewProps {
  facilityId: number;
  onReviewed: () => void;
  resourceName: string;
}

export const RequiredDocumentGoverningBodyReview = React.memo(
  /**
   *
   */
  function RequiredDocumentGoverningBodyReview({
    facilityId,
    onReviewed,
    resourceName,
  }: RequiredDocumentGoverningBodyReviewProps) {
    const dispatch = useAppDispatch();

    const [showReviewDialog, setShowReviewDialog] = useState(false); // the review dialog is hidden until we've checked that a review is required

    const [governingBodyUsers, setGoverningBodyUsers] = useState<IdNamePair[]>(
      [],
    );

    const checkGoverningBodyReview = useCallback(async () => {
      const reviewed = await dispatch(
        facilityAdminActions.getGoverningBodyReviewed(facilityId),
      );

      if (reviewed) {
        onReviewed();
        return;
      }

      // if governing body was not yet reviewed, retrieve the gb users list
      const gb = await dispatch(
        facilityAdminActions.getGoverningBody(facilityId),
      );
      if (gb) {
        setGoverningBodyUsers(gb);
      }

      setShowReviewDialog(true);
    }, [dispatch, facilityId, onReviewed]);

    // check governing body review status and retrieve governing body users on mount
    useEffect(() => {
      checkGoverningBodyReview();
    }, [checkGoverningBodyReview]);

    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        confirmed: "",
        requiredUpdates: "",
      },
      validationSchema,
      async onSubmit(values) {
        const submitted = await dispatch(
          facilityAdminActions.submitGoverningBodyReview(facilityId, {
            confirmed: values.confirmed === "true",
            requiredUpdates: values.requiredUpdates,
          }),
        );
        if (submitted) {
          onReviewed();
        }
      },
    });

    return showReviewDialog ? (
      <Dialog open={true} PaperProps={{ sx: { width: "520px" } }}>
        <Form form={form}>
          <DialogTitle variant="h6">
            Review Governing Body
            <Typography color="text.secondary" mt="8px" variant="body2">
              Please confirm that the governing body members are correct. A copy
              of the {resourceName} will be sent to the governing body upon
              submission.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle2">Current Governing Body</Typography>
            {governingBodyUsers.length ? (
              governingBodyUsers.map((user) => (
                <Typography key={user.id} mt="12px" variant="body2">
                  {user.name}
                </Typography>
              ))
            ) : (
              <Typography color="text.secondary" mt="12px" variant="body2">
                No Governing Body members found
              </Typography>
            )}
            <Typography variant="subtitle2" mt="24px" mb="12px">
              Are the members of the GB correct?
            </Typography>
            <RadioGroupField
              name="confirmed"
              options={[
                {
                  id: "true",
                  name: "Yes",
                  display: (
                    <Typography variant="body2">
                      Yes, all the information is accurate
                    </Typography>
                  ),
                },
                {
                  id: "false",
                  name: "No",
                  display: (
                    <Typography variant="body2">
                      No, there has been a change
                    </Typography>
                  ),
                },
              ]}
            />
            {form.values.confirmed === "false" && (
              <>
                <Typography variant="subtitle2" mt="24px" mb="12px">
                  Please list the details of the changes. They will be sent to
                  CCG.
                </Typography>
                <TextField
                  fullWidth
                  name="requiredUpdates"
                  label="Details"
                  multiline={true}
                  rows={4}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button fullWidth size="large" type="submit" variant="contained">
              Submit and go to required document
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    ) : null;
  },
);
