import React, { useCallback, useMemo, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ConfirmationDialog,
  ConfirmationDialogTypes,
  DeleteIcon,
  DownloadIcon,
  List,
  ListActionsContainerStyled,
  ListActionsMainStyled,
} from "../../../../components";
import { formatDate, useFileDownload } from "../../../../lib";
import { dateColumnSortingOrder } from "../../../../components/lists/List";
import {
  BasicFacility,
  facilityAdminActions,
  FacilityTraining,
  listActionUrls,
  sysSelectors,
  TrainingAttendanceRecord,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import { TrainingAttendanceUploadDialog } from "./TrainingAttendanceUploadDialog";

interface TrainingAttendanceListActionsProps {
  facilityId: number;
  trainingId: number;
}

function TrainingAttendanceListActions({
  facilityId,
  trainingId,
}: TrainingAttendanceListActionsProps) {
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        <Typography variant="h6">Attendees</Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ ml: 1 }}
          onClick={() => setShowUploadDialog(true)}
        >
          Add attendee list
        </Button>
        {showUploadDialog && (
          <TrainingAttendanceUploadDialog
            handleClose={() => setShowUploadDialog(false)}
            facilityId={facilityId}
            trainingId={trainingId}
          />
        )}
      </ListActionsMainStyled>
    </ListActionsContainerStyled>
  );
}

interface TrainingAttendanceListProps {
  training: FacilityTraining;
}

export const TrainingAttendanceList = React.memo(
  /**
   *
   */
  function TrainingAttendanceList({ training }: TrainingAttendanceListProps) {
    const dispatch = useAppDispatch();

    const facilityFilter = useSelector(
      sysSelectors.facilityFilter,
    ) as BasicFacility;

    const listFilter = useMemo(
      () => ({ facilityIDs: [facilityFilter.id] }),
      [facilityFilter],
    );

    const [attendanceToDelete, setAttendanceToDelete] =
      useState<TrainingAttendanceRecord>();

    const onDelete = useCallback(async () => {
      if (!attendanceToDelete) {
        return;
      }
      const deleted = await dispatch(
        facilityAdminActions.deleteTrainingAttendance(
          training.id,
          attendanceToDelete.id,
        ),
      );
      if (deleted) {
        setAttendanceToDelete(undefined);
      }
    }, [attendanceToDelete, dispatch, training.id]);

    const downloadFile = useFileDownload();

    const columns: GridColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "createdOn",
          headerName: "Uploaded on",
          flex: 1,
          valueFormatter: ({ value }) => formatDate(value),
          sortingOrder: dateColumnSortingOrder,
        },
        {
          field: "actions",
          headerName: "",
          width: 120,
          renderCell: ({ row }: { row: TrainingAttendanceRecord }) => (
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => downloadFile(row.url, row.name, true)}
                sx={{ mr: 1, color: "text.secondary" }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setAttendanceToDelete(row)}
                sx={{ color: "text.secondary" }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ),
          sortable: false,
        },
      ],
      [downloadFile],
    );

    return (
      <>
        <List
          actions={
            <TrainingAttendanceListActions
              facilityId={facilityFilter.id as number}
              trainingId={training.id}
            />
          }
          dataUrl={listActionUrls[ListTypes.trainingAttendance].replace(
            ":id",
            training.id.toString(),
          )}
          columns={columns}
          defaultOrderBy="createdOn"
          defaultOrderDirection="desc"
          filter={listFilter}
          name="training attendees"
          stickyHeader={true}
          type={ListTypes.trainingAttendance}
        />
        {attendanceToDelete && (
          <ConfirmationDialog
            handleClose={() => setAttendanceToDelete(undefined)}
            handleConfirm={onDelete}
            open={true}
            message="Are you sure you want to delete this attendance list?"
            title="Delete attendance list?"
            type={ConfirmationDialogTypes.Alert}
          />
        )}
      </>
    );
  },
);
