import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Chip, Collapse, Typography } from "@mui/material";
import {
  AnnouncementRecord,
  facilityAdminActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { ListTypes } from "../../../../state/lists/state";
import {
  CheckboxInput,
  FilterListIcon,
  HtmlSanitizedText,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  PaginationContainer,
  SelectInput,
} from "../../../../components";
import {
  AnnouncementBodyTextStyled,
  AnnouncementCardStyled,
  AnnouncementHeaderStyled,
  AnnouncementSubjectTextStyled,
} from "./AnnouncementsList.styles";
import { asArray, formatDate } from "../../../../lib";
import { FacilityTypes } from "../../../../lib/constants";
import notFound from "../../../../assets/img/not_found.svg";

const filterQueryKey = {
  facilityIDs: "facilities",
  excludeRead: "hideRead",
};

function AnnouncementsListActions({
  onFilterChange = () => {},
  query,
}: ListActionsProps) {
  const facilityOptions = useSelector(sysSelectors.activeFacilities);

  const { facilities = [], hideRead } = query;

  const [filtersOpen, setFiltersOpen] = useState(
    !!asArray(facilities).length || hideRead,
  );

  const facilitiesArray = asArray(facilities);

  // open the filters when facility filter is set from the adjacent Facilties list
  useEffect(() => {
    if (facilitiesArray[0] && !filtersOpen) {
      setFiltersOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitiesArray[0]]);

  return (
    <ListActionsContainerStyled sx={{ borderBottom: "none" }}>
      <ListActionsMainStyled sx={{ paddingTop: "0px" }}>
        <Typography variant="h6">Announcements</Typography>
        <Button
          variant="text"
          color="primary"
          size="large"
          sx={{ ml: 1 }}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Filter <FilterListIcon sx={{ ml: 1 }} />
        </Button>
      </ListActionsMainStyled>
      <Collapse in={filtersOpen}>
        <ListActionsExpandedStyled>
          <SelectInput
            label="Facility"
            name="facilities"
            value={facilitiesArray}
            onChange={onFilterChange}
            options={facilityOptions}
            groupOpts={true}
            multiple={true}
            sx={{ mr: 2 }}
          />
          <CheckboxInput
            checked={!!hideRead}
            label="Hide read"
            name="hideRead"
            onChange={onFilterChange}
          />
        </ListActionsExpandedStyled>
      </Collapse>
    </ListActionsContainerStyled>
  );
}

function AnnouncementsListItem({
  announcement,
}: {
  announcement: AnnouncementRecord;
}) {
  const {
    body,
    facilities,
    facilityType,
    facilityTypeDisplay,
    id,
    isNew,
    isRead,
    postedOn,
    states,
    subject,
  } = announcement;

  const dispatch = useAppDispatch();

  const onMarkRead = useCallback(() => {
    dispatch(facilityAdminActions.markAnnouncementRead(id));
  }, [id, dispatch]);

  return (
    <AnnouncementCardStyled read={isRead}>
      <AnnouncementHeaderStyled>
        <Typography variant="overline">
          {formatDate(postedOn, "MMMM d'TH' yyyy")}
          {isNew && <Chip color="primary" label="New" size="small" />}
        </Typography>
        {!isRead && (
          <Button onClick={onMarkRead} size="small" variant="text">
            Mark as read
          </Button>
        )}
      </AnnouncementHeaderStyled>
      <HtmlSanitizedText
        styledTypography={AnnouncementSubjectTextStyled}
        text={subject}
        variant="subtitle2"
      />
      <HtmlSanitizedText
        styledTypography={AnnouncementBodyTextStyled}
        text={body}
        variant="body2"
      />
      <Typography color="text.secondary" variant="caption">
        Relevant for:{" "}
        {facilityType !== FacilityTypes.SNF && !facilities.length
          ? `${facilityTypeDisplay}s in `
          : ""}
        {facilities.length
          ? facilities.map((f) => f.name).join(", ")
          : states.length > 50
          ? "All States"
          : states.map((s) => s.name).join(", ")}
      </Typography>
    </AnnouncementCardStyled>
  );
}

export const AnnouncementsList = React.memo(
  /**
   *
   */
  function AnnouncementsList() {
    return (
      <Box>
        <PaginationContainer
          actions={<AnnouncementsListActions />}
          defaultOrderBy="isRead"
          defaultOrderDirection="desc"
          defaultResultsPerPage={25}
          filterQueryKey={filterQueryKey}
          hidePaginationForMinRows={true}
          name="announcements"
          noRowsLogo={notFound}
          paginationQueryKeyPrefix="a" // differentiate pagination query params from facilities list
          renderResult={(row: AnnouncementRecord) => (
            <AnnouncementsListItem key={row.id} announcement={row} />
          )}
          type={ListTypes.announcements}
        />
      </Box>
    );
  },
);
