import { styled } from "@mui/material/styles";
import { RouterLink } from "../../../../../lib";

export const FacilityFilesLinkStyled = styled(RouterLink)(({ theme }) => ({
  display: "block",
  marginLeft: "32px",
  fontSize: "14px",
  color: `${theme.palette.primary.main} !important`,
  textDecoration: "none",
}));
