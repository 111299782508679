import { useMediaQuery, createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }

  interface PaletteColor {
    extraLight?: string;
  }
  interface SimplePaletteColorOptions {
    extraLight?: string;
  }
}

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  palette: {
    primary: { dark: "#1E5FA9", main: "#2470BB", light: "#328EDA" },
    secondary: { dark: "#E8A80A", main: "#F5B517", light: "#F7C548" },
    error: { dark: "#E42930", main: "#FF3E36", light: "#FF5252" },
    warning: { dark: "#E86F0A", main: "#F57C17", light: "#F79848" },
    info: { dark: "#6409D0", main: "#7C17F5", light: "#8A2FF6" },
    success: { dark: "#1FAC5F", main: "#25CD71", light: "#47DE8B" },
    background: { default: "#FFFFFF" },
    text: { primary: "#000000", secondary: "#6B8AAC" },
    divider: "#DDE3EB",
    neutral: {
      dark: "#6B8AAC",
      main: "#9DB2C8",
      light: "#DDE3EB",
      extraLight: "#F4F6F8",
    },
  },

  typography: {
    fontFamily: "Noto Sans",
    h1: {
      fontSize: "95px",
      letterSpacing: "-1.5px",
      lineHeight: "130px",
    },
    h2: {
      fontSize: "59px",
      fontWeight: "bold",
      letterSpacing: "-0.5px",
      lineHeight: "80px",
    },
    h3: {
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "65px",
    },
    h4: {
      fontSize: "32px",
      fontWeight: "bold",
      letterSpacing: "0.24px",
      lineHeight: "43px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "33px",
    },
    h6: {
      fontSize: "20px",
      fontWeight: "bold",
      letterSpacing: "0.15px",
      lineHeight: "27px",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "22px",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "19px",
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "22px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "19px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "17px",
    },
    overline: {
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: "1.5px",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "3px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeSmall: {
          fontSize: "13px",
          lineHeight: "18px",
          padding: "4px 10px",
        },
        sizeMedium: {
          fontSize: "14px",
          lineHeight: "19px",
          padding: "6px 16px",
        },
        sizeLarge: {
          fontSize: "15px",
          lineHeight: "20px",
          padding: "8px 22px",
        },
        //primary button color set to primary light
        containedPrimary: {
          backgroundColor: "#328EDA",
        },
        textPrimary: {
          color: "#328EDA",
        },
        outlinedPrimary: {
          borderColor: "#DDE3EB",
          color: "#000000",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#328EDA",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "19px",
          textDecoration: "none",
          ":hover": {
            opacity: 0.8,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          justifyContent: "center",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: "#2B8CDD",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#6A89AD",
          "&.Mui-selected": {
            color: "#2B8CDD",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#6A89AD",
          "&.Mui-selected": {
            backgroundColor: "#328EDA",
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& svg": {
            color: "#6B8AAC",
          },
        },
        input: {
          "&::placeholder": {
            color: "#6B8AAC",
            opacity: "100%",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        sizeSmall: {
          "&:not(.MuiInputBase-multiline)": {
            height: "40px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#9DB2C8",
        },
        root: {
          "&:hover fieldset": {
            borderColor: "#6B8AAC !important",
          },
          "&.Mui-focused fieldset": {
            borderWidth: "1.5px !important",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          color: "#6B8AAC",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          maxHeight: "60vh",
          // scroll shadow to clearly indicate when there is scrollable content
          background: `/* Shadow Cover TOP */
            linear-gradient(
              white 30%,
              rgba(255, 255, 255, 0)
            ) center top,
            
            /* Shadow Cover BOTTOM */
            linear-gradient(
              rgba(255, 255, 255, 0), 
              white 70%
            ) center bottom,
            
            /* Shadow TOP */
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            ) center top,
            
            /* Shadow BOTTOM */
            radial-gradient(
              farthest-side at 50% 100%,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            ) center bottom`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
          backgroundAttachment: "local, local, scroll, scroll",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
  },

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "(max-width:600px)") {
  return useMediaQuery(size);
}

// Other colors
export const fuchsia = "#DE47A7";
export const lightBlue = "#429CCF";
export const purple = "#B342CF";
export const turquoise = "#3DBBBD";
