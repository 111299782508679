import { PermissionClaims } from "../../lib/constants";
import { PageDefinitions } from "../../lib/types";
import { FacilityFilePage } from "./FacilityFilePage";
import { FacilityFilesPage } from "./FacilityFilesPage";
import { PoliciesPage } from "./PoliciesPage";
import { PolicyPage } from "./PolicyPage";

export const FacilityStaffPages: PageDefinitions = {
  policies: {
    anon: false,
    authClaims: [PermissionClaims.FacilityStaffClaim],
    isDefault: true,
    path: "/policies",
    title: "Policies and Procedures",
    view: PoliciesPage,
  },
  policy: {
    anon: false,
    authClaims: [PermissionClaims.FacilityStaffClaim],
    goBack: true,
    path: "/policies/:id",
    goBackDefaultPath: "/policies",
    title: "Policies",
    view: PolicyPage,
  },
  // facility files
  facilityFiles: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    path: "/files",
    title: "Facility Files",
    view: FacilityFilesPage,
  },
  facilityFile: {
    anon: false,
    authClaims: [PermissionClaims.FacilityFilesReadClaim],
    goBack: true,
    goBackDefaultPath: "/files",
    path: "/files/:id",
    title: "Facility Files",
    view: FacilityFilePage,
  },
};
