import React from "react";
import { TabbedView } from "../../components";
import { TrainingsPageTabs } from "../../lib/constants";
import { TrainingPacketsList, TrainingsList } from "./components";

const tabs = [
  {
    id: TrainingsPageTabs.Trainings,
    name: "Trainings",
    component: TrainingsList,
  },
  {
    id: TrainingsPageTabs.TrainingPackets,
    name: "Packets",
    component: TrainingPacketsList,
  },
];

export const TrainingsPage = React.memo(
  /**
   *
   */
  function TrainingsPage() {
    return <TabbedView tabs={tabs} />;
  },
);
