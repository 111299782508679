import React, { useCallback, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { ListTypes } from "../../../../../state/lists/state";
import {
  CheckboxInput,
  List,
  ListActionsContainerStyled,
  ListActionsExpandedStyled,
  ListActionsMainStyled,
  ListActionsProps,
  SearchInput,
  SelectInput,
} from "../../../../../components";
import {
  asArray,
  convertEntityFilter,
  formatAddress,
  Navigation,
  useLocation,
} from "../../../../../lib";
import { FilterListIcon } from "../../../../../components";
import {
  FlexCellStyled,
  HeaderContainerStyled,
  StatusChipStyled,
} from "./GroupsFacilitiesRegionsLists.styles";
import { GroupsFacilitiesRegionsDashboard } from "../general/GroupsFacilitiesRegionsDashboard";
import { AddGroupFacilityRegionMenu } from "../general/AddGroupFacilityRegionMenu";
import { AdminPages } from "../../..";
import { EntityFilter } from "../../../../../lib/types";
import { RegionRecord, sysSelectors, useSelector } from "../../../../../state";

const filterQueryKey = {
  groupIDs: "groups",
  includeInactive: "showInactive",
  text: "search",
};

interface RegionsListActionsProps extends ListActionsProps {
  filter?: EntityFilter;
  listType: string;
}

function RegionsListActions({
  filter,
  onFilterChange = () => {},
  listType,
  query,
}: RegionsListActionsProps) {
  const groupOptions = useSelector(sysSelectors.activeGroups);

  const { groups = [], showInactive = false, search = "" } = query;

  const [filtersOpen, setFiltersOpen] = useState(!!asArray(groups).length);

  const isSublist = listType === ListTypes.relatedRegions;

  return (
    <ListActionsContainerStyled>
      <ListActionsMainStyled>
        {isSublist ? (
          <Typography variant="h5">Regions</Typography>
        ) : (
          <SearchInput
            variant="outlined"
            placeholder="Search regions"
            name="search"
            value={search}
            debounced={true}
            onSearch={(_search) => onFilterChange("search", _search)}
          />
        )}
        <Box display="flex" alignItems="center">
          {!filter?.includeInactive && (
            <CheckboxInput
              checked={!!showInactive}
              label="Show inactive"
              name="showInactive"
              onChange={onFilterChange}
            />
          )}
          {!isSublist && (
            <Button
              variant="text"
              color="primary"
              size="large"
              sx={{ ml: 1 }}
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              Filter <FilterListIcon sx={{ ml: 1 }} />
            </Button>
          )}
          {listType === ListTypes.relatedRegions && (
            <AddGroupFacilityRegionMenu filter={filter} sx={{ ml: 1 }} />
          )}
        </Box>
      </ListActionsMainStyled>
      {!isSublist && (
        <Collapse in={filtersOpen}>
          <ListActionsExpandedStyled>
            <SelectInput
              label="Group"
              name="groups"
              value={asArray(groups)}
              onChange={onFilterChange}
              options={groupOptions}
              multiple={true}
            />
          </ListActionsExpandedStyled>
        </Collapse>
      )}
    </ListActionsContainerStyled>
  );
}

interface RegionsListProps {
  filter?: EntityFilter;
  listType?: string;
  noRowsDisplay?: string | JSX.Element;
}

export const RegionsList = React.memo(
  /**
   *
   */
  function RegionsList({
    filter,
    listType = ListTypes.regions,
    noRowsDisplay,
  }: RegionsListProps) {
    const {
      query: { showInactive },
    } = useLocation();

    const listFilter = useMemo(
      () => (filter ? convertEntityFilter(filter) : undefined),
      [filter],
    );

    const columns: GridColDef[] = useMemo(() => {
      const allColumns: GridColDef[] = [
        {
          field: "name",
          headerName: "Region",
          flex: 2,
        },
        {
          field: "groupName",
          headerName: "Group",
          flex: 2,
        },
        {
          field: "address",
          headerName: "Address",
          flex: 2,
          renderCell: ({ row }: { row: RegionRecord }) => (
            <Typography variant="body2" whiteSpace="pre">
              {formatAddress(row, "\n")}
            </Typography>
          ),
          sortable: false,
        },
        {
          field: "facilitiesCount",
          headerName: "Facilities",
          flex: 1,
          renderCell: ({ row }: { row: RegionRecord }) => (
            <FlexCellStyled
              onClick={(e) => {
                e.stopPropagation();
                Navigation.go(AdminPages.region, {
                  params: { id: row.id },
                  query: { tab: "facilities" },
                });
              }}
            >
              {showInactive && row.inactiveFacilitiesCount
                ? `${
                    row.activeFacilitiesCount
                      ? `${row.activeFacilitiesCount.toLocaleString()} + `
                      : ""
                  }${row.inactiveFacilitiesCount.toLocaleString()} Inactive`
                : row.activeFacilitiesCount.toLocaleString()}
            </FlexCellStyled>
          ),
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }: { row: RegionRecord }) =>
            row.status && (
              <StatusChipStyled
                label={row.status}
                size="small"
                status={row.status}
              />
            ),
        },
      ];

      switch (listType) {
        case ListTypes.relatedRegions:
          return allColumns.filter((c) => c.field !== "groupName");
        case ListTypes.regions:
        default:
          return allColumns;
      }
    }, [listType, showInactive]);

    const onRowClick = useCallback(({ row }: { row: RegionRecord }) => {
      Navigation.go(AdminPages.region, { params: { id: row.id } });
    }, []);

    return (
      <>
        {listType !== ListTypes.relatedRegions && (
          <HeaderContainerStyled>
            <GroupsFacilitiesRegionsDashboard />
            <AddGroupFacilityRegionMenu />
          </HeaderContainerStyled>
        )}
        <List
          actions={<RegionsListActions filter={filter} listType={listType} />}
          columns={columns}
          filter={listFilter}
          filterQueryKey={filterQueryKey}
          name="regions"
          noRowsDisplay={noRowsDisplay}
          onRowClick={onRowClick}
          rowHeight={64}
          stickyHeader={true}
          type={listType}
        />
      </>
    );
  },
);
