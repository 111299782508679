import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AddIcon, CloseIcon, FilePreview } from "../../../../components";
import {
  RequiredDocumentComment,
  RequiredDocumentRecord,
  authSelectors,
  listsActions,
  sharedActions,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { FileTypes, PortalUserTypes } from "../../../../lib/constants";
import { formatDate } from "../../../../lib";
import {
  CommentHeaderStyled,
  CommentsPanelStyled,
  CommentsScrollContainerStyled,
  TitleStyled,
} from "./RequiredDocumentSubmissionDialog.styles";
import { isToday } from "date-fns";
import { ListTypes } from "../../../../state/lists/state";

interface RequiredDocumentSubmissionDialogProps {
  allowReview: boolean;
  document: RequiredDocumentRecord;
  handleClose: () => void;
  refreshSubmissions?: boolean;
}

export const RequiredDocumentSubmissionDialog = React.memo(
  /**
   *
   */
  function RequiredDocumentSubmissionDialog({
    allowReview,
    document,
    handleClose,
    refreshSubmissions,
  }: RequiredDocumentSubmissionDialogProps) {
    const dispatch = useAppDispatch();

    const userId = useSelector(authSelectors.userId);
    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const [comments, setComments] = useState<RequiredDocumentComment[]>([]);
    const [showNewComment, setShowNewComment] = useState(
      allowReview && !document.reviewedOn,
    );
    const [newCommentText, setNewCommentText] = useState("");

    const retrieveComments = useCallback(async () => {
      const comments = await dispatch(
        sharedActions.getRequiredDocumentComments(document.id),
      );

      if (comments) {
        setComments(comments);

        if (allowReview && !comments.length) {
          setShowNewComment(true);
        }
      }
    }, [allowReview, dispatch, document.id]);

    useEffect(() => {
      if (document.reviewedOn) {
        retrieveComments();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retrieveComments]);

    const submissionUpdated = useRef(false);

    const onClose = useCallback(async () => {
      // refresh submissions list when there were updates made
      if (submissionUpdated.current && refreshSubmissions) {
        dispatch(
          listsActions.refreshList(ListTypes.requiredDocumentSubmissions),
        );
      }

      handleClose();
    }, [dispatch, handleClose, refreshSubmissions, submissionUpdated]);

    const onSubmit = useCallback(async () => {
      if (allowReview && !document.reviewedOn) {
        const reviewSubmitted = await dispatch(
          sharedActions.submitRequiredDocumentReview(
            document.id,
            newCommentText,
          ),
        );

        if (!reviewSubmitted) {
          return;
        }

        submissionUpdated.current = true;
      } else if (newCommentText) {
        const commentSubmitted = await dispatch(
          sharedActions.submitRequiredDocumentComment(document.id, {
            text: newCommentText,
          }),
        );

        if (!commentSubmitted) {
          return;
        }

        submissionUpdated.current = true;
      }

      onClose();
    }, [
      allowReview,
      dispatch,
      document.id,
      document.reviewedOn,
      newCommentText,
      onClose,
    ]);

    const onDeleteComment = useCallback(
      async (commentId: number) => {
        const deleted = await dispatch(
          sharedActions.deleteRequiredDocumentComment(document.id, commentId),
        );

        if (deleted) {
          submissionUpdated.current = true;
          retrieveComments();
        }
      },
      [dispatch, document.id, retrieveComments],
    );

    const newCommentInputRef = useRef<HTMLInputElement>(null);

    const onClickAddComment = useCallback(() => {
      setShowNewComment(true);

      // focus on new comment input
      setTimeout(() => {
        newCommentInputRef.current?.focus();
      }, 0);
    }, []);

    const showCommentsPanel =
      document.hasReviewWorkflow && (allowReview || !!document.reviewedOn); // show comments panel for review or if already reviewed

    return (
      <Dialog
        open={true}
        PaperProps={{
          sx: { width: "80vw", maxWidth: "1200px", height: "90vh" },
        }}
      >
        <DialogTitle
          component="div"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <TitleStyled variant="h6">
              {document.resourceName}
              {document.hasReviewWorkflow && (
                <Chip
                  color={document.reviewedOn ? "success" : "warning"}
                  label={
                    document.reviewedOn
                      ? "Reviewed and confirmed by GB Designee"
                      : "Received - Pending GB Designee"
                  }
                  size="small"
                />
              )}
            </TitleStyled>
            <Typography color="text.secondary" variant="body2">
              Submitted by {document.createdBy} on{" "}
              {formatDate(document.createdOn)}
              {document.reviewedOn &&
                ` • Confirmed by ${document.reviewedBy} on ${formatDate(
                  document.reviewedOn,
                )}`}
            </Typography>
          </Box>
          {(isCcgAdmin || !showCommentsPanel) && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "100%",
          }}
        >
          <Box display="flex" width="100%">
            <FilePreview
              fileName={document.resourceName}
              fileSource={document.url}
              fileType={FileTypes.Pdf}
              secure={true}
              style={{ width: "100%" }}
            />
          </Box>
          {showCommentsPanel && (
            <CommentsPanelStyled>
              <Box>
                <Typography variant="subtitle1" mb="16px">
                  Feedback
                  {allowReview && (
                    <Typography
                      color="text.secondary"
                      display="block"
                      mt="6px"
                      variant="caption"
                    >
                      Please post feedback to be reviewed by the Compliance and
                      Ethics Officer
                    </Typography>
                  )}
                </Typography>
                <CommentsScrollContainerStyled>
                  {comments.map((comment) => (
                    <Box mb="24px" key={comment.id}>
                      <CommentHeaderStyled>
                        <Typography color="text.secondary" variant="caption">
                          {comment.commenterName} •{" "}
                          {formatDate(
                            comment.createdOn,
                            isToday(new Date(comment.createdOn || ""))
                              ? "h:mm aa"
                              : "MMM d",
                          )}
                        </Typography>
                        {(isCcgAdmin || userId == comment.commenterID) && (
                          <Button
                            color="error"
                            onClick={() => onDeleteComment(Number(comment.id))}
                            size="small"
                            variant="text"
                          >
                            Delete
                          </Button>
                        )}
                      </CommentHeaderStyled>
                      <Typography variant="body1">{comment.text}</Typography>
                    </Box>
                  ))}
                  {showNewComment && (
                    <TextField
                      fullWidth
                      inputRef={newCommentInputRef}
                      label="Comment"
                      multiline={true}
                      onChange={(e) => setNewCommentText(e.target.value)}
                      rows={4}
                      sx={{ marginY: "6px" }}
                      value={newCommentText}
                    />
                  )}
                </CommentsScrollContainerStyled>
                {!showNewComment && (
                  <Button
                    onClick={onClickAddComment}
                    size="small"
                    variant="text"
                    sx={{ padding: "4px" }}
                  >
                    <AddIcon sx={{ mr: "4px" }} /> Add comment
                  </Button>
                )}
              </Box>
              <Box>
                <Button variant="contained" onClick={onSubmit}>
                  {allowReview && !document.reviewedOn
                    ? "Mark Reviewed"
                    : newCommentText
                    ? "Save and Close"
                    : "Close"}
                </Button>
              </Box>
            </CommentsPanelStyled>
          )}
        </DialogContent>
      </Dialog>
    );
  },
);
