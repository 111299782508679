import React from "react";
import { TabbedView } from "../../components";
import { AuditsPageTabs } from "../../lib/constants";
import { AuditsList } from "./components";

const tabs = [
  {
    id: AuditsPageTabs.Inbox,
    name: "Audit Inbox",
    component: AuditsList,
  },
  {
    id: AuditsPageTabs.Completed,
    name: "Completed Audits",
    component: AuditsList,
  },
];

export const AuditsPage = React.memo(
  /**
   *
   */
  function AuditsPage() {
    return <TabbedView tabs={tabs} />;
  },
);
