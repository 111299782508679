import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, IconButton } from "@mui/material";
import { useSecureFileRead } from "../../../../lib";
import { CloseIcon, PdfViewer, PrintIcon } from "../../../../components";

interface AuditCertificatePreviewDialogProps {
  certificateUrl: string;
  handleClose: () => void;
}

export const AuditCertificatePreviewDialog = React.memo(
  /**
   *
   */
  function AuditCertificatePreviewDialog({
    certificateUrl,
    handleClose,
  }: AuditCertificatePreviewDialogProps) {
    const [previewUrl, setPreviewUrl] = useState("");

    const getSecureFileUrl = useSecureFileRead("audit certificate");

    useEffect(() => {
      (async () => {
        setPreviewUrl(await getSecureFileUrl(certificateUrl));
      })();
    });

    return (
      <Dialog
        open={true}
        PaperProps={{
          sx: {
            width: "80vw",
            maxWidth: "1200px",
            height: "80vh",
            padding: "16px",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <PdfViewer
          allowPrint={true}
          dataUrl={previewUrl}
          defaultScale={1}
          name="audit certificate"
          printButton={
            <Button variant="contained" color="primary" size="large">
              <PrintIcon sx={{ mr: 1 }} /> Print
            </Button>
          }
          printOnly={true}
        />
      </Dialog>
    );
  },
);
