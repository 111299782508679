import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
  authSelectors,
  FacilityFile,
  sharedActions,
  sysActions,
  sysSelectors,
  useAppDispatch,
  useSelector,
} from "../../../../state";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  AutocompleteField,
  ConfirmationDialog,
  ConfirmationDialogTypes,
  FileUploadField,
  FormFooterContainerStyled,
  FormGridContainerStyled,
  FormGridItemFullWidthStyled,
  FormGridItemStyled,
  InfoIcon,
  SelectField,
  TextField,
  ToggleField,
} from "../../../../components";
import {
  Form,
  Navigation,
  replaceEmptyProps,
  replaceNullProps,
  useRedirect,
  yup,
} from "../../../../lib";
import { BasicStatusOptions, PortalUserTypes } from "../../../../lib/constants";
import { AdminPages } from "../../../admin";
import { FacilityAdminPages } from "../../../facilityAdmin";

const validationSchema = yup.object({
  name: yup.string().max(250, "Name cannot exceed 250 characters"),
  categoryID: yup.number().nullable(),
  groupID: yup.number().nullable().required("Group is required"),
  facilityIDs: yup.array().min(1, "Facility is required"),
  status: yup.string().required("Status is required"),
  // file is required when creating a new facility file
  file: yup.mixed().when("id", {
    is: (id) => !id,
    then: (schema) => schema.required("File is required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

interface FacilityFileFormProps {
  groupId: number;
}

export const FacilityFileForm = React.memo(
  /**
   *
   */
  function FacilityFileForm({ groupId }: FacilityFileFormProps) {
    const dispatch = useAppDispatch();
    const params = useParams();

    const { redirect, setRedirect } = useRedirect();

    const userType = useSelector(authSelectors.userType);
    const isCcgAdmin = userType === PortalUserTypes.CcgAdmin;

    const facilityFilesUrl = useMemo(
      () =>
        isCcgAdmin
          ? Navigation.url(AdminPages.facilityFiles, {
              params: { groupId },
            })
          : FacilityAdminPages.facilityFiles.path,
      [groupId, isCcgAdmin],
    );

    const facilities = useSelector(
      isCcgAdmin ? sysSelectors.allFacilities : sysSelectors.activeFacilities,
    );
    const facilityOptions = useMemo(
      () => facilities.filter((f) => f.groupID === groupId),
      [facilities, groupId],
    );
    const facilityFileCategories = useSelector(
      sysSelectors.facilityFileCategories,
    );
    const facilityFileCategoryOptions = useMemo(
      () => facilityFileCategories.filter((c) => c.groupID === groupId),
      [facilityFileCategories, groupId],
    );

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
      useState(false);

    const [initialValues, setInitialValues] = useState<FacilityFile>({
      name: "",
      categoryID: null,
      groupID: groupId,
      status: "",
      facilityIDs: [],
      file: null,
    });

    useEffect(() => {
      (async () => {
        if (params.id) {
          const facilityFile = await dispatch(
            sharedActions.getFacilityFile(Number(params.id)),
          );
          if (facilityFile) {
            setInitialValues(replaceNullProps(facilityFile));
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params.id]);

    const onSubmit = useCallback(
      async (values: FacilityFile) => {
        const valuesForSubmission = { ...values };

        // if name is not set, default name to the name of the uploaded file
        if (!values.name && values.file) {
          valuesForSubmission.name = values.file.name.replace(/\.[^/.]+$/, ""); // remove extension from file name
        }

        const savedFacilityFile = await dispatch(
          sharedActions.submitFacilityFile(
            replaceEmptyProps(valuesForSubmission),
          ),
        );
        if (savedFacilityFile) {
          setInitialValues(replaceNullProps(savedFacilityFile));
          setRedirect(() => () => Navigation.goBack(facilityFilesUrl));
        }
      },
      [dispatch, facilityFilesUrl, setRedirect],
    );

    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

    useEffect(() => {
      // on group change, retrieve categories if there are no options available for the group
      if (!facilityFileCategoryOptions.length) {
        dispatch(sysActions.getFacilityFileCategories(groupId));
      }
      // on group change, reset selected category in form
      if (form.values.categoryID) {
        form.setFieldValue("categoryID", null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, groupId]);

    const onCancel = useCallback(
      () => Navigation.goBack(facilityFilesUrl),
      [facilityFilesUrl],
    );

    const onDelete = useCallback(async () => {
      form.resetForm(initialValues);
      const deleted = await dispatch(
        sharedActions.deleteFacilityFile(Number(initialValues.id)),
      );
      if (deleted) {
        Navigation.replace(facilityFilesUrl);
      }
    }, [dispatch, facilityFilesUrl, form, initialValues]);

    return (
      <Form
        form={form}
        noPrompt={!!redirect}
        promptCancelText="Back to Facility file"
      >
        <FormGridContainerStyled container>
          <FormGridItemStyled item>
            <TextField name="name" label="Name" />
          </FormGridItemStyled>
          <FormGridItemStyled item>
            <SelectField
              disabled={!form.values.groupID}
              name="categoryID"
              label="Category"
              options={facilityFileCategoryOptions}
            />
          </FormGridItemStyled>
          <FormGridItemFullWidthStyled item>
            <AutocompleteField
              disabled={!form.values.groupID}
              name="facilityIDs"
              label="Applicable for"
              multiple={true}
              options={facilityOptions}
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <Typography
              variant="h6"
              display="flex"
              alignItems="center"
              mt={1}
              mb={1}
            >
              File
              <Tooltip title="Upload a PDF or MS Word file. File size must be limited to 10MB.">
                <InfoIcon
                  fontSize="small"
                  sx={{ ml: 1, color: "text.secondary" }}
                />
              </Tooltip>
            </Typography>
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled>
            <FileUploadField
              accept={{
                "application/pdf": [], // pdf
                "application/msword": [], // doc
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [], // docx
              }}
              description="file"
              fileRejectionMessage="File must be a PDF or MS Word file, and limited to 10MB"
              maxSize={10485760} // limit size to 10MB
              name="file"
              previewFileTypes={["application/pdf"]}
              secure={true}
              uploadText={initialValues.id ? "Change" : "Upload"} // no url is returned for a saved file, therefore when editing a file replace Upload text with 'Change'
            />
          </FormGridItemFullWidthStyled>
          <FormGridItemFullWidthStyled item>
            <ToggleField
              buttonWidth="140px"
              fullWidth={false}
              name="status"
              options={BasicStatusOptions}
              size="large"
            />
          </FormGridItemFullWidthStyled>
          <FormFooterContainerStyled>
            <Button
              variant="outlined"
              size="large"
              disabled={form.isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Box display="flex" alignItems="center">
              {!!initialValues.id && (
                <>
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    disabled={form.isSubmitting}
                    onClick={() => setShowDeleteConfirmationDialog(true)}
                    sx={{ mr: 2 }}
                  >
                    Delete
                  </Button>
                  {showDeleteConfirmationDialog && (
                    <ConfirmationDialog
                      confirmColor="error"
                      confirmText="Delete"
                      handleClose={() => setShowDeleteConfirmationDialog(false)}
                      handleConfirm={onDelete}
                      message="This will delete the file from all applicable facilities. This action cannot be undone."
                      open={true}
                      title="Delete file?"
                      type={ConfirmationDialogTypes.Alert}
                    />
                  )}
                </>
              )}
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </FormFooterContainerStyled>
        </FormGridContainerStyled>
      </Form>
    );
  },
);
