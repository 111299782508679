import React from "react";
import { IconButton, Snackbar, Alert } from "@mui/material";
import { uiActions, uiSelectors, useAction, useSelector } from "../state";
import { CloseIcon } from "./icons";
import { useLocation } from "../lib";
import { SharedPages } from "../pages";
const Notification = () => {
  const location = useLocation();

  const notification = useSelector(uiSelectors.notification);
  const hideNotification = useAction(uiActions.hideNotification);

  // don't show notifications on unauthorized page
  const noNotification = location.pathname === SharedPages.unauthorized.path;

  return noNotification ? null : (
    <Snackbar
      open={!!notification}
      onClose={hideNotification}
      autoHideDuration={notification?.duration || 5000}
      message={notification?.message}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      {notification?.variant && (
        <Alert
          severity={notification.variant}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideNotification}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          variant="filled"
          sx={{ whiteSpace: "pre-wrap", color: "background.default" }}
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};
export default Notification;
