import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const RootStyled = styled(`div`)(({ theme }) => ({
  minWidth: "400px",
  borderLeft: "1px solid",
  borderLeftColor: theme.palette.divider,
  padding: "0 24px",
  marginLeft: "24px",
}));

export const GridSectionStyled = styled(`div`)(({ theme }) => ({
  padding: "1em 0",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const GridSectionHeaderStyled = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": {
    color: theme.palette.text.secondary,
    "& svg": {
      color: theme.palette.text.secondary,
    },
  },
}));

export const GridItemStyled = styled(Grid)(() => ({
  padding: "0 4px 8px",
}));
