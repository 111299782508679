import React from "react";
import { useParams } from "react-router-dom";
import { FacilityFilesList } from "../shared/components";
import { GroupFacilityFilesAccessWrapper } from "./components";

export const FacilityFilesPage = React.memo(
  /**
   *
   */
  function FacilityFilesPage() {
    const params = useParams();

    return (
      <GroupFacilityFilesAccessWrapper>
        <FacilityFilesList groupId={Number(params.groupId)} />
      </GroupFacilityFilesAccessWrapper>
    );
  },
);
