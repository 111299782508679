import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getKeyByValue, Navigation, useLocation } from "../../lib";
import { listsActions, useAppDispatch } from "../../state";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import isequal from "lodash.isequal";

export const paginationQueryKey = {
  order: "o",
  orderBy: "ob",
  page: "p",
  resultsPerPage: "rp",
};

export const pageSizeOptions = [15, 25, 35, 50];

export interface ListPaginationProps {
  actions?: JSX.Element; // can be provided when useQuery is true: actions element will be wrapped in ListActions wrapper for injection of query filter update params
  dataModifier?: (results: any[]) => any[];
  dataUrl?: string;
  defaultOrderBy?: string;
  defaultOrderDirection?: string;
  defaultResultsPerPage?: number;
  filter?: object; // when useQuery is true: static applied filters (dynamic filters are set and retrieved through the query key); when useQuery is false: dynamic list filters
  filterQueryKey?: object; // can be provided when useQuery is true: key for filters that should be applied from the url query
  paginationQueryKeyPrefix?: string; // used to differentiate between pagination query params for multiple query-using lists on the same page
  type: string;
  useQuery?: boolean; // maintain list state (pagination, filters, etc) in url query
}

export function useListPagination({
  dataModifier,
  dataUrl,
  defaultOrderBy,
  defaultOrderDirection,
  defaultResultsPerPage,
  filter,
  filterQueryKey,
  paginationQueryKeyPrefix = "",
  type,
  useQuery,
}: ListPaginationProps) {
  const dispatch = useAppDispatch();
  const { pathname, query } = useLocation();

  const paginationParamDefaults = useMemo(
    () => ({
      order: defaultOrderDirection || "asc",
      orderBy: defaultOrderBy,
      page: 1,
      resultsPerPage: defaultResultsPerPage || 15,
    }),
    [defaultOrderBy, defaultOrderDirection, defaultResultsPerPage],
  );

  // #region useQuery:

  // query list pagination params
  const queryPaginationParams = useMemo(
    () => ({
      order: (query[paginationQueryKeyPrefix + paginationQueryKey.order] ||
        paginationParamDefaults.order) as string,
      orderBy: (query[paginationQueryKeyPrefix + paginationQueryKey.orderBy] ||
        paginationParamDefaults.orderBy) as string | undefined,
      page: (query[paginationQueryKeyPrefix + paginationQueryKey.page] ||
        paginationParamDefaults.page) as number,
      resultsPerPage: (query[
        paginationQueryKeyPrefix + paginationQueryKey.resultsPerPage
      ] || paginationParamDefaults.resultsPerPage) as number,
    }),
    [
      paginationParamDefaults.order,
      paginationParamDefaults.orderBy,
      paginationParamDefaults.page,
      paginationParamDefaults.resultsPerPage,
      paginationQueryKeyPrefix,
      query,
    ],
  );

  // update query list params
  const setQueryPaginationParams = useCallback(
    (paramUpdates: object) => {
      const queryUpdates = { ...query };
      Object.keys(paramUpdates).forEach((paramKey) => {
        const paramName = paginationQueryKey[paramKey]; // retrieve param name from the paginationQueryKey
        if (paramName) {
          queryUpdates[paginationQueryKeyPrefix + paramName] =
            paramUpdates[paramKey];
        }
      });

      Navigation.replace(pathname, { query: queryUpdates });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [pathname, paginationQueryKeyPrefix, query],
  );

  // track final params obj applied to the list, to retrieve data only when there are applicable param changes (in the effect below which is called upon any query changes)
  const appliedListParams = useRef<any>(null);

  // retrieve list data when query params or filters have changed
  useEffect(() => {
    if (useQuery) {
      let params = { ...queryPaginationParams };

      // apply query filters based on provided filterQueryKey map
      if (filterQueryKey) {
        Object.keys(query).forEach((qKey) => {
          const paramKey = getKeyByValue(filterQueryKey, qKey);
          if (paramKey) {
            params[paramKey] = query[qKey];
          }
        });
      }

      // apply static filters to params
      if (filter) {
        params = { ...params, ...filter };
      }

      // retrieve list data only if applicable list params have changed
      if (
        !appliedListParams.current ||
        !isequal(appliedListParams.current, params)
      ) {
        appliedListParams.current = params;
        dispatch(listsActions.getList(type, params, dataUrl, dataModifier));
      }
    }
  }, [
    dataModifier,
    dataUrl,
    dispatch,
    filter,
    filterQueryKey,
    query,
    queryPaginationParams,
    type,
    useQuery,
  ]);

  // #endregion

  // #region !useQuery:

  // local list params
  const [nonQueryPaginationParams, setNonQueryPaginationParams] = useState({
    order: paginationParamDefaults.order,
    orderBy: paginationParamDefaults.orderBy,
    page: paginationParamDefaults.page,
    resultsPerPage: paginationParamDefaults.resultsPerPage,
  });

  // track filters locally in order to reset page when filter has changed
  const [nonQueryListFilter, setNonQueryListFilter] = useState(filter);
  useEffect(() => {
    if (!useQuery) {
      setNonQueryListFilter((prevFilters) => {
        if (!isequal(filter, prevFilters)) {
          setNonQueryPaginationParams((params) => ({ ...params, page: 1 }));
        }
        return filter;
      });
    }
  }, [filter, useQuery]);

  // retrieve list data when pagination params or filters have changed
  useEffect(() => {
    if (!useQuery) {
      const params: any = {
        ...nonQueryPaginationParams,
        ...(nonQueryListFilter || {}),
      };

      dispatch(listsActions.getList(type, params, dataUrl, dataModifier));
    }
  }, [
    dataModifier,
    dataUrl,
    dispatch,
    type,
    nonQueryListFilter,
    nonQueryPaginationParams,
    useQuery,
  ]);

  // #endregion

  const setListParams = useCallback(
    (params: object) => {
      if (useQuery) {
        setQueryPaginationParams(params);
      } else {
        setNonQueryPaginationParams((prevParams) => ({
          ...prevParams,
          ...params,
        }));
      }
    },
    [setQueryPaginationParams, useQuery],
  );

  const onPageChange = useCallback(
    (pageIndex: number) => {
      const paramUpdates = {
        page: pageIndex + 1 || paginationParamDefaults.page,
      };
      setListParams(paramUpdates);
    },
    [paginationParamDefaults.page, setListParams],
  );

  const onPageSizeChange = useCallback(
    (resultsPerPageUpdate: number) => {
      const paramUpdates = {
        page: paginationParamDefaults.page,
        resultsPerPage:
          resultsPerPageUpdate || paginationParamDefaults.resultsPerPage,
      };
      setListParams(paramUpdates);
    },
    [
      paginationParamDefaults.page,
      paginationParamDefaults.resultsPerPage,
      setListParams,
    ],
  );

  const onSortChange = useCallback(
    (orderUpdate: GridSortDirection, orderByUpdate: string | null) => {
      const paramUpdates = {
        order: orderUpdate || paginationParamDefaults.order,
        orderBy: orderByUpdate || paginationParamDefaults.orderBy,
        page: paginationParamDefaults.page,
      };
      setListParams(paramUpdates);
    },
    [
      paginationParamDefaults.order,
      paginationParamDefaults.orderBy,
      paginationParamDefaults.page,
      setListParams,
    ],
  );

  return {
    listParams: useQuery ? queryPaginationParams : nonQueryPaginationParams,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  };
}
