import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabbedView } from "../../components";
import { FacilityStatuses, EntityTypes } from "../../lib/constants";
import { adminActions, Facility, useAppDispatch } from "../../state";
import {
  FacilityActivityList,
  FacilityNotesList,
  FacilityRequiredDocumentSubmissionsList,
  FacilityResourcesList,
  FacilitySettings,
  FacilityTrainingsList,
  GroupFacilityRegionSnapshot,
  UsersSublist,
} from "./components";

const inactiveFacilityStatuses = [
  FacilityStatuses.Pending,
  FacilityStatuses.Review,
  FacilityStatuses.Inactive,
];

const tabs = [
  {
    id: "users",
    name: "Users",
    component: UsersSublist,
  },
  {
    id: "settings",
    name: "Settings",
    component: FacilitySettings,
  },
  {
    id: "resources",
    name: "Resources",
    component: FacilityResourcesList,
    excludedFacilityStatuses: inactiveFacilityStatuses,
  },
  {
    id: "required-docs",
    name: "Required Document Submissions",
    component: FacilityRequiredDocumentSubmissionsList,
    excludedFacilityStatuses: inactiveFacilityStatuses,
  },
  {
    id: "trainings",
    name: "Trainings",
    component: FacilityTrainingsList,
    excludedFacilityStatuses: inactiveFacilityStatuses,
  },
  {
    id: "activity",
    name: "Activity",
    component: FacilityActivityList,
    excludedFacilityStatuses: inactiveFacilityStatuses,
  },
  {
    id: "notes",
    name: "Notes",
    component: FacilityNotesList,
  },
];

export const FacilityPage = React.memo(
  /**
   *
   */
  function FacilityPage() {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [facility, setFacility] = useState<Facility>();

    useEffect(() => {
      (async () => {
        if (params.id) {
          const facility = await dispatch(
            adminActions.getFacility(Number(params.id)),
          );
          if (facility) {
            setFacility(facility);
          }
        }
      })();
    }, [dispatch, params.id]);

    const facilityTabs = useMemo(
      () =>
        facility?.status
          ? tabs.filter(
              (t) =>
                !t.excludedFacilityStatuses?.includes(facility.status || ""),
            )
          : [],

      [facility?.status],
    );

    const componentProps = useMemo(
      () => ({
        facility,
        filter: {
          facilityId: params.id,
          groupId: facility?.groupID,
          includeInactive: facility?.status === FacilityStatuses.Inactive,
        },
        resetFacility: setFacility,
      }),
      [facility, params.id],
    );

    return facility ? (
      <>
        <GroupFacilityRegionSnapshot
          details={facility}
          resetDetails={setFacility}
          type={EntityTypes.Facility}
        />
        <TabbedView tabs={facilityTabs} viewProps={componentProps} />
      </>
    ) : null;
  },
);
