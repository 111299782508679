import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ListActionsContainerStyled = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const ListActionsMainStyled = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "24px",
  paddingBottom: "24px",
  "& .MuiFormControl-root": {
    maxWidth: "400px",
  },
}));

export const ListActionsExpandedStyled = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: "24px",
  "& .MuiFormControl-root": {
    width: "360px",
  },
  "& .MuiFormControlLabel-root": {
    whiteSpace: "nowrap",
  },
}));
